// Data Services

import {CancelToken, create} from 'apisauce';
import auth from './auth';
import {history} from '../store/configureStore';
import {PathLogin, PathLogout, PathRegister} from "src/js/components/routes/NavHelper";
import {logDebug} from "src/js/utils/AppLog";

export const APP_TYPE_PROD = 1;
export const APP_TYPE_DEV = 2;
export const APP_TYPE_LOCAL = 3;

// export const APP_TYPE = process.env.REACT_APP_TYPE;
export const APP_TYPE = APP_TYPE_PROD;
// export const APP_TYPE = APP_TYPE_DEV;

export const APP_SHOWCASE = window.location.hostname.startsWith('show.') || window.location.hostname.startsWith('127.0.0.1');

export const APP_BASE_URI = APP_TYPE === APP_TYPE_PROD ? 'https://api.rab7.com' : 'https://dev.rab7.com';
export const SOCKET_BASE_URI = APP_TYPE === APP_TYPE_PROD ? 'https://api.rab7.com:10443' : (APP_TYPE === APP_TYPE_DEV ? 'https://dev.rab7.com:10081' : 'http://localhost:10081');
export const AVATAR_URI = APP_BASE_URI + '/v1/avatar/';
export const APP_URI = APP_TYPE === APP_TYPE_PROD ? 'https://rab7.com/#' : (APP_TYPE === APP_TYPE_DEV ? 'https://play.dev.rab7.com/#' : 'http://localhost:3000/#');
export const APP_URI_WO_HASH = APP_TYPE === APP_TYPE_PROD ? 'https://rab7.com' : (APP_TYPE === APP_TYPE_DEV ? 'https://play.dev.rab7.com' : 'http://localhost:3000');
export const EX_GAME_BASE_URI = APP_TYPE === APP_TYPE_PROD ? 'https://rab7.com/build/games' : 'https://play.dev.rab7.com/build/games';
export const SHOWCASE_APP_URI_WO_HASH = APP_TYPE === APP_TYPE_PROD ? 'https://show.rab7.com' : (APP_TYPE === APP_TYPE_DEV ? 'https://show.dev.rab7.com' : 'http://127.0.0.1:3000');

export const SUPPORT_EMAIL = 'rab7@rab7.com';

export const ACTIVATE_SHOWCASE_URI = APP_URI + '/screens/manage?c=';
export const ACTIVATE_SHOWCASE_SHORT_URI = APP_TYPE === APP_TYPE_PROD ? 'rab7.com/activate' : 'play.dev.rab7.com/activate';

export const TAP_PAYMENT_TOKEN = APP_TYPE === APP_TYPE_PROD ? 'pk_live_n34ji9yQAzbG6F5x7BVkf2KL' : 'pk_test_0954rSkMpLdjnKAF2YTCocJq';
export const TAP_ADD_CARD_FORM = APP_BASE_URI +'/v1/user/cards/view';

export const SOCIAL_GOOGLE_REDIRECT_REGISTER_URI = APP_URI_WO_HASH + PathRegister;
export const SOCIAL_GOOGLE_REDIRECT_LOGIN_URI = APP_URI_WO_HASH + PathLogin;
export const SOCIAL_APPLE_REDIRECT_REGISTER_URI = APP_URI_WO_HASH + PathRegister;
export const SOCIAL_APPLE_REDIRECT_LOGIN_URI = APP_URI_WO_HASH + PathLogin;
export const SOCIAL_GOOGLE_CLIENT_ID = '646042338606-4hvpskjaibdt7ka4slr9fh7pjarh4n82.apps.googleusercontent.com';
export const SOCIAL_APPLE_CLIENT_ID = 'com.rab7.web.signin';

export const SHOWCASE_LIST_QR_URI = APP_URI +'/show';
export const SHOWCASE_PLAY_QR_URI = APP_URI +'/play';

export const API_ASSET_URI = APP_BASE_URI +'/public';
export const APP_GAME_AUDIO_URI = API_ASSET_URI +'/images/activityAudio/';
export const APP_GAME_DEFAULT_IMAGES_URI = API_ASSET_URI +'/images/slot_1/';
export const APP_GAME_POOL_IMAGES_URI = API_ASSET_URI +'/images/slot_1_';
export const API_IMAGES_URI = API_ASSET_URI +'/images';
export const API_AUDIO_DEFAULT_POSTER_URL = `${API_IMAGES_URI}/img_audio_poster.png`;

export const TNC_URL = 'https://api.rab7.com/v1/btncpp/en';
export const TNC_URL_AR = 'https://api.rab7.com/v1/btncpp/ar';

export const HELP_ENABLE_LOCATION_URL = '/helpEnableLocation.html';
export const HELP_ENABLE_LOCATION_AR_URL = '/helpEnableLocation-ar.html';

// Default API root is same server, same protocol
export const API_ROOT = APP_BASE_URI + '/v1';
const jwtToken = auth.getToken();

export const getActivityDefaultImageUrl = (activityType) => {
  return `${API_ASSET_URI}/images/activity/img-${activityType}.png`;
};

// access to our main API
export const api = create({
  baseURL: API_ROOT,
  headers: {
    'Authorization': 'Bearer ' + jwtToken,
    'X-Locale': auth.getLocale(),
    'X-TimeZoneInterval': -(new Date().getTimezoneOffset())/60,
  }
});

export const cancelTokenSource =  CancelToken.source;

export const setAuthHeader = (token) => {
  //api.setHeader('x-request-token', token);
  api.setHeader('Authorization', 'Bearer ' + token);
};

export const setLocaleHeader = (locale) => {
  api.setHeader('X-Locale', locale);
};

export const removeAuthHeader = () => {
  //api.deleteHeader('x-request-token');
  api.deleteHeader('Authorization');
};

api.addMonitor((response) => {
  if (response.status === 401) {
    const user = auth.getUser();
    const isUserGuest = user && !user.registerType && auth.getToken();
    if (!isUserGuest) {
      auth.deleteUserToken();
    }
    const url = (response.config && response.config.url) || '';
    logDebug('urlInApi', url);
    if ((url.indexOf('logout') === -1) && (url.indexOf('auth/user') === -1)) {
      setTimeout(() => {history.push(PathLogout)}, 400);
    }
  }
});

api.addRequestTransform(request => {
  const bustParam = (request.url.indexOf('?') === -1 ? '?' : '&') + '_=' + Date.now();
  request.url += bustParam;

  /*const token = auth.getToken();
  if (request.method !== 'get' && request.data && token) {
    request.data.token = token;
  } else if (token) {
    request.url += '&token=' + token;
  }*/

  if (request.method === 'post' && (request.data instanceof FormData) && request.data.get('_method') === 'PUT') {
    request.headers['X-HTTP-Method-Override'] = 'PUT';
  }
});
