import {locString} from "../components/commons/ViewHelper";

export const BASE_URL_DEV = 'https://dev.rab7.com/v1';
export const BASE_URL_PROD = 'https://api.rab7.com/v1';
export const BASE_URL = BASE_URL_DEV;

export const TNC_URL_AR = 'https://api.rab7.com/v1/ctncpp/ar';
export const TNC_URL_EN = 'https://api.rab7.com/v1/ctncpp/en';

export const LINK_REVIEW = 'https://m.rab7.com/r/';
export const LINK_CAMPAIGN = 'https://m.rab7.com/play/';
export const LINK_CAMPAIGN_REVIEW = 'https://m.rab7.com/cr/';
export const LINK_CAMPAIGN_REVIEWS = 'https://m.rab7.com/crs/';
export const AUDIO_POSTER_IMAGE_URL = 'https://api.rab7.com/public/images/img_audio_poster.png';

export const LATITUDE_DEFAULT = 24.7136;
export const LONGITUDE_DEFAULT = 46.6753;

export const ADMIN_ID = 1;

export const ACTIVITY_TYPE_SMART_AD = 1;
export const ACTIVITY_TYPE_OFFER = 2;
export const ACTIVITY_TYPE_VOTE = 3;
export const ACTIVITY_TYPE_QUESTION = 4;
export const ACTIVITY_TYPE_OPINION = 6;
export const ACTIVITY_TYPE_VISITOR_OPINION = 7;
export const ACTIVITY_TYPE_DISCOUNT = 8;
export const ACTIVITY_TYPE_CUSTOMER_POST = 10;
export const ACTIVITY_TYPE_CHALLENGE = 11;
export const ACTIVITY_TYPE_PREDICTION = 12;
export const ACTIVITY_TYPE_SLOT = 13;
export const ACTIVITY_TYPE_FLIP_CARD = 15;
export const ACTIVITY_TYPE_EVENT = 16;
export const ACTIVITY_TYPE_SHARE_N_LIKE = 17;
export const ACTIVITY_TYPE_COLLECT_STARS = 18;
export const ACTIVITY_TYPE_GUESS = 19;
export const ACTIVITY_TYPE_GAME_ROULETTE = 20;
export const ACTIVITY_TYPE_CONTENT_WRITING = 21;
export const ACTIVITY_TYPE_SPIN_DISCOVER = 22;
export const ACTIVITY_TYPE_FIND_HIDDEN = 23;
export const ACTIVITY_TYPE_GREEDY_SPIN = 24;
export const ACTIVITY_TYPE_BETTING_PIC = 25;
export const ACTIVITY_TYPE_MATCHING_PIC = 26;
export const ACTIVITY_TYPE_TIC_TAC_TOE = 27;
export const ACTIVITY_TYPE_QUESTIONNAIRE = 28;
export const ACTIVITY_TYPE_MATCH_THREE = 29;
export const ACTIVITY_TYPE_PUZZLE = 30;
export const ACTIVITY_TYPE_WHAC_A_MOLE = 31;
export const ACTIVITY_TYPE_BRAND_RACE = 32;
export const ACTIVITY_TYPE_POKER = 33;
export const ACTIVITY_TYPE_IDENTIFY_LOCATION = 34;
export const ACTIVITY_TYPE_PUZZLE_SLIDE = 35;
export const ACTIVITY_TYPE_SURVEY = 36;
export const ACTIVITY_TYPE_SLIDES = 37;
export const ACTIVITY_TYPE_SHOWCASE_MENU = 38;
export const ACTIVITY_TYPE_EXTERNAL_GAMES = 100;

export const ACTIVITY_UPDATE_STATE_RATE = 82;
export const ACTIVITY_UPDATE_STATE_TRANSFER = 84;
export const ACTIVITY_UPDATE_STATE_PREVIEWED = 85;
export const ACTIVITY_UPDATE_STATE_ACTION = 87;
export const ACTIVITY_UPDATE_STATE_SHARE = 88;
export const ACTIVITY_CTA1_VIEW = 91;
export const ACTIVITY_CTA1_ACTION = 92;
export const ACTIVITY_CTA2_VIEW = 93;
export const ACTIVITY_CTA2_ACTION = 94;
export const ACTIVITY_UPDATE_STATE_RESET = 100;

export const ACTIVITY_SLIDESHOW_DURATION_MIN = 4;
export const ACTIVITY_SLIDESHOW_DURATION_MAX = 12;

export const AD_AT_PROMPT = 1;
export const AD_AT_FEED = 2;

export const ADDRESS_TYPE_HOME = 1;
export const ADDRESS_TYPE_WORK = 2;
export const ADDRESS_TYPE_OTHER = 3;
export const ADDRESS_TYPE_CURRENT = 10;
export const ADDRESS_TYPE_FROM_MAP = 11;

export const ANSWER_TYPE_MULTIPLE = 2;

export const COUNTRY_ID_SAUDI_ARABIA = 1;
export const STORE_TYPE_EXPRESS = 1;
export const STORE_TYPE_NORMAL = 2;
export const STORE_TYPE_INDIVIDUAL_SELL = 3;
export const STORE_TYPE_INDIVIDUAL_DEMAND = 4;

export const CREATED_TYPE_USER = 1;
export const CREATED_TYPE_BUSINESS = 2;
export const CREATED_TYPE_EMPLOYEE = 3;
export const CREATED_TYPE_ADMIN = 4;
export const CREATED_TYPE_DRIVER = 5;
export const CREATED_TYPE_ME = CREATED_TYPE_USER;

export const FEED_TYPE_AD = 1;
export const FEED_TYPE_POST = 2;
export const FEED_TYPE_USER_TRANSACTION = 3;
export const FEED_TYPE_ITEM = 4;

export const GENDER_MALE = 1;
export const GENDER_FEMALE = 2;
// export const GENDERS = [
//   {'id': GENDER_MALE, 'name': locString('gMale')},
//   {'id': GENDER_FEMALE, 'name': locString('gFemale')},
// ];

export const HELP_REGISTRATION_BIRTHDAY = 1;
export const HELP_REGISTRATION_CITY = 2;
export const HELP_REGISTRATION_EDUCATION_LEVEL = 3;
export const HELP_REGISTRATION_NATIONALITY = 4;
export const HELP_REGISTRATION_PROFESSION = 5;
export const HELP_REGISTRATION_LOGIN_CREDENTIALS = 6;

export const HELP_ADD_TRANSACTION = 10;
export const HELP_CHECK_REQUEST = 11;
export const HELP_SYSTEM_POINTS = 12;

export const IMAGE_WIDTH_PROFILE = 600;
export const IMAGE_WIDTH = 1200;
export const IMAGE_COMPRESSION = 0.8;
export const IMAGE_ASPECT_RATIO = 16/9;

export const LOCALITY_TYPE_REGION = 2;
export const LOCALITY_TYPE_CITY = 3;
export const LOCALITY_TYPE_DISTRICT = 4;

export const LANGUAGE_CODE_ARABIC = 'ar';
export const LANGUAGE_CODE_ENGLISH = 'en';

export const MARITAL_STATUS_MARRIED = 1;
export const MARITAL_STATUS_NOT_MARRIED = 2;

export const MAX_PER_PAGE = 25;
export const MAX_PER_PAGE_MAP = 40;

export const PUSH_NOTIFICATION_TYPE_ACTIVITY = 10;

export const NOTIFICATION_TYPE_EARN = 1;
export const NOTIFICATION_TYPE_REDEEM_GIFT = 2;
export const NOTIFICATION_TYPE_REDEEM_OFFER = 3;
export const NOTIFICATION_TYPE_REFUND = 4;
export const NOTIFICATION_TYPE_USER_REQUEST_PROCESSED_SILENT = 5;
export const NOTIFICATION_TYPE_REJECT_SILENT = 6;
export const NOTIFICATION_TYPE_CART_ABANDONED = 9;
export const NOTIFICATION_TYPE_REVIEW_PENDING = 17;
export const NOTIFICATION_TYPE_TRANSFER_OFFER = 10;
export const NOTIFICATION_TYPE_TRANSFER_POINTS = 11;
export const NOTIFICATION_TYPE_TRANSFER_SYSTEM_POINTS = 12;
export const NOTIFICATION_TYPE_CONTACT_REQUESTED_IN_REVIEW = 13;
export const NOTIFICATION_TYPE_CONTACT_REQUESTED_IN_ACTIVITY_REVIEW = 15;
export const NOTIFICATION_TYPE_WINNER_IN_ACTIVITY = 18;
export const NOTIFICATION_TYPE_COMMENT_IN_POST = 20;
export const NOTIFICATION_TYPE_REPLY_IN_COMMENT = 21;
export const NOTIFICATION_TYPE_COMMENT_IN_ACTIVITY = 22;
export const NOTIFICATION_TYPE_REPLY_IN_COMMENT_IN_ACTIVITY = 23;
export const NOTIFICATION_TYPE_LIKE_IN_POST = 24;
export const NOTIFICATION_TYPE_LIKE_IN_ACTIVITY_REVIEW = 28;
export const NOTIFICATION_TYPE_REFERRAL_WINNER_IN_ACTIVITY = 29;
export const NOTIFICATION_TYPE_MESSAGE = 30;
export const NOTIFICATION_TYPE_MESSAGE_IN_ORDER = 31;
export const NOTIFICATION_TYPE_MESSAGE_IN_ORDER_DRIVER = 32;
export const NOTIFICATION_TYPE_RING_IN_ORDER = 33;
export const NOTIFICATION_TYPE_SYSTEM = 40;
export const NOTIFICATION_TYPE_SYSTEM_PRIZE_WINNER = 41;
export const NOTIFICATION_TYPE_VOTING_STARTED = 46;

export const NOTIFICATION_TYPE_BUSINESS_DRIVER_REQUEST = 50;
export const NOTIFICATION_TYPE_BUSINESS_DRIVER_REQUEST_UPDATE = 51;
export const NOTIFICATION_TYPE_LITE_DRIVERS_STORE_REQUESTS = 55;

export const NOTIFICATION_GROUP_DRIVER_ORDER = 300;
export const NOTIFICATION_TYPE_ORDER_DRIVER_READY_TO_PICK = 301;
export const NOTIFICATION_TYPE_ORDER_DRIVER_PICKUP_ASSIGNED = 302;
export const NOTIFICATION_TYPE_ORDER_DRIVER_PICKUP_CANCELLED = 303;
export const NOTIFICATION_TYPE_ORDER_DRIVER_SHIPPED = 304;
export const NOTIFICATION_TYPE_ORDER_DRIVER_CANCELLED = 305;
export const NOTIFICATION_TYPE_ORDER_DRIVER_FREE_UP = 306;
export const NOTIFICATION_TYPE_ORDER_DRIVER_FREEZE = 307;
export const NOTIFICATION_TYPE_ORDER_LITE_DRIVER_SHARE_INFO = 308;

export const NOTIFICATION_GROUP_ORDER = 310;
export const NOTIFICATION_TYPE_ORDER_PLACED = 310;
export const NOTIFICATION_TYPE_ORDER_REVIEW_ITEMS = 311;
export const NOTIFICATION_TYPE_ORDER_ITEMS_UPDATED = 312;
export const NOTIFICATION_TYPE_ORDER_CONFIRMED = 313;
export const NOTIFICATION_TYPE_ORDER_READY_TO_PICK = 314;
export const NOTIFICATION_TYPE_ORDER_PICKUP_ASSIGNED = 315;
export const NOTIFICATION_TYPE_ORDER_PICKUP_CANCELLED = 316;
export const NOTIFICATION_TYPE_ORDER_SHIPPED = 317;
export const NOTIFICATION_TYPE_ORDER_DELIVERY_CANCELLED = 318;
export const NOTIFICATION_TYPE_ORDER_DELIVERED = 319;
export const NOTIFICATION_TYPE_ORDER_CANCELLED = 320;
export const NOTIFICATION_TYPE_ORDER_REFUNDED = 321;
export const NOTIFICATION_TYPE_ORDER_DISPUTE_RESOLVED = 323;

export const NOTIFICATION_TYPE_DAILY_WINNERS = 400;

export const POST_TYPE_REVIEWS = 1;
export const POST_TYPE_SELL_ITEM = 2;
export const POST_TYPE_INFO = 3;
export const POST_TYPE_QUESTION = 4;
export const POST_TYPE_JOB = 5;
export const POST_TYPE_RESUME = 6;
export const POST_TYPE_DEMAND = 7;
export const POST_TYPE_TRAVEL = 8;
export const POST_TYPE_SHIPMENT = 9;
export const POST_TYPE_DRIVER_JOB = 10;
export const POST_TYPE_I_REVIEW = 11;

export const POST_TYPE_CHALLENGE = 100;
export const POST_TYPE_PLACE = 101;
export const POST_TYPE_EVENT = 102;
export const POST_TYPE_FUN = 103;
export const POST_TYPE_TRAINING = 104;
export const POST_TYPE_TOP_ACTIVITY_POINTS = 105;

export const JOB_TYPE_FULL_TIME = 1;
export const JOB_TYPE_PART_TIME = 2;
export const JOB_TYPE_ONE_TIME = 3;

export const RESEND_TIMER = 59;

export const ROLE_CASHIER = 31;
export const ROLE_MANAGER = 32;

export const REVIEW_BY_BUYER = 2;
export const REVIEW_BY_VISITOR = 1;

export const STATE_DELETED = 0;
export const STATE_ENABLED = 1;
export const STATE_DISABLED = 2;
export const STATE_SUSPENDED = 3;
export const STATE_SOLD_OUT = 4;
export const STATE_USER_REGISTRATION_PENDING = 10;
export const STATE_USER_BLOCKED = 11;
export const STATE_BUSINESS_APPROVAL_PENDING = 21;
export const STATE_BUSINESS_APPROVAL_REJECTED = 22;
export const STATE_ACK_PENDING = 30;
export const STATE_ACK_OPENED = 31;
export const STATE_ACK_DELETED = 32;
export const STATE_NOTIFICATION_NEW = 40;
export const STATE_NOTIFICATION_PUSHED = 41;
export const STATE_NOTIFICATION_READ = 42;
export const STATE_EMPLOYEE_INVITED = 50;
export const STATE_EMPLOYEE_REJECTED = 51;
export const STATE_PRIZE_RECEIVED = 60;
export const STATE_USER_REQUEST_COMPLETED = 80;
export const STATE_USER_REQUEST_REJECTED = 81;
export const STATE_USER_REQUEST_TIMED_OUT = 82;

export const STATE_ORDER_PAYMENT_FAILED = 90;
export const STATE_ORDER_PAYMENT_CANCELLED = 91;
export const STATE_ORDER_CREATED = 92;
export const STATE_ORDER_PLACED = 93;
export const STATE_ORDER_IN_REVIEW = 94;
export const STATE_ORDER_UPDATED = 95;
export const STATE_ORDER_CONFIRMED = 96;
export const STATE_ORDER_READY_TO_PICK = 97;
export const STATE_ORDER_PICKUP_ASSIGNED = 98;
export const STATE_ORDER_SHIPPED = 99;
export const STATE_ORDER_DELIVERY_FAILED = 100;
export const STATE_ORDER_DELIVERED = 101;
export const STATE_ORDER_CANCELLED = 102;
export const STATE_ORDER_DISPUTE_RAISED = 103;

export const SYSTEM_POINTS_TYPE_USER_REGISTRATION_GENDER = 110;
export const SYSTEM_POINTS_TYPE_USER_REGISTRATION_DOB = 111;
export const SYSTEM_POINTS_TYPE_USER_REGISTRATION_NATIONALITY = 112;
export const SYSTEM_POINTS_TYPE_USER_REGISTRATION_LOCALITY = 113;
// export const SYSTEM_POINTS_TYPE_USER_REGISTRATION_MARITAL_STATUS = 23;
// export const SYSTEM_POINTS_TYPE_USER_REGISTRATION_EDUCATION_LEVEL = 24;
// export const SYSTEM_POINTS_TYPE_USER_REGISTRATION_PROFESSION = 25;

export const TRANSACTION_TYPE_EARN = 1;
export const TRANSACTION_TYPE_EARN_WITH_DISCOUNT = 2;
export const TRANSACTION_TYPE_REDEEM_GIFT = 3;
export const TRANSACTION_TYPE_REDEEM_OFFER = 4;
export const TRANSACTION_TYPE_REFUND_EARN = 5;
export const TRANSACTION_TYPE_ENCASH_DELIVERY_EARN = 6;

export const TRANSACTION_TYPE_REJECT_REDEEM_DISCOUNT = 10;
export const TRANSACTION_TYPE_REJECT_REDEEM_GIFT = 11;
export const TRANSACTION_TYPE_REJECT_REDEEM_OFFER = 12;
export const TRANSACTION_TYPE_REJECT_REFUND = 13;

export const TRANSACTION_TYPE_TRANSFER = 20;

export const USER_REQUEST_TYPE_REDEEM_OFFER = 1;
export const USER_REQUEST_TYPE_REDEEM_OFFER_WITH_CODE = 2;
export const USER_REQUEST_TYPE_REDEEM_DISCOUNT = 3;
export const USER_REQUEST_TYPE_REDEEM_GIFT = 4;
export const USER_REQUEST_TYPE_REFUND_EARN = 5;

export const CTA_TITLES = [
  {id: '1', nameId: 'activity.ctaTitle.visitNow'},
  {id: '2', nameId: 'activity.ctaTitle.shopNow'},
  {id: '3', nameId: 'activity.ctaTitle.downloadNow'},
  {id: '4', nameId: 'activity.ctaTitle.learnMore'},
  {id: '5', nameId: 'activity.ctaTitle.followUs'},
  {id: '6', nameId: 'activity.ctaTitle.orderNow'},
  {id: '7', nameId: 'activity.ctaTitle.playNow'},
  {id: '8', nameId: 'activity.ctaTitle.startNow'},
  {id: '9', nameId: 'activity.ctaTitle.joinNow'},
  {id: '10', nameId: 'activity.ctaTitle.shareNow'},
];

export const GENDERS_WITH_NR = [
  {'id': 0, 'nameId': 'gNotRegistered'},
  {'id': GENDER_MALE, 'nameId': 'gMale'},
  {'id': GENDER_FEMALE, 'nameId': 'gFemale'},
];

export const GENDERS = [
  {'id': GENDER_MALE, 'nameId': 'gMale'},
  {'id': GENDER_FEMALE, 'nameId': 'gFemale'},
];

export const SHOWCASE = [
  {'id': 1, 'nameId': 'inShowcase'},
  {'id': 0, 'nameId': 'notInShowcase'},
];

export const RATINGS = [
  {'id': 1, 'name': '1'},
  {'id': 2, 'name': '2'},
  {'id': 3, 'name': '3'},
  {'id': 4, 'name': '4'},
  {'id': 5, 'name': '5'},
  {'id': 0, 'name': locString('notRatedYet')},
];

export const MONTHS = [
  {'id': 1, 'nameId': 'january'},
  {'id': 2, 'nameId': 'february'},
  {'id': 3, 'nameId': 'march'},
  {'id': 4, 'nameId': 'april'},
  {'id': 5, 'nameId': 'may'},
  {'id': 6, 'nameId': 'june'},
  {'id': 7, 'nameId': 'july'},
  {'id': 8, 'nameId': 'august'},
  {'id': 9, 'nameId': 'september'},
  {'id': 10, 'nameId': 'october'},
  {'id': 11, 'nameId': 'november'},
  {'id': 12, 'nameId': 'december'},
];

export const WEEK_DAYS = [
  {'id': 1, 'nameId': 'sunday'},
  {'id': 2, 'nameId': 'monday'},
  {'id': 3, 'nameId': 'tuesday'},
  {'id': 4, 'nameId': 'wednesday'},
  {'id': 5, 'nameId': 'thursday'},
  {'id': 6, 'nameId': 'friday'},
  {'id': 7, 'nameId': 'saturday'},
];

export const DAYS = [
  {'id': 1, 'name': '1'},
  {'id': 2, 'name': '2'},
  {'id': 3, 'name': '3'},
  {'id': 4, 'name': '4'},
  {'id': 5, 'name': '5'},
  {'id': 6, 'name': '6'},
  {'id': 7, 'name': '7'},
  {'id': 8, 'name': '8'},
  {'id': 9, 'name': '9'},
  {'id': 10, 'name': '10'},
  {'id': 11, 'name': '11'},
  {'id': 12, 'name': '12'},
  {'id': 13, 'name': '13'},
  {'id': 14, 'name': '14'},
  {'id': 15, 'name': '15'},
  {'id': 16, 'name': '16'},
  {'id': 17, 'name': '17'},
  {'id': 18, 'name': '18'},
  {'id': 19, 'name': '19'},
  {'id': 20, 'name': '20'},
  {'id': 21, 'name': '21'},
  {'id': 22, 'name': '22'},
  {'id': 23, 'name': '23'},
  {'id': 24, 'name': '24'},
  {'id': 25, 'name': '25'},
  {'id': 26, 'name': '26'},
  {'id': 27, 'name': '27'},
  {'id': 28, 'name': '28'},
  {'id': 29, 'name': '29'},
  {'id': 30, 'name': '30'},
  {'id': 31, 'name': '31'},
];

export const HOURS = [
  {'id': 0, 'name': '0'},
  {'id': 1, 'name': '1'},
  {'id': 2, 'name': '2'},
  {'id': 3, 'name': '3'},
  {'id': 4, 'name': '4'},
  {'id': 5, 'name': '5'},
  {'id': 6, 'name': '6'},
  {'id': 7, 'name': '7'},
  {'id': 8, 'name': '8'},
  {'id': 9, 'name': '9'},
  {'id': 10, 'name': '10'},
  {'id': 11, 'name': '11'},
  {'id': 12, 'name': '12'},
  {'id': 13, 'name': '13'},
  {'id': 14, 'name': '14'},
  {'id': 15, 'name': '15'},
  {'id': 16, 'name': '16'},
  {'id': 17, 'name': '17'},
  {'id': 18, 'name': '18'},
  {'id': 19, 'name': '19'},
  {'id': 20, 'name': '20'},
  {'id': 21, 'name': '21'},
  {'id': 22, 'name': '22'},
  {'id': 23, 'name': '23'},
];
