import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {baseStyles, withMultipleStyles} from "src/styles/Styles";
import {GridList} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import View from "../commons/View";
import {Pagination} from "@material-ui/lab";
import {
  fetchActivityDummyWinners,
  fetchActivityWinners,
  fetchOpenActivityWinners,
  notifyActivityWinner
} from "../../actions/funActions";
import AppTextWrapper from "../commons/AppTextWrapper";
import {FontWeightMedium} from "src/res/FontWeight";
import AppImage from "../commons/AppImage";
import {SpaceXSmall} from "src/res/Spacing";
import {ColorSubTitle, ColorTheme, ColorTitle} from "src/styles/Colors";
import Space from "../commons/Space";
import TextView from "../commons/TextView";
import AppText from "src/js/components/commons/AppText";
import Snackbar from "@material-ui/core/Snackbar";
import {SNACKBAR_AUTO_HIDE_DURATION} from "src/js/components/commons/ViewHelper";
import MuiAlert from "@material-ui/lab/Alert";
import {getErrorFromResponse} from "src/js/utils/ApiHelper";

const useStyles = () => ({
  gridItemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    paddingLeft: 8,
    paddingRight: 8,
  },
  messageBox: {
    display: 'flex',
    width: '100%',
    minHeight: 300,
    alignItems: 'center',
    justifyContent: 'center'
  },
  winnerName: {
    textAlign: 'left',
    fontWeight: FontWeightMedium,
  },
});

class ActivityWinners extends React.Component {

  constructor(props) {
    super(props);

    this.readOnly = props.readOnly;
    this.state = {
      items: null,
    };

  }

  componentDidMount() {
    this.fetchActivityWinners();
  }

  componentWillUnmount() {
  }

  render() {
    const {uiConfigs, classes, items, maxHeight, forUser} = this.props;
    const isLoading = items.isLoading;

    if (isLoading) {
      return (
        <View className={classes.flexColumn} style={{justifyContent: 'center'}}>
          <CircularProgress style={{color: uiConfigs.loadingColor || ColorTheme}} />
        </View>
      );
    }

    const showMessage = (!isLoading || (items.errMsg && items.errMsg.length > 0) || (items.errMsgF && items.errMsgF.length > 0)) && items.paginated.data.length === 0 && items.paginated.totalSize === 0;
    let message = null;
    if (items.paginated.data.length === 0) {
      if (items.errMsg && items.errMsg.length > 0) {
        message = items.errMsg;
      } else if (items.errMsgF && items.errMsgF.length > 0) {
        message = <FormattedMessage id={items.errMsgF}/>;
      } else if (!isLoading) {
        if (items.paginated.totalSize > 0) {
          message = <FormattedMessage id={forUser ? 'activity.noMoreWinners' : 'activity.noWinners'}/>;
        } else {
          message = <FormattedMessage id={this.props.inPreview ? 'activity.dummyWinnerMessage' : (forUser ? 'noWinnersYet' : 'activity.noWinners')}/>;
        }
      }
    }

    return (
      <div className={classes.viewColumnFullWidthNoCenter}>
        {!forUser ?
          <AppText
            fontSizeN={uiConfigs.fontSize}
            variant={'h6'}
            align={'center'}
            style={{
              marginTop: 4 * uiConfigs.sizeFactor,
              marginBottom: 16 * uiConfigs.sizeFactor,
              color: uiConfigs.overlayHeadingColor,
              fontWeight: FontWeightMedium}}>
            <FormattedMessage id={'winners'}/>
          </AppText>
          : null
        }
        {!message ?
          <GridList cols={1} className={classes.viewColumnFullWidthNoCenter} cellHeight={'auto'}>
            {items.paginated.data.map((item, index) => {
              return this.getViewForItem(index, item);
            })}
          </GridList>
          :
          <div className={classes.messageBox}>
            <TextView fontSizeN={this.props.uiConfigs.fontSize} variant={'subtitle1'} color={this.props.uiConfigs.overlaySubTitleColor} className={classes.typoMedium}>
              {message}
            </TextView>
          </div>
        }

        {!isLoading && (items.paginated.page > 1 || items.paginated.canLoadMore) ?
          <Pagination
            color="primary"
            style={{marginTop: 12}}
            size={"small"}
            count={Math.max(items.paginated.page, Math.ceil(items.paginated.totalSize/items.paginated.perPage))}
            page={items.paginated.page}
            showFirstButton={true}
            defaultPage={6}
            siblingCount={0}
            onChange={(event, page) => {
              this.handleTableChange(event, {page: page});
            }}
          />
          : null
        }

        {this.renderSnackBar()}
      </div>
    );
  }

  renderSnackBar() {
    const {showSnackbar, snackbarInfo} = this.state;
    if (showSnackbar) {
      const {snackbarMessageId, snackbarMessage, snackbarType} = snackbarInfo;
      const onClose = () => {
        this.setState({showSnackbar: false, snackbarInfo: null});
      };
      let message = snackbarMessage;
      if (!message && snackbarMessageId) {
        message = <FormattedMessage id={snackbarMessageId}/>;
      }
      return (
        <Snackbar open={message != null} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} onClose={onClose}>
          <MuiAlert severity={snackbarType || "success"} onClose={onClose} elevation={6} variant="filled">
            {message}
          </MuiAlert>
        </Snackbar>
      );
    }
  }

  showSnackbar(snackbarInfo) {
    if (snackbarInfo) {
      this.setState({showSnackbar: true, snackbarInfo});
    }
  }

  getViewForItem(index, item) {
    const {classes, uiConfigs} = this.props;

    const titleColor = uiConfigs ? uiConfigs.overlayTitleColor : ColorTitle;
    const subTitleColor = uiConfigs ? uiConfigs.overlaySubTitleColor : ColorSubTitle;

    const name = item.createdBy.name;
    const username = item.createdBy.username;
    const imageUrl = item.createdBy.imageUrl;
    const email = item.createdBy.email;
    const mobileNumberNCode = item.createdBy.mobileNumberNCode;
    // const date = DateTimeHelper.getDisplayDateOnly(item.createdAt);
    // const time = DateTimeHelper.getDisplayTime(item.createdAt);
    // const dateTime = <AppText><AppText>{date}</AppText><AppText>{"\n"}</AppText><AppText>{time}</AppText></AppText>;

    const userIconSize = 42 * uiConfigs.sizeFactor;
    let marginTop = 0;
    if (index === 0) {
      marginTop = 8;
    }
    return (
      <View style={{width: '100%', marginTop: marginTop, marginBottom: 12, padding: 2}}>
        {/*<Touchable onPress={this.onUserClicked}>*/}
          <View className={classes.viewRow}>
            <View style={{
              width: userIconSize,
              marginEnd: SpaceXSmall,
            }}>
              <AppImage
                style={{width: userIconSize, height: userIconSize, borderRadius: userIconSize / 2}}
                source={imageUrl}
              />
            </View>
            <Space verticalSpace={1} />
            <View className={classes.viewColumnFullWidthNoCenter1}>
              <AppTextWrapper
                fontSizeN={uiConfigs.fontSize}
                textAlign={'left'}
                variant={'body1'}
                numberOfLines={1}
                className={classes.winnerName}
                style={{
                  flex: 1,
                  color: titleColor
                }}>{name}
              </AppTextWrapper>
              {mobileNumberNCode || email ?
                <AppTextWrapper
                  fontSizeN={uiConfigs.fontSize}
                  textAlign={'left'}
                  variant={'body2'}
                  numberOfLines={1}
                  className={classes.winnerName}
                  style={{
                    flex: 1,
                    color: subTitleColor
                  }}>{mobileNumberNCode || email}
                </AppTextWrapper>
                : null
              }

              {/*{username ?*/}
              {/*    <AppTextWrapper*/}
              {/*        numberOfLines={1}*/}
              {/*        style={{*/}
              {/*            fontSize: FontSizeSmall,*/}
              {/*            fontWeight: FontWeightRegular,*/}
              {/*            color: subTitleColor,*/}
              {/*        }}>{"@" + username}*/}
              {/*    </AppTextWrapper>*/}
              {/*    : null*/}
              {/*}*/}

              {/*{this.props.showNotify ?*/}
              {/*  <Button*/}
              {/*    disableElevation={true}*/}
              {/*    variant={'contained'}*/}
              {/*    color={'secondary'}*/}
              {/*    endIcon={<MailOutlineIcon/>}*/}
              {/*    onClick={() => this.onClickNotify(item)}>*/}
              {/*    <FormattedHTMLMessage id={'activity.notifyEmail'}/>*/}
              {/*  </Button>*/}
              {/*  : null*/}
              {/*}*/}
            </View>
          </View>
        {/*</Touchable>*/}
      </View>
    );
  }

  onClickNotify = (item) => {
    this.props.notifyActivityWinner(item.winnerId).then(() => {
      this.showSnackbar({snackbarMessageId: 'activity.emailSent'});
    }).catch((response) => {
      const error = getErrorFromResponse(response, this.context.intl);
      this.showSnackbar({
        snackbarMessage: error,
        snackbarMessageId: 'activity.emailSendFailed',
        snackbarType: 'error'
      });
    });
  }

  handleTableChange = (type, { page }) => {
    this.fetchActivityWinners(page);
  }

  fetchActivityWinners(page = 1) {
    const {activityId} = this.props;
    if (this.readOnly) {
      this.props.fetchOpenActivityWinners(activityId, page);
    } else if (activityId > 0) {
      this.props.fetchActivityWinners(activityId, this.props.forUser, page);
    } else {
      setTimeout(() => {
        this.props.fetchActivityDummyWinners(activityId, page);
      }, 200);
    }
  }

}

ActivityWinners.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.object,
  activityId: PropTypes.number,

  fetchActivityWinners: PropTypes.func,
  fetchActivityDummyWinners: PropTypes.func,
};

ActivityWinners.contextTypes = {
  intl:PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    items: state.admin.activityWinners,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivityWinners: (id, forUser, page) => dispatch(fetchActivityWinners(id, forUser, page)),
    fetchActivityDummyWinners: (id, page) => dispatch(fetchActivityDummyWinners(id, page)),
    fetchOpenActivityWinners: (id, page) => dispatch(fetchOpenActivityWinners(id, page)),
    notifyActivityWinner: (id) => dispatch(notifyActivityWinner(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMultipleStyles(baseStyles, useStyles)(ActivityWinners));
