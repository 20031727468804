import {
  ACTIVITY_TYPE_BETTING_PIC,
  ACTIVITY_TYPE_BRAND_RACE,
  ACTIVITY_TYPE_CHALLENGE,
  ACTIVITY_TYPE_COLLECT_STARS,
  ACTIVITY_TYPE_CONTENT_WRITING,
  ACTIVITY_TYPE_DISCOUNT,
  ACTIVITY_TYPE_EVENT,
  ACTIVITY_TYPE_FIND_HIDDEN,
  ACTIVITY_TYPE_FLIP_CARD,
  ACTIVITY_TYPE_GAME_ROULETTE,
  ACTIVITY_TYPE_GREEDY_SPIN,
  ACTIVITY_TYPE_GUESS,
  ACTIVITY_TYPE_IDENTIFY_LOCATION,
  ACTIVITY_TYPE_MATCH_THREE,
  ACTIVITY_TYPE_MATCHING_PIC,
  ACTIVITY_TYPE_OFFER,
  ACTIVITY_TYPE_OPINION,
  ACTIVITY_TYPE_POKER,
  ACTIVITY_TYPE_PREDICTION,
  ACTIVITY_TYPE_PUZZLE,
  ACTIVITY_TYPE_PUZZLE_SLIDE,
  ACTIVITY_TYPE_QUESTION,
  ACTIVITY_TYPE_QUESTIONNAIRE,
  ACTIVITY_TYPE_SLOT,
  ACTIVITY_TYPE_SPIN_DISCOVER,
  ACTIVITY_TYPE_SURVEY,
  ACTIVITY_TYPE_TIC_TAC_TOE,
  ACTIVITY_TYPE_VISITOR_OPINION,
  ACTIVITY_TYPE_WHAC_A_MOLE,
  POST_TYPE_DEMAND,
  POST_TYPE_JOB,
  POST_TYPE_QUESTION,
  POST_TYPE_RESUME,
  POST_TYPE_REVIEWS,
  POST_TYPE_SELL_ITEM,
  POST_TYPE_SHIPMENT,
  POST_TYPE_TRAVEL,
  STORE_TYPE_NORMAL,
} from 'src/js/constants/ApiConstants';
import {locString} from "../components/commons/ViewHelper";
import {APP_URI} from "./services";
import {logDebug} from "./AppLog";
import {ColorBackground} from "src/styles/Colors";
import tinycolor from "tinycolor2";

export const IS_LOCATION_REQUIRED = false;

export const isUserAccountPending = (user) => {
  // return user && user.registerType > 0 && (!user.name || user.name.startsWith(`#${user.rab7Number}`));
  return user && user.registerType > 0 && user.showA;
}

export const isUserPrivileged = (user) => {
  return user && (user.id === 1 || user.id === 3);
}

export const areObjectsSame = (obj1, obj2) => {
  // Check if both are the same object reference
  if (obj1 === obj2) return true;

  // Check if both are objects and not null
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if they have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare each key and value
  for (let key of keys1) {
    // Check if the value is an object, and do a recursive check
    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      if (!areObjectsSame(obj1[key], obj2[key])) {
        return false;
      }
    } else {
      // Check if the values are the same
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
}

export const areSetsEqual = (setA, setB) => {
  if (setA.size !== setB.size) {
    return false; // Different sizes mean the sets can't be equal
  }

  for (let item of setA) {
    if (!setB.has(item)) {
      return false; // If any item in setA is not in setB, they're not equal
    }
  }

  return true; // All checks passed; sets are equal
}

export const getWords = (text) => {
  if (!text || text.trim().length === 0) {
    return null;
  }
  const words = [];
  text.split(' ').forEach((word) => {
    if (word && word.trim().length > 0) {
      words.push(word);
    }
  })
  return words;
}


export const dispatchSimulationClick = (element) => {
  const {x, y, width, height} = element.getBoundingClientRect();
  const clickEvent = new PointerEvent('click', {
    bubbles: true,
    cancelable: true,
    clientX: x + width/2,
    clientY: y + height/2,
  });
  clickEvent.simulation = 1;
  element.dispatchEvent(clickEvent);
}

export const isRTLFirstChar = (str) => {
  if (!str) return false; // Handle empty strings
  const rtlPattern = /^[\u0590-\u08FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlPattern.test(str);
}


export const flattenTo2DArray = (flatArray, columns) => {
  const rows = Math.ceil(flatArray.length / columns);
  const result = [];
  for (let i = 0; i < rows; i++) {
    result.push(flatArray.slice(i * columns, (i + 1) * columns));
  }
  return result;
}

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.position = "fixed";  //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function () {
    console.log('Async: Copying to clipboard was successful!');
  }, function (err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export function implode(glueWith, array) {
  let outString = null;
  if (array && array.length > 0) {
    outString = '';
    let count = 0;
    array.forEach((item) => {
      if (count > 0) {
        outString += glueWith;
      }
      outString += item;
      count++;
    });
  }
  return outString;
}

export function getArrayOfIds(array) {
  let ids = null;
  if (array && array.length > 0) {
    ids = [];
    array.forEach((value) => {
      ids.push(value.id);
    });
  }
  return ids;
}

export function colorWithAlpha(color, alpha = 1) {
  if (color.startsWith('#')) {
    color = hexToRgb(color);
    return 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + alpha + ')';
  } else if (color.startsWith('rgba')) {
    const splits = color.replace('(rgb', '').replace(')', '').split(',');
    return 'rgba('+ splits[0] +','+ splits[1] +','+ splits[2] +','+ alpha +')';
  }
  return color.replace(')', ','+ alpha +')').replace('rgb', 'rgba');
}

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const getNavLocationFromUrl = (url) => {
  url = url.replace(window.location.origin +'/#', '');
  url = url.replace(window.location.origin +'/build/#', '');
  const splits = url.split('#');

  const location = {};
  if (splits) {
    if (splits.length > 0) {
      const pathNSearch = splits[0];
      const splits2 = pathNSearch.split('?');
      location.pathname = splits2[0];
      if (splits2.length > 1) {
        const search = splits2[1];
        location.search = search && search.length > 0 ? '?'+ search : null;
      }
    }
    if (splits.length > 1) {
      location.hash = splits[1];
    }
  }
  return location;
}

export const areNavLocationsEqual = (location1, location2, checkHash = false) => {
  let equal = false;
  if (location1 && location2) {
    const path1 = location1.pathname + (location1.search && location1.search.length > 0 ? location1.search : '') + (checkHash && location1.hash && location1.hash.length > 0 ? location1.hash : '');
    const path2 = location2.pathname + (location2.search && location2.search.length > 0 ? location2.search : '') + (checkHash && location2.hash && location2.hash.length > 0 ? location2.hash : '');
    equal = path1 === path2;
  }
  return equal;
}

export const getCompletePath = (location, withHash = true) => {
  return location.pathname + (location.search && location.search.length > 0 ? location.search : '') + (withHash && location.hash && location.hash.length > 0 ? location.hash : '');
}

export const getMostReadableColor = (color) => {
  return tinycolor.mostReadable(color, ['#FFFFFF', '#EEEEEE', '#000000', '#333333', '#000080', '#FF0000', '#FFFF00']).toString();
}

export const getAbsoluteLink = (link) => {
  const regex = /^(?!.*:\/\/).*/;
  if (regex.test(link)) {
    return 'https://'+ link;
  }
  return link;
}

export const isPWAInstalled = () => {
  return window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone === true;
}

export const GAME_ASPECT_RATIO = 16/10;

export default class AppUtils {

  static getHashFromUrl(url) {
    url = url.replace('#/', '/');
    const splits = url.split('#');
    if (splits && splits.length > 1) {
      return splits[1];
    }
    return null;
  }

  static isEmailValid(email) {
    if (email && email.trim().length > 0) {
      let reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (reg.test(email.trim()) === true) {
        return true;
      }
    }
    return false;
  }

  static isPasswordValid(password) {
    return password && password.trim().length >= 5 && password.trim().length <= 20;
  }

  static isUsernameValid(username) {
    if (username && username.trim().length > 2 && username.trim().length <= 20) {
      let reg = /^[a-zA-Z0-9_]+$/;
      if (reg.test(username.trim()) === true) {
        return true;
      }
    }
    return false;
  }

  static getBottomSpace() {
    return 0;
  }

  static getStatusBarHeight() {
    return 0;
  }

  static isArabic() {
    return false;
  }

  static isHavingArabicText(text) {
    const arabic = /[\u0600-\u06FF]/;
    return arabic.test(text);
  }

  static isAndroid() {
    return false;
  }

  static isIos() {
    return false;
  }

  static getDeviceDimensions() {
    const GAME_ASPECT_RATIO = 16/8;
    const GAME_MIN_WIDTH = 320;
    const GAME_MIN_HEIGHT = 568;
    let width = window.innerWidth;
    let height = window.innerHeight;
    let maxWidth = window.innerWidth;
    let maxHeight = window.innerHeight;
    let possibleMaxHeight = 2160;
    if (height > width) {
      possibleMaxHeight = 3840;
    }
    maxHeight = Math.min(maxHeight, possibleMaxHeight);
    // height = Math.min(height, possibleMaxHeight);
    maxWidth = Math.min(maxWidth, maxHeight/GAME_ASPECT_RATIO);
    // width = Math.min(width, height/GAME_ASPECT_RATIO);
    height = Math.min(height, maxHeight);
    height = Math.max(height, GAME_MIN_HEIGHT);
    width = Math.min(width, maxWidth);
    width = Math.max(width, GAME_MIN_WIDTH);
    // if (!isXS) {
    //   height -= SpaceNormal * 2;
    //   width -= SpaceNormal * 2;
    // }
    return {width: width, height: height};
  }

  static getDeviceWidth() {
    return Math.floor(AppUtils.getDeviceDimensions().width);
  }

  static getDeviceHeight() {
    return Math.floor(AppUtils.getDeviceDimensions().height);
  }

  static sharePost(post) {
    let message;
    const title = post.title || locString('media');
    if (post.type === POST_TYPE_REVIEWS) {
      message = locString('reviewShareMessage', {
        title: title,
        businessName: post.business.name,
        url: '${APP_URI}/r/' + post.id
      });
    } else if (post.type === POST_TYPE_SELL_ITEM) {
      message = locString('shareUsedItemMessage', {
        title: title,
        url: '${APP_URI}/r/' + post.id
      });
    } else if (post.type === POST_TYPE_DEMAND) {
      message = locString('shareDemandPostMessage', {
        title: title,
        url: '${APP_URI}/r/' + post.id
      });
    } else if (post.type === POST_TYPE_JOB) {
      message = locString('shareJobPostMessage', {
        title: title,
        url: '${APP_URI}/r/' + post.id
      });
    } else if (post.type === POST_TYPE_RESUME) {
      message = locString('shareResumePostMessage', {
        title: title,
        url: '${APP_URI}/r/' + post.id
      });
    } else if (post.type === POST_TYPE_QUESTION) {
      message = locString('shareQuestionPostMessage', {
        title: title,
        url: '${APP_URI}/r/' + post.id
      });
    } else if (post.type === POST_TYPE_TRAVEL) {
      message = locString('shareTripPostMessage', {
        from: post.locality.name,
        to: post.locality1.name,
        url: '${APP_URI}/r/' + post.id
      });
    } else if (post.type === POST_TYPE_SHIPMENT) {
      message = locString('sharePackagePostMessage', {
        from: post.locality.name,
        to: post.locality1.name,
        url: '${APP_URI}/r/' + post.id
      });
    } else {
      message = locString('sharePostMessage', {
        title: title,
        url: '${APP_URI}/r/' + post.id
      });
    }
    AppUtils.share(message);
  }

  static sharePlace(place) {
    const message = locString('sharePlaceMessage', {
      title: place.name,
      url: '${APP_URI}/pl/' + place.id
    });
    AppUtils.share(message);
  }

  static shareActivityPost(post) {
    const url = `${APP_URI}/cr/${post.id}`;
    const title = post.title || locString('media');
    let message;
    if (post.activity.type === ACTIVITY_TYPE_CHALLENGE) {
      message = locString('challengePostShareMessage', {title: title, campaign: post.activity.title, url: url});
    } else if (post.activity.type === ACTIVITY_TYPE_CONTENT_WRITING) {
      message = locString('socialContentPostShareMessage', {title: title, campaign: post.activity.title, url: url});
    } else {
      message = locString('activityReviewShareMessage', {title: title, campaign: post.activity.title, businessName: post.business.name, url: url});
    }
    AppUtils.share(message);
  }

  static shareActivityPosts(activity, post = null) {
    let url;
    if (post && post.id) {
      url = `${APP_URI}/crs/${activity.sId}?p=${post.id}`;
    } else {
      url = `${APP_URI}/crs/${activity.sId}`;
    }
    let activityL = null;
    let title = '';
    if (post) {
      activityL = post.activity;
      title = post.title || locString('media');
    } else {
      activityL = activity;
    }
    let message;
    if (activityL.type === ACTIVITY_TYPE_CHALLENGE) {
      message = locString('challengePostShareMessage', {title: title, campaign: activityL.title, url: url});
    } else if (activityL.type === ACTIVITY_TYPE_CONTENT_WRITING) {
      message = locString('socialContentPostShareMessage', {title: title, campaign: activityL.title, url: url});
    } else {
      message = locString('activityReviewShareMessage', {title: title, campaign: activityL.title, businessName: post.business.name, url: url});
    }
    AppUtils.share(message);
  }

  static shareActivity(activity, context, shareUrl, fallback) {
    let messageKey = null;
    let value1 = null;
    if (activity.type === ACTIVITY_TYPE_OFFER || activity.type === ACTIVITY_TYPE_DISCOUNT) {
      messageKey = 'offerShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_QUESTION) {
      messageKey = 'activityQuestionShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_QUESTIONNAIRE) {
      messageKey = 'activityQuestionnaireShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_SURVEY) {
      messageKey = 'activitySurveyShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_OPINION || activity.type === ACTIVITY_TYPE_VISITOR_OPINION) {
      messageKey = 'activityOpinionShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_CHALLENGE) {
      messageKey = 'challengeShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_EVENT) {
      messageKey = 'eventShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_PREDICTION) {
      messageKey = 'guessShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_GUESS) {
      messageKey = 'guessImageShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_IDENTIFY_LOCATION) {
      messageKey = 'identifyLocationShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_COLLECT_STARS) {
      messageKey = 'activityCollectStarsShareMessage';
      value1 = activity.value1Placeholder ? activity.value1Placeholder : locString('stars');
    } else if (activity.type === ACTIVITY_TYPE_CONTENT_WRITING) {
      messageKey = 'activitySocialContentShareMessage';
    } else if (activity.type === ACTIVITY_TYPE_SLOT
      || activity.type === ACTIVITY_TYPE_GREEDY_SPIN
      || activity.type === ACTIVITY_TYPE_BETTING_PIC
      || activity.type === ACTIVITY_TYPE_GAME_ROULETTE
      || activity.type === ACTIVITY_TYPE_SPIN_DISCOVER
      || activity.type === ACTIVITY_TYPE_FLIP_CARD
      || activity.type === ACTIVITY_TYPE_FIND_HIDDEN
      || activity.type === ACTIVITY_TYPE_MATCHING_PIC
      || activity.type === ACTIVITY_TYPE_TIC_TAC_TOE
      || activity.type === ACTIVITY_TYPE_MATCH_THREE
      || activity.type === ACTIVITY_TYPE_PUZZLE
      || activity.type === ACTIVITY_TYPE_PUZZLE_SLIDE
      || activity.type === ACTIVITY_TYPE_WHAC_A_MOLE
      || activity.type === ACTIVITY_TYPE_BRAND_RACE
      || activity.type === ACTIVITY_TYPE_POKER
    ) {
      messageKey = 'gameShareMessage';
    } else {
      messageKey = 'activityShareMessage';
    }
    const message = locString(messageKey, {title: activity.title, url: shareUrl, value1: value1}, context);
    AppUtils.shareData({text: message}, fallback);
  }

  static shareNLikeActivity(activity, context, shareUrl, fallback) {
    const value1 = activity.value1Placeholder ? activity.value1Placeholder : locString('likes');
    const message = locString('shareNLikeActivityMessage', {title: activity.title, url: shareUrl, value1: value1}, context);
    AppUtils.shareData({text: message}, fallback);
  }

  static shareBusiness(business) {
    const message = locString('shareBusiness', {title: business.name, url: '${APP_URI}/b/'+ business.username});
    AppUtils.share(message);
  }

  static shareBusinessProducts(business) {
    const name = business ? business.name : "";
    const endpoint = business.businessDeliveryType === STORE_TYPE_NORMAL ? 'dps/' : 'ps/';
    const message = locString('shareBusinessProducts', {title: name, url: '${APP_URI}/'+ endpoint + business.id});
    AppUtils.share(message);
  }

  static shareBusinessProduct(business, product) {
    const endpoint = '${APP_URI}/dp/';
    const message = locString('shareBusinessProduct', {productTitle: product.name, title: business.name, url: endpoint + product.id +'?b='+ business.id});
    AppUtils.share(message);
  }

  static inviteAndEarnApp(rab7Number) {
    const message = locString('referralShareMessage', {rab7Number: rab7Number, url: 'https://rab7.com/m'});
    AppUtils.share(message);
  }

  static shareApp() {
    const message = locString('appShareMessage', {url: 'https://rab7.com/m'});
    AppUtils.share(message);
  }

  static shareAffiliate(rab7Number, activityId = null) {
    let message;
    if (activityId) {
      message = locString('affiliateShareMessage', {url: `${APP_URI}/a/${activityId}?aid=${rab7Number}`});
    } else {
      message = locString('affiliateShareMessage', {url: '${APP_URI}/sa?aid='+ rab7Number});
    }
    AppUtils.share(message);
  }

  static share(message, fallback) {
    if (!navigator.share) {
      setTimeout(() => {
        logDebug('ShareUI Not Present');
        copyTextToClipboard(message);
        if (fallback) {
          fallback();
        }
      });
      return;
    }

    navigator.share({
      text: message
    }).catch(function(error) {
      logDebug('ShareUI Error', error);
    });
  }

  static shareData(data, fallback) {
    if (!navigator.share) {
      setTimeout(() => {
        logDebug('ShareUI Not Present');
        copyTextToClipboard(data.text);
        if (fallback) {
          fallback();
        }
      });
      return;
    }

    navigator.share(data).catch(function(error) {
      logDebug('ShareUI Error', error);
    });
  }

  static getExcludedActivityTypes() {
    return [
      'com.apple.UIKit.activity.AirDrop',
      'com.apple.UIKit.activity.Print',
      'com.apple.UIKit.activity.AssignToContact',
      'com.apple.UIKit.activity.SaveToCameraRoll',
      'com.apple.UIKit.activity.AddToReadingList',
      'com.apple.UIKit.activity.PostToFlickr',
      'com.apple.UIKit.activity.PostToVimeo',
    ];
  }

  static getDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = AppUtils.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = AppUtils.deg2rad(lon2-lon1);
    var a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(AppUtils.deg2rad(lat1)) * Math.cos(AppUtils.deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c; // Distance in km
    return d;
  }

  static deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  static getDeltaFromDistance(distance) {
    return 0.0001 / 0.008 * distance;
  }

  static implodeObjects(glueWith, array) {
    const ids = AppUtils.getArrayOfIds(array);
    return AppUtils.implode(glueWith, ids);
  }

  static getArrayOfIds(array) {
    let ids = null;
    if (array && array.length > 0) {
      ids = [];
      array.forEach((value) => {
        ids.push(value.id);
      });
    }
    return ids;
  }

  static clone(object) {
    if (!object) {
      return null;
    }
    const newObject = {};
    for (const [key, val] of Object.entries(object)) {
      newObject[key] = val;
    }
    return newObject;
  }

  static areArraysEqual(array1, array2) {
    const effArray1 = array1 && array1.length > 0 ? array1 : null;
    const effArray2 = array2 && array2.length > 0 ? array2 : null;
    if (!effArray1 && !effArray2) {
      return true;
    }
    if ((effArray1 && !effArray2) || (!effArray1 && effArray2)) {
      return false;
    }

    const array1Length = effArray1 ? effArray1.length : 0;
    const array2Length = effArray2 ? effArray2.length : 0;
    if (array1Length !== array2Length) {
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] != array2[i]) {
        return false;
      }
    }
    return true;
  }

  static areArrayObjectsEqual(array1, array2) {
    const effArray1 = array1 && array1.length > 0 ? array1 : null;
    const effArray2 = array2 && array2.length > 0 ? array2 : null;
    if (!effArray1 && !effArray2) {
      return true;
    }
    if ((effArray1 && !effArray2) || (!effArray1 && effArray2)) {
      return false;
    }

    const array1Length = effArray1 ? effArray1.length : 0;
    const array2Length = effArray2 ? effArray2.length : 0;
    if (array1Length !== array2Length) {
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      if (array1[i].id != array2[i].id) {
        return false;
      }
    }
    return true;
  }

  static areObjectsEqual(object1, object2) {
    if (!object1 && !object2) {
      return true;
    }
    if ((object1 && !object2) || (!object1 && object2)) {
      return false;
    }
    if (object1.id == object2.id) {
      return true;
    }
    return false;
  }

  static implode(glueWith, array) {
    let outString = null;
    if (array && array.length > 0) {
      outString = '';
      let count = 0;
      array.forEach((item) => {
        if (count > 0) {
          outString += glueWith;
        }
        outString += item;
        count++;
      });
    }
    return outString;
  }

  static getSizeFactor(width) {
    const widthDesign = 400;
    const effWidth = width || AppUtils.getDeviceWidth();
    return Math.min(2, effWidth / widthDesign);
  }

  static getSizeVFactor(height) {
    let heightFactor = 1;
    const thHeight = 700;
    const effHeight = height || AppUtils.getDeviceHeight();
    if (effHeight < thHeight) {
      heightFactor = 0.5;
    }
    return Math.min(1.5, thHeight / 840 * heightFactor);
  }

  static isWindowSmallHeight() {
    return AppUtils.getDeviceHeight() < 700;
  }

  static colorWithAlpha(color, alpha = 1) {
    if (color.startsWith('#')) {
      color = this.hexToRgb(color);
      return 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + alpha + ')';
    } else if (color.startsWith('rgba')) {
      const splits = color.replace('(rgb', '').replace(')', '').split(',');
      return 'rgba('+ splits[0] +','+ splits[1] +','+ splits[2] +','+ alpha +')';
    }
    return color.replace(')', ','+ alpha +')').replace('rgb', 'rgba');
  }

  static darkerColor(color) {
    if (color.startsWith('#')) {
      color = this.hexToRgb(color);
      return 'rgb(' + Math.floor(color.r * .9) + ',' + Math.floor(color.g, .9) + ',' + Math.floor(color.b, .9) + ')';
    } else if (color.startsWith('rgba')) {
      const splits = color.replace('(rgb', '').replace(')', '').split(',');
      return 'rgba('+ Math.floor(splits[0], .9) +','+ Math.floor(splits[1], .9) +','+ Math.floor(splits[2], .9) +','+ splits[3] +')';
    }
    const splits = color.replace('(rgb', '').replace(')', '').split(',');
    return 'rgb('+ Math.floor(splits[0], .9) +','+ Math.floor(splits[1], .9) +','+ Math.floor(splits[2], .9) +')';
  }

  static hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  static shuffleArray(items) {
    let currentIndex = items.length, randomIndex;
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [items[currentIndex], items[randomIndex]] = [items[randomIndex], items[currentIndex]];
    }
    return items;
  }

  static slideForwardArray(items, position) {
    const spliced = items.splice(0, position);
    spliced.forEach((item) => {
      items.push(item);
    });
    return items;
  }

  static slideReverseArray(items, position) {
    for (let i = 0; i < position; i++) {
      const spliced = items.splice(items.length - 1, 1);
      items.splice(0, 0, spliced[0]);
    }
    return items;
  }

  static getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  static changeNavColor(color = ColorBackground) {
    document.querySelector("meta[name='theme-color']").setAttribute("content", ""+ color);
  }

  static diffObjects(o1, o2) {
    return Object.keys(o2).reduce((diff, key) => {
      if (o1[key] === o2[key]) return diff
      return {
        ...diff,
        [key]: o2[key]
      }
    }, {})
  }
}



