import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {baseStyles, BORDER_RADIUS, FIELD_SPACE, FIELD_SPACE_XS, withMultipleStyles} from "src/styles/Styles";
import {Button, FormControl, Typography} from "@material-ui/core";
import {postActivityPost} from "../../actions/funActions";
import {FontWeightMedium} from "src/res/FontWeight";
import TextField from "@material-ui/core/TextField";
import {GetImageActionButton} from "../../utils/ActivityHelper";
import SingleFilePicker, {
  ACCEPTED_AUDIO_MIMES,
  ACCEPTED_IMAGE_MIMES,
  ACCEPTED_VIDEO_MIMES,
  FILE_TYPE_AUDIO,
  FILE_TYPE_IMAGE,
  FILE_TYPE_VIDEO,
  IMAGE_MAX_WIDTH_HEIGHT
} from "../commons/SingleFilePicker";
import {ColorActivityForm, ColorNegative} from "src/styles/Colors";
import FileHelper from "../../utils/FileHelper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {ImageIcon, locString, SNACKBAR_AUTO_HIDE_DURATION} from "../commons/ViewHelper";
import {ACTIVITY_UPDATE_STATE_ACTION} from "../../constants/ApiConstants";
import constants from "../../constants";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SectionHeaderView from "src/js/components/commons/SectionHeaderView";
import FieldSpace from "src/js/components/commons/FieldSpace";
import FormHelperText from "@material-ui/core/FormHelperText";
import {SpaceNormal, SpaceSmall} from "src/res/Spacing";
import Space from "src/js/components/commons/Space";
import {withStyles} from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import TextView from "src/js/components/commons/TextView";
import {getErrorFromResponse} from "src/js/utils/ApiHelper";
import ProgressLoaderBig from "src/js/components/commons/ProgressLoaderBig";

const useStyles = () => ({
   mediaCustomise: {
    borderRadius: BORDER_RADIUS,
    overflow: 'hidden',
    padding: 6,
    paddingTop: 2,
    border: '1px solid rgba(0,0,0,0.03)',
    backgroundColor: ColorActivityForm,
  },
  ratingPlaceholder: {
    align: 'center',
    color: 'rgb(247,137,20)',
  },
  ratingError: {
    textAlign: 'center',
    color: ColorNegative,
  }
});

const OpinionRating = withStyles({
  iconFilled: {
    color: 'rgb(247,137,20)',
  },
  iconHover: {
    color: 'rgb(247,137,20)',
  },
  fontSize: '3rem',
})(Rating);

class ActivityAddPost extends React.Component {

  constructor(props) {
    super(props);
    const {activity, inPreview} = props;
    this.maxAudioDurationD = 60;
    this.videoFromGallery = true;
    this.maxVideoDurationD = 60;
    this.maxVideoBitRateD = 5*1024*1024;
    this.maxVideoSizeD = 100*1024*1024;
    if (activity.mediaConfigs) {
      this.maxAudioDurationD = activity.mediaConfigs.maxAudioDuration;
      this.videoFromGallery = activity.mediaConfigs.videoFromGallery;
      this.maxVideoDurationD = activity.mediaConfigs.maxVideoDuration;
      this.maxVideoBitRateD = activity.mediaConfigs.maxVideoBitRate;
      this.maxVideoSizeD = activity.mediaConfigs.maxVideoSize;
    }

    this.state = {
      rating: 3,
      title: '',
      mediaUrl: null,
      mediaFiles: null,
      videoUrl: null,
      videoFiles: null,
      audioUrl: null,
      audioFiles: null,
      imageUrl: null,
      imageFiles: null,
      isAnonymous: false,
      contactEmail: false,
      isBlockedLoading: false,
      loadingProgress: 0,
      showValidationMsg: false,
      errorMsg: null,
    };
  }

  processMeta() {
    const {activity} = this.props;
    this.canRate = activity.contentTypes && activity.contentTypes.includes(5);
    this.canText = activity.contentTypes && activity.contentTypes.includes(1);
    this.canAddImage = false;
    this.canAddVideo = false;
    this.canAddAudio = false;
    this.mediaTypes = null;
    if (activity.type === constants.activity.types.CHALLENGE || activity.type === constants.activity.types.SOCIAL_CONTENT) {
      this.mediaTypes = {...ACCEPTED_VIDEO_MIMES, ...ACCEPTED_IMAGE_MIMES};
      this.canAddImage = true;
      this.canAddVideo = true;
    } else if (activity.contentTypes) {
      if (activity.contentTypes.includes(2)) {
        this.mediaTypes = {...ACCEPTED_IMAGE_MIMES};
        this.canAddImage = true;
      }
      if (activity.contentTypes.includes(3)) {
        this.mediaTypes = this.mediaTypes ? {...this.mediaTypes, ...ACCEPTED_AUDIO_MIMES} : {...ACCEPTED_AUDIO_MIMES};
        this.canAddAudio = true;
      }
      if (activity.contentTypes.includes(4)) {
        this.mediaTypes = this.mediaTypes ? {...this.mediaTypes, ...ACCEPTED_VIDEO_MIMES} : {...ACCEPTED_VIDEO_MIMES};
        this.canAddVideo = true;
      }
    }
  }

  render() {
    const {classes, uiConfigs, maxWidth, isXS, inPreview, activity} = this.props;
    const {
      rating,
      mediaFiles,
      title,
      isAnonymous,
      contactEmail,
      showValidationMsg,
      errorMsg,
      isBlockedLoading,
      loadingProgress
    } = this.state;
    this.processMeta();

    const sizeFactor = uiConfigs.sizeFactor;
    const paddingSide = Math.max(0, uiConfigs.infoViewContentHorizontalMargin * sizeFactor - SpaceNormal);
    let buttonWidth = (maxWidth - 2 * paddingSide) / 2 - SpaceSmall;
    buttonWidth = Math.max(150, buttonWidth) / sizeFactor;

    const actionButton = (title, onClick, isNegative, width) => {
      if (uiConfigs.otherActionImage) {
        return (
          <GetImageActionButton
            uiConfigs={uiConfigs}
            sizeFactor={sizeFactor}
            onClick={onClick}
            title={title}
            width={width}
          />
        );
      } else {
        return (
          <Button
            fullWidth
            disableElevation={true}
            size={'large'}
            variant={isNegative ? 'outlined' : 'contained'}
            color={isNegative ? 'secondary' : 'primary'}
            onClick={onClick}>
            {title}
          </Button>
        );
      }
    };

    const isOpinion = activity.type === constants.activity.types.VISITOR_OPINION;
    const isDisplayedNonDialog = activity.type === constants.activity.types.VISITOR_OPINION;
    const isMediaError = this.mediaTypes && !mediaFiles;
    let mediaError = false;
    if (showValidationMsg && isMediaError) {
      mediaError = this.context.intl.formatMessage({id: 'activity.mediaRequiredAdd'});
      let mediaCount = 0;
      if (this.canAddVideo) {
        mediaError += this.context.intl.formatMessage({id: 'media.video'});
        mediaCount++;
      }
      if (this.canAddAudio) {
        if (mediaCount > 0) {
          mediaError += this.context.intl.formatMessage({id: 'generic.slash'});
        }
        mediaError += this.context.intl.formatMessage({id: 'media.audio'});
        mediaCount++;
      }
      if (this.canAddImage) {
        if (mediaCount > 0) {
          mediaError += this.context.intl.formatMessage({id: 'generic.slash'});
        }
        mediaError += this.context.intl.formatMessage({id: 'media.image'});
      }
    }
    const isTitleError = isOpinion ? !title : null;
    const textError = showValidationMsg && isTitleError ? locString('activity.feedbackRequired') : null;
    const ratingError = showValidationMsg && this.canRate && !rating ? locString('activity.ratingRequired') : null;

    let viewWidth = maxWidth - 2 * paddingSide - 2 * SpaceNormal;
    if (!isDisplayedNonDialog && !isXS) {
      viewWidth -= 2 * SpaceNormal;
    }
    const showCoverImage = false;
    // const showCoverImage = mediaFiles && mediaFiles.length > 0 && mediaFiles[0].type === FILE_TYPE_AUDIO;
    let mediaWidth = viewWidth;
    if (showCoverImage) {
      mediaWidth = mediaWidth - 150 - (isXS ? FIELD_SPACE_XS : FIELD_SPACE);
    }
    const textDefHeight = isOpinion ? 2 : 3;
    return (
      <div className={classes.viewColumnFullWidthNoCenter1} style={{paddingLeft: SpaceNormal, paddingRight: SpaceNormal}}>
        <Space horizontalSpace={2}/>
        {this.canRate ?
          <React.Fragment>
            <div className={classes.viewRowFullWidth}>
              <div className={classes.flex1}/>
              {activity.value1Placeholder ? <TextView className={classes.ratingPlaceholder}>{activity.value1Placeholder}</TextView> : null}
              <Space verticalSpace={1}/>
              <OpinionRating
                style={{fontSize: inPreview ? '1.5rem' : '2.2rem'}}
                size={'large'}
                id={'rating'}
                name={'rating'}
                defaultValue={rating}
                onChange={this.onChangeRate}/>
              <Space verticalSpace={1}/>
              {activity.value2Placeholder ? <TextView className={classes.ratingPlaceholder}>{activity.value2Placeholder}</TextView> : null}
              <div className={classes.flex1}/>
            </div>
            {ratingError ? <FormHelperText className={classes.ratingError}>{ratingError}</FormHelperText>
              : null
            }
            <FieldSpace multiplier={1.5 - (inPreview ? 0.5 : 0)}/>
          </React.Fragment>
          : null
        }

        {this.mediaTypes ?
          <React.Fragment>
            <div className={classes.viewRowFullWidthNoCenter}>
              <div className={classes.viewColumn}>
                <SingleFilePicker
                  width={mediaWidth}
                  height={isXS ? 60 : 80}
                  acceptedFiles={this.mediaTypes}
                  maxWidthOrHeight={IMAGE_MAX_WIDTH_HEIGHT}
                  maxDurationMimes={{audio: this.maxAudioDurationD, video: this.maxVideoDurationD}}
                  maxSizeMimes={{audio: 2*1024*1024, image: 20*1024*1024, video: 200*1024*1024}}
                  aspectRatioMimes={{image: 1, video: 9/16}}
                  pickTitle={<FormattedMessage id={'campaign.uploadMedia'}/>}
                  aspectRatio={1}
                  initialFiles={null}
                  onChange={(files) => {
                    this.onChangeFiles(files, 'mediaUrl');
                  }}
                  onDelete={(file) => {
                    this.onDeleteFile(file, 'mediaUrl');
                  }}
                />
                {mediaError ? <FormHelperText className={classes.helperErrorPadding}>{mediaError}</FormHelperText>
                  : !inPreview ? <FormHelperText className={classes.helper}>{<FormattedMessage id={'activity.postVideoSizeDuration'} values={{value1: this.maxVideoDurationD, value2: 100}}/>}</FormHelperText> : null
                }
              </div>

              {showCoverImage ?
                <React.Fragment>
                  <FieldSpace vertical={true}/>
                  <div className={classes.viewColumn}>
                    <SectionHeaderView labelId={'activity.coverImage'} />
                    <SingleFilePicker
                      width={150}
                      height={isXS ? 60 : 80}
                      filesLimit={1}
                      acceptedFiles={ACCEPTED_IMAGE_MIMES}
                      aspectRatioMimes={{image: 1, video: 9/16}}
                      aspectRatio={1}
                      maxFileSize={20*1024*1024}
                      maxWidthOrHeight={IMAGE_MAX_WIDTH_HEIGHT}
                      initialFiles={null}
                      onError={this.onErrorFiles}
                      onChange={(files) => {
                        this.onChangeFiles(files, 'imageUrl');
                      }}
                      onDelete={(file) => {
                        this.onDeleteFile(file, 'imageUrl');
                      }}
                    />
                  </div>
                </React.Fragment>
                : null
              }
            </div>
            <FieldSpace multiplier={1.5 - (inPreview ? 0.5 : 0)}/>
          </React.Fragment>
          : null
        }

        {this.canText ?
          <React.Fragment>
            <TextField
              variant="filled"
              fullWidth
              multiline
              rows={isXS ? textDefHeight-1 : textDefHeight}
              rowsMax={isXS ? 5 : 6}
              maxRows={isXS ? 5 : 6}
              size="small"
              type="text"
              id="title"
              name="title"
              value={title}
              label={<FormattedMessage id={activity.type === constants.activity.types.VISITOR_OPINION ? 'activity.commentsHere' : 'activity.reelTitle'}/>}
              InputProps={{disableUnderline: true}}
              inputProps={{maxLength: 200}}
              onChange={this.onChange}
              error={textError}
              helperText={textError ? textError : null}
            />
            <FieldSpace multiplier={1.2 - (inPreview ? 0.5 : 0)}/>
          </React.Fragment>
          : null
        }

        {/*{activity.type !== constants.activity.types.VISITOR_OPINION ?*/}
        {/*  <React.Fragment>*/}
        {/*    <Space top={-0.5 * (isXS ? FIELD_SPACE_XS : FIELD_SPACE)}/>*/}
        {/*    <FormControl>*/}
        {/*      <FormGroup>*/}
        {/*        <FormControlLabel*/}
        {/*          style={{marginLeft: -8}}*/}
        {/*          control={*/}
        {/*            <Checkbox*/}
        {/*              size={'medium'}*/}
        {/*              icon={<ImageIcon src={require('src/images/ic_square.png')} width={20} height={20} />}*/}
        {/*              checkedIcon={<ImageIcon src={require('src/images/ic_square_check.png')} width={20} height={20} />}*/}
        {/*              checked={isAnonymous} onChange={this.onToggleCheckbox} />*/}
        {/*          }*/}
        {/*          label={<div style={{display: 'flex', flexDirection: 'column'}}>*/}
        {/*            <Typography variant="body1">*/}
        {/*              <FormattedMessage id='activity.postAnonymous' />*/}
        {/*            </Typography>*/}
        {/*          </div>}*/}
        {/*        />*/}
        {/*      </FormGroup>*/}
        {/*    </FormControl>*/}
        {/*    <FieldSpace multiplier={2 - (inPreview ? 0.5 : 0)}/>*/}
        {/*  </React.Fragment>*/}
        {/*  : <FieldSpace multiplier={0.5}/>*/}
        {/*}*/}

        {/*{activity.type === constants.activity.types.VISITOR_OPINION ?*/}
        {/*  <React.Fragment>*/}
        {/*    <Space top={-1.5 * (isXS ? FIELD_SPACE_XS : FIELD_SPACE)}/>*/}
        {/*    <FormControl>*/}
        {/*      <FormGroup>*/}
        {/*        <FormControlLabel*/}
        {/*          style={{marginLeft: -8}}*/}
        {/*          control={*/}
        {/*            <Checkbox*/}
        {/*              size={'medium'}*/}
        {/*              icon={<ImageIcon src={require('src/images/ic_square.png')} width={20} height={20} />}*/}
        {/*              checkedIcon={<ImageIcon src={require('src/images/ic_square_check.png')} width={20} height={20} />}*/}
        {/*              checked={contactEmail} onChange={this.onToggleContactEmail} />*/}
        {/*          }*/}
        {/*          label={<div style={{display: 'flex', flexDirection: 'column'}}>*/}
        {/*            <Typography variant="body1">*/}
        {/*              <FormattedMessage id='activity.shareYourEmailForContact' />*/}
        {/*            </Typography>*/}
        {/*          </div>}*/}
        {/*        />*/}
        {/*      </FormGroup>*/}
        {/*    </FormControl>*/}
        {/*    <FieldSpace multiplier={2 - (inPreview ? 0.5 : 0)}/>*/}
        {/*  </React.Fragment>*/}
        {/*  : null*/}
        {/*}*/}

        <div className={classes.viewRowFullWidth} style={{justifyContent: 'center'}}>
          {!isDisplayedNonDialog ?
            <React.Fragment>
              {actionButton(<FormattedMessage id={'generic.cancel'} />, this.cancelPost, true, buttonWidth)}
              <FieldSpace vertical={true}/>
            </React.Fragment>
            : null
          }
          {actionButton(<FormattedMessage id={'generic.submit'} />, this.postApi, false, buttonWidth)}
        </div>
        <FieldSpace/>

        {errorMsg ?
          <React.Fragment>
            <Typography align={"center"} color={"error"} variant="body1" style={{fontWeight: FontWeightMedium}}>
              {errorMsg}
            </Typography>
            <FieldSpace/>
          </React.Fragment>
          : null
        }

        {isBlockedLoading && <ProgressLoaderBig progress={loadingProgress} title={locString('activity.preparingPost')} />}
        {this.renderSnackBar()}
      </div>
    );
  }

  renderSnackBar() {
    const {showSnackbar, snackbarInfo} = this.state;
    if (showSnackbar) {
      const {snackbarMessageId, snackbarMessage, snackbarType} = snackbarInfo;
      const onClose = () => {
        this.setState({showSnackbar: false, snackbarInfo: null});
      };
      let message = snackbarMessage;
      if (!message && snackbarMessageId) {
        message = <FormattedMessage id={snackbarMessageId}/>;
      }
      return (
        <Snackbar open={message != null} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} onClose={onClose}>
          <MuiAlert severity={snackbarType || "success"} onClose={onClose} elevation={6} variant="filled">
            {message}
          </MuiAlert>
        </Snackbar>
      );
    }
  }

  showSnackbar(snackbarInfo) {
    if (snackbarInfo) {
      this.setState({showSnackbar: true, snackbarInfo});
    }
  }

  onChangeRate = (event, newValue) => {
    this.setState({[event.target.name]: Math.max(1, parseInt(newValue)), showValidationMsg: false, errorMsg: null});
  };

  onToggleCheckbox = () => {
    this.setState({
      isAnonymous: !this.state.isAnonymous,
      showValidationMsg: false
    });
  }

  onToggleContactEmail = () => {
    this.setState({
      contactEmail: !this.state.contactEmail,
      showValidationMsg: false
    });
  }

  onChange = (event) => {
    let value = event.target.value;
    if (event.target.title === 'title') {
      value = value.replace("\n", " ");
    }
    this.setState({[event.target.name]: value, showValidationMsg: false, errorMsg: null});
  };

  onErrorFiles = (error) => {
    this.showSnackbar({
      snackbarMessage: error,
      snackbarType: "error",
    });
  };

  onChangeFiles(files, id) {
    const {
      mediaUrl,
      videoUrl,
      audioUrl,
      imageUrl,
    } = this.state;

    let eFileUrl = null;
    let keyFileUrl = null;
    let keyFiles = null;
    if (id === 'videoUrl') {
      eFileUrl = videoUrl;
      keyFileUrl = 'videoUrl';
      keyFiles = 'videoFiles';
    } else if (id === 'audioUrl') {
      eFileUrl = audioUrl;
      keyFileUrl = 'audioUrl';
      keyFiles = 'audioFiles';
    } else if (id === 'imageUrl') {
      eFileUrl = imageUrl;
      keyFileUrl = 'imageUrl';
      keyFiles = 'imageFiles';
    } else if (id === 'mediaUrl') {
      eFileUrl = mediaUrl;
      keyFileUrl = 'mediaUrl';
      keyFiles = 'mediaFiles';
    }

    let lFiles = null;
    let lFileUrl = null;
    if (files && files.length > 0) {
      files.forEach((file) => {
        if (FileHelper.isFileNameSame(file, eFileUrl)) {
          lFileUrl = eFileUrl;
        } else {
          if (!lFiles) {
            lFiles = [];
          }
          lFiles.push(file);
        }
      });
    }

    this.setState({showValidationMsg: false, errorMsg: null, [keyFileUrl]: lFileUrl, [keyFiles]: lFiles});
  }

  onDeleteFile(file, id) {
    const {
      mediaUrl,
      mediaFiles,
      videoUrl,
      videoFiles,
      audioUrl,
      audioFiles,
      imageUrl,
      imageFiles,
    } = this.state;

    let eFileUrl = null;
    let eFiles = null;
    let keyFileUrl = null;
    let keyFiles = null;
    let keyIsFileDeleted = null;
    if (id === 'videoUrl') {
      eFileUrl = videoUrl;
      eFiles = videoFiles;
      keyFileUrl = 'videoUrl';
      keyFiles = 'videoFiles';
      keyIsFileDeleted = 'isVideoDeleted';
    } else if (id === 'audioUrl') {
      eFileUrl = audioUrl;
      eFiles = audioFiles;
      keyFileUrl = 'audioUrl';
      keyFiles = 'audioFiles';
      keyIsFileDeleted = 'isAudioDeleted';
    } else if (id === 'imageUrl') {
      eFileUrl = imageUrl;
      eFiles = imageFiles;
      keyFileUrl = 'imageUrl';
      keyFiles = 'imageFiles';
      keyIsFileDeleted = 'isImageDeleted';
    } else if (id === 'mediaUrl') {
      eFileUrl = mediaUrl;
      eFiles = mediaFiles;
      keyFileUrl = 'mediaUrl';
      keyFiles = 'mediaFiles';
      keyIsFileDeleted = 'isMediaDeleted';
    }

    let lFiles = [];
    let lFileUrl = eFileUrl;
    if (eFiles) {
      eFiles.forEach(lFile => {
        if (FileHelper.areFilesSame(lFile, file)) {
          lFiles.push(lFile);
        }
      });
      lFiles = eFiles.filter(x => !lFiles.includes(x));
    }
    if (FileHelper.isFileNameSame(file, eFileUrl)) {
      lFileUrl = null;
    }

    this.setState({showValidationMsg: false, errorMsg: null, [keyFileUrl]: lFileUrl, [keyFiles]: lFiles, [keyIsFileDeleted]: true});
  }

  cancelPost = () => {
    if (this.props.onDone) {
      this.props.onDone();
    }
  };

  postApi = () => {
    const {activity, postActivityPost, onDone} = this.props;
    let {rating, mediaFiles, videoFiles, audioFiles, imageFiles, title, isAnonymous, contactEmail} = this.state;
    if (mediaFiles && mediaFiles.length > 0) {
      if (mediaFiles[0].type === FILE_TYPE_VIDEO) {
        videoFiles = mediaFiles;
        imageFiles = null;
      } else if (mediaFiles[0].type === FILE_TYPE_AUDIO) {
        audioFiles = mediaFiles;
      } else if (mediaFiles[0].type === FILE_TYPE_IMAGE) {
        imageFiles = mediaFiles;
      }
    }
    const isOpinion = activity.type === constants.activity.types.VISITOR_OPINION;
    if (isOpinion) {
      if (this.canRate && !rating) {
        this.setState({showValidationMsg: true, errorMsg: null});
        return;
      }
      if (this.mediaTypes && (!videoFiles || videoFiles.length === 0) && (!audioFiles || audioFiles.length === 0) && (!imageFiles || imageFiles.length === 0)) {
        this.setState({showValidationMsg: true, errorMsg: null});
        return;
      }
      if (this.canText && (!title || title.trim().length <= 2)) {
        this.setState({showValidationMsg: true, errorMsg: null});
        return;
      }
    } else if ((!videoFiles || videoFiles.length === 0) && (!audioFiles || audioFiles.length === 0) && (!imageFiles || imageFiles.length === 0)) {
      this.setState({showValidationMsg: true, errorMsg: null});
      return;
    }
    this.setState({apiError: null});

    if (this.props.inPreview) {
      if (onDone) {
        const review = {
          activityId: activity.id,
          activity: activity,
          business: activity.business,
          businessId: activity.business.id,
          createdBy: this.props.user,
          state: 1,
          isAnonymous: isAnonymous ? 1 : 0,
          contactEmail: contactEmail ? 1 : 0,
          rating: this.canRate && rating ? rating : 1,
          title: title,
        };
        if (videoFiles && videoFiles.length > 0) {
          review.videoUrl = videoFiles[0].url;
        }
        if (audioFiles && audioFiles.length > 0) {
          review.audioUrl = audioFiles[0].url;
        }
        if (imageFiles && imageFiles.length > 0) {
          review.imageUrls = [imageFiles[0].url];
        }
        onDone({activity: activity, post: review});
      }
      return;
    }

    const data = {
      scId: this.props.showcaseId,
      id: activity.id,
      activityId: activity.id,
      title: title,
      isAnonymous: isAnonymous ? 1 : 0,
      contactEmail: contactEmail ? 1 : 0,
      state: ACTIVITY_UPDATE_STATE_ACTION,
      isCompleteReview: 1,
      rating: rating
    };
    let files = [];
    if (videoFiles && videoFiles.length > 0) {
      files.push({'id': 'videoFile1', 'file': videoFiles[0].file});
    }
    if (audioFiles && audioFiles.length > 0) {
      files.push({'id': 'audioFile', 'file': audioFiles[0].file});
    }
    if (imageFiles && imageFiles.length > 0) {
      files.push({'id': 'imageFile1', 'file': imageFiles[0].file});
    }
    files.forEach((file) => {
      data[file.id] = file.file;
    });

    this.setState({isBlockedLoading: true, errorMsg: null});
    const _this = this;
    postActivityPost(data, (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (this.state.loadingProgress !== percentCompleted) {
        this.setState({loadingProgress: percentCompleted});
      }
    }).then((response) => {
      this.setState({isBlockedLoading: false, loadingProgress: 0});
      if (onDone) {
        const review = response.data.review;
        delete response.data.review;
        onDone({activity: response.data, post: review});
      }
    }).catch((response) => {
      _this.setState({isBlockedLoading: false, errorMsg: getErrorFromResponse(response, this.context.intl), loadingProgress: 0});
    });
  };

}

ActivityAddPost.propTypes = {
  classes: PropTypes.object,
  activity: PropTypes.object,
  onDone: PropTypes.func,
  postActivityPost: PropTypes.func,
};

ActivityAddPost.contextTypes = {
  intl:PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    postActivityPost: (data, onUploadProgress) => dispatch(postActivityPost(data, onUploadProgress)),
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMultipleStyles(baseStyles, useStyles)(ActivityAddPost));
