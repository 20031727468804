import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {HeightElementMinimumAdd, SpaceNormal, SpaceSmall, SpaceXSmall,} from 'src/res/Spacing';
import {NavHeaderTitleTintColor} from 'src/res/UIConfigs';
import {
  ColorBackground,
  ColorCard,
  ColorCardXS,
  ColorClear,
  ColorListBackground,
  ColorNav,
  ColorNegative,
  ColorPlaceholder,
  ColorStatusBar,
  ColorSubTitle,
  ColorTheme,
  ColorTitle,
  ColorWhite,
} from 'src/styles/Colors';
import {FontSizeNormal, FontSizeSmall, FontSizeXSmall} from 'src/res/FontSize';
import {FontWeightMedium, FontWeightRegular} from 'src/res/FontWeight';
import {RadiusButton, RadiusView} from 'src/res/Radius';
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import AppText from "./AppText";
import utils from "../../utils/utils";
import AppImage from "./AppImage";
import AppTextWrapper from "./AppTextWrapper";
import ValueHelper from "../../utils/ValueHelper";
import TouchableOpacity from "./TouchableOpacity";
import View from "./View";
import {logDebug, logInfo} from "../../utils/AppLog";
import AppUtils from "../../utils/AppUtils";
import {Button, IconButton, LinearProgress, makeStyles} from "@material-ui/core";
import VideoPlayer from "./VideoPlayer";
import {AppIcon, IconArrowLeftLong, IconArrowRightLong, IconPause, IconPlay, IconUnmute} from "./AppIcon";
import {BORDER_RADIUS} from "src/styles/Styles";
import ApiHelper from "../../utils/ApiHelper";
import FocusHelperView from "src/js/components/commons/FocusHelperView";
import Space from "src/js/components/commons/Space";
import Typography from "@material-ui/core/Typography";
import FieldSpace from "src/js/components/commons/FieldSpace";
import TextView from "src/js/components/commons/TextView";

const useStyles = makeStyles((theme) => ({
  navBackWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 22,
    marginLeft: 12,
    marginRight: 12,
    [theme.breakpoints.only('sm')]: {
      marginLeft: 4,
      marginRight: 4,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 4,
      marginRight: 4,
    }
  },
  navBack: {
    minWidth: 44,
    minHeight: 44,
    borderRadius: 22,
  },
  navActionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 14,
  },
  navActionContainerRTL: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 14,
    paddingRight: 0,
  },
  navAction: {
    minWidth: 44,
    minHeight: 44,
    backgroundColor: ColorCard,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: ColorCardXS
    }
  },
  navActionImage: {
    minWidth: 44,
    minHeight: 44,
    backgroundColor: ColorCard,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: ColorCardXS
    },
    opacity: 1,
    '&:hover': {
      opacity: 0.8,
    }
  },
}))

export const SNACKBAR_AUTO_HIDE_DURATION = 5000;

export const NavBackActionView = (props) => {
  const {isLTR, color = ColorNav, onClick, style} = props;

  const classes = useStyles();
  return (
    <div className={classes.navBackWrapper} style={style}>
      <IconButton onClick={onClick} className={classes.navBack}>
        <AppIcon icon={isLTR ? IconArrowLeftLong : IconArrowRightLong} size={'sm'} color={color}/>
      </IconButton>
    </div>
  );
};

export const NavActionView = (props) => {
  const {isLTR, icon, color = ColorNav, onClick, style} = props;

  const classes = useStyles();
  return (
    <div className={classes.navActionContainer} style={style}>
      <IconButton onClick={onClick} className={classes.navAction}>
        <AppIcon icon={icon} size={'sm'} color={color}/>
      </IconButton>
    </div>
  );
};

export const NavActionImageView = (props) => {
  const {isLTR, image, onClick, style} = props;

  const classes = useStyles();
  return (
    <div className={classes.navActionContainer} style={style}>
      <IconButton onClick={onClick} className={classes.navActionImage}>
        <ImageIcon src={image} width={22} height={22}/>
      </IconButton>
    </div>
  );
};

export const ImageIcon = (props) => {
  const {src, width = 18, height = 'auto', style} = props;

  const classes = useStyles();
  return (
    <img src={src} width={width} height={height} style={style}/>
  );
};

// export const SVGIcon = React.memo((props) => {
//   return (
//     <Icon>
//       <img alt="edit" src={props.svg} />
//     </Icon>
//   );
// })

export const VideoPlayerView = forwardRef((props, ref) => {

  const styles = makeStyles(() => ({
    colorPrimary: {
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
    barColorPrimary: {
      backgroundColor: '#FFFFFF',
    },
    colorPrimaryI: {
      backgroundColor: ColorClear,
    },
    barColorPrimaryI: {
      backgroundColor: 'rgba(255,255,255,0.5)',
    }
  }));
  const classes = styles();

  const {videoUrl, videoPosterUrl, width, height, maxWidth, maxHeight, borderRadius} = props;

  const wasVideoPlaying = useRef(false);
  const refVideo = useRef();
  const isInFocus = useRef(false);
  const [isPlaying, setIsPlaying] = useState(props.autoplay);
  const [showIcon, setShowIcon] = useState(false);
  const [showMuteIcon, setShowMuteIcon] = useState(false);
  const [progress, setProgress] = useState(0);

  let aspectRatio = '16:9';
  let aspectRatioValue = 16/9;
  const videoInfo = ApiHelper.getInfoForVideoUrl(videoUrl);
  if (videoInfo) {
    aspectRatio = videoInfo.width +':'+ videoInfo.height;
    aspectRatioValue = videoInfo.width / videoInfo.height;
  }
  let videoWidth = 0;
  let videoHeight = 0;
  if (width > 0) {
    videoWidth = width;
    videoHeight = Math.min(videoWidth / aspectRatioValue, width);
  }
  const extraStyles = {
    width: videoWidth || '100%',
    height: videoHeight || '100%',
  };
  if (!videoWidth) {
    extraStyles.maxHeight = maxHeight;
    extraStyles.maxWidth = maxWidth;
  }

  let type = 'video/mp4';
  if (videoUrl.substring(videoUrl.lastIndexOf('.') + 1) === 'm3u8') {
    type = 'application/x-mpegURL';
  }
  const videoJsOptions = {
    width: '100%',
    height: '100%',
    style: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: BORDER_RADIUS
    },
    autoplay: props.autoplay,
    muted: true,
    controls: true,
    loop: props.loop,
    poster: videoPosterUrl,
    controlBar: {
      pictureInPictureToggle: false
    },
    sources: [
      {src: videoUrl, type: type},
    ],
    eListenerOnLoaded: () => {
      if (props.autoplay) {
        play(true);
      }
    },
    eListenerOnProgress: (progress) => {
      setProgress(progress);

    },
    eListenerOnError: (error) => {
      setProgress(1);
    },
    eListenerOnEnded: () => {
      if (props.onEnded) {
        props.onEnded();
      }
    },
    eListenerOnMuted: () => {
      setShowMuteIcon(true);
    },
  };

  useImperativeHandle(ref, () => ({
    play, pause, isInPlay
  }));

  const play = (hasInteraction) => {
    if (refVideo && refVideo.current) {
      isInFocus.current = true;
      refVideo.current.play(hasInteraction);
      if (!hasInteraction) {
        setShowMuteIcon(true);
      } else {
        setShowMuteIcon(false);
      }
      setShowIcon(false);
      setIsPlaying(true);
    }
  };

  const pause = () => {
    if (refVideo && refVideo.current) {
      isInFocus.current = false;
      refVideo.current.pause();
      setIsPlaying(false);
    }
  };

  const isInPlay = () => {
    return isPlaying;
  };

  const onPlayPauseClicked = () => {
    if (isInFocus.current) {
      if (refVideo && refVideo.current && refVideo.current.isPaused()) {
        refVideo.current.play(true);
        setTimeout(() => {
          setIsPlaying(true);
          setShowIcon(false);
        }, 300);
      } else {
        setShowIcon(true);
        setTimeout(() => {
          refVideo.current.pause();
          setShowIcon(false);
        }, 300);
        setTimeout(() => {
          setShowIcon(true);
          setIsPlaying(false);
        }, 600);
      }
    }
  }

  const onUnmuteClicked = () => {
    if (isInFocus.current) {
      if (refVideo && refVideo.current) {
        refVideo.current.unmute();
        setShowMuteIcon(false);
      }
    }
  }

  const onAppDidFocus = () => {
    if (wasVideoPlaying.current) {
      play(true);
    }
  };

  const onAppDidBlur = () => {
    wasVideoPlaying.current = isPlaying;
    pause();
  };

  const translucentColor = 'rgba(0,0,0,0.1)';
  logDebug('renderVideo');
  return <View ref={ref} style={{position: 'relative', display: 'flex', borderRadius: BORDER_RADIUS, overflow: 'hidden', width: width, height: height}}>
    <FocusHelperView
      onAppDidFocus={onAppDidFocus}
      onAppDidBlur={onAppDidBlur}
    />
    <VideoPlayer ref={refVideo} {...videoJsOptions} />

    {showIcon || showMuteIcon ?
      <TouchableOpacity onClick={showMuteIcon ? onUnmuteClicked : onPlayPauseClicked} style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        {showIcon ?
          <View style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 120, height: 120, borderRadius: 60, backgroundColor: translucentColor}}>
            <AppIcon icon={isPlaying ? IconPause : IconPlay} size={'3x'} color={ColorWhite} />
          </View>
          : null
        }
        {showMuteIcon ?
          <View style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 120, height: 120, borderRadius: 60, backgroundColor: translucentColor}}>
            <AppIcon icon={IconUnmute} size={'3x'} color={ColorWhite} />
          </View>
          : null
        }
      </TouchableOpacity>
      : null
    }
    <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, borderBottomLeftRadius: borderRadius, borderBottomRightRadius: borderRadius, overflow: 'hidden'}}>
      {progress > 0 ?
        null
        // <LinearProgress variant={'determinate'} value={progress} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>
        : <LinearProgress variant={'indeterminate'} value={progress} classes={{colorPrimary: classes.colorPrimaryI, barColorPrimary: classes.barColorPrimaryI}}/>
      }
    </div>
  </View>
});

export const ErrorView = (props) => {
  const {error} = props;
  return error && (
    <div>
      <Space top={-6}/>
      <Typography align={"center"} color={"error"} variant={"body1"}>
        <FormattedMessage id={error} />
      </Typography>
      <FieldSpace/>
    </div>
  );
}

export const ErrorViewEx = (props) => {
  const {classes, error, onRetryClicked} = props;
  return error && (
    <div className={classes.containerError}>
      <TextView align={"center"} color={ColorSubTitle} variant={"body1"} fontWeight={FontWeightMedium}>
        {error}
      </TextView>
      <FieldSpace multiplier={1.5}/>
      {onRetryClicked ?
        <Button
          disableElevation={true}
          variant={'contained'}
          color={'primary'}
          onClick={onRetryClicked}>
          <FormattedMessage id={'retry'} />
        </Button>
        : null
      }
    </div>
  );
}

export const ErrorText = (props) => {
  const {error} = props;
  return error && (
    <div>
      <Space top={-6}/>
      <Typography align={"center"} color={"error"} variant={"body1"}>
        <FormattedMessage id={error} />
      </Typography>
      <FieldSpace/>
    </div>
  );
}

export const locString = (textId, values, intl) => {
  if (intl) {
    if (values) {
      return intl.formatMessage({id: textId}, values);
    }
    return intl.formatMessage({id: textId});
  }
  return <FormattedMessage id={textId} values={values} />;
};

export const locStringA = (intl, textId, values) => {
  if (values) {
    return intl.formatMessage({id: textId}, values);
  }
  return intl.formatMessage({id: textId});
};

export const locHtmlString = (textId, values, intl) => {
  if (intl) {
    return intl.formatHTMLMessage({id: textId}, values);
  }
  return <FormattedHTMLMessage id={textId} values={values} />;
};

export default class ViewHelper {

  static isArabic() {
    return false;
  }

  static getNavigationBack(onClick, tintColor = NavHeaderTitleTintColor, bgColor = null, style = null) {
    // let icon = null;
    // if (isRTL) {
    //   icon = IconArrowRight;
    // } else {
    //   icon = IconArrowLeft;
    // }
    // return ViewHelper.getNavigationLeft(onClick, icon, tintColor, bgColor, style);
    return null;
  }

  static getNavigationLeft(onClick, icon, tintColor = NavHeaderTitleTintColor, bgColor = ColorClear, style = null) {
    // return <AppIconButton
    //   elevation={false}
    //   style={[{marginLeft: SpaceXXSmall}, style]}
    //   icon={icon}
    //   size={18}
    //   color={tintColor}
    //   bgColor={bgColor}
    //   onClick={onClick} />
    return null;
  }

  static getNavigationLeftTextThin(onClick, title, disabled = false, tintColor = NavHeaderTitleTintColor, bgColor = ColorClear, style = null) {
    // return <AppButtonEx
    //   elevation={false}
    //   disabled={disabled}
    //   style={[{marginLeft: SpaceXSmall}, style]}
    //   titleStyle={{fontWeight: FontWeightRegular}}
    //   title={title}
    //   titleColor={tintColor}
    //   color={bgColor}
    //   onClick={onClick} />
    return null;
  }

  static getNavigationLeftText(onClick, title, disabled = false, tintColor = NavHeaderTitleTintColor, bgColor = ColorClear, style = null) {
    // return <AppButtonEx
    //   elevation={false}
    //   disabled={disabled}
    //   style={[{marginLeft: SpaceXSmall}, style]}
    //   title={title}
    //   titleColor={tintColor}
    //   color={bgColor}
    //   onClick={onClick} />
    return null;
  }

  static getNavigationRight(onClick, icon, disabled = false, tintColor = NavHeaderTitleTintColor, bgColor = ColorClear, style = null) {
    // return (
    //   <AppIconButton
    //     size={17}
    //     elevation={false}
    //     disabled={disabled}
    //     style={[{marginRight: 0}, style]}
    //     icon={icon}
    //     color={tintColor}
    //     bgColor={bgColor}
    //     onClick={onClick} />
    // );
    return null;
  }

  static getNavigationRightTextThin(onClick, title, disabled = false, tintColor = NavHeaderTitleTintColor, bgColor = ColorClear, style = null) {
    // return (
    //   <AppButtonEx
    //     elevation={false}
    //     disabled={disabled}
    //     style={[{marginRight: 0}, style]}
    //     titleStyle={{fontWeight: FontWeightRegular}}
    //     title={title}
    //     titleColor={tintColor}
    //     color={bgColor}
    //     onClick={onClick} />
    // );
    return null;
  }

  static getNavigationRightText(onClick, title, disabled = false, tintColor = NavHeaderTitleTintColor, bgColor = ColorClear, style = null) {
    // return (
    //   <AppButtonEx
    //     elevation={false}
    //     disabled={disabled}
    //     style={[{marginRight: 0}, style]}
    //     title={title}
    //     titleColor={tintColor}
    //     color={bgColor}
    //     onClick={onClick} />
    // );
    return null;
  }

  static getNavigationLeftImage(onClick, image) {
    return (
      <View style={{marginLeft: 2}}>
        {/*<AppImageButtonEx*/}
        {/*  imageStyle={{width: 24, height: 24, margin: 10}}*/}
        {/*  resizeMode={'contain'}*/}
        {/*  onClick={onClick}*/}
        {/*  imageSource={image} />*/}
      </View>
    );
  }

  static getNavigationRightImage(onClick, image) {
    return (
      <View style={{marginRight: 2}}>
        {/*<AppImageButtonEx*/}
        {/*  imageStyle={{width: 24, height: 24, margin: 10}}*/}
        {/*  resizeMode={'contain'}*/}
        {/*  onClick={onClick}*/}
        {/*  imageSource={image} />*/}
      </View>
    );
  }

  static getNavigationFilter(onClick, filterCount = 0, disabled = false, tintColor = NavHeaderTitleTintColor, style = null) {
    // const opacity = disabled ? 0.3 : 1;
    // const view = <View
    //   style={[{alignItems: 'center', justifyContent: 'center', minWidth: HeightElementMinimum, minHeight: HeightElementMinimum}, style]}>
    //   <AppIcon icon={IconFilter} size={15} color={tintColor}/>
    //   {filterCount > 0 ?
    //     <View style={{
    //       position: 'absolute',
    //       top: 2,
    //       right: 6,
    //       minWidth: 16,
    //       minHeight: 16,
    //       backgroundColor: ColorNegative,
    //       borderRadius: 8,
    //       alignItems: 'center'
    //     }}>
    //       <AppTextWrapper style={{
    //         fontSize: 12,
    //         fontWeight: FontWeightMedium,
    //         color: ColorWhite
    //       }}>{filterCount}</AppTextWrapper>
    //     </View>
    //     : null
    //   }
    // </View>;
    // return (
    //   <View style={{opacity: opacity}}>
    //     {disabled ? view :
    //       <Touchable onClick={onClick}>
    //         {view}
    //       </Touchable>
    //     }
    //   </View>
    // );
    return null;
  }

  static getStatusBar(hidden = false, color = ColorStatusBar) {
    // return (
    //   <StatusBar
    //     backgroundColor={color}
    //     barStyle={color === ColorBlack ? 'light-content' : 'dark-content'}
    //     hidden={hidden}
    //     showHideTransition={'slide'} />
    // )
    return null;
  }

  static getImageView(imageUrl, onImageClicked) {
    const cardWidth = utils.getDeviceWidth() - 16;
    let imageHeight = cardWidth * 9 / 16;
    imageHeight = imageHeight > 350 ? 350 : imageHeight;
    if (imageUrl) {
      const view =
        <View style={{
          borderBottomEndRadius: 0,
          borderBottomStartRadius: 0,
          borderTopEndRadius: RadiusView,
          borderTopStartRadius: RadiusView,
          overflow: 'hidden',
          height: imageHeight}}>
          <AppImage style={{
            height: undefined,
            width: undefined,
            flex: 1,
            backgroundColor: ColorBackground}}
                    imageUrl={imageUrl}
                    resizeMode={'cover'}/>
        </View>;
      return <TouchableOpacity onClick={onImageClicked}>
        <View>
          {view}
        </View>
      </TouchableOpacity>;
    }
    return null;
  }

  static getHeadingInCountView(title) {
    return (
      <AppTextWrapper style={{
        color: ColorTheme,
        fontWeight: FontWeightRegular,
        fontSize: FontSizeXSmall}}>
        {title}
      </AppTextWrapper>
    );
  }

  static getValueInCountView(title, color = ColorTitle) {
    return (
      <AppTextWrapper style={{
        color: color,
        fontWeight: FontWeightMedium,
        fontSize: FontSizeNormal}}
                      numberOfLines={1}>
        {title}
      </AppTextWrapper>
    );
  }

  static getPlaceholderView(placeholder, isMandatory, bgColor = '#EEEEEE') {
    return (
      <View style={{flexDirection: 'row', marginBottom: -6, alignItems: 'flex-end'}}>
        <View style={{flexShrink: 1, flexDirection: 'row', backgroundColor: bgColor, paddingStart: 4, paddingEnd: 4, paddingTop: 1, paddingBottom: 7,
          borderTopStartRadius: RadiusButton, borderTopEndRadius: RadiusButton, borderBottomEndRadius: RadiusButton}}>
          <AppTextWrapper style={{color: ColorSubTitle, fontSize: FontSizeXSmall}}>
            {placeholder}
          </AppTextWrapper>
          {isMandatory ?
            <AppTextWrapper style={{color: ColorNegative, fontSize: FontSizeXSmall, marginLeft: 2}}>
              {"*"}
            </AppTextWrapper>
            : null
          }
        </View>
      </View>
    );
  }

  static getHeadingInDetailView(title) {
    return (
      <AppTextWrapper style={{
        color: ColorTheme,
        fontWeight: FontWeightMedium,
        fontSize: 15}}>
        {title}
      </AppTextWrapper>
    );
  }

  static getValueInDetailView(title, color = ColorSubTitle, uiConfigs, maxLines = 0) {
    return (
      <AppTextWrapper
        fontSizeN={uiConfigs.fontSize}
        numberOfLines={maxLines}
        style={{
          color: color,
          fontWeight: FontWeightRegular,
          fontSize: FontSizeNormal}}>
        {title}
      </AppTextWrapper>
    );
  }

  static getHintView(hint) {
    return (
      <AppTextWrapper style={{marginLeft: 0, color: ColorPlaceholder, fontSize: FontSizeXSmall, fontStyle: isArabic() ? 'normal' : 'italic'}}>
        {hint}
      </AppTextWrapper>
    );
  }

  static getSubTitleHeadingView(heading) {
    return (
      <View style={{marginLeft: SpaceNormal, marginRight: SpaceNormal, marginTop: 0, marginBottom: SpaceSmall, alignItems: 'center'}}>
        <AppTextWrapper style={{textAlign: 'center', color: NavHeaderTitleTintColor, fontSize: FontSizeSmall}}>
          {heading}
        </AppTextWrapper>
      </View>
    );
  }

  static getMoneyView(value, currencyCode = null, fontSize = FontSizeNormal, weight = FontWeightMedium, addFraction = 2) {
    if (!value) {
      value = 0;
    }
    const negativeText = value < 0 ? '(-) ' : null;
    value = Math.abs(value);
    const valueString = ValueHelper.getDisplayValue(value, false, addFraction);
    let decimalValue = '';
    let fractionValue = '';
    const splits = valueString.split('.');
    if (splits) {
      if (splits.length > 0) {
        decimalValue = splits[0];
      }
      if (splits.length > 1) {
        const fraction = splits[1];
        if (fraction) {
          fractionValue = fraction;
          addFraction -= fraction.length;
        }
      }
    }
    while (addFraction > 0) {
      fractionValue += '0';
      addFraction--;
    }
    return (
      <AppText style={{fontSize: fontSize, fontWeight: weight}}>
        {negativeText ?
          <AppText style={{fontSize: fontSize - 2, fontWeight: FontWeightMedium}}>
            {negativeText}
          </AppText>
          : null
        }
        {currencyCode ?
          <AppText>
            <AppText style={{fontSize: fontSize, fontWeight: weight}}>
              {currencyCode +' '}
            </AppText>
          </AppText>
          : null
        }
        <AppText style={{fontSize: fontSize, fontWeight: weight}}>
          {decimalValue}
        </AppText>
        {fractionValue ?
          <AppText style={{fontSize: fontSize - 2, fontWeight: weight}}>
            {'.' + fractionValue}
          </AppText>
          : null
        }
      </AppText>
    );
  }

  static getHowToButton(url, onClick) {
    if (url) {
      return ViewHelper.getNavigationRightText(onClick, locString('howTo'), false, ColorWhite, NavHeaderTitleTintColor, {
        minHeight: 32,
        marginRight: -4
      });
    }
    return null;
  }

  static getDotsView(count, selectedIndex) {
    const views = [];
    for (let i = 0; i < count; i++) {
      const size = 8;
      const view = <View style={{
        marginLeft: size / 2,
        marginRight: size / 2,
        width: size,
        height: size,
        backgroundColor: i === selectedIndex ? ColorTheme : ColorWhite,
        borderColor: ColorTheme,
        borderWidth: 1,
        borderRadius: size / 2,}}
      />;
      views.push(view);
    }
    return (
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
        {views}
      </View>
    );
  }

  static getMandatoryView() {
    return (
      <View style={{flexDirection: 'row', marginBottom: SpaceXSmall, justifyContent: 'flex-end'}}>
        <View>
          <AppTextWrapper style={{fontSize: FontSizeSmall, color: ColorNegative, fontStyle: isArabic() ? 'normal' : 'italic'}}>
            {locString('mandatoryHint')}
          </AppTextWrapper>
        </View>
      </View>
    );
  }

  static getVideoPlayButton(icon, onClick, size = 64) {
    // return (
    //   <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, alignItems: 'center', justifyContent: 'center'}}>
    //     <AppIconButton
    //       elevation={false}
    //       onClick={onClick}
    //       icon={icon}
    //       color={ColorWhite}
    //       style={{backgroundColor: 'rgba(0, 0, 0, 0.6)',
    //         width: size,
    //         height: size,
    //         borderRadius: size/2,
    //         justifyContent: 'center',
    //         alignItems: 'center'}}/>
    //   </View>
    // );
    return null;
  }

  static getVideoView(videoAsset, options, callbacks) {
    const width = options.width;
    const height = options.height;
    const autoplay = options.autoplay ? options.autoplay : false;
    const paused = options.paused ? options.paused : false;
    const muted = options.muted ? options.muted : false;
    const hideControls = options.hideControls ? options.hideControls : false;
    const hideSeekBarWhenNoControls = options.hideSeekBarWhenNoControls ? options.hideSeekBarWhenNoControls : false;
    const onClickedOut = callbacks.onClickedOut;
    const onExpandClicked = callbacks.onExpandClicked;
    const onCollapseClicked = callbacks.onCollapseClicked;
    const disableFullscreen = onExpandClicked == null;
    if (videoAsset) {
      // return (
      //   <View style={{height: height}}>
      //     <VideoPlayerView
      //       ref={(ref) => videoAsset.ref = ref}
      //       key={videoAsset.url}
      //       autoplay={autoplay}
      //       paused={paused}
      //       defaultMuted={muted}
      //       videoWidth={width}
      //       videoHeight={height}
      //       hideControls={hideControls}
      //       hideSeekBarWhenNoControls={hideSeekBarWhenNoControls}
      //       fullPlayPauseAction={options.fullPlayPauseAction}
      //       inListAutoLoad={options.inListAutoLoad}
      //       loop={options.loop}
      //       controlsTimeout={1500}
      //       audioOnly={videoAsset.isAudioOnly}
      //       asset={videoAsset}
      //       video={{uri: videoAsset.streamUrl || videoAsset.url}}
      //       poster={videoAsset.posterUrl}
      //       thumbnail={{uri: videoAsset.thumbnailUrl}}
      //       endThumbnail={{uri: videoAsset.thumbnailUrl}}
      //       duration={videoAsset.duration / 1000}
      //       onClickedOut={onClickedOut}
      //       onExpandClicked={onExpandClicked}
      //       onCollapseClicked={onCollapseClicked}
      //       disableFullscreen={disableFullscreen}
      //     />
      //   </View>
      // );
      return null;
    }
  }

  static getImageWidthHeight(horizontalSpace = 16) {
    const deviceWidth = utils.getDeviceWidth();
    const deviceHeight = utils.getDeviceHeight();
    const eDeviceHeight = deviceHeight;
    let width = 0;
    let height = 0;
    width = deviceWidth - horizontalSpace;
    height = width * 9 / 16;
    const imageMaxHeight = eDeviceHeight - 100;
    height = height > imageMaxHeight ? imageMaxHeight : height;
    return {
      width: width,
      height: height
    };
  }

  static getPickLocationView(latLngText, onPickLocationClicked) {
    // const enablePickLocation = true;
    // let touchableView = null;
    // const view = <View>
    //   <TextInput
    //     placeholder={locString('chooseLocation')}
    //     onTouchStart={onPickLocationClicked}
    //     style={[{paddingStart: 40, paddingEnd: 40, elevation: enablePickLocation ? 1 : 0}]}
    //     pointerEvents="none"
    //     editable={false}>
    //     {latLngText}
    //   </TextInput>
    //   <View style={[{
    //     width: 40,
    //     height: HeightElementMinimumAdd,
    //     position: 'absolute',
    //     right: 0,
    //     bottom: 0,
    //     justifyContent: 'center',
    //     alignItems: 'center'}]}>
    //     {/*<AppIcon*/}
    //     {/*  icon={IconMapMarked}*/}
    //     {/*  color={enablePickLocation ? ColorTitle : ColorOtherTitle}/>*/}
    //   </View>
    //   <View style={[{
    //     width: 40,
    //     height: HeightElementMinimum,
    //     position: 'absolute',
    //     left: 0,
    //     bottom: 0,
    //     justifyContent: 'center',
    //     alignItems: 'center'}]}>
    //     {/*<AppIcon*/}
    //     {/*  icon={IconLocation}*/}
    //     {/*  color={ColorSubTitle}/>*/}
    //   </View>
    // </View>;
    // if (enablePickLocation) {
    //   touchableView = <Touchable onClick={onPickLocationClicked}>{view}</Touchable>;
    // } else {
    //   touchableView = view;
    // }
    // return <View style={{marginBottom: SpaceSmall, opacity: enablePickLocation ? 1 : 0.3}}>{touchableView}</View>;
    return null;
  }

  static getBaseListItem(index, subView, onClick, lite = false) {
    let marginTop = 0;
    if (index === 0) {
      marginTop = SpaceXSmall;
    }

    return (
      <View style={{
        flex: 1,
        margin: SpaceXSmall,
        marginTop: marginTop,
        marginBottom: SpaceSmall}}>
        <View style={{backgroundColor: ColorListBackground, borderRadius: RadiusView}}>
          <TouchableOpacity onClick={onClick} style={{flex: 1}}>
            <View style={{flex: 1}}>
              {subView}
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  static getUserButtonView(user, navigation, borderColor = ColorWhite) {
    if (!user) {
      return null;
    }
    // const onClick = () => {NavigationHelper.navigate(navigation, NAVIGATION_NAME_USER_PROFILE)};
    const onClick = () => {};
    const size = 38;
    return (
      <View style={{marginLeft: SpaceXSmall, alignItems: 'center', justifyContent: 'center'}}>
        <TouchableOpacity
          onClick={onClick}
          style={{minHeight: HeightElementMinimumAdd, alignItems: 'center', justifyContent: 'center'}}>
          <AppImage
            style={{width: size, height: size, borderRadius: size/2, borderWidth: 2, borderColor: borderColor}}
            imageUrl={user.imageUrl}
            resizeMode={'cover'}
          />
        </TouchableOpacity>
      </View>
    );
  }

  static logStatePropsChange(oldState, newState, oldProps, newProps, logPrefix) {
    const ds = AppUtils.diffObjects(oldState, newState)
    // if (Object.keys(ds) > 0) {
    logDebug(logPrefix + '-stateChange', ds);
    // }
    const dp = AppUtils.diffObjects(oldProps, newProps)
    // if (Object.keys(dp) > 0) {
    logDebug(logPrefix + '-propsChange', dp);
    // }
  }

  static isPropsChange(oldProps, newProps, logPrefix) {
    const diff = AppUtils.diffObjects(oldProps, newProps)
    if (Object.keys(diff).length > 0) {
      logDebug(logPrefix + '-propsChange', diff);
      return true;
    }
    return false;
  }

  static isStateChange(oldState, newState, logPrefix) {
    const diff = AppUtils.diffObjects(oldState, newState)
    if (Object.keys(diff).length > 0) {
      logDebug(logPrefix + '-stateChange', diff);
      return true;
    }
    return false;
  }

  static onProfilerCallback = (id, phase, actualDuration, baseDuration, startTime, commitTime) => {
    logInfo('Rendered: '+ id, actualDuration);
  };
}


