import {Scene} from 'phaser';

export class GAHBoot extends Scene {

  constructor() {
    super('GAHBoot');
  }

  preload() {
    // this.load.image('background', 'media/gameArcHunter/bg.png');
  }

  create() {
    this.scene.start('GAHPreloader');
  }
}
