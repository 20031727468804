import {Scene} from 'phaser';

export class GPBoot extends Scene {

  constructor() {
    super('GPBoot');
  }

  preload() {
    // this.load.image('background', 'media/gameRoshambo/bg.png');
  }

  create() {
    this.scene.start('GPPreloader');
  }
}
