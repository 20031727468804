import * as types from '../constants/actionTypes';
import {wrapCall} from '../utils/serviceUtils';
import {api, APP_SHOWCASE} from '../utils/services';
import {isArray} from "lodash";
import auth from "../utils/auth";
import {NOTIFY_ACTIVITY_WINNER} from "../constants/actionTypes";

export const resetActivitiesError = () => {
  return {
    type: types.RESET_FUN_ACTIVITIES_ERROR,
  };
};
export const fetchCacheActivities = (showcaseId = null, activityId = null) => {
  return {
    type: types.FETCH_CACHE_FUN_ACTIVITIES,
    extra: {
      showcaseId: showcaseId,
      activityId: activityId
    }
  }
};
export const fetchOpenActivities = (geoLocation, page, perPage, showcaseId = null, activityId = null) => {
  return wrapCall(
    api.get(`/funs?page=${page}&perPage=${perPage || 20}${geoLocation ? '&latitude='+ geoLocation.latitude +'&longitude='+ geoLocation.longitude : ''}${showcaseId ? '&scId=' + showcaseId : ''}${activityId ? '&acId=' + activityId : ''}`),
    types.FETCH_FUN_ACTIVITIES,
    {
      extra: {
        showcaseId: showcaseId,
        activityId: activityId
      }
    }
  );
};
export const fetchActivities = (geoLocation, page, perPage, showcaseId = null, activityId = null) => {
  return wrapCall(
    api.get(`/user/funs?page=${page}&perPage=${perPage || 20}${geoLocation ? '&latitude='+ geoLocation.latitude +'&longitude='+ geoLocation.longitude : ''}${showcaseId ? '&scId=' + showcaseId : ''}${activityId ? '&acId=' + activityId : ''}`),
    types.FETCH_FUN_ACTIVITIES,
    {
      extra: {
        showcaseId: showcaseId,
        activityId: activityId
      }
    }
  );
};
export const fetchRewards = (geoLocation, page, perPage) => {
  return wrapCall(
    api.get(`/user/offers?page=${page}&perPage=${perPage || 20}${geoLocation ? '&latitude='+ geoLocation.latitude +'&longitude='+ geoLocation.longitude : ''}`),
    types.FETCH_FUN_ACTIVITIES,
  );
};

export const showActivityLinkCopiedSnackbar = (id) => {
  return {
    type: types.SHOW_FUN_ACTIVITY_LINK_COPIED_SNACKBAR,
    id
  };
}
export const fetchOpenActivity = (id, showcaseId, inDetail) => {
  return wrapCall(
    api.get(`/activities/${id}?${inDetail ? 'inDetail=' + inDetail : ''}${showcaseId ? '&scId=' + showcaseId : ''}`),
    types.FETCH_FUN_ACTIVITY,
    {id}
  );
};
export const fetchActivity = (id, showcaseId, blockedLoading, geoLocation, skipQuestion) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : '/user';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  return wrapCall(
    api.get(`${endpointBase}/activities/${id}?inDetail=1${skipQuestion ? '&nq=1' : ''}${showcaseId ? '&scId=' + showcaseId : ''}${geoLocation ? '&latitude='+ geoLocation.latitude +'&longitude='+ geoLocation.longitude +'&map='+ (geoLocation.inMap ? 1 : 0) : ''}${showcaseUserToken ? '&suToken='+ showcaseUserToken : ''}`),
    blockedLoading ? types.FETCH_FUN_ACTIVITY_BLOCKED_LOADING : types.FETCH_FUN_ACTIVITY,
    {id}
  );
};
export const fetchActivityReviews = (id, showcaseId, page, perPage = 10, postId = null) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : '/user';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  return wrapCall(
    api.get(`${endpointBase}/activityReviews?page=${page}&perPage=${perPage}&activityId=${id}${postId ? '&postId=' + postId : ''}${showcaseId ? '&scId=' + showcaseId : ''}${showcaseUserToken ? '&suToken='+ showcaseUserToken : ''}`),
    types.GET_ACTIVITY_REVIEWS,
    {id}
  );
};
export const fetchUserActivityReviews = (userId, page, perPage = 10, postId = null) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : '/user';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  return wrapCall(
    api.get(`${endpointBase}/activityReviews?page=${page}&perPage=${perPage}&createdById=${userId}&createdByType=1${postId ? '&postId=' + postId : ''}${showcaseUserToken ? '&suToken='+ showcaseUserToken : ''}`),
    types.GET_ACTIVITY_REVIEWS,
    {userId}
  );
};
export const fetchOpenActivityReviews = (id, showcaseId, page, perPage = 10, postId = null, activityType = null) => {
  return wrapCall(
    api.get(`/activityReviews?page=${page}&perPage=${perPage}&activityId=${id}${postId ? '&postId=' + postId : ''}${showcaseId ? '&scId=' + showcaseId : ''}${activityType ? '&activityTypes=' + activityType : ''}`),
    types.GET_ACTIVITY_REVIEWS,
    {id}
  );
};
export const makeActionInActivity = (id, data, withBlockedLoading = false) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : '/user';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  return wrapCall(
    api.post(`${endpointBase}/activities/state/${id}${showcaseUserToken ? '?suToken='+ showcaseUserToken : ''}`, data),
    withBlockedLoading ? types.MAKE_ACTION_IN_ACTIVITY_BLOCKING : types.MAKE_ACTION_IN_ACTIVITY,
  );
};
export const makePreActionInActivity = (data, withBlockedLoading) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : '/user';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  return wrapCall(
    api.post(`${endpointBase}/activities/gameData${showcaseUserToken ? '?suToken='+ showcaseUserToken : ''}`, data),
    withBlockedLoading ? types.MAKE_ACTION_IN_ACTIVITY_BLOCKING : types.MAKE_ACTION_IN_ACTIVITY,
  );
};

export const fetchOpenActivityWinners = (id, page = 1, perPage = 50) => {
  return wrapCall(
    api.get(`/activities/winners/${id}?page=${page}&perPage=${perPage}`),
    types.FETCH_FUN_WINNERS,
    {id}
  );
};
export const fetchActivityWinners = (id, forUser = true, page = 1, perPage = 50) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : forUser ? '/user' : '/userB';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  return wrapCall(
    api.get(`${endpointBase}/activities/winners/${id}?page=${page}&perPage=${perPage}${showcaseUserToken ? '&suToken='+ showcaseUserToken : ''}`),
    types.FETCH_FUN_WINNERS,
    {id}
  );
};
export const fetchActivityDummyWinners = (id, page = 1, perPage = 10) => {
  return {
    type: types.FETCH_FUN_DUMMY_WINNERS,
    id
  };
};
export const notifyActivityWinner = (id) => {
  return wrapCall(
    api.get(`/userB/activities/notifyWinners/${id}`),
    types.NOTIFY_ACTIVITY_WINNER,
    {id}
  );
};

export const fetchOpenActivityPlayerStats = (id, page = 1, perPage = 50) => {
  return wrapCall(
    api.get(`/activities/players/${id}?page=${page}&perPage=${perPage}`),
    types.FETCH_FUN_WINNERS,
    {id}
  );
};
export const fetchActivityPlayerStats = (id, page = 1, perPage = 50) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : '/user';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  return wrapCall(
    api.get(`${endpointBase}/activities/players/${id}?page=${page}&perPage=${perPage}${showcaseUserToken ? '&suToken='+ showcaseUserToken : ''}`),
    types.FETCH_FUN_WINNERS,
    {id}
  );
};
export const fetchActivityDummyPlayerStats = (id, page = 1, perPage = 10) => {
  return {
    type: types.FETCH_FUN_DUMMY_WINNERS,
    id
  };
};

export const fetchActivityAnswerDistributions = (id) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : '/user';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  return wrapCall(
    api.get(`${endpointBase}/activities/answerDistribution/${id}${showcaseUserToken ? '?suToken='+ showcaseUserToken : ''}`),
    types.FETCH_FUN_ANSWER_DISTRIBUTIONS,
    {id}
  );
};
export const fetchDummyActivityAnswerDistributions = (id, data) => {
  return {
    type: types.FETCH_DUMMY_FUN_ANSWER_DISTRIBUTIONS,
    id,
    data
  };
};

export const reportActivity = (data) => {
  const id = data.id;
  return wrapCall(
    api.post(`/user/spammedActivities`, data),
    types.ACTIVITY_REPORT,
    {id}
  );
};

export const reportActivityPost = (data) => {
  const id = data.id;
  return wrapCall(
    api.post(`/user/spammedActivityReviews`, data),
    types.ACTIVITY_POST_REPORT,
    {id}
  );
};

export const deleteActivityPost = (id) => {
  return wrapCall(
    api.delete(`/user/activityReviews/${id}`),
    types.ACTIVITY_POST_DELETE,
    {id}
  );
};

export const likeActivityPost = (data) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : '/user';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  const id = data.id;
  return wrapCall(
    api.post(`${endpointBase}/activityReviewLikes${showcaseUserToken ? '?suToken='+ showcaseUserToken : ''}`, data),
    types.ACTIVITY_POST_ACTION_BG,
    {id}
  );
};

export const previewActivityPost = (id, showcaseId) => {
  const endpointBase = APP_SHOWCASE ? '/showcaseUser' : '/user';
  const showcaseUserToken = APP_SHOWCASE ? auth.getShowcaseUserToken() : null;
  return wrapCall(
    api.post(`${endpointBase}/activityReviews/previews/${id}?${showcaseUserToken ? '&suToken='+ showcaseUserToken : ''}${showcaseId ? '&scId='+ showcaseId : ''}`, {id: id}),
    types.ACTIVITY_POST_ACTION_BG,
    {id}
  );
};

export const downloadActivityPost = (id, onDownloadProgress) => {
  return wrapCall(
    api.get(`/user/activityReviews/downloads/${id}`, null, {responseType: 'blob', onDownloadProgress: onDownloadProgress}),
    types.DOWNLOAD_ACTIVITY_REVIEW,
    {id}
  );
};

export const postActivityPost = (data, onUploadProgress) => {
  const formData = new FormData();
  for (let key in data) {
    if (isArray(data[key])) {
      const arr = data[key];
      for (var i = 0; i < arr.length; i++) {
        formData.append(`${key}[]`, arr[i]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  formData.append('token', auth.getToken());

  const url = `/user/activities/state/${data.id}`;
  return wrapCall(
    api.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}, onUploadProgress: onUploadProgress}),
    types.ACTIVITY_ADD_POST,
  );
};
