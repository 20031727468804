'use strict';
import React from 'react';

import CircularProgress from "@material-ui/core/CircularProgress";
import {Card} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {ColorTheme, ColorTitle} from "src/styles/Colors";
import {FontWeightMedium} from "src/res/FontWeight";
import Utils from "../../utils/utils";
import TextView from "src/js/components/commons/TextView";

const useStyles = (theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: Number.MAX_SAFE_INTEGER,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: 280,
    height: 280,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 8,
    opacity: 0.95,
    position: 'relative',
    padding: 20,
  },
  progressBg: {
    color: Utils.colorWithAlpha(ColorTheme, 0.2),
  },
  circle: {
    strokeLinecap: 'round',
  },
});

class ProgressLoaderBig extends React.Component {

  render() {
    const {classes, progress, title} = this.props;
    return <div className={classes.container}>
      <Card className={classes.card}>
        <div style={{position: 'absolute', top: title ? 40 : 60, bottom: 'auto', left: 20, right: 20, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress
              variant="determinate"
              className={classes.progressBg}
              size={150}
              thickness={4}
              value={100}
            />
          </div>
          <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress
              classes={{
                circle: classes.circle,
              }}
              size={150}
              thickness={4}
              variant={progress < 1 || progress >= 100 ? "indeterminate" : "determinate"}
              value={progress} />
          </div>
          {progress > 0 ?
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center">
              <Typography variant="h6" component="div" color="primary" style={{fontWeight: FontWeightMedium}}>
                {`${Math.max(1, Math.round(progress))}%`}
              </Typography>
            </Box>
            : null
          }
        </div>
        {title ? <TextView color={ColorTitle} fontWeight={FontWeightMedium} style={{marginTop: 190}}>{title}</TextView> : null}
      </Card>
    </div>
  }
}

ProgressLoaderBig.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles())(ProgressLoaderBig);
