import {ButtonBase, IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import React from "react";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {baseStyles, FONT_WEIGHT_MEDIUM, withMultipleStyles} from "src/styles/Styles";
import TextView from "src/js/components/commons/TextView";
import {ColorTitle} from "src/styles/Colors";

export const SECTION_HEADER_BOTTOM_SPACE = 10;
export const SECTION_HEADER_WITH_SUBTITLE_BOTTOM_SPACE = 8;

const SectionHeaderView = ({classes, labelId, label, subLabelId, subLabel, subLabelColor, subLabelNoOfLines = 0, noBottomSpace, marginBottom, align = 'left', colorTitle = ColorTitle}) => {
  const onClick = () => {
    // onToggle(section);
  };
  if (!subLabel && subLabelId) {
    subLabel = <FormattedHTMLMessage id={subLabelId} />;
  }
  const disabled = true; // section.expandAlways || (section.expand && section.noCollapse);
  const disabledButton = true; // section.expandAlways || (section.expand && section.noCollapse);
  const marginBottomEff = marginBottom || subLabel ? SECTION_HEADER_WITH_SUBTITLE_BOTTOM_SPACE : SECTION_HEADER_BOTTOM_SPACE;
  return (
    <div className={classes.expandCollapseBar}>
      <ButtonBase disabled={disabled} className={classes.buttonBaseApp} style={{marginBottom: noBottomSpace ? 0 : marginBottomEff}} onClick={onClick}>
        <div className={classes.flexRow}>
          <div className={classes.viewColumnFullWidthNoCenter}>
            <div className={classes.flexRow} style={{justifyContent: align === 'center' ? 'center' : 'flex-start'}}>
              <TextView variant={'body1'} color={colorTitle} align={align} fontWeight={FONT_WEIGHT_MEDIUM} >
                {labelId ?
                  <FormattedHTMLMessage id={labelId} />
                  : label
                }
              </TextView>
            </div>
            {subLabel ?
              <TextView color={subLabelColor || 'textSecondary'} variant={'caption'} align={align} className={classes.typoNormal} numberOfLines={subLabelNoOfLines}>
                {subLabel}
              </TextView>
              : null
            }
          </div>
        </div>
      </ButtonBase>
      {!disabledButton ?
        <IconButton onClick={onClick}>
          {section.expand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
        </IconButton>
        : null
      }
    </div>
  );
};

export default (withMultipleStyles(baseStyles))(SectionHeaderView);
