import {Scene} from 'phaser';
import {logDebug} from "src/js/utils/AppLog";

export class Preloader extends Scene {
  constructor() {
    super('Preloader');
  }

  init() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    //  We loaded this image in our GFKBoot Scene, so we can display it here
    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const barWidth = gameWidth - 96;
    const innerBarWidth = barWidth - 8;

    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(gameWidth / 2, gameHeight / 2, barWidth, 32).setStrokeStyle(1, 0xEEEEEE);

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle((gameWidth - innerBarWidth) / 2, gameHeight / 2, 4, 28, 0xEEEEEE);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on('progress', (progress) => {

      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + (innerBarWidth * progress);

    });
  }

  preload() {
    let bgAudioUrl = null;
    let ballImageUrl = null;
    let adImageUrl1 = null;
    let adImageUrl2 = null;
    let adImageUrl3 = null;
    const options = this.game.options;
    if (options) {
      if (options.images && options.images.length > 0) {
        options.images.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              ballImageUrl = item.url;
            } else if (item.number === 1) {
              adImageUrl1 = item.url;
            } else if (item.number === 2) {
              adImageUrl2 = item.url;
            } else if (item.number === 3) {
              adImageUrl3 = item.url;
            }
          }
        });
      }
      if (options.audios && options.audios.length > 0) {
        options.audios.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              bgAudioUrl = item.url;
            }
          }
        });
      }
    }

    this.load.setPath('media/gameTapTapShots');

    this.load.image('ball', ballImageUrl || 'ball.png');
    this.load.image('adImage1', adImageUrl1 || 'adImage1.png');
    this.load.image('adImage2', adImageUrl2 || 'adImage2.png');
    this.load.image('adImage3', adImageUrl3 || 'adImage3.png');

    this.load.image('ground', 'ground.png');
    this.load.image('hoop1', 'hoop1.png');
    this.load.image('hoop2', 'hoop2.png');
    this.load.image('hoop3', 'hoop3.png');
    this.load.image('hoop4', 'hoop4.png');
    this.load.image('hoop5', 'hoop5.png');
    this.load.image('hoop6', 'hoop6.png');
    this.load.image('hoopb1', 'hoopb1.png');
    this.load.image('hoopb2', 'hoopb2.png');
    this.load.image('hoopb3', 'hoopb3.png');
    this.load.image('hoopNet', 'hoopNet.png');

    if (!options.simulation) {
      this.load.audio('audio_game_bg', bgAudioUrl || 'audios/game_bg.m4a');
      this.load.audio('audio_jump', 'audios/jump.wav');
      this.load.audio('audio_basket', 'audios/basket.mp3');
      this.load.audio('audio_game_end', 'audios/game_end.wav');
      this.load.audio('audio_game_win', 'audios/game_win.mp3');
    }
  }

  create() {
    const renderTexture = this.make.renderTexture({x: 40, y: 40, width: 80, height: 80}, false);
    const maskShape = this.make.graphics({}, false)
      .fillCircle(40, 40, 40);
    const image = this.add.image(40, 40, 'ball')
      .setDisplaySize(80, 80)
      .setVisible(false);
    image.setMask(maskShape.createGeometryMask());
    renderTexture.draw(image, 40, 40, 1);
    renderTexture.saveTexture('ballC');
    image.destroy(true);
    renderTexture.destroy(true);

    this.scene.start('MainMenu');
    // this.scene.start('GFKGame');
  }
}
