import React from 'react';
import constants from "../constants";
import DateTimeHelper from "./DateTimeHelper";
import View from 'src/js/components/commons/View';
import ScrollView from 'src/js/components/commons/ScrollView';
import {
  ColorActivityDetail,
  ColorBackground,
  ColorBlack,
  ColorBorder,
  ColorLink,
  ColorNegative,
  ColorOrange,
  ColorOtherTitle,
  ColorPreviewBorder,
  ColorSubTitle,
  ColorTheme,
  ColorTitle,
  ColorWhite,
} from 'src/styles/Colors';
import {FontSizeNormal, FontSizeSmall} from 'src/res/FontSize';
import {FontWeightMedium, FontWeightRegular} from 'src/res/FontWeight';
import {
  ACTIVITY_TYPE_BETTING_PIC,
  ACTIVITY_TYPE_BRAND_RACE,
  ACTIVITY_TYPE_CHALLENGE,
  ACTIVITY_TYPE_COLLECT_STARS,
  ACTIVITY_TYPE_CONTENT_WRITING,
  ACTIVITY_TYPE_DISCOUNT,
  ACTIVITY_TYPE_EVENT,
  ACTIVITY_TYPE_EXTERNAL_GAMES,
  ACTIVITY_TYPE_FIND_HIDDEN,
  ACTIVITY_TYPE_FLIP_CARD,
  ACTIVITY_TYPE_GAME_ROULETTE,
  ACTIVITY_TYPE_GREEDY_SPIN,
  ACTIVITY_TYPE_GUESS,
  ACTIVITY_TYPE_IDENTIFY_LOCATION,
  ACTIVITY_TYPE_MATCH_THREE,
  ACTIVITY_TYPE_MATCHING_PIC,
  ACTIVITY_TYPE_OFFER,
  ACTIVITY_TYPE_OPINION,
  ACTIVITY_TYPE_POKER,
  ACTIVITY_TYPE_PREDICTION,
  ACTIVITY_TYPE_PUZZLE,
  ACTIVITY_TYPE_PUZZLE_SLIDE,
  ACTIVITY_TYPE_QUESTION,
  ACTIVITY_TYPE_QUESTIONNAIRE,
  ACTIVITY_TYPE_SHARE_N_LIKE, ACTIVITY_TYPE_SHOWCASE_MENU, ACTIVITY_TYPE_SLIDES,
  ACTIVITY_TYPE_SLOT,
  ACTIVITY_TYPE_SPIN_DISCOVER,
  ACTIVITY_TYPE_SURVEY,
  ACTIVITY_TYPE_TIC_TAC_TOE,
  ACTIVITY_TYPE_VISITOR_OPINION,
  ACTIVITY_TYPE_WHAC_A_MOLE,
} from 'src/js/constants/ApiConstants';
import {RadiusView, RadiusViewList} from 'src/res/Radius';
import {HeightElementMinimum, SpaceNormal, SpaceSmall, SpaceXSmall} from 'src/res/Spacing';
import {NavHeaderTitleTintColor} from 'src/res/UIConfigs';
import AppImage from "../components/commons/AppImage";
import AppText from "../components/commons/AppText";
import AppTextWrapper from "../components/commons/AppTextWrapper";
import ViewHelper, {locHtmlString, locString, locStringA, VideoPlayerView} from "../components/commons/ViewHelper";
import utils from "./utils";
import ReadMoreText from "../components/commons/ReadMoreText";
import ValueHelper from "./ValueHelper";
import {Button} from "@material-ui/core";
import TouchableOpacity from "../components/commons/TouchableOpacity";
import LinkHelper from "./LinkHelper";
import AppImageButtonEx from "../components/commons/AppImageButtonEx";
import TextView from "../components/commons/TextView";
import {FormattedMessage} from "react-intl";
import initialState from "../reducers/initialState";
import Space from "../components/commons/Space";
import LanguageSelector from "../components/commons/LanguageSelector";
import ApiHelper from "src/js/utils/ApiHelper";
import {BORDER_RADIUS} from "src/styles/Styles";
import tinycolor from "tinycolor2";

import ActivityAddPost from '../components/activityComponent/ActivityAddPost';
import ActivityAnswerDistributions from '../components/activity/ActivityAnswerDistributions';
import ActivityWinners from '../components/activity/ActivityWinners';
import ActivityReport from '../components/activity/ActivityReport';

// const ActivityAddPost = React.lazy(() => import('../components/activityComponent/ActivityAddPost'));
// const ActivityAnswerDistributions = React.lazy(() => import('../components/activity/ActivityAnswerDistributions'));
// const ActivityWinners = React.lazy(() => import('../components/activity/ActivityWinners'));
// const ActivityPlayerStats = React.lazy(() => import('../components/activity/ActivityPlayerStats'));
// const ActivityReport = React.lazy(() => import('../components/activity/ActivityReport'));


const ELEVATION = {};
const MAX_WIDTH_REWARD_VIEW = 360;
const MAX_WIDTH_WON_REWARD_VIEW = 360;

export const getCurrentPlanType = (plan) => {
  if (plan && plan.planType > 0) {
    return plan.planType;
  }
  return constants.activity.plans.NO_PLAN;
};

export const isCurrentPlanPaid = (plan) => {
  return plan && plan.planId && plan.planType === constants.activity.plans.PAID_PLAN;
};

export const isCurrentPlanNoPlan = (plan) => {
  return !plan || !plan.planId || plan.planType <= constants.activity.plans.NO_PLAN;
};

export const isCurrentPlanEnded = (plan) => {
  const planType = getCurrentPlanType(plan);
  if (planType > constants.activity.plans.NO_PLAN) {
    // const availableViews = Math.max(0, plan.planViewsCount - plan.feedDisplayedCount);
    const planEndAt = plan.planEndAt;
    const endDateDiff = DateTimeHelper.getSecondsDiffFromNow(planEndAt);
    return endDateDiff <= 0;
  }
  return false;
};

export const isCurrentPlanViewEnded = (plan) => {
  const planType = getCurrentPlanType(plan);
  if (planType > constants.activity.plans.NO_PLAN) {
    return Math.max(0, plan.planViewsCount - plan.feedDisplayedCount) === 0;
  }
  return false;
};

export const getPlanType = (plan) => {
  if (plan && plan.type > 0) {
    return plan.type;
  }
  return constants.activity.plans.NO_PLAN;
};


export const GetLanguageSelectorView = (props) => {
  const {uiConfigs} = props;
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <Space vertical={12} />
      <TextView variant={'body2'} fontWeight={FontWeightRegular} color={ColorTitle} style={{padding: 4}}>
        <FormattedMessage id='generic.language' />
      </TextView>
      <Space verticalSpace={2} />
      <LanguageSelector hideIcon={true} />
      <Space verticalSpace={1} />
    </div>
  );
};

export const GetImageView = (props) => {
  const {sizeFactor, imageUrl, onImageClicked} = props;

  const onImageClickedL = () => {
    onImageClicked(imageUrl);
  };

  return (
    <View style={{width: 40 * sizeFactor, height: 40 * sizeFactor, marginLeft: 4, marginRight: 4}}>
      <TouchableOpacity
        onClick={onImageClickedL}>
        <AppImage
          style={Object.assign({
            width: 40 * sizeFactor,
            height: 40 * sizeFactor,
            borderRadius: RadiusView
          }, ELEVATION)}
          source={imageUrl}/>
      </TouchableOpacity>
    </View>
  );
};

export const GetRewardsView = (props) => {
  const {sizeFactor, uiConfigs, inPreview, contentWidth, activity, onDismissFunction, onActivityClicked, onImageClicked, onTextLinkClicked} = props;

  // let rewardsTitleExtra = null;
  // if (activity.type === ACTIVITY_TYPE_SLOT) {
    // rewardsTitleExtra = locString('rewardsSlotExtra');
  // }

  let winRewardsViews = null;
  let winRewardId = null;
  if (activity.type === ACTIVITY_TYPE_CHALLENGE
    || activity.type === ACTIVITY_TYPE_CONTENT_WRITING
    || activity.type === ACTIVITY_TYPE_PREDICTION
    || activity.type === ACTIVITY_TYPE_GUESS
    || activity.type === ACTIVITY_TYPE_IDENTIFY_LOCATION
    || activity.type === ACTIVITY_TYPE_SLOT
    || activity.type === ACTIVITY_TYPE_GREEDY_SPIN
    || activity.type === ACTIVITY_TYPE_BETTING_PIC
    || activity.type === ACTIVITY_TYPE_GAME_ROULETTE
    || activity.type === ACTIVITY_TYPE_SPIN_DISCOVER
    || activity.type === ACTIVITY_TYPE_BRAND_RACE
    || activity.type === ACTIVITY_TYPE_SHARE_N_LIKE
    || activity.type === ACTIVITY_TYPE_COLLECT_STARS
    || activity.type === ACTIVITY_TYPE_FLIP_CARD
    || activity.type === ACTIVITY_TYPE_FIND_HIDDEN
    || activity.type === ACTIVITY_TYPE_MATCHING_PIC
    || activity.type === ACTIVITY_TYPE_TIC_TAC_TOE
    || activity.type === ACTIVITY_TYPE_POKER
    || activity.type === ACTIVITY_TYPE_MATCH_THREE
    || activity.type === ACTIVITY_TYPE_WHAC_A_MOLE
    || activity.type === ACTIVITY_TYPE_PUZZLE
    || activity.type === ACTIVITY_TYPE_PUZZLE_SLIDE
    || activity.type === ACTIVITY_TYPE_VISITOR_OPINION
    || activity.type === ACTIVITY_TYPE_QUESTION
    || activity.type === ACTIVITY_TYPE_QUESTIONNAIRE
    || activity.type === ACTIVITY_TYPE_SURVEY
    || activity.type === ACTIVITY_TYPE_EXTERNAL_GAMES) {

    if (activity.type === ACTIVITY_TYPE_CHALLENGE
      || activity.type === ACTIVITY_TYPE_CONTENT_WRITING
      || activity.type === ACTIVITY_TYPE_PREDICTION
      || activity.type === ACTIVITY_TYPE_GUESS
      || activity.type === ACTIVITY_TYPE_IDENTIFY_LOCATION
      || activity.type === ACTIVITY_TYPE_QUESTIONNAIRE
      || activity.type === ACTIVITY_TYPE_SURVEY) {
      winRewardId = 'activity.rewardRaffleTitle';
    } else {
      winRewardId = 'activity.rewardsViewTitle';
    }

    if (activity.slotValues && activity.slotValues.length > 0) {
      let slot = null;
      activity.slotValues.forEach((slotL) => {
        if (slotL.number === 0) {
          slot = slotL;
        }
      });

      if (slot) {
        const showImages = false; // activity.type === ACTIVITY_TYPE_SLOT;
        // const showMultiple = false; // activity.type === ACTIVITY_TYPE_SLOT;
        // for (let i = 0; i < activity.slotValues.length; i++) {
        //   const slot = activity.slotValues[i];
        //   if (slot.number <= 0) {
        //     continue;
        //   }
        const pointsFunsView = ActivityHelper.getPointsNFunView(uiConfigs, contentWidth, slot.points, 0, slot.offers, 0, onDismissFunction, onActivityClicked, onImageClicked);

        if (pointsFunsView != null) {
          let imagesView = null;
          if (showImages) {
            imagesView = [];
            for (let j = 0; j < activity.gameColumns; j++) {
              imagesView.push(
                <GetImageView
                  sizeFactor={sizeFactor}
                  imageUrl={slot.imageUrl}
                  onImageClicked={onImageClicked}
                />
              );
            }
          }

          if (!winRewardsViews) {
            winRewardsViews = [];
            winRewardsViews.push(
              <View style={{width: '100%'}}>
                <AppTextWrapper
                  wrapperStyle={{
                    flex: 1,
                    justifyContent: 'center',
                  }}
                  fontSizeN={uiConfigs.fontSize}
                  style={{
                    fontWeight: FontWeightMedium,
                    color: uiConfigs.overlayTitleColor,
                    textAlign: 'center',
                    marginBottom: 2
                  }}>
                  {locString(winRewardId)}
                </AppTextWrapper>
              </View>
            );
          }
          winRewardsViews.push(
            <View style={{marginBottom: 8, alignItems: 'center', width: '100%'}}>
              {imagesView != null ?
                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 16
                }}>
                  {imagesView}
                </View>
                : null
              }
              {pointsFunsView}
            </View>
          );
        }

        // if (!showMultiple) {
        //   break;
        // }
        // }
      }
    }
  }
  if (!winRewardsViews) {
    winRewardsViews = [];
    winRewardsViews.push(
      <View style={{marginBottom: 16, alignItems: 'center'}}>
        <TextView align={'center'} fontSizeN={uiConfigs.fontSize} fontWeight={FontWeightMedium} color={uiConfigs.overlayTitleColor}><FormattedMessage id={inPreview ? 'activity.noRewards' : 'activity.noRewardsProvided'} /></TextView>
      </View>
    );
  }

  const guideView = ActivityHelper.getGuideView(activity, uiConfigs, onImageClicked, onTextLinkClicked);
  const participateRewards = ActivityHelper.getParticipateRewardsView(uiConfigs, activity, contentWidth, onDismissFunction, onActivityClicked, onImageClicked);
  const referralRewards = ActivityHelper.getReferralRewardsView(activity, uiConfigs, contentWidth, onDismissFunction, onActivityClicked, onImageClicked);
  if (guideView != null || winRewardsViews != null || participateRewards != null || referralRewards != null) {
    return (
      // <ScrollView
      //   style={{marginBottom: -8}}
      //   contentContainerStyle={{paddingTop: 12, paddingBottom: 12}}>
      <View style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingTop: 8}}>
        {/*{guideView}*/}
        {/*{ActivityHelper.getOverlayContentSeparatorView(uiConfigs)}*/}
        {/*{winRewardsViews != null ?*/}
        {/*  <View style={{width: '100%'}}>*/}
        {/*    <AppTextWrapper*/}
        {/*      wrapperStyle={{*/}
        {/*        flex: 1,*/}
        {/*        justifyContent: 'center',*/}
        {/*      }}*/}
        {/*      style={{*/}
        {/*        fontWeight: FontWeightMedium,*/}
        {/*        color: uiConfigs.overlayTitleColor,*/}
        {/*        textAlign: 'center',*/}
        {/*        marginBottom: SpaceSmall*/}
        {/*      }}>*/}
        {/*      {locString('rewardsViewTitle')}*/}
        {/*    </AppTextWrapper>*/}
        {/*  </View>*/}
        {/*  : null*/}
        {/*}*/}
        {/*{winRewardsViews != null && rewardsTitleExtra ?*/}
        {/*  <AppTextWrapper*/}
        {/*    variant={'body2'}*/}
        {/*    wrapperStyle={{*/}
        {/*      flex: 1,*/}
        {/*      justifyContent: 'center',*/}
        {/*    }}*/}
        {/*    style={{*/}
        {/*      fontWeight: FontWeightMedium,*/}
        {/*      color: uiConfigs.rewardSubTitleColor,*/}
        {/*      textAlign: 'center',*/}
        {/*      marginTop: -10,*/}
        {/*      marginBottom: SpaceSmall*/}
        {/*    }}>*/}
        {/*    {rewardsTitleExtra}*/}
        {/*  </AppTextWrapper>*/}
        {/*  : null*/}
        {/*}*/}
        {winRewardsViews}
        {participateRewards != null ?
          <View style={{width: '100%'}}>
            {winRewardsViews != null ? ActivityHelper.getOverlayContentSeparatorView(uiConfigs) : null}
            {participateRewards}
          </View>
          : null
        }
        {referralRewards != null ?
          <View style={{width: '100%'}}>
            {winRewardsViews != null || participateRewards != null ? ActivityHelper.getOverlayContentSeparatorView(uiConfigs) : null}
            {referralRewards}
          </View>
          : null
        }
      </View>
      // </ScrollView>
    );
  }
  return null;
};

const GetPointsNFunView = (props) => {
  const {uiConfigs, contentWidth, storePoints, rab7Points, activities, type = 0,
    onDismissFunction, onActivityClicked, onImageClicked} = props;
  // type = 0 => showing things only
  // type = 1 => earned for action
  // type = 2 => won
  // type = 3 => won referral
  // type = 4 => sponsored
  const sizeFactor = uiConfigs.sizeFactor;
  const invokeToImage = type === 0;
  let titleColor = uiConfigs.overlayTitleColor;
  if (type === 2 || type === 3) {
    titleColor = uiConfigs.rewardTitleColor;
  }

  let wonStorePointsText = null;
  if (storePoints > 0) {
    if (type === 1) {
      wonStorePointsText = locString('earnedStorePointsWithValue', {value: storePoints});
    } else if (type === 2) {
      wonStorePointsText = locString('youWonJackpotPoints', {value: storePoints});
    } else if (type === 3) {
      wonStorePointsText = locString('youWonReferralPoints', {value: storePoints});
    } else {
      wonStorePointsText = locString('storePointsWithValue', {value: storePoints});
    }
  }
  let wonRab7PointsText = null;
  if (rab7Points > 0) {
    if (wonStorePointsText) {
      if (type === 1 || type === 3) {
        wonRab7PointsText = locString('earnedERab7PointsWithValue', {value: rab7Points});
      } else {
        wonRab7PointsText = locString('andRab7PointsWithValue', {value: rab7Points});
      }
    } else {
      if (type === 1 || type === 3) {
        wonRab7PointsText = locString('earnedRab7PointsWithValue', {value: rab7Points});
      } else {
        wonRab7PointsText = locString('rab7PointsWithValue', {value: rab7Points});
      }
    }
  }
  let wonOffers = [];
  let wonGames = [];
  if (activities && activities.length > 0) {
    activities.forEach((item) => {
      if (item.type === ACTIVITY_TYPE_OFFER) {
        wonOffers.push(item);
      } else {
        wonGames.push(item);
      }
    });
  }
  let wonOffersTitle = null;
  let wonOffersView = null;
  if (wonOffers && wonOffers.length > 0) {
    if (wonStorePointsText || wonRab7PointsText) {
      if (type === 1) {
        wonOffersTitle = locString(wonOffers.length > 1 ? 'earnedEOffers' : 'earnedEOffer1');
      } else if (type === 2) {
        wonOffersTitle = locString(wonOffers.length > 1 ? 'youWonEJackpotOffers' : 'youWonEJackpotOffer');
      } else if (type === 3) {
        wonOffersTitle = locString(wonGames.length > 1 ? 'referralWonEOffers' : 'referralWonEOffer');
      } else if (type !== 4) {
        // wonOffersTitle = locString(wonOffers.length > 1 ? 'andOffers1' : 'andOffer1');
      }
    } else {
      if (type === 1) {
        wonOffersTitle = locString(wonOffers.length > 1 ? 'earnedOffers' : 'earnedOffer1');
      } else if (type === 2) {
        wonOffersTitle = locString(wonOffers.length > 1 ? 'youWonJackpotOffers' : 'youWonJackpotOffer');
      } else if (type === 3) {
        wonOffersTitle = locString(wonGames.length > 1 ? 'referralWonOffers' : 'referralWonOffer');
      } else if (type !== 4) {
        // wonOffersTitle = locString(wonOffers.length > 1 ? 'offers1' : 'offer1');
      }
    }
    wonOffersView = [];
    wonOffers.forEach((item) => {
      wonOffersView.push(ActivityHelper.getActivityHorizontalViewEx(item, contentWidth, onDismissFunction, onActivityClicked, onImageClicked, titleColor, invokeToImage, uiConfigs, type));
    });
  }
  let wonGamesTitle = null;
  let wonGamesView = null;
  if (wonGames && wonGames.length > 0) {
    if (wonStorePointsText || wonRab7PointsText || (wonOffers && wonOffers.length > 0)) {
      if (type === 1) {
        wonGamesTitle = locString(wonGames.length > 1 ? 'earnedEGames' : 'earnedEGame');
      } else if (type === 2) {
        wonGamesTitle = locString(wonGames.length > 1 ? 'youWonEJackpotGames' : 'youWonEJackpotGame');
      } else if (type === 3) {
        wonGamesTitle = locString(wonGames.length > 1 ? 'referralWonEFunActivities' : 'referralWonEFunActivity');
      } else if (type !== 4) {
        // wonGamesTitle = locString(wonGames.length > 1 ? 'andFunActivities1' : 'andFunActivity1');
      }
    } else {
      if (type === 1) {
        wonGamesTitle = locString(wonGames.length > 1 ? 'earnedGames' : 'earnedGame');
      } else if (type === 2) {
        wonGamesTitle = locString(wonGames.length > 1 ? 'youWonJackpotGames' : 'youWonJackpotGame');
      } else if (type === 3) {
        wonGamesTitle = locString(wonGames.length > 1 ? 'referralWonFunActivities' : 'referralWonFunActivity');
      } else if (type !== 4) {
        // wonGamesTitle = locString(wonGames.length > 1 ? 'funActivities1' : 'funActivity1');
      }
    }
    wonGamesView = [];
    wonGames.forEach((item) => {
      wonGamesView.push(ActivityHelper.getActivityHorizontalViewEx(item, contentWidth, onDismissFunction, onActivityClicked, onImageClicked, titleColor, invokeToImage, uiConfigs, type));
    });
  }

  const interMarginBottom = 12;
  const intraMarginBottom = 6;
  const fontWeight = FontWeightMedium;
  if (wonStorePointsText || wonRab7PointsText || (wonOffers && wonOffers.length > 0) || (wonGames && wonGames.length > 0)) {
    return (
      <View style={{flexDirection: 'row', justifyContent: 'center', width: '100%'}}>
        <View style={{flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          {wonStorePointsText ? <AppText fontSizeN={uiConfigs.fontSize} style={{textAlign: 'center', color: titleColor, fontWeight: fontWeight, marginBottom: interMarginBottom}}>{wonStorePointsText}</AppText> : null}
          {wonRab7PointsText ? <AppText fontSizeN={uiConfigs.fontSize} style={{textAlign: 'center', color: titleColor, fontWeight: fontWeight, marginBottom: interMarginBottom}}>{wonRab7PointsText}</AppText> : null}

          {/*<View style={{height: 800, width: '100%', borderWidth: 4, borderColor: ColorNegative}} />*/}

          {wonOffersView ?
            <View style={{marginBottom: interMarginBottom, width: '100%', alignItems: 'center'}}>
              <AppText fontSizeN={uiConfigs.fontSize} style={{textAlign: 'center', color: titleColor, fontWeight: fontWeight, marginBottom: intraMarginBottom}}>{wonOffersTitle}</AppText>
              <View style={{flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: type === 0 ?  MAX_WIDTH_REWARD_VIEW : MAX_WIDTH_WON_REWARD_VIEW}}>
                {wonOffersView}
              </View>
            </View>
            : null
          }
          {wonGamesView ?
            <View style={{marginBottom: interMarginBottom, width: '100%', alignItems: 'center'}}>
              <AppText fontSizeN={uiConfigs.fontSize} style={{textAlign: 'center', color: titleColor, fontWeight: fontWeight, marginBottom: intraMarginBottom}}>{wonGamesTitle}</AppText>
              <View style={{flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: type === 0 ?  MAX_WIDTH_REWARD_VIEW : MAX_WIDTH_WON_REWARD_VIEW}}>
                {wonGamesView}
              </View>
            </View>
            : null
          }
        </View>
      </View>
    );
  }
  return null;
};

export const GetActivityHorizontalViewEx = (props) => {
  const {uiConfigs, contentWidth, activity, disableClick, type, onActivityClicked, onImageClicked} = props;
  let {color} = props;
  if (!color) {
    color = ColorWhite
  }
  // let fontSize = FontSizeSmall;
  let borderWidth = 1;
  let textMarginTop = 4;
  // in game ad
  if (type === 4) {
    // fontSize = FontSizeNormal + 1;
    borderWidth = 0;
    textMarginTop = 8;
  }
  let invokeMethod = null;
  if (disableClick) {
    if (onImageClicked) {
      invokeMethod = () => onImageClicked(activity.imageUrl);
    }
  } else {
    if (onActivityClicked) {
      invokeMethod = () => onActivityClicked(activity);
    }
  }
  let styleButton = {display: 'flex', flex: 1, flexDirection: 'column', opacity: 1, borderRadius: RadiusViewList, alignItems: 'center', justifyContent: 'center'};
  if (!disableClick) {
    styleButton = {display: 'flex', flex: 1, flexDirection: 'column', opacity: 1, borderRadius: RadiusViewList, boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.2)', alignItems: 'center', justifyContent: 'center'};
  }

  const mediaMaxWidth = Math.min(contentWidth - 2*(type === 0 ? uiConfigs.infoViewContentHorizontalMargin : uiConfigs.rewardViewContentMarginHorizontal) - (type === 0 ? 2*SpaceNormal : 8), type === 0 ? MAX_WIDTH_REWARD_VIEW : MAX_WIDTH_WON_REWARD_VIEW);
  const mediaMaxHeight = 400;
  let videoWidth = mediaMaxWidth;
  let videoHeight = null;
  if (activity.videoUrl) {
    let videoInfo = null;
    if (activity.videoStreamUrl) {
      videoInfo = ApiHelper.getInfoForVideoUrl(activity.videoStreamUrl);
    } else {
      videoInfo = ApiHelper.getInfoForVideoUrl(activity.videoUrl);
    }
    if (videoInfo) {
      videoHeight = Math.min(videoWidth * videoInfo.height / videoInfo.width, mediaMaxHeight);
    } else {
      videoHeight = mediaMaxHeight;
    }
  }
  const showVideo = activity.videoUrl && (activity.type === constants.activity.types.OFFER || activity.type === constants.activity.types.VOUCHER);
  return (
    <View style={{width: '100%', marginBottom: 8, marginLeft: 8, marginRight: 8, backgroundColor: utils.colorWithAlpha(color, 0.15), borderRadius: RadiusViewList, borderWidth: disableClick ? borderWidth : 0, borderStyle: 'solid', borderColor: utils.colorWithAlpha(color, 0.2)}}>
      <div style={styleButton}>
        <TouchableOpacity onClick={invokeMethod} style={{opacity: 1}}>
          <View style={{alignItems: 'center', width: '100%'}}>
            <View style={{margin: 8, marginLeft: 12, marginRight: 12}}>
              <AppText
                fontSizeN={uiConfigs.fontSize}
                fontWeight={FontWeightRegular}
                variant={'body1'}
                numberOfLines={5}
                style={{
                  // fontSize: fontSize,
                  // fontWeight: FontWeightMedium,
                  color: color,
                  textAlign: 'center',
                }}>{activity.title}
              </AppText>
            </View>
            {!showVideo ? <AppImage
              style={{width: '100%', minHeight: 200, aspectRatio: 4/3, borderRadius: RadiusViewList}}
              imageUrl={activity.imageUrl}
            /> : null
            }
            {type === 4 ?
              <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 12}}>
                <Button
                  onClick={onClick}
                  color={ColorWhite}
                  titleColor={'#ff0000'}
                  style={{borderRadius: RadiusViewList, marginTop: SpaceSmall, padding: 8, paddingStart: 20, paddingEnd: 20, minHeight: HeightElementMinimum, minWidth: 140}}>
                  {ActivityHelper.getCtaTitle(activity)}
                </Button>
              </View>
              : null
            }
          </View>
        </TouchableOpacity>
        {showVideo ?
          <VideoPlayerView
            autoplay={true}
            videoUrl={activity.videoStreamUrl || activity.videoUrl}
            width={videoWidth}
            height={videoHeight}
            borderRadius={BORDER_RADIUS}/>
          : null
        }
      </div>
    </View>
  );
};

export const GetOverlayContentSeparatorView = ({uiConfigs}) => {
  return (
    <View style={{margin: SpaceNormal, marginTop: 0, width: '100%', maxWidth: 120 * uiConfigs.sizeFactor, height: 2, borderWidth: 0, borderTopWidth: 1, borderColor: uiConfigs.messageBorderColor, borderStyle: 'dotted', alignSelf: 'center'}} />
  );
};

export const GetMoreDetailsView = (props) => {
  const {uiConfigs, classes, activity, showPointsCondition, onDismissFunction, onLinkClick} = props;

  let validityColor;
  let validityText;
  const startDateDiff = DateTimeHelper.getSecondsDiffFromNow(activity.startAt);
  if (startDateDiff <= 0) {
    const endDateDiff = DateTimeHelper.getSecondsDiffFromNow(activity.endAt);
    const validity = DateTimeHelper.getValidity(activity.endAt);
    if (endDateDiff <= 0) {
      if (activity.recurAt) {
        validityText = locString('endedRestartingAgain');
        validityColor = ColorOrange;
      } else {
        validityText = locString('ended');
        validityColor = ColorNegative;
      }
    } else {
      validityText = DateTimeHelper.getValidity(activity.endAt, 'generic.endIn');
      validityColor = uiConfigs.overlayTitleColor;
    }
  } else {
    validityText = DateTimeHelper.getValidity(activity.startAt, 'generic.startIn');
    validityColor = uiConfigs.overlayTitleColor;
  }

  const participateConditionsView = showPointsCondition ? ActivityHelper.getParticipateConditionsView(uiConfigs, activity, true) : null;
  return (
    <View className={classes.viewRowFullWidth} style={{marginBottom: -8}} contentContainerStyle={{paddingTop: 12, paddingBottom: 12}}>
      <View className={classes.viewColumnFullWidthNoCenter} style={{minHeight: 40}}>
        <AppText fontSizeN={uiConfigs.fontSize} variant={'body2'} textAlign={'left'} style={{color: uiConfigs.overlaySubTitleColor, fontWeight: FontWeightMedium}}>{activity.title}</AppText>
        <View className={classes.viewColumn} style={{marginTop: 2*SpaceNormal, marginBottom: SpaceNormal}}>
          {ActivityHelper.getNewHeading(locString('duration'), uiConfigs, 'left')}
          <AppText fontSizeN={uiConfigs.fontSize} variant={'body2'} textAlign={'left'} style={{color: validityColor, fontWeight: FontWeightMedium}}>{validityText}</AppText>
          <AppText fontSizeN={uiConfigs.fontSize} variant={'body2'} textAlign={'left'} style={{color: uiConfigs.overlayTitleColor}}>{locString('startingFrom', {value: DateTimeHelper.getDisplayDate(activity.startAt)})}</AppText>
          <AppText fontSizeN={uiConfigs.fontSize} variant={'body2'} textAlign={'left'} style={{color: uiConfigs.overlayTitleColor}}>{locString('validUpto', {value: DateTimeHelper.getDisplayDate(activity.endAt)})}</AppText>
          {activity.recurAt ?
            <AppText fontSizeN={uiConfigs.fontSize} variant={'body2'} style={{align: 'left', color: uiConfigs.overlayTitleColor}}>{locString('restartAgainOn', {value: DateTimeHelper.getDisplayDate(activity.recurAt)})}</AppText>
            : null
          }
        </View>
        {participateConditionsView != null ?
          <View>
            {ActivityHelper.getOverlayContentSeparatorView(uiConfigs)}
            {participateConditionsView}
          </View>
          : null
        }
        {/*{ActivityHelper.getConsumeDetailView(activity, uiConfigs)}*/}
        {activity.description ?
          <View>
            {ActivityHelper.getOverlayContentSeparatorView(uiConfigs)}
            <View style={{marginBottom: SpaceNormal}}>
              {ActivityHelper.getNewHeading(locString('details'), uiConfigs, 'left')}
              <ReadMoreText
                fontSizeN={uiConfigs.fontSize}
                variant={'body2'}
                textAlign={'left'}
                onLinkClick={onLinkClick}
                color={uiConfigs.overlaySubTitleColor}
                fontColor={uiConfigs.overlaySubTitleColor}
                moreColor={uiConfigs.overlaySubTitleColor}
                text={activity.description}
                minLines={10}
                showCount={400}
              />
            </View>
          </View>
          : null
        }

        {/*{ActivityHelper.getBranchDetailView(activity, uiConfigs, onDismissFunction)}*/}

        {activity.tnc ?
          <View>
            {ActivityHelper.getOverlayContentSeparatorView(uiConfigs)}
            <View style={{marginBottom: SpaceNormal}}>
              {ActivityHelper.getNewHeading(locString('termsNConditions'), uiConfigs, 'left')}
              <ReadMoreText
                fontSizeN={uiConfigs.fontSize}
                variant={'body2'}
                textAlign={'left'}
                onLinkClick={onLinkClick}
                color={uiConfigs.overlaySubTitleColor}
                fontColor={uiConfigs.overlaySubTitleColor}
                moreColor={uiConfigs.overlaySubTitleColor}
                text={activity.tnc}
                minLines={10}
                showCount={400}
              />
            </View>
          </View>
          : null
        }
        {activity.type === ACTIVITY_TYPE_EVENT && activity.feedbackHint ?
          <View style={{marginBottom: SpaceNormal}}>
            {ActivityHelper.getOverlayContentSeparatorView(uiConfigs)}
            {ActivityHelper.getNewHeading(locString('contactNumber'), uiConfigs, 'left')}
            <View style={{flexDirection: 'row', flex: 1, marginTop: 1}}>
              {/*<AppIconButton*/}
              {/*  style={{margin: -SpaceSmall, marginRight: 0}}*/}
              {/*  onClick={() => {*/}
              {/*    if (onDismissFunction) {*/}
              {/*      onDismissFunction(() => {*/}
              {/*        LinkHelper.open(`tel:+${activity.feedbackHint}`);*/}
              {/*      })*/}
              {/*    }*/}
              {/*  }}*/}
              {/*  size={14}*/}
              {/*  icon={IconCall}*/}
              {/*  color={ColorSubTitle}/>*/}
              <Button
                elevation={false}
                style={{margin: -SpaceSmall, marginLeft: -8}}
                titleStyle={{fontWeight: FontWeightRegular}}
                titleColor={ColorSubTitle}
                onClick={() => {
                  if (onDismissFunction) {
                    onDismissFunction(() => {
                      LinkHelper.openLink(`tel:+${activity.feedbackHint}`);
                    })
                  }
                }}
              >
                {activity.feedbackHint}
              </Button>
            </View>
          </View>
          : null
        }
      </View>
    </View>
  );
};

export const GetImageActionButton = (props) => {
  const {onClick, title, uiConfigs, sizeFactor, image = uiConfigs.otherActionImage, width = 200, disabled} = props;
  return (
    <AppImageButtonEx
      disabled={disabled}
      imageStyle={{width: width * sizeFactor, height: 42 * sizeFactor}}
      titleStyle={{color: uiConfigs.actionTitleColor, fontSizeN: uiConfigs.fontSize}}
      onClick={onClick}
      title={title}
      imageSource={image} />
  );
};

export const GetActionButton = (props) => {
  const {onClick, title, uiConfigs, size, textVariant, disabled, isNegative, style} = props;
  return (
    <Button
      elevation={1}
      // disableElevation={true}
      disabled={disabled}
      // size={uiConfigs.sizeFactor < 0.9 ? 'small' : uiConfigs.sizeFactor > 1.1 ? 'large' : 'medium'}
      size={size || uiConfigs.sizeFactor > 1.1 ? 'large' : 'medium'}
      variant={'contained'}
      color={isNegative ? 'secondary' : 'primary'}
      style={{minWidth: 80, paddingLeft: SpaceSmall, paddingRight: SpaceSmall, backgroundColor: isNegative ? tinycolor(uiConfigs.actionBgColor).setAlpha(0.9) : uiConfigs.actionBgColor, ...style}}
      onClick={onClick}>
      <TextView variant={textVariant || 'body1'} color={uiConfigs.actionTitleColor || ColorWhite} fontWeight={FontWeightMedium} text={title}/>
    </Button>
  );
};

export default class ActivityHelper {

  static isRTL() {
    return !initialState.app.isLTR;
  }

  static isPointsEarned(activity) {
    return activity.earnedPoints > 0 || activity.earnedRab7Points > 0 || (activity.earnedOffers && activity.earnedOffers.length > 0);
  }

  static showPointsEarnedAlertView(screenRef, uiConfigs, activity, contentWidth, message, positive, negative, onActivityClicked, onImageClicked, onPositiveClicked, onNegativeClicked) {
    const dismissOverlayViewFunction = (callback) => {
      onPositiveClicked();
      if (callback) {
        callback();
      }
    };
    const earnedPoints = activity.earnedPoints;
    const earnedRab7PointsTitleKey = earnedPoints > 0 ? 'earnedERab7PointsWithValue' : 'earnedRab7PointsWithValue';
    const earnedRab7Points = activity.earnedRab7Points;
    const isPointsEarned = earnedPoints > 0 || earnedRab7Points > 0;
    let earnedOffers = [];
    let earnedGames = [];
    if (activity.earnedOffers && activity.earnedOffers.length > 0) {
      activity.earnedOffers.forEach((item) => {
        if (item.type === ACTIVITY_TYPE_OFFER) {
          earnedOffers.push(item);
        } else {
          earnedGames.push(item);
        }
      });
    }
    let earnedOffersTitle = null;
    let earnedOffersView = null;
    if (earnedOffers && earnedOffers.length > 0) {
      if (isPointsEarned) {
        earnedOffersTitle = locString(earnedOffers.length > 1 ? 'earnedEOffers' : 'earnedEOffer1')
      } else {
        earnedOffersTitle = locString(earnedOffers.length > 1 ? 'earnedOffers' : 'earnedOffer1')
      }
      earnedOffersView = [];
      earnedOffers.forEach((item) => {
        earnedOffersView.push(ActivityHelper.getActivityHorizontalViewEx(item, dismissOverlayViewFunction, onActivityClicked, onImageClicked, ColorTitle, false, uiConfigs));
      });
    }
    let wonGamesTitle = null;
    let wonGamesView = null;
    if (earnedGames && earnedGames.length > 0) {
      if (isPointsEarned || (earnedOffers && earnedOffers.length > 0)) {
        wonGamesTitle = locString(earnedOffers.length > 1 ? 'earnedEGames' : 'earnedEGame')
      } else {
        wonGamesTitle = locString(earnedOffers.length > 1 ? 'earnedGames' : 'earnedGame')
      }
      wonGamesView = [];
      earnedGames.forEach((item) => {
        wonGamesView.push(ActivityHelper.getActivityHorizontalViewEx(item, dismissOverlayViewFunction, onActivityClicked, onImageClicked, ColorTitle, false, uiConfigs));
      });
    }
    if (isPointsEarned || (earnedOffers && earnedOffers.length > 0) || (earnedGames && earnedGames.length > 0)) {
      const view = (
        <View style={{margin: -6, marginTop: 12, marginBottom: 16}}>
          {earnedPoints ? <AppText style={{textAlign: 'center', color: ColorTheme, fontSize: FontSizeNormal, fontWeight: FontWeightMedium, marginTop: 4}}>{locString('earnedStorePointsWithValue', {value: earnedPoints})}</AppText> : null}
          {earnedRab7Points ? <AppText style={{textAlign: 'center', color: ColorTheme, fontSize: FontSizeNormal, fontWeight: FontWeightMedium, marginTop: 4}}>{locString(earnedRab7PointsTitleKey, {value: earnedRab7Points})}</AppText> : null}
          {earnedOffersView ?
            <View style={{minHeight: 20 + earnedOffers.length * 36}}>
              <AppText style={{textAlign: 'center', color: ColorTheme, fontSize: FontSizeNormal, fontWeight: FontWeightMedium, marginTop: 4, marginBottom: 4}}>{earnedOffersTitle}</AppText>
              {earnedOffersView}
            </View>
            : null
          }
          {wonGamesView ?
            <View style={{minHeight: 20 + earnedGames.length * 36}}>
              <AppText style={{textAlign: 'center', color: ColorTheme, fontSize: FontSizeNormal, fontWeight: FontWeightMedium, marginTop: 4, marginBottom: 4}}>{wonGamesTitle}</AppText>
              {wonGamesView}
            </View>
            : null
          }
        </View>
      );
      screenRef.showAlertWithView(null, locString('earnedPointsTitle'), message, view, positive, negative, onPositiveClicked, onNegativeClicked);
    }
    delete activity.earnedPoints;
    delete activity.earnedRab7Points;
    delete activity.earnedOffers;
    delete activity.earnedGames;
  }

  static getActivityHorizontalView(activity, screenRef) {
    return (
      <View style={{flex: 1, marginBottom: 4, marginLeft: 8, marginRight: 8, minHeight: 36, backgroundColor: ColorActivityDetail, borderRadius: RadiusView}}>
        <TouchableOpacity style={{flex: 1}} onClick={() => {
          if (screenRef) {
            screenRef.dismissAlert(() => {
              ActivityHelper.invokeActivity(activity);
            });
          } else {
            ActivityHelper.invokeActivity(activity);
          }
        }}>
          <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <View style={{width: 36,}}>
              <AppImage
                style={{width: 36, height: 36, borderColor: ColorOtherTitle, borderRadius: RadiusView}}
                imageUrl={activity.imageUrl}
              />
            </View>
            <View style={{flex: 1, marginLeft: 6, marginRight: 8}}>
              <AppTextWrapper
                numberOfLines={2}
                style={{
                  fontSize: FontSizeSmall,
                  fontWeight: FontWeightRegular,
                  color: ColorTitle
                }}>{activity.title}</AppTextWrapper>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  static getActivityHorizontalViewEx(activity, contentWidth, dismissOverlayViewFunction, onActivityClicked, onImageClicked, color = ColorWhite, disableClick = false, uiConfigs, type) {
    return (
      <GetActivityHorizontalViewEx
        contentWidth={contentWidth}
        uiConfigs={uiConfigs}
        activity={activity}
        disableClick={disableClick}
        type={type}
        dismissOverlayViewFunction={dismissOverlayViewFunction}
        onActivityClicked={onActivityClicked}
        onImageClicked={onImageClicked}
        color={color}
      />
    )
  }

  static getPointsNFunView(uiConfigs, contentWidth, storePoints, rab7Points, activities, type = 0, onDismissFunction, onActivityClicked, onImageClicked) {
    if (storePoints > 0 || rab7Points > 0 || activities && activities.length > 0) {
      return (
          <GetPointsNFunView
              uiConfigs={uiConfigs}
              contentWidth={contentWidth}
              storePoints={storePoints}
              rab7Points={rab7Points}
              activities={activities}
              type={type}
              onDismissFunction={onDismissFunction}
              onActivityClicked={onActivityClicked}
              onImageClicked={onImageClicked}
          />
      );
    }
    return null;
  }

  static getParticipateRewardsView(uiConfigs, activity, contentWidth, onDismissFunction, onActivityClicked, onImageClicked) {
    if (activity.consumedPoints > 0 || activity.rewardedOffer) {
      const rewardedOffers = activity.rewardedOffer ? [activity.rewardedOffer] : null;
      let storePoints = 0;
      let rab7Points = 0;
      if (activity.checkRab7Points) {
        rab7Points = activity.consumedPoints;
      } else {
        storePoints = activity.consumedPoints;
      }
      return (
        <View style={{marginBottom: SpaceNormal, alignItems: 'center'}}>
          {ActivityHelper.getNewHeading(locString('participateRewards'), uiConfigs)}
          <View style={{marginTop: 6}}>
            {ActivityHelper.getPointsNFunView(uiConfigs, contentWidth, storePoints, rab7Points, rewardedOffers, 0, onDismissFunction, onActivityClicked, onImageClicked)}
          </View>
        </View>
      );
    }
    return null;
  }

  static getReferralRewardsView(activity, uiConfigs, contentWidth, onDismissFunction, onActivityClicked, onImageClicked) {
    if (activity.referralWinPoints > 0 || activity.referralWinFun) {
      const rewardedOffers = activity.referralWinFun ? [activity.referralWinFun] : null;
      // const title = activity.referralWinLimitPerUser > 1 ? locString('referralRewardsWithValue', {value: activity.referralWinLimitPerUser}) : locString('referralRewards1WithValue');
      const title = locString('activity.referAndWin');
      return (
        <View style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          {ActivityHelper.getNewHeading(title, uiConfigs, 'center')}
          {/*{ActivityHelper.getPointsNFunView(uiConfigs, contentWidth, activity.referralWinPoints, 0, rewardedOffers, 0, onDismissFunction, onActivityClicked, onImageClicked)}*/}
        </View>
      );
    }
    return null;
  }

  static getWinnersView(activity, uiConfigs, maxHeight, readOnly, inPreview) {
    return (
      <View style={{display: 'flex', flex: 1, flexDirection: 'column', height: maxHeight, minHeight: maxHeight, width: '100%'}}>
        <ActivityWinners activityId={activity.id} uiConfigs={uiConfigs} readOnly={readOnly} inPreview={inPreview} />
      </View>
    );
  }

  static getReportView(activityId, uiConfigs, maxHeight, maxWidth, onDone) {
    return (
      <View style={{display: 'flex', flex: 1, flexDirection: 'column', height: maxHeight, minHeight: maxHeight, width: '100%'}}>
        <ActivityReport activityId={activityId} uiConfigs={uiConfigs} onDone={onDone} maxWidth={maxWidth} />
      </View>
    );
  }

  static getReportPostView(postId, uiConfigs, maxHeight, maxWidth, onDone) {
    return (
      <View style={{display: 'flex', flex: 1, flexDirection: 'column', height: maxHeight, minHeight: maxHeight, width: '100%'}}>
        <ActivityReport activityPostId={postId} uiConfigs={uiConfigs} onDone={onDone} maxWidth={maxWidth} />
      </View>
    );
  }

  static getAddPostView(activity, showcaseId, uiConfigs, maxHeight, maxWidth, inPreview, isXS, user, contentTypes, onDone) {
    return (
      <View style={{display: 'flex', flex: 1, flexDirection: 'column', height: maxHeight, minHeight: maxHeight, width: '100%'}}>
        <ActivityAddPost activity={activity} showcaseId={showcaseId} uiConfigs={uiConfigs} maxWidth={maxWidth} inPreview={inPreview} isXS={isXS} user={user} contentTypes={contentTypes} onDone={onDone} />
      </View>
    );
  }

  static getAnswerDistributionsView(activity, forUser, uiConfigs, maxHeight, readOnly = false, previewData = null) {
    return (
      <View style={{display: 'flex', flex: 1, flexDirection: 'column', height: maxHeight, minHeight: maxHeight, width: '100%'}}>
        <ActivityAnswerDistributions activity={activity} forUser={forUser} uiConfigs={uiConfigs} readOnly={readOnly} previewData={previewData} />
      </View>
    );
  }

  static getGuideView(activity, uiConfigs, onImageClicked, onTextLinkClicked) {
    let slotsView = null;
    if (activity.type === ACTIVITY_TYPE_GREEDY_SPIN
      // || activity.type === ACTIVITY_TYPE_SLOT
      || activity.type === ACTIVITY_TYPE_BETTING_PIC
      || activity.type === ACTIVITY_TYPE_SPIN_DISCOVER
      || activity.type === ACTIVITY_TYPE_BRAND_RACE
      || activity.type === ACTIVITY_TYPE_COLLECT_STARS
      || activity.type === ACTIVITY_TYPE_FLIP_CARD
      || activity.type === ACTIVITY_TYPE_FIND_HIDDEN
      // || activity.type === ACTIVITY_TYPE_MATCHING_PIC
      // || activity.type === ACTIVITY_TYPE_MATCH_THREE
      || activity.type === ACTIVITY_TYPE_WHAC_A_MOLE
      || activity.type === ACTIVITY_TYPE_POKER
      || (activity.type === ACTIVITY_TYPE_TIC_TAC_TOE && activity.slotValues && activity.slotValues.length > 0 && activity.slotValues[0].imageUrl)) {
      slotsView = ActivityHelper.getSlotValuesView(activity, uiConfigs, false, onImageClicked);
    }
    const pointsView = null; // this.getParticipateConditionsView();
    let guideMessage = uiConfigs.guideMessage;
    let fontWeight = FontWeightMedium;
    if (activity.guideMessage) {
      guideMessage = activity.guideMessage;
      fontWeight = FontWeightMedium;
    }
    return (
      // <ScrollView
      //   style={{marginBottom: -8, paddingTop: 12, paddingBottom: 12}}>
      <View style={{width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'}}>
        {guideMessage ?
          <AppText
            onLinkClick={onTextLinkClicked}
            variant={'body2'}
            fontSizeN={uiConfigs.fontSize}
            style={{
              align: 'center',
              width: '100%',
              color: uiConfigs.overlayTitleColor,
              marginBottom: SpaceSmall,
            }}>
            {guideMessage}
          </AppText>
          : null
        }
        {slotsView}
        {pointsView ? <View style={{width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'}}>
            {ActivityHelper.getOverlayContentSeparatorView(uiConfigs)}
            {pointsView}
          </View>
          : null
        }

      </View>
      // </ScrollView>
    );
  }

  static getSlotValuesView(activity, uiConfigs, showOffers, onImageClicked) {
    let showPoints = false;
    let imagePlaceholder = null;
    let valuePlaceholder = null;
    let showImagesInOneRow = null;
    let showCounts = 1;
    let showReverse = false;
    let imageNosDisplay = 0;
    if (activity.type === ACTIVITY_TYPE_SLOT) {
      showPoints = false;
      // imagePlaceholder = locString('image');
      // valuePlaceholder = locString('points');
    } else if (activity.type === ACTIVITY_TYPE_SPIN_DISCOVER) {
      showPoints = true;
      imagePlaceholder = locString('image');
      valuePlaceholder = locString('partsCount');
      showReverse = true;
    } else if (activity.type === ACTIVITY_TYPE_BRAND_RACE) {
      showPoints = true;
      imagePlaceholder = locString('avatar');
      valuePlaceholder = locString('moveCount');
      showReverse = true;
    } else if (activity.type === ACTIVITY_TYPE_POKER) {
      showPoints = true;
      imagePlaceholder = locString('cardImage');
      valuePlaceholder = locString('cardValue');
      imageNosDisplay = 5;
    } else if (activity.type === ACTIVITY_TYPE_GREEDY_SPIN || activity.type === ACTIVITY_TYPE_BETTING_PIC || activity.type === ACTIVITY_TYPE_COLLECT_STARS
      || activity.type === ACTIVITY_TYPE_MATCH_THREE || activity.type === ACTIVITY_TYPE_WHAC_A_MOLE) {
      showPoints = true;
      imagePlaceholder = locString('image');
      valuePlaceholder = locString('cardValue');
    } else if (activity.type === ACTIVITY_TYPE_FLIP_CARD || activity.type === ACTIVITY_TYPE_FIND_HIDDEN
      || activity.type === ACTIVITY_TYPE_MATCHING_PIC) {
      showImagesInOneRow = true;
    } else if (activity.type === ACTIVITY_TYPE_TIC_TAC_TOE) {
      imageNosDisplay = 1;
    }

    let views = null;
    if (activity.slotValues) {
      views = [];
      for (let i = 0; i < activity.slotValues.length; i++) {
        const item = activity.slotValues[i];
        // if (i > showCounts - 1 && (item.value1 >= 0 || !showReverse)) {
        //   continue;
        // }
        if (item.number <= imageNosDisplay) {
          let valueText = null;
          if (activity.type === ACTIVITY_TYPE_TIC_TAC_TOE) {
            if (item.number === 0) {
              valueText = locString('game.avatarYours');
            } else {
              valueText = locString('game.avatarOpponent');
            }
          }
          views.push(ActivityHelper.getSlotValueView(activity, uiConfigs, item, showOffers, showPoints, valueText, showImagesInOneRow, onImageClicked));
        }
      }
    }
    const style = {margin: SpaceNormal, marginTop: 0, flexDirection: 'column', alignItems: 'center'};
    // if (!showOffers) {
    //   style.maxWidth = 250;
    // } else {
    //   style.width = '90%';
    // }
    return (
      <View style={style}>
        {(imagePlaceholder != null || valuePlaceholder != null || showOffers) ?
          <View style={{flexDirection: 'row', marginBottom: SpaceXSmall}}>
            {imagePlaceholder != null ?
              <View style={{flexDirection: 'column', minWidth: 60 * uiConfigs.sizeFactor}}>
                <AppTextWrapper
                  textAlign={'left'}
                  fontSizeN={uiConfigs.fontSize}
                  variant={'body2'}
                  style={{
                    color: uiConfigs.overlaySubTitleColor,
                    fontWeight: FontWeightMedium,
                  }}>
                  {imagePlaceholder}
                </AppTextWrapper>
              </View>
              : null
            }
            {valuePlaceholder != null ?
              <Space horizontalSpace={2} />
              : null
            }
            {valuePlaceholder != null ?
              <View style={{flexDirection: 'column', minWidth: 100 * uiConfigs.sizeFactor, alignItems: 'flex-end'}}>
                <AppTextWrapper
                  fontSizeN={uiConfigs.fontSize}
                  variant={'body2'}
                  style={{
                    color: uiConfigs.overlaySubTitleColor,
                    fontWeight: FontWeightMedium,
                  }}>
                  {valuePlaceholder}
                </AppTextWrapper>
              </View>
              : null
            }
            {/*{showOffers ?*/}
            {/*  <View style={{flex: 1, alignItems: 'center'}}>*/}
            {/*    <AppTextWrapper*/}
            {/*      fontSizeN={uiConfigs.fontSize}*/}
            {/*      style={{*/}
            {/*        color: uiConfigs.overlaySubTitleColor,*/}
            {/*        fontWeight: FontWeightMedium,*/}
            {/*        marginRight: SpaceSmall,*/}
            {/*      }}>*/}
            {/*      {locString('offer')}*/}
            {/*    </AppTextWrapper>*/}
            {/*  </View>*/}
            {/*  : null*/}
            {/*}*/}
          </View>
          : null
        }
        <View style={showImagesInOneRow ? {flexDirection: 'row', flexWrap: 'wrap'} : {flexDirection: 'column', alignItems: 'center'}}>
          {views}
        </View>
      </View>
    );
  }

  static getSlotValueView(activity, uiConfigs, item, showOffers, showPoints, valueText, showInOneRow, onImageClicked) {
    let effPoints = !activity.noRewards ? item.points : item.id > 0 ? -1 : 0; // added -1 for no rewards to process it
    if (activity.type === ACTIVITY_TYPE_COLLECT_STARS
      || activity.type === ACTIVITY_TYPE_GREEDY_SPIN
      || activity.type === ACTIVITY_TYPE_BETTING_PIC
      || activity.type === ACTIVITY_TYPE_SPIN_DISCOVER
      || activity.type === ACTIVITY_TYPE_BRAND_RACE
      || activity.type === ACTIVITY_TYPE_POKER
      || activity.type === ACTIVITY_TYPE_MATCH_THREE
      || activity.type === ACTIVITY_TYPE_WHAC_A_MOLE) {
      effPoints = item.value1 ? item.value1 : 0;
    }

    if (effPoints !== 0 || (item.offers && item.offers.length > 0) || valueText) {
      let offersView = null;
      // if (showOffers && item.offers && item.offers.length > 0) {
      //   offersView = [];
      //   item.offers.forEach((offer) => {
      //     offersView.push(
      //       <Touchable onClick={() => {
      //         // this.dismissOverlayView(() => {
      //         //     ActivityHelper.invokeActivity(offer);
      //         // });
      //       }}>
      //         <AppTextWrapper
      //           fontSizeN={uiConfigs.fontSize}
      //           numberOfLines={2}
      //           style={{
      //             marginTop: 4,
      //             color: uiConfigs.overlayTitleColor,
      //             fontWeight: FontWeightRegular,
      //           }}>
      //           {offer.title}
      //         </AppTextWrapper>
      //         // </Touchable>
      //     );
      //   });
      // }
      return (
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: SpaceXSmall}}>
          <View style={showInOneRow ? {minWidth: 60 * uiConfigs.sizeFactor} : {flex: 1, flexDirection: 'column', alignItems: 'center'}}>
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <View
                style={{minWidth: 60 * uiConfigs.sizeFactor, flexDirection: 'row', justifyContent: showPoints ? 'flex-start' : 'center'}}>
                <TouchableOpacity
                  key={item.id}
                  disabled={!item.imageUrl}
                  onClick={() => onImageClicked(item.imageUrl)}>
                  <AppImage
                    style={{
                      width: 40 * uiConfigs.sizeFactor,
                      height: 40 * uiConfigs.sizeFactor,
                      borderRadius: RadiusView
                    }}
                    source={item.imageUrl}/>
                </TouchableOpacity>
              </View>
              {showPoints || valueText ?
                <Space horizontalSpace={2} />
                : null
              }
              {/*{showPoints || offersView != null ?*/}
              {/*  <View style={{flexDirection: 'row', flex: 1, alignItems: 'center', marginLeft: SpaceSmall}}>*/}
                  {showPoints || valueText ?
                    <TextView fontSizeN={uiConfigs.fontSize} align={'right'} style={{flexDirection: 'row', minWidth: 100 * uiConfigs.sizeFactor, justifyContent: 'flex-end'}}>
                      {valueText ? valueText : ViewHelper.getValueInDetailView(effPoints, uiConfigs.overlayTitleColor, uiConfigs)}
                    </TextView>
                    : null
                  }
              {/*    {offersView ?*/}
              {/*      <View style={{flex: 1, alignItems: 'center'}}>*/}
              {/*        {offersView}*/}
              {/*      </View>*/}
              {/*      : null*/}
              {/*    }*/}
              {/*  </View>*/}
              {/*  : null*/}
              {/*}*/}
            </View>
          </View>
        </View>
      );
    }
    return null;
  }

  static getCtaTitle(activity) {
    let linkText = locString('ctaView');
    if (activity.type === ACTIVITY_TYPE_OFFER || activity.type === ACTIVITY_TYPE_DISCOUNT) {
      linkText = locString('ctaConsume');
    } else if (activity.type === ACTIVITY_TYPE_QUESTION || activity.type === ACTIVITY_TYPE_QUESTIONNAIRE || activity.type === ACTIVITY_TYPE_SURVEY) {
      linkText = locString('ctaAnswer');
    } else if (activity.type === ACTIVITY_TYPE_OPINION
      || activity.type === ACTIVITY_TYPE_VISITOR_OPINION) {
      linkText = locString('ctaReview');
    } else if (activity.type === ACTIVITY_TYPE_PREDICTION) {
      linkText = locString('ctaPredict');
    } else if (activity.type === ACTIVITY_TYPE_GUESS || activity.type === ACTIVITY_TYPE_IDENTIFY_LOCATION) {
      linkText = locString('ctaGuess');
    } else if (activity.type === ACTIVITY_TYPE_EVENT) {
      linkText = locString('ctaParticipate');
    } else if (activity.type === ACTIVITY_TYPE_SLOT
      || activity.type === ACTIVITY_TYPE_GREEDY_SPIN
      || activity.type === ACTIVITY_TYPE_BETTING_PIC
      || activity.type === ACTIVITY_TYPE_GAME_ROULETTE
      || activity.type === ACTIVITY_TYPE_SPIN_DISCOVER
      || activity.type === ACTIVITY_TYPE_FLIP_CARD
      || activity.type === ACTIVITY_TYPE_FIND_HIDDEN
      || activity.type === ACTIVITY_TYPE_MATCHING_PIC
      || activity.type === ACTIVITY_TYPE_TIC_TAC_TOE
      || activity.type === ACTIVITY_TYPE_COLLECT_STARS
      || activity.type === ACTIVITY_TYPE_SHARE_N_LIKE
      || activity.type === ACTIVITY_TYPE_MATCH_THREE
      || activity.type === ACTIVITY_TYPE_PUZZLE
      || activity.type === ACTIVITY_TYPE_PUZZLE_SLIDE
      || activity.type === ACTIVITY_TYPE_WHAC_A_MOLE
      || activity.type === ACTIVITY_TYPE_BRAND_RACE
      || activity.type === ACTIVITY_TYPE_POKER
    ) {
      linkText = locString('ctaPlay');
    }
    return linkText;
  }

  static getCtaTitleML(activity) {
    let linkText = locHtmlString('ctaViewML');
    if (activity.type === ACTIVITY_TYPE_OFFER || activity.type === ACTIVITY_TYPE_DISCOUNT) {
      linkText = locHtmlString('ctaConsumeML');
    } else if (activity.type === ACTIVITY_TYPE_QUESTION || activity.type === ACTIVITY_TYPE_QUESTIONNAIRE || activity.type === ACTIVITY_TYPE_SURVEY) {
      linkText = locHtmlString('ctaAnswerML');
    } else if (activity.type === ACTIVITY_TYPE_OPINION
      || activity.type === ACTIVITY_TYPE_VISITOR_OPINION) {
      linkText = locHtmlString('ctaReviewML');
    } else if (activity.type === ACTIVITY_TYPE_PREDICTION) {
      linkText = locHtmlString('ctaPredictML');
    } else if (activity.type === ACTIVITY_TYPE_GUESS || activity.type === ACTIVITY_TYPE_IDENTIFY_LOCATION) {
      linkText = locHtmlString('ctaGuessML');
    } else if (activity.type === ACTIVITY_TYPE_EVENT) {
      linkText = locHtmlString('ctaParticipateML');
    } else if (activity.type === ACTIVITY_TYPE_SLOT
      || activity.type === ACTIVITY_TYPE_GREEDY_SPIN
      || activity.type === ACTIVITY_TYPE_BETTING_PIC
      || activity.type === ACTIVITY_TYPE_GAME_ROULETTE
      || activity.type === ACTIVITY_TYPE_SPIN_DISCOVER
      || activity.type === ACTIVITY_TYPE_FLIP_CARD
      || activity.type === ACTIVITY_TYPE_FIND_HIDDEN
      || activity.type === ACTIVITY_TYPE_MATCHING_PIC
      || activity.type === ACTIVITY_TYPE_TIC_TAC_TOE
      || activity.type === ACTIVITY_TYPE_COLLECT_STARS
      || activity.type === ACTIVITY_TYPE_SHARE_N_LIKE
      || activity.type === ACTIVITY_TYPE_MATCH_THREE
      || activity.type === ACTIVITY_TYPE_PUZZLE
      || activity.type === ACTIVITY_TYPE_PUZZLE_SLIDE
      || activity.type === ACTIVITY_TYPE_WHAC_A_MOLE
      || activity.type === ACTIVITY_TYPE_BRAND_RACE
      || activity.type === ACTIVITY_TYPE_POKER
    ) {
      linkText = locHtmlString('ctaPlayML');
    }
    return linkText;
  }

  static getNewHeading(heading, uiConfigs, textAlign = 'center') {
    return (
      <AppTextWrapper
        fontSizeN={uiConfigs.fontSize}
        textAlign={textAlign}
        style={{fontWeight: FontWeightMedium, color: uiConfigs.overlayTitleColor, marginBottom: 2}}>
        {heading}
      </AppTextWrapper>
    );
  };

  static getDetailsNameValueTemplateView(name, value, uiConfigs, inMoreDetails = false) {
    return (
      <AppTextWrapper style={{display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', color: uiConfigs.overlaySubTitleColor, fontWeight: FontWeightRegular, marginBottom: inMoreDetails ? 0 : 3}}>
        <AppText fontSizeN={uiConfigs.fontSize} variant={'body2'} style={{textAlign: 'left', display: 'flex', flexDirection: 'row'}}>
          {name}
        </AppText>
        <AppText fontSizeN={uiConfigs.fontSize} variant={'body2'} style={{display: 'flex', flexDirection: 'row'}}>
          {':'}
        </AppText>
        <AppText fontSizeN={uiConfigs.fontSize} variant={'body2'} style={{textAlign: 'left', paddingLeft: 8, paddingRight: 8, display: 'flex', flexDirection: 'row', fontWeight: FontWeightMedium}}>
          {value}
        </AppText>
      </AppTextWrapper>
    );
  }

  static getParticipateConditionsView(uiConfigs, activity, inMoreDetails = false) {
    const pointsRequired = Math.max(activity.pointsNeeded, activity.fee);
    if (pointsRequired > 0 || activity.fee > 0) {
      return (
        <View style={{flex: 1, marginBottom: SpaceNormal, alignItems: inMoreDetails ? 'flex-start' : 'center'}}>
          {ActivityHelper.getNewHeading(locString('participatePointsConditions'), uiConfigs, 'left')}
          <View style={{marginTop: inMoreDetails ? 0 : 6}}>
            {ActivityHelper.getDetailsNameValueTemplateView(locString(activity.checkRab7Points ? 'rab7PointsBalance' : 'storePointsBalance'), activity.userEarnedPoints, uiConfigs, inMoreDetails)}
            {pointsRequired > 0 ? ActivityHelper.getDetailsNameValueTemplateView(locString(activity.checkRab7Points ? 'rab7PointsRequiredToAction' : 'storePointsRequiredToAction'), pointsRequired, uiConfigs, inMoreDetails) : null}
            {activity.fee > 0 ? ActivityHelper.getDetailsNameValueTemplateView(locString(activity.checkRab7Points ? 'rab7PointsChargedOnAction' : 'storePointsChargedOnAction'), activity.fee, uiConfigs, inMoreDetails) : null}
          </View>
        </View>
      );
    }
    return null;
  }

  static getParticipationWonOverlayContentView(uiConfigs, activity, contentWidth, message, onDismissFunction, onActivityClicked, onImageClicked) {
    const view = ActivityHelper.getPointsNFunView(uiConfigs, contentWidth, activity.earnedPoints, activity.earnedRab7Points, activity.earnedOffers, 1, onDismissFunction, onActivityClicked, onImageClicked);
    if (!view) {
      return null;
    }

    return (
      <ScrollView>
        <View style={{minHeight: 30}}>
          {message ?
            <AppText style={{textAlign: 'center', color: uiConfigs.overlaySubTitleColor, fontSize: FontSizeNormal, fontWeight: FontWeightMedium, marginBottom: 16}}>
              {message}
            </AppText>
            : null
          }
          {view}
        </View>
      </ScrollView>
    );
  }

  static getConsumeDetailView(activity, uiConfigs) {
    if (activity.type === ACTIVITY_TYPE_OFFER || activity.type === ACTIVITY_TYPE_DISCOUNT) {
      const threshold = 100000;
      const max = 999999999;
      let availabilityForMeText = null;
      const effConsumeLimitPerUser = Math.min(activity.consumeLimit - activity.consumedCount, Math.max(activity.consumeLimitPerUser - activity.userConsumedCount, 0));
      if (effConsumeLimitPerUser >= threshold) {
        availabilityForMeText = locString('unlimited');
      } else {
        availabilityForMeText = ValueHelper.getDisplayValue(Math.max(effConsumeLimitPerUser, 0), false);
      }
      let remainingText = null;
      if (activity.consumeLimit - activity.consumedCount >= threshold) {
        remainingText = locString('unlimited');
      } else {
        remainingText = ValueHelper.getDisplayValue(activity.consumeLimit - activity.consumedCount, false);
      }
      let remainingTodayText = null;
      if (activity.consumeLimitPerDay > 0 && activity.consumeLimitPerDay < max) {
        if (activity.consumeLimitPerDay - activity.consumedCountDaily >= threshold) {
          remainingTodayText = locString('unlimited');
        } else {
          remainingTodayText = ValueHelper.getDisplayValue(activity.consumeLimitPerDay - activity.consumedCountDaily, false);
        }
      }

      return (
        <View style={{marginBottom: SpaceNormal}}>
          {ActivityHelper.getOverlayContentSeparatorView(uiConfigs)}
          {ActivityHelper.getNewHeading(locString('consumeDetails'), uiConfigs, 'left')}
          {ActivityHelper.getDetailsNameValueTemplateView(locString('totalOffersConsumed'), ValueHelper.getDisplayValue(activity.consumedCount, false), uiConfigs, true)}
          {ActivityHelper.getDetailsNameValueTemplateView(locString('availableOffersToConsume'), remainingText, uiConfigs, true)}
          {remainingTodayText ? ActivityHelper.getDetailsNameValueTemplateView(locString('availableOffersTodayToConsume'), remainingTodayText, uiConfigs, true) : null}
          {ActivityHelper.getDetailsNameValueTemplateView(locString('availableOffersToMeToConsume'), availabilityForMeText, uiConfigs, true)}
          {activity.consumableOnlyWDays ? ActivityHelper.getDetailsNameValueTemplateView(locString('consumableDay'), activity.consumableOnlyWDays, uiConfigs, true) : null}
          {activity.hourStartAt && activity.hourEndAt ?
            ActivityHelper.getDetailsNameValueTemplateView(locString('consumableTime'), locString('fromTo', {
              from: DateTimeHelper.getDisplayTime(activity.hourStartAt),
              to: DateTimeHelper.getDisplayTime(activity.hourEndAt)
            }), uiConfigs, true) : null}
        </View>
      );
    }
    return null;
  }

  static getBranchDetailView(activity, uiConfigs, dismissOverlayFunction) {
    if ((activity.type === ACTIVITY_TYPE_OFFER && !activity.isVoucher) || activity.type === ACTIVITY_TYPE_DISCOUNT) {
      return (
        <View style={{marginBottom: 16}}>
          {ActivityHelper.getOverlayContentSeparatorView(uiConfigs)}
          {ActivityHelper.getNewHeading(locString(activity.isBranchTargeted ? 'consumableInSelectedBranches' : 'consumableInAllBranches'), uiConfigs)}
          <View style={{flexDirection: 'row'}}>
            <Button
              onClick={() => {
                if (dismissOverlayFunction) {
                  dismissOverlayFunction(() => {
                    // NavigationService.push(NAVIGATION_NAME_ACTIVITY_BRANCHES, {activity: activity, isInPickMode: true})
                  });
                }
              }}
              elevation={false}
              titleStyle={{fontSize: FontSizeSmall, color: ColorLink, fontWeight: FontWeightRegular}}
              style={{paddingStart: 0, marginTop: -8, marginBottom: -8, minHeight: 36}}
              title={locString('viewBranches')}
            />
          </View>
        </View>
      );
    }
    return null;
  }

  static getOverlayContentSeparatorView(uiConfigs) {
    return (
      <GetOverlayContentSeparatorView uiConfigs={uiConfigs} />
    );
  }

  static getGameValuePlaceholder(activity) {
    if (activity.type === ACTIVITY_TYPE_SHARE_N_LIKE) {
      return activity.value1Placeholder ? activity.value1Placeholder : locString('likes');
    }
    if (activity.type === ACTIVITY_TYPE_COLLECT_STARS) {
      return activity.value1Placeholder ? activity.value1Placeholder : locString('stars');
    }
    if (activity.type === ACTIVITY_TYPE_MATCH_THREE || activity.type === ACTIVITY_TYPE_GREEDY_SPIN
      || activity.type === ACTIVITY_TYPE_BETTING_PIC || activity.type === ACTIVITY_TYPE_WHAC_A_MOLE) {
      return activity.value1Placeholder ? activity.value1Placeholder : locString('values');
    }
    if (activity.type === ACTIVITY_TYPE_BRAND_RACE) {
      return activity.value1Placeholder ? activity.value1Placeholder : locString('brandDice');
    }
    return null;
  }

  static getGameCollectValueMessage(activity) {
    let collectToWin = null;
    if (activity.winnersBy > 0) {
      if (activity.winnersByValue === 0) {
        collectToWin = locString('collectToWin', {value: ActivityHelper.getGameValuePlaceholder(activity)});
      } else if (activity.winnersByValue > 0) {
        collectToWin = locString('collectRequiredValueToWin', {value: ActivityHelper.getGameValuePlaceholder(activity), value1: ValueHelper.getDisplayValue(activity.winnersByValue)});
      }
    } else {
      collectToWin = locString('collectMaxToWin', {value: ActivityHelper.getGameValuePlaceholder(activity)});
    }
    return collectToWin;
  }

  static getActionTopSubView(activity, contentWidth, dismissOverlayViewFunction) {
    if (activity && (activity.imageActionUrl || activity.actionMessage)) {
      const uiConfigs = ActivityHelper.getUIConfigs(activity);
      const imageSize = contentWidth - (2 * uiConfigs.infoViewContentHorizontalMargin) - 40;
      let invokeMethod = null;
      if (activity.imageActionUrl) {
        invokeMethod = () => {
          if (dismissOverlayViewFunction) {
            dismissOverlayViewFunction(() => {
              ActivityHelper.invokeImage([activity.imageActionUrl]);
            });
          } else {
            ActivityHelper.invokeImage([activity.imageActionUrl]);
          }
        };
      }
      return (
        <View style={{alignItems: 'center', justifyContent: 'center', marginTop: uiConfigs.infoViewTitleMarginTop + 8, marginLeft: uiConfigs.infoViewContentHorizontalMargin, marginRight: uiConfigs.infoViewContentHorizontalMargin}}>
          {activity.imageActionUrl ?
            <TouchableOpacity onClick={invokeMethod}>
              <AppImage
                style={{width: imageSize, height: imageSize, borderColor: ColorOtherTitle, borderRadius: RadiusView}}
                imageUrl={activity.imageActionUrl}/>
            </TouchableOpacity>
            : null
          }
          {activity.actionMessage ?
            <AppText style={{textAlign: 'center', color: uiConfigs.overlayHeadingColor, fontSize: FontSizeNormal, fontWeight: FontWeightMedium, marginTop: 8}}>{activity.actionMessage}</AppText>
            : null
          }
        </View>
      );
    }
    return null;
  }

  static getGameTimeText(time) {
    let seconds = time / 1000;
    let hours = parseInt(seconds / (60*60));
    let minutes = parseInt(seconds / 60);
    seconds = parseInt(seconds % 60);
    return `${hours > 0 && hours < 10 ? '0' : ''}${hours > 0 ? hours + ':' : ''}${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  static getUIConfigs(activity, intl = null, isLTR = true, inPreview = false) {
    if (activity) {
      if (activity.isInternal && activity.next && activity.next.type !== constants.activity.types.CHALLENGE) {
        activity = activity.next;
      }

      let uiConfigs = null;
      if (activity.type === ACTIVITY_TYPE_VISITOR_OPINION) {
        uiConfigs = ActivityHelper.getUiConfigsForOpinion(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_QUESTION) {
        uiConfigs = ActivityHelper.getUiConfigsForQuestion(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_QUESTIONNAIRE || activity.type === ACTIVITY_TYPE_SURVEY) {
        uiConfigs = ActivityHelper.getUiConfigsForQuestionnaire(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_CHALLENGE || activity.type === ACTIVITY_TYPE_CONTENT_WRITING) {
        uiConfigs = ActivityHelper.getUiConfigsForChallenge(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_PREDICTION) {
        uiConfigs = ActivityHelper.getUiConfigsForGamePrediction(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_GUESS) {
        uiConfigs = ActivityHelper.getUiConfigsForGameGuess(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_IDENTIFY_LOCATION) {
        uiConfigs = ActivityHelper.getUiConfigsForGameIdentifyLocation(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_SHARE_N_LIKE) {
        uiConfigs = ActivityHelper.getUiConfigsForGameShareNLike(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_SLOT) {
        uiConfigs = ActivityHelper.getUiConfigsForGameSlot(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_GREEDY_SPIN) {
        uiConfigs = ActivityHelper.getUiConfigsForGameGreedySpin(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_BETTING_PIC) {
        uiConfigs = ActivityHelper.getUiConfigsForGameBettingPic(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_GAME_ROULETTE) {
        uiConfigs = ActivityHelper.getUiConfigsForGameRoulette(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_SPIN_DISCOVER) {
        uiConfigs = ActivityHelper.getUiConfigsForGameSpinDiscover(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_BRAND_RACE) {
        uiConfigs = ActivityHelper.getUiConfigsForGameBrandRace(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_FLIP_CARD) {
        uiConfigs = ActivityHelper.getUiConfigsForGameFlipCard(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_FIND_HIDDEN) {
        uiConfigs = ActivityHelper.getUiConfigsForGameFindHidden(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_MATCHING_PIC) {
        uiConfigs = ActivityHelper.getUiConfigsForGameMatchPair(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_TIC_TAC_TOE) {
        uiConfigs = ActivityHelper.getUiConfigsForGameTicTacToe(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_POKER) {
        uiConfigs = ActivityHelper.getUiConfigsForGamePoker(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_MATCH_THREE) {
        uiConfigs = ActivityHelper.getUiConfigsForGameMatchThree(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_WHAC_A_MOLE) {
        uiConfigs = ActivityHelper.getUiConfigsForGameWhacAMole(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_PUZZLE) {
        uiConfigs = ActivityHelper.getUiConfigsForGamePuzzleTap(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_PUZZLE_SLIDE) {
        uiConfigs = ActivityHelper.getUiConfigsForGamePuzzleSlide(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_COLLECT_STARS) {
        uiConfigs = ActivityHelper.getUiConfigsForGameCollectStar(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_SLIDES || activity.type === ACTIVITY_TYPE_SHOWCASE_MENU) {
        uiConfigs = ActivityHelper.getUiConfigsForGameSlides(activity, intl, isLTR);
      } else if (activity.type === ACTIVITY_TYPE_EXTERNAL_GAMES) {
        uiConfigs = ActivityHelper.getUiConfigsForGameExternal(activity, intl, isLTR);
      }
      if (uiConfigs && uiConfigs.color) {
        const color = uiConfigs.color;
        const colorDark = tinycolor(color).lighten().toString();
        const colorLight = tinycolor(colorDark).lighten().toString();
        const colorTitle = tinycolor.mostReadable(colorDark, ['#000000', '#FFFFFF', '#000080', '#000080', '#FF0000', '#FFFF00']).toString();
        const colorSubTitle = tinycolor(colorTitle).lighten().toString();
        const colorAccent = tinycolor(colorDark).complement().toString();
        const colorAccentDark = tinycolor(colorAccent).darken().toString();
        const colorAccentTitle = tinycolor.mostReadable(colorAccent, ['#FFFFFF', '#EEEEEE', '#000000', '#333333', '#000080', '#FF0000', '#FFFF00']).toString();
        delete (uiConfigs.color);

        uiConfigs = Object.assign(uiConfigs, {
          themeColor: colorLight,
          themeColorDark: colorDark,

          accentColor: colorAccent,
          actionTitleColor: colorAccentTitle,
          actionBgColor: colorAccent,
          actionBgColorDark: colorAccentDark,
          titleColor: colorTitle,
          subTitleColor: colorSubTitle,

          bgColor: tinycolor(colorLight).setAlpha(0.7),
          bg2Color: tinycolor(colorLight).setAlpha(0.2),
          safeAreaColor: tinycolor(colorLight).setAlpha(0.2),
          iconColor: colorTitle,
          businessTitleColor: colorTitle,
          businessSubTitleColor: colorSubTitle,
          rewardHeadingColor: colorTitle,
          rewardTitleColor: colorTitle,
          rewardSubTitleColor: colorSubTitle,
          bottomMenuTitleColor: colorTitle,
          overlayHeadingColor: colorTitle,
          overlayTitleColor: colorTitle,
          overlaySubTitleColor: colorSubTitle,
          messageBgColor: colorLight,
          messageBorderColor: colorLight,

          topIconWidth: 72,
          topIconHeight: 72,
          topViewHeight: 72,
          topViewSmallHeight: 68,
          bottomViewHeight: 58,
          bottomViewSmallHeight: 44,
          bottomViewMarginBottomN: utils.getBottomSpace(),
          bottomIconViewSize: 40,
          bottomIconSize: 22,

          rewardTopImage: null,
          rewardBottomImage: null,
          rewardMiddleImage: null,

          rewardViewTopHeight: 0,
          rewardViewBottomHeight: 0,
          rewardViewMarginTop: 0,
          rewardViewMarginBottom: 0,
          rewardViewTitleHeight: 0,
          rewardViewTitleMarginBottom: 24,
          rewardViewContentMarginHorizontal: 36,

          infoViewTopHeight: 35,
          infoViewBottomHeight: 35,
          infoViewContentHorizontalMargin: 16,
          infoViewTitleMarginTop: 12,
        });
        return uiConfigs;
      }
    }
    return {
      showSimpleHeader: true,
      bgColor: ColorBackground,
      safeAreaColor: ColorBackground,
      headerTintColor: NavHeaderTitleTintColor,
      titleColor: ColorTitle,
      subTitleColor: ColorSubTitle,
      accentColor: ColorOrange,
      actionTitleColor: ColorWhite,
      actionBgColor: ColorTheme,
      overlayBgColor: ColorWhite,
      overlayBorderColor: ColorBorder,
      overlayHeadingColor: ColorTitle,
      overlayTitleColor: ColorTitle,
      overlaySubTitleColor: ColorSubTitle,
      rewardHeadingColor: ColorTitle,
      rewardTitleColor: ColorTitle,
      rewardSubTitleColor: ColorSubTitle,
      messageBgColor: ColorPreviewBorder,
      messageBorderColor: ColorBorder,
      textViewExtraSpacing: 2,
      infoViewTitleMarginTop: 2,
      infoViewContentHorizontalMargin: 12,
    };
  }

  static getUiConfigsForOpinion(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FFFEE8';
    let color = '#FFFEE8';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      // showGuideAtFirstLaunch: activity.winGLimit > 0,
      showGuideAtFirstLaunch: true,
      guideMessage: locString('activity.guideOpinion', null, intl),

      backImage: isRTL ? require('src/images/activityOpinion/7-img_back_rtl.png') : require('src/images/activityOpinion/7-img_back.png'),
      moreImage: isRTL ? require('src/images/activityOpinion/7-img_more_rtl.png') : require('src/images/activityOpinion/7-img_more.png'),
      bgImage: require('src/images/activityOpinion/7-img_bg.png'),
      topCenterImage: isRTL ? require('src/images/activityOpinion/7-img_top_center_rtl.png') : require('src/images/activityOpinion/7-img_top_center.png'),
      bottomImage: require('src/images/activityOpinion/7-img_bottom.png'),
      businessBgImage: require('src/images/activityOpinion/7-img_business_bg.png'),
      menuItemBgImage: require('src/images/activityOpinion/7-img_button_bg.png'),
      otherActionImage: require('src/images/activityOpinion/7-img_other_action.png'),
      otherActionNImage: require('src/images/activityOpinion/7-img_other_action.png'),
      rewardTopImage: require('src/images/activityOpinion/7-img_reward_t.png'),
      rewardBottomImage: require('src/images/activityOpinion/7-img_reward_b.png'),
      rewardMiddleImage: require('src/images/activityOpinion/7-img_reward_m.png'),
      infoTopImage: require('src/images/activityOpinion/7-img_info_t.png'),
      infoBottomImage: require('src/images/activityOpinion/7-img_info_b.png'),
      infoMiddleImage: require('src/images/activityOpinion/7-img_info_m.png'),
      gameBgImage: require('src/images/activityOpinion/7-img_game_bg.png'),
      gameBgQBottomImage: require('src/images/activityOpinion/7-img_game_bg_q_b.png'),
      gameBgQTopImage: require('src/images/activityOpinion/7-img_game_bg_q_t.png'),
      gameBgQMiddleImage: require('src/images/activityOpinion/7-img_game_bg_q_m.png'),
      // topCenterMidImage: require('src/images/activityOpinion/7-img_top_center_m.png'),
      // topCenterBottomImage: require('src/images/activityOpinion/7-img_top_center_b.png'),
      cardBgImage: require('src/images/activityOpinion/7-img_card_bg.png'),
      mediaBgImage: require('src/images/activityOpinion/7-img_media_bg.png'),

      bgColor: 'rgb(224,178,158)',
      safeAreaColor: 'rgb(144,49,85)',
      actionTitleColor: ColorWhite,
      businessTitleColor: 'rgb(145,75,0)',
      businessSubTitleColor: 'rgb(185,115,25)',
      bottomMenuTitleColor: 'rgb(165,95,5)',
      titleColor: 'rgb(145,75,0)',
      subTitleColor: 'rgb(165,95,5)',
      overlayHeadingColor: 'rgb(145,75,0)',
      overlayTitleColor: 'rgb(165,95,5)',
      overlaySubTitleColor: 'rgb(185,115,25)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: 'rgb(145,75,0)',
      rewardSubTitleColor: 'rgb(165,95,5)',
      messageBgColor: 'rgb(255,254,232)',
      messageBorderColor: 'rgb(215,132,35)',
      gameValueTextColor: 'rgb(185,115,25)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      contentViewMarginTop: 24,

      rewardViewTopHeight: 72,
      rewardViewBottomHeight: 44,
      rewardViewMarginTop: 0,
      rewardViewMarginBottom: 40,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 24,
      rewardViewContentMarginHorizontal: 46,
      infoViewTopHeight: 21,
      infoViewBottomHeight: 23,
      infoViewContentHorizontalMargin: 20,
      infoViewTitleMarginTop: 12,
    };
  }

  static getUiConfigsForQuestion(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FCEDBE';
    let color = '#FCEDBE';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    const isRTL = !isLTR;
    const uiConfigs = {
      color: color,

      showGuideAtFirstLaunch: activity.isInternal || activity.winGLimit > 0,
      guideMessage: locString('gameQuestionGuide', null, intl),

      backImage: isRTL ? require('src/images/activityQuestion/4-img_back_rtl.png') : require('src/images/activityQuestion/4-img_back.png'),
      moreImage: isRTL ? require('src/images/activityQuestion/4-img_more_rtl.png') : require('src/images/activityQuestion/4-img_more.png'),
      bgImage: require('src/images/activityQuestion/4-img_bg.png'),
      topCenterImage: isRTL ? require('src/images/activityQuestion/4-img_top_center_rtl.png') : require('src/images/activityQuestion/4-img_top_center.png'),
      bottomImage: require('src/images/activityQuestion/4-img_bottom.png'),
      businessBgImage: require('src/images/activityQuestion/4-img_business_bg.png'),
      menuItemBgImage: require('src/images/activityQuestion/4-img_button_bg.png'),
      otherActionImage: require('src/images/activityQuestion/4-img_other_action.png'),
      otherActionNImage: require('src/images/activityQuestion/4-img_other_action.png'),
      rewardTopImage: require('src/images/activityQuestion/4-img_reward_t.png'),
      rewardBottomImage: require('src/images/activityQuestion/4-img_reward_b.png'),
      rewardMiddleImage: require('src/images/activityQuestion/4-img_reward_m.png'),
      infoTopImage: require('src/images/activityQuestion/4-img_info_t.png'),
      infoBottomImage: require('src/images/activityQuestion/4-img_info_b.png'),
      infoMiddleImage: require('src/images/activityQuestion/4-img_info_m.png'),
      gameBgImage: require('src/images/activityQuestion/4-img_game_bg.png'),
      gameBgQBottomImage: require('src/images/activityQuestion/4-img_game_bg_q_b.png'),
      gameBgQTopImage: require('src/images/activityQuestion/4-img_game_bg_q_t.png'),
      gameBgQMiddleImage: require('src/images/activityQuestion/4-img_game_bg_q_m.png'),
      // topCenterMidImage: require('src/images/activityQuestion/4-img_top_center_m.png'),
      // topCenterBottomImage: require('src/images/activityQuestion/4-img_top_center_b.png'),
      cardBgImage: require('src/images/activityQuestion/4-img_card_bg.png'),
      mediaBgImage: require('src/images/activityQuestion/4-img_media_bg.png'),

      bgColor: '#e58a9f',
      safeAreaColor: 'rgb(144,49,85)',
      actionTitleColor: ColorWhite,
      businessTitleColor: 'rgb(145,75,0)',
      businessSubTitleColor: 'rgb(185,115,25)',
      bottomMenuTitleColor: 'rgb(165,95,5)',
      titleColor: 'rgb(145,75,0)',
      subTitleColor: 'rgb(165,95,5)',
      overlayHeadingColor: 'rgb(145,75,0)',
      overlayTitleColor: 'rgb(165,95,5)',
      overlaySubTitleColor: 'rgb(185,115,25)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: 'rgb(145,75,0)',
      rewardSubTitleColor: 'rgb(165,95,5)',
      messageBgColor: 'rgb(252,237,190)',
      messageBorderColor: 'rgb(215,132,35)',
      gameValueTextColor: 'rgb(185,115,25)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      contentViewMarginTop: 24,

      rewardViewTopHeight: 72,
      rewardViewBottomHeight: 44,
      rewardViewMarginTop: 0,
      rewardViewMarginBottom: 40,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 24,
      rewardViewContentMarginHorizontal: 46,
      infoViewTopHeight: 21,
      infoViewBottomHeight: 23,
      infoViewContentHorizontalMargin: 20,
      infoViewTitleMarginTop: 12,
    };

    if (activity.isInternal && activity.next && activity.next.type === constants.activity.types.CHALLENGE) {
      activity.guideMessage = null;
      const description = activity.next.description || locStringA(intl, 'activity.guideChallenge');
      uiConfigs.guideMessage = locStringA(intl, 'activity.guideAnswerToContinueToFun') + description;
      uiConfigs.bgColor = ColorBackground;
      uiConfigs.safeAreaColor = ColorBlack;
    }
    return uiConfigs;
  }

  static getUiConfigsForQuestionnaire(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FFEBBE';
    let color = '#FFEBBE';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    const isRTL = !isLTR;
    const guideMessage = activity.type === ACTIVITY_TYPE_QUESTIONNAIRE ? locString('gameQuestionnaireMessage', null, intl) : locString('guideSurvey', null, intl);
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: guideMessage,

      backImage: isRTL ? require('src/images/activityQuestionnaire/28-img_back_rtl.png') : require('src/images/activityQuestionnaire/28-img_back.png'),
      moreImage: isRTL ? require('src/images/activityQuestionnaire/28-img_more_rtl.png') : require('src/images/activityQuestionnaire/28-img_more.png'),
      bgImage: require('src/images/activityQuestionnaire/28-img_bg.png'),
      topCenterImage: require('src/images/activityQuestionnaire/28-img_top_center.png'),
      topCenterImage1: isRTL ? require('src/images/activityQuestionnaire/28-img_top_center_1.png') : require('src/images/activityQuestionnaire/28-img_top_center_1.png'),
      bottomImage: require('src/images/activityQuestionnaire/28-img_bottom.png'),
      businessBgImage: require('src/images/activityQuestionnaire/28-img_business_bg.png'),
      businessViewBgImage: isRTL ? require('src/images/activityQuestionnaire/28-img_business_view_bg_rtl.png') : require('src/images/activityQuestionnaire/28-img_business_view_bg.png'),
      menuItemBgImage: require('src/images/activityQuestionnaire/28-img_button_bg.png'),
      otherActionImage: require('src/images/activityQuestionnaire/28-img_other_action.png'),
      rewardTopImage: require('src/images/activityQuestionnaire/28-img_reward_t.png'),
      rewardBottomImage: require('src/images/activityQuestionnaire/28-img_reward_b.png'),
      rewardMiddleImage: require('src/images/activityQuestionnaire/28-img_reward_m.png'),
      infoTopImage: require('src/images/activityQuestionnaire/28-img_info_t.png'),
      infoBottomImage: require('src/images/activityQuestionnaire/28-img_info_b.png'),
      infoMiddleImage: require('src/images/activityQuestionnaire/28-img_info_m.png'),
      gameBgEndImage: require('src/images/activityQuestionnaire/28-img_game_bg_end.png'),
      gameBgTopImage: require('src/images/activityQuestionnaire/28-img_game_bg_top.png'),
      gameBgQBottomImage: require('src/images/activityQuestionnaire/28-img_game_bg_q_b.png'),
      gameBgQTopImage: require('src/images/activityQuestionnaire/28-img_game_bg_q_t.png'),
      gameBgQMiddleImage: require('src/images/activityQuestionnaire/28-img_game_bg_q_m.png'),
      mediaBgImage: require('src/images/activityQuestionnaire/28-img_media_bg.png'),
      cardBgImage: require('src/images/activityQuestionnaire/28-img_card_bg.png'),
      nextImage: isRTL ? require('src/images/activityQuestionnaire/28-img_prev.png') : require('src/images/activityQuestionnaire/28-img_next.png'),
      prevImage: isRTL ? require('src/images/activityQuestionnaire/28-img_next.png') : require('src/images/activityQuestionnaire/28-img_prev.png'),

      bgColor: 'rgba(108,88,43,0.8)',
      safeAreaColor: 'rgb(22,101,127)',
      actionTitleColor: 'rgb(255,255,255)',
      businessTitleColor: 'rgb(106,38,5)',
      businessSubTitleColor: 'rgb(126,58,25)',
      bottomMenuTitleColor: 'rgb(245,245,245)',
      titleColor: 'rgb(106,38,5)',
      subTitleColor: 'rgb(126,58,25)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(235,235,235)',
      rewardHeadingColor: 'rgb(138,45,25)',
      rewardTitleColor: 'rgb(158,55,35)',
      rewardSubTitleColor: 'rgb(178,65,45)',
      messageBgColor: 'rgb(255,235,190)',
      messageBorderColor: 'rgb(150,55,35)',
      gameValueTextColor: 'rgb(143,78,51)',

      businessIconBorderRadius: RadiusView,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      topViewSmallHeight: 68,
      bottomViewHeight: 60,
      bottomViewSmallHeight: 44,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 16,

      rewardViewTopHeight: 98,
      rewardViewBottomHeight: 68,
      rewardViewMarginTop: 20,
      rewardViewMarginBottom: 8,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 4,
      rewardViewContentMarginHorizontal: 30,

      infoViewTopHeight: 48,
      infoViewBottomHeight: 50,
      infoViewContentHorizontalMargin: 12,
      infoViewTitleMarginTop: 12,
    };
  }

  static getUiConfigsForChallenge(activity) {
    // let color = activity.themeColor || '#FFFFFF';
    let color = '#FFFFFF';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    return {
      color: color,

      showSimpleHeader: false,

      safeAreaColor: ColorBlack,
      titleColor: '#222222',
      subTitleColor: '#444444',
      accentColor: ColorOrange,
      actionTitleColor: ColorWhite,
      actionBgColor: ColorTheme,
      otherTitleColor: ColorOtherTitle,
      overlayBgColor: ColorBackground,
      overlayBorderColor: ColorBorder,
      overlayHeadingColor: ColorBlack,
      overlayTitleColor: '#222222',
      overlaySubTitleColor: '#444444',
      rewardHeadingColor: ColorBlack,
      rewardTitleColor: '#222222',
      rewardSubTitleColor: '#444444',
      messageBgColor: ColorWhite,
      messageBorderColor: ColorBorder,

      rewardViewTopHeight: 1,
      rewardViewBottomHeight: 1,
      rewardViewMarginTop: 4,
      rewardViewMarginBottom: 0,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 0,
      rewardViewContentMarginHorizontal: 12,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 0,
      textViewExtraSpacing: 2,
    };
  }

  static getUiConfigsForGamePrediction(activity, intl, isLTR) {
    if (!activity.subType) {
      let color = '#2E7D32';
      if (activity.themeColor) {
        color = activity.themeColor;
      }
      if (activity.themeColor) {
        color = activity.themeColor;
      }
      const colorLight = tinycolor(color).lighten().toString();
      const colorTitle = tinycolor.mostReadable(color, ['#000', '#fff']).toString();
      const colorSubTitle = tinycolor(colorTitle).lighten().toString();
      return {
        showGuideAtFirstLaunch: true,
        guideMessage: locString(activity.subType === 1 ? 'gamePredictionMessage' : 'activity.gamePredictWinnerMessage', null, intl),
        bgLImage: require('src/images/activityGamePrediction/12-img_bg_l.png'),

        color: color,
        businessTitleColor: colorTitle,
        businessSubTitleColor: colorSubTitle,
        bottomMenuTitleColor: colorTitle,
        overlayHeadingColor: colorTitle,
        overlayTitleColor: colorTitle,
        overlaySubTitleColor: colorSubTitle,
        bgColor: tinycolor(colorLight).setAlpha(0.2),
        safeAreaColor: tinycolor(colorLight).setAlpha(0.2),
        iconColor: colorTitle,

        gameValueTextColor: 'rgb(255,255,255)',

        businessIconBorderRadius: 8,
        topIconWidth: 72,
        topIconHeight: 72,
        topViewHeight: 72,
        topViewSmallHeight: 68,
        bottomViewHeight: 58,
        bottomViewSmallHeight: 44,
        bottomViewMarginBottomN: utils.getBottomSpace(),
        bottomIconViewSize: 40,
        bottomIconSize: 22,
        contentViewMarginTop: 0,

        rewardViewTopHeight: 0,
        rewardViewBottomHeight: 0,
        rewardViewMarginTop: 0,
        rewardViewMarginBottom: 0,
        rewardViewTitleHeight: 0,
        rewardViewTitleMarginBottom: 24,
        rewardViewContentMarginHorizontal: 36,

        infoViewTopHeight: 35,
        infoViewBottomHeight: 35,
        infoViewContentHorizontalMargin: 16,
        infoViewTitleMarginTop: 12,
      };
    }

    // let color = activity.themeColor || '#09237A';
    let color = '#09237A';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString(activity.subType === 1 ? 'gamePredictionMessage' : 'activity.gamePredictWinnerMessage', null, intl),

      // backImage: isRTL ? require('src/images/activityGamePrediction/12-img_back_rtl.png') : require('src/images/activityGamePrediction/12-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGamePrediction/12-img_more_rtl.png') : require('src/images/activityGamePrediction/12-img_more.png'),
      bgLImage: require('src/images/activityGamePrediction/12-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGamePrediction/12-img_top_center.png'),
      // bottomImage: require('src/images/activityGamePrediction/12-img_bottom.png'),
      // businessBgImage: require('src/images/activityGamePrediction/12-img_business_bg.png'),
      // menuItemBgImage: require('src/images/activityGamePrediction/12-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGamePrediction/12-img_other_action.png'),
      // otherActionNImage: require('src/images/activityGamePrediction/12-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGamePrediction/12-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGamePrediction/12-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGamePrediction/12-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGamePrediction/12-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGamePrediction/12-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGamePrediction/12-img_info_m.png'),
      gameBgImage: require('src/images/activityGamePrediction/12-img_game_bg.png'),
      gameTopImage: require('src/images/activityGamePrediction/12-img_game_top.png'),
      gameTop1Image: require('src/images/activityGamePrediction/12-img_game_top_1.png'),
      gameVsImage: require('src/images/activityGamePrediction/12-img_game_vs.png'),
      gameTextImage: require('src/images/activityGamePrediction/12-img_game_text.png'),
      gameTextLImage: isRTL ? require('src/images/activityGamePrediction/12-img_game_text_r.png') : require('src/images/activityGamePrediction/12-img_game_text_l.png'),
      gameTextRImage: isRTL ? require('src/images/activityGamePrediction/12-img_game_text_l.png') : require('src/images/activityGamePrediction/12-img_game_text_r.png'),

      loadingColor: ColorWhite,
      bgColor: 'rgba(26,175,240,0.8)',
      safeAreaColor: 'rgb(0,39,95)',
      actionTitleColor: 'rgb(255,255,255)',
      businessTitleColor: 'rgb(255,255,255)',
      businessSubTitleColor: 'rgb(245,245,245)',
      bottomMenuTitleColor: 'rgb(245,245,245)',
      titleColor: 'rgb(245,245,245)',
      subTitleColor: 'rgb(235,235,235)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(235,235,235)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: 'rgb(245,245,245)',
      rewardSubTitleColor: 'rgb(235,235,235)',
      messageBgColor: 'rgb(9,35,122)',
      messageBorderColor: 'rgb(200,200,200)',
      gameValueTextColor: 'rgb(255,255,255)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 20,

      rewardViewTopHeight: 58,
      rewardViewBottomHeight: 14,
      rewardViewMarginTop: 10,
      rewardViewMarginBottom: 14,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 6,
      rewardViewContentMarginHorizontal: 35,
      infoViewTopHeight: 14,
      infoViewBottomHeight: 4,
      infoViewContentHorizontalMargin: 42,
      infoViewTitleMarginTop: 20,
    };
  }

  static getUiConfigsForGameGuess(activity, intl, isLTR) {
    // let color = activity.themeColor || '#E9CD8C';
    let color = '#f8d84b';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString('guideGuessMessage', null, intl),

      // backImage: isRTL ? require('src/images/activityGameGuess/19-img_back_rtl.png') : require('src/images/activityGameGuess/19-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameGuess/19-img_more_rtl.png') : require('src/images/activityGameGuess/19-img_more.png'),
      bgLImage: require('src/images/activityGameGuess/19-img_bg_l.png'),
      // topCenterImage: isRTL ? require('src/images/activityGameGuess/19-img_top_center_rtl.png') : require('src/images/activityGameGuess/19-img_top_center.png'),
      // bottomImage: require('src/images/activityGameGuess/19-img_bottom.png'),
      // businessBgImage: require('src/images/activityGameGuess/19-img_business_bg.png'),
      // menuItemBgImage: require('src/images/activityGameGuess/19-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameGuess/19-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameGuess/19-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameGuess/19-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameGuess/19-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameGuess/19-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameGuess/19-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameGuess/19-img_info_m.png'),
      gameBgImage: require('src/images/activityGameGuess/19-img_game_bg.png'),
      mediaBgImage: require('src/images/activityGameGuess/19-img_media_bg.png'),
      cardBgImage: require('src/images/activityGameGuess/19-img_card_bg.png'),
      playImage: require('src/images/activityGameGuess/19-img_play.png'),

      bgColor: '#dfd7b8',
      safeAreaColor: 'rgb(0,52,15)',
      actionTitleColor: 'rgb(255,255,255)',
      businessTitleColor: 'rgb(85,65,45)',
      businessSubTitleColor: 'rgb(105,85,65)',
      bottomMenuTitleColor: 'rgb(65,45,25)',
      titleColor: 'rgb(85,65,45)',
      subTitleColor: 'rgb(105,85,65)',
      overlayHeadingColor: 'rgb(65,45,25)',
      overlayTitleColor: 'rgb(85,65,45)',
      overlaySubTitleColor: 'rgb(105,85,65)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: 'rgb(85,65,45)',
      rewardSubTitleColor: 'rgb(105,85,65)',
      messageBgColor: 'rgb(233,205,140)',
      messageBorderColor: 'rgb(214,178,129)',
      gameValueTextColor: 'rgb(65,45,25)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 24,

      rewardViewTopHeight: 72,
      rewardViewBottomHeight: 44,
      rewardViewMarginTop: 0,
      rewardViewMarginBottom: 40,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 24,
      rewardViewContentMarginHorizontal: 46,
      infoViewTopHeight: 24,
      infoViewBottomHeight: 12,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 20,
    };
  }

  static getUiConfigsForGameIdentifyLocation(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FFE7CD';
    let color = '#623A37';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString('guideIdentifyLocation', null, intl),

      // backImage: isRTL ? require('src/images/activityIdentifyLocation/34-img_back_rtl.png') : require('src/images/activityIdentifyLocation/34-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityIdentifyLocation/34-img_more_rtl.png') : require('src/images/activityIdentifyLocation/34-img_more.png'),
      bgLImage: require('src/images/activityIdentifyLocation/34-img_bg_l.png'),
      // topCenterImage: require('src/images/activityIdentifyLocation/34-img_top_center.png'),
      // bottomImage: require('src/images/activityIdentifyLocation/34-img_bottom.png'),
      // businessBgImage: isRTL ? require('src/images/activityIdentifyLocation/34-img_business_bg_rtl.png') : require('src/images/activityIdentifyLocation/34-img_business_bg.png'),
      // businessViewBgImage: isRTL ? require('src/images/activityIdentifyLocation/34-img_business_view_bg_rtl.png') : require('src/images/activityIdentifyLocation/34-img_business_view_bg.png'),
      // menuItemBgImage: require('src/images/activityIdentifyLocation/34-img_button_bg.png'),
      // otherActionImage: require('src/images/activityIdentifyLocation/34-img_other_action.png'),
      // rewardTopImage: require('src/images/activityIdentifyLocation/34-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityIdentifyLocation/34-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityIdentifyLocation/34-img_reward_m.png'),
      // infoTopImage: require('src/images/activityIdentifyLocation/34-img_info_t.png'),
      // infoBottomImage: require('src/images/activityIdentifyLocation/34-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityIdentifyLocation/34-img_info_m.png'),
      gameBgImage: require('src/images/activityIdentifyLocation/34-img_game_bg.png'),
      gameBg1Image: require('src/images/activityIdentifyLocation/34-img_game_bg_1.png'),
      mediaBgImage: require('src/images/activityIdentifyLocation/34-img_media_bg.png'),
      gameTextBgImage: require('src/images/activityIdentifyLocation/34-img_game_text_bg.png'),
      playImage: require('src/images/activityIdentifyLocation/34-img_play.png'),

      bgColor: 'rgb(83,57,47,0.8)',
      safeAreaColor: 'rgb(60,91,124)',
      actionTitleColor: 'rgb(78,38,35)',
      businessTitleColor: 'rgb(78,38,35)',
      businessSubTitleColor: 'rgb(98,58,55)',
      bottomMenuTitleColor: 'rgb(255,255,255)',
      titleColor: 'rgb(78,38,35)',
      subTitleColor: 'rgb(98,58,55)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(235,235,235)',
      rewardHeadingColor: 'rgb(58,18,15)',
      rewardTitleColor: 'rgb(78,38,35)',
      rewardSubTitleColor: 'rgb(98,58,55)',
      messageBgColor: 'rgb(255,231,205)',
      messageBorderColor: 'rgb(78,38,35)',
      gameValueTextColor: 'rgb(245,245,245)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 8,

      rewardViewTopHeight: 80,
      rewardViewBottomHeight: 60,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 12,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 4,
      rewardViewContentMarginHorizontal: 8,
      infoViewTopHeight: 42,
      infoViewBottomHeight: 24,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 16,
    };
  }

  static getUiConfigsForGameShareNLike(activity) {
    // let color = activity.themeColor || '#EFF5FF';
    let color = '#EFF5FF';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    return {
      color: color,

      showSimpleHeader: true,
      guideMessage: ActivityHelper.getGameCollectValueMessage(activity),

      backImage: isRTL ? require('src/images/activityShareNLike/img_back_rtl.png') : require('src/images/activityShareNLike/img_back.png'),
      moreImage: require('src/images/activityShareNLike/img_more.png'),
      shareImage: require('src/images/activityShareNLike/img_share.png'),
      otherActionImage: require('src/images/activityShareNLike/img_other_action.png'),
      otherActionNImage: require('src/images/activityShareNLike/img_other_action_negative.png'),
      otherActionCancelImage: require('src/images/activityShareNLike/img_other_action_neutral.png'),
      playBgImage: require('src/images/activityShareNLike/img_play_bg.png'),
      playImage: require('src/images/activityShareNLike/img_play.png'),

      safeAreaColor: ColorBackground,
      headerTintColor: '#022D71',
      actionTitleColor: ColorWhite,
      businessTitleColor: '#3C3C5C',
      businessSubTitleColor: '#3A3A5A',
      bottomMenuTitleColor: '#3C3C5C',
      titleColor: '#3C3C5C',
      subTitleColor: '#3A3A5A',
      otherTitleColor: '#8F8E94',
      overlayHeadingColor: '#3C3C5C',
      overlayTitleColor: '#3C3C5C',
      overlaySubTitleColor: '#3A3A5A',
      rewardHeadingColor: '#479D58',
      rewardTitleColor: '#479D58',
      rewardSubTitleColor: '#479D58',
      messageBgColor: ColorBackground,
      messageBorderColor: ColorBorder,
      gameValueTextColor: 'rgb(166,97,6)',
      borderColor: 'rgb(219,247,246)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      contentViewMarginTop: 16,

      rewardViewTopHeight: 1,
      rewardViewBottomHeight: 1,
      rewardViewMarginTop: 4,
      rewardViewMarginBottom: 0,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 0,
      rewardViewContentMarginHorizontal: 16,
      infoViewTopHeight: 10,
      infoViewBottomHeight: 10,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 12,
      textViewExtraSpacing: 4,
    };
  }

  static getUiConfigsForGameSlot(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FFF1DC';
    let color = '#fad075';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locHtmlString('activity.guideSlot'),

      preload: {
        media: [
          // require('src/images/activityGameSlot/13-img_back_rtl.webp'),
          // require('src/images/activityGameSlot/13-img_back.webp'),
          // require('src/images/activityGameSlot/13-img_more_rtl.webp'),
          // require('src/images/activityGameSlot/13-img_more.webp'),
          require('src/images/activityGameSlot/13-img_bg_l.png'),
          // require('src/images/activityGameSlot/13-img_top_center.webp'),
          // require('src/images/activityGameSlot/13-img_bottom.webp'),
          // require('src/images/activityGameSlot/13-img_business_bg.webp'),
          // require('src/images/activityGameSlot/13-img_button_bg.webp'),
          // require('src/images/activityGameSlot/13-img_other_action.webp'),
          // require('src/images/activityGameSlot/13-img_other_action.webp'),
          // require('src/images/activityGameSlot/13-img_info_t.webp'),
          // require('src/images/activityGameSlot/13-img_info_b.webp'),
          // require('src/images/activityGameSlot/13-img_info_m.webp'),
          require('src/images/activityGameSlot/13-img_game_bg_l.webp'),
          require('src/images/activityGameSlot/13-img_game_bg_r.webp'),
          require('src/images/activityGameSlot/13-img_game_bg_m.webp'),
          require('src/images/activityGameSlot/13-img_game_selected.webp'),
          require('src/images/activityGameSlot/13-img_play_bg.webp'),
          require('src/images/activityGameSlot/13-img_play.webp'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameSlot/13-img_back_rtl.webp') : require('src/images/activityGameSlot/13-img_back.webp'),
      // moreImage: isRTL ? require('src/images/activityGameSlot/13-img_more_rtl.webp') : require('src/images/activityGameSlot/13-img_more.webp'),
      // bgImage: require('src/images/activityGameSlot/13-img_bg.webp'),
      bgLImage: require('src/images/activityGameSlot/13-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGameSlot/13-img_top_center.webp'),
      // bottomImage: require('src/images/activityGameSlot/13-img_bottom.webp'),
      // businessBgImage: require('src/images/activityGameSlot/13-img_business_bg.webp'),
      // menuItemBgImage: require('src/images/activityGameSlot/13-img_button_bg.webp'),
      // otherActionImage: require('src/images/activityGameSlot/13-img_other_action.webp'),
      // otherActionNImage: require('src/images/activityGameSlot/13-img_other_action.webp'),
      // rewardTopImage: require('src/images/activityGameSlot/13-img_reward_t.webp'),
      // rewardBottomImage: require('src/images/activityGameSlot/13-img_reward_b.webp'),
      // rewardMiddleImage: require('src/images/activityGameSlot/13-img_reward_m.webp'),
      // infoTopImage: require('src/images/activityGameSlot/13-img_info_t.webp'),
      // infoBottomImage: require('src/images/activityGameSlot/13-img_info_b.webp'),
      // infoMiddleImage: require('src/images/activityGameSlot/13-img_info_m.webp'),
      gameBgSImage: isRTL ? require('src/images/activityGameSlot/13-img_game_bg_l.webp') : require('src/images/activityGameSlot/13-img_game_bg_l.webp'),
      gameBgEImage: isRTL ? require('src/images/activityGameSlot/13-img_game_bg_r.webp') : require('src/images/activityGameSlot/13-img_game_bg_r.webp'),
      gameBgMImage: require('src/images/activityGameSlot/13-img_game_bg_m.webp'),
      gameOverlayImage: require('src/images/activityGameSlot/13-img_game_selected.webp'),
      playBgImage: require('src/images/activityGameSlot/13-img_play_bg.webp'),
      playImage: require('src/images/activityGameSlot/13-img_play.webp'),

      bgColor: 'rgba(37,241,251,0.5)',
      safeAreaColor: '#3C9CFE',
      actionTitleColor: ColorWhite,
      businessTitleColor: 'rgb(92,0,122)',
      businessSubTitleColor: 'rgb(112,0,142)',
      bottomMenuTitleColor: 'rgb(92,0,122)',
      titleColor: 'rgb(92,0,122)',
      subTitleColor: 'rgb(112,0,142)',
      overlayHeadingColor: 'rgb(72,0,102)',
      overlayTitleColor: 'rgb(92,0,122)',
      overlaySubTitleColor: 'rgb(112,0,142)',
      rewardHeadingColor: 'rgb(72,0,102)',
      rewardTitleColor: 'rgb(92,0,122)',
      rewardSubTitleColor: 'rgb(112,0,142)',
      messageBgColor: 'rgb(255,241,220)',
      messageBorderColor: 'rgb(233,182,137)',
      gameValueTextColor: 'rgb(92,0,122)',

      topIconWidth: 90,
      topIconHeight: 100,
      topViewHeight: 100,
      bottomViewHeight: 60,
      contentViewMarginTop: 50,

      rewardViewTopHeight: 76,
      rewardViewBottomHeight: 40,
      rewardViewMarginTop: 22,
      rewardViewMarginBottom: 35,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 0,
      rewardViewContentMarginHorizontal: 40,
      infoViewTopHeight: 30,
      infoViewBottomHeight: 12,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 16,

      spinViewHeight: 105,
      spinViewWidth: 270,
      spinButtonSize: 100,
      spinButtonMarginTop: 35,
      spinTextMarginBottom: 0,
      slotMarginStartEnd: 28,
      slotMarginTop: 28,
      slotMarginBottom: 32,
      slotSelectorBorderStartEnd: 12,
      slotSelectorBorderStartEndP: 2,
      slotSelectorBorderTop: 14,
      slotSelectorBorderBottom: 14,
      slotSpacing: 0,
    };
  }

  static getUiConfigsForGameGreedySpin(activity, intl, isLTR) {
    // let color = activity.themeColor || '#785C53';
    let color = '#785C53';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString('gameGreedySpinMessage', {value: ActivityHelper.getGameValuePlaceholder(activity), value1: activity.gameValue1}, intl),

      backImage: isRTL ? require('src/images/activityGameGreedySpin/24-img_back_rtl.png') : require('src/images/activityGameGreedySpin/24-img_back.png'),
      moreImage: isRTL ? require('src/images/activityGameGreedySpin/24-img_more_rtl.png') : require('src/images/activityGameGreedySpin/24-img_more.png'),
      bgImage: require('src/images/activityGameGreedySpin/24-img_bg.png'),
      topCenterImage: isRTL ? require('src/images/activityGameGreedySpin/24-img_top_center_rtl.png') : require('src/images/activityGameGreedySpin/24-img_top_center.png'),
      bottomImage: require('src/images/activityGameGreedySpin/24-img_bottom.png'),
      businessBgImage: require('src/images/activityGameGreedySpin/24-img_business_bg.png'),
      menuItemBgImage: require('src/images/activityGameGreedySpin/24-img_button_bg.png'),
      otherActionImage: require('src/images/activityGameGreedySpin/24-img_other_action.png'),
      otherActionNImage: require('src/images/activityGameGreedySpin/24-img_other_action.png'),
      rewardTopImage: require('src/images/activityGameGreedySpin/24-img_reward_t.png'),
      rewardBottomImage: require('src/images/activityGameGreedySpin/24-img_reward_b.png'),
      rewardMiddleImage: require('src/images/activityGameGreedySpin/24-img_reward_m.png'),
      infoTopImage: require('src/images/activityGameGreedySpin/24-img_info_t.png'),
      infoBottomImage: require('src/images/activityGameGreedySpin/24-img_info_b.png'),
      infoMiddleImage: require('src/images/activityGameGreedySpin/24-img_info_m.png'),
      gameOuterBgImage: require('src/images/activityGameGreedySpin/24-img_game_bg.png'),
      gameBgMImage: require('src/images/activityGameGreedySpin/24-img_game_bg_m.png'),
      playBgImage: require('src/images/activityGameGreedySpin/24-img_play_bg.png'),
      playImage: require('src/images/activityGameGreedySpin/24-img_play.png'),
      // cardsBgImage: require('src/images/activityGameGreedySpin/24-img_cards_bg.png'),
      // cardBgImage: require('src/images/activityGameGreedySpin/24-img_card_bg.png'),
      collectionImage: require('src/images/activityGameGreedySpin/24-img_collect_value.png'),

      loadingColor: ColorWhite,
      bgColor: 'rgb(82,56,48,0.8)',
      safeAreaColor: 'rgb(162,211,57)',
      actionTitleColor: ColorWhite,
      businessTitleColor: 'rgb(255,255,255)',
      businessSubTitleColor: 'rgb(245,245,245)',
      bottomMenuTitleColor: 'rgb(245,245,245)',
      titleColor: 'rgb(245,245,245)',
      subTitleColor: 'rgb(235,235,235)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(235,235,235)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: 'rgb(245,245,245)',
      rewardSubTitleColor: 'rgb(235,235,235)',
      messageBgColor: 'rgb(120,92,83)',
      messageBorderColor: 'rgb(87,173,171)',
      gameValueTextColor: 'rgb(255,255,255)',
      gameValue1TextColor: 'rgb(255,255,255)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 8,

      rewardViewTopHeight: 130,
      rewardViewBottomHeight: 110,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 88,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 24,
      rewardViewContentMarginHorizontal: 66,
      infoViewTopHeight: 70,
      infoViewBottomHeight: 50,
      infoViewContentHorizontalMargin: 20,
      infoViewTitleMarginTop: 12,

      spinViewHeight: 90,
      spinViewWidth: 280,
      spinButtonSize: 90,
      spinButtonMarginTop: 16,
      spinTextMarginBottom: 8,
      spinTextMarginSide: 28,
      slotMarginStartEnd: 0,
      slotMarginTop: 2,
      slotMarginBottom: 2,
      slotSelectorBorderStartEnd: 0,
      slotSelectorBorderTop: 0,
      slotSelectorBorderBottom: 0,
      slotSpacing: 6,
      slotBgSpacing: 6
    };
  }

  static getUiConfigsForGameBettingPic(activity, intl, isLTR) {
    // let color = activity.themeColor || '#EFCDA9';
    let color = '#57292a';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString('gameBettingPicMessage', null, intl),

      preload: {
        media: [
          // require('src/images/activityGameBettingPic/25-img_back_rtl.png'),
          // require('src/images/activityGameBettingPic/25-img_back.png'),
          // require('src/images/activityGameBettingPic/25-img_more_rtl.png'),
          // require('src/images/activityGameBettingPic/25-img_more.png'),
          require('src/images/activityGameBettingPic/25-img_bg_l.png'),
          // require('src/images/activityGameBettingPic/25-img_top_center_rtl.png'),
          // require('src/images/activityGameBettingPic/25-img_top_center.png'),
          // require('src/images/activityGameBettingPic/25-img_bottom.png'),
          // require('src/images/activityGameBettingPic/25-img_business_bg.png'),
          // require('src/images/activityGameBettingPic/25-img_button_bg.png'),
          // require('src/images/activityGameBettingPic/25-img_other_action.png'),
          // require('src/images/activityGameBettingPic/25-img_other_action.png'),
          // require('src/images/activityGameBettingPic/25-img_reward_t.png'),
          // require('src/images/activityGameBettingPic/25-img_reward_b.png'),
          // require('src/images/activityGameBettingPic/25-img_reward_m.png'),
          // require('src/images/activityGameBettingPic/25-img_info_t.png'),
          // require('src/images/activityGameBettingPic/25-img_info_b.png'),
          // require('src/images/activityGameBettingPic/25-img_info_m.png'),
          require('src/images/activityGameBettingPic/25-img_game_bg.png'),
          require('src/images/activityGameBettingPic/25-img_game_bg_m.png'),
          require('src/images/activityGameBettingPic/25-img_play_bg.png'),
          require('src/images/activityGameBettingPic/25-img_play.png'),
          require('src/images/activityGameBettingPic/25-img_collect_value.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameBettingPic/25-img_back_rtl.png') : require('src/images/activityGameBettingPic/25-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameBettingPic/25-img_more_rtl.png') : require('src/images/activityGameBettingPic/25-img_more.png'),
      bgLImage: require('src/images/activityGameBettingPic/25-img_bg_l.png'),
      // topCenterImage: isRTL ? require('src/images/activityGameBettingPic/25-img_top_center_rtl.png') : require('src/images/activityGameBettingPic/25-img_top_center.png'),
      // bottomImage: require('src/images/activityGameBettingPic/25-img_bottom.png'),
      // businessBgImage: require('src/images/activityGameBettingPic/25-img_business_bg.png'),
      // menuItemBgImage: require('src/images/activityGameBettingPic/25-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameBettingPic/25-img_other_action.png'),
      // otherActionNImage: require('src/images/activityGameBettingPic/25-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameBettingPic/25-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameBettingPic/25-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameBettingPic/25-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameBettingPic/25-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameBettingPic/25-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameBettingPic/25-img_info_m.png'),
      gameOuterBgImage: require('src/images/activityGameBettingPic/25-img_game_bg.png'),
      gameBgMImage: require('src/images/activityGameBettingPic/25-img_game_bg_m.png'),
      playBgImage: require('src/images/activityGameBettingPic/25-img_play_bg.png'),
      playImage: require('src/images/activityGameBettingPic/25-img_play.png'),
      collectionImage: require('src/images/activityGameBettingPic/25-img_collect_value.png'),

      loadingColor: ColorWhite,
      bgColor: 'rgba(162,48,50,0.8)',
      safeAreaColor: 'rgb(80,10,30)',
      actionTitleColor: 'rgb(20,108,59)',
      businessTitleColor: 'rgb(255,255,255)',
      businessSubTitleColor: 'rgb(245,245,245)',
      bottomMenuTitleColor: 'rgb(245,245,245)',
      titleColor: 'rgb(147,59,61)',
      subTitleColor: 'rgb(167,79,81)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(235,235,235)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: 'rgb(147,59,61)',
      rewardSubTitleColor: 'rgb(167,79,81)',
      messageBgColor: 'rgb(239,205,169)',
      messageBorderColor: 'rgb(219,118,66)',
      gameValueTextColor: 'rgb(255,255,255)',
      gameValue1TextColor: 'rgb(255,255,255)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 12,

      rewardViewTopHeight: 85,
      rewardViewBottomHeight: 32,
      rewardViewMarginTop: 14,
      rewardViewMarginBottom: 17,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 16,
      rewardViewContentMarginHorizontal: 16,
      infoViewTopHeight: 70,
      infoViewBottomHeight: 50,
      infoViewContentHorizontalMargin: 20,
      infoViewTitleMarginTop: 12,

      spinViewHeight: 80,
      spinViewWidth: 280,
      spinButtonSize: 80,
      spinButtonMarginTop: 16,
      spinTextMarginBottom: 4,
      spinTextMarginSide: 14,
      slotMarginStartEnd: 0,
      slotMarginTop: 2,
      slotMarginBottom: 5,
      slotSelectorBorderStartEnd: 0,
      slotSelectorBorderTop: 0,
      slotSelectorBorderBottom: 0,
      slotSpacing: 0,
      slotBgSpacing: 0
    };
  }

  static getUiConfigsForGameRoulette(activity, intl, isLTR) {
    // let color = activity.themeColor || '#390062';
    let color = '#390062';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString('gameRouletteMessage', null, intl),

      preload: {
        media: [
          // require('src/images/activityGameRoulette/20-img_back_rtl.png'),
          // require('src/images/activityGameRoulette/20-img_back.png'),
          // require('src/images/activityGameRoulette/20-img_more_rtl.png'),
          // require('src/images/activityGameRoulette/20-img_more.png'),
          require('src/images/activityGameRoulette/20-img_bg_l.png'),
          // require('src/images/activityGameRoulette/20-img_top_center.png'),
          // require('src/images/activityGameRoulette/20-img_bottom.png'),
          // require('src/images/activityGameRoulette/20-img_business_bg.png'),
          // require('src/images/activityGameRoulette/20-img_button_bg.png'),
          // require('src/images/activityGameRoulette/20-img_other_action.png'),
          // require('src/images/activityGameRoulette/20-img_other_action.png'),
          // require('src/images/activityGameRoulette/20-img_reward_t.png'),
          // require('src/images/activityGameRoulette/20-img_reward_b.png'),
          // require('src/images/activityGameRoulette/20-img_reward_m.png'),
          // require('src/images/activityGameRoulette/20-img_info_t.png'),
          // require('src/images/activityGameRoulette/20-img_info_b.png'),
          // require('src/images/activityGameRoulette/20-img_info_m.png'),
          require('src/images/activityGameRoulette/20-img_game_bg_l.png'),
          require('src/images/activityGameRoulette/20-img_game_bg_l.png'),
          require('src/images/activityGameRoulette/20-img_game_bg_r.png'),
          require('src/images/activityGameRoulette/20-img_game_bg_r.png'),
          require('src/images/activityGameRoulette/20-img_game_bg_m.png'),
          require('src/images/activityGameRoulette/20-img_game_selected.png'),
          require('src/images/activityGameRoulette/20-img_play_bg.png'),
          require('src/images/activityGameRoulette/20-img_play.png'),
          require('src/images/activityGameRoulette/20-img_cards_bg.png'),
          require('src/images/activityGameRoulette/20-img_card_bg.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameRoulette/20-img_back_rtl.png') : require('src/images/activityGameRoulette/20-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameRoulette/20-img_more_rtl.png') : require('src/images/activityGameRoulette/20-img_more.png'),
      bgLImage: require('src/images/activityGameRoulette/20-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGameRoulette/20-img_top_center.png'),
      // bottomImage: require('src/images/activityGameRoulette/20-img_bottom.png'),
      // businessBgImage: require('src/images/activityGameRoulette/20-img_business_bg.png'),
      // menuItemBgImage: require('src/images/activityGameRoulette/20-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameRoulette/20-img_other_action.png'),
      // otherActionNImage: require('src/images/activityGameRoulette/20-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameRoulette/20-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameRoulette/20-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameRoulette/20-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameRoulette/20-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameRoulette/20-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameRoulette/20-img_info_m.png'),
      gameBgSImage: isRTL ? require('src/images/activityGameRoulette/20-img_game_bg_l.png') : require('src/images/activityGameRoulette/20-img_game_bg_l.png'),
      gameBgEImage: isRTL ? require('src/images/activityGameRoulette/20-img_game_bg_r.png') : require('src/images/activityGameRoulette/20-img_game_bg_r.png'),
      gameBgMImage: require('src/images/activityGameRoulette/20-img_game_bg_m.png'),
      gameOverlayImage: require('src/images/activityGameRoulette/20-img_game_selected.png'),
      playBgImage: require('src/images/activityGameRoulette/20-img_play_bg.png'),
      playImage: require('src/images/activityGameRoulette/20-img_play.png'),
      cardsBgImage: require('src/images/activityGameRoulette/20-img_cards_bg.png'),
      cardBgImage: require('src/images/activityGameRoulette/20-img_card_bg.png'),

      loadingColor: ColorWhite,
      bgColor: 'rgb(193,81,171,0.8)',
      safeAreaColor: 'rgb(6,26,81)',
      actionTitleColor: ColorWhite,
      businessTitleColor: 'rgb(35,34,30)',
      businessSubTitleColor: 'rgb(55,54,50)',
      bottomMenuTitleColor: 'rgb(245,245,245)',
      titleColor: 'rgb(245,245,245)',
      subTitleColor: 'rgb(235,235,235)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(235,235,235)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: 'rgb(245,245,245)',
      rewardSubTitleColor: 'rgb(235,235,235)',
      messageBgColor: 'rgb(57,0,98)',
      messageBorderColor: 'rgb(190,190,190)',
      gameValueTextColor: 'rgb(255,208,63)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 20,

      rewardViewTopHeight: 70,
      rewardViewBottomHeight: 120,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 52,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 10,
      rewardViewContentMarginHorizontal: 28,
      infoViewTopHeight: 30,
      infoViewBottomHeight: 45,
      infoViewContentHorizontalMargin: 20,
      infoViewTitleMarginTop: 12,

      spinViewHeight: 80,
      spinViewWidth: 380,
      spinButtonSize: 80,
      spinButtonMarginTop: 8,
      spinTextMarginBottom: 14,
      spinTextMarginSide: -40,
      slotMarginStartEnd: 12,
      slotMarginTop: 8,
      slotMarginBottom: 12,
      slotSelectorBorderStartEnd: 12,
      slotSelectorBorderTop: 12,
      slotSelectorBorderBottom: 12,
      slotSpacing: 0,
    };
  }

  static getUiConfigsForGameSpinDiscover(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FFFDD1';
    let color = '#a28f1d';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString('gameSpinDiscoverMessage', null, intl),

      preload: {
        media: [
          // require('src/images/activityGameSpinDiscover/22-img_back_rtl.png'),
          // require('src/images/activityGameSpinDiscover/22-img_back.png'),
          // require('src/images/activityGameSpinDiscover/22-img_more_rtl.png'),
          // require('src/images/activityGameSpinDiscover/22-img_more.png'),
          require('src/images/activityGameSpinDiscover/22-img_bg_l.png'),
          // require('src/images/activityGameSpinDiscover/22-img_top_center.png'),
          // require('src/images/activityGameSpinDiscover/22-img_bottom.png'),
          // require('src/images/activityGameSpinDiscover/22-img_business_bg.png'),
          // require('src/images/activityGameSpinDiscover/22-img_button_bg.png'),
          // require('src/images/activityGameSpinDiscover/22-img_other_action.png'),
          // require('src/images/activityGameSpinDiscover/22-img_other_action.png'),
          // require('src/images/activityGameSpinDiscover/22-img_reward_t.png'),
          // require('src/images/activityGameSpinDiscover/22-img_reward_b.png'),
          // require('src/images/activityGameSpinDiscover/22-img_reward_m.png'),
          // require('src/images/activityGameSpinDiscover/22-img_info_t.png'),
          // require('src/images/activityGameSpinDiscover/22-img_info_b.png'),
          // require('src/images/activityGameSpinDiscover/22-img_info_m.png'),
          require('src/images/activityGameSpinDiscover/22-img_game_bg.png'),
          require('src/images/activityGameSpinDiscover/22-img_game_selected.png'),
          require('src/images/activityGameSpinDiscover/22-img_play_bg.png'),
          require('src/images/activityGameSpinDiscover/22-img_play.png'),
          require('src/images/activityGameSpinDiscover/22-img_cards_bg.png'),
          require('src/images/activityGameSpinDiscover/22-img_card_bg.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameSpinDiscover/22-img_back_rtl.png') : require('src/images/activityGameSpinDiscover/22-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameSpinDiscover/22-img_more_rtl.png') : require('src/images/activityGameSpinDiscover/22-img_more.png'),
      bgLImage: require('src/images/activityGameSpinDiscover/22-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGameSpinDiscover/22-img_top_center.png'),
      // bottomImage: require('src/images/activityGameSpinDiscover/22-img_bottom.png'),
      // businessBgImage: require('src/images/activityGameSpinDiscover/22-img_business_bg.png'),
      // menuItemBgImage: require('src/images/activityGameSpinDiscover/22-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameSpinDiscover/22-img_other_action.png'),
      // otherActionNImage: require('src/images/activityGameSpinDiscover/22-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameSpinDiscover/22-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameSpinDiscover/22-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameSpinDiscover/22-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameSpinDiscover/22-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameSpinDiscover/22-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameSpinDiscover/22-img_info_m.png'),
      gameBgImage: require('src/images/activityGameSpinDiscover/22-img_game_bg.png'),
      gameOverlayImage: require('src/images/activityGameSpinDiscover/22-img_game_selected.png'),
      playBgImage: require('src/images/activityGameSpinDiscover/22-img_play_bg.png'),
      playImage: require('src/images/activityGameSpinDiscover/22-img_play.png'),
      cardsBgImage: require('src/images/activityGameSpinDiscover/22-img_cards_bg.png'),
      slotCardBgImage: require('src/images/activityGameSpinDiscover/22-img_card_bg.png'),

      loadingColor: ColorWhite,
      bgColor: 'rgba(97,27,122,0.8)',
      safeAreaColor: 'rgb(24,13,64)',
      actionTitleColor: ColorWhite,
      businessTitleColor: 'rgb(23,65,6)',
      businessSubTitleColor: 'rgb(30,70,14)',
      bottomMenuTitleColor: 'rgb(253,226,127)',
      titleColor: 'rgb(92,0,122)',
      subTitleColor: 'rgb(112,20,142)',
      overlayHeadingColor: 'rgb(254,246,147)',
      overlayTitleColor: 'rgb(253,226,127)',
      overlaySubTitleColor: 'rgb(252,206,107)',
      rewardHeadingColor: 'rgb(254,246,147)',
      rewardTitleColor: 'rgb(253,226,127)',
      rewardSubTitleColor: 'rgb(252,206,107)',
      messageBgColor: 'rgb(255,253,209)',
      messageBorderColor: 'rgb(183,101,114)',
      gameValueTextColor: 'rgb(125,45,8)',

      topIconWidth: 90,
      topIconHeight: 90,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 8,

      rewardViewTopHeight: 80,
      rewardViewBottomHeight: 84,
      rewardViewMarginTop: 0,
      rewardViewMarginBottom: 44,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 16,
      rewardViewContentMarginHorizontal: 54,
      infoViewTopHeight: 10,
      infoViewBottomHeight: 60,
      infoViewContentHorizontalMargin: 20,
      infoViewTitleMarginTop: 12,

      topViewMargin: 32,
      spinViewHeight: 95,
      spinViewWidth: 360,
      spinButtonSize: 80,
      spinButtonMarginTop: 2,
      spinTextMarginBottom: 4,
      spinTextMarginSide: 16,
      slotMarginStartEnd: 2,
      slotMarginTop: 2,
      slotMarginBottom: 2,
      slotSelectorBorderStartEnd: 11,
      slotSelectorBorderTop: 13,
      slotSelectorBorderBottom: 8,
      slotSpacing: 0,
      slotBgSpacing: 4,
    };
  }

  static getUiConfigsForGameBrandRace(activity, intl, isLTR) {
    // let color = activity.themeColor || '#0268B0';
    let color = '#f35829';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    const gameValuePlaceholder = ''; // this.getGameValuePlaceholder(activity);
    let guideMessage = locString('guideGameBrandRace', {value: gameValuePlaceholder}, intl);
    guideMessage += '\n'+ locString('viewYourAvatar', {value: gameValuePlaceholder}, intl);
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: guideMessage,

      preload: {
        media: [
          // require('src/images/activityGameBrandRace/32-img_back_rtl.png'),
          // require('src/images/activityGameBrandRace/32-img_back.png'),
          // require('src/images/activityGameBrandRace/32-img_more_rtl.png'),
          // require('src/images/activityGameBrandRace/32-img_more.png'),
          require('src/images/activityGameBrandRace/32-img_bg_l.png'),
          // require('src/images/activityGameBrandRace/32-img_top_center.png'),
          // require('src/images/activityGameBrandRace/32-img_bottom.png'),
          // require('src/images/activityGameBrandRace/32-img_business_view_bg.png'),
          // require('src/images/activityGameBrandRace/32-img_button_bg.png'),
          // require('src/images/activityGameBrandRace/32-img_other_action.png'),
          // require('src/images/activityGameBrandRace/32-img_reward_t.png'),
          // require('src/images/activityGameBrandRace/32-img_reward_b.png'),
          // require('src/images/activityGameBrandRace/32-img_reward_m.png'),
          // require('src/images/activityGameBrandRace/32-img_info_t.png'),
          // require('src/images/activityGameBrandRace/32-img_info_b.png'),
          // require('src/images/activityGameBrandRace/32-img_info_m.png'),
          require('src/images/activityGameBrandRace/32-img_game_bg.png'),
          require('src/images/activityGameBrandRace/32-img_play_bg.png'),
          require('src/images/activityGameBrandRace/32-img_play.png'),
          require('src/images/activityGameBrandRace/32-img_game_top_bg_l.png'),
          require('src/images/activityGameBrandRace/32-img_game_top_bg_l.png'),
          require('src/images/activityGameBrandRace/32-img_game_top_bg_r.png'),
          require('src/images/activityGameBrandRace/32-img_game_top_bg_r.png'),
          require('src/images/activityGameBrandRace/32-img_game_top_bg_m.png'),
          require('src/images/activityGameBrandRace/32-img_card_bg.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameBrandRace/32-img_back_rtl.png') : require('src/images/activityGameBrandRace/32-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameBrandRace/32-img_more_rtl.png') : require('src/images/activityGameBrandRace/32-img_more.png'),
      bgLImage: require('src/images/activityGameBrandRace/32-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGameBrandRace/32-img_top_center.png'),
      // bottomImage: require('src/images/activityGameBrandRace/32-img_bottom.png'),
      // businessViewBgImage: require('src/images/activityGameBrandRace/32-img_business_view_bg.png'),
      // menuItemBgImage: require('src/images/activityGameBrandRace/32-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameBrandRace/32-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameBrandRace/32-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameBrandRace/32-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameBrandRace/32-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameBrandRace/32-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameBrandRace/32-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameBrandRace/32-img_info_m.png'),
      gameBgImage: require('src/images/activityGameBrandRace/32-img_game_bg.png'),
      playBgImage: require('src/images/activityGameBrandRace/32-img_play_bg.png'),
      playImage: require('src/images/activityGameBrandRace/32-img_play.png'),
      gameTopBgSImage: isRTL ? require('src/images/activityGameBrandRace/32-img_game_top_bg_l.png') : require('src/images/activityGameBrandRace/32-img_game_top_bg_l.png'),
      gameTopBgEImage: isRTL ? require('src/images/activityGameBrandRace/32-img_game_top_bg_r.png') : require('src/images/activityGameBrandRace/32-img_game_top_bg_r.png'),
      gameTopBgMImage: require('src/images/activityGameBrandRace/32-img_game_top_bg_m.png'),
      slotCardBgImage: require('src/images/activityGameBrandRace/32-img_card_bg.png'),

      loadingColor: ColorWhite,
      safeAreaColor: 'rgb(79,127,255)',
      bgColor: 'rgb(155,208,239)',
      actionTitleColor: ColorWhite,
      businessTitleColor: 'rgb(255,255,255)',
      businessSubTitleColor: 'rgb(245,245,245)',
      bottomMenuTitleColor: '#002517',
      titleColor: 'rgb(255,255,255)',
      subTitleColor: 'rgb(245,245,245)',
      overlayHeadingColor: '#002517',
      overlayTitleColor: '#012819',
      overlaySubTitleColor: '#012819',
      rewardHeadingColor: '#214839',
      rewardTitleColor: 'rgb(255,255,255)',
      rewardSubTitleColor: 'rgb(245,245,245)',
      messageBgColor: 'rgb(2,104,176)',
      messageBorderColor: 'rgb(244,133,21)',
      gameValueTextColor: 'rgb(255,255,255)',

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 8,

      rewardViewTopHeight: 68,
      rewardViewBottomHeight: 30,
      rewardViewMarginTop: 6,
      rewardViewMarginBottom: 10,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 6,
      rewardViewContentMarginHorizontal: 36,
      infoViewTopHeight: 37,
      infoViewBottomHeight: 15,
      infoViewContentHorizontalMargin: 20,
      infoViewTitleMarginTop: 10,

      topViewMargin: 36,
      spinViewHeight: 70,
      spinViewWidth: 240,
      spinButtonSize: 70,
      spinButtonMarginTop: 0,
      spinTextMarginBottom: 40,
      slotMarginStartEnd: 30,
      slotMarginTop: 16,
      slotMarginBottom: 10,
      slotSelectorBorderStartEnd: 0,
      slotSelectorBorderTop: 0,
      slotSelectorBorderBottom: 0,
      slotSpacing: 0,
      slotBgSpacing: 4,
    };
  }

  static getUiConfigsForGameFlipCard(activity, intl, isLTR) {
    // let color = activity.themeColor || '#24E6FF';
    let color = '#2f9eff';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString('getImageFlippedForJackpot', {value: activity.flipsNeeded}, intl),

      preload: {
        media: [
          // require('src/images/activityGameFlip/15-img_back_rtl.png'),
          // require('src/images/activityGameFlip/15-img_back.png'),
          // require('src/images/activityGameFlip/15-img_more_rtl.png'),
          // require('src/images/activityGameFlip/15-img_more.png'),
          require('src/images/activityGameFlip/15-img_bg_l.png'),
          // require('src/images/activityGameFlip/15-img_top_center.png'),
          // require('src/images/activityGameFlip/15-img_bottom.png'),
          // require('src/images/activityGameFlip/15-img_business_view_bg.png'),
          // require('src/images/activityGameFlip/15-img_business_bg.png'),
          // require('src/images/activityGameFlip/15-img_button_bg.png'),
          // require('src/images/activityGameFlip/15-img_other_action.png'),
          // require('src/images/activityGameFlip/15-img_other_action.png'),
          // require('src/images/activityGameFlip/15-img_reward_t.png'),
          // require('src/images/activityGameFlip/15-img_reward_b.png'),
          // require('src/images/activityGameFlip/15-img_reward_m.png'),
          // require('src/images/activityGameFlip/15-img_info_t.png'),
          // require('src/images/activityGameFlip/15-img_info_b.png'),
          // require('src/images/activityGameFlip/15-img_info_m.png'),
          require('src/images/activityGameFlip/15-img_game_bg.png'),
          require('src/images/activityGameFlip/15-img_game_bg_t.png'),
          require('src/images/activityGameFlip/15-img_card_bg.png'),
          require('src/images/activityGameFlip/15-img_timer.png'),
          require('src/images/activityGameFlip/15-img_flip_counter.png'),
          require('src/images/activityGameFlip/15-img_dead.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameFlip/15-img_back_rtl.png') : require('src/images/activityGameFlip/15-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameFlip/15-img_more_rtl.png') : require('src/images/activityGameFlip/15-img_more.png'),
      bgLImage: require('src/images/activityGameFlip/15-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGameFlip/15-img_top_center.png'),
      // bottomImage: require('src/images/activityGameFlip/15-img_bottom.png'),
      // businessViewBgImage: require('src/images/activityGameFlip/15-img_business_view_bg.png'),
      // businessBgImage: require('src/images/activityGameFlip/15-img_business_bg.png'),
      // menuItemBgImage: require('src/images/activityGameFlip/15-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameFlip/15-img_other_action.png'),
      // otherActionNImage: require('src/images/activityGameFlip/15-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameFlip/15-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameFlip/15-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameFlip/15-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameFlip/15-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameFlip/15-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameFlip/15-img_info_m.png'),
      gameBgImage: require('src/images/activityGameFlip/15-img_game_bg.png'),
      gameBgTopImage: require('src/images/activityGameFlip/15-img_game_bg_t.png'),
      cardBgImage: require('src/images/activityGameFlip/15-img_card_bg.png'),
      timerImage: require('src/images/activityGameFlip/15-img_timer.png'),
      flipCounterImage: require('src/images/activityGameFlip/15-img_flip_counter.png'),
      deadImage: require('src/images/activityGameFlip/15-img_dead.png'),

      bgColor: 'rgb(118,203,254,0.8)',
      safeAreaColor: '#3C9CFE',
      actionTitleColor: ColorWhite,
      businessTitleColor: 'rgb(255,255,255)',
      businessSubTitleColor: 'rgb(235,235,235)',
      bottomMenuTitleColor: 'rgb(22,73,147)',
      titleColor: 'rgb(22,73,147)',
      subTitleColor: 'rgb(44,93,167)',
      overlayHeadingColor: 'rgb(2,53,127)',
      overlayTitleColor: 'rgb(22,73,147)',
      overlaySubTitleColor: 'rgb(44,93,167)',
      rewardHeadingColor: 'rgb(2,53,127)',
      rewardTitleColor: 'rgb(255,255,255)',
      rewardSubTitleColor: 'rgb(235,235,235)',
      messageBgColor: 'rgb(36,230,255)',
      messageBorderColor: 'rgb(48,132,199)',
      gameValueTextColor: ColorWhite,

      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 16,

      rewardViewTopHeight: 108,
      rewardViewBottomHeight: 80,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 60,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 12,
      rewardViewContentMarginHorizontal: 54,

      infoViewTopHeight: 85,
      infoViewBottomHeight: 32,
      infoViewContentHorizontalMargin: 24,
      infoViewTitleMarginTop: 20,

      flipBgMarginTop: 14,
      flipBgMarginBottom: 18,
      flipBgMarginStartEnd: 14,
    };
  }

  static getUiConfigsForGameFindHidden(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FCF7F1';
    let color = '#7B4C96';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: activity.flipsNeeded === 1 ? locString('gameFindImageMessage1') : locString('gameFindImageMessage', {value: activity.flipsNeeded}),

      backImage: isRTL ? require('src/images/activityGameFindHidden/img_back_rtl.png') : require('src/images/activityGameFindHidden/img_back.png'),
      moreImage: isRTL ? require('src/images/activityGameFindHidden/img_more_rtl.png') : require('src/images/activityGameFindHidden/img_more.png'),
      bgImage: require('src/images/activityGameFindHidden/img_bg.png'),
      topCenterImage: isRTL ? require('src/images/activityGameFindHidden/img_top_center_rtl.png') : require('src/images/activityGameFindHidden/img_top_center.png'),
      bottomImage: require('src/images/activityGameFindHidden/img_bottom.png'),
      businessBgImage: require('src/images/activityGameFindHidden/img_business_bg.png'),
      menuItemBgImage: require('src/images/activityGameFindHidden/img_button_bg.png'),
      otherActionImage: require('src/images/activityGameFindHidden/img_other_action.png'),
      otherActionNImage: require('src/images/activityGameFindHidden/img_other_action.png'),
      rewardTopImage: require('src/images/activityGameFindHidden/img_reward_t.png'),
      rewardBottomImage: require('src/images/activityGameFindHidden/img_reward_b.png'),
      rewardMiddleImage: require('src/images/activityGameFindHidden/img_reward_m.png'),
      infoTopImage: require('src/images/activityGameFindHidden/img_info_t.png'),
      infoBottomImage: require('src/images/activityGameFindHidden/img_info_b.png'),
      infoMiddleImage: require('src/images/activityGameFindHidden/img_info_m.png'),
      gameBgImage: require('src/images/activityGameFindHidden/img_game_bg.png'),
      gameBgTopImage: require('src/images/activityGameFindHidden/img_game_bg_t.png'),
      cardBgImage: require('src/images/activityGameFindHidden/img_card_bg.png'),
      timerImage: require('src/images/activityGameFindHidden/img_timer.png'),
      flipCounterImage: require('src/images/activityGameFindHidden/img_flip_counter.png'),

      loadingColor: ColorWhite,
      safeAreaColor: 'rgb(247,195,213)',
      actionTitleColor: 'rgb(113,56,130)',
      businessTitleColor: 'rgb(113,56,130)',
      businessSubTitleColor: 'rgb(123,76,150)',
      bottomMenuTitleColor: 'rgb(255,255,255)',
      titleColor: 'rgb(113,56,130)',
      subTitleColor: 'rgb(123,76,150)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(235,235,235)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: 'rgb(113,56,130)',
      rewardSubTitleColor: 'rgb(123,76,150)',
      messageBgColor: 'rgb(252,247,241)',
      messageBorderColor: 'rgb(110,54,126)',
      gameIconColor: 'rbg(62,22,22)',
      gameValueTextColor: 'rgb(113,56,130)',

      businessIconBorderRadius: 20,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 12,

      rewardViewTopHeight: 130,
      rewardViewBottomHeight: 85,
      rewardViewMarginTop: 16,
      rewardViewMarginBottom: 48,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 12,
      rewardViewContentMarginHorizontal: 56,

      infoViewTopHeight: 68,
      infoViewBottomHeight: 21,
      infoViewContentHorizontalMargin: 20,
      infoViewTitleMarginTop: 8,

      flipBgMarginTop: 16,
      flipBgMarginBottom: 16,
      flipBgMarginStartEnd: 16,
    };
  }

  static getUiConfigsForGameMatchPair(activity, intl, isLTR) {
    // let color = activity.themeColor || '#00F0FA';
    let color = '#132e79';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString('gameMatchPairMessage', null, intl),

      preload: {
        media: [
          // require('src/images/activityGameMatchPair/26-img_back_rtl.png'),
          // require('src/images/activityGameMatchPair/26-img_back.png'),
          // require('src/images/activityGameMatchPair/26-img_more_rtl.png'),
          // require('src/images/activityGameMatchPair/26-img_more.png'),
          require('src/images/activityGameMatchPair/26-img_bg_l.png'),
          // require('src/images/activityGameMatchPair/26-img_top_center_rtl.png'),
          // require('src/images/activityGameMatchPair/26-img_top_center.png'),
          // require('src/images/activityGameMatchPair/26-img_bottom.png'),
          // require('src/images/activityGameMatchPair/26-img_business_bg.png'),
          // require('src/images/activityGameMatchPair/26-img_button_bg.png'),
          // require('src/images/activityGameMatchPair/26-img_other_action.png'),
          // require('src/images/activityGameMatchPair/26-img_other_action.png'),
          // require('src/images/activityGameMatchPair/26-img_reward_t.png'),
          // require('src/images/activityGameMatchPair/26-img_reward_b.png'),
          // require('src/images/activityGameMatchPair/26-img_reward_m.png'),
          // require('src/images/activityGameMatchPair/26-img_info_t.png'),
          // require('src/images/activityGameMatchPair/26-img_info_b.png'),
          // require('src/images/activityGameMatchPair/26-img_info_m.png'),
          require('src/images/activityGameMatchPair/26-img_game_bg.png'),
          require('src/images/activityGameMatchPair/26-img_game_bg_t.png'),
          require('src/images/activityGameMatchPair/26-img_card_bg.png'),
          require('src/images/activityGameMatchPair/26-img_timer.png'),
          require('src/images/activityGameMatchPair/26-img_flip_counter.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameMatchPair/26-img_back_rtl.png') : require('src/images/activityGameMatchPair/26-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameMatchPair/26-img_more_rtl.png') : require('src/images/activityGameMatchPair/26-img_more.png'),
      bgLImage: require('src/images/activityGameMatchPair/26-img_bg_l.png'),
      // topCenterImage: isRTL ? require('src/images/activityGameMatchPair/26-img_top_center_rtl.png') : require('src/images/activityGameMatchPair/26-img_top_center.png'),
      // bottomImage: require('src/images/activityGameMatchPair/26-img_bottom.png'),
      // businessBgImage: require('src/images/activityGameMatchPair/26-img_business_bg.png'),
      // menuItemBgImage: require('src/images/activityGameMatchPair/26-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameMatchPair/26-img_other_action.png'),
      // otherActionNImage: require('src/images/activityGameMatchPair/26-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameMatchPair/26-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameMatchPair/26-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameMatchPair/26-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameMatchPair/26-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameMatchPair/26-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameMatchPair/26-img_info_m.png'),
      gameBgImage: require('src/images/activityGameMatchPair/26-img_game_bg.png'),
      gameBgTopImage: require('src/images/activityGameMatchPair/26-img_game_bg_t.png'),
      cardBgImage: require('src/images/activityGameMatchPair/26-img_card_bg.png'),
      timerImage: require('src/images/activityGameMatchPair/26-img_timer.png'),
      flipCounterImage: require('src/images/activityGameMatchPair/26-img_flip_counter.png'),

      bgColor: 'rgba(19,55,138,0.7)',
      safeAreaColor: 'rgb(17,46,135)',
      actionTitleColor: 'rgb(0,90,160)',
      businessTitleColor: 'rgb(0,100,170)',
      businessSubTitleColor: 'rgb(0,120,190)',
      bottomMenuTitleColor: 'rgb(0,90,160)',
      titleColor: 'rgb(0,100,170)',
      subTitleColor: 'rgb(0,120,190)',
      overlayHeadingColor: 'rgb(0,90,160)',
      overlayTitleColor: 'rgb(0,100,170)',
      overlaySubTitleColor: 'rgb(0,120,190)',
      rewardHeadingColor: 'rgb(0,90,160)',
      rewardTitleColor: 'rgb(245,245,245)',
      rewardSubTitleColor: 'rgb(235,235,235)',
      messageBgColor: 'rgb(0,240,250)',
      messageBorderColor: 'rgb(25,89,206)',
      gameIconColor: 'rgb(0,90,160)',
      gameValueTextColor: 'rgb(0,90,160)',

      businessIconBorderRadius: 8,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 12,

      rewardViewTopHeight: 82,
      rewardViewBottomHeight: 52,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 40,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 8,
      rewardViewContentMarginHorizontal: 48,

      infoViewTopHeight: 74,
      infoViewBottomHeight: 4,
      infoViewContentHorizontalMargin: 20,
      infoViewTitleMarginTop: 8,

      flipBgMarginTop: 8,
      flipBgMarginBottom: 8,
      flipBgMarginStartEnd: 6,
    };
  }

  static getUiConfigsForGameTicTacToe(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FED985';
    let color = '#232161';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    let guideMessage = locString('gameTicTacToeMessage', null, intl);
    if (activity.slotValues && activity.slotValues.length > 0 && activity.slotValues[0].imageUrl) {
      guideMessage += '\n'+ locString('yourTicTacToeBelowImage', null, intl);
    }
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: guideMessage,

      preload: {
        media: [
          // require('src/images/activityGameTicTacToe/27-img_back_rtl.png'),
          // require('src/images/activityGameTicTacToe/27-img_back.png'),
          // require('src/images/activityGameTicTacToe/27-img_more_rtl.png'),
          // require('src/images/activityGameTicTacToe/27-img_more.png'),
          require('src/images/activityGameTicTacToe/27-img_bg_l.png'),
          // require('src/images/activityGameTicTacToe/27-img_top_center.png'),
          // require('src/images/activityGameTicTacToe/27-img_bottom.png'),
          // require('src/images/activityGameTicTacToe/27-img_business_bg.png'),
          // require('src/images/activityGameTicTacToe/27-img_business_view_bg_rtl.png'),
          // require('src/images/activityGameTicTacToe/27-img_business_view_bg.png'),
          // require('src/images/activityGameTicTacToe/27-img_button_bg.png'),
          // require('src/images/activityGameTicTacToe/27-img_other_action.png'),
          // require('src/images/activityGameTicTacToe/27-img_reward_t.png'),
          // require('src/images/activityGameTicTacToe/27-img_reward_b.png'),
          // require('src/images/activityGameTicTacToe/27-img_reward_m.png'),
          // require('src/images/activityGameTicTacToe/27-img_info_t.png'),
          // require('src/images/activityGameTicTacToe/27-img_info_b.png'),
          // require('src/images/activityGameTicTacToe/27-img_info_m.png'),
          require('src/images/activityGameTicTacToe/27-img_game_bg.png'),
          require('src/images/activityGameTicTacToe/27-img_game_bg_t.png'),
          require('src/images/activityGameTicTacToe/27-img_game_bg_t_l.png'),
          require('src/images/activityGameTicTacToe/27-img_game_bg_t_r.png'),
          require('src/images/activityGameTicTacToe/27-img_card_bg_odd.png'),
          require('src/images/activityGameTicTacToe/27-img_card_bg_even.png'),
          require('src/images/activityGameTicTacToe/27-img_x.png'),
          require('src/images/activityGameTicTacToe/27-img_o.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameTicTacToe/27-img_back_rtl.png') : require('src/images/activityGameTicTacToe/27-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameTicTacToe/27-img_more_rtl.png') : require('src/images/activityGameTicTacToe/27-img_more.png'),
      bgLImage: require('src/images/activityGameTicTacToe/27-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGameTicTacToe/27-img_top_center.png'),
      // bottomImage: require('src/images/activityGameTicTacToe/27-img_bottom.png'),
      // businessBgImage: require('src/images/activityGameTicTacToe/27-img_business_bg.png'),
      // businessViewBgImage: isRTL ? require('src/images/activityGameTicTacToe/27-img_business_view_bg_rtl.png') : require('src/images/activityGameTicTacToe/27-img_business_view_bg.png'),
      // menuItemBgImage: require('src/images/activityGameTicTacToe/27-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameTicTacToe/27-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameTicTacToe/27-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameTicTacToe/27-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameTicTacToe/27-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameTicTacToe/27-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameTicTacToe/27-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameTicTacToe/27-img_info_m.png'),
      gameBgImage: require('src/images/activityGameTicTacToe/27-img_game_bg.png'),
      gameBgTopImage: require('src/images/activityGameTicTacToe/27-img_game_bg_t.png'),
      gameBgTopLeftImage: require('src/images/activityGameTicTacToe/27-img_game_bg_t_l.png'),
      gameBgTopRightImage: require('src/images/activityGameTicTacToe/27-img_game_bg_t_r.png'),
      cardBgOddImage: require('src/images/activityGameTicTacToe/27-img_card_bg_odd.png'),
      cardBgEvenImage: require('src/images/activityGameTicTacToe/27-img_card_bg_even.png'),
      cardXImage: require('src/images/activityGameTicTacToe/27-img_x.png'),
      cardOImage: require('src/images/activityGameTicTacToe/27-img_o.png'),

      bgColor: 'rgba(71,67,155,0.8)',
      safeAreaColor: 'rgb(21,18,80)',
      actionTitleColor: 'rgb(255,255,255)',
      businessTitleColor: 'rgb(255,47,128)',
      businessSubTitleColor: 'rgb(255,67,148)',
      bottomMenuTitleColor: 'rgb(106,38,5)',
      titleColor: 'rgb(106,38,5)',
      subTitleColor: 'rgb(126,58,25)',
      overlayHeadingColor: 'rgb(86,18,0)',
      overlayTitleColor: 'rgb(106,38,5)',
      overlaySubTitleColor: 'rgb(126,58,25)',
      rewardHeadingColor: 'rgb(254,217,133)',
      rewardTitleColor: 'rgb(255,255,255)',
      rewardSubTitleColor: 'rgb(235,235,235)',
      messageBgColor: 'rgb(254,217,133)',
      messageBorderColor: 'rgb(250,185,73)',
      gameValueTextColor: 'rgb(255,255,255)',

      businessIconBorderRadius: 20,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 0,

      rewardViewTopHeight: 50,
      rewardViewBottomHeight: 32,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 20,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 4,
      rewardViewContentMarginHorizontal: 54,

      infoViewTopHeight: 50,
      infoViewBottomHeight: 40,
      infoViewContentHorizontalMargin: 34,
      infoViewTitleMarginTop: 22,

      flipBgMarginTop: 10,
      flipBgMarginBottom: 10,
      flipBgMarginStartEnd: 10,
    };
  }

  static getUiConfigsForGamePoker(activity, intl, isLTR) {
    // let color = activity.themeColor || '#260D3D';
    let color = '#0e502e';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    let guideMessage = locString('guidePokerMessage', null, intl);
    guideMessage += '\n'+ locString('guidePokerCardsImages', null, intl);
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: guideMessage,

      preload: {
        media: [
          // require('src/images/activityGamePoker/33-img_back_rtl.png'),
          // require('src/images/activityGamePoker/33-img_back.png'),
          // require('src/images/activityGamePoker/33-img_more_rtl.png'),
          // require('src/images/activityGamePoker/33-img_more.png'),
          require('src/images/activityGamePoker/33-img_bg_l.png'),
          // require('src/images/activityGamePoker/33-img_top_center.png'),
          // require('src/images/activityGamePoker/33-img_bottom.png'),
          // require('src/images/activityGamePoker/33-img_business_bg.png'),
          // require('src/images/activityGamePoker/33-img_business_view_bg_rtl.png'),
          // require('src/images/activityGamePoker/33-img_business_view_bg.png'),
          // require('src/images/activityGamePoker/33-img_button_bg.png'),
          // require('src/images/activityGamePoker/33-img_other_action.png'),
          // require('src/images/activityGamePoker/33-img_reward_t.png'),
          // require('src/images/activityGamePoker/33-img_reward_b.png'),
          // require('src/images/activityGamePoker/33-img_reward_m.png'),
          // require('src/images/activityGamePoker/33-img_info_t.png'),
          // require('src/images/activityGamePoker/33-img_info_b.png'),
          // require('src/images/activityGamePoker/33-img_info_m.png'),
          require('src/images/activityGamePoker/33-img_game_bg_t.png'),
          require('src/images/activityGamePoker/33-img_game_score.png'),
          require('src/images/activityGamePoker/33-img_game_uscore.png'),
          require('src/images/activityGamePoker/33-img_i_value.png'),
          require('src/images/activityGamePoker/33-img_card_bg.png'),
          require('src/images/activityGamePoker/33-img_card_bg_1.png'),
          require('src/images/activityGamePoker/33-img_play.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGamePoker/33-img_back_rtl.png') : require('src/images/activityGamePoker/33-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGamePoker/33-img_more_rtl.png') : require('src/images/activityGamePoker/33-img_more.png'),
      bgLImage: require('src/images/activityGamePoker/33-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGamePoker/33-img_top_center.png'),
      // bottomImage: require('src/images/activityGamePoker/33-img_bottom.png'),
      // businessBgImage: require('src/images/activityGamePoker/33-img_business_bg.png'),
      // businessViewBgImage: isRTL ? require('src/images/activityGamePoker/33-img_business_view_bg_rtl.png') : require('src/images/activityGamePoker/33-img_business_view_bg.png'),
      // menuItemBgImage: require('src/images/activityGamePoker/33-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGamePoker/33-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGamePoker/33-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGamePoker/33-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGamePoker/33-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGamePoker/33-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGamePoker/33-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGamePoker/33-img_info_m.png'),
      gameBgTopImage: require('src/images/activityGamePoker/33-img_game_bg_t.png'),
      gameScoreImage: require('src/images/activityGamePoker/33-img_game_score.png'),
      gameUserScoreImage: require('src/images/activityGamePoker/33-img_game_uscore.png'),
      gameIValueBgImage: require('src/images/activityGamePoker/33-img_i_value.png'),
      cardBgImage: require('src/images/activityGamePoker/33-img_card_bg.png'),
      cardBg1Image: require('src/images/activityGamePoker/33-img_card_bg_1.png'),
      playImage: require('src/images/activityGamePoker/33-img_play.png'),

      bgColor: 'rgba(38,61,58,0.8)',
      safeAreaColor: 'rgb(30,17,17)',
      actionTitleColor: 'rgb(255,255,255)',
      businessTitleColor: 'rgb(255,255,255)',
      businessSubTitleColor: 'rgb(245,245,245)',
      bottomMenuTitleColor: 'rgb(255,255,255)',
      titleColor: 'rgb(245,245,245)',
      subTitleColor: 'rgb(225,225,225)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(225,225,225)',
      rewardHeadingColor: 'rgb(8,2,13)',
      rewardTitleColor: 'rgb(28,22,33)',
      rewardSubTitleColor: 'rgb(48,42,53)',
      messageBgColor: 'rgb(38,13,61)',
      messageBorderColor: 'rgb(8,2,13)',
      gameValueTextColor: 'rgb(255,255,255)',

      businessIconBorderRadius: 8,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 4,

      rewardViewTopHeight: 50,
      rewardViewBottomHeight: 50,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 8,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 4,
      rewardViewContentMarginHorizontal: 20,

      infoViewTopHeight: 35,
      infoViewBottomHeight: 35,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 12,
    };
  }

  static getUiConfigsForGameMatchThree(activity, intl, isLTR) {
    // let color = activity.themeColor || '#863C14';
    let color = '#f87d02';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    let guideMessage = locString('guideGameMatchThree', null, intl);
    // if (activity.gameTime && activity.flipsNeeded) {
    //   guideMessage += locString('guidePartInMovesNTime', null, intl);
    // }
    // else if (activity.gameTime) {
    //   guideMessage += locString('guidePartInTime', null, intl);
    // } else if (activity.flipsNeeded) {
    //   guideMessage += locString('guidePartInMoves', null, intl);
    // }
    if (activity.winnersBy === 1) {
      guideMessage += '\n'+ locString('guidePartScoreMoreThanValueToWin', {value: activity.winnersByValue}, intl);
    } else {
      guideMessage += '\n'+ locString('guidePartScoreMaximumToWin', null, intl);
    }
    // const valuePlaceholder = ActivityHelper.getGameValuePlaceholder(activity);
    // guideMessage += '\n\n'+ locString('matchThreeGuidePartBelowGivenWeightage', {value: valuePlaceholder}, intl);
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: guideMessage,

      preload: {
        media: [
          // require('src/images/activityGameMatchThree/29-img_back_rtl.png'),
          // require('src/images/activityGameMatchThree/29-img_back.png'),
          // require('src/images/activityGameMatchThree/29-img_more_rtl.png'),
          // require('src/images/activityGameMatchThree/29-img_more.png'),
          require('src/images/activityGameMatchThree/29-img_bg_l.png'),
          // require('src/images/activityGameMatchThree/29-img_top_center.png'),
          // require('src/images/activityGameMatchThree/29-img_bottom.png'),
          // require('src/images/activityGameMatchThree/29-img_business_bg.png'),
          // require('src/images/activityGameMatchThree/29-img_business_view_bg.png'),
          // require('src/images/activityGameMatchThree/29-img_button_bg.png'),
          // require('src/images/activityGameMatchThree/29-img_other_action.png'),
          // require('src/images/activityGameMatchThree/29-img_reward_t.png'),
          // require('src/images/activityGameMatchThree/29-img_reward_b.png'),
          // require('src/images/activityGameMatchThree/29-img_reward_m.png'),
          // require('src/images/activityGameMatchThree/29-img_info_t.png'),
          // require('src/images/activityGameMatchThree/29-img_info_b.png'),
          // require('src/images/activityGameMatchThree/29-img_info_m.png'),
          require('src/images/activityGameMatchThree/29-img_game_bg.png'),
          require('src/images/activityGameMatchThree/29-img_game_top.png'),
          require('src/images/activityGameMatchThree/29-img_timer.png'),
          require('src/images/activityGameMatchThree/29-img_flip_counter.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameMatchThree/29-img_back_rtl.png') : require('src/images/activityGameMatchThree/29-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameMatchThree/29-img_more_rtl.png') : require('src/images/activityGameMatchThree/29-img_more.png'),
      bgLImage: require('src/images/activityGameMatchThree/29-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGameMatchThree/29-img_top_center.png'),
      // bottomImage: require('src/images/activityGameMatchThree/29-img_bottom.png'),
      // businessBgImage: require('src/images/activityGameMatchThree/29-img_business_bg.png'),
      // businessViewBgImage: require('src/images/activityGameMatchThree/29-img_business_view_bg.png'),
      // menuItemBgImage: require('src/images/activityGameMatchThree/29-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameMatchThree/29-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameMatchThree/29-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameMatchThree/29-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameMatchThree/29-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameMatchThree/29-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameMatchThree/29-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameMatchThree/29-img_info_m.png'),
      gameBgImage: require('src/images/activityGameMatchThree/29-img_game_bg.png'),
      gameTopImage: require('src/images/activityGameMatchThree/29-img_game_top.png'),
      timerImage: require('src/images/activityGameMatchThree/29-img_timer.png'),
      tryCounterImage: require('src/images/activityGameMatchThree/29-img_flip_counter.png'),

      bgColor: 'rgba(222,165,72,0.6)',
      safeAreaColor: 'rgb(88,207,242)',
      actionTitleColor: 'rgb(255,255,255)',
      businessTitleColor: 'rgb(110,40,10)',
      businessSubTitleColor: 'rgb(130,60,20)',
      bottomMenuTitleColor: 'rgb(110,40,10)',
      titleColor: 'rgb(245,245,245)',
      subTitleColor: 'rgb(225,225,225)',
      overlayHeadingColor: 'rgb(110,40,10)',
      overlayTitleColor: 'rgb(130,60,20)',
      overlaySubTitleColor: 'rgb(150,80,30)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: 'rgb(245,245,245)',
      rewardSubTitleColor: 'rgb(235,235,235)',
      messageBgColor: 'rgb(134,60,20)',
      messageBorderColor: 'rgb(99,99,99)',
      gameValueTextColor: 'rgb(245,245,245)',
      gameValue1TextColor: 'rgb(110,40,10)',

      businessIconBorderRadius: 8,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 8,

      rewardViewTopHeight: 150,
      rewardViewBottomHeight: 60,
      rewardViewMarginTop: 16,
      rewardViewMarginBottom: 20,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 24,
      rewardViewContentMarginHorizontal: 48,
      rewardViewMarginHorizontal: 0,

      infoViewTopHeight: 24,
      infoViewBottomHeight: 24,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 10,

      gameBgMarginTop: 12,
      gameBgMarginBottom: 12,
      gameBgMarginStartEnd: 12,
    };
  }

  static getUiConfigsForGameWhacAMole(activity, intl, isLTR) {
    // let color = activity.themeColor || '#460D26';
    let color = '#460D26';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    const valuePlaceholder = ActivityHelper.getGameValuePlaceholder(activity);
    let guideMessage = locString('guideGameWhacAMole', null, intl);
    // if (activity.gameTime) {
    //   guideMessage += locString('guidePartInTime', null, intl);
    // }
    if (activity.winnersBy === 1) {
      guideMessage += '\n'+ locString('guidePartScoreMoreThanValueToWin', {value: activity.winnersByValue}, intl);
    } else {
      guideMessage += '\n'+ locString('guidePartScoreMaximumToWin', null, intl);
    }
    guideMessage += '\n\n'+ locString('matchThreeGuidePartBelowGivenWeightage', {value: valuePlaceholder}, intl);
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: guideMessage,

      preload: {
        media: [
          // require('src/images/activityGameWhacAMole/31-img_back_rtl.png'),
          // require('src/images/activityGameWhacAMole/31-img_back.png'),
          // require('src/images/activityGameWhacAMole/31-img_more_rtl.png'),
          // require('src/images/activityGameWhacAMole/31-img_more.png'),
          require('src/images/activityGameWhacAMole/31-img_bg_l.png'),
          // require('src/images/activityGameWhacAMole/31-img_top_center.png'),
          // require('src/images/activityGameWhacAMole/31-img_bottom.png'),
          // require('src/images/activityGameWhacAMole/31-img_business_view_bg.png'),
          // require('src/images/activityGameWhacAMole/31-img_button_bg.png'),
          // require('src/images/activityGameWhacAMole/31-img_other_action.png'),
          // require('src/images/activityGameWhacAMole/31-img_reward_t.png'),
          // require('src/images/activityGameWhacAMole/31-img_reward_b.png'),
          // require('src/images/activityGameWhacAMole/31-img_reward_m.png'),
          // require('src/images/activityGameWhacAMole/31-img_info_t.png'),
          // require('src/images/activityGameWhacAMole/31-img_info_b.png'),
          // require('src/images/activityGameWhacAMole/31-img_info_m.png'),
          require('src/images/activityGameWhacAMole/31-img_game_bg.png'),
          require('src/images/activityGameWhacAMole/31-img_card_bg.png'),
          require('src/images/activityGameWhacAMole/31-img_timer.png'),
          require('src/images/activityGameWhacAMole/31-img_flip_counter.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGameWhacAMole/31-img_back_rtl.png') : require('src/images/activityGameWhacAMole/31-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGameWhacAMole/31-img_more_rtl.png') : require('src/images/activityGameWhacAMole/31-img_more.png'),
      bgLImage: require('src/images/activityGameWhacAMole/31-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGameWhacAMole/31-img_top_center.png'),
      // bottomImage: require('src/images/activityGameWhacAMole/31-img_bottom.png'),
      // businessBgImage: require('src/images/activityGameWhacAMole/31-img_business_bg.png'),
      // businessViewBgImage: require('src/images/activityGameWhacAMole/31-img_business_view_bg.png'),
      // menuItemBgImage: require('src/images/activityGameWhacAMole/31-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGameWhacAMole/31-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGameWhacAMole/31-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGameWhacAMole/31-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGameWhacAMole/31-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGameWhacAMole/31-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGameWhacAMole/31-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGameWhacAMole/31-img_info_m.png'),
      gameBgImage: require('src/images/activityGameWhacAMole/31-img_game_bg.png'),
      cardBgImage: require('src/images/activityGameWhacAMole/31-img_card_bg.png'),
      timerImage: require('src/images/activityGameWhacAMole/31-img_timer.png'),
      tryCounterImage: require('src/images/activityGameWhacAMole/31-img_flip_counter.png'),

      loadingColor: 'rgb(255,255,255)',
      bgColor: 'rgba(38,62,59,0.8)',
      safeAreaColor: 'rgb(32,34,34)',
      actionTitleColor: 'rgb(70,13,38)',
      businessTitleColor: 'rgb(70,13,38)',
      businessSubTitleColor: 'rgb(70,13,38)',
      bottomMenuTitleColor: 'rgb(255,255,255)',
      titleColor: 'rgb(245,245,245)',
      subTitleColor: 'rgb(225,225,225)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(225,225,225)',
      rewardHeadingColor: 'rgb(70,13,38)',
      rewardTitleColor: 'rgb(245,245,245)',
      rewardSubTitleColor: 'rgb(235,235,235)',
      messageBgColor: 'rgb(70,13,38)',
      messageBorderColor: 'rgb(26,99,3)',
      gameValueTextColor: 'rgb(245,245,245)',
      gameValue1TextColor: 'rgb(255,255,255)',

      businessIconBorderRadius: 12,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 4,

      rewardViewTopHeight: 86,
      rewardViewBottomHeight: 60,
      rewardViewMarginTop: 0,
      rewardViewMarginBottom: 14,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 36,
      rewardViewContentMarginHorizontal: 22,
      rewardViewMarginHorizontal: 0,

      infoViewTopHeight: 54,
      infoViewBottomHeight: 40,
      infoViewContentHorizontalMargin: 24,
      infoViewTitleMarginTop: 24,

      gameBgMarginTop: 54,
      gameBgMarginBottom: 40,
      gameBgMarginStart: 0,
      gameBgMarginEnd: 0,
      gameBgWrapperMarginStart: -60,
      gameBgWrapperMarginEnd: -50,
    };
  }

  static getUiConfigsForGamePuzzleSlide(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FBE19A';
    let color = '#591936';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    let guideMessage = locString('gameGamePuzzle', null, intl);
    if (activity.gameTime && activity.flipsNeeded) {
      guideMessage += locString('guidePartInMovesNTime', null, intl);
    } else if (activity.gameTime) {
      guideMessage += locString('guidePartInTime', null, intl);
    } else if (activity.flipsNeeded) {
      guideMessage += locString('guidePartInMoves', null, intl);
    }
    guideMessage += '\n'+ locString('puzzleGuidePartCompleteGameToWin', null, intl);
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: guideMessage,

      preload: {
        media: [
          // require('src/images/activityGamePuzzleSlide/35-img_back_rtl.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_back.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_more_rtl.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_more.png'),
          require('src/images/activityGamePuzzleSlide/35-img_bg_l.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_top_center.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_bottom.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_business_bg.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_business_view_bg_rtl.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_business_view_bg.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_button_bg.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_other_action.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_reward_t.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_reward_b.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_reward_m.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_info_t.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_info_b.png'),
          // require('src/images/activityGamePuzzleSlide/35-img_info_m.png'),
          require('src/images/activityGamePuzzleSlide/35-img_game_bg.png'),
          require('src/images/activityGamePuzzleSlide/35-img_game_media.png'),
          require('src/images/activityGamePuzzleSlide/35-img_timer.png'),
          require('src/images/activityGamePuzzleSlide/35-img_flip_counter.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGamePuzzleSlide/35-img_back_rtl.png') : require('src/images/activityGamePuzzleSlide/35-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGamePuzzleSlide/35-img_more_rtl.png') : require('src/images/activityGamePuzzleSlide/35-img_more.png'),
      bgLImage: require('src/images/activityGamePuzzleSlide/35-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGamePuzzleSlide/35-img_top_center.png'),
      // bottomImage: require('src/images/activityGamePuzzleSlide/35-img_bottom.png'),
      // businessBgImage: require('src/images/activityGamePuzzleSlide/35-img_business_bg.png'),
      // businessViewBgImage: isRTL ? require('src/images/activityGamePuzzleSlide/35-img_business_view_bg_rtl.png') : require('src/images/activityGamePuzzleSlide/35-img_business_view_bg.png'),
      // menuItemBgImage: require('src/images/activityGamePuzzleSlide/35-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGamePuzzleSlide/35-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGamePuzzleSlide/35-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGamePuzzleSlide/35-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGamePuzzleSlide/35-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGamePuzzleSlide/35-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGamePuzzleSlide/35-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGamePuzzleSlide/35-img_info_m.png'),
      gameBgImage: require('src/images/activityGamePuzzleSlide/35-img_game_bg.png'),
      gameMediaImage: require('src/images/activityGamePuzzleSlide/35-img_game_media.png'),
      timerImage: require('src/images/activityGamePuzzleSlide/35-img_timer.png'),
      tryCounterImage: require('src/images/activityGamePuzzleSlide/35-img_flip_counter.png'),

      bgColor: 'rgba(76,22,74,0.8)',
      safeAreaColor: 'rgb(59,18,82)',
      actionTitleColor: 'rgb(255,255,255)',
      businessTitleColor: 'rgb(59,18,82)',
      businessSubTitleColor: 'rgb(79,38,102)',
      bottomMenuTitleColor: 'rgb(255,255,255)',
      titleColor: 'rgb(170,85,1)',
      subTitleColor: 'rgb(190,105,25)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(235,235,235)',
      rewardHeadingColor: 'rgb(251,225,154)',
      rewardTitleColor: 'rgb(170,85,1)',
      rewardSubTitleColor: 'rgb(190,105,25)',
      messageBgColor: 'rgb(251,225,154)',
      messageBorderColor: 'rgb(219,145,46)',
      gameValueTextColor: 'rgb(59,18,82)',

      businessIconBorderRadius: 4,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 4,

      rewardViewTopHeight: 120,
      rewardViewBottomHeight: 60,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 16,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 16,
      rewardViewContentMarginHorizontal: 20,

      infoViewTopHeight: 62,
      infoViewBottomHeight: 22,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 10,

      gameBgMarginTop: 24,
      gameBgMarginBottom: 12,
      gameBgMarginStartEnd: 12,
    };
  }

  static getUiConfigsForGamePuzzleTap(activity, intl, isLTR) {
    // let color = activity.themeColor || '#FBE19A';
    let color = '#784204';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const isRTL = !isLTR;
    let guideMessage = locString('gameGamePuzzleTapMultiple', null, intl);
    if (activity.gameTime && activity.flipsNeeded) {
      guideMessage += locString('guidePartInMovesNTime', null, intl);
    } else if (activity.gameTime) {
      guideMessage += locString('guidePartInTime', null, intl);
    } else if (activity.flipsNeeded) {
      guideMessage += locString('guidePartInMoves', null, intl);
    }
    guideMessage += '\n'+ locString('puzzleGuidePartCompleteGameToWin', null, intl);
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: guideMessage,

      preload: {
        media: [
          // require('src/images/activityGamePuzzle/30-img_back_rtl.png'),
          // require('src/images/activityGamePuzzle/30-img_back.png'),
          // require('src/images/activityGamePuzzle/30-img_more_rtl.png'),
          // require('src/images/activityGamePuzzle/30-img_more.png'),
          require('src/images/activityGamePuzzle/30-img_bg_l.png'),
          // require('src/images/activityGamePuzzle/30-img_top_center.png'),
          // require('src/images/activityGamePuzzle/30-img_bottom.png'),
          // require('src/images/activityGamePuzzle/30-img_business_bg.png'),
          // require('src/images/activityGamePuzzle/30-img_business_view_bg_rtl.png'),
          // require('src/images/activityGamePuzzle/30-img_business_view_bg.png'),
          // require('src/images/activityGamePuzzle/30-img_button_bg.png'),
          // require('src/images/activityGamePuzzle/30-img_other_action.png'),
          // require('src/images/activityGamePuzzle/30-img_reward_t.png'),
          // require('src/images/activityGamePuzzle/30-img_reward_b.png'),
          // require('src/images/activityGamePuzzle/30-img_reward_m.png'),
          // require('src/images/activityGamePuzzle/30-img_info_t.png'),
          // require('src/images/activityGamePuzzle/30-img_info_b.png'),
          // require('src/images/activityGamePuzzle/30-img_info_m.png'),
          // require('src/images/activityGamePuzzle/30-img_game_bg.png'),
          // require('src/images/activityGamePuzzle/30-img_game_media.png'),
          // require('src/images/activityGamePuzzle/30-img_timer.png'),
          // require('src/images/activityGamePuzzle/30-img_flip_counter.png'),
        ]
      },

      // backImage: isRTL ? require('src/images/activityGamePuzzle/30-img_back_rtl.png') : require('src/images/activityGamePuzzle/30-img_back.png'),
      // moreImage: isRTL ? require('src/images/activityGamePuzzle/30-img_more_rtl.png') : require('src/images/activityGamePuzzle/30-img_more.png'),
      bgLImage: require('src/images/activityGamePuzzle/30-img_bg_l.png'),
      // topCenterImage: require('src/images/activityGamePuzzle/30-img_top_center.png'),
      // bottomImage: require('src/images/activityGamePuzzle/30-img_bottom.png'),
      // businessBgImage: require('src/images/activityGamePuzzle/30-img_business_bg.png'),
      // businessViewBgImage: isRTL ? require('src/images/activityGamePuzzle/30-img_business_view_bg_rtl.png') : require('src/images/activityGamePuzzle/30-img_business_view_bg.png'),
      // menuItemBgImage: require('src/images/activityGamePuzzle/30-img_button_bg.png'),
      // otherActionImage: require('src/images/activityGamePuzzle/30-img_other_action.png'),
      // rewardTopImage: require('src/images/activityGamePuzzle/30-img_reward_t.png'),
      // rewardBottomImage: require('src/images/activityGamePuzzle/30-img_reward_b.png'),
      // rewardMiddleImage: require('src/images/activityGamePuzzle/30-img_reward_m.png'),
      // infoTopImage: require('src/images/activityGamePuzzle/30-img_info_t.png'),
      // infoBottomImage: require('src/images/activityGamePuzzle/30-img_info_b.png'),
      // infoMiddleImage: require('src/images/activityGamePuzzle/30-img_info_m.png'),
      gameBgImage: require('src/images/activityGamePuzzle/30-img_game_bg.png'),
      gameMediaImage: require('src/images/activityGamePuzzle/30-img_game_media.png'),
      timerImage: require('src/images/activityGamePuzzle/30-img_timer.png'),
      tryCounterImage: require('src/images/activityGamePuzzle/30-img_flip_counter.png'),

      bgColor: 'rgba(97,45,0,0.8)',
      safeAreaColor: 'rgb(52,21,0)',
      actionTitleColor: 'rgb(255,255,255)',
      businessTitleColor: 'rgb(245,245,245)',
      businessSubTitleColor: 'rgb(225,225,225)',
      bottomMenuTitleColor: 'rgb(52,21,0)',
      titleColor: 'rgb(170,85,1)',
      subTitleColor: 'rgb(190,105,25)',
      overlayHeadingColor: 'rgb(52,21,0)',
      overlayTitleColor: 'rgb(72,41,10)',
      overlaySubTitleColor: 'rgb(92,61,20)',
      rewardHeadingColor: 'rgb(251,225,154)',
      rewardTitleColor: 'rgb(170,85,1)',
      rewardSubTitleColor: 'rgb(190,105,25)',
      messageBgColor: 'rgb(251,225,154)',
      messageBorderColor: 'rgb(219,145,46)',
      gameValueTextColor: 'rgb(59,18,82)',

      businessIconBorderRadius: 4,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 4,

      rewardViewTopHeight: 120,
      rewardViewBottomHeight: 60,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 16,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 16,
      rewardViewContentMarginHorizontal: 20,

      infoViewTopHeight: 42,
      infoViewBottomHeight: 42,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 10,

      gameBgMarginTop: 8,
      gameBgMarginBottom: 8,
      gameBgMarginStartEnd: 8,
    };
  }

  static getUiConfigsForGameCollectStar(activity) {
    // let color = activity.themeColor || '#FF474D';
    let color = '#FF474D';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    return {
      color: color,

      showGuideAtFirstLaunch: true,
      guideMessage: locString('flipToCollectStars', {value: activity.flipsNeeded, value1: ActivityHelper.getGameValuePlaceholder(activity)}) +'\n'+ ActivityHelper.getGameCollectValueMessage(activity),

      backImage: isRTL ? require('src/images/activityGameCollect/img_back_rtl.png') : require('src/images/activityGameCollect/img_back.png'),
      moreImage: isRTL ? require('src/images/activityGameCollect/img_more_rtl.png') : require('src/images/activityGameCollect/img_more.png'),
      bgImage: require('src/images/activityGameCollect/img_bg.png'),
      topCenterImage: require('src/images/activityGameCollect/img_top_center.png'),
      bottomImage: require('src/images/activityGameCollect/img_bottom.png'),
      businessBgImage: require('src/images/activityGameCollect/img_business_bg.png'),
      businessViewBgImage: require('src/images/activityGameCollect/img_business_view_bg.png'),
      menuItemBgImage: require('src/images/activityGameCollect/img_button_bg.png'),
      otherActionImage: require('src/images/activityGameCollect/img_other_action.png'),
      otherActionNImage: require('src/images/activityGameCollect/img_other_action.png'),
      rewardTopImage: require('src/images/activityGameCollect/img_reward_t.png'),
      rewardBottomImage: require('src/images/activityGameCollect/img_reward_b.png'),
      rewardMiddleImage: require('src/images/activityGameCollect/img_reward_m.png'),
      infoTopImage: require('src/images/activityGameCollect/img_info_t.png'),
      infoBottomImage: require('src/images/activityGameCollect/img_info_b.png'),
      infoMiddleImage: require('src/images/activityGameCollect/img_info_m.png'),
      gameBgImage: require('src/images/activityGameCollect/img_game_bg.png'),
      gameBgTopImage: require('src/images/activityGameCollect/img_game_bg_t.png'),
      cardBgImage: require('src/images/activityGameCollect/img_card_bg.png'),
      timerImage: require('src/images/activityGameCollect/img_timer.png'),
      flipCounterImage: require('src/images/activityGameCollect/img_flip_counter.png'),
      collectionImage: require('src/images/activityGameCollect/img_collect_value.png'),

      safeAreaColor: 'rgb(240,240,240)',
      actionTitleColor: ColorWhite,
      businessTitleColor: 'rgb(245,245,245)',
      businessSubTitleColor: 'rgb(235,235,235)',
      bottomMenuTitleColor: 'rgb(255,255,255)',
      titleColor: 'rgb(245,245,245)',
      subTitleColor: 'rgb(235,235,235)',
      overlayHeadingColor: 'rgb(255,255,255)',
      overlayTitleColor: 'rgb(245,245,245)',
      overlaySubTitleColor: 'rgb(235,235,235)',
      rewardHeadingColor: 'rgb(255,255,255)',
      rewardTitleColor: '#333333',
      rewardSubTitleColor: '#555555',
      messageBgColor: 'rgb(255,71,77)',
      messageBorderColor: 'rgb(171,2,2)',
      gameIconColor: '#FFC600',
      gameValueTextColor: ColorWhite,

      businessIconBorderRadius: 20,
      topIconWidth: 85,
      topIconHeight: 85,
      topViewHeight: 100,
      bottomViewHeight: 60,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      contentViewMarginTop: 12,

      rewardViewTopHeight: 80,
      rewardViewBottomHeight: 80,
      rewardViewMarginTop: 8,
      rewardViewMarginBottom: 39,
      rewardViewTitleHeight: 44,
      rewardViewTitleMarginBottom: 24,
      rewardViewContentMarginHorizontal: 24,

      infoViewTopHeight: 55,
      infoViewBottomHeight: 18,
      infoViewContentHorizontalMargin: 24,
      infoViewTitleMarginTop: 20,

      flipBgMarginTop: 16,
      flipBgMarginBottom: 20,
      flipBgMarginStartEnd: 16,
    };
  }

  static getUiConfigsForGameExternal(activity, intl, isLTR) {
    let color = '#FFF1DC';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const colorLight = tinycolor(color).lighten().toString();
    const colorTitle = tinycolor.mostReadable(color, ['#000', '#fff']).toString();
    const colorSubTitle = tinycolor(colorTitle).lighten().toString();
    let guideMessage = locString('guidePokerMessage', null, intl);
    guideMessage += '\n'+ locString('guidePokerCardsImages', null, intl);
    return {
      color: color,
      businessTitleColor: colorTitle,
      businessSubTitleColor: colorSubTitle,
      bottomMenuTitleColor: colorTitle,
      overlayHeadingColor: colorTitle,
      overlayTitleColor: colorTitle,
      overlaySubTitleColor: colorSubTitle,
      bgColor: tinycolor(colorLight).setAlpha(0.2),
      safeAreaColor: tinycolor(colorLight).setAlpha(0.2),
      iconColor: colorTitle,

      showGuideAtFirstLaunch: true,
      guideMessage: guideMessage,

      gameValueTextColor: 'rgb(255,255,255)',

      businessIconBorderRadius: 8,
      topIconWidth: 72,
      topIconHeight: 72,
      topViewHeight: 72,
      topViewSmallHeight: 68,
      bottomViewHeight: 58,
      bottomViewSmallHeight: 44,
      bottomViewMarginBottomN: utils.getBottomSpace(),
      bottomIconViewSize: 40,
      bottomIconSize: 22,
      contentViewMarginTop: 0,

      rewardViewTopHeight: 0,
      rewardViewBottomHeight: 0,
      rewardViewMarginTop: 0,
      rewardViewMarginBottom: 0,
      rewardViewTitleHeight: 0,
      rewardViewTitleMarginBottom: 24,
      rewardViewContentMarginHorizontal: 36,

      infoViewTopHeight: 35,
      infoViewBottomHeight: 35,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 12,
    };
  }

  static getUiConfigsForGameSlides(activity, intl, isLTR) {
    let color = activity.type === constants.activity.types.SLIDES ? '#4de7ff' : '#4E342E';
    if (activity.gameInfo && activity.gameInfo.themeColor) {
      color = activity.gameInfo.themeColor;
    }
    if (activity.themeColor) {
      color = activity.themeColor;
    }
    const colorLight = tinycolor(color).lighten().toString();
    const colorTitle = tinycolor.mostReadable(color, ['#000', '#fff']).toString();
    const colorSubTitle = tinycolor(colorTitle).lighten().toString();
    return {
      color: color,
      businessTitleColor: colorTitle,
      businessSubTitleColor: colorSubTitle,
      bottomMenuTitleColor: colorTitle,
      overlayHeadingColor: colorTitle,
      overlayTitleColor: colorTitle,
      overlaySubTitleColor: colorSubTitle,
      bgColor: tinycolor(colorLight).setAlpha(0.2),
      safeAreaColor: tinycolor(colorLight).setAlpha(0.2),
      iconColor: colorTitle,

      showGuideAtFirstLaunch: true,
      guideMessage: null,

      gameValueTextColor: 'rgb(255,255,255)',

      businessIconBorderRadius: 8,
      topIconWidth: 72,
      topIconHeight: 72,
      topViewHeight: 72,
      topViewSmallHeight: 68,
      bottomViewHeight: 58,
      bottomViewSmallHeight: 44,
      bottomViewMarginBottomN: 0,
      bottomIconViewSize: 40,
      bottomIconSize: 22,
      contentViewMarginTop: 0,

      rewardViewTopHeight: 0,
      rewardViewBottomHeight: 0,
      rewardViewMarginTop: 0,
      rewardViewMarginBottom: 0,
      rewardViewTitleHeight: 0,
      rewardViewTitleMarginBottom: 24,
      rewardViewContentMarginHorizontal: 36,

      infoViewTopHeight: 35,
      infoViewBottomHeight: 35,
      infoViewContentHorizontalMargin: 16,
      infoViewTitleMarginTop: 12,
    };
  }

  static invokeActivity(activity) {
    // NavigationService.push(NAVIGATION_NAME_ACTIVITY_DETAIL, {
    //   activityId: activity.id,
    //   activityType: activity.type,
    //   dp: activity.dp,
    //   aid: activity.aid,
    // });
  }

  static invokeImage(imageUrls) {
    // NavigationService.push(ImageScreen.getNavigationName(), {
    //   imageUrls: imageUrls,
    // });
  }

  static hasReverseImage(activityType) {
    return activityType === constants.activity.types.GAME_BRAND_RACE
    || activityType === constants.activity.types.GAME_SPIN_N_DISCOVER
    || activityType === constants.activity.types.GAME_WHAC_A_MOLE;
  }

  static isReward(activityType) {
    return activityType === constants.activity.types.OFFER
      || activityType === constants.activity.types.DISCOUNT
      || activityType === constants.activity.types.VOUCHER;
  }
}



