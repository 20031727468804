export default class FileHelper {

  static getFileNameFromString(fileName) {
    if (fileName) {
      return fileName.substring(fileName.lastIndexOf('/') + 1)
    }
    return null;
  }

  static isFileNameSame(file, fileName) {
    return file && file.url == fileName;
  }

  static areFilesSame(file1, file2) {
    if (file1 && file2) {
      return file1.url == file2.url;
    }
    return false;
  }
}
