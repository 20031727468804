import React from 'react';

const AppImage = ({style = {}, imageUrl, source, borderRadius, resizeMode = 'cover', ...props}) => {
  // Adjust resizeMode if necessary
  const objectFit = resizeMode === 'stretch' ? 'fill' : resizeMode;

  // Combine styles with defaults
  const combinedStyle = {
    ...style,
    objectFit,
    ...(borderRadius && { borderRadius }),
  };

  const handleError = (event) => {
    // load empty image
    event.target.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFklEQVR42mNgGAWjYBSMglEwCwB+AB7Qyp1XAAAAAElFTkSuQmCC"; // Change to your placeholder image
  };

  return (
    <img
      alt=""
      src={imageUrl || source}
      style={combinedStyle}
      onError={handleError}
      {...props}
    />
  );
};

export default AppImage;
