export default {
  account: 'الحساب',
  activity: 'الحملات',
  add: 'اضافة',
  addFeedback: 'أضف ملاحظات',
  addMessage: 'اضف رسالة',
  agoSecondShort: '{0}s',
  agoMinuteShort: '{0}m',
  agoHourShort: '{0}h',
  agoDayShort: '{0}d',
  agoMonthShort: '{0}M',
  agoYearShort: '{0}y',
  application: 'التطبيق',
  approve: 'موافق',
  audioFromMe: 'مقطع صوتي',
  audioFromSystem: 'مقطع صوتي من Rab7 ',
  audioFromUserName: 'مقطع صوتي كم {0}',
  audioHelp: 'مساعدة',
  back: 'رجوع',
  birthday: 'تاريخ الميلاد',
  birthdaySubtitle: 'وش اليوم اللي تزينت فيك الدنيا',
  birthdayWithColor: 'تاريخ الميلاد:',
  camera: 'الكاميرا',
  cancel: 'إلغاء',
  cashier: 'محاسب',
  changePassword: 'تغيير كلمة المرور',
  chatWithUs: 'تحدث معنا ',
  checkRequest: 'شيك على طلبك',
  city: 'المدينة',
  cityHint : ' اكتب المدينة اللي ساكن فيها وراح تطلع لك علشان تختارها ,اذا مالقيت مدينتك , اختر اقرب مدينة لها وراسلنا علشان نحطها' ,
  cityNDistrict: 'المكان اللي تنوره',
  cityNDistrictSubtitle: 'اختر البلد والمدينة والحي اللي ساكن فيه ، علشان نجيب لك العروض القريبة منك ، واذا مالقيت اختر الاقرب لك',
  cityWithColon: 'المدينة :',
  confirmation: 'تأكيد',
  confirmPassword: 'تأكيد كلمة المرور',
  congratulations: 'أحسنت',
  dateWithDate: 'التاريخ: {0}',
  daysToGo: '{days} ايام',
  delete: 'حذف',
  discard: 'تراجع',
  discardPromptMessage: 'متأكد انك ما تبي هذي التعديلات ؟',
  discountWithColon: 'خصم: ',
  district: 'حي',
  districtHint: 'اكتب الحي اللي ساكن فيه وراح يطلع لك زلو ماطلع لك اختر أي حي مجاور له وراسلنا نحطه في القائمة',
  districtWithColon: 'حي:',
  earn: 'اكتساب',
  earnedWithColon: 'اكتساب: ',
  eBachelor: 'بكالوريوس',
  eDiploma: 'دبلوم',
  eDoctorate: 'دكتوراه',
  educationLevel: 'المستوى التعليمي',
  educationLevelHint: 'اختر المستوى التعليمي الخاص بك من القائمة',
  educationLevelSubTitle: 'وين وصل مشوارك مع التعليم',
  educationLevelWithColon: 'المستوى التعليمي: ',
  eHighSchool: 'الثانوية',
  eligibility: 'استحقاق الدخول في سحب الجوائز',
  eligible: 'كفو عليك , تأهلت للمكافئة',
  eligibleForPrize: 'أنت الحين من المتأهلين للسحب على المكافئة',
  email: 'البريد الالكتروني',
  emailSubtitle: 'فضلا اكتب بريدك الالكتروني',
  emailWithColon: 'البريد الالكتروني:',
  eMaster: 'ماجستير',
  english: 'انجليزي',
  eNotInAcademicSector: 'لم التحق بالتعليم',
  enterFeedback: 'شاركنا برايك',
  enterVerificationCodeMessage: 'اطلب من العميل رقم التفعيل الذي تم ارساله له',
  ePrimarySchool: 'الابتدائية',
  errorApiGeneric: 'معليش في خطأ حصل بالاتصال , بس لايهمك حاول مرة ثاني وراح يضبط معك ',
  errorAudioPlayGeneric: 'غير قادر على تشغيل الصوت الان .',
  errorAudioPlayNoNetwork: 'تفقد اتصالك بالانترنت .',
  errorAudioRecordGeneric: 'حدث خطأ ما , غير قادر على تسجيل الصوت.',
  errorNoNetwork: 'تفقد اتصال الانترنت لديك وحاول مرة اخرى',
  externalLinkFailure: 'غير قادر على فتح هذا الرابط',
  female: 'اميرة',
  forgotPassword: 'نسيت كلمة المرور؟',
  gallery: 'معرض الصور',
  genderWithColor: 'الجنس: ',
  gift: 'المكافئة',
  giftRedeemedSuccess: 'ابشرك سجلنا انك حصلت الهدية وعليك بالعافية',
  help: 'الفزعة',
  invoiceAmount: 'قيمة الفاتورة',
  invoiceAmountWithColon: 'قيمة الفاتورة: ',
  itemCountSelected: '{count} تم اختيار',
  languageArabic: 'عربي',
  languageEnglish: 'انجليزي',
  login: 'دخول',
  logout: 'تسجيل خروج',
  logoutPromptMessage: ' ضايق صدرنا انك بتطلع من التطبيق , بس لا تطول علينا , نسوي خروج؟',
  male: 'شيخ',
  maleOrFemale: 'شيخ ولا أميرة',
  manager: 'مدير',
  maritalStatus: 'الحالة الاجتماعية',
  maritalStatusSubtitle: 'نقدر نقول عن الحالة الاجتماعية حقتك انك',
  maritalStatusWithColor: 'الحالة الاجتماعية:',
  married: 'مرتبط بالحبيب (متزوج/ـة)',
  media: 'قطاع الاعلام',
  messagedYou: 'نرى ارسلك رسالة',
  messageFeedbackAdded: 'شاركنا رايك وخل الاخرين يستفيدون ويشاركونك',
  messages: 'رسالة',
  mobileNumber: 'رقم الجوال',
  mobileNumberSubtitle: 'اكتب جوالك هنا علشان نرسل لك كود تفعيل',
  mobileNumberVerification: 'رسالة التحقق',
  mobileNumberVerificationSubtitle: 'أرسل رمز التحقق المرسل على الجوال',
  mobileNumberWithColon: 'رقم الجوال: ',
  money: 'فلوسي',
  monthPoints: 'نقاط ({0})',
  moneyWithColon: 'فلوسك: ',
  name: 'الاسم',
  nameSubtitle: 'نورنا باسم الكريم',
  nameWithColor: 'الاسم:',
  nationality: 'الجنسية',
  nationalityHint: 'فضلا اختر بلدك الذي تنتمي اليه ',
  nationalitySubtitle: 'فضلا اختر جنسيتك التي تفتخر بها',
  nationalityWithColon: 'الجنسية:',
  never: 'اطلاقا',
  newPassword: 'كلمة المرور الجديدة',
  newPasswordSubtitle: 'فضلا اكتب كلمة المرور الجديدة',
  next: 'التالي',
  noAccountYet: 'معقول ماعندك حساب , تعال هنا ؟',
  noDistrictsFound: 'لا يوجد حي متوفر',
  noGiftsAvailable: 'الجوائز جاية في الايام القادمة, انتظرونا',
  noMessages: 'لحد الآن أنت ما راسلت أحد، طبعاً ما نسمح لأي تاجر يراسلك إلا لما تقوم أنت بمراسلته لأجل نحترم خصوصيتك',
  noNotifications: 'ماعندك اشعارات الحين',
  notEligible: 'للاسف النقاط اللي جمعتها ماتكفي لدخولك السحب',
  notEligibleForThePrize: 'للاسف ما كفت نقاطقك لدخول السحب',
  notAssociatedToBranch: 'عذرا لم يتم اسناد اي فرع لك',
  notification: 'الاشعارات',
  notifications: 'الاشعارات',
  notifyMessages: 'تنبيه الرسايل',
  notMarried: 'انتظر الحبيب',
  noWinners: 'محد فاز لحد الان , بس اول ما يفوزون راح نعلنهم ',
  offer: 'عروض',
  offerConsumedSuccess: 'ابشرك تم تسجيل العرض باسمك وتقدر تستلمه , عساه العافية ',
  ok: 'موافق',
  oldPassword: 'كلمة المرور السابقة',
  password: 'كلمة المرور',
  passwordChangedSuccessfully: 'تم تغيير كلمة المرور',
  passwordMismatch: 'كلمتي المرور غير متطابقتين',
  pastMonthsPoints: 'النقاط حقت الشهور اللي فاتت',
  passwordResetSuccessfully: 'احسنت تم اعادة ضبط كلمة المرور',
  pBusiness: 'اعمال حرة',
  pEducation: 'قطاع التعليم',
  pEngineering: 'قطاع هندسي',
  permissionDenied: 'التصريح مرفوض',
  pFieldOperation: 'ميداني',
  pFinance: 'قطاع مالي',
  pHealthcare: 'قطاع صحي',
  pHomeMaker: 'اهتم بشؤون منزلي',
  photo: 'الصورة الشخصية',
  photoSubtitle: 'اذا تبي تحط صورة شخصية لملفك الشخصي او تعدلها اضغط على رمز الصورة اللي تحت',
  pickPhoto: 'اختر صورة',
  pJobSeeker: 'باحث عن عمل',
  pManagement: 'اداري',
  pMilitary: 'قطاع عسكري',
  points: 'النقاط',
  pointsNeeded: 'كم تحتاج من نقطة',
  pRetired: 'متقاعد',
  previous: 'السابق',
  prize: 'المكافئة',
  prizeDetails: 'تفاصيل المكافئة',
  prizeWinners: 'من اللي فاز بالمسابقة ؟',
  profession: 'التخصص الوظيفي',
  professionSubtitle: 'تخصصك الوظيفي في أي قطاع',
  profile: 'الملف الشخصي',
  profileDetails: 'تفاصيل الملف الشخصي',
  promptApproveRequest: ' متأكد من موافقتك على الطلب ؟',
  promptDeleteNotification: 'متأكد ان ودك تحذف {0} الاشعارات ؟',
  promptDeleteNotifications: ' متأكد ان ودك تحذف {0} الاشعارات ؟',
  promptEarn: ' متأكد من اضافة :',
  promptPasswordMismatch: 'كلمتي المرور غير متطابقتين',
  promptRejectRequest: 'متأكد ماودك بالطلب ذا وتبي ترفضه ؟',
  pTechnical: 'فني',
  pTechnology: 'تقني',
  rab7NumberWithColon: ' رقم بطاقة Rab7 : ',
  quantityWithCount: 'الكمية : {0}',
  rab7OrMobileNumber: 'رقم جوال العميل , او رقم بطاقة Rab7 للعميل',
  recordedAudio: 'تسجيل صوت',
  recoverPassword: 'استعادة كلمة المرور',
  redeemDiscount: 'تحصيل خصم على الفاتورة',
  redeemDiscountWithValue: 'خصم على الفاتورة بقدر : {0}',
  redeemGift: 'تحصيل مكافئة',
  redeemOffer: 'تحصيل عرض',
  refund: 'استرجاع مشتريات سابقة',
  refundedSuccess: 'تم تسجيل الاسترجاع بنجاح',
  refundWithColon: 'استرداد : ',
  register: 'سجل',
  registrationSuccessful: 'ياهلا وغلا باللي سجل معنا, مبرووك تم تسجيلك بنجاح',
  reject: 'رفض',
  relationshipParent: 'أب او أم',
  relationshipSibling: 'أخ او أخت',
  relationshipSpouse: 'زوج أو زوجة',
  relationshipFriend: 'صديق',
  relationshipCoworker: 'زميل عمل',
  relationshipNeighbour: 'جار',
  relationshipOther: 'اخرى',
  removePhoto: 'احذف الصورة الحالية',
  requestRejectedSuccess: 'تم رفض الطلب بنجاح',
  resend: 'ارسل الكود مرة ثانية',
  resendCodeInSeconds: 'اعد ارسال كود التفعيل خلال {0} ثانية',
  resetPassword: 'اعادة ضبط كلمة المرور',
  retry: 'حاول مرة اخرى',
  save: 'حفظ',
  selectBranch: 'اختر الفرع الذي تعمل فيه الان',
  settings: 'الاعدادات',
  shareYourFeelings: 'شارك الاخرين شعورك بهذا الشيء',
  signupHere: 'سجل هنا',
  startedFromDate: 'تبدأ من: {0}',
  startingFromDate: 'تبدأ من: {0}',
  somethingWentWrong: 'حدث خطأ ما',
  systemNotification: 'اشعارات النظام',
  termsConditionsNPrivacyPolicy: 'الشروط والاحكام وسياسة الخصوصية',
  termsNConditions: 'الشروط والاحكام وسياسة الخصوصية',
  transactionSuccessful: 'نجحت العملية',
  update: 'تعديل',
  updateSuccessful: 'تم التعديل بنجاح',
  username: 'اسم المستخدم',
  usernameNPassword: 'اسم المستخدم وكلمة المرور',
  usernameNPasswordSubtitle: 'ياهلا ومرحبا , تكرما ادخال اسم المستخدم وكلمة المرور',
  verificationCode: 'كود التاكيد',
  verify: 'تأكيد',
  version: 'النسخة:',
  winners: 'الرابحون',
  yourFeedback: 'وش ودك تقول بعد فوزك بالجايزة وتشارك فيه الناس',
  transferButtonPoints: 'الفزعة',
  addComment: 'حط تعليقك هنا',
  addImage: 'اضافة صورة',
  addMedia: 'اضافة صوت',
  addRating: 'اضف التقييم',
  addReview: 'اضف مشاركة',
  agoDay: {
    'one': '{count} يوم',
    'other': '{count} يوم'
  },
  agoHour: {
    'one': '{count} ساعة',
    'other': '{count} ساعة'
  },
  agoMinute: {
    'one': '{count} دقيقة',
    'other': '{count} دقيقة'
  },
  agoMonth: {
    'one': '{count} شهر',
    'other': '{count} شهور'
  },
  agoSecond: {
    'one': '{count} ثانية',
    'other': '{count} ثانية'
  },
  agoYear: {
    'one': '{count} سنة',
    'other': '{count} سنوات'
  },
  all: 'كل',
  amount: 'الكمية',
  answer: 'الجواب',
  answerNEarn: 'جاوب السؤال واحصل على نقاط زيادة',
  apply: 'نفذ',
  availability: 'المتوفر',
  available: 'المتوفر',
  availableBalanceInYourWallet: 'رصيد محفظتك ',
  balance: 'الرصيد',
  branches: 'الفروع',
  businessBranchName: 'اسم الفرع',
  businessCategory: 'نشاط التاجر',
  businessGift: 'تفاصيل الهدية',
  businessGifts: 'قائمة الهدايا',
  businessMoney: 'حساب أرصدتي',
  businessMoneyDetails: 'تفاصيل رصيدي',
  businessName: 'اسم التاجر',
  businessProfile: 'الملف الشخصي بالتاجر',
  buyer: 'تقييم شراء',
  cancelRequest: 'الغي الطلب',
  chat: 'المحادثة',
  checkRequestStatus: 'شيك على طلبك وش صار فيه',
  choose: 'اختر',
  claimEarn: 'فعل الرصيد لأجل تزيد محفظتك',
  claimed: 'تستاهل تم زيادة رصيد محفظتك',
  claimedBusinessEarn: 'كفوو , فعلنا رصيدك وزدنا محفظتك عند هذا التاجر , عليك بالعافية ',
  commentAdded: 'ياسلام عليك تم اضافة التعليق',
  commentDeleted: 'وحذفنا التعليق بناء على طلبك',
  comments: 'تعليقات',
  commentsCount: {
    'one': '{count} تعليق',
    'other': '{count} تعليقات'
  },
  consumable: 'قابل للاستخدام',
  consumableBalance: 'الرصيد المسموح فيه للاستخدام',
  consume: 'استخدم الحين',
  consumed: 'مستخدمة',
  consumedAtAllBranches: 'هذا العرض رهيب , لانه موجود في كل الفروع',
  consumedAtOnlyBranches: 'هذا العرض بس موجود في الفروع التالية',
  consumedGift: 'الهدايا المستلمة',
  consumedOffer: 'العروض لمستخدمة',
  consumedOfferLimit: 'خلصت حصتك من هذا العرض , وعساها العافية',
  consumedOffers: 'العروض المستخدمة',
  consumeFormulaInformation: 'معلومات عن كيفية حساب الرصيد',
  consumeNEarn: 'اضغط هنا علشان تستخدم العرض',
  consumingGift: 'الهدايا المستلمة',
  consumingMoney: 'الرصيد المستخدم',
  consumingOffer: 'العروض المستخدمة',
  contactDetails: 'تفاصيل الاتصال',
  countAndPoints: '{0} نقطة ',
  deleteAck: 'احذف رسالة التفعيل',
  deleteAckPromptMessage: 'حذف اشعار التقييم متأكد من رغبتك في حذف اشعار التقييم ؟',
  deleteActivity: 'حذف لوحة الاعلان',
  deleteActivityPromptMessage: 'متأكد انك ودك تحذف لوحة الاعلان , استفد من النقاط الموجودة فيها وزد رصيدك بالضغط على الصور او التقييمات او الاجابة عن الاسئلة ان وجدت',
  deleteComment: 'احذف التعليق',
  deleteCommentPromptMessage: 'متأكد ودك تحذف التعليق ؟',
  deletedSuccessfully: 'تم الحذف',
  deleteOffer: 'حذف العرض',
  deleteOfferPromptMessage: 'متاكد ودك تحذف العرض , ترى تقدر تروح التعليقات وتقول رايك في العرض , علشان التاجر اللي حطه يحسن من عروضه مثل ما تحب',
  deletePromptMessage: 'متأكد ودك تحذف الرسالة',
  deleteReview: 'حذف المشاركة',
  deleteReviewPromptMessage: 'متأكد ودك تحذف المشاركة ؟',
  details: 'التفاصيل',
  directions: 'الاتجاهات',
  discount: 'الخصم',
  dislikesCount: {
    'one': '{count} استياء',
    'other': '{count} استيائات'
  },
  duration: 'فترة الصلاحية',
  earned: 'مكتسب',
  earnMore: 'من هنا تقدر تكسب نقاط Rab7 اكثر وتقرب من الجايزة',
  earnMoreToConsume: 'تحتاج {0} علشان توصل للهدية',
  earnSystemPoints: 'ونقاط Rab7 اللي كسبتها هي',
  earnWithDiscount: 'كسب مع خصم',
  editReview: 'عدل مشاركتك',
  emailNPassword: 'معلومات الدخول',
  emailNPasswordSubtitle: 'تكرما اكتب اسم المستخدم والبريد الالكتروني ومسموح بس الحروف والارقام',
  emailOrUsername: 'اكتب بريدك الالكتروني او اسم المستخدم',
  enterMoneyRange: 'المبلغ بين {0} و {1}',
  expired: 'انتهت صلاحيتها',
  favouriteOffers: 'العروض المفضلة',
  feeds: 'المشاركات',
  filter: 'التصفية',
  fromDate: 'من تاريخ',
  giftConsumed: 'تم استخدام كل الهدايا',
  giftConsumedMessage: 'بالعافية عليك الهدية , تم تسجيل استلامك للهدية',
  giftPrice: 'سعر الهدية',
  gifts: 'الهدايا',
  giftsConsumed: 'هدية \nمستلمة ',
  hintClickImageToEarn: 'لا يفوتك, اذا ضغطت الصورة راح نزيد من نقاط Rab7 حقتك وتوصل لجوايزنا بسرعة',
  hintEnterMoney: 'كم الفلوس اللي تبي تستخدمها بالخصم؟',
  hintEnterMoneyRange: '# الفلوس لازم بين {0} و {1}',
  hintEnterPointsRange: '# النقاط بين {0} و {1}',
  hintRab7: 'ادخل رقم Rab7 للشخص اللي تبي تحول له',
  hintRelationship: 'وش يمثل لك هذا الشخص ؟',
  inCorrectedAnswerSelected: 'اممم, تراك اخترت جواب غلط ',
  less: 'أقل',
  likes: 'اعجاب',
  likesCount: {
    'one': '{count} أعجاب',
    'other': '{count} أعجاب'
  },
  maximumDailyConsume: 'الحد اليومي للخصم',
  maximumDailyConsumeHint: 'اعلى حد في الخصومات ممكن تاخذه باليوم',
  maximumEarn: 'الحد الاعلى للكسب',
  minimumAmountToConsume: 'اقل خصم تستخدمه',
  minimumAmountToConsumeHint: 'اقل كمية من الرصيد تستطيع ان تستخدمها في الخصم ',
  minimumBalanceNeededToConsume: 'الحد الادنى من الرصيد ',
  minimumBalanceNeededToConsumeHint: 'كم لازم يكون رصيدك علشان تقدر تستخدمه',
  minimumSpentNeededToConsume: 'الحد الادنى من الصرف',
  minimumSpentNeededToConsumeHint: 'الحد الادنى من الفاتورة علشان تستحق نقاط',
  moneyConsumedMessage: 'عليك بالعافية تم استخدام رصيدك في الخصم المطلوب',
  moneyTransferred: 'الله يخلف عليك بالخير , ارسلنا رصيدك لمحفظة الشخص المطلوب',
  moneyWithValue: '{0} {1}',
  more: 'أكثر',
  myReviews: 'مشاركاتي',
  needToConsume: 'باقي عليك',
  noBranches: 'مافيه فروع لحد الان',
  noBusinessGifts: 'ما فيه هدايا لحد الان',
  noBusinessMoney: 'لحد الحين ماعندك نقاط ، بس تقدر تعبي محفظتك مع اي مشتريات تقوم فيها مع شركاءنا وتتحول لك على شكل خصومات تستفيد منها عندهم ، Rab7 يوفر قروووشك',
  noComments: 'مافيه تعليقات لحد الان',
  noConsumedOffers: 'لحد الان ما استخدمت أي عرض , وش تنتظر , جرب عروضنا واستمتع',
  noFavouriteOffers: 'ماعندك عروض مفضلة الحين، داخل العروض، تلاقي ايقونة قلب، اذا ضغطتها صار عرض مفضل، علشان ترجع له اي وقت تبي',
  noMapApp: 'No app found to show direction',
  noMoreDetails: 'للاسف ما فيه تفاصيل اكثر',
  noOffers: 'مافيه عروض الحين، بس اكييد جاية بالطريق',
  noPendingEarn: 'الحين مافيه رصيد يحتاج تفعيل منك ، بس مع كل عملية شراء راح تحتاج تفعل الرصيد هنا بضغطة زر واحدة ، علشان نحطه في محفظتك وتستفيد منه كخصومات',
  noReviews: 'ما فيه مشاركات لحد الان، ترا رايك مهم جدا مع أي مشتريات او زيارة لاي متجر اكتب رايك هنا وخل الناس تستفيد من تجربتك ورايك',
  notifyComments: 'تنبيه على تعليقات في مشاركاتك',
  noTnc: 'الشروط والاحكام',
  noTransfers: 'مافيه ترحيل للرصيد',
  noUsers: 'ما فيه مستخدم كذا',
  offerConsumed: 'العرض تم استخدامه , وعليك بالعافية',
  offerConsumedMessage: 'عوافي عليك , تم استخدام العرض',
  offers: 'العروض',
  offersConsumed: 'العروض \nالمستخدمة',
  offerTransferred: 'تم ارسال العرض للطرف الاخر',
  participate: 'شارك في ترشيح الاخرين للمكافئة',
  participateMessage: 'الحين تقدر ترشح الفائزين للمكافئة واللي حصلوا على رصيد كافي لدخول السحب , وعلشانك تهمنا , راح نخليك واحد منهم اذا نقاطك كافية لدخول السحب ',
  participateMessageEligible: 'الحين تقدر ترشح الفائزين للمكافئة واللي حصلوا على رصيد كافي لدخول السحب , وعلشانك تهمنا , راح نخليك واحد منهم اذا نقاطك كافية لدخول السحب ',
  participateNEarn: 'شارك في ترشيح الفائزين واكسب نقاط اكثر',
  participationThanksMessage: 'كفو عليك، تم الترشيح للفائزين التالين ',
  pendingEarn: 'تفعيل الأرصدة',
  post: 'مشاركة',
  pressAgainToExit: 'اذا مصر انك تطلع اضغط الخلف مرة ثانية',
  prizeRace: 'سباق الحصول على المكافئة',
  prizeWinnersAnnounced: 'ابشرك تم الاعلان عن الفائزين',
  professionHint: 'وشو القطاع اللي تشتغل فيه ',
  professionWithColon: 'قطاع العمل: ',
  profileUpdateRestriction: 'خصصنا تعديل واحد بالشهر لهذي الاشياء , بس ولا يهمك اذا انت مصر تعدلها تواصل معنا عبر المحادثة وراح نخدمك باللي تبي , بس فيه اشياء تقدر تعدلها اكثر من مرة ومتى ما بغيت مثل الاسم والصورة ورقم الجوال ',
  promptCancelRequestMessage: 'متأكد تبي تلغي الطلب ؟',
  promptConsume: 'متأكد تبي تستخدم العرض ؟',
  promptConsumeDiscount: 'متاكد تبي ترسل طلب لاستخدام رصيدك كخصم عند التاجر ؟',
  promptConsumeDiscountMessage: 'ترى احسن اذا تستخدم العرض وانت موجود بالمتجر , ليش ؟ علشان مدة طلب استهلاك العرض او الخصم تجلس دقايق وتتكنسل , علشان كذا استخدمه بالمتجر اريح لك ',
  promptConsumeDiscountSpendMessage: 'علشان تستفيد من الخصم لازم تكون فاتورتك على الاقل بقيمة {0} والخصم ما يكون اعلى من قيمة الفاتورة',
  promptConsumeGift: 'متأكد انك تبي تستخدم رصيدك علشان تاخذ هذي الهدية ؟',
  promptConsumeGiftMessage: 'ترى احسن اذا تستخدم العرض وانت موجود بالمتجر , ليش ؟ علشان مدة طلب استلام الهدية تجلس دقايق وتتكنسل , علشان كذا استخدمه بالمتجر اريح لك ',
  promptConsumeMessage: 'ترى احسن اذا تستخدم العرض وانت موجود بالمتجر , ليش ؟ علشان مدة طلب استهلاك العرض او الخصم تجلس دقايق وتتكنسل , علشان كذا استخدمه بالمتجر اريح لك ',
  promptParticipateVote: 'شارك الحين',
  promptParticipateVoteEligibleMessage: 'مشاركتك في التصويت للفايزين راح تساعدهم في الحصول على الجوائز , واذا انت عندك رصيد كافي من النقاط لدخول السحب فراح نعطيك صوت اضافي لك , وتقدر تشوف بعدين قائمة المرشحين وترتيبهم بعد التصويت',
  promptParticipateVoteMessage: 'مشاركتك في التصويت للفايزين راح تساعدهم في الحصول على الجوائز , واذا انت عندك رصيد كافي من النقاط لدخول السحب فراح نعطيك صوت اضافي لك , وتقدر تشوف بعدين قائمة المرشحين وترتيبهم بعد التصويت',
  promptRefund: 'عمليات استرجاع المشتريات',
  promptRefundMessage: 'اذا ناوي تسترد مشترياتك فالافضل انك تسوي هذا الطلب وانت في فرع المتجر اللي شريت منه , علشان الطلب يجلس دقايق ويتكنسل وتضطر تطلب من جديد',
  promptTransferMoney: 'تحويل رصيد المحفظة',
  promptTransferMoneyMessage: 'متأكد انك تبي تحول رصيد محفظتك هذا للطرف الاخر؟',
  promptTransferOffer: 'ارسال العروض',
  promptTransferOfferMessage: 'اذا ما جاز لك العرض, وش رايك تخلي غيرك يستفيد منه, الحين تقدر ترسل العرض لشخص اخر, بس اهم شي يكون ماهو موجود عنده',
  promptTransferPoints: 'تحويل نقاط Rab7 ',
  promptTransferPointsMessage: 'متأكد تبي تحول نقاط Rab7 للطرف الاخر',
  provideVerificationCode: 'هذا الرقم ابو اربع خانات لازم تعطيه الكاشير علشان يأكد الخصم الخاص فيك',
  quantity: 'الكمية',
  rab7Number: 'رقم Rab7 ',
  rab7PointsTransferred: 'تم تحويل نقاط Rab7 ',
  rate: 'قيّم',
  ratedSuccessfully: 'ياسلام عليك , تم التقييم بنجاح',
  rating: 'التقييم',
  ratingsCount: {
    'one': '{count} التقييم',
    'other': '{count} التقييمات'
  },
  received: 'استلام',
  recordAudio: 'سجل صوت',
  refunded: 'تم استردادها',
  refunding: 'الاسترداد',
  refundWalletLowInfoMessage: 'قيمة مبلغ الاسترداد اقل من قيمة الفاتورة ليش ؟ لان رصيد المحفظة لايكفي فتم الاستقطاع من مبلغ الاسترداد',
  registrationPointsHint: 'حتى في خطوات التسجيل راح نخليك تربح نقاط , ومع كل خطوة راح تربح',
  relation: 'العلاقة مع الطرف الاخر',
  removeAll: 'بدون فلترة',
  replyingTo: 'الرد على',
  replyTo: 'يرد على',
  report:   'ابلاغ',
  reportedMessage: 'تم ارسال البلاغ',
  reportHint: 'وش بلاغك عن هذي المشاركة؟',
  requestInProgress: 'تو الكاشير مابعد فعله, شيك معه',
  reverseDiscount: 'تم استرداد الخصم',
  reverseEarned: 'تم خصم الرصيد من المحفظة',
  review: 'مشاركة',
  reviewAdded: 'تم اضافة المشاركة',
  postDeleted: 'تم حذف المشاركة',
  reviewNEarn: 'قيّم واحصل على نقاط اكثر',
  reviews: 'تقييمات',
  reviewUpdated: 'تم تعديل الشماركة',
  reviewYourVisit: 'قيّم زيارتك',
  selected: 'اختيار',
  spent: 'الصرف',
  startingFrom: 'تبدأ من {value}',
  submit: 'ارسال',
  tnc: 'شروط الاستخدام',
  toDate: 'الى تاريخ ',
  totalDiscount: 'مجموع \nالخصم',
  totalEarned: 'مجموع \nالمكتسب',
  totalReceived: 'مجموع \nالمستلم',
  totalSpent: 'مجموع \nالمصروف',
  totalTransferred: 'مجموع \nالتحويل',
  transactionConsumeGiftMade: 'تم تاكيد استلام الهدية',
  transactionConsumeOfferMade: 'عليك بالعافية , تم استخدامك للعرض',
  transactionEarnMade: 'سويت عملية شراء',
  transactionRefundMade: 'تم اجراء عملية استرجاع',
  transactions: 'عملياتي',
  transactionTypes: 'نوع العملية',
  transfer: 'تحويل',
  transferMoney: 'الفزعة',
  transferOffer: 'تحويل عرض',
  transferPoints: 'تحويل رصيد Rab7 ',
  transferred: 'محول',
  transfers: 'التحويلات',
  user: 'المستخدم',
  usernameHint: 'اسم المستخدم (a-z 0-9 _)',
  userRequestCancelled: 'تم الغاء طلبك',
  userRequestCompleted: 'تم معالجة طلبك',
  userRequestCompletedMessage: 'امورك تمام , تم قبول طلبك بنجاح وتاكيده ',
  userRequestInProgress: 'طلبك تحت المعالجة , لازم تروح الكاشير في المحل وتخليه يفعل طلبك , لانه راح يتكنسل تلقائي بعد',
  userRequestNotFound: 'لا يوجد طلب',
  userRequestNotFoundMessage: 'الطلب غير موجود , فضلا قم باجراء طلب اخر',
  userRequestRejected: 'تم رفض الطلب',
  userRequestRejectedMessage: 'تم الغاء طلبك , فضلا تواصل مع الكاشير, واذا وجدت تحدي تواصل مع الدعم الخاص بذلك التاجر من خلال الملف الشخصي للمتجر',
  userRequestTimedOut: 'تم الغاء الطلب تلقائيا',
  userRequestTimedOutMessage: 'انتهت مهلة استهلاك الطلب',
  validity: 'الصلاحية',
  validUpto: 'صالح لغاية {value}',
  visitor: 'تقييم زيارة',
  voteCurrentStatus: 'حالة سباق الترشح للمكافئة',
  voteFinalStatus: 'النتائج النهائية للترشح للمكافئة',
  voting: 'التصويت',
  votingBegan: 'مهلة الحصول على المكافئة انتهت والان يبدأ سباق التصويت على الفائز , فضلا قم بالتصويت',
  walletBalance: 'رصيد محفظتي',
  walletBalanceIs: 'رصيد محفظتي',
  winner: 'الفائز',
  nearby: 'المتاجر المجاورة',
  nearbyMultiLine: 'المتاجر\nالمجاورة',
  noBusinessFound: 'لحد الان مافيه متاجر',
  referAndEarn: 'ادعو غيرك واكسب نقاط',
  referAndEarnMessage: 'لا يفوتك تقدر تكسب نقاط كثيرة وتستغلها في العروض المميزة، ماعليك الا تشارك التطبيق مع معارفك، واذا سجلوا وحطوا رقم Rab7 الخاص فيك راح نعطيك ونعطيهم نقاط مجانية، وهذا هو الرقم',
  share: 'شارك',
  invitationCode: 'كود الدعوة',
  invitationCodeHint: 'اذا احد مسويلك دعوة فحط هنا كود الدعوة حقته, علشان نعطيه نقاط, واذا ماعندك عادي اتركه فاضي',
  ratings: 'التقييم',
  smartAds: 'الاعلانات',
  noSmartAds: 'ما فيه اعلانات لحد الان',
  toStart: 'للبداية',
  noActivityAfterAckMessage: 'يمال العافية ، استمر في تصفح تطبيق Rab7 واربح الكثير من النقاط اللي تخليك تاخذ عروض مررره كبيرة',
  businessRequestSubmitted: 'تم ارسال الطلب',
  addBusinessRequest: 'طلب اضافة متجر مهب عندنا',
  locationCoordinates: 'احداثيات الموقع',
  done: 'تم',
  markerDragHint: 'اضغط مطولا لتحريك المؤشر',
  contactNumber: 'رقم التواصل',
  multipleBranchesHere: 'فروع كثيرة',
  clickToView: 'اضغط علشان تشوفها',
  multipleOffersHere: 'عروض واجد هنا',
  votingStarted: 'ترا التصويت للجايزة بدا',
  commentedOnPost: 'علق على مشاركتك',
  repliedInPost: 'رد عليك في التعليقات',
  repliedInActivity: 'رد عليك في التعليقات',
  forwardedOfferToConsume: 'شارك العرض لاحد لو تبي',
  transferredBusinessPoints: 'حول {0} لمحفظتك الخاصة بالمتجر',
  transferredRab7Points: 'تحولت {0} نقطة من رصيد Rab7 ',
  votingCompleted: 'مدة المكافئة خلصت وبعد خلصوا التصويت',
  votingToStart: 'مدة التاهل للجايزة خلصت , بعد شوي بنبدأ التصويت عليها ',
  viewVoting: 'اعرض التصويت',
  businessReviews: 'تقييم التاجر',
  shareYourFeedback: 'شاركنا رايك',
  referralShareMessage: 'شف هذولا تطبيقهم رهيب واسمه Rab7 , تقدر توفر فيه فلوسك وتربح جوايز لا وتجيك عروض خيالية , اذا سجلت فيه لاتنس تحط الكود حقي هذا {rab7Number} علشان تربح نقاط زيادة وحمله من هنا بهذا الرابط. حمل التطبيق {url}',
  appShareMessage: 'شف هذولا تطبيقهم رهيب واسمه Rab7 , مليان العاب وترفيه واذا فزت عطوك عروض مجانية , حمل التطبيق {url}',
  offerShareMessage: 'لا يفوووتك هذا العرض الرهيب "{title}". {url}',
  reviewShareMessage: 'شف هنا التقييم وش يقول "{title}" عن ذا المتجر "{businessName}". {url}',
  smartAd: 'الاعلانات',
  hintBusinessAd: 'اذا مالقيت المتجر عندنا؟',
  close: 'اغلاق',
  visit: 'او زر الرابط هذا',
  unlimited: 'غير محدودة',
  referNow: 'بلغنا عنه ونسجله من هنا',
  noFeeds: 'ما فيه مشاركات لحد الان , ترا رايك مهم جدا مع أي مشتريات او زيارة لاي متجر اكتب رايك هنا وخل الناس تستفيد من تجربتك ورايك',
  pointsRequiredForPrize: 'نقاط Rab7 اللي محتاجها علشان تصير متاهل للسحب على الجايزة: {0}',
  january: 'يناير',
  february: 'فبراير',
  march: 'مارس',
  april: 'أبريل',
  may: 'مايو',
  june: 'يونيو',
  july: 'يوليو',
  august: 'أغسطس',
  september: 'سبتمبر',
  october: 'أكتوبر',
  november: 'نوفمبر',
  december: 'ديسمبر',
  consumeDetails: 'معلومات عن استخدام العرض',
  validityMessage: 'هذا العرض بيخلص في',
  myAvailability: 'المتاح لي',
  myAvailabilityMessage: 'كم مره اقدر استخدم العرض',
  myConsumed: 'استخدامي',
  myConsumedMessage: 'كم مره استخدمت هذا العرض',
  availabilityMessage: 'كم عدد العروض اللي. مقدمها هذا التاجر لكل الزباين',
  totalConsumed: 'استخدام الجميع',
  availabilityAll: 'كم عرض حاط هذا التاجر',
  totalConsumedMessage: 'كم واحد استخدم هذا العرض',
  remainingOffers: 'المتبقي',
  businessRequestDetails: 'عطنا تفاصيل عن المحل',
  transferRab7Points: 'تحويل رصيد Rab7 ',
  reviewEarn: 'قيم تجربتك وشاركها مع الاخرين',
  yourLocation: 'موقعك',
  search: 'بحث',
  validityFutureMessage: 'العرض سيبدأ بعد',
  startingIn: 'يبدأ في',
  consumeDiscount: 'استخدام الرصيد',
  systemPointsClaimed: 'تم اضافة نقاط Rab7 لرصيدك وقربت من الجايزة',
  prizePoints: 'نقاط المكافئة',
  youWonSmall: 'مبروك فزت معنا',
  transferPointsSubTitle: 'من هنا تقدر تفزع و تحول بعض من رصيدك او كله لاحد معارفك باستخدام رقم Rab7 الخاص فيه',
  transferRab7PointsShort: 'الفزعة',
  registrationHint: 'سيتم استخدام هذه المعلومات لتوفير العروض الملائمة لك وتحسين تجربتك في التطبيق' ,
  promptDeleteMessage: 'بقيامك بالحذف فانت تمنع التاجر من التواصل معك، وسيسمح له بالتواصل مرة اخرى فقط في حال قمت بمحادثته مرة اخرى وذلك احتراما لخصوصيتك، متأكد من الحذف ؟',
  promptDeleteMessages: 'بقيامك بالحذف فانت تمنع التاجر من التواصل معك، وسيسمح له بالتواصل مرة اخرى فقط في حال قمت بمحادثته مرة اخرى وذلك احتراما لخصوصيتك، متأكد من الحذف ؟',
  charity: 'جمعيات خيرية',
  noCharity: 'لحد الان مافيه جمعيات خيرية مسجلة',
  unclaimedBalance: '{0} غير نشطة',
  distanceKms: {
    'one': '{count} كلم',
    'other': '{count} كلم'
  },
  chooseAnswerToQuestion: 'اختر اجابة من الاسئلة المتوفرة ',
  relationshipCharity: 'جمعية خيرية',
  expenses: 'المصروفات',
  categories: 'الفئات',
  noExpenses: 'لحد الان ماعندك مصروفات في هذي الفترة',
  rab7Points: 'نقاط Rab7 ',
  visitsCount: {
    'one': '{count} الزيارة',
    'other': '{count} الزيارة'
  },
  votings: 'التصويت',
  noVotings: 'لا يوجد عمليات تصويت الحين، بس جاية بالطريق',
  noTransactions: 'ما عندك عمليات لحد الان',
  signupInformationRegardingMessage: 'تطبيق Rab7 يقدم عروضات قوية واستثنائية خصوصاً للعملاء اللي يجمعون نقاط Rab7، وعلشان ودنا انك تحصل عليها بسرعة وما تفوتك راح نعطيك نقاط مجانية مع كل خطوة من خطوات التسجيل اللي راح تسويها، الطيبين امثالكم يستاهلوووون.',
  branch: 'الفرع',
  branchReviews: 'تقييمات الفروع',
  addReviewValidationMessage: 'نحتاج منك ان تملأ الحقول التالية: اسم التاجر, اسم الفرع, التقييم, مشاركة نصية او صوتية زي ماتحب',
  addReviewAckValidationMessage: 'نحتاج منك ان تملأ الحقول التالية: التقييم, مشاركة نصية او صوتية زي ماتحب',
  addReviewAckWQuestionValidationMessage: 'نحتاج منك ان تملأ الحقول التالية: التقييم, مشاركة نصية او صوتية زي ماتحب مع اجابة الاستبيان',
  experienceNearby: 'اكسب نقاط قريبة منك',
  survey: 'استبيان',
  noActivityInNearbyMessage: 'عذرا لا يوجد شيء الان لكن نعدك بالكثير قريبا',
  distanceMeters: {
    'one': '{count} متر',
    'other': '{count} متر',
  },
  ratingDoneAlready: 'انت سبق وحطيت التقييم بس ناقص تعليقك.',
  enterRating: 'يمال العافية نحتاج تقييمك ',
  shiftingToEarnMore: 'ابشر بالخير، راح ننقلك لمكان تكسب فيه نقاط اكثر',
  continueSignup: 'اكمل للتسجيل',
  contestRules: 'شروط واحكام المكافئة',
  shareBusiness: 'لا يوفتك هذا المتجر وعروضاتهم "{title}". {url} شي رهييب',
  transactionAlreadyReviewed: 'تراك قيمت هذي الزيارة قبل كذا',
  earnMoreRab7PointsToConsume: 'باقيلك {0} نقطة علشان تقدر تستخدم العرض',
  registrationHintNationality: 'جنسيتك اللي تفتخر فيها، مهمة لنا، وراح نحرص اننا نميزك بعروض مخصصة لها، وبعد راح نقدم لك XXX من نقاط Rab7 ',
  registrationHintGender: 'ودنا نعرف شيخ والا اميرة، علشان نجيبلك العروض اللي يحبها قلبك، وراح نقدم لك XXX من نقاط Rab7 علشان تستخدمها في هذيك العروض',
  registrationHintBirthday: 'يوم ميلادك شي كبير بالنسبة لنا، لان عندنا عروض خاصة نعطيها هدية في اعياد الميلاد، وما نبي يفوتنا اننا نهديك في يوم ميلادك، وراح نبدا من الان باننا نعطيك XXX من نقاط Rab7 ',
  registrationHintCity: 'انك تدري قبل غيرك عن العروض اللي حولك وحواليك قبل لا تخلص شي مهم، علشان كذا تحديدك للمدينة والحي اللي تسكن فيه راح يخلينا نجيبها لك قبل اي احد ثاني، وراح نعطيك XXX من نقاط Rab7 ',
  rab7PointsEarnLimitReached: 'معليش، انت وصلت للحد اليومي الاعلى من تحصيل نقاط Rab7، بس ولا يهمك جرب من بكره وجمع اكثر',
  limitReached: 'تجاوز الحد اليومي',
  noSmartAdFoundMessage: 'اممم الحين ما فيه شي، بس اكيد لو شيكت بعدين راح تلاقي اشياء كثيرة وتربح اكثر',
  opinion: 'صوت العميل',
  ad: 'اعلان',
  myOpinions: 'وجهة نظري',
  recommendBusiness: 'توصية باضافة متجر',
  startingInTime: '{0} بيبدا بعد',
  endingInTime: '{0} بينتهي بعد',
  tncpp: 'الشروط والأحكام وسياسة الخصوصية',
  bySigningIn: 'من خلال تسجيل الدخول، فإنك توافق على',
  rab7PointsNeeded: 'نقاط Rab7 ',
  rab7PointsNeededToConsume: 'كم تحتاج من نقاط Rab7 علشان تستخدم ذا العرض',
  consumableWeekDays: 'ايام الاستخدام',
  consumableWeekDaysMessage: 'العرض هذا مسموح باستخدامه فقط في الايام هذي',
  buyerRatings: 'تقييم المشترين',
  visitorRatings: 'تقييم الزائرين',
  products: 'المنتجات و الخدمات',
  noBusinessProducts: 'معليش الحين مافيه منتجات بس جالسين نعدها لك',
  businessProducts: 'المنتجات والخدمات',
  productCategory: 'فئة المنتجات والخدمات',
  businessProduct: 'المنتج الخدمة',
  likeProduct: 'مدى اعجابك بالمنتج',
  initiateChat: 'بدأ المحادثة',
  initiateChatMessage: 'سنقوم ببدأ المحادثة مع المتجر حول المنتج , فضلا ضع استفسارك هنا لإرسالة',
  enterMessage: 'اكتب استفسارك او اقتراحك هنا',
  shareBusinessProducts: 'لا يفوتك قائمة منتجات وخدمات رهيبة تلاقيها في متجر "{title}". {url}',
  shareBusinessProduct: 'شف هذا لا يفوتك "{productTitle}" تلاقيه عند متجر "{title}". {url}',
  noAvailability: 'غير متوفر الحين',
  offerCannotTransferred: 'العرض غير قابل للتحويل',
  shiftingToReviewVisit: 'الحين بنروح سوا علشان تقيم زيارتك للمتجر وتفعل نقاطك فيه',
  reviewVisit: 'تقييم الزيارة',
  shareExperienceInOffer: 'شارك تجربتك في استخدام هذا العرض',
  shareExperienceInOffer2nd: 'مع الآخرين علشان يستفيدون من تجربتك وراح يشوفون هذا التعليق في تعليقات هذا العرض',
  shareExperience: 'شارك تجربتك في',
  shareExperience2nd: 'مع الآخرين علشان يستفيدون من تجربتك وراح يشوفون هذا التعليق في المشاركات',
  hintQuestionInReview: 'اربح نقاط اكثر وزد من تحسين تجربتك في',
  hintQuestionInReview2nd: 'بالإجابة على هذا الإستطلاع',
  shareExperienceInBusiness: 'شارك تجربتك مع هذا المتجر مع الاخرين علشان يستفيدون منها وراح تلاقيها بالمشاركات',
  list: 'قائمة',
  map: 'خريطة',
  byCategory: 'بالفئات',
  byBusiness: 'بالمتاجر',
  businessChats: 'محادثة مع الادارة الرئيسية',
  chats: 'المحادثات',
  noBusinessChats: 'لا يوجد محادثات لغاية الآن',
  chatsIn: 'محادثة مع {businessName}',
  noChats: 'لا يوجد محادثات',
  profilePendingMessage: ' Rab7 يقدم عروض مذهلة وحصرية، وعلشان نقدر نوصلها لك نحتاج منك تكمل معلومات ملفك الشخصي، اضغط علشان تكملها',
  stepsLeft: '{0} خطوات متبقية',
  stepLeft: '{0} خطوة متبقية',
  statistics: 'إحصائيات',
  at: 'في',
  total: 'المجموع',
  earnedPoints: 'ونقاط اللي كسبتها هي',
  businessPointsNeededMessage: 'كم لازم يكون في محفظنك علشان نقدر تستهلك الرصيد ',
  offerFeeMessage: 'كم راح يستقطع من محفظتك بعد إستهلاك العرض',
  consumableHoursMessage: 'العرض يمكن إستخدامه من الساعة الى الساعة',
  consumableHours: 'وقت إستخدام العرض',
  answerSuccess: 'يمال العافية، شكرا لمجهودك في المشاركة',
  opinionSuccess: 'يعطيك العافية ورايك هذا على راسنا و راح يوخذ بكل جدية',
  youHaveAnswered: 'شكرا على تفاعلك.',
  youHaveReviewed: 'كفو عليك تم ارسال رأيك الكريم.',
  am: 'صباحاً',
  pm: 'مساءً',
  sunday: 'الأحد',
  monday: 'الأثنين',
  tuesday: 'الثلاثاء',
  wednesday: 'الأربعاء',
  thursday: 'الخميس',
  friday: 'الجمعة',
  saturday: 'السبت',
  pointsAddedOnConsume:'كم ستشحن محفظتك بعد إستهلاك العرض',
  noneBranch: 'لا يوجد فروع مضافة لغاية الآن',
  allBranches: 'جميع الفروع',
  from: 'من',
  to: 'إلى',
  offerDurationMessage: 'العرض متوفر الآن',
  consumableOnBirthday: 'يستهلك في يوم ميلادك',
  everyday: 'يومياً',
  openToday: 'مفتوح اليوم',
  closedToday: 'مغلق اليوم',
  openNow: 'مفتوح الآن',
  closedNow: 'مغلق الآن',
  allDay: 'طوال اليوم',
  orderNow: 'أطلب الآن',
  storeTimings: 'مواعيد المتجر',
  orderTimings: 'مواعيد الطلبات',
  consumeAtStore: 'استخدام داخل الفرع',
  addToBasket: 'أضف إلى السلة',
  applyToBasket: 'استخدمه في السلة',
  maxDiscount: 'الحد الأقصى للخصم',
  minPurchaseAmount: 'الحد الأدنى للشراء',
  order: 'أطلب',
  confirmLocation: 'تأكيد الموقع',
  enableLocation: 'تفعيل الموقع',
  updateManually: 'تحديث يدوي',
  locationNotUpdated: 'الموقع غير مُحدث',
  locationNotUpdatedMessage: 'حدث موقعك الحالي لتتمكن من مشاهدة المتاجر القريبة منك',
  updateLocation: 'قم بتحديث الموقع',
  unableToDetectCurrentLocationWithUpdate: 'لا يمكن الوصول لموقعك الحالي، الرجاء اختيار الموقع يدوياً',
  unableToDetectCurrentLocationDeniedWithUpdate: 'غير قادر على الوصول للموقع الحالي بسبب عدم السماح، الرجاء إختيار السماح للوصول إلى الموقع أو قم بإختيار الموقع يدوياً',
  unableToDetectCurrentLocation: 'غير قادر على تحديد الموقع الحالي',
  unableToDetectCurrentLocationDenied: 'غير قادر على الوصول للموقع الحالي بسبب عدم السماح، الرجاء إختيار السماح للوصول إلى الموقع ',
  pickFromMap: 'إختيار الموقع من الخريطة',
  useCurrentLocation: 'إختيار الموقع الحالي',
  savedAddressesCapitalize: 'العناوين المسجلة',
  searchCity: 'البحث عن مدينة',
  noSavedAddresses: 'لا توجد عناوين مسجلة',
  currentLocation: 'الموقع الحالي',
  pickedFromMap: 'تم إختيار الموقع من الخريطة',
  noNearbyStoresFound: 'لا توجد متاجر قريبة ',
  day: 'أيام',
  days: 'أيام',
  hours: 'ساعة',
  mins: 'دقائق',
  searchStores: 'البحث عن المتاجر، طبق',
  allNearbyStoresCapitalize: 'كل المتاجر القريبة',
  deliveryTime: 'وقت التوصيل',
  distance: 'المسافة',
  itemsCapitalize: 'المنتجات',
  noItems: 'لا توجد منتجات',
  allItems: 'كل المنتجات',
  discountOff: '{0}% خصم',
  itemsCountWithValue: '{0} منتجات',
  product: 'منتج',
  additionalMoneyWithValue: '{0} {1} +',
  addToCart: 'الإضافة إلى السلة',
  itemAdded: 'تمت إضافة المنتج',
  unableToAddToCart: 'غير قادر على الإضافة إلى السلة',
  minOptionsText: {
    'one': 'فضلا اختر خيار واحدا على الاقل',
    'other': 'فضلا قم باختيار {count} خيارات على الاقل'
  },
  maxOptionsText: {
    'one': 'فقط مسموح بخيار واحد',
    'other': 'متاح عدد {count} من الخيارات'
  },
  maxOptionsTextStartComma: {
    'one': ', متاح خيار واحد',
    'other': ' متاح عدد {count} من الخيارات'
  },
  cartFetchFailed: 'السلة غير متاحة',
  zeroItems: 'لا توجد منتجات',
  cartIsEmpty: 'السلة فارغة',
  replaceCartItem: 'استبدال المنتجات الموجودة في السلة',
  replaceCartItemMessage: 'يوجد بالسلة مشتريات من متجر اخر\n"{oldBusiness}"\nهل تريد حذفها لاضافة هذه المنتجات الحالية\n"{newBusiness}"؟',
  replaceCartItemWithinBusinessMessage: 'يوجد بالسلة مشتريات من متجر اخر\n"{oldBusiness} ({oldBranch})"\nهل تريد حذفها لاضافة هذه المنتجات الحالية\n"{newBusiness} ({newBranch})"؟',
  yes: 'نعم',
  no: 'لا',
  noItemsCart: 'السلة فارغة',
  cart: 'سلة المشتريات',
  itemsWithCount: 'منتج ({count})',
  deleteStoreItems: 'حذف منتجات المتجر من السلة',
  deleteStoreItemsMessage: 'هل أنت متأكد من حذف كل المنتجات من السلة؟',
  deleteItem: 'حذف المنتج',
  deleteItemMessage: 'هل أنت متأكد من حذف المنتج من السلة؟',
  offerApplied: 'عرض ترويجي',
  itemUpdated: 'تم تحديث المنتج',
  updateCart: 'قم بتحديث السلة',
  payNPlaceOrder: 'الدفع وتأكيد الطلب',
  placeOrder: 'تأكيد الطلب',
  viewDetails: 'التفاصيل',
  free: 'مجاناً',
  deliveryCharges: 'تكاليف التوصيل',
  deliveryFee: 'رسوم التوصيل',
  storeRequest: 'هل لديك طلب خاص للمتجر؟ أكتبها هنا',
  applyDiscount: 'إضافة الخصم',
  discountApplied: 'تم تطبيق الخصم',
  edit: 'تعديل',
  remove: 'حذف',
  offerAdded: 'تمت إضافة العرض',
  unableToUpdateToCart: 'غير قادر على تحديث السلة',
  youWillEarn2: 'ستحصل على',
  youWillEarn1: 'في رصيد محفظتك',
  billDetails: 'تفاصيل الفاتورة',
  itemTotalInclusiveTaxes: 'مجموع المنتجات',
  paidUsingWallet: 'الدفع بواسطة المحفظة',
  totalAmount: 'إجمالي المبلغ',
  totalDiscountInCart: 'الخصم',
  toPay: 'قم بدفع',
  earning: 'المبلغ المكتسب',
  taxes: 'ضريبة القيمة المضافة ',
  savedAddresses: 'العناوين المسجلة',
  distanceKmsFromCurrentLocation: {
    'one': '{count} كيلومتر من موقعك الحالي',
    'other': '{count} كيلومتر من موقعك الحالي '
  },
  distanceMetersFromCurrentLocation: {
    'one': '{count} متر من موقعك الحالي',
    'other': '{count} متر من موقعك الحالي'
  },
  selectAddress: 'إختيار العنوان',
  addNewAddress: 'إضافة عنوان جديد',
  deliveryAt: 'التوصيل إلى',
  deleteAddress: 'حذف العنوان',
  deleteAddressMessage: 'هل أنت متأكد من حذف العنوان؟',
  addressDeletedSuccessfully: 'تم حذف العنوان بنجاح',
  addresses: 'العناوين',
  moveMapToAdjust: 'قم بتحريك الخريطة',
  addAddress: 'إضافة عنوان',
  editAddress: 'تعديل العنوان',
  addressType: 'نوع العنوان',
  placeholderAddress: 'شقة /مبنى / شارع',
  placeholderLandmark: 'علامة بارزة بجوار العنوان ',
  home: 'المنزل',
  work: 'العمل',
  other: 'مكان اخر',
  chooseLocation: 'إختيار الموقع',
  placingOrder: 'تأكيد الطلب',
  placingOrderMessage: 'طلبك قيد المعالجة , قد ياخذ قليلا من الوقت , فضلا عدم الضغط للخلف او الخروج حتى اتمام ذلك',
  processingPayment: 'معالجة الدفع',
  processingPaymentMessage: 'عملية الدفع قيد المعالجة , قد ياخذ قليلا من الوقت , فضلا عدم الضغط للخلف او الخروج حتى اتمام ذلك',
  orderPlaced: 'تم تأكيد الطلب',
  orderWithId: 'طلب رقم. {0}',
  orderPlacedMessage: 'تم معالجة طلبك.\nوسنبلغك بتاكيد الطلب حالما يتم تاكيده من المتجر ',
  earningClaimMessage: 'سوف تقوم بالحصول على المبالغ المكتسبة عند استلام الطلب',
  continueShopping: 'تصفح متاجر أخرى',
  trackOrder: 'مشاهدة الطلب',
  paymentFailed: 'عملية دفع غير ناجحة',
  paymentIsInProcessing: 'معالجة الدفع',
  paymentIsInProcessingMessage: 'الدفع قيد المعالجة , يمكنك مراجعة حالة الدفع في قائمة الطلبات',
  paymentCancelled: 'تم إلغاء الدفع',
  paymentFailedMessageGeneric: '{0} \n\n اذا تم الخصم من بطاقتك فسيتم استرجاعها خلال فترة 3-15 ايام عمل والمدفوعات من خلال المحفظة سيتم استرجاعها خلال فترة قصيرة , وفي حالة عدم حدوث ذلك فضلا التواصل مع فريق الدعم.\n رقم العملية : {1}',
  paymentCancelledMessageGeneric: ' تم الغاء عملية الدفع {0} \n\n اذا تم الخصم من بطاقتك فسيتم استرجاعها خلال فترة 3-15 ايام عمل والمدفوعات من خلال المحفظة سيتم استرجاعها خلال فترة قصيرة , وفي حالة عدم حدوث ذلك فضلا التواصل مع فريق الدعم.',
  deliveryWithin: 'يتم التوصيل في',
  branchNotAvailable: 'المتجر غير متاح',
  branchNotAvailableMessage: 'المتجر لا يقوم بالتوصيل إلى العنوان المطلوب. يمكنك الإختيار من الفروع القريبة لنفس المتجر. أو تحديث عنوان التوصيل من أعلى الشاشة',
  branchNBusinessNotAvailableMessage: 'الفرع لا يقوم بالتوصيل إلى العنوان المطلوب. يمكنك الإختيار من المتاجر القريبة منك. أو تحديث عنوان التوصيل من أعلى الشاشة',
  selectNearbyStoreCapitalize: 'إختيار المتاجر القريبة',
  addMoreItemsToCart: 'إضافة منتجات أخرى إلى السلة',
  addressAdded: 'تم إضافة العنوان',
  addressUpdated: 'تم تحديث العنوان',
  inclusiveTaxes: 'السعر شامل للضريبة',
  offerAvailable: 'العرض متوفر',
  offersAvailableMessage: 'قم بإختيار العرض المطلوب أو قم بإضافة المنتج إلى السلة بدون استخدام العرض',
  branchesNotAvailable: 'المتاجر غير متاحة',
  offerPrice: 'سعر العرض',
  orders: 'طلبات الشراء',
  driverCapitalize: 'مندوب التوصيل (طير شلوى)',
  myRequests: 'طلبات توظيف (طير شلوى)',
  businessInvitations: 'دعوات توظيف مخصصة',
  joinedBusinesses: 'المتاجر التي تعمل بها',
  shipments: 'طلبات التوصيل',
  noPendingInvitations: 'لا توجد دعوات',
  notJoinedToBusinesses: 'لا توجد متاجر تعمل بها',
  rejected: 'مرفوض',
  invited: 'تمت دعوتك',
  inactive: 'غير نشط',
  active: 'نشط',
  acceptInvitation: 'قبول الدعوة',
  acceptInvitationMessage: 'هل أنت متأكد من الإنضمام كمندوب توصيل (طير شلوى) لهذا المتجر؟',
  rejectInvitation: 'رفض الدعوة',
  rejectInvitationMessage: 'هل أنت متأكد من رفض الإنضمام كمندوب توصيل (طير شلوى) هذا المتجر؟',
  deactivateBusinessJoining: 'إيقاف التوصيل لهذا المتجر',
  deactivateBusinessJoiningMessage: 'هل أنت متأكد من إيقاف التوصيل لهذا المتجر؟',
  activateBusinessJoining: 'تنشيط طلبات التوصيل',
  activateBusinessJoiningMessage: 'هل أنت متأكد من تنشيط التوصيل لهذا المتجر؟',
  businessDriverServing: 'المتاجر التي تقوم بالتوصيل لها',
  accept: 'قبول ',
  deleteDriver: 'حذف المتجر',
  deleteDriverMessage: 'هل أنت متأكد من حذف المتجر من المتاجر التي تعمل معها؟',
  deliveryDistance: 'مسافة التوصيل',
  deactivate: 'تعطيل',
  activate: 'تنشيط ',
  driverInvitationMessage: 'قام بدعوتك للعمل كمندوب توصيل (طير شلوى)',
  jobRequestInfo: 'بيانات طلب التوظيف',
  nationalIdPhoto: 'بطاقة الهوية',
  informationSaved: 'المعلومات المحفوظة',
  jobRequests: 'طلبات التوظيف',
  noJobRequests: 'لا توجد طلبات توظيف',
  jobRequestInformation: 'معلومات طلب التوظيف',
  noJobRequestInformationAdded: 'لا توجد معلومات مضافة لطلب التوظيف، قم بإنشاء طلب',
  deleteJobInformationMessage: 'حذف معلومات طلب التوظيف وأيضاً حذف كل الطلبات لهذه الوظيفة.\nهل أنت متأكد من الحذف؟',
  deleteJobInformation: 'حذف معلومات طلب التوظيف',
  hiringDrivers: 'تعيين مناديب توصيل (طير شلوى)',
  joinAsDriver: 'الإنضمام كمندوب توصيل (طير شلوى)',
  joinAsDriverMessage: 'قم بإرسال طلب التوظيف للعمل كمندوب توصيل (طير شلوى) للمتجر، وسوف يتم إشعارك برد المتجر.',
  joinAsDriverMessageAddInfo: 'قم بتحديث بياناتك قبل إرسال طلب التوظيف',
  send: 'إرسال',
  requestToJoinSent: 'تم إرسال الطلب',
  requestToJoinSentMessage: 'طلبك تحت المراجعة، سوف يتم إشعارك عند رد المتجر. يمكنك مشاهدة باقي طلبات التوظيف في الملف الشخصي',
  requestToJoinSentAgainMessage: 'تم تحديث طلبك لدى المتجر وطلبك تحت المراجعة، سوف يتم إشعارك عند رد المتجر. يمكنك مشاهدة باقي طلبات التوظيف في الملف الشخصي',
  jobRequest: 'طلب توظيف',
  resendRequest: 'إعادة إرسال الطلب',
  applied: 'تم التقدم',
  resendDriverRequestMessage: 'هل أنت متأكد من التقدم لهذة الوظيفة مرة أخرى؟',
  resendOnly: 'إعادة إرسال ',
  deleteJobRequest: 'حذف طلب التوظيف',
  deleteJobRequestMessage: 'هل أنت متأكد من حذف طلب التوظيف؟',
  addJobRequest: 'إضافة طلب توظيف',
  updateJobRequest: 'تحديث طلب التوظيف',
  hintLeaveBranchEmptyDriverRequest: 'إختيار الفرع (الفروع) التي ترغب في العمل بها، أو أترك الحقل فارغ للتقدم للعمل لكل الفروع',
  requestUpdated: 'تحديث الطلب',
  business: 'المتجر',
  driverRequestUpdateSuccessMessage: 'مبروك، تمت الموافقة على طلبك وتمت إضافتك لفريق العمل لديهم',
  driverRequestUpdateRejectMessage: 'تم رفض طلبك للعمل مع المتجر',
  driverOpenings: 'إعلانات مناديب التوصيل (طير شلوى)',
  assignPickupMessage: 'هل أنت متأكد من قبولك لتوصيل الطلب؟',
  cancelPickupMessage: 'هل أنت متأكد من عدم قيامك بتوصيل هذا الطلب؟',
  cancelDeliveryMessage: 'هل أنت متأكد من عدم قيامك بتوصيل هذا الطلب؟',
  cancelOrderMessage: 'هل أنت متأكد من إلغاء هذا الطلب؟',
  cancelOrderAfterDeliverMessage: 'قام مندوب التوصيل (طير شلوى) بتأكيد تسليم الطلب، الرجاء تأكيد الإستلام حتى لا يتم تغريم المندوب برسوم الطلب. هل أنت متأكد من طلب الإلغاء؟',
  cancelNewOrderMessage: 'لم يتم تأكيد الدفع لهذا الطلب. إذا قمت بالدفع ولكنك لم تستكمل عملية الدفع، نرجو عدم إلغاء الطلب.\nإذا قمت بإلغاء الطلب وتم تأكيد الدفع سوف نقوم بإرجاع المبالغ المدفوعة بعد خصم رسوم الدفع.\nهل أنت متأكد من طلب الإلغاء؟',
  deliveryAllowedMessage: 'مسموح بالتوصيل حتى {0}, {1}',
  assignPickupOrderWithNumber: 'تم قبول توصيل الطلب #{value}',
  cancelOrderPickupWithNumber: 'تم إلغاء توصيل الطلب #{value}',
  cancelOrderWithNumber: 'إلغاء الطلب #{value}',
  deliverOrderWithNumber: 'توصيل الطلب #{value}',
  deliverOrderMessage: 'هل أنت متأكد أنك تريد إكمال الطلب؟',
  withAllItems: 'مع كل المنتجات',
  withSelectedItems: 'مع منتجات مختارة',
  reasonDetails: 'تفاصيل السبب',
  confirm: 'تأكيد ',
  enterVerificationCodeFromCustomer: 'قم بإدخال كود التوصيل من العميل',
  deliver: 'توصيل',
  selectCancelReason: 'إختيار سبب الإلغاء',
  orderDataNoLatest: 'بيانات الطلب غير سليمة',
  onlyAvailableWithValue: 'فقط {value} متاحة',
  cancelledItemsWithValue: {
    'one': '{count} منتجات ملغية',
    'other': '{count} منتجات ملغية'
  },
  notAvailable: 'غير متوفرة',
  orderActions: 'مراحل الطلب',
  orderStateAll: 'الكل',
  orderStateReadyToPick: 'جاهز',
  orderStateDriverAssigned: 'تم الإسناد',
  orderStateDelivered: 'تم التوصيل',
  orderStateCancelled: 'تم الإلغاء',
  noOrdersAvailable: 'لا توجد طلبات توصيل متاحة',
  noDriverAssignedOrders: 'لا توجد طلبات مستلمة',
  noDeliveredOrders: 'لا توجد طلبات تم توصيلها',
  noCancelledOrders: 'لا توجد طلبات تم إلغائها',
  noShipments: 'لا توجد طلبات توصيل',
  noOrders: 'لا توجد طلبات شراء',
  searchDriverOrder: 'البحث برقم الطلب، المبلغ، رقم العميل',
  searchOrder: 'البحث برقم الطلب، المبلغ، المتجر',
  customersLocation: 'موقع العميل',
  "generic.agoDayWAgo": '{count} يوم',
  "generic.agoDaysWAgo": '{count} ايام',
  "generic.agoHourWAgo": '{count} ساعة',
  "generic.agoHoursWAgo": '{count} ساعة',
  "generic.agoMinuteWAgo": '{count} دقيقة',
  "generic.agoMinutesWAgo": '{count} دقائق',
  "generic.agoMonthWAgo": '{count} شهر',
  "generic.agoMonthsWAgo": '{count} اشهر',
  "generic.agoSecondWAgo": '{count} ثانية',
  "generic.agoSecondsWAgo": '{count} ثواني',
  "generic.agoYearWAgo": '{count} سنة',
  "generic.agoYearsWAgo": '{count} سنة',
  orderNoWithNumber: 'طلب رقم #{0}',
  itemsCountWithCount: {
    'one': '{count} منتج',
    'other': '{count} منتجات'
  },
  subTotalWithItemsCount: {
    'one': 'المجموع ({count} منتج)',
    'other': 'المجموع ({count} منتجات)'
  },
  storeLocation: 'موقع المتجر',
  cancelled: 'ملغي',
  refundInWallet: 'تم الاسترجاع في المحفظة',
  paidUsingCard: 'تم الدفع بواسطة البطاقة',
  refundInCard: 'تم الاسترجاع إلى البطاقة',
  refundWillInitiateOnOrderCompletion: 'سوف تبدأ عملية الاسترجاع بعد الانتهاء من الطلب',
  approveOrderWithNumber: 'الموافقة على الطلب #{value}',
  approveOrderMessage: 'بموافقتك على الطلب سوف يتم الاستمرار بالمنتجات المتاحة. أي فارق في المبالغ المدفوعة سوف يتم استرجاعه عند استلام الطلب. هل ترغب بالموافقة على الطلب فقط بالمنتجات المتاحة؟',
  deliveryAddress: 'عنوان التوصيل',
  items: 'المنتجات',
  otpInfoMessage: 'كود التحقق للاستلام',
  driverOtpInfoMessage: 'كود التحقق لاستلام الطلب من المتجر',
  offerFees: 'رسوم العرض',
  offerFeesDeductibleFromWallet: '(تخصم من المحفظة)',
  offerFeesDeductedFromWallet: '(الخصم من المحفظة)',
  offerFeesRevertedToWallet: '(تضاف إلى المحفظة)',
  driverLocation: 'موقع مندوب التوصيل (طير شلوى)',
  deliveringTo: 'التوصيل إلى',
  orderFromStore: 'الطلب من متجر',
  retryLocation: 'قم بإختيار الموقع مرة أخرى',
  driverLocationNotAvailable: 'موقع مندوب التوصيل (طير شلوى) غير متاح',
  receivedOrderWithNumber: 'تم استلام الطلب #{value}',
  receivedOrderMessage: 'هل أنت متأكد من استلام الطلب؟',
  addressNotAvailableForOrder: 'نعتذر، هذا الموقع غير متاح لطلبات التوصيل ويمكنك إختيار موقع أخر',
  storePickup: 'سفري',
  dineIn: 'محلي',
  addCard: 'إضافة بطاقة للدفع',
  cardDeleted: 'تم حذف البطاقة',
  deleteCard: 'حذف البطاقة',
  deleteCardMessage: 'هل أنت متأكد من حذف البطاقة؟',
  addNewCard: 'إضافة بطاقة جديدة',
  savedCardsCapitalize: 'البطاقات المحفوظة',
  noSavedCards: 'لا توجد بطاقات محفوظة',
  savedCards: 'البطاقات المحفوظة',
  selectPaymentMethod: 'قم بإختيار وسيلة الدفع',
  selectPayment: 'إختيار الدفع',
  placeOrderQ: 'تأكيد طلب الشراء؟',
  continue: 'للاستمرار',
  cameraPermissionDenied: 'السماح بالوصول للكاميرا مرفوض',
  permissionDeniedCamera: 'لقد قمت برفض السماح بالوصول إلى الكاميرا',
  permissionDeniedCameraMessage: 'لقد قمت برفض السماح بالوصول إلى الكاميرا. الرجاء السماح بالوصول حتى يمكنك إلتقاط الصور',
  microphonePermissionDenied: 'لقد قمت برفض السماح بالوصول إلى مسجل الصوت',
  permissionDeniedMicrophoneMessage: 'لقد قمت برفض السماح بالوصول إلى مسجل الصوت. الرجاء السماح بالوصول حتى يمكنك وضع المقاطع الصوتية',
  permissionDeniedMicrophone: 'لقد قمت برفض السماح بالوصول إلى مسجل الصوت',
  photoPermissionDenied: 'السماح بالوصول إلى الصور مرفوض',
  permissionDeniedPhotoMessage: 'لقد قمت برفض السماح بالوصول إلى الصور. الرجاء السماح بالوصول حتى يمكنك وضع الصور',
  permissionDeniedPhoto: 'لقد قمت برفض السماح بالوصول إلى الصور',
  permissionDeniedStorageMessage: 'لقد قمت برفض السماح بالوصول إلى الذاكرة المخزنة. الرجاء السماح بالوصول حتى يمكنك وضع الصور',
  storagePermissionDenied: ' السماح بالوصول إلى الذاكرة المخزنة مرفوض',
  permissionDeniedStorage: 'لقد قمت برفض السماح بالوصول إلى الذاكرة المخزنة',
  locationPermissionDenied: 'السماح بالوصول إلى المواقع مرفوض',
  permissionDeniedLocation: 'لقد قمت برفض السماح بالوصول إلى موقعك الحالي.',
  permissionDeniedLocationAlwaysMessage: 'لقد قمت برفض السماح بالوصول إلى موقعك الحالي في الخلفية. الرجاء السماح بالوصول حتى نتمكن من ربط المعلومات بموقعك الحالي.',
  openSettings: 'فتح إعدادات الجهاز',
  noOpinions: 'لا يوجد أراء',
  stopDelivering: 'إيقاف التوصيل',
  stopDeliveringMessage: 'لن يمكنك استقبال الطلبات إذا قمت بإيقاف التوصيل. هل ترغب بإيقاف التوصيل لهذا المتجر؟',
  startDelivering: 'البدء بالتوصيل',
  startDeliveringMessage: 'هل أنت متأكد من بدء التوصيل لهذا المتجر؟',
  start: 'إبدأ',
  stop: 'توقف',
  shipmentDelivering: 'توصيل الطلبات',
  suspended: 'معلق',
  engaged: 'مشغول',
  inAllTheBranches: 'في كل الفروع',
  inSelectedBranches: 'في الفروع المختارة',
  orderItemsReviewNeededWithOrderNo: 'فضلاً مراجعة المنتجات في الطلب #{value}',
  orderConfirmedWithOrderNo: 'تم تأكيد الطلب #{value}',
  orderReadyToPickWithOrderNo: 'تم تجهيز الطلب #{value}',
  orderShippedWithOrderNo: 'تم شحن الطلب #{value}',
  orderDeliveryCancelledWithOrderNo: 'فشل توصيل الطلب #{value}',
  orderDeliveredWithOrderNo: 'تم توصيل الطلب #{value}',
  orderCancelledWithOrderNo: 'تم إلغاء الطلب #{value}',
  orderRefundedWithOrderNo: 'تم استرجاع قيمة الطلب #{value}',
  messagedInOrderWithOrderNo: 'رسالة لك من الطلب #{value}',
  ratingForDriver: 'تقييم مندوب التوصيل',
  ratingForCashier: 'تقييم البائع',
  ratingForBusiness: 'تقييم المتجر',
  ratingForOffer: 'تقييم العرض',
  reviewYourOrderWithValue: 'مراجة الطلب #{value}',
  orderWithValue: 'طلب #{value}',
  enable: 'تمكين',
  delivered: 'تم التوصيل',
  refundOfferFees: 'استرجاع رسوم العرض',
  buyAgain: 'اشتري مرة أخرى',
  orderAgainMessage: 'سوف يتم إضافة هذه المنتجات إلى السلة، سوف يتم استبدال كافة محتويات السلة إذا كانت من متجر أخر. هل أنت متأكد من إضافة هذة المنتجات إلى السلة؟',
  pickupExtraMessage: '#شاركنا بيانات سيارتك مثل اللون، النوع، في ملاحظاتك للمتجر لتسهيل الاستلام من المتجر',
  refundCharges: 'رسوم الاسترجاع',
  refundChargesMessage: 'تم الإلغاء بواسطة العميل',
  viewOrders: 'مشاهدة الطلبات',
  checkStatusAgain: 'مراجعة الحالة مرة أخرى',
  notDeliverable: 'غير قابل للتوصيل',
  deliverable: 'قابل للتوصيل',
  delivery: 'توصيل',
  noDelivery: 'بدون توصيل',
  rates: 'أجور التوصيل',
  fixedRate: 'قيمة ثابتة',
  noRate: 'لا يوجد أجر محدد',
  notSet: 'غير محدد',
  deliverySuccessRate: 'أجر التوصيل الناجح',
  deliveryFailureRate: 'أجر التوصيل الغير ناجح',
  driverHiringInfo: 'معلومات طلب توظيف المناديب (طير شلوى)',
  driverHiringBranches: 'توظيف المناديب في الفروع',
  noDriverOpenings: 'لا توجد إعلانات لمناديب التوصيل',
  encash: 'تحصيل نقدي',
  promptEncash: 'هل أنت متأكد من تقديم طلب تحصيل نقدي؟',
  promptEncashMessage: 'تأكد من قيامك من طلب التحصيل النقدي في الفروع المحددة. من الأفضل التواجد في الفرع عن القيام بطلب الاستلام.',
  hintEnterMoneyToEncash: 'إدخال المبلغ المراد تحصيله',
  driverEarningEncash: 'التحصيل النقدي لمندوب التوصيل',
  byItem: 'المنتج',
  rateItem: 'تقييم المنتج',
  wallet: 'المحفظة',
  deliveryInsight: 'تحليل بيانات التوصيل',
  offerExpired: 'انتهت فترة العرض',
  orderingBans: 'متاجر حظرتني',
  noOrderingBans: 'لا يوجد متجر يمنعك من الطلب',
  hintOrderingBans: 'لقد قامت المتاجر بوضعك على قائمة منع الطلب من متاجرهم.',
  consumeWalletMoney: 'استهلاك رصيد المحفظة',
  selectWalletMoneyToConsume: 'قم بإختيار المبلغ المراد استهلاكة {0} و {1}',
  walletBalanceIsLowThanSelected: 'رصيد المحفظة أقل من المبلغ المطلوب',
  reviewItem: 'قم بتقييم المنتج',
  addCapitalize: 'إضافة',
  customizable: 'قابل للتعديل',
  tellAboutLiked: 'أخبرنا ما الذي أعجبك',
  tellAboutDisLiked: 'أخبرنا ما الذي لم يعجبك',
  postAsAnonymous: 'اظهر اسمي كمجهول',
  commentAsAnonymous: 'قم بالتعليق كمجهول',
  ring: 'إرسال تنبيه للعميل',
  ringCustomerDevice: 'إرسال تنبيه للعميل بنغمة على جهازه',
  ringCustomerDeviceMessage: 'سوف تقوم بإرسال تنبيه للعميل بنغمة على جهازه وذلك في حال إحتياجك لمساعدة عاجلة تتعلق بالطلب الذي تقوم بتوصيله. هل أنت متأكد من إرسال التنبيه؟',
  ringCustomerDeviceSent: 'تم إرسال تنبيه الطلب إلى العميل',
  rab7Support: 'خدمة العملاء - Rab7 ',
  noMessagesSupport: 'لا توجد رسائل\n\nقم بإرسال رسالة إذا احتجت للمساعدة؟',
  happyToAssistYou: 'سعيدون بمساعدتك',
  endChat: 'إنهاء المحادثة',
  requestResolved: 'تم الرد على الاستفسار',
  requestResolvedMessage: 'هل أنت متأكد من إنهاء المحادثة وأن استفسارك قد تم الرد عليه؟',
  connectLater: 'سأقوم بالتواصل فيما بعد',
  connectLaterTitle: 'لقد إخترت التواصل لاحقاً',
  endChatMessages: 'هل أنت متأكد من إنهاء المحادثة؟',
  chatStartMessage: 'عزيزي العميل، نشكرك على التواصل مع دعم Rab7 ، طلبك للخدمة رقم #{value}. نرجو كتابة تفاصيل الاستفسار سوف نقوم بالرد عليك في أسرع وقت.',
  notificationChannelNormal: 'إشعارات عادية',
  notificationChannelNormalDesc: 'إشعارات خاصة بمنشوراتك، للقيام بعمليات لدى المتاجر، قبول الطلبات والتحديثات و باقي اشعارات النظام.',
  notificationChannelImportant: 'إشعارات هامة',
  notificationChannelImportantDesc: 'إشعارات رسائل الدعم، والتحديثات على طلبات الشراء',
  notificationChannelVeryImportant: 'إشعارات مهمة',
  notificationChannelVeryImportantDesc: 'إشعارات لتنببه السائق في حال توفر طلبات للتوصيل',
  notificationChannelRing: 'إشعارات نبيه الجوال',
  notificationChannelRingDesc: 'إشعارك في حال رغبة مندوب التوصيل بمساعدتك لتوصيل طلبك',
  noStaffForChat: 'لا يوجد أحد للرد على محادثتك في الوقت الحالي، نرجو معاودة التواصل فيما بعد',
  selectStoreToConnect: 'قم بإختيار فرع للتواصل',
  selectBranchForChat: 'اختر الفرع الذي تريده',
  connect: 'تواصل',
  branchChats: 'تواصل مع موظفي المتجر',
  manageChats: 'إدارة المحادثات',
  deleteChat: 'حذف المحادثة',
  deleteEmployeeChatMessage: 'حذف المحاثة سوف يمنع موظفي المتجر من الوصول إليك. يمكنك بدء المحادثة مع المتجر من ملف المتجر.\nهل أنت متأكد من حذف المحادثة؟',
  chatDeleted: 'تم حذف المحادثة',
  profileUpdateRestrictionMessage: 'مسموح لك بتغيير تفاصيل ملفك الشخصي مرة كل شهر، ماعدا الاسم، صورة العرض ورقم الجوال',
  fromTo: '{from} إلى {to}',
  campaignReviews: 'تقييمات الحملات',
  campaignReview: 'تقييم الحملة',
  activityReviewShareMessage: 'شاهد تقييم "{title}" لحملة "{campaign}" من متجر "{businessName}". {url}',
  reportCampaignReview: 'بلغ عن التقييم',
  commentedOnActivityReview: 'تم التعليق في تقييم الحملة',
  repliedOnActivityReview: 'تم الرد في تقييم الحملة',
  shareOpinionFeedback: 'شاركنا برأيك في الحملة',
  shareCampaignFeedback: 'شاركنا برأيك في الحملة',
  shareCampaignFeedback2nd: 'سيكون موجود في تقييمات الحملة ويمكن للباقيين الاستفادة من رأيك',
  reviewCampaign: 'قيم الحملة',
  by: 'بواسطة',
  requestedToShareContact: 'طلب معرفة رقم جوالك في التقييم',
  stopContactNumberSharing: 'وقف مشاركة رقم جوالك',
  stopContactNumberSharingMessage: 'رقم جوالك شاركته مع المتجر الحين،تبغى توقف المشاركة؟',
  shareContactNumber: 'شارك رقم جوالك',
  shareContactNumberMessage: 'رقم جوالك غير معروف للمتجر, تبغى تشاركه مع المتجر؟',
  contactNumberShared: 'تمت مشاركة رقم جوالك مع التاجر',
  contactNumberSharingStopped: 'تم إيقاف مشاركة رقم جوالك',
  businessIsRequestingContactNumber: 'المتجر يطلب معرفة رقم جوالك',
  myCampaignReviews: 'تقييماتي للحملات',
  confirmationByMessage: 'تأكيد بواسطة {0}, {1}',
  deliveryByMessage: 'تسليم بواسطة {0}, {1}',
  pickupReadyByMessage: 'المنتج جاهز بواسطة {0}, {1}',
  approvalAllowedMessage: 'التأكيد متاح لغاية {0}, {1}\nالفرق في السعر سيتم استرجاعة حالما يتم تأكيد استلام الطلب',
  delayed: 'متأخر',
  activityReviews: 'التعليقات',
  locationNeededInBackground: 'الموقع مطلوب دائمًا',
  locationNeededInBackgroundMessage:' يجمع هذا التطبيق بيانات الموقع لمشاركة موقع السائق الحالي للطلب مع العميل حتى في حالة إغلاق التطبيق أو عدم استخدامه.',
  grant: 'منح الصلاحية',
  permissionDeniedLocationDriverMessage: ' لقد رفضت الإذن بالوصول إلى موقع الجهاز.\nالرجاء منح إذن الموقع دائمًا حتى عندما يكون التطبيق مغلقًا أو ليس قيد الاستخدام لتوفير متابعة الوقت الحقيقي للتسليم.\nيتم متابعة الموقع فقط أثناء الفترة التي يتم فيها تعيين التسليم إلى أنت.',
  locationNotUpdatedDriverMessage: 'الرجاء تمكين الموقع دائمًا حتى عندما يكون التطبيق مغلقًا أو غير مستخدم لتوفير متابعة الوقت الفعلي للتسليم.\nيتم متابعة الموقع فقط أثناء الفترة التي يتم فيها تعيين التسليم لك.',
  editPhoto: 'تعديل الصورة',
  placeOrderMessage: 'سيتم توصيل الطلب للعنوان الذي قمت بإختياره، وسيتم توصيل الطلب من خلال (سائقي المتجر)\n هل ترغب في التأكيد؟',
  placePickupOrderMessage: 'سوف تقوم بتأكيد طلب الشراء واستلامه من الفرع المحدد، اكمال الطلب؟',
  placeDineInOrderMessage: 'سوف تقوم بتأكيد طلب الشراء والاستمتاع به داخل المتجر المحدد، اكمال الطلب؟',
  processingImage: 'معالجة الصورة..',
  locationServiceDisabled: 'خدمة تحديد المواقع غير مفعلة',
  locationServiceDisabledMessage: ' لقد قمت بتعطيل خدمة تحديد المواقع ، فضلاً قم بتفعيلها لنستطيع ان نجلب لك البيانات والطلبات حسب موقعك من خلال الاعدادات في جوالك',
  noLikes: 'لا يوجد إعجابات',
  reviewLikes: 'الإعجابات',
  notifyLikes: 'اشعارات للاعجابات',
  activityShareMessage: 'هلابك، لا يفوتك هذا الشي في تطبيق Rab7 "{title}". {url}',
  earnFormulaInformation: 'تفاصيل الاكتساب والخصم',
  maximumConsumeSpecificAmountHint: 'المبالغ المتاح استخدامها كخصومات في المتجر هي كالتالي',
  maximumConsume: 'حد الخصم',
  maximumConsumeHint: 'القيم التي يمكن ايتخدامها كخصومات في المتجر',
  deliveryTimings: 'أوقات التوصيل',
  unclaimedBalancePresent: 'عندك نقاط لم تفعل لحد الآن',
  unclaimedBalancePresentMessage: 'أضغط هنا علشان تفعلها وتقيم زيارتك',
  allowed: 'مسموح',
  notAllowed: 'غير مسموح',
  consumeBalance: 'استهلاك الرصيد',
  consumeBalanceMessageHint: 'الرصيد مسموح استهلاكه فقط في المشتريات من خلال التطبيق',
  showArabic: 'معلومات الواجهة العربية',
  showEnglish: 'معلومات الواجهة الانجليزية',
  availableIn: 'متوفر في',
  only: 'فقط',
  comma: ',',
  rab7ServiceCharges: 'رسوم تطبيق Rab7 ',
  generateOrderPickupTicketWithNumber: ' أنشاء تذكرة استلام الطلب رقم # {value}',
  generateOrderPickupTicketMessage: 'بإظهار التذكرة فأنت تؤكد وجودك بالمتجر لاستلام الطلب وتوصيله للعميل الذي سيظهر لك ولن تستيطيع الإلغاء. \nهل أنت متأكد من قيامك بالتوصيل؟',
  generate: 'نعم',
  notNow: 'ليس الان',
  orderPickupTicket: 'تذكرة استلام الطلب',
  customer: 'العميل',
  driver: 'السائق',
  rab7HashWithValue: ' Rab7 #{value}',
  deliveringOrderWithoutCodeWithNumber: 'توصيل طلب رقم  #{value} بدون رمز تحقق',
  deliverOrderWithoutCodeMessage: 'توصيل الطلب بدون اخذ رمز التحقق من العميل قد يمكن العميل من الاعتراض بعدم استلامه , لذلك من الافضل اخذ رمز التحقق من العميل تفاديا لاي اجراء , هل أنت متاكد من تاكيد التوصيل بدون رمز تحقق ؟',
  uptoKmsMoneyWithValue: 'لغاية {value1} كلم: {value2} {value3}',
  afterKmsMoneyWithValue: 'اضافي لكل كلم  {value1} كلم: {value2} {value3}',
  rab7ServiceFeeReceipt: 'فاتورة رسوم الخدمة',
  helpNSupport: 'مساعدة للطلب',
  helpNSupportInOrderMessage: 'اختر نوع الاعتراض',
  selectIssue: 'أختر الاعتراض',
  raiseDispute: 'ارسل الاعتراض',
  disputeInProgressMessage: 'أعتراضك لازال تحت المعالجة',
  needMoreHelpMessage: '\nتحتاج مزيد من المساعدة ؟ \n\n بامكانك التحدث مع المتجر من خلال المحادثة',
  contactStore: 'التحدث مع المتجر',
  contactUs: 'دعم Rab7 ',
  orderIssueResolvedWithOrderNo: 'حل الاعتراض باسترجاع القيمة للطلب #  {value}',
  orderIssueClosedWithOrderNo: 'تم اغلاق الاعتراض من قبل المتجر #{value}',
  forgotPasswordDetail: ' فضلا ادخل رقم الجوال لاستعادة كلمة المرور , وسيتم ارسال رمز تحقق لك لادخاله لانشاء كلمة مرور جديدة ',
  verificationCodeDetails: 'ارسل رمز التحقق المرسل لجوالك',
  discountOnDelivery: 'خصم على رسوم التوصيل',
  discountOnItems: 'خصم على المنتجات',
  businessPointsNeeded: 'رصيد المحفظة المطلوب',
  offerFee: 'خصم من رصيد المحفظة',
  selectStore: 'اختر المتجر',
  selectProduct: 'اختر المنتج للعرض',
  noProductsAvailableInOffer: 'لا توجد منتجات متوفرة لهذا العرض',
  customerDistanceFromStore: 'مسافة العميل عن المتجر',
  pickupTicketMessage: 'أظهر تذكرة الاستلام للكاشير\n\nتنبيه هام\nيجب الحصول على كود استلام الطلب من العميل وإدخاله قبل الضغط على زر تأكيد الاستلام حتي لا يتم خصم مبلغ الطلب منك.\n\nيمكنك إغلاق الشاشة X للحصول على موقع العميل',
  helpNSupportInOrderMessageAfterSelect:' لقد حددت الاعتراض المتعلق بالطلب على أنه \n\n{value}\n\n  ، تنبيه : تطبيق Rab7 مسؤول عن رفع الاعتراض فقط وليس عن حله \n\n  سيتم رفع الاعتراض المحدد إلى المتجر ، وسيقوم المتجر بمعالجتها وفقًا لذلك . قد يستغرق حل الاعتراض عن طريق المتجر بعض الوقت. \n هل تريد بالتأكيد رفع الأعتراض ؟',
  issueResolutionByMessage: 'تم رفع الاعتراض للمتجر وهم بصدد حل الاعتراض من خلال {0}, {1}',
  shipmentDeliveryDisabled: 'تعطيل خدمة توصيل الطلبات',
  shipmentDeliveryDisabledMessage: 'تمكين خدمة توصيل الطلبات',
  customerMode: 'واجهة العميل',
  driverMode: 'واجهة السائق',
  switchAppDisplayMode: 'تغيير الواجه',
  switchAppDisplayModeMessage: 'يمكنك التغيير بين واجهة العميل والسائق\n\nلتوصيل الطلبات قم بإختيار واجهة السائق. وللواجهة العادية قم بإختيار واجهة العميل',
  customerModePrompt: 'متأكد من رغبتك للتغيير؟',
  driverModePrompt: 'متأكد من رغبتك للتغيير؟',
  appSwitchToCustomerMode: 'تم التغيير لطريقة العرض الخاصة بالعميل',
  appSwitchToDriverMode: 'تم التغيير لطريقة العرض الخاصة بالسائق',
  howTo: 'كيف أفعل؟',
  confirmDeliver: 'تأكيد تسليم الطلب للعميل',
  confirmDeliverWithoutCode: 'توصيل بدون رمز تحقق',
  otpWithValue: 'رمز التحقق: {value}',
  noStoresFound: 'المتجر غير متوفر',
  multipleStoresHere: 'متاجر متعددة',
  nearbyStores: 'متاجر مجاورة',
  businessOrderReceipt: 'فاتورة المتجر',
  commentsOptional: 'التعليق -- إختياري--',
  addImages: 'اضف صورة',
  redeemOfferCode: 'تفعيل عروض جديدة',
  redeemOfferCodeMessage: 'أدخل كود العرض لجعل العرض متوفرا لديك',
  redeem: 'تفعيل',
  offerCodeRedeemed: 'تم تفعيل العرض',
  enterOfferCodeToRedeem: 'أدخل كود العرض',
  redeemCode: 'فعّل العرض',
  bankName: 'اسم البنك',
  bankBranchName: 'اسم فرع البنك',
  accountName: 'اسم الحساب',
  accountNumber: 'رقم الحساب',
  iban: 'الأيبان',
  searchNormalStores: 'بحث',
  searchItems: 'بحث',
  quickOrderTitle: 'جوعان',
  landmark: 'وصف اضافي',
  selectCity: 'اختر المدينة',
  selectDistrict: 'اختر الحي',
  soldOut: 'نفدت الكمية',
  deliveryInTime: 'توصيل خلال {value}',
  pickupInTime: 'استلام خلال {value}',
  cod: 'دفع عند الإستلام',
  bankTransfer: 'تحويل بنكي',
  storeBankDetails: 'معلومات التحويل البنكي',
  hintBankTransfer: 'يرجى تحويل المبلغ إلى تفاصيل البنك الخاصة بالمتجر المعروضة أعلاه.\nقم باكمال الطلب الآن ثم قم بتحميل إيصال التحويل المصرفي لاحقًا في شاشة الطلبات وذلك لتسهيل تتبع المتجر للتحويل المصرفي الخاص بك واتمامه لإجراءات الطلب.',
  addBankTransferDetails: 'اضف معلومات التحويل البنكي',
  transactionId: 'رقم العملية',
  addBankTransferDetailsMessage: 'تأكد ان الصورة فيها تفاصيل عملية التحويل بشكل واضح',
  trackingIdCopied: 'تم نسخ رقم الشحنة',
  returnPolicy: 'سياسة الاسترجاع والاستبدال',
  placeOrderCod: 'الدفع عند الاستلام',
  placeOrderBankTransfer: 'الدفع بطريقة التحويل المصرفي',
  vatDocument: 'شهادة الضريبة',
  totalRefund: 'مجموع المبلغ المسترد',
  localityNameWithDistance: '{0}، ~ {1}',
  mandatoryHint: '* حقل إلزامي',
  prev: 'السابق',
  productNotFound: 'المنتج غير موجود',
  addItemAd: 'أضف إعلان',
  editItemAd: 'تعديل إعلان',
  itemInformation: 'معلومات الإعلان',
  locationInformation: 'معلومات الموقع',
  category: 'الفئة',
  title: 'العنوان',
  setPrice: 'السعر',
  postType: 'نوع المشاركة',
  informative: 'معلومة',
  enquiry: 'إستفسار',
  addPost: 'أضف مشاركة',
  appName: 'رابح',
  posts: 'التفاعلات',
  shareApp: 'شارك تطبيق Rab7 ',
  userItems: 'ممتلكات المستخدم',
  usedItems: 'مقتنيات',
  usedItem: 'مستعمل',
  myUsedItems: 'ممتلكاتي',
  myPosts: 'مشاركاتي',
  noUsedItems: 'لايوجد ممتلكات للمستعمل',
  noPosts: 'لا يوجد مشاركات',
  userReviews: 'تقييم المستخدم',
  sellUsedItem: 'اعرض أشيائك المستعملة',
  postUpdated: 'تم التعديل',
  postAdded: 'تم الإضافة',
  usedItemUpdated: 'تم التعديل بنجاح',
  usedItemAdded: 'تم الإضافة بنجاح',
  editPost: 'تعديل',
  deleteUsedItem: 'حذف',
  deleteUsedItemPromptMessage: 'متأكد من رغبتك بالحذف ؟',
  deletePost: 'حذف',
  deletePostPromptMessage: 'متأكد من رغبتك بالحذف ؟',
  morePeopleLikedReview: '{value} أعجب بمشاركتك',
  likedReview: 'أعجب بمشاركتك',
  sharePostMessage: 'لا تفوتك هذي المشاركة في تطبيق Rab7 . {title}. {url}',
  shareUsedItemMessage: 'لا يفوتك لقيت هذا الشيء في تطبيق Rab7 , {title}. {url}',
  similarItems: 'ممتلكات مشابهة',
  sellerItems: 'ممتلكات البائع',
  barter: 'مقايضة',
  selectItemToBarter: 'اختر الذي تريد المقايضة به',
  noUsedItemsToBarter: 'لا يوجد عناصر للمقايضة بها, قم باضافتها',
  hintAddCommentToCreateBarterDeal: 'اضف تفاصيل مقايضتك',
  barterDeal: 'مقايضة',
  appNameLanding: 'مجتمع Rab7 ',
  community: 'مجتمع',
  jobs: 'فرصة و مهارة',
  noJobsFound: 'غير متوفر الآن',
  noTalentsFound: 'لا توجد مهارات متوفرة الآن',
  jobSubWork: 'فرصة',
  jobSubTalent: 'مهارة',
  myJobPosts: 'مشاركات الفرص والمهارات',
  jobPosts: 'مشاركات الفرص والمهارات',
  jobPost: 'مشاركات الفرص والمهارات',
  myTalentPosts: 'مشاركات المهارات',
  talentPosts: 'المهارات',
  talentPost: 'المهارات',
  updateJob: 'تحديث',
  addJob: 'إضافة',
  updateResume: 'تحديث',
  addResume: 'إضافة',
  jobTitle: 'مسمى الوظيفة',
  jobDescription: 'تفاصيل الوظيفة',
  jobType: 'نوع الوظيفة',
  additionalDetails: 'معلومات إضافية',
  contactEmail: 'البريد الإلكتروني',
  jobPosted: 'تم الإضافة',
  jobUpdated: 'تم التحديث',
  fullTime: 'دوام كامل',
  partTime: 'دوام جزئي',
  oneTime: ' قطوعة',
  basicInformation: 'المعلومات الأساسية',
  locationNContactInformation: 'الموقع و عنوات التواصل',
  contactInformation: 'معلومات التواصل',
  resumeAdded: 'تمت الإضافة',
  resumeUpdated: 'تم التحديث',
  resumeTitle: 'مسمى المهارة',
  skills: 'المهارات',
  experience: 'الخبرات',
  job: 'الوظيفة',
  resume: 'أكمل الاجابة',
  normalOrderTitle: 'تسوّق',
  deleteJobPost: 'تم الحذف',
  deleteJobPostPromptMessage: 'هل أنت متأكد من الحذف',
  deleteResumePost: 'حذف',
  deleteResumePostPromptMessage: 'هل أنت متأكد من الحذف ؟',
  shareJobPostMessage: 'شيك على هذه الفرصة الوظيفية , {title}. {url}',
  shareResumePostMessage: 'شيك على هذه السيرة الذاتية, {title}. {url}',
  itemDetails: 'تفاصيل المنتج',
  item: 'المنتج',
  trips: 'رحلات',
  tripSubTravellers: 'مسافرون',
  tripSubShipments: 'شحنات',
  noTravellersAvailable: 'لا يوجد شيء الآن',
  noShipmentsAvailable: 'لا يوجد شيء الآن',
  subHeaderAddQuestion: 'ضع استفسارك هنا وسيقوم أعضاء مجتمع Rab7 بالرد عليك بخبراتهم',
  subHeaderAddJob: 'ضع المهارة والخبرة التي تريد هنا وسيقوم أعضاء مجتمع Rab7 من افراد ومتاجر ممن لديهم تلك المهارات والخبرات بالتواصل معك لتقديمها لك',
  subHeaderAddResume: 'ضع مهاراتك وخبراتك وسيقوم أعضاء مجتمع Rab7 من افراد ومتاجر ممن يرغبون بخدماتك بالتواصل معك للاتفاق على تقديم خدماتك لهم بمقابل',
  subHeaderAddUserItem: 'قم وبمشاركة أغراضك التي تريد بيعها وسيقوم أعضاء مجتمع Rab7 من افراد ومتاجر لتقديم صفقة جيدة أو المقايضة بأشيائهم معك',
  subHeaderAddUserDemand: 'ضع المنتجات التي تبحث عنها وسيقوم أعضاء مجتمع Rab7 من افراد ومتاجر بالرد عليك بعروض و منتجاتهم حسب طلبك و بصفقات جيدة',
  nameColon: 'الإسم :',
  joinDateColon: 'تاريخ الإنضمام : ',
  rab7CommunityMember: 'بطاقة عضوية مجتمع Rab7',
  titleQuestions: 'تواصل',
  noQuestions: 'لا توجد اسئلة الآن',
  questions: 'إستفسارات',
  myQuestions: 'إستفساراتي',
  userQuestions: 'إستفسارات المستخدم',
  question: 'إستفسار',
  editQuestion: 'تعديل',
  addQuestion: 'اضف إستفسار',
  shareQuestionPostMessage: 'إطلع على الإستفسار  {title}. {url}',
  deleteQuestion: 'حذف',
  deleteQuestionPostPromptMessage: 'متأكد من رغبتك بالحذف',
  deleteDemand: 'حذف',
  deleteDemandPostPromptMessage: 'متأكد من رغبتك بالحذف',
  shareDemandPostMessage: 'إطلع على الطلب, {title}. {url}',
  addDemand: 'أبحث عن',
  demand: 'أبحث عن',
  demands: 'رغبات',
  myDemands: 'رغباتي',
  userDemands: 'رغبات المستخدم',
  noDemands: 'لا يوجد',
  demandUpdated: 'تم التحديث',
  demandAdded: 'تمت الإضافة',
  demandDetails: 'تفاصيل الرغبات',
  approximatePrice: 'السعر بشكل تقريبي',
  selectItemToFulfillDemand: 'إختر الغرض',
  noUsedItemsToFulfillDemand: 'لا يوجد لديك اغراض للبيع او المقايضة',
  similarDemands: 'رغبات مشابهة',
  sellerDemands: 'رغبات المستخدم',
  suggestStuff: 'الغرض المقترح',
  hintAddCommentToSuggestStuff: 'أضف تعليق',
  emailNMobileNumber: 'رقم التواصل و البريد الإلكتروني',
  emailNMobileNumberSubtitle: 'فضلا ضع رقم للتواصل و بريدك الإلكتروني',
  subHeaderAddTravel: 'أضف رحلتك وانتظر أعضاء مجتمع Rab7 للتواصل معك و إرسال اغراضهم معك و اربح اموالاً من ذلك',
  subHeaderAddShipment: 'أضف شحنتك التي تريد إرسالها أو إحضارها وانتظر حتى يقوم أفراد مجتمع Rab7 بالتواصل معك لحملها في رحلاتهم أو إحضارها إليك',
  addTrip: 'أضف رحلة',
  updateTrip: 'تعديل',
  tripAdded: 'تمت الإضافة',
  tripUpdated: 'تم التعديل',
  addPackage: 'أضف شحنة',
  updatePackage: 'تعديل',
  packageAdded: 'تمت الإضافة',
  packageUpdated: 'تم التعديل',
  myTrips: 'رحلاتي',
  myPackages: 'شحناتي',
  packages: 'شحنات',
  package: 'شحنة',
  trip: 'رحلة',
  noTripsFound: 'لا يوجد رحلات الان',
  noPackagesFound: 'لا يوجد شحنات الآن',
  travelFromCity: 'من مدينة',
  travelToCity: 'الى مدينة',
  travelFromDistrict: 'من الحي',
  travelToDistrict: 'إلى الحي',
  shipmentFromCity: 'ترغب في ارسال شحنة من مدينة',
  shipmentToCity: 'ترغب في جلب شحنة من مدينة',
  shipmentFromDistrict : 'ترغب في اريال شحنة من حي',
  shipmentToDistrict: 'ترغب في جلب شحنة من حي',
  travelDate: 'تاريخ الرحلة',
  shipmentDate: 'ترغب في ارسال الشحنة في نفس التاريخ',
  fromCity: 'من مدينة',
  toCity: 'إلى مدينة',
  fromDistrict: 'من حي',
  toDistrict: 'إلى حي',
  deleteTripPost: 'حذف',
  deleteTripPostPromptMessage: '؟متأكد من الحذف ',
  deletePackagePost: 'حذف',
  deletePackagePostPromptMessage: 'متأكد من الحذف',
  shareTripPostMessage: 'شيك على الرحلة من "{from}" إلى "{to}" جاهز لشحن اغراضك {url}',
  sharePackagePostMessage: 'شيك على الشحنة من  "{from}" الى "{to}" في #Rab7, {url}',
  noticeTrip: '# تطبيق Rab7 ليس مسؤولا عن صحة او سلامة المعلومات المضافة من رحلات او شحنات او عن اصحابها او محتواها',
  titleJobs: 'توظيف',
  titleDemands: 'أبحث عن',
  titleReviews: 'تقييم',
  titleStuff: 'أغراض',
  subHeaderAddPost: 'اسأل أعضاء مجتمع Rab7 أو اضف لهم معلومة من خبراتك ودعهم يتفاعلون معك بآرائهم وخبراتهم',
  shareInformation: 'اضف معلومة',
  askQuestion: 'ضع إستفسار',
  titlePosts: 'خبرات',
  permissionDeniedLocationToast: 'تم رفض إذن الموقع. يرجى منح إذن الموقع من إعدادات الجهاز إذا كنت تريد العناصر بناءً على موقعك الحالي',
  permissionDeniedLocationAlwaysToast: 'تم رفض إذن موقع خلفية التطبيق. يرجى منح إذن الموقع حتى عندما يكون التطبيق في الخلفية من إعدادات الجهاز إذا كنت تريد معالجة الشحنات المتاحة',
  consumedOfferAllLimit: 'تم إستهلاك العرض كاملاً',
  explore: 'أكتشف',
  becomeAffiliate: 'أربح وانت بمكانك',
  getAffiliateLink: 'أحصل على رابط التسويق بالعمولة',
  affiliateShareMessage: 'انضم لعضوية مجتمع Rab7 واربح العروض الاستثنائية , اكتشف  {url} الان',
  addingMoneyToWallet: 'شحن رصيد المحفظة',
  addingMoneyToWalletMessage: 'عملية شحن المحفظة قيد التنفيذ, ويمكن أن تأخذ بعض الوقت, فضلا لا تقم بالضغط على زر الرجوع أو الخروج من التطبيق',
  walletRecharged: 'تم شحن المحفظة',
  rechargeIdWithValue: 'شحن المحفظة رقم: {value}',
  cancelWalletRecharge: 'إلغاء شحن المحفظة',
  cancelWalletRechargeMessage: 'لم يتم استلام تأكيد الدفع لهذة العملية.\nإذا تمت العملية بنجاح لن يتم إلغاءها وسوف يتم إضافة الرصيد لمحفظتك, وإلا سوف يتم قبول الإلغاء.\nهل أنت متأكد من طلب الإلغاء؟',
  walletRechargePaymentIsInProcessingMessage: 'عملية الدفع قيد التنفيذ, يمكنك التأكد من حالة العملية مرة أخرى أو إنتظر بعض الوقت. يمكنك محادثة الدعم لطلب المساعدة .\nعملية رقم: {0}',
  walletRechargePaymentFailedMessageGeneric: '{0}\nإذا تم خصم المبلغ, سوف يتم إرجاع المبلغ في خلال 3-15 يوم. يمكنك التواصل مع الدعم لطلب المساعدة.\nعملية رقم: {1}',
  walletRechargePaymentCancelledMessageGeneric: 'تم إلغاء عملية الدفع.\nإذا تم خصم المبلغ, سوف يتم إرجاع المبلغ المدفوع للبطاقة في خلال 3-15 يوم. يمكنك محادثة الدعم لطلب المساعدة.\nعملية رقم: {0}',
  manageAdvertisement: 'إدارة ترويج الحملات',
  adStateExpired: 'تم إيقاف الترويج لإنتهاء تاريخ الحملة',
  adStatePausedNoWalletBalance: 'تم إيقاف الترويج لعدم وجود رصيد كافي في محفظة Rab7 للإعلانات',
  advertisementLowWalletBalance: 'لا يوجد لديك رصيد كافي في محفظة Rab7 للإعلانات لاستكمال الترويج, فضلا قم بشحن رصيد المحفظة.',
  later: 'فيما بعد',
  advertisementBudget: 'موازنة الترويج',
  advertisementDailyBudget: 'موازنة الترويج اليومية',
  hintAdvertisementDailyBudget2: 'دع الحقل فارغا ان كنت لا تريد وضع حد يومي لصرف الموازنة',
  advertisementRate: 'رسوم الإعلان',
  dailyBudgetLessThanBudget: 'الحد اليومي للترويج يجب ان يكون اقل من الموازنة المرصودة للترويج',
  adStatePausedNoBalance: 'تم إنهاء ترويج الحملة لإنتهاء الموازنة المرصودة , بامكانك زيادتها الان',
  adStatePaused: 'ترويج الحملة متوقف',
  adStateOngoing: 'ترويج الحملة فعال',
  adStateStopped: 'ترويج الحملة متوقف',
  adDailyStatePausedNoBalance: 'تم إيقاف الإعلانات اليوم لاستهلاك الرصيد الخاص بالميزانية اليومية',
  adStateStoppedCompletely: 'تم إيقاف الخدمات الإعلانية عن طريق منصة Rab7 ',
  paidPreviews: 'الوصول المدفوع',
  paidViews: 'المشاهدات المدفوعة',
  adBudget: 'ميزانية الترويج',
  adAvailableBudget: 'الميزانية المتوفرة',
  adDailyBudget: 'الحد الأعلى للصرف يوميا على الترويج',
  promotePostBalance: 'رصيد الإعلانات',
  recharge: 'شحن',
  rechargeAdWallet: 'شحن محفظة الإعلانات',
  rechargeAdWalletMessage: 'أدخل المبلغ لإضافته إلى المحفظة الإعلانية',
  enterAmountToAddToAdWalletWithMinMax: 'أدخل المبلغ لإضافته إلى المحفظة الإعلانية بين {value1} و ببن {value2}',
  walletRechargeConvenienceFee: 'رسوم اعادة الشحن {value}%',
  advertisePost: 'تروبج المنشور',
  postAdvertisementUpdated: 'تم التحديث',
  hintAdvertisementBudget: '# أدخل ميزانيتك للإعلان عن هذا المنشور ، يتم إيقاف الإعلان تلقائيًا بمجرد استهلاك الميزانية',
  hintAdvertisementDailyBudget: '# أدخل ميزانيتك اليومية للإعلان عن هذا المنشور ، فسيتم إيقاف الإعلان تلقائيًا بمجرد استهلاك الميزانية اليومية',
  gNotRegistered: 'غير مسجل',
  gMale: 'ذكر',
  gFemale: 'أُنثى',
  regions: 'المناطق',
  cities: 'المدن',
  districts: 'الأحياء',
  gender: 'الجنس',
  ageGroups: 'الفئات العُمرية',
  companies: 'العاملون في الشركات',
  customers: 'عملاء مخصصون',
  userInterests: 'الإهتمامات',
  leaveEmptyToTargetAll: 'أترك الحقل فارغا اذا رغبت في استهداف الجميع',
  targetSelectedCustomersOnly: 'إستهداف العملاء المحددون برقم Rab7 فقط',
  nationalities: 'الجنسيات',
  userTargeting: 'عناصر الإستهداف',
  adTargeting: 'الإستهداف',
  postAdTargeting: 'الإستهداف',
  postAdTargetingUpdated: 'تم التحديث',
  promotedPosts: 'المشاركات المروجة',
  noPromotedPosts: 'لا يوجد',
  allowComments: 'السماح بالتعليقات',
  hintAllowComments: '# السماح لأعضاء المجتمع بالتعليق في هذه المشاركة',
  postAdSuccess: 'تم إنشاء الإعلان , وسنقوم بتحويلك إلى شاشة الاستهداف حيث يمكنك تحديد المكانة التي تريد استهداف أعضاء المجتمع بها ، اترك الاستهداف فارغًا إذا كنت تريد استهداف جميع أفراد المجتمع.',
  userRab7Numbers: 'رقم Rab7 ',
  codFee: 'رسوم الدفع بعد الإستلام',
  hintCodFee: ' الرسوم التي سيتم اضافته حين اختيار الدفع بعد الإستلام ',
  codFeeApplicable: 'الرسوم التي سيتم اضافته حين اختيار الدفع بعد الإستلام',
  totalValuePayCod: 'المبلغ الإجمالي الذي يتعين دفعه في وقت التسليم',
  totalValuePayBankTransfer: 'المبلغ الإجمالي المراد تحويله في الحساب المصرفي',
  continueWithQuestionMark: 'متابعة ؟',
  minPurchaseAmountMessage: 'المبلغ بعد تطبيق الخصم ورصيد المحفظة في سلة التسوق',
  challenges: 'تحدّيات',
  challenge: 'تحدّي',
  fun: 'وناسة',
  noChallenges: 'لا توجد تحدّيات',
  noChallengePosts: 'لا توجد مشارات',
  takeOnChallenge: 'ادخل في التحدّي وضع مشاركتك',
  subHeaderAddPostInChallenge: '  شارك في التحدي \n{value}\n  اكتب عنوان لمشاركتك بالتحدّي',
  removeOffer: 'حذف العرض',
  removeOfferPromptMessage: 'هل أنت متأكد أنك تريد إزالة هذا العرض ليتم عرضه لك',
  removeActivity: 'حذف الاعلان',
  removeActivityPromptMessage: 'هل أنت متأكد أنك تريد إزالة هذا الاعلان ليتم عرضه لك',
  challengeShareMessage: 'شارك بالتحدّي "{title}" في #Rab7. {url}',
  challengePostShareMessage: 'الق نظرة على المشاركة #Rab7, "{title}" في تحدّي "{campaign}". {url}',
  challengeDetails: 'تفاصيل التحدّي',
  challengeDurationMessage: 'التحدّي متاح',
  challengeValidityMessage: 'سينتهي التحدي بعد',
  challengeExpired: 'انتهى التحدّي',
  challengeValidityFutureMessage: 'التحدّي سيبدأ',
  birthdayCampaignOnly: 'يوم الميلاد',
  processingVideo: '... جاري معالجة الفيديو ',
  pickVideo: 'إختر فيديو',
  audio: 'تسجيل صوت',
  video: 'فيديو',
  hintVideoDuration: '# أقصى حد لمقطع الفيديو هو  {value} ثانية',
  videoDurationLessThanAllowed: 'مقطع الفيديو أقصر من المطلوب , اقصر مدة مسموحة هي  {value} ثانية',
  videoDurationMoreThanAllowed: 'مقطع الفيديو أطول من المطلوب , اقصى مدة مسموحة هي {value} ثانية',
  videoSizeMoreThanAllowed: 'حجم الفيديو أكبر من المطلوب , اقصى حجم مسموح  هو {value} ميجابايت',
  cameraVideoPermissionDenied: 'السماح بالوصول للكاميرا مرفوض',
  permissionDeniedCameraVideoMessage: '\n لقد رفضت الإذن بالوصول إلى الكاميرا. \n\n نحتاج إلى الوصول إلى الكاميرا لتسجيل الفيديو وتحميله في النشر. \n\n امنح إذن الكاميرا من إعدادات الجهاز لتسجيل الفيديو ونشره. فتح إعدادات الجهاز؟',
  permissionDeniedCameraVideo: 'لقد رفضت الإذن بالوصول إلى كاميرا الجهاز',
  microphoneVideoPermissionDenied: 'تم رفض إذن الميكروفون',
  permissionDeniedMicrophoneVideoMessage: '\n لقد رفضت الإذن بالوصول إلى الميكروفون. \n\n نحتاج إلى الوصول إلى الميكروفون لتسجيل الصوت مع الفيديو وتحميله في النشر. \n\n امنح إذن الميكروفون من إعدادات الجهاز لتسجيل الصوت ونشره. فتح إعدادات الجهاز؟',
  permissionDeniedMicrophoneVideo: 'لقد رفضت إذن الوصول إلى الميكروفون',
  permissionDeniedStorageVideoMessage: '\n لقد رفضت الإذن بالوصول إلى مساحة تخزين الجهاز. \n\n نحتاج إلى الوصول إلى مساحة تخزين الجهاز لتسجيل الفيديو باستخدام الكاميرا وحفظها على مساحة تخزين الجهاز وتحميلها في النشر. \ n \ n امنح إذن التخزين من إعدادات الجهاز لتسجيل الفيديو ونشره. هو - هي. فتح إعدادات الجهاز؟',
  storageVideoPermissionDenied: 'تم رفض إذن التخزين',
  permissionDeniedStorageVideo: 'لقد رفضت الإذن بالوصول إلى تخزين الجهاز',
  videoPermissionDenied: 'تم رفض إذن الفيديو',
  permissionDeniedVideoMessage: '\n لقد رفضت الإذن بالوصول إلى مقاطع الفيديو. \n\n نحتاج إلى الوصول إلى مقاطع الفيديو لتحديد مقاطع الفيديو من المعرض وتحميلها في النشر. \n\n امنح إذنًا بالصور من إعدادات الجهاز لتحديد الفيديو ونشره. فتح إعدادات الجهاز؟',
  permissionDeniedGalleryVideoMessage: '\n لقد رفضت الإذن بالوصول إلى مساحة تخزين الجهاز. \n\n نحتاج إلى الوصول إلى مساحة تخزين الجهاز لتحديد مقاطع الفيديو من تخزين الجهاز وتحميلها في النشر. \n\n امنح إذن التخزين من إعدادات الجهاز لتحديد الفيديو ونشره. فتح إعدادات الجهاز؟',
  permissionDeniedGalleyImageMessage: '\n لقد رفضت الإذن بالوصول إلى مساحة تخزين الجهاز. \n\n نحتاج إلى الوصول إلى مساحة تخزين الجهاز لتحديد الصور من تخزين الجهاز وتحميلها في النشر. \n\n امنح إذن التخزين من إعدادات الجهاز لتحديد الصورة ونشرها. فتح إعدادات الجهاز؟',
  permissionDeniedVideo: 'لقد رفضت الإذن بالوصول إلى مقاطع الفيديو',
  discardVideo: 'إستبعاد الفيديو',
  discardVideoPromptMessage: 'متأكد ؟',
  cameraMountError: 'غير قادر على تشغيل الكاميرا',
  videoRecordInvalid: 'تعذر تسجيل الفيديو بشكل صحيح ، يرجى المحاولة مرة أخرى',
  videoRecordingInProgress: 'تسجيل الفيديو قيد التقدم ، لا يمكن الخروج',
  uploadingPostMediaMessage: 'جالسين نحمل الوسائط , وعلشان مانخليكم تنتظرون بنحطها بالخلفية ونوديكم للشاشة الرئيسية وتابعوا تقدم التحميل منها  .',
  postMediaUploadingWithValue: 'تحميل الوسائط  #{value}',
  postMediaUploadingMessage: 'جالسين نحمل الوسائط',
  postMediaUploadedWithValue: 'تم تحميلها #{value}',
  postMediaUploadedMessage: 'نبشركم تم تحميلها بنجاح.',
  postMediaUploadError: 'للاسف ما تم تحميل الوسائط بشكل جيد , يمكن الانترنت فيها شي !',
  postMediaUploadErrorWithValue: 'الوسائط #{value} لم تحمل',
  postMediaUploadErrorMessage: 'حدث خطأ أثناء تحميل الفيديو ، يرجى تحميله مرة أخرى لنشر المحتوى.',
  postMediaUploadCancelWithValue: 'الوسائط #{value} الغيت',
  postMediaUploadCancelMessage: 'تم إلغاء تحميل الوسائط ، يرجى تحميلها مرة أخرى لنشر المحتوى.',
  postMediaUploadErrorMessageWithValue: 'حدث خطأ أثناء تحميل الفيديو ، يرجى تحميله مرة أخرى لنشر المحتوى .\n {value}',
  postMediaUploadLimitReached: 'تم الوصول إلى حد تحميل الوسائط {value}',
  uploadingInProgress: 'جاري التحميل...',
  uploadStateError: 'لم يتم التحميل',
  uploadStateInProgressWithValue: 'التقدم في التحميل ({value}%)',
  uploadStateComplete: 'تم التحميل',
  retryUploadMedia: 'جرب تحمل مرة ثانية',
  retryUploadMediaMessage: 'فشل تحميل الوسائط بسبب: \n {value}\n\n أعد محاولة تحميل الوسائط أو إزالتها من قائمة التحميل',
  walletBalanceWithValue: 'رصيدك بالمحفظة {value}',
  expiredS: 'منتهية',
  walletBalanceExpired: 'رصيد المحفظة منهي',
  walletBalanceExpiringIn: 'رصيد المحفظة بينتهي خلال {value}',
  expiringIn: 'ينتهي خلال {value}',
  hintExtendExpiring: '# تنتهي صلاحية نقاط الولاء في وقت انتهاء الصلاحية المعروض لكل متجر على حدة، يرجى استخدامها أو إجراء عملية شراء جديدة خلال وقت انتهاء الصلاحية لتمديدها بمقدار {value} يوم، بحد اقصى {value1} يوم',
  expiryMonthShortWithSpace: {
    'one': '{count} شهر ',
    'other': '{count} اشهر '
  },
  expiryDayShortWithSpace: {
    'one': '{count} يوم ',
    'other': '{count} يوم '
  },
  expiryHourShortWithSpace: {
    'one': '{count} ساعة ',
    'other': '{count} ساعة '
  },
  expiryMinuteShortWithSpace: {
    'one': '{count} دقيقة ',
    'other': '{count} دقيقة '
  },
  purchaseReviewed: 'تم التقييم',
  guess: 'توقّع',
  guessShareMessage: 'اظهر موهبتك وتوقّع النتيجة في  "{title}" on #Rab7. {url}',
  guessSubmitted: 'تم ارسال التوقع',
  yourGuess: 'توقّعك',
  payOnDelivery: 'الدفع عند الإستلام',
  payAtStore: 'الدفع عند المتجر',
  payViaCard: 'الدفع بالبطاقة',
  payOnDeliveryTotalValue: 'القيمة الإجمالية',
  payAtStoreTotalValue: 'القيمة الإجمالية',
  payViaTotalValue: 'القيمة الإجمالية',
  balanceTransferTotalValue: 'يجب تحويل المبلغ الإجمالي إلى البنك',
  selectCard: 'إختر البطاقة',
  orderPaymentPODDeliveryMessage: 'دفع عند استلام الطلب.',
  orderPaymentPODMessage: 'دفع في المتجر.',
  orderPlaceCancellationMessage: '* الطلب بمجرد تأكيده من قبل المتجر لا يمكن إلغاؤه ، وفي حال عدم استلامه لن يتم إرجاع العرض المستهلك ورصيد المحفظة لن يتم استرداده ، لذا احرص على استلام الطلب .',
  discounts: 'خصومات',
  noDiscounts: 'لا توجد خصومات الآن',
  moreItems: 'منتجات أكثر',
  fromGivenValuesOnly: 'من القيم المحددة فقط',
  orderPaymentBankTransferMessage: 'تدفع عن طريق التحويل المصرفي.  قم بتحويل المبلغ بمجرد تأكيد الطلب من قبل المتجر.',
  bankTransferDetailsUpdated: 'تم التحديث',
  commercialRecordDocument: 'وثيقة السجل التجاري',
  commercialRecordNumber: 'رقم السجل التجاري',
  maroofLink: 'رابط التعريف في موقع معروف',
  notes: 'ملاحظة',
  selectDistricts: 'أختر الأحياء',
  becomeDriver: 'التقديم على مندوب توصيل',
  becomeDriverInfo: 'المعلومات',
  subHeaderBecomeDriver: 'قم بتحديث المعلومات المطلوبة وسيتم مشاركتها مع المتاجر المسجلة في مجتمع Rab7 للأعمال.',
  becomeDriverInfoUpdated: 'تم التحديث',
  becomeDriverInfoUpdatedMessage: 'لقد قمت بتحديث المعلومات ، وسيتم مشاركتها مع المتاجر المسجلة في مجتمع Rab7 للأعمال.',
  addInformation: 'أضف المعلومات',
  becomeDriverMessageTitle: 'المعلومات المطلوبة',
  becomeDriverMessageSubTitle: 'يجب ملء بعض المعلومات لكي تصبح مندوبا مرشحا و ، وسيتم مشاركتها مع المتاجر المسجلة في مجتمع Rab7 للأعمال.',
  becomeDriverInformationDeleted: 'تم الحذف',
  deleteBecomeDriverInformation: 'حذف المعلومات',
  areYouSureContinue: 'متأكد ؟',
  businessRegistrationDocument: 'وثيقة تسجيل المتجر',
  locationNotAvailable: 'الموقع غير متوفر',
  locationNotAvailableMessage: 'غير قادر على جلب موقعك الحالي ، يرجى السماح لنا بجلب موقعك الحالي لرؤية المتاجر ذات الصلة من حولك',
  locationNotAvailableInCartMessage: 'غير قادر على جلب موقعك الحالي ، يرجى السماح لنا بجلب موقعك الحالي لمعالجة الطلب',
  permissionDeniedLocationInCartMessage: 'لقد رفضت الإذن بالوصول إلى موقع الجهاز. \n\n نحتاج إلى الوصول إلى موقع الجهاز لمعالجة الطلب. \n\ منح إذن الموقع من إعدادات الجهاز.  فتح إعدادات الجهاز؟',
  mockedLocationDiscardInCartMessage: 'موقع السائق غير متاح .  يرجى تعطيل أي موفر موقع وهمي والمحاولة مرة أخرى باستخدام موقع الجهاز الصحيح.',
  permissionDeniedLocationMessage: '\n لقد رفضت الإذن بالوصول إلى موقع الجهاز. \n\n\n\n لمعالجة الطلب وتجربة المتاجر والعروض القريبة ، يجب أن تحتفظ بالموقع متاحًا باتباع الخطوات التالية: \n\n • افتح إعدادات الجهاز ، وحدد الأذونات  \n\n • حدد الموقع ، وحدد السماح أثناء استخدام التطبيق \n\n • تشغيل خدمة الموقع للجهاز \n\n\n\n هل تريد فتح إعدادات الجهاز؟',
  permissionDeniedLocationIosMessage: '\n لقد رفضت الإذن بالوصول إلى موقع الجهاز. \n\n\n لمعالجة الطلب وتجربة المتاجر والعروض القريبة ، يجب أن تحتفظ بالموقع متاحًا باتباع الخطوات التالية: \n\n• افتح إعدادات الجهاز ، حدد الموقع \n\n • التحديد أثناء استخدام التطبيق \n\n • تشغيل خدمة الموقع للجهاز \n\n\n\n هل تريد فتح إعدادات الجهاز؟',
  shopPermit: 'رخصة المحل',
  shopPermitDocument: 'مستند رخصة المحل',
  shopPermitDocumentNumber: 'رقم رخصة المحل',
  spin: 'فرّها',
  spinsLeftCounter: '{value1}/{value2} عدد اللفات الباقية',
  youWonJackpot: 'حركااات ، مبروك الفوز',
  betterLuckNextTime: 'خيرها بغيرها ، ابشر بالخير واجد المسابقات الثانية',
  addCommentWithDots: 'أضف تعليق ...',
  discardChanges: 'تراجع ؟',
  getSlotForJackpot: 'اذا جبت كل الصف صور متشابهة تفوز بالمكافئة',
  youWonJackpotPointsAndOffer: 'مبروووك {value1} نقاط والعرض',
  viewOffer: 'شاهد العرض',
  spinCountComplete: 'معليش خلصت محاولاتك, لا يضيق صدرك الجايات كثير ومتأكدين انك بتفوز فيها',
  jackpotValidityFutureMessage: 'التحدي راح يبدأ بعد \n {value1}',
  confirmAnswer: 'تأكيد الإجابة ؟',
  titleIReviews: 'تغطيات',
  noIReviews: 'لا يوجد تغطيات',
  addIReview: 'اضف تغطية',
  editIReview: 'تعديل تغطية',
  subHeaderAddIReview: 'شارك تجربتك مع المتجر',
  selectBusiness: 'اختر اسم المتجر',
  actionUrl: 'عنوان الرابط',
  actionUrlText: 'عنوان الرابط',
  likeWithValue: '{value} اعجاب',
  likesWithValue: '{value} اعجاب',
  commentWithValue: '{value} تعليق',
  commentsWithValue: '{value} تعليق',
  country: 'الدولة',
  countryWithColon: 'الدولة:',
  totalAmountWillBeDeductedFromCard: 'اجمالي المبلغ الذي سيتم خصمه هو ',
  rechargeAmountWithValue: 'تم شحن المحفظة بـ ',
  amountDeductedInSAR: 'سيتم احتساب الدفع بالريال السعودي وفقًا لسعر صرف العملة',
  amountDeductedInSARAndChargeableAmount: 'سيتم احتساب الدفع بالريال السعودي وفقًا لسعر صرف العملة ، وسيكون المبلغ الخاضع للتحصيل ريال سعودي',
  influencerLinkClickedSuccessful: 'شكرا لتفاعلك',
  spendsPerPoint: 'الصرف لكل نقطة',
  spendsPerPointHint: 'المبلغ المصروف لاكتساب نقطة واحدة',
  maximumEarnHint: 'الحد الأعلى للإكتساب اليومي',
  totalMoney: 'مجموع النقاط',
  hintWalletPointsUsableInOtherBusinesses: '# رصيد المحفظة يمكن ان يستهلك في متاجر اخرى',
  playLeftCounter: '{value1} محاولة متبقية',
  playsLeftCounter: '{value1} محاولة متبقية',
  bombFlippedGameOver: 'قلبت الصورة الخطأ ، معليش انتهت التحدي',
  congratulationsYouWon: 'مبرووووك الفوز',
  youWon: 'مبرووك الفوز',
  playCountComplete: 'لقد لعبت كل أدوارك ، حظ أفضل في المرة القادمة',
  remainingOffersMessage: 'عدد العروض المتاحة لجميع العملاء',
  remainingOffersTodayMessage: 'عدد العروض المتاحة لجميع العملاء لهذا اليوم فقط',
  availabilityAllToday: 'متاح طول اليوم',
  noPlaces: 'لا يوجد اماكن متوفرة',
  places: 'أماكن',
  place: 'مكان',
  sharePlaceMessage: 'الق نظرة على المكان, {title}. {url}',
  socialMediaAccounts: 'حسابات التواصل الإجتماعي',
  event: 'الإيفينت',
  events: 'الإيفينتات',
  noEvents: 'لا يوجد الإيفينت',
  eventShareMessage: 'لا تفوتك الإيفينت  "{title}" on #Rab7. {url}',
  commentWithCountInBracket: 'التعليقات ({value})',
  youHaveParticipated: 'تم مشاركتك',
  readMore: 'أطلع على المزيد',
  showLess: 'أقرأ اقل',
  description: 'التفاصيل',
  organiser: 'منظم الإيفينت',
  startingInWithValue: 'تبدأ في  {value}',
  ongoing: 'جارية',
  completed: 'أكتملت',
  participatingEvent: 'تبي تشارك ؟',
  participatingEventMessage: 'راح نشارك اسم المستخدم وجوالك للمنظم علشان ينسق معك , بعد الموافقة على المشاركة سيتعذر الإلغاء , تشارك الان ؟',
  earnedPointsWithValue: 'لقد حصلت على  {value1} نقطة',
  earnedOffer: 'حصلت على عرض ',
  earnedPointsAndOffer: 'حصلت على  {value1} نقاط وعرض',
  youHaveParticipatedViewOffer: 'تم مشاركتك , شف العرض اللي حصلت عليه',
  completelyReserved: 'تم اكتمال الحجز',
  commentedInEvent: 'علق على الإيفينت',
  repliedInEvent: 'رد على الإيفينت',
  userFollowers: 'تحدي اللايكات',
  doFastTimeLeftWithValue: 'بسررعه, {value} متبقي',
  likedSuccess: 'تم الاعجاب',
  like: 'إعجاب',
  campaignOver: 'خلصت المسابقة',
  wonTheLikeUserCampaign: 'فزت بمسابقة تحدي اللايكات',
  wonTheCampaign: 'مبروووك فزت',
  collectStars: 'جمع النجووم',
  backAgainToCollectMore: 'ترى ماخلصنا , لانه بعد {value} تقدر تجمع زيادة.',
  becomeDriverMessage: 'لكي تصبح سائقًا ، عليك النقر فوق الزر أدناه وتحديد المتاجر التي يمكنك من خلالها تقديم خدمة التوصيل لديها كسائق , . \n سيتم الاتصال بك من قبل العميل بعد موافقة المتجر قبولك كسائق لديه ',
  becomeDriverConfirmation: 'تأكيد الانضمام',
  deliverPackages: 'توصيل الطلب',
  disableDelivery: 'تعطيل التوصيل',
  disableDeliveryMessage: 'سيؤدي تعطيل التسليم إلى إيقاف عرضك في تطبيق العميل ، ولن يتمكن العميل من الاتصال بك  .\n\n موافق?',
  deliveryDisabled: 'تم التعطيل',
  enableDelivery: 'تمكين التوصيل',
  deliveryEnabled: 'تم التمكين',
  enableDeliveryMessage: 'سيظهر لك تمكين التسليم في تطبيق العميل ، وسيتصل بك العميل لتسليم الطلباتن الخاصة به.\n\n  موافق?',
  liteDeliveryEnabledSelectStoresMessage: 'تم تمكين التسليم ، حدد المتاجر التي يمكنك من خلالها توفير التوصيل.',
  liteDeliveryEnabledMessage: 'تم تمكين التسليم ، وسيتم الاتصال بك من قبل العملاء للتسليم من المتاجر المعتمدة.',
  liteDeliveryDisabledMessage: 'التوصيل معطل , مكن التوصيل ليظهر رقمك للعملاء',
  selectStores: 'اختر المتاجر',
  selectMoreStores: 'أختر المتاجر',
  manageStores: 'عرض وادارة المتاجر',
  storesSelectedHintMessage: 'اخترت  {value} متاجر لكي تكون سائق توصيل لديها .',
  storesNotSelectedHintMessage: 'لم تقم بتحديد أي متاجر. حدد المتاجر التي يمكنك من خلالها تقديم خدمة التوصيل.',
  noStoresAvailable: 'لا توجد متاجر',
  hintSelectCityAndSelectBranches: '# حدد المدينة ثم من القائمة أدناه حدد المتاجر التي يمكنك من خلالها توفير التوصيل',
  selectedStoreWithValue: '{value} متاجر مختارة',
  selectedStoresWithValue: '{value} متاجر مختارة',
  selectStoresForDelivery: 'اختر متاجر للانضمام لها كتوصيل',
  selectStoresForDeliveryMessage: 'سيتم إرسال طلبك إلى أصحاب المتاجر للموافقة عليه لتكون كابتن توصيل طلبات لديهم ، وبمجرد موافقتهم ، سيتم عرضك على العملاء أثناء تقديم الطلبات وسيتصلون بك للتسليم.\n\n  موافق?',
  approvalPendingML: 'موافقة \n انتظار',
  approvalRejectedML: 'موافقة\n رفض',
  disableDeliveryInStoreQ: 'عطل التوصيل?',
  enableDeliveryInStoreQ: 'مكن التوصيل?',
  deleteDeliveryInStoreQ: 'حذف الطلب?',
  deliveryByLiteDriversMessage: 'يتم توفير توصيل الطلب من قبل السائقين المتعاقدين مع المتاجر ، يجب الاتصال بهم والتعامل معهم للحصول على الطلب لتسليمه اليك .',
  viewAvailableDrivers: 'عرض السائقين المتوفرين',
  availableDrivers: 'السائقون المتوفرون',
  informationRegardingDelivery: 'معلومات عن التوصيل',
  continueToPayment: 'المتابعة للدفع',
  noAvailableDrivers: 'لا يوجد سائقون الان',
  shareOrderInformation: 'شارك معلومات الطلب',
  deliveryByLiteDriversMessage2: 'تأكد من أنه يتعين عليك الاتصال والتعامل مع السائقين المتعاقدين مع المتجر لتوصيل الطلب لك , يمكنك التعامل معهم قبل أو بعد تقديم الطلب.',
  shareOrderInformationMessage: 'هل أنت متأكد أنك تريد مشاركة معلومات الطلب مع السائق?',
  orderInformationShared: 'تم المشاركة',
  orderShareInfoWithOrderNo: 'معلومات الطلب رقم #{value}',
  deliveryByLiteDriversMessage3: 'تواصل وتعامل مع السائق المتعاقد مع المتجر لتصلك الطلبية.',
  requestForDeliveryInStoresStateApproved: 'وافق على طلبك لتقديم خدمة التوصيل في متاجرهم',
  requestForDeliveryInStoresStateRejected: 'رفض طلبك لتقديم خدمة التوصيل في متاجرهم',
  deliveryOnly: 'توصيل فقط',
  pickupOnly: 'استلام فقط',
  cancelSavingCard: 'إلغاء حفظ البطاقة',
  cancelSavingCardMessage: 'رسالة الغاء حفظ البطاقة',
  postsInChallengeWithValue: 'مشاركة ({value})',
  campaignWillStartIn: 'البداية في',
  campaignIsEnded: 'أنتهى التحدي',
  playNWin: 'استمتع الآن',
  youWonJackpotPoints: 'ربحت {value} نقاط من المتجر',
  youWonJackpotOffer: 'ربحت عرض',
  youWonJackpotOffers: 'ربحت عرض',
  youWonEJackpotOffer: 'و ربحت عرض',
  youWonEJackpotOffers: 'و ربحت عرض',
  youWonJackpotGame: 'تأهلت لتحدي التالية',
  youWonJackpotGames: 'تأهلت لتحدي التالية',
  youWonEJackpotGame: 'و تأهلت لتحدي التالية',
  youWonEJackpotGames: 'و تأهلت لتحدي التالية',
  playAgain: 'حاول ثاني مرة',
  earnedStorePointsWithValue: 'كسبت {value} نقطة من المتجر',
  earnedRab7PointsWithValue: 'كسبت {value} نقطة من Rab7 ',
  earnedERab7PointsWithValue: 'و كسبت {value} نقطة من Rab7 ',
  earnedOffer1: 'ربحت عرض',
  earnedOffers: 'ربحت عرض',
  earnedEOffer1: 'و ربحت عرض',
  earnedEOffers: 'و ربحت عرض',
  earnedGame: 'تأهلت لتحدي',
  earnedGames: 'تأهلت لتحدي',
  earnedEGame: 'و تأهلت لتحدي',
  earnedEGames: 'و تأهلت لتحدي',
  earnedPointsTitle: 'مبرووووك!',
  gameEarnMessage: 'معلييش المرة ذي ما ضبطت ، بس المرة الجاية يمكن يحالفك الحظ ، استمررر  ',
  gameWinLimitComplete: 'علشان الجوائز خلصت وفازوا فيها اللي بدوا التحدي بدري فنتأسف نقولك التحدي انتهت .',
  gameRecurAgain: ' سيبدأ التحدي بعد :   \n {value} ',
  fewMinutes: 'خلال دقائق',
  gameShareMessage: 'العب واربح التحدي  "{title}" في Rab7 #Rab7 . {url}',
  guessImageMessage: 'خمن الصورة وأدخل الإجابة في الحقل أدناه ، خمنها بشكل صحيح لتكون مؤهلاً للفوز بالتحدي',
  enterGuessHere: 'أدخل الإجابه هنا',
  guessRecorded: 'تم اضافة الاجابة',
  guessNotMatched: 'تخمينك غير مطابق للقيم المحددة الممكنة',
  getSlotForRoulette: 'حدد تركيبة الصور الخاصة بك بلمس الصور والتقليب بين الصور',
  getSlotForRoulette1: 'قم بالتدوير الان لتحصل على التركيبة المطابقة',
  deleteAccount: 'حذف الحساب',
  deleteAccountMessage: 'سيؤدي حذف حسابك إلى إزالة حسابك وملفك الشخصي ومنشوراتك ونقاط ولاءك في Rab7. لا يمكن استرداد حسابك مرة أخرى بعد الحذف. \n \n في الخطوة التالية يتم إرسال رمز التحقق إلى رقم هاتفك المحمول المسجل ، تحتاج إلى إدخال الرمز لمعالجة حذف حسابك.\n\n متابعة ؟',
  proceed: 'متابعة',
  accountDeleted: 'لقد تم حذف حسابك',
  enterVerificationCode: 'أدخل رمز التحقق',
  enterVerificationCodeMessage1: 'أدخل رمز التحقق المرسل إلى رقم هاتفك المحمول المسجل لمتابعة حذف الحساب',
  activitySocialContentShareMessage: 'انشر محتوى يلبي احتياجات المتجر وفز بجوائز قيمة  "{title}" في #Rab7. {url}',
  ended: 'أنتهت',
  contentWriting: 'كتابة المحتوى',
  postContent: 'أضف مشاركة',
  subHeaderAddContent: '{value}\n أضف نصًا أو وسائط تريد نشرها',
  youHavePosted: 'اضفت  {value} مشاركة',
  youHavePostedS: 'أضفت  {value} مشاركة',
  socialContentPostShareMessage: 'لا يفوتك هذي المشاركة على  #Rab7, "{title}" لـ "{campaign}". {url}',
  yourGuessIncorrect: 'لم يكن تخمينك صحيحًا',
  allPostsWithValue: 'كل المشاركات ({value})',
  myPostsWithValue: 'مشاركاتي ({value})',
  dataNotLatest: 'البيانات المعروضة ليست الأحدث ، محدثة إلى الأحدث.',
  viewWinnerPost: 'عرض المشاركات الفائزة',
  viewPost: 'عرض المشاركة',
  guessImageShareMessage: 'حل هذا اللغز  "{title}" على #Rab7. {url}',
  activityShareNLikeShareMessage: 'شارك واحصل على أكثر عدد من الاعجابات واربح الجوائز  "{title}" على #Rab7. {url}',
  activityQuestionShareMessage: 'شاركنا اهتمامك  "{title}" على #Rab7. {url}',
  activityQuestionnaireShareMessage: 'اختبر قدراتك واربح في  "{title}" على #Rab7. {url}',
  activityOpinionShareMessage: 'شاركنا رأيك  "{title}" على #Rab7. {url}',
  activityReviewsWithValue: {
    'one': '{count} تقييم',
    'other': '{count} تقييم'
  },
  consumableInAllBranches: 'صالح في كل الفروع',
  consumableInSelectedBranches: 'صالح في فروع مختارة',
  viewBranches: 'عرض الفروع',
  view: 'عرض',
  enterYourFeedback: 'إدخل ملاحظاتك',
  participateRewardsMessage: 'هلا وغلا , دامك اشتركت بالايفينت فراح نعطيك التالي ',
  participateOfferRewardsMessage: 'لقد شاركت وتمكنت من الوصول إلى المكافئة. \n \n كما تمت مكافأتك بالمزايا التالية للمشاركة.',
  participateOfferMessage: 'لقد شاركت وتمكنت من الوصول إلى المكافئة',
  genericEarnRewardsMessage: 'لقد تمت مكافأتك بالمزايا التالية.',
  moreRab7PointsNeeded: 'مطلوب المزيد من نقاط Rab7',
  moreStorePointsNeeded: 'مطلوب المزيد من نقاط المتجر',
  pointsConditionPassed: 'تم اجتياز شرط النقاط',
  pointsConditionsNRewards: 'الشروط والمزايا',
  rab7PointsRequiredToAction: 'مطلوب نقاط Rab7 للاستمرار',
  rab7PointsChargedOnAction: 'نقاط Rab7 التي سيتم خصمها',
  rab7PointsDeductedOnAction: 'نقاط Rab7  التي سيتم اكتسابها',
  storePointsRequiredToAction: 'نقاط المتجر المطلوب توفرها',
  storePointsChargedOnAction: 'نقاط المتجر التي سيتم خصمها',
  storePointsDeductedOnAction: 'نقاط المتجر التي سيتم اكتسابها',
  offerRewardedOnAction: 'العرض الذي سيكتسبه الفائز',
  rab7PointsBalance: 'رصيد محفظة Rab7 الكلي',
  storePointsBalance: 'رصيد محفظة المتجر',
  totalOffersConsumed: 'استهلك من قبل العملاء',
  availableOffersToConsume: 'المتوفر لجميع العملاء',
  availableOffersTodayToConsume: 'المتوفر يوميا لجميع العملاء',
  availableOffersToMeToConsume: 'المتوفر يوميا لي',
  consumableDay: 'يتوفر في الايام :',
  consumableTime: 'يتوفر في الوقت :',
  collectMorePoints: 'جمّع مزيد من النقاط',
  rewards: 'المكافئة',
  stars: 'نجوم',
  image: 'الصورة',
  clickToShowJackpotRewards: 'اضغط هنا لتفاصيل المكافئات',
  noFunAvailable: 'اممم مافي شي متوفر الحين',
  allEarningsClaimed: 'تم التقييم لجميع مشترياتك',
  pendingEarnings: 'باقيلك نقاط تحتاج لتقييم',
  helpCollectStarsTitle: 'قيمة الصور',
  challengePhotoPlaceholder: 'صورة',
  sponsored: 'ــ إعلان ــ',
  pendingEarnClaimed: 'لقد اتممت كل التقييمات',
  pendingEarnLeft: 'أممم عندك تقييمات لزياراتك , اول ماتقيم بنرحل النقاط لرصيدك واتستمتع فيها',
  generateVoucherCode: 'إنشاء رمز القسيمة',
  generateVoucherCodePrompt: 'إنشاء رمز القسيمة ?',
  generateVoucherCodeAgainMessagePrompt: 'تأكد من حفظ ونسخ رمز القسيمة الحالي لاستخدامه لاحقًا ، سيؤدي إنشاء رمز قسيمة آخر إلى إخفاء رمز القسيمة الحالية  من العرض لتمكين إظهار رمز القسيمة الجديد   . \n \n هل تريد المتابعة ؟',
  generateNewVoucherCode: 'قم بإنشاء رمز قسيمة جديد',
  voucherCodeGenerated: 'تم إنشاء رمز القسيمة',
  code: 'الكود',
  pin: 'الرمز',
  generatedVoucherCode: 'قسيمتك',
  voucherEarnMessage: 'لقد حصلت على الامتيازات أدناه لإنشاء القسيمة.',
  voucherCodeCopied: 'تم نسخ رمز القسيمة',
  voucherPinCopied: 'تم نسخ رقم التعريف الشخصي للقسيمة',
  enterHere: 'ادخل هنا',
  clickToShowCollectStarPointsMessage: 'اقلب البطاقات التي تحتوي على الصور أدناه لجمع {value1}',
  clickToShowCollectStarPoints: 'أضغط هنا لعرض قيم الصور ',
  flipToCollectStars: 'اقلب الكروت {value} لجمع {value1}۔',
  activityCollectStarsShareMessage: 'أجمع اكثر  {value1} وفز في التحدي "{title}" في #Rab7. {url}',
  youAreInTopCollectors: 'مبروك أنت في مقدمة جامعي {value1}۔',
  yourStarsCollectionWithValue: 'جمعت {value1}: {value}',
  topStarsCollectors: 'أعلى {value1} المجمعين',
  starWithValue: '{value} {value1}',
  starsWithValue: '{value} {value1}',
  collectMaxStarsToWin: 'Collect maximum {value1} to win',
  starsCollected: '{value1} Collected',
  starsCollectedMessage: 'You have collected {value} {value1}.',
  starCollectedMessage: 'You have collected {value} {value1}.',
  cLikeWithValue: '{value} {value1}',
  cLikesWithValue: '{value} {value1}',
  shareNLikeActivityMessage: 'إفتح الرابط وساعدني في تجميع  {value1} علشان افوز في المسابقة, "{title}" في #Rab7. {url}',
  rewardToLikeOthers: 'كمكافئة على دعمي {value1}',
  shareNGetLike: 'شارك واربح {value1}',
  getMaxLikedToWin: 'شارك واطلب الدعم لتجميع {value1} علشان تفوز',
  likeUserMessage: 'مسموح لك تعطي {value1}  .\n\n متابعة?',
  likeUser: 'اعط {value1}',
  likeUserPrompt: 'أعط {value1} ?',
  likeUserCampaignMessage: 'اعط المستخدم {value1} وادعمه علشان يفوز بالتجميع والجايزة',
  topLikes: 'اعلى الجامعين',
  youAreInTopLiked: 'مبروك انت في قمة جامعي  {value1}  .',
  yourLikesWithValue: 'تجميعك من {value1}: {value}',
  youHaveLiked: 'أعط {value1} لـ',
  yourActionSubmitted: 'تم بنجاح',
  prediction: 'التوقعات',
  chooseGuess: 'إختر الاجابة الصحيحة',
  recordGuess: 'متأكد من الإجابة ؟',
  moreFunActivities: 'وناسة أكثر من هنا',
  clickToShowJackpotRewardsMessage: 'احصل على صور متطابقة من الصور أدناه للفوز بالمكافآت ',
  gameSpinDiscoverMessage: 'فر السبينر علشان تقدر تقلب البطاقات وتكشف الصورة المختفية ، واذا كشفتها كلها تتاهل للفوز بالمكافئة ',
  showGameSpinDiscoverReward: 'اضغط هنا علشان نعلمك كل صورة وقيمتها',
  showGameSpinDiscoverRewardMessage: 'صور البطاقات وقيمتها',
  photoPartsCount: 'البطاقات اللي تأثر في كشف الصورة الكبيرة',
  partsCount: 'عدد الصور اللي راح تنكشف',
  revealThePhoto: 'اكشف الصورة',
  revealedCounter: '{value1}/{value2} كروت مكشوفة ',
  helpGreedySpinSubTitle: 'الصور و {value1} والقيم المقابله لها ، احصل على جميع الصور المتطابقة في الصف المحدد واحصل على {value2} مرة',
  helpGreedySpinTitle: 'قيمة الصورة',
  values: 'القيم',
  showGameValues: 'اضغط هنا لمشاهدة {value} لكل صورة',
  winnersWillBeDeclaredAt: 'سيعلن عن الفائزين في\n{value}',
  notWinningMessage: 'لم يتسنَ لك الفوز هذه المرة، ولكن واثقون انك ستكون منافس قوي المرة القادمة',
  restartAgainOn: 'اعادة {value}',
  multiplierWithValue: '{value}x',
  gameGreedySpinMessage: 'فر وجمّع  {value} وكل صورة لها قيمة واذا جبت متشابهات بكل الصف تاخذ ضعف {value1} مرات',
  collectedValues: 'جمعت  {value}: {value1}',
  flipsLeftCounter: '{value1}/{value2}',
  gameOver: 'انتهت اللعبة',
  userEligibleToWin: 'مبروك دخلت السحب على الجايزة',
  resetCollectedValuesPrompt: 'سؤدي التحدي مرة اخرى الى اعادة تعيين النقاط اتي جمعتها في اخر محاولة لك.\n\n متابعة ؟',
  playAgainQ: 'حاول مجددا ؟',
  winningEligibleMessage: 'مبروووك , الحين انت داخل في مجموعة السحب على المكافئة',
  guide: 'الطريقة',
  spinsLeft: 'محاولة',
  moreDetails: 'تفاصيل اكثر',
  guideSlot: 'حاول تجيب صور متطابقة في الصف المحدد واربح الجايزة',
  rewardsViewTitle: 'جوائز اللي بيربحون',
  rewardsSlotExtra: 'جب مثل الصور هذي وتربح ',
  participatePointsConditions: 'تفاصيل النقاط',
  participateRewards: 'بس العب وتربح نقاط حتى لو مافزت',
  storePointsWithValue: '{value} نقاط المتجر',
  rab7PointsWithValue: '{value} نقاط Rab7 ',
  andRab7PointsWithValue: '& {value} نقاط Rab7 ',
  offers1: 'العروض',
  andOffers1: '& عروض',
  andOffer1: '& عرض',
  funActivities1: 'مسابقات',
  funActivity1: 'مسابقة',
  andFunActivities1: '& مساابقات',
  andFunActivity1: '& مسابقة',
  gameFindHidden: 'البطاقة المخفية',
  gameMatchPair: 'البطاقات المتطابقة',
  gameFlipCard: 'البطاقات الصحيحة',
  collectValue: 'جـمّـع {value}',
  collectToWin: 'ارسل وشاركها لشبكة معارفك وخلهم يدعمونك في تجميع {value} واللي بيجمع أكثر هو اللي حلال عليه الجايزة , شاركها اكثر علشان تجمع أكثر',
  collectMaxToWin: 'ارسل وشاركها لشبكة معارفك وخلهم يدعمونك في تجميع {value} واللي بيجمع أكثر هو اللي حلال عليه الجايزة , شاركها اكثر علشان تجمع أكثر',
  collectRequiredValueToWin: 'ارسل وشاركها لشبكة معارفك وخلهم يدعمونك في تجميع {value} واللي بيجمع أكثر هو اللي حلال عليه الجايزة , شاركها اكثر علشان تجمع أكثر',
  shareNGet: 'أضغط هنا للتجميع',
  yourCollectionValue: 'جـمّـعت لحد الان {value}',
  topCollectors: 'الترتيب',
  topCollectorsMulti: 'الترتيب',
  getLikeRequestValue: 'طلب لدعم تجميع {value} ...',
  giveOne: 'أمنح',
  likeToUserValue: 'لـ {value}',
  youGave: 'أنت منحت',
  youAlreadyGave: 'أنت منحت مسبقا أحد',
  great: 'ياسلام على الناس العزوة',
  greatJob: 'ياسلام على الناس العزوة',
  noSpinsLeft: 'معليش محاولاتك أنتهت',
  gameRouletteMessage: 'كوّن الصور ثم اضغط الـ Spin واذا جت الصور حسب ترتيبك راح تفوز بالجايزة',
  selectCombo: 'كوّن الصور',
  reportPost: 'ابلاغ',
  information: 'معلومات',
  thisIsWinningPost: 'المشاركة الفائزة',
  showingWinningPosts: 'مشاهدة المشاركات الفائزة',
  showingAllPosts: 'مشاهدة كل المشاركات',
  postingIn: 'المشاركة في',
  addVideo: 'فيديو',
  titleHere: 'وش رايك ؟',
  showWinningPosts: 'مشاهدة المشاركات الفائزة',
  showAllPosts: 'مشاهدة كل المشاركات',
  gameGuessMessage: 'خمّن الإجابة الصحيحة علشان تدخل السحب على المكافئة ',
  walletInfo: 'تفاصيل المحفظة',
  reviewing: 'تقييم',
  unableToPost: 'لا يمكن المشاركة الان حاولمرة اخرى او لاحقا',
  clickHere: 'اضغط هنا',
  gameCollectStar: 'جمع النجووم',
  swipeUp: 'ارفع الشاشة',
  youWonReferralPoints: ' مبرووك اللي شاركته التحدي فاز \n وانت حصلت على {value} نقاط ',
  referralWonEFunActivity: 'التحدي',
  referralWonFunActivity: ' مبرووك اللي شاركته التحدي فاز \n وانت فزت بهذا التحدي ',
  referralWonEOffer: 'العرض',
  referralWonOffer: ' مبرووك اللي شاركته التحدي فاز \n علشان كذا تستاهل انت هذي الجايزة ',
  referralRewardsWithValue: 'شارك التحدي وراح تفوز بجايزة لو اللي شاركته فاز بالتحدي',
  referralRewards1WithValue: 'شارك التحدي وراح تفوز بجايزة لو اللي شاركته فاز بالتحدي ',
  referralWonTheCampaign: 'مبرووك اللي شاركته فاز وانت علشان كذا تستحق جايزة ',
  noChallengesAvailable: 'مافي شي الحين ، بس قرييب بنحط اشيا جميلة ومثيرة ووسيعة صدر',
  trends: 'سواليف',
  myProfile: 'ملفي',
  valuePosts: '{value} مشاركة',
  valuePost: '{value} مشاركات',
  valueViews: '{value} مشاهدة',
  valueView: '{value} مشاهدات',
  ctaPlay: 'ابدأ التحدي',
  ctaView: 'شاهد الآن',
  ctaAnswer: 'شارك الآن',
  ctaReview: 'شارك الآن',
  ctaPredict: 'توقع الآن',
  ctaGuess: 'خمّنها الآن',
  ctaParticipate: 'شارك الآن',
  ctaConsume: 'استمتع الآن',
  ctaPlayML: 'إلعب<br>الآن',
  ctaViewML: 'شاهد<br>الآن',
  ctaAnswerML: 'شارك<br>الآن',
  ctaReviewML: 'شارك<br>الآن',
  ctaPredictML: 'توقع<br>الآن',
  ctaGuessML: 'خمّنها<br>الآن',
  ctaParticipateML: 'شارك<br>الآن',
  ctaConsumeML: 'استمتع<br>الآن',
  promptGenerateOfferCode: 'انشاء كود للعرض',
  promptGenerateOfferCodeMessage: 'تأكد من استهلاك العرض قبل نفاد الكمية',
  offerCodeGenerated: 'تم انشاء الكود',
  generatedOfferCode: 'رمز الـQR للعرض',
  shareGenerateOfferCodeMessage: 'يرجى إظهار رمز الاستجابة السريعة (QR) لعرض الاسترداد هذا إلى البائع في المتجر حتى يتمكن من مسحه ضوئيًا. تأكد من استخدام العرض قبل تاريخ انتهاء الصلاحية أو استنفاد الكمية المتاحة.',
  gameFindImageMessage: 'حاول تقلّب {value} وحاول تلاقي الصورة المختفية وتفوووز',
  gameFindImageMessage1: 'قلّب البطاقات وحاول تلاقي الصورة المختفية بينهم وتفووز',
  getImageFlippedForJackpot: 'لديك {value} محاولات تقلّب فيها البطاقات ، ولكن لا تقع بفخ الـX الأحمر.',
  multipleFunActivitiesHere: 'فيه اكثر من وناسة هنا',
  funActivityNotSupported: 'غير مدعومة',
  funActivityNotSupportedMessage: 'هلا بالغالي ، الظاهر لازم تحدث التطبيق علشان تطلعلك الوناسة هذي ، تراها ناتخذ ثواني.',
  updateNow: 'حدّثه الحين',
  gameTicTacToe: 'إكس أو',
  gameTicTacToeMessage: 'العب لعبة [ إكس أو ] ضد الروبوت ، فز بجولات اكثر من الروبوت وتتأهل للسحب على المكافئة.',
  yourTicTacToeBelowImage: 'الصورة الرمزية للعبة-',
  youLost: 'لم يحالفك الحظ، حظاً أوفر',
  gameDraw: 'تعادل، شد حيلك',
  roundInfo: 'الجولة {value1}/{value2}',
  you: 'أنت',
  bot: 'Bot',
  gameQuestionGuide: 'أجب على الاستبيان واربح.',
  gameQuestionnaireMessage: 'اجب على الاسئلة وكن مؤهلا المكافئة.',
  totalQuestions: 'عدد الأسئلة',
  answerToWin: 'جاوب للفوز',
  totalTime: 'الوقت',
  exitQuestionnaire: 'خروج',
  exitQuestionnaireMessage: 'الافضل تكمل الاسئلة الحين ، بس ترى عادي تقدر تكملها بعدين .\n\n خروج ؟',
  exit: 'خروج',
  questionValue: 'السؤال:  {value1} من {value2}',
  timeValue: 'الوقت:  {value1}',
  complete: 'اتمام',
  completeQuestionnaire: 'اتمام الاسئلة ؟',
  completeQuestionnaireMessage: 'أكتملت الاسئلة تاكد انك جاوبتها بشكل صحيح .\n\n أكمل ؟ ',
  proceedAnyway: 'لا , سأكمل',
  changeAnswer: 'سأغير الاجابة',
  correctAnswers: 'اجابتك الصحيحة ',
  correctAnswersValue: '{value1} من {value2} (%{value3})',
  startAgain: 'محاولة أخرى ؟',
  takeARest: 'استرخي',
  takeARestMessage: 'حنا هنا للوناسة ، وش رايك توسع صدرك هنا وبعدها ترجع تكمل الاسئلة ؟',
  continueToQuestions: 'كمّل الاسئلة',
  questionnaireTimeOver: 'انتهى الوقت',
  gameEnded: 'انتهت التحدي',
  gameEndedNScore: 'انتهت التحدي\ nحصّلت :  {value} ',
  gameGamePuzzle: 'قم بتقليب الصور حتى تكون الصورة كاملة كما هي معروضة بالاعلى في الوقت وعدد الحركات المحددة لتتاهل للجائزة',
  gameGamePuzzleTapMultiple: 'قم بتقليب الصور حتى تكون الصورة كاملة كما هي معروضة بالاعلى في الوقت وعدد الحركات المحددة لتتاهل للجائزة',
  guideGameMatchThree: 'عندك مجموعة صور ، حاول تجيب ثلاث صور جمب بعض راسي او افقي وتكسب نقاط اكثر ، وفاالك الفووز ',
  guidePartInTime: ' كمّل التحدي في الوقت المحدد.',
  guidePartInMoves: ' كمل التحدي بالعدد المحدد من الحركات',
  guidePartInMovesNTime: '   ',
  matchThreeGuidePartBelowGivenWeightage: 'وما يلي كل صورة وقيمة النقاط التي تحققها ',
  puzzleGuidePartCompleteGameToWin: '   ',
  brandDice: 'Brand',
  opponentWonMessage: 'المرّة ذي فازوا عليك ، حظ طيب المرة الجاية',
  guideGameBrandRace: 'اضغط السبينر واذا وقف على افتارك تتقدم خطوة , واذا وقف على الصورة العكسية تتراجع خطوة , فز بالسباق وتاهل للجائزة',
  viewYourAvatar: 'هذي الصورة اللي تسابق فيها {value} .',
  moveCount: 'عداد الحركات',
  avatar: 'اڤتارك',
  startGame: 'ابدأ',
  guideGameWhacAMole: 'أضغط بسرعة على الصورة الصحيحة لتكسب النقاط , لكن انتبه من الصور التي تسبب بخسارتك للنقاط ',
  identifyLocationShareMessage: 'حدد الموقع وتاهل لجايزة  "{title}" on #Rab7. {url}',
  guideIdentifyLocation: 'ماعليك. الا تحدد الموقع بالخريطة وتتاهل للجايزة اذا حددته صح',
  identifyLocationForThePlace: 'حدد الموقع',
  pickedLocationSubmitted: 'تم حفظ الموقع',
  correctLocation: 'الموقع الصحيح',
  yourPickedLocation: 'موقعك الذي تم تحديده',
  guidePokerMessage: 'وزّع الاوراق بينك وبين المنافس ثم اقلب اوراقك ، اذا كان مجموع اوراقك اكثر فزت بالجولة ، فز بجولات اكثر من المنافس وتتاهل للجايزة.',
  guidePokerCardsImages: 'كل بطاقة لها قيمة 1-5, باستثناء البطاقة الخاصة أدناه-',
  cardImage: 'صورة الكرت',
  cardValue: 'القيمة',
  hint1GamePoker: 'اضغط الزر للحصول على البطاقات',
  hint2GamePoker: 'اقلب البطاقة لكشفها',
  trainings: 'كويز',
  noTrainingAvailable: 'مافيه تدريب الحين متوفر',
  multipleItemsHere: 'أكثر من شيء هنا',
  multiplePlacesHere: 'أكثر من مكان هنا',
  multiplePostsHere: 'أكثر من مشاركة هنا',
  userActivityTopPoints: 'النشيطين',
  noUserActivityTopPoints: 'مافيه احد الان',
  userProfile: 'الملف الشخصي',
  addSocialAccounts: 'اضف حسابات التواصل الاجتماعي',
  editSocialAccounts: 'تعديل حسابات التواصل الاجتماعي',
  socialAccountsUpdated: 'تم التحديث',
  socialAccountsAdded: 'تمت الاضافة',
  youtubeUsername: 'يوزرك باليوتيوب',
  facebookUsername: 'يوزرك بالفيسبوك',
  instagramUsername: 'يوزرك بالانستاقرام',
  twitterUsername: 'يوزرك بتويتر',
  snapchatUsername: 'يوزرك  بالسناب',
  tiktokUsername: 'يوزرك بالتيك توك',
  userPosts: 'مشاركاتي',
  userJobPosts: 'وظائف',
  userTalentPosts: 'مهارات',
  userTrips: 'رحلاتي',
  userPackages: 'شحناتي',
  userChallengePosts: 'مشاركاتي بالريل',
  myChallengePosts: 'مشاركاتي بالريل',
  userIReviews: 'تقاريري',
  myIReviews: 'تقاريري',
  challengePosts: 'مشاركات الريل',
  hintSocialAccountUsername: 'علشان نخلي حسابات التواصل الاجتماعي حقتك مشهورة ونزيد متابعين لك , حط بالحقول فقط اسم يوزرك بدون رابط او بدون علامة الـ@ فقط اليوزر',
  showResults: 'اظهار النتيجة',
  answers: 'الإجابات',
  totalAnswersColon: 'عدد الإجابات : ',
  adVideo: 'شاهد الفيديو',
  playNowWinnerList: 'شارك الآن',
  endedRestartingAgain: 'خلصت ، ابدا مرة ثانية',
  noWinnersYet: 'محد فاز لحد الان ، فرصتك لا تفوتها',
  rewardsWon: 'الفايزين',
  noPlayersPlayed: 'لا احد لحد الان',
  players: 'نتايج',
  playersStats: 'نتايج',
  offer1: 'المكافئة',
  reelTitleLengthPrompt: 'اقل شي للمشاركة 15 حرف',
  enterRab7: '.. ادخل لعالم Rab7 ..',
  inSameTrendMessage: 'انت تتصفح نفس هذا الترند',
  games: 'ألعاب',
  noReels: 'لا يوجد مشاركات الان في هذا الترند',
  reels: 'سواليف',




  "generic.yes": "نعم",
  "generic.no": "لا",
  "generic.ok": "موافق",
  "generic.done": "تم",
  "generic.cancel": "إلغاء",
  "generic.click_here": "انقر هنا",
  "generic.add": "إضافة",
  "generic.save": "حفظ",
  "generic.send": "إرسال",
  "generic.update": "تحديث",
  "generic.later": "فيما بعد",
  "generic.edit": "تعديل",
  "generic.next": "التالي",
  "generic.log_in": "الدخول",
  "generic.submit": "إرسال",
  "generic.server_error_msg": "حدثت مشكلة، حاول مرة أخرى",
  "generic.server_not_reachable": "معليش في خطأ حصل بالاتصال , بس لايهمك حاول مرة ثاني وراح يضبط معك",
  "generic.required": "مطلوب",
  "generic.logout": "خروج",
  "generic.loading...": "تحميل ...",
  "generic.select...": "إختيار...",
  "generic.search": "بحث",
  "generic.means_mandatory": "أساسي",
  "generic.likes": "المعجبين",
  "generic.dislikes": "غير المعجبين",
  "generic.comments": "تعليقات",
  "generic.your_feedback": "الإجابات",
  "generic.your_comment": "التعليق",
  "generic.reply_to": "الرد على",
  "generic.replying_to": "الرد على",
  "generic.image_file": "ملف صورة",
  "generic.audio_file": "ملف صوت",
  "generic.branch": "فرع",
  "generic.select_all": "إختيار الكل",
  "generic.select_branch": "اختيار فرع",
  "generic.date": "التاريخ",
  "generic.start_date": "تاريخ البداية",
  "generic.end_date": "تاريخ النهاية",
  "generic.add_new": "إضافة جديد",
  "generic.active": "نشط",
  "generic.inactive": "غير نشط",
  "generic.approval_pending": "في انتظار الموافقة",
  "generic.rejected": "مرفوض",
  "generic.name": "العنوان (الإنجليزية)",
  "generic.name_ar": "العنوان (باللغة العربية)",
  "generic.title": "العنوان  (الإنجليزية)",
  "generic.title_ar": "العنوان (بالعربية)",
  "generic.description": "الوصف  (الإنجليزية)",
  "generic.description_ar": "الوصف (بالعربية)",
  "generic.photo": "الصورة  (الإنجليزية)",
  "generic.photo_ar": "الصورة (باللغة العربية)",
  "generic.audio": "الصوت  (الإنجليزية)",
  "generic.audio_ar": "الصوت (بالعربية)",
  "generic.tnc": "الشروط و الأحكام  (الإنجليزية)",
  "generic.tnc_ar": "الشروط و الأحكام (بالعربية)",
  "generic.help": "الارشادات",
  "generic.user": "المستخدم",
  "generic.activity": "النشاط",
  "generic.gift": "هدية",
  "generic.spent": "المبلغ المنفق",
  "generic.earned": "المبلغ المكتسب",
  "generic.redeemed": "المبلغ المسترد",

  "app.name": "رابح",
  "app.name1": "رابح",
  "app.name2": "أعمال",
  "app.not_found.404": "404",
  "app.not_found.text": "الصفحة غير موجودة",
  "app.not_found.go_to_home": "الذهاب إلى الصفحة الرئيسية",

  "confirm.are_you_sure": "هل أنت متأكد؟",

  "credential.password": "كلمة المرور",
  "credential.old_password": "كلمة المرور القديمة",
  "credential.new_password": "كلمة المرور الجديدة",
  "credential.confirm_password": "تأكيد كلمة المرور",

  "user.profile.business_name.label": "اسم الشركة بالسجل التجاري",
  "user.profile.record_number.label": "رقم السجل التجاري",
  "user.profile.record_image.label": "صورة السجل التجاري",
  "user.profile.owing_type.label": "نوعية الشركة",
  "user.profile.category.label": "الفئة",
  "user.profile.sub_category.label": "الفئة الثانوية",
  "user.profile.short_name.label": "الإسم المختصر للشركة  (الإنجليزية)",
  "user.profile.short_name_ar.label": "الإسم المختصر للشركة (باللغة العربية)",
  "user.profile.logo.label": "شعار الشركة",
  "user.profile.contact_person_name.label": "إسم مسئول التواصل",
  "user.profile.contact_person_email.label": "البريد الالكتروني لمسئول التواصل",
  "user.profile.contact_person_number.label": "رقم مسئول التواصل",
  "user.profile.city.label": "المدينة",
  "user.profile.district.label": "الحي",
  "user.profile.geo_location.label": "الموقع على الخريطة",
  "user.profile.latitude.label": "خط العرض",
  "user.profile.longitude.label": "خط الطول",
  "user.profile.email.label": "البريد الالكتروني",
  "user.profile.username.label": "اسم المستخدم",
  "user.profile.mobile_number.label": "رقم الجوال",
  "user.profile.success": "تم تحديث الملف الشخصي بنجاح",

  "login.signin": "الدخول",
  "login.signup": "لا تملك حسابًا؟<br><b>أنشئ حسابًا جديدًا هنا</b>",
  "login.email": "البريد الالكتروني",
  "login.password": "كلمة المرور",
  "login.forgotpassword": "نسيت كلمة المرور؟",
  "login.app_name": " Rab7 أعمال",
  "login.mfa.title": "الدخول",
  "login.validation.email.required": "البريد الالكتروني مطلوب",
  "login.validation.password.required": "كلمة المرور مطلوبة",

  "login.forgot_password.text": "نسيت كلمة المرور؟ ",
  "login.forgot_password.info": "يرجى كتابة بريدك الإلكتروني لاستعادة كلمة المرور الخاصة بك ، وسوف نرسل رمز التفعيل على بريدك الإلكتروني و هاتفك المسجلين ، تحتاج إلى إدخال الرمز وإعادة تعيين كلمة المرور على الشاشة التالية.",
  "login.forgot_password.reset_password": "إعادة إنشاء كلمة المرور",
  "login.forgot_password.resend_code": "إعادة ارسال رمز التفعيل",
  "login.forgot_password.resend_code.retry_in": "التفعيل بعد {value} ثانية",
  "login.forgot_password.reset_password.info": "أدخل كود التفعيل الذي تم إرساله على بريدك الالكتروني و هاتفك المسجل",
  "login.forgot_password.new_password.info": "من فضلك ضع كلمة مرور جديدة",
  "login.forgot_password.send_otp_success": "تم إرسال رمز التفعيل بنجاح!",
  "login.forgot_password.change_password_success": "تم تحديث كلمة المرور بنجاح!",

  "registration.go_through_all_step": "من فضلك أكمل جميع مراحل التسجيل للبداية.",
  "registration.step1": "1. الخطوة",
  "registration.step2": "2. الخطوة",
  "registration.step3": "3. الخطوة",
  "registration.step4": "4. الخطوة",
  "registration.step1.title": "الخطوة الأولى. المعلومات الأساسية:",
  "registration.step2.title": "الخطوة الثانية. المعلومات الإضافية:",
  "registration.step3.title": "الخطوة الثالثة. معلومات الموقع:",
  "registration.step4.title": "الخطوة الرابعة.معلومات النظام:",
  "registration.to_go_to_login_page": "الذهاب إلى صفحة الدخول!",
  "registration.i_agree": "أنا أوافق على",
  "registration.tnc": " الشروط والأحكام والخصوصية",
  "registration.mobile_verification.title": "التحقق من رقم الجوال",
  "registration.mobile_verification.resend_code": "إعادة ارسال رمز التفعيل",
  "registration.mobile_verification.resend_code.retry_in": "التفعيل بعد {value} ثانية",
  "registration.mobile_verification.info": "أدخل رمز التفعيل المرسل إلى هاتفك المسجل",

  "registration.validation.name.required": "مطلوب",
  "registration.validation.name.max_char": "{max} لا يجب أن يكون أكثر من  (الإنجليزية)",
  "registration.validation.name_ar.required": "مطلوب",
  "registration.validation.business_name.required": "مطلوب",
  "registration.validation.record_number.required": "مطلوب",
  "registration.validation.record_number.max_char": "{max} يجب أن يكون أكثر من ",
  "registration.validation.recordImageFile.required": "مطلوب",
  "registration.validation.imageFile.required": "مطلوب",
  "registration.validation.owing_type.required": "مطلوب",
  "registration.validation.owing_type.min_num": "{min} يجب أن يكون أكثر من ",
  "registration.validation.owing_type.max_num": "{max} يجب أن يكون أقل من ",
  "registration.validation.category_id.required": "مطلوب",
  "registration.validation.sub_category_id.required": "مطلوب",
  "registration.validation.contact_person_name.required": "مطلوب",
  "registration.validation.contact_person_name.max_char": "{max} لا يجب أن يكون أكثر من",
  "registration.validation.contact_person_number.required": "مطلوب",
  "registration.validation.contact_person_email.required": "مطلوب",
  "registration.validation.contact_person_email.max_char": "{max} لا يجب أن يكون أكثر من ",
  "registration.validation.locality_id.required": "مطلوب",
  "registration.validation.sub_locality_id.required": "مطلوب",
  "registration.validation.latitude.required": "مطلوب",
  "registration.validation.longitude.required": "مطلوب",
  "registration.validation.username.required": "مطلوب",
  "registration.validation.username.min_char": "لايقل عدد الحروف عن {min} حرف",
  "registration.validation.username.max_char": "{max} لا يجب أن يكون أكثر من ",
  "registration.validation.username.invalid_char": "يمكنك فقط استخدام الحروف الإنجليزية و الأرقام و _",
  "registration.validation.email.required": "مطلوب",
  "registration.validation.mobile_number.required": "مطلوب",
  "registration.validation.mobile_number.min_char": "يجب ألا يقل عدد الأرقام عن {min} رقم",
  "registration.validation.mobile_number.max_char": "يجب ألا يزيد عدد الأرقام عن {max} رقم",
  "registration.validation.password.required": "مطلوب",
  "registration.validation.password.min_char": "لا يقل عدد الحروف {min} حرف",
  "registration.validation.password.max_char": "{max} لا يجب أن يزيد عن ",
  "registration.validation.confirmPassword.required": "مطلوب",
  "registration.validation.confirmPassword.not_matched": "كلمة المرور غير متطابقة",

  "notification.messaged_you": "قام بإرسال رسالة إليك",
  "notification.replied_to_comment": "قام بالرد على تعليقك",
  "notification.system_message": "رسالة من النظام",

  "navigation.home": "الرئيسية",
  "navigation.profile": "الملف الشخصي",
  "navigation.profile.approval_pending": "في انتظار الموافقة",
  "navigation.insights": "تحليل البيانات",
  "navigation.campaigns": "الحملات",
  "navigation.sales": "المبيعات",
  "navigation.employees": "الموظفين",
  "navigation.management": "إدارة الحساب",
  "navigation.branches": "الفروع",
  "navigation.loyalty_formulas": "معادلات نقاط الولاء",
  "navigation.earn_point_formulas": "معادلات إكتساب النقاط",
  "navigation.redeem_point_formulas": "معادلات استرداد النقاط",
  "navigation.gifts": "هدايا",
  "navigation.chats": "محادثات",
  "navigation.reviews": "التعليقات",
  "navigation.activities": "حملات",
  "navigation.employee": "موظف",
  "navigation.customer": "عميل",
  "navigation.transactions": "عمليات",
  "navigation.user_balances": "أرصدة المستخدمين",
  "navigation.system_users": "مستخدمي النظام",

  "admin.loyalty_formula_name": "اسم المعادلة لنظام الولاء",
  "admin.priority": "الأولوية",
  "admin.priority.hint": "من 1: 10 حيث 1 الأقل أهمية و 10 أعلى أهمية و ذلك عند إضافة أكثر من معادلة",
  "admin.quantity": "الكمية",
  "admin.invite": "دعوة",
  "admin.deleted": "محذوف",
  "admin.invited": "مدعو",
  "admin.all": "الكل",
  "admin.none": "لا أحد",
  "admin.access": "دخول",
  "admin.address": "العنوان  (الإنجليزية)",
  "admin.availability": "التوافر",
  "admin.actions": "الإجراءات ",
  "admin.branch": "الفرع",
  "admin.role": "الوظيفة",
  "admin.photo": "الصورة الشخصية",
  "admin.rating": "تقييم",
  "admin.creator": "المستخدم",
  "admin.type": "النوع",
  "admin.recommended_aspect_ratio": "نسبة العرض إلى الارتفاع الموصى بها للصورة هي 16: 9",
  "admin.search_address": "البحث عن عنوان",
  "admin.table.no_data": "لا توجد بيانات متاحة",
  "admin.select_geo": "اختر الموقع الجغرافي",

  "admin.list.action.toggle_availability": "تعطيل",
  "admin.list.action.reply": "رد",
  "admin.list.action.edit": "تعديل",
  "admin.list.action.delete": "حذف",
  "admin.list.action.send_message": "إرسال رسالة",
  "admin.list.action.invite_again": "إعادة إرسال الدعوة",

  "admin.landing.welcome_to": "مرحبا بك في ",
  "admin.landing.business_portal": " بوابة الأعمال",
  "admin.landing.approval_pending": "لم تتم الموافقة على تسجيل حسابك بعد. و يمكنك تعديل بياناتك حتى تتم الموافقة.",
  "admin.landing.you_have_access_to": "يمكنك الدخول إلى:",

  "admin.profile.title": "الملف الخاص بالشركة",
  "admin.profile.edit_profile": "تعديل الملف",
  "admin.profile.change_password": "تغيير كلمة المرور",
  "admin.profile.settings": "الإعدادات",
  "admin.profile.settings.allow_bal_transfer.title": "السماح بتحويل الرصيد",
  "admin.profile.settings.allow_bal_transfer.desc": "هل توافق أن يقوم عملائك بتحويل المبالغ المكتسبة بينهم؟.",
  "admin.profile.change_mobile": "تغيير رقم الجوال",
  "admin.profile.change_mobile.info": "إدخل رمز التفعيل الذي تم إرسالة إلى الجوال المسجل",
  "admin.profile.change_mobile.success": "تم تحديث رقم الجوال بنجاح!",
  "admin.profile.rating": "التقييم",
  "admin.profile.total_earnings": "إجمالي المبالغ المكتسبة",
  "admin.profile.total_discount": "إجمالي الخصومات",
  "admin.profile.rab7_balance": "رصيد Rab7 ",

  "admin.post.list.title": "قائمة المراجعات",
  "admin.post.list.search": "البحث عن منشور",
  "admin.post.table.post_title": "ردود الفعل",
  "admin.post.table.date_posted": "تاريخ النشر",
  "admin.post.table.comments_count": "عدد التعليقات",
  "admin.post.confirm_delete_msg": "هل أنت متأكد من حذف المنشور؟ لن تكون قادر على استرجاعه فيما بعد.",
  "admin.post.comment.confirm_delete_msg": "هل أنت متأكد من حذف التعليق ؟ لن يمكنك استرجاع البيانات فيما بعد.",
  "admin.post.validation.address.required": "مطلوب",

  "admin.branch.list.title": "قائمة الفروع",
  "admin.branch.list.search": "البحث عن فرع",
  "admin.branch.field.branch_name": "اسم الفرع  (الإنجليزية)",
  "admin.branch.field.branch_name_ar": "اسم الفرع (باللغة العربية)",
  "admin.branch.field.city.hint": "قم بكتابة اسم المدينة و الحي إن وجد، ثم اختار من القائمة التي ستظهر و إذا لم تجد اسم المدينة أو الحي قم بإختيار أقرب موقع لك",
  "admin.branch.field.geo_location.hint": "دقة اختيارك للموقع ستساعد عملائك في ايجادك بسرعة وسهولة",
  "admin.branch.modal.hint.location": "حدد المدينة أولاً ، ثم استخدم الموقع الجغرافي لملء خطوط الطول والعرض",
  "admin.branch.add_new_branch": "اضافة فرع جديد",
  "admin.branch.edit_branch": "تعديل فرع",
  "admin.branch.confirm_enable_msg": "هل أنت متأكد من تفعيل الفرع؟",
  "admin.branch.confirm_disable_msg": "هل أنت متأكد من أنك تريد تعطيل الفرع؟ سيؤدي هذا إلى تعطيل جميع الموظفين المرتبطين بهذا الفرع. يمكنك تمكين الفرع في وقت لاحق. تحتاج إلى تمكين الموظفين المرتبطين بهذا الفرع بشكل صريح.",
  "admin.branch.confirm_delete_msg": "هل أنت متأكد أنك تريد حذف الفرع؟ سيؤدي هذا إلى حذف جميع الموظفين المرتبطين بهذا الفرع؟ لن تكون قادر على استرجاع البيانات لاحقا.",
  "admin.branch.validation.name.required": "مطلوب  (الإنجليزية)",
  "admin.branch.validation.name_ar.required": "مطلوب",
  "admin.branch.validation.address.required": "مطلوب  (الإنجليزية)",
  "admin.branch.validation.localityId.required": "مطلوب",
  "admin.branch.validation.subLocalityId.required": "مطلوب",
  "admin.branch.validation.latitude.required": "مطلوب",
  "admin.branch.validation.longitude.required": "مطلوب",

  "admin.gift.list.title": "قائمة الهدايا",
  "admin.gift.list.search": "البحث عن الهدايا",
  "admin.gift.table.gift_name": "اسم الهدية",
  "admin.gift.table.consumed_limit": "الحد المسموح للاستهلاك",
  "admin.gift.field.points_to_redeem": "عدد النقاط المطلوبة",
  "admin.gift.add_new_gift": "إضافة هدية جديدة",
  "admin.gift.edit_gift": "تعديل هدية",
  "admin.gift.confirm_enable_msg": "هل أنت متأكد من تفعيل الهدية ؟",
  "admin.gift.confirm_disable_msg": "هل أنت متأكد من تعطيل الهدية ؟",
  "admin.gift.confirm_delete_msg": "هل أنت متأكد من حذف الهدية ؟ لن تكون قادر على استرجاع البيانات لاحقا",
  "admin.gift.validation.name.required": "مطلوب",
  "admin.gift.validation.imageFile.required": "مطلوب",
  "admin.gift.validation.pointsToRedeem.required": "مطلوب",
  "admin.gift.validation.startAt.required": "مطلوب",
  "admin.gift.validation.endAt.required": "مطلوب",

  "admin.loyalty_formula.field.name.hint": "اسم تعريفي لنظام الولاء",

  "admin.earnPointFormula.list.title": "معادلات الاكتساب لنظام الولاء",
  "admin.earnPointFormula.list.search": "البحث عن المعادلات",
  "admin.earnPointFormula.table.min_invoice_value": "الحد الأدنى لقيمة الفاتورة",
  "admin.earnPointFormula.table.max_points": "الحد الأقصى للنقاط",
  "admin.earnPointFormula.field.spends_per_point": "كم يجب على العميل الانفاق ليحصل على رصيد ريال واحد",
  "admin.earnPointFormula.field.min_bill_to_earn_point": "الحد الأدنى للفاتورة للحصول على نقاط",
  "admin.earnPointFormula.field.max_earn_points": "الحد اليومي للنقاط الذي يمكن اكتسابه",
  "admin.earnPointFormula.add_new_earnPointFormula": "إضافة معادلة جديدة لإكتساب النقاط",
  "admin.earnPointFormula.edit_earnPointFormula": "تعديل معادلة اكتساب النقاط",
  "admin.earn_point_formula.validation.name.required": "مطلوب",
  "admin.earn_point_formula.validation.priority.required": "مطلوب",
  "admin.earn_point_formula.validation.priority.min_num": "{min} يجب أن يكون أكثر من ",
  "admin.earn_point_formula.validation.priority.max_num": "{max} يجب أن يكون أقل من ",
  "admin.earn_point_formula.validation.startAt.required": "مطلوب",
  "admin.earn_point_formula.validation.endAt.required": "مطلوب",
  "admin.earn_point_formula.validation.spendsPerPoint.required": "مطلوب",

  "admin.redeemPointFormula.list.title": "إضافة معادلة الخصومات النقدية",
  "admin.redeemPointFormula.list.search": "البحث عن المعادلات",
  "admin.redeemPointFormula.table.min_invoice_amount": "الحد الأدنى للفاتورة",
  "admin.redeemPointFormula.table.min_points_can_redeem": "الحد الأدنى للاسترداد بفاتورة واحدة",
  "admin.redeemPointFormula.table.max_points_can_redeem": "الحد الأقصى للاسترداد بفاتورة واحدة",
  "admin.redeemPointFormula.table.min_wallet_balance_to_redeem": "الحد الأدنى في رصيد المحفظة للقيام بالاسترداد",
  "admin.redeemPointFormula.field.min_spends_to_redeem": "أقل مبلغ لفاتورة العميل ليحصل على خصم نقدي",
  "admin.redeemPointFormula.field.min_points_can_redeem": "أقل مبلغ يمكن خصمه للعميل بفاتورة واحدة",
  "admin.redeemPointFormula.field.max_points_can_redeem": "أعلى مبلغ يمكن خصمه للعميل بفاتورة واحدة",
  "admin.redeemPointFormula.field.max_points_can_redeem_daily": "أعلى مبلغ خصم للعميل في يوم واحد",
  "admin.redeemPointFormula.field.min_wallet_balance_to_redeem": "الرصيد المطلوب تجميعه حتي يمكن للعميل استعمال الخصم (رصيد المحفظة)",
  "admin.redeemPointFormula.add_new_redeemPointFormula": "إضافة معادلة جديدة لاسترداد النقاط",
  "admin.redeemPointFormula.edit_redeemPointFormula": "تعديل معادلة استرداد النقاط",
  "admin.redeemPointFormula.confirm_enable_msg": "هل أنت متأكد من تفعيل معادلة استرداد النقاط؟",
  "admin.redeemPointFormula.confirm_disable_msg": "هل انت متاكد من ايقاف الخصومات النقدية لعماائك",
  "admin.redeemPointFormula.confirm_delete_msg": "هل أنت واثق من حذف معادلة استرداد النقاط؟ لن تكون قادر على استرجاع البيانات لاحقا.",
  "admin.redeem_point_formula.validation.name.required": "مطلوب",
  "admin.redeem_point_formula.validation.priority.required": "مطلوب",
  "admin.redeem_point_formula.validation.priority.min_num": "{min} يجب أن يكون أكثر من ",
  "admin.redeem_point_formula.validation.priority.max_num": "{max} يجب أن يكون أقل من ",
  "admin.redeem_point_formula.validation.start_at.required": "مطلوب",
  "admin.redeem_point_formula.validation.end_at.required": "مطلوب",
  "admin.redeem_point_formula.validation.minSpendsToRedeem.required": "مطلوب",
  "admin.redeem_point_formula.validation.minSpendsToRedeem.min_num": "{min} يجب أن يكون أكثر من ",
  "admin.redeem_point_formula.validation.minPointsCanRedeem.required": "مطلوب",
  "admin.redeem_point_formula.validation.minPointsCanRedeem.min_num": "{min} يجب أن يكون أكثر من ",
  "admin.redeem_point_formula.validation.maxPointsCanRedeem.required": "مطلوب",
  "admin.redeem_point_formula.validation.maxPointsCanRedeem.min_num": "{min} يجب أن يكون أكثر من ",
  "admin.redeem_point_formula.validation.maxPointsCanRedeemDaily.required": "مطلوب",
  "admin.redeem_point_formula.validation.maxPointsCanRedeemDaily.min_num": "{min} يجب أن يكون أكثر من ",
  "admin.redeem_point_formula.validation.minWalletBalanceToRedeem.required": "مطلوب",
  "admin.redeem_point_formula.validation.minWalletBalanceToRedeem.min_num": "{min} يجب أن يكون أكثر من ",

  "admin.employee.list.title": "إدارة الموظفين",
  "admin.employee.list.search": "البحث عن موظف",
  "admin.employee.field.role.help": "إختيار الوظيفة مدير أو بائع",
  "admin.employee.field.mobile_numbers": "رقم جوال الموظف",
  "admin.employee.field.mobile_numbers.hint": "الضغط على إدخال للإضافة",
  "admin.employee.field.branch.hint": "عدم اختيار اي فرع يعني ان الموظف مصرح له بكل الفروع",
  "admin.employee.add_new_employee": "دعوة موظف",
  "admin.employee.edit_employee": "تعديل موظف",
  "admin.employee.confirm_enable_msg": "هل أنت متأكد من تفعيل الموظف؟",
  "admin.employee.confirm_disable_msg": "هل أنت متأكد من أنك تريد تعطيل الموظف؟ سيؤدي ذلك أيضًا إلى تعطيله من جميع الفروع الحالية وبعد تفعيله مرة أخرى ، عليك إضافته إلى الفرع الخاص به يدويًا.",
  "admin.employee.confirm_delete_msg_invitation": "هل أنت متأكد من حذف الدعوة؟ لن يمكنك استرجاع البيانات لاحقا.",
  "admin.employee.confirm_delete_msg_employee": "هل أنت متأكد من حذف الموظف؟ لن يمكنك استرجاع البيانات لاحقا.",
  "admin.employee.confirm_invite_msg": "هل أنت متأكد من إعادة ارسال الدعوة؟",
  "admin.employee.validation.mobile_numbers.required": "مطلوب",
  "admin.employee.validation.role.required": "مطلوب",
  "admin.employee.validation.branchIds.required": "مطلوب",
  "admin.employee.validation.access.required": "مطلوب",

  "admin.chats.table.chat_with": "المحادثة مع",
  "admin.chats.table.last_message": "الرسالة الأخيرة",
  "admin.chats.table.last_message_at": "الرسالة الأخيرة في ",

  "admin.customerChats.list.title": "قائمة محادثات العملاء",
  "admin.customerChats.list.search": "البحث عن مستخدم",

  "admin.employeeChats.list.title": "قائمة محادثات الموظفين",
  "admin.employeeChats.list.search": "البحث عن مستخدم",

  "admin.supportChats.modal.title": "الدعم الفني @Rab7",

  "admin.activity.list.title": "قائمة الأنشطة",
  "admin.activity.list.search": "البحث عن نشاط",

  "admin.activity.table.clicked_count": "عدد النقرات",
  "admin.activity.table.consumed_count": "عدد العروض المستهلكة",
  "admin.activity.table.answer_count": "عدد الردود",
  "admin.activity.table.displayed_count": "عدد مرات العرض",
  "admin.activity.table.likes_count": "عدد المعجبين",
  "admin.activity.table.dislikes_count": "عدد الغير معجبين",
  "admin.activity.confirm_enable_msg": "هل أنت متأكد من تفعيل هذا النشاط؟",
  "admin.activity.confirm_disable_msg": "هل أنت متأكد من تعطيل هذا النشاط؟",
  "admin.activity.confirm_delete_msg": "هل أنت متأكد من حذف المنشور؟ لن يمكنك استرجاع البيانات لاحقا.",
  "admin.activity.comment.confirm_delete_msg": "هل أنت متأكد من إلغاء التعليق على النشاط؟ لن يمكنك استرجاع البيانات فيما بعد.",
  "admin.activity.reject.title": "سبب الرفض",
  "admin.activity.section.basic_details": "معلومات اساسية",
  "admin.activity.section.display_limit": "الحد الاعلى للمشاهدات الكلية",
  "admin.activity.offer.section.display_limit": "عناصر العرض",
  "admin.activity.section.display_criteria": "معايير العملاء المستهدفون",
  "admin.activity.offer.section.display_criteria": "الفئة المستهدفة",
  "admin.activity.section.question": "سؤال",
  "admin.activity.criteria.hint": "اذا اردت استهداف الكل ، فاترك الحقل فارغا",
  "admin.activity.field.activity_type": "نوع النشاط",
  "admin.activity.field.display_limit": "الحد الكلي المتاح للعرض",
  "admin.activity.field.display_limit_per_user": "الحد المتاح للعرض لكل مستخدم",
  "admin.activity.field.consume_limit": "الحد الكلي المسموح للاستهلاك",
  "admin.activity.field.consume_limit_per_user": "الحد المسموح للاستهلاك لكل مستخدم",
  "admin.activity.field.click_limit": "الحد الكلي المسموح للنقر على النشاط",
  "admin.activity.field.answer_limit": "الحد الكلي المتاح للاجابات عن الاسئلة",
  "admin.activity.field.transfer_limit_per_user": "الحد المسموح للتحويل لكل مستخدم",
  "admin.activity.field.clicked_points": "النقط المستحقة لكل نقرة",
  "admin.activity.field.answered_points": "النقط المستحقة لكل إجابة",
  "admin.activity.field.consumed_points": "النقط المستحقة لكل استهلاك",
  "admin.activity.field.allow_transfer": "السماح بالتحويل",
  "admin.activity.field.is_code_needed": "طلب كود التفعيل لاستهلاك العرض",
  "admin.activity.field.select_branch": "الفرع الذي يسمح باستهلاك العرض فيه",
  "admin.activity.field.select_regions": "المنطقة المستهدفة",
  "admin.activity.field.select_localities": "المدينة المستهدفة",
  "admin.activity.field.select_sub_localities": "الحي المستهدف",
  "admin.activity.field.gender": "الجنس المستهدف",
  "admin.activity.field.marital_status": "الحالة الإجتماعية المستهدفة",
  "admin.activity.field.select_age_group": "الفئة العمرية المستهدفة",
  "admin.activity.field.select_education_level": "مستوى التعليم المستهدف",
  "admin.activity.field.select_nationality": "الجنسية المستهدفة",
  "admin.activity.field.select_profession": "المهنة المستهدفة",
  "admin.activity.field.select_companies": "الشركة التي يعمل بها العملاء المستهدفون",
  "admin.activity.field.select_company_level": "مستوى الشركة المستهدفة",
  "admin.activity.field.criteria_earned_system_points": "عدد النقاط المطلوب للاستهداف",
  "admin.activity.field.select_criteria_targeted_customers": "استهداف العملاء عن طريق رقم Rab7 ",
  "admin.activity.field.select_criteria_targeted_all_customers": "استهداف الجميع",
  "admin.activity.field.question_options_1": "الخيار الأول  (الإنجليزية)",
  "admin.activity.field.question_options_1_ar": "الخيار الأول (بالعربية)",
  "admin.activity.field.question_options_2": "الخيار الثاني  (الإنجليزية)",
  "admin.activity.field.question_options_2_ar": "الخيار الثاني (بالعربية)",
  "admin.activity.field.question_options_3": "الخيار الثالث  (الإنجليزية)",
  "admin.activity.field.question_options_3_ar": "الخيار الثالث (بالعربية)",
  "admin.activity.field.question_options_4": "الخيار الرابع  (الإنجليزية)",
  "admin.activity.field.question_options_4_ar": "الخيار الثالث (بالعربية)",
  "admin.activity.field.right_option": "الجواب الصحيح",
  "admin.activity.field.right_option.hint": "اتركه فارغا لتترك للعميل حرية الاجابة ، وسيكون هناك تنبيه في الاجابة الخاطئة مرتين",
  "admin.activity.field.right_option_hint": "تلميح  الجواب الصحيح  (الإنجليزية)",
  "admin.activity.field.right_option_hint_ar": " تلميح  الجواب الصحيح (بالعربية)",
  "admin.activity.field.feedback_hint": "تلميحات لتقييمات العملاء",
  "admin.activity.field.feedback_hint_ar": "تلميحات لتقييمات العملاء   - عربي - ",
  "admin.activity.validation.type.required": "مطلوب",
  "admin.activity.validation.title.required": "مطلوب",
  "admin.activity.validation.priority.required": "مطلوب",
  "admin.activity.validation.priority.min_num": "{min} يجب أن يكون أكثر من ",
  "admin.activity.validation.priority.max_num": "{max} يجب أن يكون أقل من ",
  "admin.activity.validation.start_at.required": "مطلوب",
  "admin.activity.validation.end_at.required": "مطلوب",

  "admin.transaction.title": "قائمة العمليات",
  "admin.transaction.search": "البحث عن المبلغ المنفق",
  "admin.transaction.field.transaction_type": "نوع العملية",
  "admin.transaction.type.earn": "اكتساب",
  "admin.transaction.type.redeem": "هدية مستبدلة",
  "admin.transaction.type.consume_offer": "استهلاك العرض",
  "admin.transaction.type.refund": "استرداد",
  "admin.transaction.type.transfer": "تحويل",

  "admin.userBalance.title": "قائمة أرصدة المستخدم",
  "admin.userBalance.search": "البحث في أرصدة المستخدم",

  "admin.systemUser.list.title": "قائمة مستخدمي النظام",
  "admin.systemUser.list.search": "البحث عن مستخدم للنظام",
  "admin.systemUser.add_new_user": "إضافة مستخدم للنظام",
  "admin.systemUser.edit_user": "تعديل مستخدم النظام",
  "admin.system_user.validation.mobile_number.required": "مطلوب",
  "admin.system_user.validation.email.required": "مطلوب",
  "admin.system_user.validation.branchIds.required": "مطلوب",
  "admin.system_user.validation.access.required": "مطلوب",

  "admin.insights.sort.title": "التقسيم على:",
  "admin.insights.reset_all": "إعادة التهيئة",
  "admin.insights.filters": "عوامل التصفية",
  "admin.insights.filter.date_rang": "النطاق الزمني",
  "admin.insights.filter.hours": "ساعة اليوم",
  "admin.insights.filter.days": "أيام الشهر",
  "admin.insights.filter.day_of_week": "أيام الأسبوع",
  "admin.insights.filter.months": "الشهور",
  "admin.insights.filter.region": "منطقة",
  "admin.insights.filter.city": "مدينة",
  "admin.insights.filter.district": "حي",
  "admin.insights.filter.gender": "نوع",
  "admin.insights.filter.marital_status": "الحالة الإجتماعية",
  "admin.insights.filter.age_group": "الفئة العمرية",
  "admin.insights.filter.education_level": "المستوى التعليمي",
  "admin.insights.filter.branch": "فرع",
  "admin.insights.filter.employee": "اسم الموظف",
  "admin.insights.filter.transaction_type": "نوع العملية",
  "admin.insights.filter.landing_options": "أسئلة الكاشير",
  "admin.insights.filter.ratings": "التقييم",

  "admin.insights.filter.activity": "الحملة",
  "admin.insights.filter.answers": "إجابات الحملة",
  "admin.insights.filter.transferred_to": "التحويل إلى",
  "admin.insights.filter.like": "الإعجاب بالحملة",
  "admin.insights.filter.preference": "المفضلة",
  "admin.insights.filter.nationality": "الجنسية",
  "admin.insights.filter.profession": "المهنة",
  "admin.insights.filter.company": "الشركة",
  "admin.insights.filter.visits": "تردد العملاء",
  "admin.insights.filter.gift": "هدية",
  "admin.insights.filter.offer": "عرض",
  "admin.insights.filter.title.general": "عام",
  "admin.insights.filter.title.demographic": "الديموغرافي",
  "admin.insights.filter.title.geographic": "الجغرافي",
  "admin.insights.filter.title.timeline": "الزمني",
  "admin.insights.filter.title.transactional": "العملياتي",

  "user.profile.mobile_number.hint": "ادخل رقم الجوال الجديد",
  "notification.activity_approved": "تم الموافقة على الطلب  <strong>{title}</strong>",
  "notification.activity_rejected": "تم رفض الطلب  <strong>{title}</strong>",
  "notification.activity_reject_reason": "سبب الرفض  - <strong>{rejectReason}</strong>",
  "navigation.profile.approval_rejected": "تم الرفض ",
  "admin.landing.approval_rejected": "تم رفض الطلب.",
  "admin.landing.approval_rejected_reason": "سبب الرفض: {rejectReason}",
  "admin.post.filter.type": "النوع",
  "admin.post.filter.branch": "الفرع",
  "admin.post.filter.type.both": "الكل",
  "admin.post.table.rating_by": "قيمت من قبل",
  "admin.post.rating_by.visitor": "زائر",
  "admin.post.rating_by.buyer": "مشتري",
  "admin.gift.validation.description.max_char": "التفاصيل يجب ان لا تتجاوز احرفها  {maxChar} حرفا  (الإنجليزية)",
  "admin.gift.validation.tnc.max_char": "الشروط والاحكام لا يجب ان يتحاوز عدد احرفها  {maxChar} حرفا  (الإنجليزية)",
  "admin.gift.validation.description_ar.max_char": "التفاصيب (بالعربية) بجب ان لا يتجاوز عدد احرفها {maxChar} حرفا",
  "admin.gift.validation.tnc_ar.max_char": "الشروط والاحكام s (العربية) بجب ان لا يتجاوز عدد احرفها  {maxChar} حرفا",
  "admin.employee.table.email": "البريد الالكتروني",
  "admin.activity.filter.type": "النوع",
  "admin.activity.filter.state": "الحالة",
  "admin.activity.filter.city": "المدينة",
  "admin.activity.add_offer": "اضف عرض",
  "admin.activity.add_activity": "اضف اعلان",
  "admin.activity.modal.ratings": "التقييم",
  "admin.activity.table.rating": "التقييم",
  "admin.activity.table.raters_count": "عدد المقيميين",
  "admin.activity.field.display_space": "مكان العرض",
  "admin.activity.field.target_only_selected_business": "استهداف العملاء فقط ",
  "admin.activity.field.question_title": "نص السؤال  (الإنجليزية)",
  "admin.activity.field.question_title_ar": "نص السؤال  (بالعربي)",
  "admin.activity.display_spaces.acknowledgements": "لوحة الاعلان بعد اكتساب النقاط",
  "admin.activity.display_spaces.feeds": "لوحة الاعلان في المشاركات",
  "admin.activity.display_spaces.special_prompts": "لوحة الاعلان في فتح التطبيق",
  "admin.activity.display_spaces.everywhere": "كل الامكنة المتاحة",
  "admin.activity.display_spaces.mine": "عرض",
  "admin.userBalance.filter.minPoints": "الحد الادنى من النقاط",
  "admin.userBalance.filter.maxPoints": "الحد الاعلى من النقاط",
  "admin.insights.filter.rating_by": "قيمت من قبل",

  "admin.branch.filter.city": "المدينة",
  "admin.activity.count_limit.hint": "اترك الحقل فارغا لجعلها غير محدودة",
  "admin.address_ar": "العنوان بالعربية",
  "admin.branch.validation.address_ar.required": "متطلب",
  "admin.activity.field.target_only_selected_customers": "استهداف عملاء محددين",
  "admin.profile.passed_for_approval": "مرحبا ، وتم ارسال الملف الشخصي لمنشئتك لمدير النظام في (رابح) للمراجعة والموافقة ، وسيتم ابلاغك بالموافقة برسالة نصية على جوالك المسجل",
  "profile.validation.bio.max_char": "لا يجب ان تتجاوز {max}",
  "admin.activity.field.link": "الرابط  (الإنجليزية)",
  "admin.activity.field.link_ar": "الرابط للعربية (عربي)",
  "admin.activity.link.hint": "ضع الرابط الذي تريد ان يفتح حين الضغط على القورة",
  "admin.activity.smart_ad.criteria.hint": "اترك الحقل فارغا لعرضه على الجميع",
  "admin.activity.smart_ad.field.select_branch": "الفروع التي سيتم عرض الاعلان فبها",
  "user.profile.bio.label": "تعريف مبسط بالنشاط وخدماته ومنتجاته",

  "admin.transfer_transaction.title": "عمليات التحويل",
  "admin.transfer_transaction.search": "بحث",
  "admin.transfer_transaction.from_user": "من المستخدم",
  "admin.transfer_transaction.to_user": "الى المستخدم",
  "admin.transfer_transaction.points": "نقاط",
  "admin.transfer_transaction.relation": "العلاقة",
  "admin.transfer_transaction.createdAt": "انشئ في",
  "admin.transfer_transaction.filter.relation": "العلاقة",
  "admin.system_notification.title": "اشعارات النظام",
  "admin.insights.filter.customers": "العملاء",
  "admin.insights.filter.top_customers": "أفضل العملاء",
  "notification.commented_in_activity": "تعليق على <strong>{title}<strong>",
  "admin.activity.target_only_selected_business.hint": "استهداف كل عملاء Rab7 ",
  "user.profile.form_image.label": "العقد الابتدائي",
  "user.profile.form_image.hint": "<a href='https://f1db5c47-94e6-4de9-837c-d70fa07f0494.filesusr.com/ugd/a3cf48_b573ddf23f804a40a935131ffaa39e21.doc?dn=Chumber.doc' target='_blank'>حمل النموذج وقم بتعبئته وختمه من الغرفة التجارية من خلال ابشر او من خلال مكاتب الغرفة التجارية ثم قم بارفاقه هنا</a>",
  "navigation.transferTransactions": "عمليات التحويل",
  "notification.count_text": "و {count} اخرى",
  "notification.counts_text": "و {count} اخرى",

  "admin.gift.quantity.hint": "اترك الحقل فارغ عندما تكون الهدايا غير محدودة",
  "admin.systemUser.confirm_disable_msg": "هل انت متاكد من رغبتك في تعطيل هذا المستخدم",
  "admin.systemUser.confirm_enable_msg": "هل انت متاكد من رغبتك في تفعيل هذا المستخدم",

  "admin.activity.field.question_option": "خيارات الاجابة {number} (الإنجليزية)",
  "admin.activity.field.question_option.add": "أضف اجابة ",
  "admin.activity.field.question_option_ar": "خيار الإجابة {number} (عربي)",
  "admin.activity.validation.options.name.required": "حقل متطلب",
  "admin.activity.validation.options.name.max_char": "يجب ان لا يتجاوز  {maxChar}  احرف  (الإنجليزية)",
  "admin.activity.validation.options.name_ar.max_char": "يجب ان لا يتجاوز  {maxChar} أحرف",
  "admin.activity.field.select_user_interests": "استهداف باهتمامات العملاء",
  "admin.insights.focus_area.insights": "اهتمامات العملاء",
  "admin.insights.fetch_insights": "عرض لوحة المؤشرات ",
  "admin.customerChats.disabled.message": "تم ايقاف المحادثة من قبل العميل , لا يمكنك اكمال الحديث معه.",
  "admin.activity.edit_activity_display_criteria": "استهداف الحملات",
  "admin.activity.add_question": "إنشاء إهتمامات العملاء",
  "admin.activity.list.action.edit_display_criteria": "تعديل استهداف الحملات",
  "admin.activity.activity_modal.success.message": "ملحوظة: سيتم تمرير إعلاناتك / عرضك / سؤالك إلى [فريق دعم  [Rab7  للموافقة عليه ، وسوف ننتقلك إلى صفحة الاستهداف حيث يمكنك تحديد مكان وفئة العملاء الذين تريد استهدافهم، وفي حال عدم استهدافك لاحد سيتم اعتباره استهدف لجميع العملاء",
  "admin.activity.activity_modal.target_customers": "استهداف العملاء",
  "admin.activity.question.field.select_branch": "اسم الفرع الذي سيعرض به السؤال",
  "admin.insights.full_screen_map.compress": "تصغير الخريطة",
  "admin.insights.full_screen_map.expand": "تكبير الخريطة",
  "admin.insights.answers": "اسم الاهتمام",

  "registration.validation.form_image.required": "مطلوب",

  "admin.activity.add_ad_title": "إنشاء إعلان",
  "admin.activity.add_offer_title": "إنشاء عرض ترويجي",
  "admin.activity.add_question_title": "إنشاء إهتمامات العملاء",
  "admin.activity.edit_ad_title": "تعديل الاعلان",
  "admin.activity.edit_offer_title": "تعديل العرض",
  "admin.activity.edit_question_title": "تعديل الاهتمامات",

  "admin.activity.smart_ad.field.display_limit_per_user": "عدد مرات تكرار مشاهدة الإعلان لكل مستخدم",
  "admin.activity.offer.link.hint": "ضع الرابط الذي تريد ان يفتح حين الضغط على الصورة",
  "admin.activity.offer.field.consume_limit": "كم إجمالي عدد العروض المقدمة",
  "admin.activity.offer.field.consume_limit_per_user": "كم عرض يمكن استخدامه من كل عميل",

  "admin.activity.question.section.display_limit": "الفترة الزمنية للسؤال",
  "admin.activity.smart_ad.section.display_limit": "عناصر الإعلان",

  "admin.language.hint": "(الإنجليزية)   لمستخدمي الجوال باللغة الإنجليزية \n (العربية) لمستخدمي الجوال باللغة العربية",
  "admin.activity.add_buyer_opinion": "إنشاء اراء المشترين",
  "admin.activity.edit_buyer_opinion": "تعديل راي المشترين",
  "admin.activity.add_buyer_opinion_title": "إنشاء رأي المشترين",
  "admin.activity.edit_buyer_opinion_title": "تعديل رأي المشترين",
  "admin.branch.table.branch_name": "اسم الفرع",
  "generic.name_en": "الاسم (الانجليزية) ",
  "generic.title_en": "العنوان (بالانجليزية)",

  "admin.activity.add_employee_offer": "اضافة عرض للموظفين",
  "admin.activity.add_employee_offer_title": "اضافة عرض للموظفين",
  "admin.activity.edit_employee_offer_title": "تعديل عرض الموظفين",
  "admin.activity.section.feedback": "توجيه اراء التقييم",
  "admin.activity.buyer_opinion.section.display_limit": "عناصر راي المشترين",
  "generic.tnc_en": "الشروط والاحكام",
  "admin.activity.buyer_opinion.field.select_branch": "الفرع الذي سيظهر فيه السؤال عن رأي العميل",
  "admin.employee.table.name": "اسم الموظف",
  "admin.activity.table.title": "العنوان",

  "admin.activity.visitor_opinion.section.display_limit": "عناصر رأي العميل",
  "admin.activity.add_visitor_opinion_title": "إنشاء رأي الزائرين",
  "admin.activity.edit_visitor_opinion_title": "تعديل رأي الزائرين",
  "admin.activity.add_visitor_opinion": "إضافة رأي الزائرين",

  "admin.activity.offer.field.consumable_days": "ايام استخدام العروض",
  "admin.activity.consumable_days.hint": "اختر ايام الاسبوع التي يستطيع فيها عملائك من استخدام العروض. \nوفي حال اردت اختيار جميع الايام فاترك الحقل فارغاً",

  "admin.earnPointFormula.table.amount_spend": "المبلغ المطلوب انفاقه لاكتساب ريال واحد",
  "admin.earnPointFormula.table.availability": "الحالة",
  "admin.earnPointFormula.table.toggle_redeem_formula_state": "الحالة",

  "navigation.products": "المنتجات",
  "navigation.product_categories": "الفئات",
  "navigation.product_items": "المنتجات",

  "admin.productCategory.list.title": "فئة المنتج",
  "admin.productCategory.list.search": "بحث",
  "admin.productCategory.edit": "تعديل فئة المنتجات",
  "admin.productCategory.add_new": "إنشاء فئة للمنتجات",
  "admin.productCategory.validation.name.required": "حقل إلزامي",
  "admin.productCategory.validation.name_ar.required": "حقل إلزامي",
  "admin.productCategory.validation.name.max_char": "لا يجب ان يكون اكثر من {max}",
  "admin.productCategory.validation.name_ar.max_char": "لا يجب ان يكون اكثر من {max}",
  "admin.productCategory.confirm_enable_msg": "هل انت متأكد من رغبتك في تمكين هذه الفئة ",
  "admin.productCategory.confirm_disable_msg": "هل أنت متأكد من رغبتك في تعطيل هذه الفئة, سيتم تعطيل كل المنتجات التي تنتمي اليها, وفي حال تمكينها لاحقا سيتوجب عليك تمكين منتجاتها كل واحد على حده",
  "admin.productCategory.confirm_delete_msg": "هل انت متأكد من رغبتك في حذف هذه الفئة, سيتم حذف كل المنتجات التي تنتمي اليها ولن يكون لديك القدرة على ارجاعها لاحقا",

  "admin.productItems.field.category": "فئة المنتج",
  "admin.productItems.field.price": "السعر",
  "admin.productItems.field.discount_price": "السعر بعد الخصم",
  "admin.productItems.field.details": "وصف المنتج",
  "admin.productItems.field.details_ar": "وصف المنتج (بالعربية) ",
  "admin.productItems.field.allow_chat": "السماج بالمحادثة",
  "admin.productItems.field.allow_chat.hint": "السماح للعميل ببدأ محادثة حول المنتج",
  "admin.productItem.list.title": "المنتجات",
  "admin.productItem.list.search": "البحث",
  "admin.productItems.list.name": "الإسم",
  "admin.productItems.list.name_ar ": "الإسم (بالعربية) ",
  "admin.productItems.list.category": "الفئة",
  "admin.productItems.list.price": "السعر",
  "admin.productItems.list.discount_price": "السعر بعد الخصم",
  "admin.productItems.list.likes": "الاعجاب",
  "admin.productItems.list.reaches": "المشاهدات",
  "admin.productItem.add_product": "اضف منتج",
  "admin.productItem.edit_product": "تعديل منتج",
  "admin.productItem.confirm_enable_msg": "هل انت متاكد من رغبتك في تمكين المنتج",
  "admin.productItem.confirm_disable_msg": "هل انت متاكد من رغبتك في تعطيل المنتج",
  "admin.productItem.confirm_delete_msg": "هل انت متاكد من رغبتك بحذف المنتج , حيث لن يسمح باسترجاعه لاحقا",

  "admin.productItem.validation.categoryId.required": "حقل إلزامي",
  "admin.productItem.validation.name.required": "حقل إلزامي",
  "admin.productItem.validation.name.max_char": "لا يسمح باكثر من  {max} حرف ",
  "admin.productItem.validation.name_ar.required": "حقل إلزامي",
  "admin.productItem.validation.name_ar.max_char": "لا يسمح باكثر من  {max} حرف ",
  "admin.productItem.validation.details.required": "حقل إلزامي",
  "admin.productItem.validation.details.max_char": "لا يسمح باكثر من  {max} حرف ",
  "admin.productItem.validation.details_ar.required": "حقل إلزامي",
  "admin.productItem.validation.details_ar.max_char": "لا يسمح باكثر من  {max} حرف ",
  "admin.productItem.validation.imageUrls.required": "يجب على الاقل اضافة  {min} صورة ",
  "admin.productItem.validation.price.required": "حقل إلزامي",
  "admin.productItem.validation.price.min": "يجب ان يكون اكثر من   {min}",

  "admin.earnPointFormula.list.discount": "السعر بعد الخصم ",
  "admin.earnPointFormula.confirm_enable_msg": "هل انت متاكد من رغبتك في اظهار الخصم",
  "admin.earnPointFormula.discount.confirm_disable_msg": "هل انت متاكد من رغبتك في تعطيل الخصم",
  "admin.earnPointFormula.discount.confirm_delete_msg": "هل انت متاكد من رغبتك في حذف الخصم , لن يسمح باسترجاعه بعد الحذف",

  "admin.employeeChats.disabled.message": "The chat has been ended by employee. You are not allowed to send message.",
  "admin.supportChats.disabled.message": "المحادثة اغلقت من قبل الطرف الاخر لا يمكنك استئنافها ",

  "admin.activity.state.suspended": "Suspended",
  "admin.activity.state.waiting": "Waiting for Targeting",
  "admin.activity.activity_modal.target_all": "Target All",
  "admin.action.copy_link": "Copy Link",
  "admin.notification.link_copied": "Link copied.",

  "admin.profile.settings.notify_reviews.title": "Notify Reviews",
  "admin.profile.settings.notify_review_comments.title": "Notify Review Comments",
  "admin.profile.settings.notify_activity_comments.title": "Notify Campaign Comments",
  "admin.profile.settings.notify_messages.title": "Notify Chat Messages",

  "navigation.products_and_services": "Products & Services",
  "navigation.branch_and_employees": "Branches & Employees",
  "navigation.transfer_transactions": "Transfer Transactions",
  "navigation.customer_balances": "Customer Balances",

  "admin.list.action.comment": "Comment",

  "admin.system_user.permission.ad": "Ad",
  "admin.system_user.permission.offer": "Offer",
  "admin.system_user.permission.question": "Question",
  "admin.system_user.permission.buyer_opinion": "Buyer Opinion",
  "admin.system_user.permission.visitor_opinion": "Visitor Opinion",
  "admin.system_user.permission.review": "Review",
  "admin.system_user.permission.customer_chat": "Customer Chat",
  "admin.system_user.permission.employee_chat": "Employee Chat",
  "admin.system_user.permission.support_chat": "Support Chat",
  "admin.system_user.permission.transaction": "Transaction",
  "admin.system_user.permission.customer_balance": "Customer Balance",
  "admin.system_user.permission.loyalty_points": "Loyalty Points",
  "admin.system_user.permission.product": "Product",
  "admin.system_user.permission.gift": "Gift",
  "admin.system_user.permission.branch": "Branch",
  "admin.system_user.permission.employee": "Employee",
  "admin.system_user.permission.system_user": "System User",
  "admin.system_user.permission.profile": "Profile",
  "admin.system_user.permission.insights_campaign": "Insights Campaign",
  "admin.system_user.permission.insights_product": "Insights Product",
  "admin.system_user.permission.insights_profile": "Insights Profile",
  "admin.system_user.permission.insights_sales": "Insights Sales",

  "admin.system_user.permission.type.view": "View",
  "admin.system_user.permission.type.comment": "Comment",
  "admin.system_user.permission.type.manage": "Manage",

  "generic.clear": "Clear",
  "admin.activity.modal.answers": "Answers",
  "admin.activity.modal.totalAnswers": "Total Answers",

  "admin.post.filter.customer": "Customer",

  // trans

  "login.registration": "التسجيل",
  "login.register": "سجل",
  "login.mobileNumber": "رقم الجوال",
  "login.validation.tnc.required": "فضلا اقبل الشروط والأحكام وسياسة الخصوصية وسياسة الإستخدام للتمكن من التسجيل",
  "login.verifyMobile.title": "تأكيد رقم الجوال",
  "login.verifyMobile.subTitle": "ضع الرقم المرسل لجوالك هنا",
  "login.bySignUpAgreeTo": "من خلال تسجيل الدخول، فإنك توافق على",
  "login.tnc": "الشروط والأحكام وسياسة الخصوصية وسياسة الإستخدام",
  "login.mobileNumberCode": "966+",
  "login.verificationCode": "كود التاكيد",

  "registration.success": "تم التحديث بنجاح",
  "registration.companyInformation": "المعلومات التجارية للمنشأة",
  "registration.businessInformation": "معلومات مجال المنشأة",
  "registration.LocationInformation": "معلومات موقع المنشئة",
  "registration.bankAccountInformation": "المعلومات البنكية",
  "registration.personalInformation": "المعلومات الشخصية",
  "registration.typeBusiness": "سجل تجاري",
  "registration.category": "الفئة",
  "registration.nameEn": "الإسم – بالانجليزية-",
  "registration.nameAr": "الإسم – بالعربية -",
  "registration.logoImage": "صورة شعار المنشأة",
  "registration.keywords": "كلمات مفتاحية",
  "registration.bio": "مختصر يعبر عن منشأتك",
  "registration.bank": "اسم البنك",
  "registration.bankBranch": "اسم فرع البنك",
  "registration.bankAccountName": "اسم الحساب البنكي (باللغة الإنجليزية فقط)",
  "registration.bankAccountNumber": "رقم الحساب",
  "registration.bankIban": "الأيبان",
  "registration.identityExpiryDate": "تاريخ إنتهاء بطاقة الهوية",
  "registration.identityNumber": "رقم بطاقة الهوية",
  "registration.locality": "المدينة",
  "registration.subLocality": "حي",
  "registration.successMessage": "مبروك تم تسجيلك بنجاح , قم بالدخول الان",
  "registration.taxInformation": "المعلومات الضريبية",
  "registration.businessType": "نوع المنشأة",
  "registration.typeFreelancer": "عمل حر",
  "registration.businessNameEn": "أسم المنشأة (بالانجيزية)",
  "registration.businessNameAr": "أسم المنشأة (بالعربية)",
  "registration.recordNumberCommercial": "رقم وثيقة السجل التجاري",
  "registration.recordImageCommercial": "وثيقة السجل التجاري",
  "registration.recordNumberFreelance": "رقم وثيقة العمل الحر",
  "registration.recordImageFreelance": "وثيقة العمل الحر",
  "registration.issueDate": "تاريخ الإصدار",
  "registration.expiryDate": "تاريخ الإنتهاء",
  "registration.identityNameFirst": "الاسم الأول",
  "registration.identityNameLast": "الكنية",
  "registration.identityImage": "بطاقة الهوية",
  "registration.location": "الموقع",
  "registration.pickLocation": "اختر الموقع من الخريطة",
  "registration.taxNumber": "الرقم الضريبي",
  "registration.taxDocument": "وثيقة ضريبة القيمة المضافة",

  "registration.hint.keywords": "# هذه الكلمات المفتاحية تساعد العميل على ايجادك في البحث , ضع بين كل كلمة واخرى فاصلة",
  "registration.hint.businessNameCommercialEn": "# إسم المنشأة كما هو في السجل التجاري - باللغة الانجليزية - ",
  "registration.hint.businessNameCommercialAr": "# إسم المنشأة كما هو في السجل التجاري - باللغة العربية- ",
  "registration.hint.businessNameFreelanceEn": "# الإسم كما هو في وثيقة العمل الحر - باللغة الانجليزية - ",
  "registration.hint.businessNameFreelanceAr": "# الإسم كما هو في وثيقة العمل الحر - باللغة العربية- ",
  "registration.hint.city": "# اكتب مدينتك واختر من القائمة ، إذا لم تجدها ، اختر أقرب مدينة",
  "registration.hint.district": "# اكتب اسم الحي واختر من القائمة ، إذا لم تجدها ، فاختر الأقرب",
  "registration.hint.businessLocation": "# حدد المدينة والحي ثم انقل العلامة والمكان في الموقع المحدد على الخريطة",

  "registration.validation.invalidAccountNumber": "رقم حساب غير صحيح",
  "registration.validation.invalidIban": " غير صحيح IBAN رقم",
  "registration.validation.identityNameFirstIncorrect": "يجب ان يكون اكثر من ثلاثو احرف",
  "registration.validation.identityNameLastIncorrect": "يجب ان يكون اكثر من ثلاثو احرف",
  "registration.validation.businessLocation": "# حدد المدينة والحي ثم انقل العلامة والمكان في الموقع المحدد على الخريطة",

  "generic.tooltip.home": "أعمالي",
  "generic.back": "رجوع للقائمة",
  "generic.validation.required": "حقل مطلوب",
  "generic.tooltip.changeLanguage": "تغيير اللغة ",
  "generic.tooltip.manageAccount": "إدارة الحساب",
  "generic.proceed": "متابعة",
  "generic.information": "المعلومات",
  "generic.dragDropClickImage": "إضغط هنا لإختيار الصورة" ,
  "generic.imagePreview": "عرض الصورة",
  "generic.invalidDateFormat": "صيغة تاريخ غير صحيحة",
  "generic.invalidDate": "تاريخ غير صحيح",

  "account.informationUpdated": "تم إرسال معلومات المتجر",
  "account.informationUpdatedMessage": "تم إرسال معلومات المتجر وسيراجعها فريق تطبيق Rab7 و سيتم إخطارك عبر البريد الإلكتروني والرسائل القصيرة إلى عنوان بريدك الإلكتروني المسجل ورقم هاتفك المحمول على التوالي بمجرد الموافقة على الحساب." ,

  "home.accountCreated": "تم إنشاء الحساب",
  "home.accountCreatedMessage": "لقد تم إنشاء حسابك ، فضلا قم باكمال بيانات متجرك ثم ارسله للموافقة ،  سيقوم فريقنا بمراجعة المعلومات المقدمة والموافقة على الحساب. سيتم إخطارك عبر البريد الإلكتروني والرسائل القصيرة إلى عنوان بريدك الإلكتروني المسجل ورقم هاتفك المحمول على التوالي بمجرد الموافقة على الحساب.",
  "home.accountPending": "تم تحديث معلومات المتجر",
  "home.accountPendingMessage": "لقد قدمت معلومات متجرك وهي قيد المراجعة. سيتم إخطارك عبر البريد الإلكتروني والرسائل القصيرة إلى عنوان بريدك الإلكتروني المسجل ورقم هاتفك المحمول على التوالي بمجرد الموافقة على الحساب." ,
  "home.accountRejected": "تم رفض معلومات المتجر",
  "home.accountRejectedMessage": "تم رفض معلومات متجرك المقدمة من  قبلكم و يرجى تحديث المعلومات المطلوبة وإرسالها مرة أخرى للموافقة عليها. سيقوم فريقنا بمراجعة المعلومات المقدمة والموافقة على الحساب. سيتم إخطارك عبر البريد الإلكتروني والرسائل القصيرة إلى عنوان بريدك الإلكتروني المسجل ورقم هاتفك المحمول مرة واحدة تمت الموافقة على الحساب. " ,
  "home.accountApproved": "تم الموافقة على حساب متجرك",
  "home.accountApprovedMessage": "تمت الموافقة على حساب متجرك و يمكنك الآن إعداد متجرك وإدارته باستخدام تطبيقنا للجوّال Rab7 والذي تستطيع تحميله من متاجر التطبيقات للأندرويد والآيفون .",
  "home.rejectionReason": "سبب الرفض",
  "home.addBusinessInformation": "إكمال بيانات المتجر",
  "home.updateBusinessInformation": "تحديث معلومات المتجر",

  "login.recoverPassword": "استعادة كلمة المرور",
  "login.changePassword": "تغيير كلمة المرور",

  "login.alreadyAccountLogin": "هل تملك حسابًا مُسبقًا ؟ <br><b>سجل الدخول من هنا</b>",
  "login.loginHere": "سجل الدخول من هنا",
  "login.name": "الاسم الكريم",
  "login.username": "إسم المستخدم",
  "login.hint.username": "# اختر اسم مستخدم بإسم متجرك ، كلمة واحدة يتراوح طولها بين 3 و 25 ، مع أحرف فقط من a-z 0-9 _ ",
  "login.validation.usernameLength": "يجب أن يكون بين 3-25 حرفًا",
  "login.validation.passwordLength": "يجب أن يكون بين 5-25 حرفًا",
  "login.validation.incorrectMobileNumber": "رقم جوال غير صحيح",

  "registration.hint.accountNumber": "# يجب أن يكون بين 10-20 حرفًا",
  "registration.hint.iban": "# يجب أن يكون بطول 24 ، يبدأ بـ SA متبوعًا بالأرقام",
  "registration.validation.accountNumber": "رقم الحساب غير صحيح ، يجب أن يكون بين 10-20 رقمًا",
  "registration.validation.iban": "رقم الـ IBAN غير صحيح ، يجب أن يكون بطول 24 ، ويبدأ بـ SA ويتبعه أرقام",

  "registration.businessNameFreelancerEn": "الاسم المدون في وثيقة العمل الحر (بالإنجليزية)",
  "registration.businessNameFreelancerAr": "الاسم المدون في وثيقة العمل الحر (بالعربية)",
  "registration.businessNameIndividualEn": "الاسم المسجل في موقع معروف  (بالإنجليزية)",
  "registration.businessNameIndividualAr": "الاسم المسجل في موقع معروف (بالعربية)",
  "registration.typeMaroof": "موقع معروف",
  "registration.maroofLink": "رابط التعريف في موقع معروف",
  "registration.expressService": "متاجر الخدمة السريعة - إكسبريس",
  "registration.darkStoreService": "متاجر إلكترونية -  دارك ستور",
  "registration.hintDarkStoreService":  "المتاجر الإلكترونية – دارك ستور - خاص بالأنشطة التي تتميز بالتالي : \n•   تخدم منطقة جغرافية كبيرة مدينة او عدة مدن    \n•  مدة التوصيل تصل لساعات أو ايام معدودة  \n• تأكيد قبول طلب العميل خلال ساعات  \n• لا يتم تجهيز الطلب بشكل عاجل للعميل \n   هذا النوع يناسب النشاطات التالية:  \n•    المتاجر المنزلية   \n• العطورات وادوات التجميل  \n• الملابس والاحذية  \n• المتاجر الالكترونية بأنواعها   " ,
  "registration.hintExpressService": " متاجر الخدمة السريعة – إكسبريس - خاص بالأنشطة التي تتميز بالتالي: \n•  تخدم منطقة جغرافية محدودة مثل عدة أحياء  \n• مدة التوصيل (من 10 دقائق لـ 60 دقيقة)   \n•صلاحية المنتج قصيرة    \n•تأكيد قبول طلب العميل خلال  5 دقائق   \n  هذا النوع يناسب النشاطات التالية:  \n•المطاعم    \n•الكافي شوب   \n• العصائر   \n•الحلويات والمخابز ",
  "registration.maroofSelectHint": "# التسجيل باستخدام رابط معروف لا يشمل خدمات الدفع الالكتروني بل خدمة الدفع عن الاستلام , اذا كنت تريد استقبال مدفوعات عملائك عن طريق الدفع الالكتروني سجل باستخدام سجل تجاري او وثيقة عمل حر",
  "home.accountUpdateMessage": "المستندات والمعلومات المطلوبة هي :  \n• مستند سجل تجاري أو وثيقة العمل الحر أو رابط تعريف نشاطك في موقع معروف  \n•  اسم المنشئة  \n•  الشعار  \n•  الموقع الجغرافي   \n•  تفاصيل الحساب المصرفي  \n•  وثيقة الهوية الشخصية  \n•  يرجى جعل جميع المستندات في متناول اليد ثم المتابعة" ,
  "registration.addressEn": "العنوان (بالإنجليزية)",
  "registration.addressAr": "العنوان (بالعربية)",
  "registration.selectBusiness": "اختر اسم المتجر",

  "filePicker.fileLimitExceedMessage": "تم تجاوز الحد الأقصى المسموح به لعدد الملفات . مسموح فقط بـ {value}. ",
  "filePicker.fileAddedMessage": "الملف  {value} تمت اضافته. ",
  "filePicker.fileRemovedMessage": "الملف {value} تم حذفه. ",
  "filePicker.fileDropRejectMessage": "الملف {value} تم رفضه. ",
  "filePicker.fileNotSupported": "نوع الملف غير مدعوم ",
  "filePicker.fileTooBig": " .{value} الملف كبير جدا . الحد الاقصى",

  "redeemOffer.title": "إستهلاك العروض",
  "redeemOffer.offerCode": "كود العرض",
  "redeemOffer.rab7Number": "رقم Rab7 ",
  "redeemOffer.selectBranch": "اختر المتجر",
  "redeemOffer.submit": "اتمام",
  "redeemOffer.promptTitleRedeemCode": "استهلاك العرض",
  "redeemOffer.promptTitleError": "خطأ",
  "redeemOffer.promptTitleSuccess": "تم استهلاك العرض",
  "redeemOffer.promptMessageError": "رسالة خطأ",
  "redeemOffer.promptMessageSuccess": "تم استهلاك العرض بنجاح",
  "redeemOffer.redeem": "استهلاك",
  "redeemOffer.redeemOfferFailed": "لم يتم استهلاك العرض",
  "redeemOffer.validation.selectBranch": "اختر المتجر",
  "redeemOffer.validation.enterCorrectCode": "ادخل كود العرض الصحيح",
  "redeemOffer.validation.enterCorrectRab7Number": "ادخل رقم Rab7 الصحيح",
  "redeemOffer.reset": "اعادة ضبط",
  "redeemOffer.offer": "العرض",
  "redeemOffer.codeColonValue": "الكود: {value}",
  "generic.agoDay": "{count} يوم",
  "generic.agoDays": "{count} يوم",
  "generic.agoHour": "{count} ساعة",
  "generic.agoHours": "{count} ساعة",
  "generic.agoMinute": "{count} دقيقة",
  "generic.agoMinutes": "{count} دقيقة",
  "generic.agoMonth": "{count} شهر",
  "generic.agoMonths": "{count} شهر",
  "generic.agoSecond": "{count} ثانية",
  "generic.agoSeconds": "{count} ثانية",
  "generic.agoYear": "{count} سنة",
  "generic.agoYears": "سنة {count}",
  "generic.ended": "أنتهت",
  "generic.startingInTime": "بيبدا بعد ",
  "generic.endingInTime": "بينتهي بعد ",
  "home.welcomeComma": "مرحبا , ",
  "menu.discover": "اكتشف",
  "menu.campaigns": "الحملات",
  "menu.myRewards": "المكافئات",
  "menu.marketingCampaigns": "التسويق و الترويج",
  "menu.loyaltyCampaigns": "برنامج ولاء العملاء",
  "menu.educationCampaigns": "التعليم",
  "menu.trainingCampaigns": "التدريب",
  "menu.socialCampaigns": "شخصي",
  "menu.influencerCampaigns": "المؤثرون",
  "menu.profile": "الملف الشخصي",
  "menu.settings": "الإعدادات",
  "profile.updated": "تم التحديث",
  "profile.logoutQ": "تسجيل الخروج ؟",
  "filePicker.resizeImage": "تحديد الصورة",
  "generic.addNew": "إضافة جديد",
  "generic.manageCampaign": "إدارة الحملات",
  "generic.addMarketingCampaign": "أضافة حملة جديدة",
  "generic.manageMarketingCampaign": "إدارة الحملة",
  "generic.addLoyaltyCampaign": "أضافة حملة جديدة",
  "generic.manageLoyaltyCampaign": "إدارة الحملة",
  "generic.addEducationCampaign": "أضافة حملة جديدة",
  "generic.manageEducationCampaign":"إدارة الحملة",
  "generic.addTrainingCampaign": "أضافة حملة جديدة",
  "generic.manageTrainingCampaign": "إدارة الحملة",
  "generic.addSocialCampaign": "أضافة حملة جديدة",
  "generic.manageSocialCampaign": "إدارة الحملة",
  "generic.addInfluencerCampaign": "أضافة حملة جديدة",
  "generic.manageInfluencerCampaign": "إدارة الحملة",
  "generic.marketingCampaigns": "التسويق",
  "generic.loyaltyCampaigns": "برنامج ولاء العملاء",
  "generic.educationCampaigns": "التعليم",
  "generic.trainingCampaigns": "التدريب",
  "generic.socialCampaigns": "شخصي",
  "generic.influencerCampaigns": "المؤثرون",
  "generic.addOffer": "أضف مكافئة",
  "generic.manageOffer": "إدارة المكافئة",
  "list.noDataFound": "البيانات غير متوفرة",
  "list.noMoreDataFound": "لا يوجد بيانات متوفرة",
  "list.noMoreCampaignsFound": "لا توجد حملات الان",
  "list.noRewardsFound": "لم تقم باضافة مكافئات بعد",
  "list.noMoreRewardsFound": "لا يوجد مكافئات بعد",
  "tooltip.action.more": "أجراءات اكثر",
  "tooltip.action.edit": "تعديل",
  "profile.image": "صورة الملف الشخصي",
  "campaign.active": "نشطة",
  "campaign.inactive": "معطلة",
  "campaign.paused": "موقفة",
  "campaign.viewsOver": "تجاوز حد المشاهدات",
  "campaign.noPlan": "لا توجد باقة",
  "campaign.suspended": "منتهية",
  "campaign.status": "الحالة",
  "campaign.views": "المشاهدات",
  "campaign.plays": "التفاعلات",
  "campaign.start": "البداية",
  "campaign.end": "النهاية",
  "campaign.consumes": "الاستهلاك",
  "campaign.actions": "التفاعلات",
  "campaign.delete": "حذف",
  "campaign.edit": "تعديل",
  "campaign.activate": "تفعيل",
  "campaign.deactivate": "تعطيل",
  "campaign.linkCopied": "تم نسخ رابط المشاركة",
  "campaign.deactivateCampaignWithId": "تعطيل  \"{value}\"",
  "campaign.activateCampaignWithId": "تفعيل \"{value}\"",
  "campaign.deleteCampaignWithId": "حذف \"{value}\"",
  "campaign.deleteCampaignMsg": "تنبيه : لايمكن استعادة الحملة بعد حذفها .",
  "campaign.campaignDeactivatedWithId": "تم تعطيل حملة",
  "campaign.campaignActivatedWithId": "تم تفعيل حملة",
  "campaign.campaignDeletedWithId": "تم حذف حملة",
  "campaign.deactivateRewardWithId": "تعطيل المكافئة \"{value}",
  "campaign.activateRewardWithId": "تفعيل المكافئة \"{value}\"",
  "campaign.deleteRewardWithId": "حذف المكافئة \"{value}\"",
  "campaign.deleteRewardMsg": "تنبيه : لايمكن استعادة المكافئة بعد حذفها .",
  "campaign.rewardDeactivatedWithId": "تم تعطيل المكافئة",
  "campaign.rewardActivatedWithId": "تم تفعيل المكافئة",
  "campaign.rewardDeletedWithId": "تم حذف المكافئة",
  "action.deactivate": "تعطيل",
  "action.activate": "تفعيل",
  "action.delete": "حذف",
  "campaign.selectUsageType": "فضلا , اختر هدفك من الحملة :",
  "campaign.selectRewardType": "إختر نوع المكافئة",
  "manage.previous": "السابق",
  "manage.next": "متابعة",
  "manage.continue": "متابعة",
  "manage.update": "تحديث",
  "manage.create": "إنشاء",
  "campaign.tabCampaignGoal": "الهدف",
  "campaign.tabFunActivityLibrary": "الترفيه",
  "campaign.tabRewardLibrary": "المكافئة",
  "campaign.tabDetails": "التفاصيل",
  "campaign.tabCustomization": "التخصيص",
  "campaign.tabQuestionOptions": "السؤال",
  "campaign.tabQuestions": "الأسئلة",
  "campaign.tabTargeting": "الاستهداف",
  "campaign.tabReward": "المكافئات",
  "campaign.tabAdvertisement": "الإعلان",
  "campaign.tabVouchers": "القسائم",
  "campaign.tabPreview": "استعراض",
  "campaign.tabPlans": "الباقة",
  "campaign.tabCTA": "ازرار التوجيه",
  "campaign.tabCharge": "الشحن",
  "campaign.tabRecharge": "اعادة الشحن",
  "reward.tabDetails": "التفاصيل",
  "reward.tabVouchers": "القسائم",
  "activity.manage.image": "الصورة",
  "activity.manage.video": "الفيديو",
  "activity.manage.title": "العنوان",
  "activity.manage.type": "النوع",
  "activity.manage.startDate": "تاريخ البداية",
  "activity.manage.endDate": "تاريخ النهاية",
  "activity.manage.description": "التفاصيل",
  "activity.manage.startDateAfterCurrentDate": "يجب ان يكون تاريخ البداية بعد تاريخ اليوم",
  "activity.manage.endDateAfterStartDate": "يجب ان يكون تاريخ النهاية بعد تاريخ البداية",
  "activity.manage.feedbackAbout": "موضوع الرأي",
  "activity.manage.opinionImage": "الصورة",
  "activity.manage.guessImage": "الصورة",
  "activity.manage.questionImage": "صورة السؤال",
  "activity.manage.questionTitle": "عنوان السؤال",
  "activity.manage.guessTitle": "عنوان التخمين",
  "activity.manage.questionOption": "الخيار {value}",
  "activity.manage.addQuestionOption": "أضف خيار",
  "activity.manage.addAnotherOption": "أضف خيار أخر",
  "activity.manage.rightOption": "رقم الخيار الصحيح",
  "activity.manage.hintRightOption": "# اضف الخيار الصحيح بين  {value1} و {value2}",
  "activity.manage.correctRightOption": "الخيار الصحيح بين رقم  {value1} و {value2}",
  "activity.manage.subUserDemographic": "الاستهداف الديموغرافي",
  "activity.manage.subUserGeographic": "الاستهداف الجغرافي",
  "activity.manage.subUserActions": "التفاعل",
  "activity.manage.topHintCustomization": "شاهد الفيديو وقم بتخصيص الحملة كما تريد ",
  "activity.manage.subSpecificUsers": "استهداف خاص",
  "activity.manage.topHintTargetingCampaign": "حدد المستخدمين بناءً على التركيبة الديموغرافية والجغرافية والإجراءات التي تريد منحهم حق الوصول إلى هذه الحملة. اتركه فارغا لاستهداف هذا لجميع المستخدمين.",
  "activity.manage.topHintLoyaltyRewards": "أدخل نقاط الولاء التي تريد منحها للفائزين وقم بوضع المكافآت التي يمكنهم استبدالها باستخدام تلك النقاط.",
  "activity.manage.topHintEventRewards": "أدخل المكافآت التي تريد منحها للمشاركين.",
  "activity.manage.topHintEventLoyaltyRewards": "أدخل نقاط الولاء التي تريد منحها للمشاركين وقم بوضع المكافآت التي يمكنهم استبدالها باستخدام تلك النقاط.",
  "activity.manage.valueFrom": "{value} من",
  "activity.manage.valueTo": "{value} الى",
  "activity.manage.sectionHintAdBudget": "قم بتحديد المدة الزمنية للحملة و عدد المشاهدات الكلي وعدد المشاهدات اليومية",
  "activity.manage.sectionHintAdBudgetExtend": "حدد المدة و عدد المشاهدات وفقًا لمتطلباتك وقم باكمال الدفع لتمديد الحملة فورا.",
  "activity.manage.autoRestartDaily": "يومي",
  "activity.manage.autoRestart3Days": "كل ثلاثة ايام",
  "activity.manage.autoRestart7Days": "كل اسبوع",
  "activity.manage.autoRestart10Days": "كل 10 ايام",
  "activity.manage.predictionTitle": "صف ما يجب توقعه",
  "activity.manage.predictionPlaceholder1": "عنوان الخيار الأول",
  "activity.manage.predictionPlaceholder2": "عنوان الخيار الثاني",
  "activity.manage.prediction1Image": "صورة الخيار الأول",
  "activity.manage.prediction2Image": "صورة الخيار الثاني",
  "activity.manage.predictionAnswer1": "جواب الخيار الأول",
  "activity.manage.predictionAnswer2": "جواب الخيار الثاني",
  "activity.manage.identifyLocationTitle": "اوصف المكان المراد تحديد موقعه",
  "activity.manage.identifyLocationLatitude": "دائرة العرض للموقع",
  "activity.manage.identifyLocationLongitude": "حط الطول للموقع",
  "activity.manage.participantsLimit": "الحد الاعلى للمشاركين",
  "activity.manage.leaveEmptyUnlimited": "# أدخل حد المشاركة ، اتركه فارغًا لتعيين غير محدود",
  "activity.manage.contactNumber": "رقم التواصل",
  "activity.manage.identifyLocationImage": "صورة الموقع",
  "activity.manage.contentTypes": "نوع المحتوى",
  "activity.manage.contentTypeText": "نص",
  "activity.manage.contentTypeImage": "صورة",
  "activity.manage.contentTypeAudio": "صوت",
  "activity.manage.contentTypeVideo": "فديو",
  "activity.manage.videoFromGallery": "السماح بفيديوات من الاستوديو",
  "activity.manage.cardPlaceholderImage": "صورة الغلاف",
  "activity.manage.sharePlaceholderImage": "صورة المشاركة",
  "activity.manage.sharePlaceholderText": "نص المشاركة",
  "activity.validation.optionRequired": "الخيار مطلوب",
  "activity.manage.puzzleImage": "صورة البزل",
  "activity.manage.placeholderImageDescription": "صورة الغلاف",
  "activity.manage.placeholderImagePrediction1": "صورة الخيار الأول",
  "activity.manage.placeholderImagePrediction2": "صورة الخيار الثاني",
  "activity.manage.imageToBeDiscovered": "الصورة المراد كشفها",
  "activity.manage.locationAnswer": "إختر الموقع الصحيح",
  "activity.manage.hintLocationAnswer": "# حدد الموقع الصحيح في الخريطة بناء على المطلوب سابقا",
  "activity.manage.moveMapToAdjust": "حرك الخريطة لضبطها",
  "activity.manage.mapRadiusMeterB": "القطر: <b>{value} متر</b>",
  "activity.manage.mapRadiusKmsB": "القطر: <b>{value} كيلومتر</b>",
  "activity.manage.mapRadiusMeter": "القطر: {value} متر",
  "activity.manage.mapRadiusKms": "القطر: {value} كيلومتر",
  "activity.manage.placeholderTargetingSelect": "اختر من الخيارات",
  "activity.manage.placeholderTargetingSelectMobile": "أدخل رقم الهاتف المحمول بدون رمز البلد واختر من الخيار",
  "activity.manage.selectGeofence": "حدد منطقة النطاق الجغرافي",
  "activity.manage.winLimit": "عدد الفائزين",
  "activity.manage.hintWinLimit": "# اتركه فارغًا لتعيين فائزين غير محدودين",
  "activity.manage.winPoints": "اكسب نقاط الولاء",
  "activity.manage.topHintReward": "حدد المكافآت التي تريد منحها للمستخدمين ، أو اختر من المكافآت التي تم إنشاؤها بالفعل أو أضف مكافأة جديدة. يمكنك تعيين عدد الفائزين حسب حاجتك.",
  "activity.manage.winRewards": "مككافئات الفائزين",
  "activity.manage.selectFuns": "البحث واختيار المكافآت",
  "activity.manage.selectorAddRewards": "# اختر من المكافآت الحالية أو أضف مكافأة جديدة",
  "activity.manage.sectionAddReward": "أضف مكافئة جديدة",
  "activity.manage.offerTitle": "عنوان العرض",
  "activity.manage.offerImage": "صورة العرض",
  "activity.manage.voucherTitle": "عنوان القسيمة",
  "activity.manage.voucherImage": "صورة القسيمة",
  "activity.manage.cancelReward": "إلغاء",
  "activity.manage.saveReward": "حفظ المكافئة",
  "activity.manage.saveRewardError": "احفظ المكافأة الجديدة أو قم بإلغائها للمتابعة",
  "activity.manage.selectRewardType": "اختر نوع المكافئة",
  "activity.manage.sectionTotalWinLimit": "إجمالي عدد الفائزين",
  "activity.manage.sectionHintTotalWinLimit": "يتم احتساب # فائزين طوال الحملة بما في ذلك جميع عمليات إعادة تشغيل الحملة ، اتركها فارغة لتعيين عدد غير محدود من الفائزين",
  "activity.manage.winGLimit": "إجمالي عدد الفائزين",
  "activity.manage.hintWinGLimit": "يتم احتساب # فائزين طوال الحملة بما في ذلك جميع عمليات إعادة التشغيل التلقائية ، اتركها فارغة لتعيين عدد غير محدود من الفائزين",
  "activity.manage.newRewardAdded": "تم إنشاء مكافأة جديدة!",
  "admin.activity.addCampaignQ": "إنشاء حملة؟",
  "admin.activity.addRewardQ": "إنشاء مكافأة؟",
  "admin.activity.updateCampaignQ": "تحديث الحملة؟",
  "admin.activity.updateRewardQ": "تحديث المكافأة؟",
  "activity.manage.updated": "تم تحديث الحملة!",
  "activity.manage.added": "تم إنشاء الحملة!",
  "activity.manage.rewardUpdated": "تم تحديث المكافأة!",
  "activity.manage.rewardAdded": "تم إنشاء المكافأة!",
  "activity.validation.requiredMoreThanZero": "مطلوب ، يجب أن يكون أكثر من صفر",
  "activity.validation.requiredEmptyOrMoreThanZero": "مطلوب ، يجب أن يكون فارغًا لتعيين عدد غير محدود أو أكثر من الصفر",
  "activity.manage.participationPoints": "نقاط المشاركة",
  "activity.manage.participationRewards": "مكافآت المشاركة",
  "activity.manage.created": "تم إنشاء الحملة بنجاح!",
  "activity.manage.campaignTitle": "العنوان",
  "activity.manage.rewardTitle": "العنوان",
  "activity.manage.searchPlaceOrCoordinates": "ابحث عن مكان أو إحداثيات",
  "campaign.manage.recommendedFun": "التوصيات",
  "activity.manage.addNewOffer": "إضافة عرض",
  "activity.manage.addNewVoucher": "أضف قسيمة",
  "activity.manage.noRewards": "لا يوجد مكافئات",
  "activity.manage.noRewardsM": "حدد ما إذا كنت لا تريد مكافأة المستخدم",
  "activity.manage.addNewReward": "أضف مكافأة جديدة",
  "activity.manage.addNewRewardM": "أضف عرضًا أو قسيمة جديدة",
  "activity.manage.selectRewardsM": "بحث واختيار العرض أو القسيمة الحالية",
  "activity.manage.addPoints": "أضف نقاط المكافآت",
  "activity.manage.rewardPoints": "نقاط مكافأة",
  "activity.manage.addPointsM": "حدد نقاط الولاء وأضفها للمكافأة",
  "activity.manage.sectionRewards": "قائمة المكافآت",
  "activity.manage.selectReward": "حدد المكافأة",
  "activity.manage.searchNSelectReward": "بحث واختيار مكافأة",
  "activity.manage.atleastOneRewardNeeded": "إضافة مكافأة للمتابعة",
  "activity.manage.noRewardAdded": "لا توجد مكافآت مضافة حتى الآن",
  "activity.manage.hintAddRewardsToList": "# أضف مكافآت باستخدام الخيارات أعلاه ، أي اختر من المكافآت الحالية أو أضف مكافأة جديدة",
  "activity.manage.rewardPointsWithValue": "نقاط ولاء المكافآت: {value}",
  "activity.manage.downloadQR": "تحميل الـ QR",
  "activity.manage.copyLink": "نسخ الرابط",
  "activity.manage.directLink": "رابط الوصول المباشر",
  "activity.manage.normalLink": "رابط المشاركة العادي",
  "activity.manage.directLinkCopied": "تم نسخ رابط الوصول المباشر",
  "activity.manage.directQRCopied": "بدأ تنزيل QR للوصول المباشر",
  "activity.manage.normalLinkCopied": "تم نسخ رابط المشاركة العادي",
  "activity.manage.normalQRCopied": "بدأ تنزيل QR العادي",
  "activity.manage.close": "إغلاق",
  "activity.manage.rewardSelected": "تم تحديد المكافأة",
  "activity.manage.rewardAddedSnack": "تمت إضافة المكافأة",
  "activity.manage.addCode": "أضف كود",
  "activity.manage.enterVoucherCode": "أدخل رمز القسيمة",
  "activity.manage.atleastOneVocuherNeeded": "أضف رمز قسيمة واحد على الأقل للمتابعة",
  "activity.manage.noVoucherCodeAdded": "لم يتم إضافة رموز قسيمة حتى الآن",
  "activity.manage.hintAddVoucherToList": "# إضافة رمز القسيمة باستخدام الحقل أعلاه",
  "activity.manage.vouchersCodes": "رموز القسيمة",
  "activity.manage.vouchersList": "قائمة القسائم",
  "activity.manage.redeemedVouchersList": "قائمة القسائم المستهلكة",
  "activity.manage.vouchers": "Vouchers",
  "activity.manage.campaignIdWithValue": "رقم الحملة  #{value}",
  "activity.manage.referenceCustomization": "مرجع التخصيص",
  "home.gettingStarted": "ابدء",
  "home.gettingStartedM": "لا توجد حملات متاحة ، حدد هدفك وأضف حملة جديدة",
  "activity.manage.actionButtonTitle": "نص التوجيه",
  "activity.manage.actionButtonLink": "رابط التوجيه",
  "generic.retry": "أعد المحاولة",
  "activity.manage.subscriptionPlans": "الباقات",
  "activity.manage.selectPlan": "إختر الباقة المناسبة",
  "activity.plan.viewsCount": "عدد المشاهدات",
  "activity.plan.viewsCountPerDay": "عدد المشاهدات لكل يوم",
  "activity.plan.duration": "المدة الزمنية",
  "activity.plan.durationDay": "{value} يوم",
  "activity.plan.durationDays": "{value} يوم",
  "activity.plan.durationUptoYear": "تصل إلى سنة",
  "activity.plan.durationUptoDays": "تصل لغاية {value} يوم",
  "activity.plan.uptoValue": "تصل لغاية  {value}",
  "activity.plan.priceFree": "مجاناً",
  "activity.plan.unlimited": "غير محدودة",
  "activity.plan.flexible": "مرنة",
  "activity.plan.campaigns": "الحملات",
  "activity.plan.onlyWithValue": "فقط {value}",
  "activity.plan.buttonFree": "أبدأ [متوفر {value} فقط]",
  "activity.plan.buttonFreeLimitExhausted": "المجانية [تم استخدام جميع المحاولات]",
  "activity.plan.buttonFreeOver": "استخدام الباقة المميزة",
  "activity.plan.buttonContinuePlanWithValue": "متابعة {value}",
  "activity.plan.choosePlanWithValue": "تبدأ بـ {value1} {value2}",
  "activity.plan.userTargeting": "الاستهداف الذكي",
  "activity.plan.yes": "متوفر",
  "activity.plan.no": "غير متوفر",
  "activity.plan.rab7AppDisplay": "التواجد بتطبيق Rab7",
  "activity.plan.price": "السعر",
  "activity.plan.priceFromValue": "تبدأ بـ {value1} {value2}",
  "activity.plan.cta1": "ازرار التوجيه الرئيسية",
  "activity.plan.cta2": "ازرار التوجيه الختامية",
  "activity.plan.rechargeFree": "اعادة شحن الرصيد المجاني",
  "activity.plan.analytics": "تحليل البيانات",
  "activity.plan.availableViews": "المشاهدات المتوفرة",
  "activity.plan.totalViews": "مجموع المشاهدات",
  "activity.plan.dailyViews": "المشاهدات اليومية",
  "activity.plan.ended": "انتهت الباقة",
  "activity.plan.endingInTime": "تنتهي الباقة في ",
  "activity.plan.campaignPlan": "باقة الحملة",
  "activity.plan.campaignPlanC": "باقة الحملة : ",
  "activity.plan.dateNoSet": "غير متوفرة",
  "activity.manage.viewCampaignPlan": "تفاصيل الباقة",
  "activity.manage.switchToPaidPlan": "ترقية الباقة",
  "activity.manage.subscribeToPlan": "الاشتراك بالباقة",
  "activity.manage.tooltipExtend": "تمديد المشاهدات و المدة الزمنية للباقاة",
  "activity.manage.actionExtend": "تمديد",
  "activity.manage.tooltipSubscribe": "اشترك بالباقة",
  "activity.manage.actionSubscribe": "اشترك",
  "activity.manage.tooltipRenew": "تجديد الباقة",
  "activity.manage.actionRenew": "تجديد",
  "activity.manage.tooltipUpgrade": "ترقية للباقة المميزة",
  "activity.manage.actionUpgrade": "ترقية",
  "activity.manage.actionExtendL": "تمديد الباقة الحالية",
  "activity.manage.actionSubscribeL": "اشتراق بالباقة المميزة",
  "activity.manage.actionRenewL": "تجديد الباقة",
  "activity.manage.actionUpgradeL": "ترقية الباقة",
  "activity.manage.cta1Title": "زر التوجيه الرئيسي",
  "activity.manage.hintCta1Link": " ",
  "activity.manage.cta2Title": "زر التوجيه الختامي",
  "activity.manage.cta2Video": "فيديو التوجيه الختامي",
  "activity.manage.addLocation": "اختر الموقع",
  "activity.manage.removeLocation": "احذف الموقع",
  "activity.manage.addGeofence": "اختر النطاق الجغرافي",
  "activity.manage.removeGeofence": "حذف النطاق الجغافي",
  "activity.manage.proceedPaymentQ": "متابعة الدفع؟",
  "activity.manage.proceedPaymentMessage": "سيتم نقلك إلى بوابة الدفع لمعالجة الدفع. بمجرد معالجة الدفع سيتم تنشيط الحملة ",
  "activity.manage.proceedPaymentExtendQ": "متابعة الدفع للتمديد؟",
  "activity.manage.proceedPaymentExtendMessage": "سيتم نقلك إلى بوابة الدفع لمعالجة الدفع. بمجرد معالجة الدفع سيتم تمديد الحملة ",
  "activity.manage.proceedPaymentRenewQ": "متابعة الدفع للتجديد؟",
  "activity.manage.proceedPaymentRenewMessage": "سيتم نقلك إلى بوابة الدفع لمعالجة الدفع. بمجرد معالجة الدفع سيتم تجديد الحملة ",
  "activity.manage.payWithValue": "دفع {value1} {value2}",
  "activity.manage.payment": "المدفوعات",
  "activity.manage.cancelPaymentQ": "رجوع",
  "activity.manage.cancelPayment": "رجوع",
  "activity.manage.cancelPaymentMessage": "سيؤدي إلغاء الدفع إلى إيقاف تشغيل حملتك. إذا لم تتمكن من معالجة الدفع الآن ، يمكنك معالجة الدفع لاحقًا من قائمة الحملات لإطلاق الحملة. هل أنت متأكد أنك تريد إلغاء الدفع؟",
  "activity.manage.duration": "المدة الزمنية (متوقفة)",
  "activity.manage.viewsCount": "عدد المشاهدات",
  "activity.manage.dailyViewsCount": "عدد المشاهدات اليومية",
  "activity.manage.shareLink": "مشاركة الرابط",
  "activity.plan.planActiveNoModify": "باقتك  الحالية نشطة ولا يمكن تعديلها. يُسمح لك فقط بتحديث الباقة بمجرد انتهائها.",
  "activity.manage.viewLimitOver": "تم استهلاك الحد الاعلى للمشاهدات في باقتك",
  "activity.manage.upgradeToIncreaseViews": "قم بترقية الباقة للحصول على حد اعلى للمشاهدات",
  "activity.manage.expandToIncreaseViews": "قم بترقية الباقة للحصول على حد اعلى للمشاهدات",
  "activity.manage.renewToIncreaseViews": "قم يتجديد الباقة للابقاء عليها نشطة والسماح للمستخدمين للوصول اليها",
  "activity.manage.loyaltyRewardFee": "نقاط الولاء لاسترداد هذه المكافأة",
  "home.accountSuspendedMessage": "تم تعليق حسابك.",
  "activity.usageType.branding": "التسويق",
  "activity.usageType.loyalty": "برنامج الولاء",
  "activity.usageType.employee": "التدريب",
  "activity.usageType.school": "تعليم",
  "activity.usageType.party": "عائلي",
  "activity.usageType.influencer": "المؤثرون",
  "activity.usageType.dbranding": "اجعل علامتك التجارية , منتجك او خدماتك في الصورة الذهنية لعملائك لتحظى بقرار الشراء لديهم",
  "activity.usageType.dloyalty": "انشئ صلة وثيقة بين منتجطك و عملائك من خلال برنامج ولاء تفاعلي وكافئ الاكثر تفاعلا بنظام النقاط",
  "activity.usageType.demployee": "طور اداء الموظفين و حصيلتهم المعرفية من خلال التدريب بنظام الترفيه التفاعلي وقس اداءهم",
  "activity.usageType.dschool": "اجعل التعليم ممتعاً واكثر تفاعلاً للطلاب وقم بقياس اداءهم",
  "activity.usageType.dparty": "اصنع الابتسامة والحماس بجو ترفيهي بين الاهل والاصدقاء في الجمعات  الحفلات",
  "activity.usageType.dinfluencer": "زد من عدد متباعينك وتفاعلهم معك بترفيه تفاعلي يزيد من انتشارك في منصات التواصل الاجتماعي",
  "campaign.manage.spinFun": "سبينر",
  "campaign.manage.challengeFun": "التحديات",
  "campaign.manage.smartFun": "الحرفنة",
  "campaign.manage.cardFun": "الكروت",
  "campaign.manage.investigateFun": "الإستقصاء",
  "campaign.manage.informativeFun": "الإعلام",
  "campaign.manage.guessFun": "التخمين",
  "campaign.manage.otherFun": "أخرى",
  "activity.type.offer": "مكافئة",
  "activity.type.voucher": "قسيمة",
  "activity.type.smartAd": "Smart Ad",
  "activity.manage.1.title": "Smart Ad",
  "activity.manage.1.description": "Just simply advertise your image or content, by redirecting users to follow your stuff using the link.",
  "activity.type.visitorOpinion": "رأي و تقييم",
  "activity.manage.12.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدم الذي يتوقع الاجابة الصحيحة سيفوز بالمكافآت. سيتم السحب بين الفائزين و الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.19.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدم الذي يتوقع الاجابة الصحيحة سيفوز بالمكافآت. سيتم السحب بين الفائزين و الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.28.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدم الذي يتوقع الاجابة الصحيحة سيفوز بالمكافآت. سيتم السحب بين الفائزين و الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.36.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدم الذي يتوقع الاجابة الصحيحة سيفوز بالمكافآت. سيتم السحب بين الفائزين و الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.16.winInfo": "ضع المكافآت التي تريد منحها لمن يشارك في الحدث.",
  "activity.manage.11.winInfo": "ضع مكافآت لمنشئي المحتوى لزيادة المشاركة. سيحصل المستخدمون الذين تجد محتواهم  ملائمًا و تقوم بتنزيله على المكافآت.",
  "activity.manage.21.winInfo": "ضع مكافآت لمنشئي المحتوى لزيادة المشاركة. سيحصل المستخدمون الذين تجد محتواهم  ملائمًا و تقوم بتنزيله على المكافآت.",
  "activity.manage.17.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. سيفوز المستخدمون الذين قاموا بمشاركة الروابط اكثر من غيرهم و سيتم السحب بين الفائزين و الإعلان و سيتم الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.18.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. سيفوز المستخدمون ذوو أعلى الدرجات بالمكافآت.  و سيتم السحب بين الفائزين و الإعلان و سيتم الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.13.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين تتوقف العجلة وتقف الصور المتشابه في صف واحد سيفوزون بالتحدي. سيتم الإعلان عن الفائزين على الفور.",
  "activity.manage.24.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. سيفوز المستخدمون ذوو أعلى الدرجات بالمكافآت.  و سيتم السحب بين الفائزين و الإعلان و سيتم الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.25.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. سيفوز المستخدمون ذوو أعلى الدرجات بالمكافآت.  و سيتم السحب بين الفائزين و الإعلان و سيتم الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.20.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين تتوقف العجلو بشكل مماثل للصور التي تم تركيبها من قبل المستخدم  سيفوزون بالتحدي. سيتم الإعلان عن الفائزين فورا .",
  "activity.manage.22.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين يكشفون عن الصورة المخفية تمامًا سيفوزون بالتحدي. سيتم الإعلان عن الفائزين على الفور.",
  "activity.manage.15.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين يحصلون على صور عند قبل البطاقات دون الوقوع في البطاقة المميتة سيفوزون بالتحدي. سيتم الإعلان عن الفائزين على الفور.",
  "activity.manage.23.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين يجدون الصورة المخفية في محاولات معينة سيفوزون بالتحدي. سيتم الإعلان عن الفائزين على الفور.",
  "activity.manage.26.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين يطابقون جميع الأزواج في محاولات معينة سيفوزون بالتحدي. سيتم الإعلان عن الفائزين على الفور.",
  "activity.manage.27.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين يفوزون بجولات أكثر من الروبوت سيفوزون بالتحدي. سيتم الإعلان عن الفائزين على الفور.",
  "activity.manage.29.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الحاصلون على أعلى الدرجات سيفوزون بالتحدي. سيتم الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.31.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الحاصلون على أعلى الدرجات سيفوزون بالتحدي. سيتم الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.32.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين ينهون السباق في عدد الدورات المعينة سيفوزون بالتحدي. سيتم الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.33.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين يفوزون بجولات أكثر من الروبوت سيفوزون بالتحدي. سيتم الإعلان عن الفائزين على الفور.",
  "activity.manage.34.winInfo": "ضع مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين يختارون أقرب إحداثيات من الخريطة سيفوزون بالتحدي. سيتم الإعلان عن الفائزين في نهاية الحملة.",
  "activity.manage.30.winInfo": "مكافآت للفائزين لزيادة المشاركة. المستخدمون الذين يحلون اللغز سيفوزون بالتحدي. سيتم الإعلان عن الفائزين على الفور.",
  "generic.language": "اللغة",
  "activity.manage.totalCostPerViewWithValue": "~ التكلفة لكل 1000 مشاهدة: {value1} {value2}",
  "activity.manage.selectRewards": "حدد من المكافأت الحالية",
  "activity.manage.placeholderTargetingSelectSearch": "البحث من الخيارات",
  "activity.manage.brandPlaceholderText": "نص العلامة التجارية",
  "activity.manage.vs": "VS",
  "activity.manage.scoreValuePlaceholder": "نص عنصر التجميع",
  "activity.manage.helpResetGameDays": "ⓘ ستتم إعادة تشغيل الحملة مرة أخرى بعد الأيام المحددة ، اتركها فارغة لتعيينها لعدم البدء من جديد.",
  "activity.manage.resetGameDays": "أيام إعادة التشغيل التلقائي",
  "activity.manage.hintEnterVoucherCode": "أدخل رمز قسيمة وانقر فوق الزر \"إضافة رمز\" للإضافة ، كرر نفس الشيء لإضافة رموز قسيمة متعددة",
  "activity.manage.hintLoyaltyRewards2": "أدخل مكافآت الولاء التي يمكن للمستخدمين استبدالها باستهلاك نقاط الولاء المكتسبة.",
  "activity.requiredLoyaltyPointsToRedeem": "أدخل نقاط الولاء المطلوبة لاسترداد المكافأة",
  "activity.manage.atleastOneLoyaltyRewardNeeded": "اختر مكافأة ولاء واحدة على الأقل للمضي قدمًا ، أو حدد صفرًا من نقاط الولاء لكسب الفائزين",
  "activity.winPoints": "نقاط الولاء للفائزين",
  "activity.eventPoints": "نقاط الولاء لمجرد المشاركة ",
  "activity.hintLoyaltyPointsParticipants": "# أدخل نقاط الولاء التي تريد منحها للمستخدمين لمجرد مشاركتهم ، ضع 0 إذا كنت لا تريد منح نقاط الولاء",
  "activity.addLoyaltyOffer": "أضف مكافئة",
  "activity.manageLoyaltyOffer": "إدارة المكافئة",
  "activity.campaignDetails": "التفاصيل",
  "activity.campaignTitle": "عنوان الحملة",
  "activity.rewardTitle": "عنوان المكافئة",
  "activity.rewardDetails": "تفاصيل المكافئة",
  "activity.eventTitle": "عنوان الإيڤينت",
  "activity.eventDetails": "اشرح تفاصيل الإيڤينت",
  "activity.opinionTitle": "عن ماذا تريد ان يعلق المستخدم ؟",
  "activity.contentTitle": "ماهو المحتوى الذي تريد المستخدمين ان يشاركوا به ؟",
  "activity.questionTitle": "ضع سؤالك للجمهور هنا",
  "activity.questionImageDesc": "ضع صورة تساعد في فهم سوالك للمستخدمين",
  "activity.correctAnswer": "الجواب الصحيح",
  "activity.correctAnswerHint": "# أدخل الإجابة الصحيحة ، بناءً على هذه الإجابة الصحيحة ، سيتم الإعلان عن الفائزين الذين تتطابق إجابتهم مع هذه الإجابة",
  "activity.correctLocation": "الموقع الجغرافي الصحيح",
  "activity.correctLocationHint": "# اختر الموقع الصحيح ، بناءً على هذا الموقع الصحيح ، سيتم الإعلان عن الفائزين الذين يقع موقعهم المختار بالقرب من هذا الموقع",
  "activity.locationImageDesc": "اختر صورة للمكان الذي تريد طلب إحداثياته للمستخدمين",
  "map.searchPlace": "ابحث عن مكان أو احداثيات",
  "filter.male": "ذكر",
  "filter.female": "أنثى",
  "filter.countries": "بلدان",
  "filter.regions": "المنطقة",
  "filter.cities": "المدن",
  "filter.districts": "الأحياء",
  "filter.gender": "الجنس",
  "filter.ageGroups": "الفئة العُمرية",
  "filter.nationalities": "الجنسية",
  "filter.users": "المستخدم",
  "filter.targetSelectedUsers": "استهداف المستخدمين المحددين أعلاه فقط",
  "filter.userInterests": "الإهتمامات",
  "activity.question": "نص السؤال",
  "activity.addAnotherQuestion": "اضف سؤال اخر",
  "activity.validationOptionRequired": "أضف على الاقل خيارا واحدا",
  "activity.selectRightOption": "حدد الخيار الصحيح",
  "activity.correctErrorAbove": "املأ الحقول المطلوبة أعلاه قبل إضافة سؤال جديد",
  "activity.moveUpQuestion": "حرك هذا السؤال لأعلى",
  "activity.moveDownQuestion": "حرك هذا السؤال لاسفل",
  "activity.removeQuestion": "حذف السؤال",
  "activity.expandQuestion": " توسيع هذا السؤال",
  "activity.collapseQuestion": "طي هذا السؤال",
  "activity.correctAnswersCount": "عدد الإجابات الصحيحة",
  "activity.minCorrectAnswersCount": "الحد الأدنى لعدد الإجابات الصحيحة",
  "filter.gamePlays": "عدد مرات التفاعل",
  "filter.noGamePlays": "غير متفاعل",
  "filter.gamePlaysRange": "التفاعل بين",
  "filter.actionsFrom": "من",
  "filter.actionsTo": "الى",
  "activity.hintLoyaltyPointsWinners": "# أدخل نقاط الولاء التي تريد منحها للمستخدمين عند فوزهم",
  "activity.enterNonZeroLoyaltyPoints": "يجب ان يكون عدد النقاط موجبا واكثر من صفر",
  "activity.manage.sectionAddOffer": "إضافة عرض",
  "activity.manage.sectionAddVoucher": "أضف قسيمة",
  "login.signUpSuccessMessage": "تم إنشاء حسابك، وسيتم الآن استكمال ملفك الشخصي.",
  "login.resetPassword": "إعادة تعيين كلمة المرور.",
  "login.passwordResetSuccess": "تم إعادة تعيين كلمة المرور بنجاح.",
  "login.signIn": "تسجيل الدخول.",
  "login.welcome": "مرحبا بك في Rab7",
  "login.completeProfile": "استكمال الملف الشخصي.",
  "login.completeProfileMessage": "استكمل ملفك الشخصي عن طريق إدخال التفاصيل أدناه وسنخصص تجربتك بناءً على المعلومات المقدمة.<br><strong>تحذير: إن عدم الدقة في ادخال المعلومات سيؤدي الى عدم الاهلية للحصول على المكافئات</strong><br>لن يتم عرض هذا علناً باستثناء اسمك.",
  "login.age": "العمر",
  "validation.enterName": "الرجاء إدخال اسمك.",
  "validation.selectGender": "الرجاء تحديد الجنس.",
  "validation.selectAge": "الرجاء تحديد العمر.",
  "validation.selectNationality": "الرجاء تحديد الجنسية.",
  "menu.play": "التحديات",
  "activity.manage.campaignImageDesc": "صورتك بدلاً من الصورة الافتراضية للحملة.",
  "activity.manage.rewardImageDesc": "صورتك بدلاً من الصورة الافتراضية للمكافأة.",
  "activity.preview": "المعاينة",
  "activity.previewR": "معاينة قائمة التحديات",
  "campaign.tabCTA1": "CTA الأمامي.",
  "campaign.tabCTA2": "CTA النهائي.",
  "activity.manage.cta2ImageDesc": "صورة/فيديو لعرضه بعد انتهاء التشغيل.",
  "activity.manage.cta2VideoDesc": "الفيديو الذي تريد عرضه بعد انتهاء التشغيل.",
  "login.oldPassword": "كلمة المرور القديمة.",
  "login.newPassword": "كلمة المرور الجديدة.",
  "login.confirmPassword": "تأكيد كلمة المرور.",
  "login.passwordMismatch": "يجب أن تكون كلمة المرور الجديدة وتأكيد كلمة المرور متماثلين.",
  "activity.sectionReward": "المكافأة.",
  "activity.hintAddRewardToList": "# أضف عرضًا أو قسيمة باستخدام الخيار أعلاه.",
  "activity.ctaTitle.visitNow": "تفضل بزيارة الموقع الآن.",
  "activity.ctaTitle.shopNow": "تسوق الآن.",
  "activity.ctaTitle.downloadNow": "قم بالتنزيل الآن.",
  "activity.ctaTitle.learnMore": "اعرف المزيد.",
  "activity.ctaTitle.followUs": "تابعنا الآن",
  "activity.ctaTitle.orderNow": "اطلب الان",
  "campaign.tabRecur": "التكرار.",
  "campaign.tabWinLimit": "حد الفوز.",
  "activity.noRewards": "للأسف لا توجد مكافآت متاحة الآن .",
  "activity.manage.cta1ImageDesc": "صورة بدلاً من صورة ملفك الشخصي.",
  "profile.deny": "رفض.",
  "profile.allow": "السماح.",
  "profile.later": "في وقت لاحق.",
  "profile.fetchAgain": "إعادة تحميل الصفحة.",
  "profile.askForLocation": "اسمح لـRab7 بالحصول على موقعك لتوفير تجربة سلسة وتقديم المزيد من الحملات والمكافآت المتاحة في منطقتك.",
  "profile.askForLocationAgain": "لا يمكن الحصول على موقعك لأن الموقع محظور أو مغلق، تأكد من تشغيل الموقع والسماح لـRab7 بالحصول على موقعك، ثم حاول مرة أخرى.",
  "profile.locationInProgress": "جاري الحصول على الموقع...",
  "profile.locationFetchedCorrectly": "تم الحصول على موقعك بنجاح.",
  "profile.locationDenied": "لا يمكن الحصول على موقعك لأن الموقع محظور أو مغلق. تابع ملء المعلومات أدناه.",
  "profile.locationTimeout": "يستغرق الحصول على موقعك وقتًا أطول من المعتاد، أعد الضبط وحاول مرة أخرى عن طريق ملء المعلومات أدناه.",
  "location.locationDenied": "لا يمكن الحصول على موقعك لأن الموقع محظور أو مغلق.",
  "location.locationTimeout": "يستغرق الحصول على موقعك وقتًا أطول من المعتاد.",
  "menu.rewardsWon": "المكافآت المكتسبة",
  "activity.noRewardsWon": "لا توجد مكافآت متاحة الآن",
  "tab.play": "التحديات",
  "tab.build": "الحملات",
  "location.learnHotToTurnOn": "تعلم كيفية تشغيل خدمات الموقع",
  "activity.manage.winLimitPerRecurring": "عدد الفائزين لكل تكرار",
  "manage.prev": "السابق",
  "manage.nxt": "التالي",
  "activity.plan.reward": "إضافة مكافأة",
  "activity.plan.listing": "تخصيص الإدراج",
  "activity.plan.anytime": "أي وقت",
  "activity.plan.cantChange": "لا يمكن التغيير",
  "activity.manage.directLinkDetail": "شارك الرابط لمنح الوصول لأي مستخدم، متجاوزًا استهداف المستخدم المحدد في الحملة.",
  "activity.manage.directLinkDetailFree": "شارك الرابط لمنح الوصول.",
  "activity.manage.normalLinkDetail": "شارك الرابط مع المستخدمين، والذي يمكن الوصول إليه فقط من قبل المستخدمين الذين يستوفون استهداف المستخدم المحدد في الحملة.",
  "activity.manage.manageSuccessMessage": "شارك الرابط لمنح الوصول. قم بالترقية إلى الإصدار المميز للإدراج في عالم Rab7 واستهداف المستخدمين والمزيد من الميزات.",
  "activity.manage.manageSuccessMessageNoPlan": "اختر باقة لجعلها متاحة للمستخدمين.",
  "activity.manage.placeholderTargetingSelectRab7No": "أدخل رقم Rab7 وحدد من الخيار",
  "activity.manage.geofence": "المحيط الجغرافي",
  "activity.manage.hintSelectGeofence": "# حرك الخريطة لضبط العلامة المحددة، وقم بالتكبير/التصغير لتحديد المحيط الجغرافي",
  "activity.manage.geofenceCenter": "الاحداثيات: <b></b>{latitude}, {longitude}",
  "generic.remove": "حذف",
  "generic.none": "لا أحد",
  "activity.noMoreWinners": "لا يوجد فائزين اكثر",
  "activity.noMorePlayerStats": "حالة اللاعبين",
  "activity.visitLink": "زيارة الرابط",
  "login.invalidEmail": "ادخل بريد الكتروني صحيح",
  "login.joinRab7": 'انظم لـ Rab7 الآن',
  "login.signInRab7": 'تسجيل الدخول',
  "login.or": "أو",
  "login.signUpGoogle": "التسجيل بواسطة حساب جووجل",
  "login.signInGoogle": "الدخول مع جوجل",
  "login.signUpApple": "التسجيل بواسطة حساب إبل ",
  "login.signInApple": "الدخول بواسطة حساب ابل",
  "login.forgotPassword": "نسيت كلمة المرور ؟",
  "filePicker.fileDurationLong": "مدة الملف {value} أطول من المسموح به ، يجب أن تكون أقل من {duration} ثانية.",
  "login.loginToPlay": "انضم إلى Rab7 أو قم بتسجيل الدخول إلى حسابك للمتابعة",
  "common.shareDataCopied": "تم نسخ بيانات المشاركة.",
  "activity.reportMessage": "بلغ عن الحملة إذا وجدت أنها ضارة أو خطرة أو معلومات مضللة أو بريدًا عشوائيًا وما إلى ذلك.",
  "activity.describeToReport": "سبك للإبلاغ",
  "login.registerSuccess": "مرحبًا بكم في Rab7!",
  "login.registerSuccessMessage": "حساب Rab7 الخاص بك جاهز تقريبًا.<br/><br/>المتابعة الآن لاستكمال ملفك الشخصي.",
  "login.accountActivated": "تم تنشيط حسابك.",
  "login.emailUnverified": "غير مؤكد",
  "login.resendVerificationLink": "أعد إرسال رابط التحقق",
  "login.resendVerificationLinkFailed": "فشل إعادة إرسال رابط التحقق",
  "login.resendVerificationLinkSuccess": "تم إرسال رابط التحقق إلى البريد الإلكتروني المسجل.",
  "login.accountActivationPending": "حالة تنشيط الحساب معلقة",
  "login.accountActivationPendingMessage": "لم تقم بالتحقق من عنوان بريدك الإلكتروني حتى الآن. افتح رابط التحقق المرسل إلى بريدك الإلكتروني المسجل أو أعد الإرسال مرة أخرى.",
  "activity.youWonGame": "لقد فزت بالتحدي",
  "login.openRab7": "افتح Rab7",
  "campaign.tabReferral": "الإحالة",
  "campaign.tabReview": "مراجعة",
  "activity.helpTabReferral": "أضف مكافأة للشركاء الذين فاز رابطهم المشترك بالتحدي.",
  "activity.previewF": "المعاينة النهائية",
  "activity.showAsLinkOnlyMessage": "هل تريد أن تكون الحملة متاحة عبر روابط المشاركة فقط ، أي لا يتم عرضها في قائمة تشغيل Rab7؟",
  "activity.subUserInterests": "اهتمامات المستخدم",
  "activity.howToRedeemOffer": "كيفية استرداد العرض",
  "activity.howToRedeemOfferStoreMessage": "اطلب من العميل إظهار رمز الاستجابة السريعة للعرض.<br><br>افتح تطبيق الكاميرا على هاتفك المحمول وامسح رمز الاستجابة السريعة.<br><br>تحقق من صحة المجال الذي يجب أن يكون <b>RAB7.COM</b> وتفاصيل العرض وتوافره ، ومن ثم المضي قدمًا في استرداد العرض.",
  "activity.guideSlot": "احصل على صور متطابقة في الصف المحدد واربح التحدي.",
  "activity.noRewardsProvided": "للأسف لم يقم الراعي الرسمي بالحملة باضافة مكافئات",
  "activity.plan.voucher": "أضف قسيمة",
  "activity.plan.date": "تحكم في تاريخ البدء",
  "activity.plan.changeDefaultImages": "تغيير الصور الافتراضية",
  "location.mapMovedToCurrentLocation": "تم نقل الخريطة إلى الموقع الحالي",
  "activity.notTargetedToUser": "غير متوفرة الان",
  "activity.notExist": "غير موجودة",
  "activity.manage.16.winInfoP": "",
  "activity.manage.11.winInfoP": "من سيتم إخطاره في نهاية الحملة، وعندما تقوم بتنزيل محتواها.",
  "activity.manage.21.winInfoP": "من سيتم مكافأته عند تنزيل محتواه.",
  "activity.manage.13.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.20.winInfoP": "من سيتم إخطاره على الفور.",
  "activity.manage.22.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.23.winInfoP": "من سيتم إخطاره على الفور.",
  "activity.manage.26.winInfoP": "من سيتم إخطاره على الفور.",
  "activity.manage.27.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.33.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.30.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.4.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.12.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.15.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.17.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.18.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.19.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.24.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.25.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.28.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.36.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.29.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.31.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.32.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.34.winInfoP": "من سيتم إخطاره في نهاية الحملة.",
  "activity.manage.shareLinkCopied": "تم نسخ رابط المشاركة",
  "activity.showOtherCampaigns": "إظهار الحملات الأخرى",
  "location.refresh": "تحديث",
  "activity.locationDenied": "قم بتشغيل الموقع لرؤية المزيد من الحملات.",
  "activity.locationTimeout": "الموقع غير متاح. حاول مرة أخرى لرؤية المزيد من الحملات.",
  "installApp": "تثبيت تطبيق Rab7؟",
  "install": "تثبيت",
  "campaign.tabDefaults": "الافتراضيات",
  "activity.gameDefaultImages": "صور التحدي الافتراضية",
  "activity.manage.topHintRewards": "تعيين مكافآت للفائزين",
  "activity.tabDefaultHint": "استبدل صور التحدي ال",
  "activity.tabTargetHint": "استهدف المستخدمين حسب التركيبة السكانية والموقع والسلوك، أو اتركها فارغة لاستهداف جميع المستخدمين.",
  "activity.tabPlanHint": "للحصول على الحملات الأكثر تأثيرًا وتفاعلاً، اختر Premium.",
  "activity.tabRecurHint": "اجعل الحملة قابلة لإعادة الاستخدام للمستخدمين بعد فترة لإعادة إشراكهم، أو اجعلها حدثًا لمرة واحدة.",
  "activity.tabWinHint": "حدد حد الفائزين.",
  "activity.tabReviewHint": "قم بمراجعة الحملة واختر كيف تريد رؤيتها: بالرابط فقط، أو بالرابط وفي قائمة لعب Rab7.",
  "activity.publicAccess": "عامة",
  "activity.linkOnlyAccess": "بالرابط فقط",
  "activity.publicAccessHint": "ستجعل المستخدم المستهدف يرى الحملة في قائمة التحدي، والوصول إلى جمهور واسع.",
  "activity.linkOnlyAccessHint": "ستجعل المستخدم يرى الحملة فقط من خلال الرابط، ويحافظ عليها خاصة.",
  "activity.tabPlayTriesHint": "تعيين عدد محاولات اللفات وإعادة تشغيل التحدي.",
  "activity.gamesCount": "عدد مرات إعادة تشغيل التحدي",
  "activity.spinsPerGame": "محاولات الدوران",
  "activity.tabReferralHint": "مكافئة ناشر الحملة بنفس الجائزة اذا فاز احد عن طريق مشاركته.",
  "activity.winProbability": "إحتمالية الفوز {value}%",
  "activity.manage.payNow": "توجه للدفع",
  "activity.manage.totalCost": "السعر الكلي:",
  "activity.manage.subUserGeofence": "النطاق الجغرافي",
  "activity.openLink": "افتح",
  "activity.validation.requiredValueOrMore": "أو اكثر {value} حقل متطلب , يجب ان يكون بـ",
  "activity.questionnaireCorrectAnswerPrompt": "الإجابة المحددة غير صحيحة، اختر الإجابة الصحيحة.",
  "activity.hintOfferValidity": "صلاحية المكافأة بالأيام بعد انتهاء الحملة المرتبطة",
  "activity.offerValidity": "صلاحية المكافأة",
  "activity.mute": "كتم",
  "activity.unmute": "صوت",
  "activity.tabQuestionsHint": "أضف أسئلتك",
  "activity.tabCustomizeAudioHint": "استبدل صوت الحملة بصوت من اختيارك",
  "campaign.declareWinners": "إعلان الفائزين",
  "campaign.declareWinnersQ": "هل تريد إعلان الفائزين؟",
  "campaign.winnersDeclared": "تم إعلان الفائزين بنجاح",
  "activity.showAnswerDistribution": "عرض توزيع الإجابات",
  "activity.showCheckRightOption": "تصحيح الإجابات",
  "activity.tabGamePlayQuestionHint": "عرض أو إخفاء توزيع الإجابات.",
  "activity.tabGamePlayQuestionnaireHint": "تكوين إجراءات الاستبيان.",
  "activity.gameTimeInSecs": "وقت التحدي (بالثواني)",
  "activity.hintGameTime": "اتركها فارغة أو أدخل الصفر لتعيين الحد الزمني بدون وقت محدد",
  "activity.correctAnswersCountHint": "الحد الأدنى للإجابات الصحيحة للنجاح أو الفوز، من 0 إلى {value}",
  "activity.roundsPerGame": "عدد الجولات لكل تحدي",
  "activity.youHaveGuessed": "تخمين سجلت.",
  "activity.youHaveGuessedNUpdate": "بإمكانك اعادة التخمين قبل انتهاء الحملة.",
  "guideGuessMessage": "خمن بشكل صحيح  لتتأهل المكافئة .",
  "activity.userGuessRight": "كان تخمينك صحيحًا.",
  "activity.userGuessWrong": "اوشكت على الوصول! استمر في التخمين.",
  "activity.roundDraw": "جولة متعادلة",
  "activity.validationBetween": "يجب أن يكون بين {value1} و {value2}",
  "activity.openCampaign": "افتح الحملة",
  "activity.optionRequired": "الخيار مطلوب",
  "activity.optionAlreadyPresent": "الخيار موجود بالفعل، أدخل خيارًا آخر",
  "activity.errorGameLoading": "حدث خطأ أثناء تحميل التحدي",
  "activity.socketTimeout": "تعذر الاتصال بخادم التحدي",
  "activity.youHavePredicated": "تم تسجيل توقعك.",
  "activity.userPredictionRight": "توقعك صحيح.",
  "activity.userPredictionWrong": "اوشكت على الوصول! استمر في التخمين.",
  "campaign.tabPredict": "توقّع",
  "activity.tabPredictionHint": "اضف التوقعات",
  "activity.predictionDateHint": "تاريخ اخر فرصة للمستخدمين للتوقع",
  "activity.predictionEndDate": "تاريخ نهاية التوقعات",
  "activity.dummyPredictionTitle": "توقع النتيجة  ",
  "activity.dummyPredictionOption1Title": "الزعيم",
  "activity.dummyPredictionOption2Title": "العالمي",
  "gamePredictionMessage": "توقع النتيجة للتأهل المكافئة ",
  "activity.twoValues": '{value1}/{value2}',
  "gameBettingPicMessage": "قم بالدوران وسجل الحد الأقصى للفوز بالتحدي، وسيعتمد التسجيل على المضاعف لكل عمود.",
  "activity.allowGuessAgain": "يمكنك تخمين ذلك مرة أخرى.",
  "activity.flipsPerGame": "عدد مرات قلب البطاقات",
  "activity.tabFlipTriesHint": "اضبط عدد مرات قلب البطاقات وإعادة التحدي من جديد .",
  "activity.validationValueBetween": "مطلوب, بين {value1} و {value2}",
  "activity.loadingDots": "جاري التحميل...",
  "activity.allowPredictionAgain": "بامكانك اعادة التوقع مجدداً.",
  "downloadContent": 'حمّل المحتوى',
  "downloadContentSocialMessage": 'تنزيل المحتوى سيمنح المستخدم يفوز بالمكافآت.\n\n  هل أنت متأكد أنك تريد تنزيل المحتوى؟',
  "downloadContentAgainMessage": 'هل أنت متأكد أنك تريد تنزيل المحتوى؟ ',
  "download": 'تحميل',
  "guidePartScoreMaximumToWin": "حقق اعلى رقم للتأهل للفوز باللتحدي",
  "guidePartScoreMoreThanValueToWin": "حقق  {value} نقطة لتتأهل المكافئة .",
  "activity.gameReverseImage": "عكس الصورة",
  "gameMatchPairMessage": "اقلب البطاقات المتشابهة في عدد مرات محددة ، واربح التحدي لتتأهل للجائزة.",
  "campaign.tabIdentifyLocation": "الموقع",
  "activity.location": "الموقع",
  "activity.tabIdentifyLocationHint": "اشرح تفاصيل الموقع",
  "activity.describeLocation": "اشرح تفاصيل الموقع المراد تحديده",
  "activity.locationImage": "صورة الموقع",
  "activity.selectLocation": "اختر الموقع",
  "activity.pickedLocation": "تم التحديد",
  "activity.sponsor": "الراعي الرسمي",
  "activity.manage.buildYourPremiumPlan": "تخصيص الباقة",
  "activity.manage.extendYourPremiumPlan": "مدد الباقة",
  "activity.noReelsFound": "لا توجد مشاركات الان",
  "generic.showLess": "...اظهر اقل",
  "generic.showMore": "...اظهر اكثر",
  "activity.addPost": "اضف مشاركة",
  "activity.postAdded": "تم الاضافة",
  "activity.reelTitle": "اضف نص لمشاركتك",
  "activity.reelVideo": "فيديو",
  "activity.reelAudio": "صوت",
  "activity.reelImage": "صورة",
  "activity.reelMediaRequired": "الوسائط المطلوبة، إضافة فيديو أو صوت أو صورة",
  "activity.postAnonymous": "اخفاء اسم المسخدم",
  "campaign.tabReelFront": "الوصف",
  "activity.tabReelFrontHint": "قم بوصف متطلباتك للريل",
  "campaign.tabReelSubPosts": "للترويج",
  "campaign.tabReelSubPostsHint": "قم بترويج اعلاناتك بين الريل التي نشرها المستخدم",
  "activity.reelFrontTitle": "صف عن ماذا تريد ان يتحدث جمورك !",
  "filePicker.fileSizeLong": "{value} حجم الملف اكبر من المسموح {size} ميجا .",
  "activity.addAnotherPost": "اضف مشاركة اخرى",
  "activity.moveUpSubPost": "حرك المشاركة لاعلى",
  "activity.moveDownSubPost": "حرك المشاركة لاسفل",
  "activity.removeSubPost": "احذف المشاركة",
  "activity.expandSubPost": "اظهار تفاصيل المشاركة",
  "activity.collapseSubPost": "تقليص تفاصيل المشاركة",
  "activity.correctErrorSubPost": "املأ الحقول المطلوبة أعلاه قبل إضافة مشاركة جديدة",
  "activity.subPostLink": "الرابط",
  "activity.subPostLinkText": "عنوان الرابط",
  "activity.reportCampaign": "ابلاغ",
  "activity.reportPost": "ابلاغ",
  "activity.reportPostMessage": "أبلغ عن المنشور إذا وجدت هذه المعلومات ضارة أو خطيرة أو مضللة أو غير مرغوب فيها أو مضللة وما إلى ذلك.",
  "activity.winnersByRandom": "اختيار مشاركة عشوائية",
  "activity.winnersByTopViewed": "اختيار المشاركة الاعلى مشاهدة",
  "activity.winnersByTopLiked": "اختيار المشاركة الاكثر اعجابا",
  "activity.winnersByDownloadedOnly": "المشاركة التي ستقوم باختيارها يدويا",
  "activity.winnersBy": "الفائزون",
  "activity.downloadComplete": "تم التحميل",
  "activity.swipeUpForMore": "حرك للاعلى ",
  "activity.addYourPost": "اضف مشاركتك",
  "activity.roundsFinish": "انتهت الجولات، لقد خسرت",
  "campaign.declareWinnersPrompt": "يتم الإعلان عن الفائزين في نهاية الحملة، ولكن يمكنك الإعلان عنهم الآن. إختر خيار-",
  "campaign.declareWinnersNEndCampaign": "الإعلان عن الفائزين وإنهاء الحملة",
  "campaign.declareWinnersNRestartCampaign": "أعلن عن الفائزين وأعد تشغيل الحملة",
  "campaign.chooseDeclareWinnersOption": "اختر الخيار المناسب لمعالجة إعلان الفائزين",
  "campaign.offerVideo": "فيديو المكافئة",
  "campaign.offerVideoDesc": "فيديو عن المكافئة",
  "login.backToLogin": "الرجوع لـ <b>تسجيل الدخول</b>",
  "login.accountVerification": "التحقق من حساب Rab7",
  "tab.add": "حملة جديدة",
  "activity.start": "ابدأ",
  "campaign.declareWinnersNEndCampaignQ": "الإعلان عن الفائزين وإنهاء الحملة؟",
  "campaign.declareWinnersNRestartCampaignQ": "الإعلان عن الفائزين وإعادة تشغيل الحملة؟",
  "generic.continueQ": "متابعة",
  "campaign.declareWinnersNEndCampaignHint": "سيتم الاعلان عن الفائزين و سيتم انهاء الحملة",
  "campaign.declareWinnersNRestartCampaignHint": "سيتم الاعلان عن الفائزين و سيتم اعادة استئناف الحملة بعد 10 دقائق",
  "campaign.declareWinnersNEndCampaignPrompt": "سيتم الإعلان عن الفائزين وتنتهي الحملة. <br><br>هل تريد الاستمرار؟",
  "campaign.declareWinnersNRestartCampaignPrompt": "سيتم الإعلان عن الفائزين وسيتم استئناف الحملة مرة أخرى بعد 10 دقائق. <br><br>هل تريد الاستمرار؟",
  "menu.redeemOfferSteps": "خطوات المكافئة",
  "manage.chooseNStart": "التالي",
  "manage.customizeNow": "صممها الان",
  "activity.empty": "_",
  "activity.tabUsageHint": "ما هو هدف حملتك!",
  "activity.tabTypeHint": "اختر الترفيه المناسب لحملتك",
  "campaign.selectType": "حدد نشاطًا ممتعًا للبدء",
  "manage.leaveCampaign": "هل تريد مغادرة إدارة الحملة؟",
  "manage.leaveMessage": "لن يتم حفظ التغييرات الخاصة بك.",
  "manage.leave": "مغادرة",
  "filter.allGender": "الكل",
  "activity.tabPriceHint": "تخصيص الباقة",
  "activity.tabTargetingHint": "استهدف المستخدمين حسب التركيبة السكانية والموقع والسلوك",
  "activity.tabCustomizeHint": "خصص الترفيه",
  "activity.tabDetailsHint": "التفاصيل",
  "activity.gamePlayImages": "استبدل صور التحدي بصور من عندك",
  "activity.bgAudio": "استبدل صوت الخلفية بصوت  مناسب لك",
  "activity.tabDetailsHeader": "تخصيص تفاصيل قائمة الحملة",
  "activity.tabRewardDetailsHeader": "تخصيص تفاصيل المكافأة",
  "campaign.coverImage": "صورة الحملة في القائمة",
  "campaign.publicLinkTitle": "اختر طريقة الوصول للحملة",
  "campaign.publicLinkSubTitle": "راجع الحملة واختر طريقة الوصول للحملة : عن طريق الرابط فقط، أو عن طريق الرابط وفي قائمة تشغيل Rab7.",
  "activity.manage.sectionResetDays": " إعادة التشغيل التلقائي",
  "activity.manage.sectionHintAutoRestart": "اعادة تشغيل الحملة يقوم بتجديد التحدي بشكل دوري مما يجذب جمهور اوسع مراراً ",
  "activity.selectMedia": "اختر الوسائط",
  "activity.hintQuestionOptions": "# املأ الخيارات التي تريد عرضها فقط",
  "activity.tabQuestionHint": "اسأل سؤالك",
  "activity.tabGuessHint": "ماذا تخمن",
  "activity.tabCustomizeFunHint": "تخصيص التحدي",
  "activity.questionNOptions": "سؤال و خيارات",
  "activity.questionRightOption": "الخيار الصحيح",
  "activity.guessTitle": "تخمين موجه",
  "activity.previewPlayListSubtitle": "انظر كيف سيبدو التحدي في قائمة التحديات",
  "activity.previewPlaySubtitle": "انظر كيف سيبدو التحدي في شاشة التحديات",
  "activity.predictionValidity": "صحة التوقع",
  "activity.questionnaireConfigs": "ضبط الاسئلة",
  "activity.showCheckRightOptionHint": "تنبيه المستخدم بتصحيح الاجابة إذا تم تحديدها بشكل غير صحيح",
  "activity.showAnswerDistributionSubTitle": "يمكن للمستخدمين رؤية احصائية الإجابات بعد إرسال إجابتهم",
  "activity.tabCTA1Hint": "قم بتوجيه المستخدمين باستخدام عبارة  أمامية مخصصة",
  "activity.manage.cta1Image": "صورة التوجيه الامامية",
  "activity.manage.cta1Name": "عنوان التوجيه الامامي ",
  "activity.manage.cta1Link": "رابط التوجيه الامامي ",
  "activity.offerDetails": "تفاصيل العرض",
  "activity.typeReward": "المكافئة",
  "generateCode": "انشاء كود المكافئة",
  "activity.tabRewardHint": "مكافآت التحدي والفائزين",
  "activity.imageObliqueVideo": "صور او فيديو المقدمة  ",
  "activity.setWinnersLimit": "الحد الاعلى للفائزين",
  "activity.editRewardInMyRewards": "ⓘ تحرير المكافأة متاح في مكافآتي",
  "activity.reelMedia": "اختر صورة او فيديو لمقدمة الريل",
  "activity.coverImage": "صورة الغلاف",
  "activity.tabVoucherHint": "ادارة القسائم",
  "activity.manage.hintRedeemedVoucherCodes": "القسائم التي تمت المطالبة بها من قبل المستخدمين",
  "activity.tabCTAHint": "اضف عناصر التوجيه",
  "activity.manage.autoRestartNone": "بدون اعادة",
  "activity.saveNExit": "حفظ و خروج",
  "campaign.finished": "انتهت",
  "campaign.live": "نشطة",
  "time.inSecond": "في {count} ثانية",
  "time.inSeconds": "في {count} ثواني",
  "time.inHour": "في {count} ساعة",
  "time.inHours": "في {count} ساعة",
  "time.inMinute": "في {count} دقيقة",
  "time.inMinutes": "في {count} دقائق",
  "time.inDay": "في {count} يوم",
  "time.inDays": "في {count} ايام",
  "time.inMonth": "في {count} شهر",
  "time.inMonths": "في {count} اشهر",
  "time.inYear": "في {count} سنة",
  "time.inYears": "في {count} سنوات",
  "redeemOffer.rab7ColonValue": "رقم متجر Rab7: <b>{value</b>",
  "redeemOffer.rewardInfo": "معلومات العرض",
  "redeemOffer.storeInfo": "معلومات المتجر",
  "campaign.manage.entertainmentFun": "ترفيه",
  "campaign.manage.engagementFun": "تفاعل",
  "campaign.manage.feedbackFun": "اراء",
  "campaign.manage.contentFun": "محتوى",
  "campaign.selectFunToPreview": "حدد التحدي لإظهار معاينته",
  "picker.fileNotWorking": "ملف الوسائط لا يعمل، يجب أن تكون قيمته {value}",
  "picker.fileTypeNotSupported": "نوع الملف غير مدعوم، يجب أن يكون {value}",
  "campaign.typeSpinDiscoverShort": "بينجو",
  "activity.noRewardsText": "بدون",
  "menu.editProfile": "عدل ملفك الشخصي",
  "menu.accountDetails": "تفاصيل حسابي",
  "menu.myReels": "الريل الخاص بي",
  "menu.others": "أخرى",
  "menu.changePassword": "تغيير كلمة المرور",
  "menu.needHelpQ": "تحتاج مساعدة ؟ ",
  "menu.emailUsAtSupport": "ارسل لنا على : rab7@rab7.com",
  "menu.needHelpEmailUs": "تواصل معنا",
  "menu.tnc": "الخصوصية والاستخدام",
  "menu.language": "اللغات",
  "campaign.myReels": "الريل الخاص بي",
  "campaign.answerToContinue": "قبل التوجه للتحدي، رأيك يهمنا",
  "list.noCampaignsFound": "لا تتوفر أي أنشطة ترفيهية حاليًا، يمكنك التحقق مرة أخرى لاحقًا",
  "list.noCampaignsFoundNoLocation": "لا توجد أنشطة ممتعة متاحة، قم بتفعيل الموقع وحاول مرة أخرى.",
  "campaign.ensureLocationOnAndRetry": "يتطلب عرض التحدي تفعيل خدمة تحديد الموقع.<br>قم بتشغيل خدحة تحديد الموقع وحاول مرة أخرى.",
  "picker.recordImageVideo": "التقاط صورة أو فيديو",
  "picker.recordImage": "التقط صورة",
  "picker.recordVideo": "سجل فيديو",
  "picker.openFile": "اختر ملف",
  "activity.tabQuestions": "الاسئلة",
  "redeemOffer.promptMessageRedeemCode": "تأكد من التحقق من اسم الراعي ورقم الراعي راب7 وتوفر العرض.<br><br>هل تريد المتابعة؟",
  "campaign.titleOfferName": "اسم العرض",
  "campaign.titleSponsorName": "اسم الراعي",
  "campaign.titleSponsorRab7Number": "رقم الراعي راب7",
  "activity.helpRedeemTitle1": "إنشاء الـ QR",
  "activity.helpRedeemSubTitle1": "يقوم الفائز بإنشاء رمز الاستجابة السريعة (QR) من المكافئة.",
  "activity.helpRedeemTitle2": "زيارة الموقع",
  "activity.helpRedeemSubTitle2": "يقوم الفائز بزيارة موقع مقدم المكافئة لاستلامها.",
  "activity.helpRedeemTitle3": "مسح الرمز",
  "activity.helpRedeemSubTitle3": "يقوم مقدم المكافئة بمسح رمز الاستجابة السريعة للحصول على معلومات المكافئة.",
  "activity.helpRedeemTitle4": "التحقق من الصفحة",
  "activity.helpRedeemSubTitle4": "يتحقق مقدم المكافئة من أن رمز الاستجابة السريعة صادر عن <b>RAB7.COM</b>",
  "activity.helpRedeemTitle5": "مطابقة الرقم",
  "activity.helpRedeemSubTitle5": "يتحقق مقدم المكافئة من أن المكافئة تنتمي إلى رقم Rab7 الخاص بمقدم المكافئة.",
  "activity.helpRedeemTitle6": "تأكيد الاستهلاك",
  "activity.helpRedeemSubTitle6": "يقوم مقدم المكافئة بضغط زر تأكيد استهلاك المكافئة.",
  "activity.helpRedeemTitle7": "الاستلام",
  "activity.helpRedeemSubTitle7": "يستلم الفائز المكافئة ويتمتع بها.",
  "activity.helpRedeemNote": "في حال كانت المكافئة قسيمة، يمكن للفائز استخدام الكود في التسوق الإلكتروني دون الحاجة إلى اتباع الخطوات السابقة.",
  "activity.helpNote": "ملاحظة",
  "campaign.uploadMedia": "تحميل الوسائط",
  "activity.tabUsage": "حدد الهدف",
  "activity.tabCustomizeGame": "تخصيص اللعبة",
  "activity.tabCustomize": "تخصيص",
  "activity.tabType": "اختر التحدي المناسب",
  "activity.tabReelFront": "وصف الحاجة",
  "activity.tabReelSubPosts": "الترويج للمنشورات",
  "activity.tabDetails": "تفاصيل القائمة",
  "activity.tabCTA": "دعوات إلى اتخاذ إجراء",
  "activity.tabCTA1": "CTA الأمامي",
  "activity.tabReferral": "الإحالة",
  "activity.tabTargeting": "استهداف المستخدمين",
  "activity.tabReview": "مراجعة",
  "activity.tabPrice": "إنشاء خطة",
  "activity.tabQuestion": "سؤال",
  "activity.tabGuess": "اطلب التخمين",
  "activity.tabPrediction": "اطلب التنبؤ",
  "activity.tabIdentifyLocation": "حول الموقع",
  "activity.tabVoucher": "إضافة قسائم",
  "activity.tabReward": "إضافة مكافأة",
  "activity.actionPrevious": "رجوع",
  "activity.actionPreviousC": "رجوع",
  "activity.actionUpdate": "تحديث",
  "activity.campaignInsight": "تحليل البيانات",
  "activity.insight": "تحليل الحملة",
  "activity.selectChart": "اختر الرسم البياني",
  "activity.selectFocus": "اختر التركيز",
  "activity.filters": "التصفية",
  "activity.showInsight": "عرض",
  "activity.resetFilters": "إعادة تعيين",
  "activity.applyFilters": "تطبيق",
  "activity.hideQuestions": "إخفاء",
  "activity.showQuestions": "اظهار نص السؤال",
  "generic.login": "الدخول",
  "activity.percentCorrectAnswer": "نسبة الإجتياز",
  "activity.type.contentWriting": "السوشل",
  "activity.type.shareNLike": "شارك و جمّع",
  "activity.type.collectStar": "التجميع",
  "activity.type.gameGreedySpin": "الطمّاعة",
  "activity.type.gameFindHidden": "المختفية",
  "activity.moneyWithCurrency": "{value1} {value2}",
  "activity.hashQWithNumber": "#س-{value}",
  "activity.hashQWithNumberNName": "#س-{value}: {value2}",
  "activity.questionnaireQuestion": "نص السؤال",
  "activity.hashPostWithNumber": "#منشور ترويجي-{value}",
  "list.noCampaignsFoundNoLogin": " لا تفوت فرصة قضاء وقت ممتع! سجل دخولك لمعرفة الأنشطة الترفيهية التي تنتظرك.",
  "activity.planDurationQ": "كم عدد الأيام المطلوبة لتشغيل الحملة ؟",
  "activity.planViewQ": "ما هو حجم الجمهور المستهدف لحملتك ؟",
  "activity.payNPublish": "الدفع و الإطلاق ",
  "activity.publishNow": "اطلقها الآن",
  "activity.resetPreview": "اعادة تعيين",
  "generic.startInDay": "بيبدا بعد {count} يوم",
  "generic.startInDays": "بيبدا بعد {count} يوم",
  "generic.startInHour": "بيبدا بعد {count} ساعة",
  "generic.startInHours": "بيبدا بعد {count} ساعة",
  "generic.startInMinute": "بيبدا بعد {count} دقيقة",
  "generic.startInMinutes": "بيبدا بعد {count} دقيقة",
  "generic.startInMonth": "بيبدا بعد {count} شهر",
  "generic.startInMonths": "بيبدا بعد {count} شهر",
  "generic.startInSecond": "بيبدا بعد {count} ثانية",
  "generic.startInSeconds": "بيبدا بعد {count} ثانية",
  "generic.startInYear": "بيبدا بعد {count} سنة",
  "generic.startInYears": "بيبدا بعد {count} سنة",
  "generic.endInDay": "بينتهي بعد {count} يوم",
  "generic.endInDays": "بينتهي بعد {count} يوم",
  "generic.endInHour": "بينتهي بعد {count} ساعة",
  "generic.endInHours": "بينتهي بعد {count} ساعة",
  "generic.endInMinute": "بينتهي بعد {count} دقيقة",
  "generic.endInMinutes": "بينتهي بعد {count} دقيقة",
  "generic.endInMonth": "بينتهي  {count} شهر",
  "generic.endInMonths": "بينتهي بعد {count} شهر",
  "generic.endInSecond": "بينتهي بعد {count} ثانية",
  "generic.endInSeconds": "بينتهي بعد {count} ثانية",
  "generic.endInYear": "بينتهي بعد {count} سنة",
  "generic.endInYears": "بينتهي بعد {count} سنة",
  "campaign.startInDay": "ستبدأ الحملة في {count} يوم",
  "campaign.startInDays": "ستبدأ الحملة في {count} يوم",
  "campaign.startInHour": "ستبدأ الحملة في {count} ساعة",
  "campaign.startInHours": "ستبدأ الحملة في {count} ساعة",
  "campaign.startInMinute": "ستبدأ الحملة في {count} دقيقة",
  "campaign.startInMinutes": "ستبدأ الحملة في {count} دقيقة",
  "campaign.startInMonth": "ستبدأ الحملة في  {count} شهر",
  "campaign.startInMonths": "ستبدأ الحملة في {count} شهر",
  "campaign.startInSecond": "ستبدأ الحملة في {count} ثانية",
  "campaign.startInSeconds": "ستبدأ الحملة في {count} ثانية",
  "campaign.startInYear": "ستبدأ الحملة في {count} سنة",
  "campaign.startInYears": "ستبدأ الحملة في {count} سنة",
  "time.sinceSecond": "منذ {count} ثانية",
  "time.sinceSeconds": "منذ {count} ثانية",
  "time.sinceHour": "منذ {count} ساعة",
  "time.sinceHours": "منذ {count} ساعة",
  "time.sinceMinute": "منذ {count} دقيقة",
  "time.sinceMinutes": "منذ {count} دقيقة",
  "time.sinceDay": "منذ {count} يوم",
  "time.sinceDays": "منذ {count} يوم",
  "time.sinceMonth": "منذ {count} شهر",
  "time.sinceMonths": "منذ {count} شهر",
  "time.sinceYear": "منذ {count} سنة",
  "time.sinceYears": "منذ {count} سنة",
  "activity.type.question": "إستطلاع",
  "activity.type.gamePuzzleSlide": "البزل",
  "activity.type.prediction": "التوقعات",
  "activity.type.guess": "الحدس",
  "activity.type.questionnaire": "الكويز",
  "activity.type.event": "ايفينت",
  "activity.type.challenge": "الريل",
  "activity.type.gameSlot": "الفرّارة",
  "activity.type.gameBettingPic": "بلاك جاك",
  "activity.type.gameRoulette": "الروليت",
  "activity.type.gameSpinNDiscover": "بينجو",
  "activity.type.gameFlipCard": "الفخ",
  "activity.type.gameMatchPair": "ثنائيات",
  "activity.type.gameTicTacToe": "اكس - أو",
  "activity.type.gameMatchThree": "ثلاثيات",
  "activity.type.gameWhacAMole": "الصّيد",
  "activity.type.gameBrandRace": "السباق",
  "activity.type.gamePoker": "البوكر",
  "activity.type.identifyLocation": "الخريطة",
  "activity.type.gamePuzzle": "تراكيب",


  // trans
  "payment.invoice": "فاتورة ضريبية مبسطة",
  "payment.campaignPlan": "خطة الحملة",
  "payment.billTo": "إلى",
  "payment.totalWithValue": "المجموع مع الضريبة (%{value})",
  "payment.item": "العنصر",
  "payment.unit": "الوحدة",
  "payment.vat": "الضريبة",
  "payment.itemTotal": "مجموع",
  "payment.subTotal": "اجمالي المبلغ الخاضع للضريبة",
  "payment.price": "السعر",
  "payment.vatWithValue": "ضريبة القيمة المضافة (%{value})",
  "payment.dateWithValue": "التاريخ: {value}",
  "payment.timeWithValue": "الوقت: {value}",
  "payment.emailWithValue": "البريد: {value}",
  "payment.businessNameUTF8": "\xd8\xb4\xd8\xb1\xd9\x83\xd8\xa9\x20\xd8\xad\xd9\x84\xd9\x88\xd9\x84\x20\xd9\x84\xd8\xb3\xd9\x86\xd9\x85\x20\xd9\x84\xd9\x84\xd8\xa7\xd8\xaa\xd8\xb5\xd8\xa7\xd9\x84\xd8\xa7\xd8\xaa\x20\xd9\x88\xd8\xaa\xd9\x82\xd9\x86\xd9\x8a\xd8\xa9\x20\xd8\xa7\xd9\x84\xd9\x85\xd8\xb9\xd9\x84\xd9\x88\xd9\x85\xd8\xa7\xd8\xaa",
  "payment.businessNameWithValue": "شركة حلول لسنم للاتصالات وتقنية المعلومات",
  "payment.businessPlace": "الرياض، المملكة العربية السعودية",
  "payment.businessTINWithValue": "رقم ضريبة القيمة المضافة: 31021037490003",
  "payment.businessCommercialRecordWithValue": "رقم السجل التجاري: 1010451372",

  "campaign.tabPayment": "دفع",
  "campaign.tabPaymentL": "دفع",
  "payment.saveCard": "احفظ هذه البطاقة للاستخدام المستقبلي",
  "payment.payWithValue": "ادفع {value1} {value2}",
  "payment.stringCardNumber": "رقم البطاقة",
  "payment.stringCardExpiry": "شهر/سنة",
  "payment.stringCvv": "CVV",
  "payment.stringCardHolderName": "اسم صاحب البطاقة",
  "payment.noBackNoReloadHint": "جاري معالجة الدفع، الرجاء عدم الضغط على العودة أو إعادة التحميل",
  "payment.processing": "معالجة الدفع",
  "payment.success": "نجاح الدفع",
  "payment.failed": "فشل الدفع",
  "payment.cancelled": "إلغاء الدفع",
  "payment.failedMsgDetail": "في حالة خصم المبلغ، سيتم إعادته خلال 3-15 يومًا. يرجى الاتصال بالدعم إذا كنت بحاجة إلى أي مساعدة، معرف المعاملة: {value}.",
  "payment.processingMsg": "جاري معالجة الدفع",
  "payment.processingMsgDetail": "جاري معالجة تأكيد الدفع، وقد يستغرق الأمر حتى 30 دقيقة لتلقي التأكيد.<br> يمكنك إعادة التحقق من الحالة هنا أو في المعاملات في الإعدادات، كما ستتلقى إشعارًا عبر البريد الإلكتروني.",
  "payment.checkStatus": "التحقق من الحالة",
  "payment.retryPayment": "إعادة محاولة الدفع",
  "payment.cancelQ": "هل تريد إلغاء الدفع؟",
  "payment.cancel": "إلغاء الدفع",
  "payment.cancelMsg": "سيتوقف إلغاء الدفع عن تشغيل حملتك. وتأكد أيضًا أنه يمكن إلغاء الدفع فقط إذا لم يتم شحنه بعد. <br><br> إذا لم تتمكن من معالجة الدفع الآن، يمكنك معالجة الدفع لاحقًا من قائمة الحملات لجعل الحملة تعمل. <br><br> هل ستستمر؟",
  "payment.savedCards": "البطاقات المحفوظة",
  "payment.addNewCard": "إضافة بطاقة جديدة",
  "payment.cardExpires": "منتهية الصلاحية",
  "payment.deleteCardQ": "هل ترغب في حذف البطاقة؟",
  "payment.deleteCard": "حذف البطاقة",
  "payment.cardDeleted": "تمت إزالة بطاقة الدفع",
  "payment.monthYear": "{valueMonth}/{valueYear}",
  "payment.noSavedCards": "لا توجد بطاقات محفوظة",
  "payment.downloadInvoice": "تنـزـيـل الفاتورة",
  "activity.dummySurvey": "ما مدى احتمالية أن توصي بخدماتنا إلىصديق أو زميل؟",
  "activity.dummySurveyO1": "محتمل جدًا",
  "activity.dummySurveyO2": "محتمل",
  "activity.dummySurveyO3": "متردد",
  "activity.dummySurveyO4": "غير محتمل",
  "activity.dummySurvey2": "كم مرة تحقق من تقدم حملتك؟",
  "activity.dummySurvey2O1": "كل ساعة",
  "activity.dummySurvey2O2": "يوميًا",
  "activity.dummySurvey2O3": "أسبوعيًا",
  "activity.dummySurvey2O4": "شهريًا",
  "activity.dummyGuess": "ما هو الأكثر فعالية؟",
  "activity.dummyGuessO1": "اختبار تفاعلي",
  "activity.dummyGuessO2": "التحديات",
  "activity.dummyGuessO3": "محتوى اللعبة",
  "activity.dummyGuessO4": "كل ما سبق",
  "activity.dummySurveyTitle": "شارك أفكارك حول RAB7",
  "activity.postVideoSizeDuration": "# مدة الفيديو القصوى {value1} ثانية، الحجم الأقصى {value2} ميجابايت",
  "record.videoCaptureError": "غير قادر على تسجيل الفيديو، تأكد من تمكين الكاميرا والميكروفون ومنح الإذن للوصول إليهما.",
  "record.imageCaptureError": "غير قادر على تسجيل الفيديو، تأكد من تمكين الكاميرا ومنح الإذن للوصول إليه.",
  "record.audioCaptureError": "غير قادر على تسجيل الفيديو، تأكد من تمكين الميكروفون ومنح الإذن للوصول إليه.",
  "record.videoCaptureErrorAndroid": "غير قادر على تسجيل الفيديو، تأكد من تمكين الكاميرا والميكروفون ومنح الإذن للوصول إليهما. استخدم التطبيق في Google Chrome للحصول على نتائج أفضل.",
  "record.imageCaptureErrorAndroid": "غير قادر على تسجيل الفيديو، تأكد من تمكين الكاميرا ومنح الإذن للوصول إليه. استخدم التطبيق في Google Chrome للحصول على نتائج أفضل.",
  "record.audioCaptureErrorAndroid": "غير قادر على تسجيل الفيديو، تأكد من تمكين الميكروفون ومنح الإذن للوصول إليه. استخدم التطبيق في Google Chrome للحصول على نتائج أفضل.",
  "record.audioError": "خطأ في تسجيل صوت، يرجى المحاولة مرة أخرى.",
  "record.imageError": "خطأ في التقاط صورة، يرجى المحاولة مرة أخرى.",
  "record.videoError": "خطأ في تسجيل الفيديو، يرجى المحاولة مرة أخرى.",
  "record.usePhoto": "استخدام الصورة",
  "record.useAudio": "استخدام التسجيل",
  "record.useVideo": "استخدام الفيديو",
  "record.photoCaps": "صورة",
  "record.videoCaps": "فيديو",
  "record.recordAudioCaps": "تسجيل صوت",
  "generic.close": "إغلاق",
  "activity.exitPlay": "الخروج من اللعبة",
  "activity.exitPlayMessage": "سيؤدي الخروج من لعب اللعبة إلى تصنيف اللعبة على أنها لعبت ولن تتمكن من لعب هذا الدور مرة أخرى، يوصى بإنهاء اللعبة ثم الخروج. هل أنت متأكد أنك ترغب في الخروج على أي حال؟",
  "activity.noPlanSet": "لم يتم تعيين خطة",
  "login.joinRab7Short": "انضم إلى Rab7",
  "game.avatarYours": "صورتك",
  "game.avatarOpponent": "صورة خصمك",
  "activity.offerMinMaxLimit": "# عدد الفائزين بالمكافأة: لا يقل عن 1 ولا يزيد عن 100",
  "activity.dummyReelCaption": "اصنع مقطع فيديو قصير يظهر طبقك المفضل، أو الأجواء النابضة بالحياة، أو لحظاتك المميزة مع الأصدقاء والعائلة.",
  "activity.dummyReelPost1Caption": "من البذرة إلى الروح، شغف يُسكب على كل طبق.",
  "activity.dummyReelPost2Caption": "رحلة ذوقية تنتظرك! اغوص في قائمة طعامنا المنسوجة بشغف، والمصنوعة من ألذ المكونات الطازجة لمتعة خالصة.",
  "activity.dummyReelPost3Caption": "حيث يلتقي الفن بالشهية، لخلق روائع صالحة للأكل.",
  "activity.dummyReelPost4Caption": "الانتعاش يتكشف، يتحول بفعل الحب، إلى سيمفونية من النكهات.",
  "payment.inclusiveOfVAT" : "(السعر شامل لضريبة القيمة المضافة)",
  "activity.noRewardsWonMessage": "لا تزال تنتظر الجوائز؟<br>انطلق واربح! اجب، العب، وشارك<br>كل مغامرة قد تفتح المجال لمكافآت رائعة. هيا لنملأ صندوق المكافئات الخاص بك!✨",
  "login.moveBackFromCode": "إلغاء، العودة",
  "login.verification": "التحقق من الحساب",
  "login.codeRequired": "أدخل رمز التحقق",
  "login.codeInvalid": "رمز التحقق غير صالح",
  "login.hintRegisterCode": "# أدخل رمز التحقق المرسل إلى البريد الإلكتروني",
  "login.registerVerification": "التحقق عبر البريد الإلكتروني",
  "login.verifyAccountMessage": "تحقق من حسابك من خلال إدخال رمز التحقق الذي تم إرساله إلى بريدك الإلكتروني المسجل.",
  "login.verifyEmailRegisterMessage": "تحقق من أن البريد الإلكتروني يخصك بإدخال الرمز الذي تم إرساله إلى بريدك الإلكتروني.",
  "login.updatePassword": "تحديث كلمة المرور",
  "login.verifyAccountResetPasswordMessage": "تحقق من حسابك بإدخال رمز التحقق الذي أُرسل إلى بريدك الإلكتروني المسجل، وعيّن كلمة مرور جديدة.",
  "menu.aboutUs": "معلومات عنا",
  "settings.aboutUsHeading": "الإعدادات > من نحن",
  "settings.othersHeading": "الإعدادات > أخرى",
  "settings.changePasswordHeading": "الإعدادات > تغيير كلمة المرور",
  "settings.accountDetailsHeading": "الإعدادات > تفاصيل الحساب",
  "settings.editProfileHeading": "الإعدادات > تعديل ملفك الشخصي",
  "about.title1": "من نحن؟",
  "about.subtitle1": "فريق Rab7.com شغوف ومكرس لإنشاء أدوات جذابة وموجهة بالبيانات للعصر الرقمي.",
  "about.title2": "ما هي المشكلة التي نحلها؟",
  "about.subtitle2": "يواجه أصحاب الأعمال والمؤثرون وأصحاب العمل والمعلمون والأفراد صعوبة في إنشاء تجارب جذابة وتفاعلية لجمهورهم.",
  "about.title3": "ما هو حلنا؟",
  "about.subtitle3": "يوفر Rab7.com منصة سهلة الاستخدام لبناء تجارب تفاعلية مثل المسابقات والألعاب و Reel والملاحظات والاستطلاعات ، وكلها قابلة للتخصيص بالكامل مع علامتك التجارية.",
  "about.title4": "من يمكنه الاستفادة من منصتنا؟",
  "about.subtitle4": "أي شخص يريد التواصل مع جمهوره بطريقة أكثر جدوى: أصحاب الأعمال والمؤثرون والمعلمون وأصحاب العمل والأفراد!",
  "about.title5": "ما الذي يجعل منصتنا فريدة؟",
  "about.subtitle5": "نقدم مجموعة متنوعة من الأشكال التفاعلية وخيارات التخصيص السلسة وأدوات تقسيم الجمهور المضمنة.",
  "about.title6": "كيف تفيد منصتنا المستخدمين؟",
  "about.subtitle6": "إنشاء محتوى جذاب، وجمع رؤى قيمة حول الجمهور، وتحفيز الإجراء من خلال دعوات واضحة للعمل.",
  "about.title7": "كيف تفيد منصتنا الجمهور؟",
  "about.subtitle7": "استمتع بتجارب تفاعلية، وخصص رحلتك، وسمع صوتك من خلال أسئلة سريعة.",
  "about.title8": "ما نوع التحليلات التي نقدمها؟",
  "about.subtitle8": "احصل على رؤى معمقة حول التركيبة السكانية لجمهورك والجغرافيا والاهتمامات.",
  "about.title9": "هل منصةنا سهلة الاستخدام؟",
  "about.subtitle9": "بالتأكيد! واجهاتنا سهلة الاستخدام تسمح لأي شخص بإنشاء تجارب تفاعلية دون خبرة تقنية.",
  "about.title10": "ما هي مهمتنا؟",
  "about.subtitle10": "نحن نوفر للمستخدمين القدرة على بناء روابط أقوى مع جماهيرهم من خلال تجارب تفاعلية وثرية بالمعلومات.",
  "about.noteInfo": "موقع Rab7.com تابع لشركة حلول سنم للاتصالات وتقنية المعلومات و نحن موثّقون في المركز السعودي للأعمال برقم (0000003896).",
  "activity.chargeFree": "مجاني",
  "activity.planEnd": "نهاية الحملة",
  "activity.planStart": "بداية الحملة",
  "activity.answerDistribution": "نتائج الأسئلة",
  "activity.transactions": "معاملات الحملة",
  "payment.download": "تحميل",
  "payment.stateSuccess": "ناجح",
  "payment.stateFailed": "فاشل",
  "payment.stateWaiting": "معلق",
  "menu.transactions": "المعاملات",
  "campaign.transactions": "معاملات الحملة",
  "settings.campaignTransactions": "الاعدادات > معاملات الحملة",
  "payment.downloadComplete": "تم تحميل الفاتورة.",
  "payment.refreshStatus": "تحديث",
  "payment.refreshStatusTooltip": "تحديث حالة الدفع",
  "payment.retry": "إعادة",
  "payment.id": "معرف",
  "payment.charge": "السعر",
  "payment.campaign": "الحملة",
  "payment.paymentStillProcessing": "جاري معالجة الدفع ولا يزال ينتظر التأكيد.",
  "campaign.noTransactions": "لا يوجد تحويلات",
  "selectLanguage": "اختار اللغة",
  "campaign.tabOpinion": "الآراء",
  "activity.tabOpinion": "حول الملاحظات",
  "activity.tabOpinionHint": "صف الملاحظات حول",
  "activity.aboutImage": "حول الصورة",
  "activity.aboutFeedback": "حول الملاحظات",
  "activity.aboutFeedbackPlaceholder": "اطلب ملاحظات",
  "activity.feedbackContents": "محتوى الملاحظات",
  "activity.feedbackContentsHint": "كيف تريد تلقي الملاحظات",
  "activity.feedbackContentRating": "التقييم",
  "activity.feedbackContentImage": "صورة",
  "activity.feedbackContentAudio": "صوتي",
  "activity.feedbackContentVideo": "فيديو",
  "activity.feedbackContentText": "نص",
  "activity.minRatingPlaceholder": "الحد الادنى ",
  "activity.maxRatingPlaceholder": "الحد الأعلى",
  "activity.minRatingPlaceholderSmall": "الحد الادنى ",
  "activity.maxRatingPlaceholderSmall": "الحد الأعلى",
  "activity.shareFeedback": "شارك ملاحظاتك",
  "activity.shareFeedbackDummy": "شارك ملاحظاتك حول الخدمات التي تقدمها Rab7",
  "activity.ratingMinPoor": "سيء",
  "activity.ratingMaxOver": "ممتاز",
  "activity.pickContentType": "حدد على الأقل واحدًا",
  "activity.guideOpinion": "شارك برأيك واربح.",
  "activity.review": "انشر المراجعة",
  "activity.feedbackPosted": "تم نشر الملاحظات",
  "activity.commentsHere": "أدخل الملاحظات هنا",
  "activity.ratingRequired": "اختر تقييما",
  "activity.mediaOrTextRequired": "مطلوب وسائط أو نص",
  "activity.feedbackRequired": "مطلوبة ملاحظات",
  "activity.mediaRequiredAdd": "مطلوب وسائط، أضف ",
  "activity.mediaOrTextRequiredAdd": "مطلوب وسائط أو نص، أضف ",
  "media.image": "صورة",
  "media.audio": "صوتي",
  "media.video": "فيديو",
  "generic.slash": "/",
  "picker.orRecordAudio": "أو تسجيل صوتي",
  "activity.viewOpinions": "عرض الملاحظات",
  "activity.reviews": "ردود فعل الحملة",
  "picker.recordAudio": "سجيل صوت جديد",
  "activity.minRatingPlaceholderHint": "كـ(سيء او غير مرضي)",
  "activity.maxRatingPlaceholderHint": "كـ(ممتاز و رائع)",
  "guideSurvey": "أكمل الاستبيان واربح.",
  "activitySurveyShareMessage": "أكمل الاستبيان واربح \"{title}\" على #Rab7. {url}",
  "activity.type.survey": "استبيان",
  "exitSurvey": "استبيان خروج",
  "exitSurveyMessage": "يوصى باستكماله الآن. على أي حال يمكنك استئنافه لاحقًا. استبيان خروج؟",
  "completeSurvey": "استكمال الاستبيان؟",
  "completeSurveyMessage": "أنت على وشك استكمال الاستبيان. هل تريد المتابعة؟",
  "surveyCompleted": "لقد أكملت الاستبيان.",
  "activity.dummyQuestionnaire": "!مسابقة ربح مع Rab7",
  "activity.dummyQuestion": "يتيح لك Rab7 إنشاء قصص تفاعلية باستخدام السحب والإفلات. ما نوع المحتوى الذي يمكنك إنشاؤه بدون كتابة كود؟",
  "activity.dummyQuestionO1": "الاختبارات والألعاب والتحديات",
  "activity.dummyQuestionO2": "الصور فقط",
  "activity.dummyQuestionO3": "مقالات طويلة",
  "activity.dummyQuestionO4": "موجزات وسائل التواصل الاجتماعي",
  "activity.dummyQuestion2": "يخصص Rab7 القصص للمشاركة من خلال ميزات مثل الموسيقى والمرئيات المخصصة. ماذا يمكن أن يفعل؟",
  "activity.dummyQuestion2O1": "أضف موسيقاك",
  "activity.dummyQuestion2O2": "مرئيات مخصصة",
  "activity.dummyQuestion2O3": "خطوط قصصية متفرعة",
  "activity.dummyQuestion2O4": "كل ما سبق",
  "activity.tabInQuestion": "سؤال التقسيم",
  "activity.tabInQuestionHint": "سؤال التقسيم",
  "activity.noQuestion": "بدون",
  "activity.selectQuestion": "سابق",
  "activity.addNewQuestion": "جديد",
  "activity.optionQuestion": "السؤال",
  "activity.selectExistingQuestion": "اختيار سؤال موجود",
  "activity.internalQuestionHint": "حدد سؤال لتقسيم المستخدمين ، سيضيف تكلفة إضافية للحملة.",
  "activity.selectQuestionToProceed": "حدد سؤال للمتابعة",
  "activity.share": "شارك",
  "activity.open": "معاينة",
  "activity.insightS": "تحليلات",
  "activity.contactEmail": "<a href='mailto:{value}' style='color: white'>{value} :البريد</a>",
  "activity.price": "السعر",
  "activity.qPrice": "سؤال التقسيم",
  "activity.noPosts": "لا توجد مشاركات متاحة",
  "activity.shareYourEmailForContact": "إتاحة إيميلك للتواصل",
  "activity.campaignStart": "تاريخ بداية الحملة",
  "activity.showPreview": "معاينة",
  "activity.hidePreview": "رجوع",
  "activity.landingTitle": "<br>تواصل مع جمهورك",
  "activity.landingSubTitle": "<br>اختر المرح<br>خصصه<br>عيّن المكافأة<br>احصل على التحليلات",
  "activity.startNow": "ابدأ الآن",
  "generic.addCampaign": "إنشاء حملة جديدة",
  "activity.offerMedia": "عرض صورة/فيديو",
  "activity.voucherMedia": "صورة/فيديو القسيمة",
  "activity.imageVideoRequired": "مطلوب صورة أو فيديو",
  "activity.consumedVouchers": "القسائم المستهلكة",
  "activity.referralReward": "مكافئة النشر",
  "activity.referAndWin": 'كذلك ستحصل على المكافئة بمجرد فوز من شاركتهم التحدي ، اضغط على زر المشاركة و شاركها الآن ...',
  "activity.referralManageHint": "مكافئة ناشر الحملة بنفس الجائزة اذا فاز احد عن طريق مشاركته.",
  "activity.viewOpinionsS": "ملاحظات",
  "activity.viewAnswersS": "إحصائيات",
  "activity.opinionDefaultQ": "شارك برأيك",
  "activity.endCta2": "التوجيه (CTA)",
  "activity.tabCTA2": "التوجيه (CTA)",
  "activity.tabCTA2Hint": "اصنع دعوة للعمل (CTA) لتحفيز المستخدمين",
  "activity.manage.cta2Image": "وسائط التوجيه",
  "activity.manage.cta2Name": "اسم زر التوحيه",
  "activity.manage.cta2Link": "رابط التوحيه",
  "activity.finalPreview": "معاينة نهائية",
  "activity.noWinners": "لا يوجد فائزون",
  "manage.save": "حفظ",
  "activity.answerSuccess": "تم تقديم إجابتك.",
  "activity.searchInEnglishOnly": "# البحث باستخدام الأحرف الإنجليزية فقط",
  "activity.opponents": "المعارضين",
  "activity.opponent1": "الخيار الأول",
  "activity.opponent2": "الخيار الثاني",
  "activity.predictionPlaceholder1Small": "الاسم الأول",
  "activity.predictionPlaceholder2Small": "الاسم الثاني",
  "record.retake": "إعادة التسجيل",
  "activity.preparingCampaign": "جاري تحضير حملتك",
  "activity.preparingPost": "جاري رفع المنشور",
  "activity.questionHere": "هنا مكان السؤال",
  "activity.option1Here": "الخيار الأول",
  "activity.option2Here": "الخيار الثاني",
  "activity.option3Here": "الخيار الثالث",
  "activity.option4Here": "الخيار الرابع",
  "activity.guideAnswerToContinueToFun": "فضلا وتكرما اجب على الاستفتاء لمواصلة الذهاب للتحدي.\n\n",
  "activity.guideChallenge": "انشر مقطع الفيديو الخاص بك واربح.\n\n",
  "login.bySignInAgreeTo": "عند تسجيل الدخول ، فإنك توافق على",
  "login.signInOrSignUp": "تسجيل الدخول أو التسجيل",
  "app.slogan": "للـمـهـتـم بـجـمـهـورة",
  "howItWorks.title1": "اختر التفاعل",
  "howItWorks.subtitle1": "حدد نوع التجربة التفاعلية التي تناسب أهدافك من مكتبة واسعة تضم الألعاب والترفيه والمحتوى والمشاركة والاستبيانات.",
  "howItWorks.title2": "خصصها بأسلوبك",
  "howItWorks.subtitle2": "أضف لمستك الشخصية على التجربة باستخدام شعاراتك وصورك وفيديوهاتك لخلق تجربة تفاعلية مميزة تجذب انتباه الجمهور.",
  "howItWorks.title3": "حفز جمهورك",
  "howItWorks.subtitle3": "قدم مكافآت جذابة لتحفيز الجمهور على المشاركة، وحدد نوع المكافأة وكميتها بما يتناسب مع أهدافك.",
  "howItWorks.title4": "استهدف جمهورك بدقة",
  "howItWorks.subtitle4": "حدد التركيبة السكانية والجغرافية لجمهورك المستهدف لضمان وصول تجربتك التفاعلية إلى الأشخاص المناسبين.",
  "howItWorks.title5": "وجه جمهورك نحو الخطوة التالية",
  "howItWorks.subtitle5": "أضف زرًا واضحًا يدعو الجمهور إلى اتخاذ الخطوة التالية، سواء كانت زيارة موقع الويب أو الشراء أو تحميل تطبيق أو غيرها.",
  "howItWorks.title6": "اكتشف جمهورك",
  "howItWorks.subtitle6": "اطرح أسئلة مدروسة لفهم جمهورك بشكل أعمق، واحصل على معلومات حول اهتماماتهم وسلوكهم لتحسين حملاتك المستقبلية.",
  "howItWorks.title7": "حدد مدى وصولك",
  "howItWorks.subtitle7": "حدد العدد المثالي لمرات الظهور (من 100 إلى 100 ألف) لتصل إلى جمهورك المستهدف بأفضل طريقة.",
  "howItWorks.title8": "حدد المدة",
  "howItWorks.subtitle8": "اختر مدة الحملة المناسبة (من يوم إلى 90 يومًا) لتحقيق أهدافك، سواء كانت ترويجًا قصير المدى أو بناءً طويل المدى للعلامة التجارية.",
  "howItWorks.title9": "شاركها مع جمهورك",
  "howItWorks.subtitle9": "أنشئ رابطًا ورمز QR فريدًا لمشاركة تجربتك التفاعلية على شبكات التواصل الاجتماعي أو في مقر عملك أو أي قناة أخرى مناسبة.",
  "howItWorks.title10": "افهم جمهورك من خلال تحليل البيانات",
  "howItWorks.subtitle10": "استكشف بيانات تفاعل الجمهور لفهم توزيعهم الجغرافي والديموغرافي واهتماماتهم، لتحسين استراتيجياتك وحملاتك المستقبلية.",
  "howItWorks.actionHeading": "استفد من قوة المحتوى التفاعلي لتحقيق أهدافك وجذب انتباه جمهورك وخلق تجربة تفاعلية لا تُنسى",
  "activity.imageOrVideoTextRequired": "مطلوب صورة أو فيديو ونص",
  "activity.describeQuiz": "وصف المسابقة",
  "activity.describeSurvey": "وصف الاستطلاع",
  "activity.quizPrompt": "موجه المسابقة",
  "activity.surveyPrompt": "موجه الاستطلاع",
  "menu.howRab7Works": "مـاهي طريـقة Rab7",
  "menu.whatIsRab7": "ماهي منصة Rab7",
  "generic.startFreeNow": "ابدأ مجانا الآن",
  "generic.joinRab7Now": "انضم إلى Rab7 الآن",
  "activity.manage.4.title": "إستطلاع",
  "activity.manage.4.description": "يجيب المستخدمون عن سؤال من خلاله تتعرف على اهتماماتهم و تفضيلاتهم ، ويتأهل المستخدمون للسحب على المكافئة التي تضعها لتحفيزهم.",
  "activity.manage.7.title": "رأي و تقييم",
  "activity.manage.7.description": "• يعطي المستخدم رأيه وتقييمه عن موضوع تحدده.<br>" +
    "• يتم السحب بشكل عشوائي للمكافئة لمن يعطي رأيه.",
  "activity.manage.11.title": "الريل",
  "activity.manage.11.description": "• يسجل المستخدم محتوى حول موضوع معين بطريقة الريل.<br>" +
    "• يشاهد الجميع المشاركات وبينهما اعلانات خاصة بك.<br>" +
    "• يفوز بالمكافئة المشاركة التي تحددها.",
  "activity.manage.12.title": "التوقعات",
  "activity.manage.13.title": "الفرّارة",
  "activity.manage.13.description": "• يحاول المستخدم الحصول على صور متطابقة في الصف محدد.<br>" +
    "• يتأهل للسحب على المكافأة . اذا حصل على صف متطابق.",
  "activity.manage.15.title": "الفخ",
  "activity.manage.15.description": "• يقلب المستخدم الكروت للحصول على صورة مخفية.<br>" +
    "• يتأهل المستخدم للسحب على المكافأة اذا لم يقع بالكرت المميت.",
  "activity.manage.16.title": "ايفينت",
  "activity.manage.16.description": "ادارة ايفينت للمستخدمين ويحصلون على مكافئة منك في حال حضورهم الايفنت.",
  "activity.manage.17.title": "شارك و جمّع",
  "activity.manage.17.description": "يقوم المشتخدم بنشر الحملة لشبكة معارفة ويطبل منهم الدعم بتجميع نقاط , ومن  يجمع نقاط أكثر يفوز.",
  "activity.manage.18.title": "التجميع",
  "activity.manage.18.description": " يحاول المستخدم ان يقلب الكروت ليحصل على صور مختلفة بقيم مختلفة ومن يحافه الحظ و يجمع نقاط اكثر يفوز.",
  "activity.manage.19.title": "الحدس",
  "activity.manage.19.description": "• يخمن المستخدم الاجابة الصحيحة للسؤال المتعلق بالصورة من بين 4 اختيارات تضعها.<br>" +
    "• يتأهل للسحب على المكافئة من يجيب على الاختيار الصحيح.",
  "activity.manage.20.title": "الروليت",
  "activity.manage.20.description": "• يختار المستخدم ترتيب للصور بالصف الاعلى.<br>" +
    "• يدور السبينر، وإذا تطابق ترتيبه مع ترتيب المستخدم، يفوز بالمكافأة.",
  "activity.manage.21.title": "السوشل",
  "activity.manage.21.description": "يشارك السمستخدم محتوى حول موضوع معين , لكن لا يطلع على المشاركة احد غيرك , وتوقوم باختيرا المشاركة الجيدة وتكافئ العميل بها , تفيد في خلق محتوى في السوشل ميديا.",
  "activity.manage.22.title": "بينجو",
  "activity.manage.22.description": "• تنكشف الصورة اذا وقف السبينر على صور معينة.<br>" +
    "• من يشكف الصورة كاملة  يتأهل للسحب على المكافأة.",
  "activity.manage.23.title": "المختفية",
  "activity.manage.23.description": "من بين الكروت المغطاة يوجد هناك صورة مختفية , من يعثر عليها أولا يفوز.",
  "activity.manage.24.title": "الطمّاعة",
  "activity.manage.24.description": "يدور السبينر و والمستخدم يحاول ان يحالفه الحظ بجمع نقاط اكثر في كل مرة تظهر فيها الصورة , وصاحب النقاط الاكثر يفوز.",
  "activity.manage.25.title": "بلاك جاك",
  "activity.manage.25.description": "• كل عمود يعطي مضاعفة للنقاط.<br>" +
    "• يفوز بالمكافئة الاعلى تجميعا للنقاط.",
  "activity.manage.26.title": "ثنائيات",
  "activity.manage.26.description": "• يقلب المستخدم الكروت لتجميع الصور المتشابهة.<br>" +
    "• يتأهل المستخدم للسحب على المكافأة اذا جمعها في المحاولات المحددة.",
  "activity.manage.27.title": "اكس - أو",
  "activity.manage.27.description": "• ينافس المستخدم الروبوت في XO للفوز بالجولة.<br>" +
    "• يتأهل المستخدم للسحب على المكافأة اذا فاز بأكثر الجولات.",
  "activity.manage.28.title": "الكويز",
  "activity.manage.28.description": "• يجيب المستخدم عن سلسة من الاسئلة التي تضعها.<br>" +
    "• يتأهل للسحب الذي يتجاوز نسبة النجاح التي تحددها.",
  "activity.manage.29.title": "ثلاثيات",
  "activity.manage.29.description": "• يرتب المستخدم ثلاث صور متشابهة لتحطيم الصف او العمود للحصول على نقاط.<br>" +
    "• يتأهل المستخدم للسحب على المكافأة اذا جمّع اكثر من 200 نقطة.",
  "activity.manage.30.title": "تراكيب",
  "activity.manage.30.description": "• يرتب المستخدم الصورة المبعثرة بقلب القطع.<br>" +
    "• يتأهل المستخدم للسحب على المكافأة اذا رتبها في الوقت المحدد.",
  "activity.manage.31.title": "الصّيد",
  "activity.manage.31.description": "• يحاول المستخدم اصطياد الصور الصحيحة وتجنب الخاطئة لتجميع النقاط.<br>" +
    "• يتأهل للسحب على المكافئة من يجمع 35 نقطة بالوقت المحدد.",
  "activity.manage.32.title": "السباق",
  "activity.manage.32.description": "• يتنافس المستخدم مع الروبوت مع كل تحريك للسبينر.<br>" +
    "• اذا فاز بالسباق يتأهل للسحب على المكافأة.",
  "activity.manage.33.title": "البوكر",
  "activity.manage.33.description": "• يتنافس المستخدم مع الروبوت بقلب ثلاث اوراق.<br>" +
    "• الأعلى مجموع نقاط يفوز بالجولة.<br>" +
    "• يتأهل المستخدم للسحب على المكافأة اذا فاز بأكثر الجولات.",
  "activity.manage.34.title": "الخريطة",
  "activity.manage.34.description": "• يبحث ويحدد المستخدم  بالخريطة عن موقع تحدده.<br>" +
    "• يفوز بالمكافئة الاقرب للموقع الذي حددته.",
  "activity.manage.35.title": "البزل",
  "activity.manage.35.description": "• يرتب المستخدم الصورة المبعثرة بتحريك القطع.<br>" +
    "• يتأهل المستخدم للسحب على المكافأة اذا رتبها في الوقت المحدد.",
  "activity.manage.36.title": "استبيان",
  "activity.manage.36.description": "• يجيب المستخدم عن سلسة من الاسئلة التي تضعها.<br>" +
    "• يتم سحب المكافئة بشكل عشوائي لمن يكمل الاستبيان.",
  "activity.shareGeneric": "انقر هنا- {url} ,{title}",
  "activity.youHavePredicatedNUpdate": "تم تسجيل توقعك، بامكانك تغييره بأي وقت.",
  "activity.valuePercent": "{value}%",
  "activity.resultProbability": "ملخص التوقعات",
  "activity.predictionDrawValue": "تعادل %{value}",
  "activity.withoutRewards": "بدون",
  "activity.savingCampaign": "جاري حفظ حملتك",
  "activity.joinRab7ForFurther": "انضم إلى Rab7 للمتابعة",
  "activity.joinRab7ForGenerateCode": "انضم إلى Rab7 لإنشاء الرمز",
  "activity.joinRab7ToPost": "انضم إلى Rab7 لإضافة مقطع الفيديو الخاص بك",
  "activity.joinRab7ToViewAllPosts": "انضم إلى Rab7 لرؤية جميع المقاطع",
  "activity.continueAnyway": "استكشاف كضيف",
  "activity.joinToGetBenefitsMessage": "انضم إلى Rab7 قبل الانتقال إلى المتعة ، حتى تحصل على توصية ممتعة مناسبة ووصول مباشر إلى المكافآت التي ربحتها. <br>بالمقابل ، يمكنك استكشافه كضيف.",
  "campaign.ensureLogin": "انضم إلى Rab7 وحاول مرة أخرى لمعرفة ما إذا كان ينطبق عليك.",
  "campaign.subscribePlanToProceed": "اشترك في خطة للمضي قدما.",
  "campaign.savingThenMovingToPlanToProceed": "يتم حفظ حملتك ومن ثم سيتم توجيهك لاختيار مدة الخطة وطرق العرض. سيتم نشر حملتك بمجرد إتمام عملية الدفع.",
  "activity.prepareCampaignAfterJoin": "انضم إلى Rab7 لنشر حملتك.",
  "campaign.publishCampaign": "نشر الحملة",
  "campaign.save": "حفظ",
  "activity.wonProceedToJoinMessage": "مبرووك لقد فزت بالتحدي .<br><br> قم بالتسجيل ليتم ارسال المكافئة لك .",
  "activity.wonEligibleProceedToJoinMessage": "مبرووك لقد اجتزت التحدي .<br><br>قم بالتسجيل ليتم ادراج اسمك بالسحب على المكافئة .",
  "activity.gameOverProceedToJoinMessage": " ما حالفك الحظ؟ <br><br>ولا يهمك سجل الان ونعطيك فرصة ثانية .",
  "activity.gameSubmitProceedToJoinMessage": "تم تسجيل احابتك <br><br>قم بالتسجيل ليتم ادراج اسمك بالسحب على المكافئة .",
  "location.pickManually": "اختر الموقع",
  "location.pickManuallyPrompt": "خدمة تحديد الموقع لا تعمل؟<br>يمكنك اختيار موقعك من الخريطة.",
  "location.hintPickManual": "للحصول على أفضل الحملات القريبة منك، حدد موقعك الحالي.  الموقع الخاطئ يعني عروضًا غير ذات صلة وأحداثًا قد تكون مزدحمة.",
  "game.score": "النقاط: {value}",
  "activity.chooseThemeColor": "اختر لون المظهر الخاص بك",
  "activity.voucherCodeDetailsPlaceholder": "تفاصيل القسيمة ، كيفية الاسترداد ، الشروط ، إلخ.",
  "activity.winLimitMoreThanVouchers": "يجب أن يكون عدد الفائزين أقل من أو يساوي عدد القسائم",
  "activity.voucherMoreThanWinLimitForReferral": "يجب أن تكون القسائم أكثر من عدد الفائزين لتكون متاحة للإحالة",
  "activity.voucherMinMaxLimit": "# عدد الفائزين بالمكافأة ، بين 1 وعدد القسائم",
  "location.zoomInMapToPickLocation": "قم بتكبير الخريطة واختر موقعًا أكثر دقة.",
  "location.pickLocationGoodToGo": "رائع! نحن قادرون على جلب موقعك المحدد.",
  "campaign.userWonJoinUsDetail": "مبروك ، لقد فزت بالجائزة",
  "campaign.userWonJoinUsDetailMessage": "تحتاج إلى الانضمام إلى ربح للوصول إلى قسائمك.",
  "activity.postDownloadedByCampaigner": "تم تنزيل منشورك بواسطة صاحب الحملة.",
  "game.scoreRounds": "(أنت) {value1} - {value2} (بوت)",
  "activity.wonChallengeMessageNR": "تحتاج إلى الانضمام إلى ربح للوصول إلى قسائمك.",
  "activity.joinGenericNR": "ارفع مستوى الترفيه الخاص بك مع Rab7! انضم للمتعة والمكافآت.",
  "activity.passChallenge": "مبروك تجاوزت التحدي بنجاح",
  "activity.passChallengeMessageNR": "انضم إلى Rab7 لتكون مؤهلاً للدخول في السحب على الجائزة، قبل يوم {value}",
  "activity.gameOverChallenge": "انتهت اللعبة! حظاً أوفر في المرة القادمة.",
  "activity.gameOverChallengeNR": "ولا يهمك",
  "activity.gameOverChallengeMessageNR": "انضم إلى Rab7 للحصول على فرصة أخرى للفوز!",
  "activity.gameOverChallengeTitle": "لم تنتهِ المتعة بعد!",
  "activity.gameOverChallengeMessage": " شارك التحدي مع أصدقائك على شبكتك الاجتماعيّة. عندما يلعبون التحدي، ستحصل على فرصة جديدة للمشاركة أنت أيضاً! ",
  "activity.gameOverChallengePlayAgain": "انتهت اللعبة! العب مرة أخرى.",
  "activity.updateActionMessageNR": "انضم إلى ربح & عدّل توقُّعك! نحن نؤمن بك.",
  "activity.updateActionRewardMessageNR": "تحديث مجاني للتوقُّع مقابل الانضمام إلى ربح! الفوز قادم!",
  "activity.locationPickedNUpdate": "الموقع مُحدَّد. التعديل ممكن قبل انتهاء الوقت.",
  "activity.locationPicked": "الموقع مُحدَّد.",
  "activity.billingName": "الفاتورة صادرة ل",
  "activity.billingInfo": "تفاصيل الفاتورة",
  "activity.validationBillName": "اسم الفاتورة مطلوب ، بحد أدنى 5 أحرف.",
  "generatedVoucherCodes": 'قسائم الشراء الخاصة بك',
  "activity.voucherDetails": "تفاصيل القسيمة",
  "campaign.endCampaign": "إنهاء الحملة",
  "campaign.endCampaignPrompt": "ستنتهي هذه الحملة بدون فائزين. في حال كنت ترغب في إنهاء الحملة وإعلان الفائزين ، فانتقل إلى خيار <strong>إعلان الفائزين<strong/>.",
  "campaign.endedDone": "الحملة انتهت",
  "location.marked": "مُشْرَة",
  "play.joinRab7Title": "استمتع أكثر مع Rab7!",
  "play.joinRab7SubTitle": "ابدأ مغامرتك المخصصة اليوم واحصل على مكافآت حصرية.",
  "play.joinRab7Action": "انضم إلى Rab7 الآن",
  "play.joinRab7MaybeLater": "ربما لاحقًا...",
  "validation.enterEmail": "أدخل بريد إلكتروني صالح",
  "login.joined": " حسابك في Rab7 جاهز! <br> رقم العضوية <strong>{value}</strong>. <br><br>",
  "menu.games": "ألعابي",
  "games.noGames": "لم تقم بإضافة أي ألعاب حتى الآن. استخدم زر \"+\" لإضافة لعبة الآن.",
  "games.notAdded": "لا توجد ألعاب إضافية متاحة.",
  "games.addNew": "أضف لعبتك",
  "games.uploadGame": "رفع اللعبة",
  "games.gameInfo": "معلومات اللعبة",
  "games.gameValidation": "التحقق من اللعبة",
  "games.gameFileMsg": "ارفع ملف اللعبة بصيغة zip وفقًا للإرشادات المعطاة",
  "games.gameGuidelines": "إرشادات الألعاب",
  "games.seeGuidelines": "اطلع على الإرشادات هنا",
  "games.addGame": "إضافة لعبة",
  "games.manageGame": "إدارة اللعبة",
  "games.upload": "رفع",
  "games.saveGameQ": "حفظ اللعبة؟",
  "games.publishGameQ": "نشر اللعبة؟",
  "games.uploadGameQ": "رفع اللعبة؟",
  "games.uploadGameSubtitle": "تأكد من أنك قد اتبعت الإرشادات الموصى بها للعبة.",
  "games.savingGame": "جارٍ حفظ لعبتك",
  "games.publishingGame": "جارٍ نشر لعبتك",
  "games.uploadingGame": "جارٍ رفع لعبتك",
  "games.tabValidation": "التحقق من اللعبة",
  "games.tabInfo": "المعلومات الأساسية",
  "games.tabInfoShort": "معلومات اللعبة",
  "games.tabUpload": "رفع اللعبة",
  "games.gamePreview": "معاينة اللعبة",
  "games.playGameToValidate": "العب اللعبة للتحقق من أنها تستوفي المعايير المطلوبة:",
  "games.ensureGameDisplayedCorrectly": "تأكد من عرض اللعبة بشكل صحيح، أو راجع الإرشادات لإجراء التعديلات اللازمة.",
  "games.leaveAdd": "إلغاء إضافة اللعبة؟",
  "games.leaveManage": "مغادرة إدارة اللعبة؟",
  "games.leaveManageMsg": "لن يتم حفظ التغييرات الخاصة بك.",
  "games.leaveAddMsg": "سيتم تجاهل لعبتك.",
  "games.shareLink": "مشاركة الرابط",
  "games.share": "مشاركة",
  "games.campaigns": "الحملات",
  "games.createdAt": "تم الإنشاء",
  "games.deactivateGameWithId": "إلغاء تنشيط اللعبة \"{value}\"",
  "games.activateGameWithId": "تنشيط الحملة \"{value}\"",
  "games.deactivateGameMsg": "لن تكون اللعبة متاحة للحملات الجديدة، لكنها ستستمر في الحملات الحالية.",
  "games.deleteGameWithId": "حذف اللعبة \"{value}\"",
  "games.deleteGameMsg": "تأكد من عدم إمكانية استعادة اللعبة بعد حذفها.",
  "games.gameDeactivated": "تم إلغاء تنشيط اللعبة",
  "games.gameActivated": "تم تنشيط اللعبة",
  "games.gameDeleted": "تم حذف اللعبة",
  "games.mute": "كتم",
  "games.unmute": "إلغاء الكتم",
  "games.replay": "العب مرة أخرى",
  "games.checkGameInit": "تم تحميل اللعبة بنجاح",
  "games.checkGameStart": "تبدأ اللعبة بشكل صحيح",
  "games.checkGameEnd": "تنتهي اللعبة بشكل صحيح",
  "games.checkGameDurationInLimit": "مدة اللعبة ضمن الحد المسموح",
  "games.checkGameWin": "تحقق من الفوز باللعبة عن طريق اللعب عدة مرات",
  "games.checkGameLoss": "تحقق من خسارة اللعبة عن طريق اللعب عدة مرات",
  "games.checkGameCustomiseBgAudio": "الصوت الخلفي قابل للتخصيص",
  "games.checkGameCustomiseAtleastImage": "يمكن تخصيص صورة واحدة على الأقل",
  "games.checkGameGivenImage": "الصور المعلنة قابلة للتخصيص",
  "games.toggleMute": "تأكيد الكتم/إلغاء الكتم: يجب أن يتوقف الصوت أو يستمر بناءً على الإجراء الخاص بك",
  "games.checksFailed": "فشلت الفحوصات المطلوبة. يرجى اللعب مرة أخرى للتحقق من المعايير المتبقية. لا يزال لا يعمل؟ تأكد من أن اللعبة تم تطويرها وفقًا للإرشادات المعطاة.",
  "games.guide": "كيفية اللعب",
  "games.name": "الاسم",
  "games.gameImage": "صورة اللعبة",
  "games.gamePublished": "تم نشر اللعبة",
  "games.gameSaved": "تم حفظ اللعبة",
  "manage.publish": "نشر",
  "games.shareLinkCopied": "تم نسخ رابط المشاركة",
  "activity.notifyEmail": "إعادة إرسال",
  "activity.emailSent": "تم إرسال البريد الإلكتروني",
  "activity.emailSendFailed": "فشل في إرسال البريد الإلكتروني",
  "screen.appShowcase": "رابح يعرض",
  "screen.delete": "حذف",
  "screen.deleteScreenWithName": "حذف العرض الرقمي \"{value}\"",
  "screen.tooltipDelete": "حذف العرض الرقمي",
  "screen.campaignsShowcased": "الحملات",
  "screen.status": "الحالة",
  "screen.createdAt": "تم الإعداد في",
  "screen.name": "الاسم",
  "screen.submit": "حفظ",
  "screen.idShort": "معرف العرض",
  "screen.id": "معرف العرض الرقمي",
  "screen.idHint": "# هوية فريدة للعرض الرقمي",
  "location.choose": "اختر",
  "screen.isInteractive": "العرض الرقمي تفاعلي",
  "screen.isInteractiveHint": "# يمكن للمستخدم لعب اللعبة الممتعة في العرض الرقمي",
  "game.playNow": "العب الآن",
  "game.viewNow": "عرض الآن",
  "screen.scanToPlay": "امسح للعب",
  "screen.scanToPost": "امسح للنشر",
  "showcase.userInfoPostedPlayNow": "تم تحديث معلوماتك، العب الآن.",
  "showcase.fillDetailsToPlay": "لكي نتمكن من إرسال الجائزة إليك، يرجى إدخال البيانات المطلوبة بدقة.",
  "showcase.installApp": "هل تريد تثبيت تطبيق عرض رابح؟",
  "showcase.autoClosePlayGameEnd": "شكرًا لمشاركتك في المرح!<br>العودة إلى الشاشة الرئيسية خلال {value} ثانية",
  "showcase.autoClosePlayGameEnd1": "شكرًا لمشاركتك في المرح!<br>العودة إلى الشاشة الرئيسية خلال ثانية واحدة",
  "showcase.autoCloseIdle": "العرض الرقمي في وضع الخمول. العودة إلى الشاشة الرئيسية خلال {value} ثانية.<br>انقر لمنع ذلك.",
  "showcase.autoCloseIdle1": "العرض الرقمي في وضع الخمول. العودة إلى الشاشة الرئيسية خلال ثانية واحدة.<br>انقر لمنع ذلك.",
  "menu.showcases": "العروض الرقمية",
  "menu.settingsShowcases": "الإعدادات > العروض الرقمية",
  "screen.noScreensShowcased": "ليس لديك حاليًا أي عروض رقمية لعرض حملاتك. لحل ذلك، اذهب إلى <a href='{value}' target=\"_blank\"><strong>{value}</strong><a/> وقم بإعداد عرض رقمي.",
  "screen.addNew": "إعداد عرض جديد",
  "screen.activation": "تفعيل العرض الرقمي",
  "screen.setupDigitalDisplay": "إعداد العرض",
  "screen.digitalDisplayInfo": "معلومات العرض",
  "screen.digitalDisplayActivated": "تم تفعيل العرض الرقمي",
  "screen.qrActivated": "تم تفعيل رمز الاستجابة السريعة",
  "screen.nameHint": "# اسم لتعريف العرض، الموقع، إلخ.",
  "screen.code": "رمز التفعيل",
  "screen.showcaseAs": "اختر العرض الخاص بك",
  "screen.showcase": "العرض",
  "screen.viewQR": "عرض رمز الاستجابة السريعة",
  "screen.statusInactive": "غير نشط",
  "screen.statusActive": "نشطة",
  "screen.insight": "رؤية",
  "screen.deactivate": "إلغاء التفعيل",
  "screen.deactivateScreenWithName": "إلغاء تفعيل العرض الرقمي \"{value}\"",
  "screen.deactivateScreenPrompt": "سيؤدي إلغاء التفعيل إلى إيقاف عرض الحملات. هل أنت متأكد؟",
  "screen.deleteScreenPrompt": "سيؤدي الحذف إلى إيقاف عرض الحملات. لا يمكنك التراجع عن هذا. هل أنت متأكد؟",
  "activity.joinRab7ToViewAllFun": "انضم إلى رابح لعرض كل المتعة.",
  "screens.orientationUpdated": "تم تحديث اتجاه العرض الرقمي",
  "screen.codeHint": "# افتح <strong>show.rab7.com</strong> في شاشتك الرقمية وأدخل رمز التفعيل المعروض",
  "screen.orientation": "اتجاه التطبيق",
  "screen.updateScreenOrientation": "تحديث اتجاه العرض الرقمي",
  "screen.updateScreenOrientationPrompt": "اختر اتجاه تطبيق العرض المراد عرضه.",
  "screen.updateScreenOrientationHint": "# اختر اتجاه تطبيق العرض المراد عرضه.",
  "screen.screenOrientationRequired": "اختر الاتجاه",
  "screen.orientationDefault": "نفس اتجاه الشاشة",
  "screen.orientationPortraitR": "عمودي يسار",
  "screen.orientationPortraitL": "عمودي يمين",
  "screen.orientationLandscapeR": "أفقي يسار",
  "screen.orientationLandscapeL": "أفقي يمين",
  "screens.screenDeactivated": "تم إلغاء تفعيل العرض الرقمي",
  "screens.screenActivated": "تم تفعيل العرض الرقمي",
  "screens.screenDeleted": "تم حذف العرض الرقمي",
  "screen.editDigitalDisplay": "تحرير العرض",
  "screen.qrUpdated": "تم تحديث رمز الاستجابة السريعة للعرض",
  "campaign.maximizeQRBenefit": "زيادة رؤية رمز الاستجابة السريعة.<br> 1. ضعه على طاولات المتجر، الأبواب، العدادات، أو تغليف المنتجات.<br> 2. شاركه عبر الإنترنت للوصول الأوسع.",
  "screen.digitalDisplayUpdated": "تم تحديث العرض الرقمي",
  "screen.diveIn": "مستعد للانطلاق؟<br>انقر على اللعب أو امسح رمز الاستجابة السريعة لبدء المتعة!",
  "screen.diveInNoPlay": "مستعد للانطلاق؟<br>امسح رمز الاستجابة السريعة لبدء المتعة!",
  "showcase.activityNotFoundError": "المتعة غير متوفرة حاليًا.",
  "screen.scanQRorOpen": "امسح باستخدام هاتفك أو اذهب إلى",
  "screen.enterCode": "أدخل الرمز",
  "screen.statusOffline": "غير متصل",
  "screen.statusOnline": "متصل",
  "activity.askInternalQuestion": "اطرح سؤال تأهيلي لتقسيم جمهورك",
  "activity.askInternalQuestionHint": "# متاح فقط أثناء إنشاء الحملة ويتطلب رسومًا إضافية.",
  "activity.askInternalQuestionHintEdit": "# بمجرد تعطيل هذه الميزة، لا يمكن إعادة تفعيلها.",
  "activity.showcaseCampaign": "اعرض حملتك على الشاشات الرقمية في المولات والمتاجر والفعاليات.",
  "activity.showcaseCampaignHint1": "افتح show.rab7.com على شاشتك للمشاركة. اربح من خلال الموافقة على حملات أخرى.",
  "activity.showcaseCampaignHint1Edit": "تعديل بيانات حملتك يتطلب إعادة الموافقة من مالكي الشاشات الآخرين.",
  "activity.showcaseCampaignHint2": "# متاح فقط أثناء إنشاء الحملة ويتطلب رسومًا إضافية.",
  "activity.showcaseCampaignHint2Edit": "# بمجرد تعطيل هذه الميزة، لا يمكن إعادة تفعيلها.",
  "activity.scPrice": "سعر العرض",
  "screen.manageCampaignRequestsShort": "تحكم في العرض",
  "screen.noNewCampaignRequests": "لا توجد حملات جديدة متاحة للموافقة عليها",
  "screen.noApprovedCampaignRequests": "لم تتم الموافقة على أي حملات بعد، وافق على الحملات المتاحة لعرضها في شاشات العرض الخاصة بك.",
  "screen.controlShowcaseCampaigns": "تحكم في عرض حملات المستخدمين الآخرين في شاشتك",
  "screen.ownApprovedDefault": "تُعرض حملاتك الخاصة افتراضيًا إذا تم تفعيل \"عرض الحملة\" في إدارة الحملات.",
  "screen.pending": "متاحة جديدة",
  "screen.viewPendingCampaignRequests": "اعتماد الحملات المتاحة",
  "screen.approve": "اعتماد",
  "screen.remove": "إزالة",
  "screen.removing": "جارٍ الإزالة",
  "screen.approving": "جارٍ الاعتماد",
  "screen.displayOwnCampaigns": "عرض حملاتي.",
  "screen.displayOnlyApproved": "عرض الحملات المعتمدة من الآخرين.",
  "screen.displayAllCampaigns": "عرض جميع الحملات من الآخرين.",
  "screen.updated": "تم التحديث",
  "screen.owner": "المالك",
  "screen.campaign": "الحملة",
  "screen.media": "الوسائط",
  "screen.view": "عرض",
  "screen.lastUpdated": "آخر تحديث",
  "screen.gameMedia": "وسائط اللعبة",
  "screen.cta": "دعوة إلى الإجراء",
  "screen.locale": "اللغة",
  "screen.localeArabic": "العربية",
  "screen.localeEnglish": "الإنجليزية",
  "screen.selectShowcaseError": "اختر شاشتك",
  "screen.codeNotMatched": "الرمز لا يتطابق؟",
  "screen.scanQRMessage": "اغمر نفسك في عالم المرح. امسح QR للعب!",
  "screen.scanQRMessage0": "عروض محدودة داخل. امسح QR الآن!",
  "screen.scanQRMessage1": "لا تفوت الفرصة! امسح QR للحصول على مكافآت حصرية.",
  "screen.scanQRMessage2": "العب الآن واربح كثيرًا. امسح رمز QR اليوم!",
  "screen.inQuestion": "سؤال التقسيم",
  "activity.roundWon": "فزت بالجولة",
  "activity.roundLost": "خسرت الجولة",
  "activity.hint1GamePokerAS": "توزيع البطاقات",
  "activity.hint2GamePokerAS": "عرض البطاقات",
  "screen.showcaseMoveToManageMessage": "سيتم عرض حملاتك تلقائيًا عند تفعيل خيار \"عرض الحملة\" في إدارة الحملات.<br><br>للتعاون مع الآخرين وكسب المال، يمكنك اختيار عرض حملات المستخدمين الآخرين. اضبط كيفية عرض حملات المستخدمين الآخرين بالنقر على \"التحكم في اعتماد الحملات\".",
  "screen.approved": "تمت الموافقة",
  "screen.waiting": "في انتظار",
  "screen.denied": "تم الرفض",
  "screen.deny": "رفض",
  "screen.denying": "جارٍ الرفض",
  "screen.interactiveScreen": "شاشة لمس",
  "screen.nonInteractiveScreen": "تلفاز",
  "screen.QR": "QR",
  "screen.noDeniedCampaignAvailable": "لا توجد حملات مرفوضة",
  "screen.noCampaignAvailable": "لا توجد حملات متاحة",
  "screen.viewContent": "عرض المحتوى",
  "screen.changeToManualApproval": "تغيير إلى الموافقة اليدوية",
  "screen.changeToAutoApproval": "تغيير إلى الموافقة التلقائية",
  "screen.changeToManualApprovalPrompt": "التبديل إلى الموافقة اليدوية يعني أن جميع الحملات تحتاج إلى موافقتك قبل عرضها في العرض. تبقى الحملات المعتمدة نشطة حتى تقوم بإزالتها. هل أنت متأكد من رغبتك في المتابعة؟",
  "screen.changeToAutoApprovalPrompt": "التبديل إلى الموافقة التلقائية سيعرض جميع الحملات الجديدة تلقائيًا دون الحاجة إلى مراجعتك. يمكن أن يوفر هذا الوقت وربما يزيد من أرباحك. هل أنت متأكد من رغبتك في المتابعة؟",
  "screen.showAllCampaigns": "وضع الموافقة التلقائي",
  "screen.showSelectedCampaigns": "وضع الموافقة اليدوي",
  "screen.modeFirstLoadPrompt": "موافقتك مطلوبة لعرض حملات الآخرين في شاشتك.<br>التبديل إلى الموافقة التلقائية لعرض جميع الحملات دون مراجعة.<br><br><strong>يرجى الملاحظة: لا تحتاج حملاتك إلى الموافقة.</strong>",
  "screen.showcaseAdded": "{value} تم الإضافة بنجاح",
  "screen.showcaseUpdated": "تم تحديث العرض بنجاح",
  "screen.earning": "الأرباح",
  "menu.settingsMore": "الإعدادات والمزيد",
  "menu.balance": "الرصيد",
  "menu.businessesWallets": "محافظ الأعمال",
  "wallet.noBusinessesWallets": "لا توجد محافظ أعمال متاحة",
  "wallet.business": "العمل",
  "wallet.balance": "الرصيد",
  "settings.businessesWallets": "الإعدادات > محافظ الأعمال",
  "generic.valueWithCurrency": "{currency}{value}",
  "screen.joinRab7ToPlay": "انضم إلى Rab7 للعب",
  "activity.use": "استخدم",
  "activity.insufficientBalanceToConsume": "رصيد Rab7 Coins غير كافٍ، تحتاج على الأقل {value} عملات للاستخدام",
  "payment.paidInCoin": "تم الدفع بالعملات",
  "payment.coinPaid": "تم الدفع بالعملة",
  "inShowcase": "في العرض",
  "notInShowcase": "ليس في العرض",
  "screen.screenInsight": "إحصاءات العرض",
  "menu.notificationSettings": "إعدادات الإشعارات",
  "profile.notificationSettings": "إعدادات الإشعارات",
  "profile.notifyActivityApproval": "إشعار عند توفر حملات للمراجعة",
  "profile.emailActivityApproval": "إرسال بريد إلكتروني عند توفر حملات للمراجعة",
  "profile.notificationSettingsUpdated": "تم تحديث إعدادات الإشعارات",
  "landing.countUsers": "المستخدمين",
  "landing.countCampaigns": "الحملات",
  "landing.countPlays": "عدد مرات اللعب",
  "landing.countCities": "المدن المشمولة",
  "landing.sectionWhatWeDo": "ماذا نقدم؟",
  "landing.sectionWhatWeDoTitle": "إنشاء اتصالات تفاعلية",
  "landing.sectionWhatWeDo1": "• تمكين الشركات، والمؤثرين، والمعلمين بأدوات مخصصة للتفاعل.",
  "landing.sectionWhatWeDo2": "• إنشاء اختبارات وألعاب واستبيانات وReels بعلامة تجارية بسهولة.",
  "landing.sectionWhatWeDo3": "• التقاط رؤى الجمهور ودفعهم للعمل بتصميم بسيط وسهل الاستخدام.",
  "landing.sectionWhatWeDo4": "• تواصل بعمق مع تقسيم الجمهور والتخصيص المدمج - لا حاجة للمهارات التقنية!",
  "landing.sectionHowItWorks": "كيف يعمل؟",
  "landing.sectionHowItWorksTitle": "أنشئ حملتك المثالية",
  "landing.sectionHowItWorks1": "1. <strong>اختر وخصص</strong>: اختر تجربة تفاعلية من مكتبتنا، وخصصها بشعارات ورسائل علامتك التجارية.",
  "landing.sectionHowItWorks2": "2. <strong>تفاعل وقدم المكافآت</strong>: شجع المشاركة بمكافآت وعروض مخصصة لجمهورك.",
  "landing.sectionHowItWorks3": "3. <strong>حدد جمهورك</strong>: اختر الديموغرافيا، والجغرافيا، وحجم الجمهور للوصول الأقصى.",
  "landing.sectionHowItWorks4": "4. <strong>حدد المدة والأهداف</strong>: اختر مدة الحملة وفقًا لأهدافك، من العروض قصيرة الأجل إلى التفاعل طويل الأمد.",
  "landing.sectionHowItWorks5": "5. <strong>شارك وقس النتائج</strong>: أنشئ روابط فريدة ورموز QR، ثم تتبع النجاح مع رؤى عن ديموغرافيا واهتمامات الجمهور.",
  "landing.createCampaign": "أنشئ حملتك",
  "landing.startFreeNow": "ابدأ مجانًا الآن",
  "landing.contactEmail": "البريد الإلكتروني للتواصل: {value}",
  "landing.privacyPolicy": "سياسة الخصوصية",
  "landing.tnc": "الشروط والأحكام",
  "landing.footerNote": "موقع Rab7.com مملوك لشركة سنّم لحلول الاتصالات وتقنية المعلومات، ومسجل في مركز الأعمال السعودي برقم (0000003896).",
  "landing.copyright": "<span>&#169;</span> 2023 Rab7.com",
  "landing.partnersTitle": "شركاؤنا",
  "landing.partnersSubTitle": "العمل معًا للتفاعل مع الجمهور وخلق اتصالات ذات معنى.",
  "landing.priceTitle": "أسعار مرنة تناسب كل احتياجاتك",
  "landing.priceSubTitle": "ابدأ مجانًا، مع خطط تبدأ من 12 دولارًا. خصص المشاهدات والمدة بما يتناسب مع أهدافك.",
  "landing.testimonialTitle": "آراء مستخدمينا",
  "landing.testimonialSubTitle": "استمع إلى كيف ساعدنا الشركات على التواصل، والتفاعل، والنمو.",
  "landing.testimonials1": "جعل Rab7.com من السهل جدًا إنشاء حملة تفاعلية لعلامتنا التجارية. خيارات التخصيص لا حصر لها، وقد رأينا نتائج رائعة بأقل جهد!",
  "landing.testimonials2": "بصفتي معلمة، أردت أداة بسيطة لإنشاء محتوى تفاعلي. كان Rab7.com مثاليًا - سهل الاستخدام وفعال في جذب تفاعل طلابي بطرق جديدة!",
  "landing.testimonials3": "كان إعداد الحملة سريعًا، وساعدتنا الرؤى في فهم جمهورنا بشكل أفضل. لاحظنا زيادة ملحوظة في التفاعل والتحويلات!",
  "landing.testimonials4": "سمحت لنا مرونة المنصة بإنشاء لعبة بعلامة تجارية تناسب جمهورنا تمامًا. نحب مدى سهولة تحفيز التفاعل وتتبع الأداء!",
  "landing.testimonials5": "من البداية إلى النهاية، كان استخدام Rab7.com سهلاً للغاية. ساعدنا على الوصول إلى المزيد من الأشخاص وجمع بيانات قيمة للحملات المستقبلية!",
  "landing.testimonials6": "مع Rab7.com، أنشأنا لعبة مخصصة لحملتنا في دقائق. أحب عملاؤنا اللعبة، وكانت الرؤى التي حصلنا عليها ذات قيمة كبيرة!",
  "landing.testimonials7": "المنصة غيرت قواعد اللعبة! كان التخصيص سهلاً وساعدنا على الوصول إلى جمهورنا المستهدف بمحتوى تفاعلي مؤثر.",
  "landing.testimonials8": "حول Rab7.com طريقة تفاعلنا مع العملاء. العملية سلسة، وشهدنا زيادة ملحوظة في تفاعل العملاء!",
  "landing.testimonials9": "كان إنشاء تجربة بعلامة تجارية تحديًا، ولكن Rab7.com جعلها سريعة وفعالة. أصبح الآن أداة أساسية في استراتيجيتنا التسويقية!",
  "landing.testimonials10": "مكتبة الألعاب الواسعة وسهولة الاستخدام جعلت من Rab7.com خيارنا الأول للتسويق التفاعلي. لقد وصل تفاعل جمهورنا إلى آفاق جديدة!",
  "landing.testimonialsUser1": "حسن الفارسي",
  "landing.testimonialsUser2": "عمر السعدي",
  "landing.testimonialsUser3": "ليلى خالد",
  "landing.testimonialsUser4": "أميرة نصار",
  "landing.testimonialsUser5": "فهد زمان",
  "landing.testimonialsUser6": "رانيا المحمد",
  "landing.testimonialsUser7": "يوسف درويش",
  "landing.testimonialsUser8": "مريم الحربي",
  "landing.testimonialsUser9": "خالد الشامي",
  "landing.testimonialsUser10": "نورة الفهد",
  "activity.noCampaigns": "لم تقم بإضافة أي حملة بعد. استخدم زر \"+\" لإضافة حملة الآن.",
  "landing.menuPartners": "الشركاء",
  "landing.menuProcess": "العملية",
  "landing.menuPricing": "الأسعار",
  "landing.menuTestimonials": "آراء العملاء",
  "landing.countCountries": "الدول المشمولة",
  "landing.countGames": "مكتبة الألعاب",
  "landing.bannerTitle1": "لماذا نقوم بذلك ؟",
  "landing.bannerTitle2": "ما هي القيمة ؟",
  "landing.bannerTitle3": "كيف يعمل ؟",
  "landing.bannerTitle4": "لماذا نحن الأفضل ؟",
  "landing.bannerSubTitle1": "اجعل التسويق التفاعلي سهلًا، بسيطًا، وبأسعار معقولة.",
  "landing.bannerSubTitle2": "الوعي بالعلامة التجارية<br>زيادة المبيعات<br>الحصول على رؤى العملاء.",
  "landing.bannerSubTitle3": "في غاية السهولة!<br>اختر لعبة وقم بتخصيصها في 20 ثانية وبدون مهارات تقنية.",
  "landing.bannerSubTitle4": "مكتبة غنية بالألعاب<br>سهل الإنشاء والتعديل<br>تعمل بشكل تلقائي بالكامل",
  "landing.sectionBannerTitle": "Rab7.com<br>تواصل، أثر و اكتشف",
  "landing.sectionBannerSubTitle": "اول منصة لإنشاء التجارب التفاعلية الغنية بالبيانات",
  "activity.mobileRequiredNonRegistered": "# سيتم استخدامه للتواصل معك إذا فزت بالجائزة",
  "activity.guessCorrectAnswerPrompt": "الإجابة المختارة غير صحيحة، يرجى اختيار الإجابة الصحيحة.",
  "activity.changeGuess": "تغيير التخمين",
  "activity.confirmGuess": "تأكيد الخيار؟",
  "activity.demoCampaignPrompt": "هذه حملة تجريبية بميزات محدودة. أنشئ حملتك الخاصة لاستكشاف كل ما نقدمه!",
  "activity.noCampaignsDemoOnly": "استكشف الحملة التجريبية.<br><br>استخدم زر \"+\" لإضافة حملتك الآن.",
  "activity.confirmAnswerNPlay": "تأكيد والانتقال للعب؟",
  "manage.confirm": "تأكيد",
  "activity.rewardsViewTitle": "اربح لتكون مؤهلاً لدخول السحب على الجائزة",
  "menu.partners": "الشركاء",
  "menu.managePartners": "إدارة عرض الشركاء",
  "partner.noPartnersEnabled": "لا يوجد شركاء معتمدون",
  "partner.noPartnersAvailable": "لا يوجد المزيد متاح",
  "partner.tabEnabled": "المعتمدون",
  "partner.tabAvailable": "المتاحون",
  "partner.name": "الشريك",
  "partner.approve": "اعتماد",
  "partner.remove": "إزالة",
  "partner.removing": "جارٍ الإزالة",
  "partner.approving": "جارٍ الاعتماد",
  "activity.guessDistribution": "توزيع الخيارات",
  "activity.rewardCodeDetailsPlaceholder": "تفاصيل المكافأة، كيفية الاسترداد، الشروط، إلخ.",
  "campaign.tabUserInfo": "معلومات الحساب",
  "campaign.userInfoTitle": "أدخل معلومات حسابك",
  "campaign.userInfoSubTitle": "يمكنك تحديثها لاحقًا من خلال الإعدادات → تعديل الملف الشخصي إذا لزم الأمر.",
  "activity.userImage": "صورتك",
  "activity.userName": "اسمك",
  "activity.checkOfferVoucher": "لا توجد رموز قسائم! نحتاج إلى بريد إلكتروني أو رقم هاتف لمعالجة المكافأة مباشرة.",
  "activity.extraEmail": "البريد الإلكتروني",
  "activity.extraMobile": "رقم الجوال",
  "activity.moreInfoTypeRequired": "اختر من البريد الإلكتروني أو رقم الجوال المطلوب",
  "activity.rewardProcessedEmail": "يتم إرسال قسيمتك إلى بريدك الإلكتروني المسجل.",
  "activity.rewardProcessedMobile": "يتم إرسال قسيمتك إلى رقم الجوال الذي قدمته.",
  "activity.assistanceContactCampaigner": "# إذا كانت لديك أي أسئلة أو تحتاج إلى مساعدة، يرجى الاتصال بمنظم الحملة على <a href='mailto:{value}'>{value}</a>",
  "activity.needCustomerContact": "أدخل رقم هاتفك للتواصل ولتكون مؤهلاً لدخول السحب على الجائزة بتاريخ {value}",
  "activity.needCustomerContactWon": "قدم رقم هاتفك لمعالجة مكافأتك.",
  "activity.eligibleToRaffle": "أنت مؤهل للسحب على الجائزة في {value}",
  "activity.validationMobileRequired": "أدخل رقم الجوال مع رمز البلد",
  "activity.manage.101.title": "ميات سريعة",
  "activity.manage.102.title": "ضربات حرة",
  "activity.manage.103.title": "باكمان",
  "activity.manage.104.title": "حركة المرور",
  "activity.manage.105.title": "جنون المدفع",
  "activity.manage.106.title": "اندفاع الثعبان",
  "activity.manage.107.title": "صائد الأقواس",
  "activity.mobileRequiredNonRegisteredWon": "ستتم إشعارك عند معالجة مكافأتك.",
  "activity.updateContactNumber": "تحديث رقم الاتصال",
  "campaign.userWonNeedMobileDetailMessage": "مكافأتك معلقة. قم بتحديث رقم هاتفك لاستلامها.",
  "activity.mobileRequiredReel": "رقم هاتفك مطلوب للتواصل معك في حال فوزك بالمكافأة. قم بتحديث رقم الهاتف للمتابعة لإضافة منشورك.",
  "landing.menuLibrary": "المكتبة",
  "landing.libraryTitle": "مكتبة الألعاب",
  "landing.librarySubTitle": "اكتشف مجموعة متنوعة من الألعاب القابلة للتخصيص بسهولة باستخدام شعار علامتك التجارية ورسائلك المرئية لحملات مؤثرة.",
  "activity.passChallengeJoinUs": "أدخل بريدك الإلكتروني للمشاركة في السحب على الجائزة واستلام تفاصيل المكافأة.",
  "activity.mobileNumberWithCode": "رقم الجوال (مع رمز الدولة)",
  "activity.manage.108.title": "رشامبو",
  "landing.clickToPreview": "# انقر على الألعاب أدناه لمعاينتها",
  "generic.customise": "تخصيص",
  "activity.dummyReward": "سيتم عرض المكافأة التي أضفتها هنا",
  "activity.dummyPlayerListMessage": "سيتم عرض قائمة اللاعبين هنا",
  "activity.dummyWinnerMessage": "سيتم عرض قائمة الفائزين هنا",
  "activity.needNameWon": "قدم اسمك لمعالجة مكافأتك.",
  "activity.updateName": "قم بتحديث اسمك",
  "activity.gameOverProvideName": "ارتقِ بترفيهك مع رابح! قدم اسمك.",
  "activity.gameOverProvideNamePlayAgain": "قدم اسمك للحصول على فرصة أخرى للفوز!",
  "activity.needCustomerName": "أدخل اسمك لتكون مؤهلاً لسحب المكافأة في {value}",
  "activity.ctaTitle.playNow": "العب الآن",
  "activity.ctaTitle.startNow": "ابدأ الآن",
  "activity.ctaTitle.joinNow": "انضم الآن",
  "activity.ctaTitle.shareNow": "شارك الآن",
  "showcase.guideShort": "العب واربح اللعبة للدخول في سحب المكافأة",
  "showcase.guideShortNR": "العب واربح اللعبة",
  "showcase.rewardPlay0": "لنلعب!",
  "showcase.rewardPlay1": "ابدأ اللعب!",
  "showcase.rewardPlay2": "العب الآن!",
  "activity.options": "خيارات",
  "activity.rewardRaffleTitle": "توقع لتكون مؤهلا لدخول السحب على الجائزة",
  "activity.loadingMsg1": "الحماس في انتظارك!",
  "activity.loadingMsg2": "جاري تحميل المرح!",
  "activity.loadingMsg3": "استعد للعب!",
  "activity.playChallenge": "الإنتقال للتحدي",
  "activity.wonChallengeNoRewardInQuestion": "تعليقاتك تعني لنا الكثير!",
  "activity.wonChallengeInQuestion": "أجب على السؤال أدناه لفتح جائزتك.",
  "activity.winEligibleInQuestion": "أكمل هذا للدخول في السحب على الجائزة.",
  "campaign.manage.gamesFun": "الألعاب",
  "campaign.manage.interactiveFun": "تفاعلي",
  "activity.rewardsLoadingError": "تعذر جلب الجوائز، يرجى المحاولة مرة أخرى.",
  "activity.copyReward": "املأ من الجوائز السابقة",
  "activity.cancelCopyReward": "إلغاء الملء",
  "activity.sharePrompt": "شارك المتعة! 🎉 ادعُ أصدقاءك للعب واستمتعوا بفرصة الفوز بجوائز مثيرة معًا!",
  "activity.opponentsPW": "المنافسون",
  "activity.gameResult": "نتيجة المباراة",
  "activity.selectGameResult": "اختر نتيجة المباراة",
  "activity.manage.predictionPlaceholder1PW": "اسم المنافس-1",
  "activity.manage.predictionPlaceholder2PW": "اسم المنافس-2",
  "activity.manage.prediction1ImagePW": "صورة المنافس-1",
  "activity.manage.prediction2ImagePW": "صورة المنافس-2",
  "activity.matchDraw": "تعادل المباراة",
  "activity.opponentWon": "{value} فاز",
  "activity.manage.12-1.description": "يتوقع المستخدمون النتيجة ويدخلون في سحب الجوائز للتوقعات الصحيحة.",
  "activity.manage.12.description": "يتوقع المستخدمون نتيجة المباراة ويدخلون في سحب الجوائز للتوقعات الصحيحة.",
  "activity.dummyPredictWinnerTitle": "توقع نتيجة المباراة",
  "activity.opponent1PW": "OP-1",
  "activity.opponent2PW": "OP-2",
  "activity.opponentWin": "فوز\n{value}",
  "activity.drawNoResult": "تعادل\n",
  "activity.confirmPrediction": "تسجيل التوقع المختار؟",
  "activity.noRewardsPresent": "لا توجد مكافآت سابقة متاحة للملء",
  "showcase.scanPlayFun": "امسح العب استمتع اربح",
  "showcase.rewardHow0": "ودك تعرف كيف تربح ؟",
  "showcase.rewardHow1": "خلنا نكتشف كيف",
  "showcase.rewardHow2": "جاهز تاخذها ؟",
  "showcase.present0": "نقدم لك طريقة رهيبة للفوز!",
  "showcase.present1": "متحمسين تفوز معنا بالمكافأة!",
  "showcase.present2": "تحمس معنا واربح هذه المكافأة الرائعة!",
  "showcase.presentNR0": "أطلق الحماس واستمتع بالرحلة!",
  "showcase.presentNR1": "عش الفرح والإثارة كما لم تعشها من قبل!",
  "showcase.presentNR2": "ادخل إلى عالم مليء بالإثارة والإمكانات اللامحدودة!",
  "showcase.justFun": "مستعدين ؟",
  "showcase.poweredBy": "مدعوم من",
  "showcase.enjoyedPreview": "جاء دورك الان",
  "screen.previews": "المعاينات",
  "screen.qrScans": "مسح QR",
  "activity.manage.109.title": "بونك",
  "screen.activateScreenWithName": "تفعيل الشاشة الرقمية \"{value}\"",
  "screen.activateScreenPrompt": "هل تريد المتابعة للتفعيل ؟",
  "screen.activate": "تفعيل",
  "activity.type.slides": "إعلانات",
  "activity.manage.37.title": "إعلانات",
  "activity.manage.37.description": "أنشئ حملة عرض شرائح جذابة باستخدام الصور ومقاطع الفيديو والنصوص لجذب جمهورك.",
  "activity.manage.38.title": "إعلانات",
  "activity.manage.38.description": "قم بتحميل الصور، وأضف النص، وأنشئ قائمة ديناميكية للشاشات الكبيرة أو شاشات التلفزيون.",
  "campaign.tabSlides": "الشرائح",
  "campaign.tabSlidesHint": "قم بتكوين الشرائح الخاصة بك",
  "activity.addAnotherSlide": "أضف شريحة أخرى",
  "activity.correctErrorSlide": "املأ الحقول المطلوبة أعلاه قبل إضافة شريحة جديدة",
  "activity.slideTitle": "النص",
  "activity.hashSlideWithNumber": "#شريحة-{value}",
  "activity.slideMediaOrTextRequired": "أدخل صورة أو نصًا",
  "activity.moveUpSlide": "تحريك هذه الشريحة لأعلى",
  "activity.moveDownSlide": "تحريك هذه الشريحة لأسفل",
  "activity.removeSlide": "إزالة هذه الشريحة",
  "activity.expandSlide": "توسيع هذه الشريحة",
  "activity.collapseSlide": "طي هذه الشريحة",
  "activity.duration": "المدة",
  "activity.inSeconds": "(بالثواني)",
  "activity.clickToPlay": "اضغط لبدء التشغيل",
  "screen.manageCampaignRequests": "التحكم في حملات الآخرين",
  "screen.campaignsApproval": "موافقة حملات الآخرين",
  "screen.campaignsApprovalSetAuto": "موافقة حملات الآخرين: تلقائي",
  "screen.campaignsApprovalSetManual": "موافقة حملات الآخرين: يدوي",
  "screen.manageCampaigns": "إدارة الحملات",
  "screen.manageCampaignsToShow": "التحكم في عرض الحملات",
  "screen.manageCampaignsToShowFirstPrompt": "قم بإدارة حملاتك لتظهر في هذا العرض.<br><br>احتفظ بـ <strong>السماح للجميع</strong> لعرض جميع حملاتك أو قم بالتبديل إلى <strong>محدد</strong> لعرض الحملات المختارة.",
  "screen.manageCampaignsAll": "التحكم في عرض الحملات: السماح للجميع",
  "screen.manageCampaignsSpecific": "التحكم في عرض الحملات: محدد",
  "screen.manageCampaignsControl": "التحكم في عرض الحملات",
  "screen.changeToSpecific": "التغيير إلى محدد",
  "screen.changeToAll": "التغيير إلى السماح للجميع",
  "screen.changeToSpecificPrompt": "سيؤدي التبديل إلى محدد إلى طلب اختيارك لعرض الحملات المحددة في هذا العرض. هل أنت متأكد أنك تريد المتابعة؟",
  "screen.changeToAllPrompt": "سيؤدي التبديل إلى السماح للجميع إلى عرض جميع الحملات في هذا العرض. هل أنت متأكد أنك تريد المتابعة؟",
  "screen.manageCampaignsNoApproved": "لم يتم تمكين أي حملات حتى الآن، قم بتمكين الحملات ليتم عرضها في هذا العرض.",
  "screen.manageCampaignsEnableNow": "تمكين الحملات الآن",
  "screen.manageCampaignsAllEnabled": "تم تمكين جميع الحملات",
  "activity.type": "النوع",
  "screen.enabled": "ممكّن",
  "screen.disabled": "معطل",
  "screen.disable": "تعطيل",
  "screen.disabling": "جارٍ التعطيل",
  "screen.enable": "تمكين",
  "screen.enabling": "جارٍ التمكين",
  "slideshow.imageText": "صورة + نص",
  "slideshow.text": "نص",
  "slideshow.image": "صورة",
  "slideshow.dummy1Text": "قم بتخصيص الشرائح باستخدام الصور والنصوص لعرض حملتك.",
  "activity.showcaseCampaignHint3": "افتح <strong>show.rab7.com</strong> على شاشتك الرقمية للإعداد.",
  "activity.dummyMenu1": "أنشئ قائمة لشاشات التلفزيون الخاصة بك مع الصور والنصوص لعرضها في المتاجر!",
  "activity.type.showcaseMenu": "القائمة",
  "activity.campaignDateTime": "تاريخ ووقت الحملة",
  "activity.endDate": "تاريخ الانتهاء",
  "activity.weekDays": "أيام الأسبوع",
  "activity.manage.startTime": "وقت البدء",
  "activity.manage.endTime": "وقت الانتهاء",
  "activity.timeOptional": "# اتركه فارغًا لتعيين الوقت طوال اليوم",
  "activity.selectDay": "حدد يومًا واحدًا على الأقل",
  "activity.showcaseMenuCreated": "تم إنشاء القائمة بنجاح!<br>قم بإعداد شاشتك الرقمية وتفعيل هذه الحملة لعرضها.",
  "activity.showcaseMenuUpdated": "تم تحديث القائمة!",
  "activity.horizontal": "أفقي",
  "activity.vertical": "عمودي",
  "activity.menuForOrientation": "اختر تخطيط القائمة الخاصة بك",

}
