import {Scene} from 'phaser';

export class GTGBoot extends Scene {

  constructor() {
    super('GTGBoot');
  }

  preload() {
    this.load.image('background', 'media/gameTrafficGo/bg.1.png');
  }

  create() {
    this.scene.start('GTGPreloader');
  }
}
