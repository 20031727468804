import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import tinycolor from "tinycolor2";
import {ColorBlack, ColorWhite} from "src/styles/Colors";
import TextField from "@material-ui/core/TextField";

export const GameTextField = forwardRef((props, ref) => {
  const {inputRef, onFocus, color = ColorWhite, bgColor = ColorBlack} = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  };
  const handleBlur = () => setIsFocused(false);

  const textAlpha = isFocused ? 1 : isHovered ? 0.9 : 0.8;
  const borderAlpha = isFocused ? 1 : isHovered ? 0.8 : 0.6;
  const bgAlpha = isFocused ? 0.2 : 0.1;
  return (
    <TextField
      inputRef={inputRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      InputLabelProps={{
        style: {
          color: tinycolor(color).setAlpha(textAlpha)
        }
      }}
      InputProps={{
        disableUnderline: true,
        style: {
          backgroundColor: tinycolor(color).setAlpha(bgAlpha),
          borderColor: tinycolor(color).setAlpha(borderAlpha),
          borderStyle: 'solid',
          borderWidth: 1,
          color: color,
        }
      }}
      {...props}
    />
  );
})
