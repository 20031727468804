export default {
  app: {
    platform: 1,
    appVersion: '1.0'
  },
  firebase: {
    senderId: '646042338606',
    publicVapidKey: 'BIo1RYrtSEIAuCpWuRQ0prFJR1DV5G15hGNY9IpbSk44vw5OsKlGGgjXzsJLhkCi7S4xL5JHebVIT3tF-oqCD4s',
  },
  dateTimeFormatPicker: 'dd/MM/yyyy HH:mm', // "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", //Y-m-d\TH:i:s.000\Z
  dateFormatPicker: 'dd/MM/yyyy',
  dateTimeFormat: 'DD/MM/YYYY HH:mm',
  dateFormat: 'DD-MM-YYYY',
  googleMapApiKey: 'AIzaSyBG1JPnpKS3ilT8KJbsF6Bsp1Uyi3Z_OlQ',
  businessStates: {
    DELETED: 0,
    ENABLED: 1,
    SUSPENDED: 3,
    APPROVAL_PENDING: 21,
    APPROVAL_REJECTED: 22,
    BUSINESS_REGISTRATION_PENDING: 23,
  },
  employeeStates: {
    INVITED: 50,
  },
  user: {
    type: {
      CUSTOMER: 1,
      EMPLOYEE: 3,
      SUPPORT: 4,
    }
  },
  access: {
    branchesNotAllowedFor: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      9,
      10,
      11,
      12,
      13,
      16,
      17,
      20,
      21,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      34,
      35,
      36,
    ],
    types: {
      COMMENTS_POST: 1,
      COMMENTS_ACTIVITY: 2,
      CHAT_EMPLOYEE: 3,
      CHAT_CUSTOMER: 4,
      TRANSACTIONS: 5,
      USER_BALANCE: 6,
      INSIGHTS: 7,
      MANAGEMENT: 8,
      PROFILE: 9,
      ACTIVITY: 10,
      SYSTEM_USER: 11,

      ACCESS_ACTIVITY_VIEW: 1,
      ACCESS_ACTIVITY_MANAGE: 3,

      ACCESS_AD_VIEW: 1,
      ACCESS_AD_COMMENT: 2,
      ACCESS_AD_MANAGE: 3,
      ACCESS_OFFER_VIEW: 4,
      ACCESS_OFFER_COMMENT: 5,
      ACCESS_OFFER_MANAGE: 6,
      ACCESS_QUESTION_VIEW: 7,
      ACCESS_QUESTION_COMMENT: 8,
      ACCESS_QUESTION_MANAGE: 9,
      ACCESS_BUYER_OPINION_VIEW: 10,
      ACCESS_BUYER_OPINION_MANAGE: 11,
      ACCESS_VISITOR_OPINION_VIEW: 12,
      ACCESS_VISITOR_OPINION_MANAGE: 13,

      ACCESS_REVIEW_VIEW: 14,
      ACCESS_REVIEW_COMMENT: 15,
      ACCESS_CHAT_CUSTOMER_VIEW: 16,
      ACCESS_CHAT_CUSTOMER_COMMENT: 17,
      ACCESS_CHAT_EMPLOYEE_VIEW: 18,
      ACCESS_CHAT_EMPLOYEE_COMMENT: 19,
      ACCESS_CHAT_SUPPORT_VIEW: 20,
      ACCESS_CHAT_SUPPORT_COMMENT: 21,

      ACCESS_TRANSACTION_VIEW: 22,
      ACCESS_USER_BALANCE_VIEW: 23,
      ACCESS_LOYALTY_FORMULA_VIEW: 24,
      ACCESS_LOYALTY_FORMULA_MANAGE: 25,

      ACCESS_PRODUCT_VIEW: 26,
      ACCESS_PRODUCT_MANAGE: 27,
      ACCESS_GIFT_VIEW: 28,
      ACCESS_GIFT_MANAGE: 29,
      ACCESS_BRANCH_VIEW: 30,
      ACCESS_BRANCH_MANAGE: 31,
      ACCESS_EMPLOYEE_VIEW: 32,
      ACCESS_EMPLOYEE_MANAGE: 33,
      ACCESS_SYSTEM_USER_VIEW: 34,
      ACCESS_SYSTEM_USER_MANAGE: 35,
      ACCESS_PROFILE_MANAGE: 36,

      ACCESS_INSIGHT_CAMPAIGN: 37,
      ACCESS_INSIGHT_PRODUCT: 38,
      ACCESS_INSIGHT_PROFILE: 39,
      ACCESS_INSIGHT_SALE: 40,

    }
  },
  notifications: {
    type: {
      TYPE_REPLY_IN_COMMENT: 21,
      TYPE_COMMENT_IN_ACTIVITY: 22,
      TYPE_REPLY_IN_COMMENT_IN_ACTIVITY: 23,
      TYPE_MESSAGE: 30,
      TYPE_SYSTEM: 40,
      TYPE_ACTIVITY_APPROVED: 43,
      TYPE_ACTIVITY_REJECTED: 44,
      TYPE_REVIEW: 26,
      TYPE_SHOWCASE_CAMPAIGN_REVIEW: 500,
    },
    state: {
      NEW: 40,
      PUSHED: 41,
      READ: 42
    }
  },
  helps: {
    ACTIVITIES: 101,
    ANALYTICS: 102,
    BRANCH: 103,
    CHATS: 104,
    EARN_POINTS: 105,
    EMPLOYEE: 106,
    GIFT: 107,
    POSTS: 108,
    PROFILE: 109,
    REDEEM_POINTS: 110,
    TRANSACTION: 111,
    SYSTEM_USER: 112,
    USER_BALANCES: 113,
    MANAGEMENT_ACTIVITIES: 114,
    REGISTRATION: 115,
    MANAGEMENT_ACTIVITY_ADD: 116
  },
  owingTypes: [
    {
      value: 1,
      label: 'National'
    },
    {
      value: 2,
      label: 'Multinational'
    }
  ],
  roles: [
    {
      value: 31,
      label: 'Cashier'
    },
    {
      value: 32,
      label: 'Manager'
    },
    {
      value: 33,
      label: 'System'
    }
  ],
  customerChats: {
    state: {
      ACTIVE: 1,
    },
  },
  employeeChats: {
    state: {
      ACTIVE: 1,
    },
  },
  supportChats: {
    state: {
      ACTIVE: 1,
    },
  },
  activity: {
    VALUE_DEFAULT_MAX: 999999999,
    VALUE_OFFER_MAX_IN_CAMPAIGN: 100,
    VALUE_SPIN_MAX: 99,
    types: {
      SMART_AD: 1,
      OFFER: 2,
      VOUCHER: 200,
      VOTE: 3,
      QUESTION: 4,
      EMPLOYEE_OFFER: 5,
      BUYER_OPINION: 6,
      VISITOR_OPINION: 7,
      DISCOUNT: 8,
      PRICE_TARGETING: 9,
      PROMOTED_POST: 10,
      CHALLENGE: 11,
      PREDICTION: 12,
      JACKPOT: 13,
      GAME_FLIP_CARD: 15,
      EVENT: 16,
      LIKE_USER: 17,
      COLLECT_STARS: 18,
      GUESS: 19,
      GAME_ROULETTE: 20,
      SOCIAL_CONTENT: 21,
      GAME_SPIN_N_DISCOVER: 22,
      GAME_FIND_HIDDEN: 23,
      GAME_GREEDY_SPIN: 24,
      GAME_BETTING_PIC: 25,
      GAME_MATCH_PAIR: 26,
      GAME_TIC_TAC_TOE: 27,
      QUESTIONNAIRE: 28,
      GAME_MATCH_THREE: 29,
      GAME_PUZZLE: 30,
      GAME_WHAC_A_MOLE: 31,
      GAME_BRAND_RACE: 32,
      GAME_POKER: 33,
      IDENTIFY_LOCATION: 34,
      GAME_PUZZLE_SLIDE: 35,
      SURVEY: 36,
      SLIDES: 37,
      SHOWCASE_MENU: 38,
      EXTERNAL_GAMES: 100,
    },
    usageTypes: {
      MARKETING: 1,
      LOYALTY: 2,
      EDUCATION: 3,
      TRAINING: 4,
      SOCIAL: 5,
      INFLUENCER: 6,
    },
    state: {
      DELETE: 0,
      ACTIVE: 1,
      INACTIVE: 2,
      APPROVAL_PENDING: 70,
      REJECTED: 71,
      WAITING: 72,
    },
    plans: {
      NO_PLAN: 1,
      FREE_PLAN: 2,
      PAID_PLAN: 3
    },
    displaySpaces: {
      // SMART_AD
      1: {
        'admin.activity.display_spaces.acknowledgements': 0,
        'admin.activity.display_spaces.everywhere': 1,
        'admin.activity.display_spaces.feeds': 2,
        'admin.activity.display_spaces.special_prompts': 3,
      },
      6: {
        'admin.activity.display_spaces.acknowledgements': 0,
        'admin.activity.display_spaces.everywhere': 1,
        'admin.activity.display_spaces.feeds': 2,
        'admin.activity.display_spaces.special_prompts': 3,
      },
      7: {
        'admin.activity.display_spaces.acknowledgements': 0,
        'admin.activity.display_spaces.everywhere': 1,
        'admin.activity.display_spaces.feeds': 2,
        'admin.activity.display_spaces.special_prompts': 3,
      },
      // OFFER
      2: {
        'admin.activity.display_spaces.mine': 0,
        'admin.activity.display_spaces.everywhere': 1,
        'admin.activity.display_spaces.feeds': 2,
        'admin.activity.display_spaces.special_prompts': 3,
      },
      5: {
        'admin.activity.display_spaces.mine': 0,
        'admin.activity.display_spaces.everywhere': 1,
        'admin.activity.display_spaces.feeds': 2,
        'admin.activity.display_spaces.special_prompts': 3,
      }
    }
  },
  localities: {
    regionType: 2,
    localityType: 3,
    subLocalityType: 4
  },
  table: {
    page: 1,
    perPage: 20
  },
  media: {
    audio: {
      mimeTypes: 'audio/mpeg,audio/mp3,audio/mp4,audio/m4a,audio/x-m4a,video/mp4,video/m4a'
    },
    image: {
      mimeTypes: 'image/png,image/jpg,image/jpeg'
    }
  },
  validations: {
    registration: {
      name: {
        isRequired: true,
        maxChar: 200
      },
      name_ar: {
        isRequired: false,
        maxChar: 200
      },
      business_name: {
        isRequired: true,
        maxChar: 200
      },
      recordNumber: {
        isRequired: true,
        maxChar: 100
      },
      imageFile: {
        isRequired: true,
        maxSize: 2 * 1000 * 1000 // 2MB
      },
      recordImageFile: {
        isRequired: true,
        maxSize: 2 * 1000 * 1000 // 2MB
      },
      owingType: {
        isRequired: true,
        minNum: 1,
        maxNum: 3
      },
      categoryId: {
        isRequired: true
      },
      subCategoryId: {
        isRequired: true
      },
      contactPersonName: {
        isRequired: true,
        maxChar: 100
      },
      contactPersonNumber: {
        isRequired: true,
      },
      contactPersonEmail: {
        isRequired: false,
        maxChar: 100
      },
      localityId: {
        isRequired: true
      },
      subLocalityId: {
        isRequired: false
      },
      latitude: {
        isRequired: true
      },
      longitude: {
        isRequired: true
      },
      username: {
        isRequired: true,
        minChar: 3,
        maxChar: 20,
        regExp: '^[a-zA-Z0-9][a-zA-Z0-9_]*$'
      },
      email: {
        isRequired: true
      },
      mobileNumber: {
        isRequired: true,
        minChar: 9,
        maxChar: 9
      },
      password: {
        isRequired: true,
        minChar: 5,
        maxChar: 20
      },
      confirmPassword: {
        isRequired: true
      }
    },
    branch: {
      name: {
        isRequired: true,
      },
      nameAr: {
        isRequired: true,
      },
      address: {
        isRequired: true,
      },
      addressAr: {
        isRequired: true,
      },
      localityId: {
        isRequired: true
      },
      subLocalityId: {
        isRequired: false
      },
      latitude: {
        isRequired: true
      },
      longitude: {
        isRequired: true
      }
    },
    gift: {
      name: {
        isRequired: true
      },
      imageFile: {
        isRequired: true,
        maxSize: 2 * 1000 * 1000 // 2MB
      },
      pointsToRedeem: {
        isRequired: true
      },
      startAt: {
        isRequired: true
      },
      endAt: {
        isRequired: true
      },
      description: {
        maxChar: 2000,
      },
      tnc: {
        maxChar: 2000,
      },
    },
    earnPointFormula: {
      name: {
        isRequired: true,
        maxChar: 50
      },
      priority: {
        isRequired: true,
        minNum: 1,
        maxNum: 10
      },
      startAt: {
        isRequired: true
      },
      endAt: {
        isRequired: true
      },
      spendsPerPoint: {
        isRequired: true,
        minNum: 1
      }
    },
    redeemPointFormula: {
      name: {
        isRequired: true,
        maxChar: 50
      },
      priority: {
        isRequired: true,
        minNum: 1,
        maxNum: 10
      },
      startAt: {
        isRequired: true
      },
      endAt: {
        isRequired: true
      },
      minSpendsToRedeem: {
        isRequired: true,
        minNum: 1
      },
      minPointsCanRedeem: {
        isRequired: true,
        minNum: 0
      },
      maxPointsCanRedeem: {
        isRequired: true,
        minNum: 0
      },
      maxPointsCanRedeemDaily: {
        isRequired: true,
        minNum: 0
      },
      minWalletBalanceToRedeem: {
        isRequired: true,
        minNum: 0
      }
    },
    employee: {
      mobileNumbers: {
        isRequired: true,
        minChar: 9,
        maxChar: 9
      },
      role: {
        isRequired: true
      },
      branchIds: {
        isRequired: false
      }
    },
    systemUser: {
      mobileNumber: {
        isRequired: true,
        minChar: 9,
        maxChar: 9
      },
      email: {
        isRequired: true
      },
      branchIds: {
        isRequired: false
      },
      access: {
        isRequired: true
      }
    },
    productCategory: {
      name: {
        isRequired: true,
        maxChar: 100,
      },
      nameAr: {
        isRequired: true,
        maxChar: 100
      }
    },
    activity: {
      type: {
        isRequired: true
      },
      title: {
        isRequired: true,
        maxChar: 200
      },
      titleAr: {
        isRequired: false,
        maxChar: 200
      },
      description: {
        isRequired: true,
        maxChar: 2000
      },
      descriptionAr: {
        isRequired: false,
        maxChar: 2000
      },
      tnc: {
        isRequired: false,
        maxChar: 2000
      },
      tncAr: {
        isRequired: false,
        maxChar: 2000
      },
      imageFile: {
        isRequired: true,
        maxSize: 2 * 1000 * 1000 // 2MB
      },
      imageFileAr: {
        isRequired: false,
        maxSize: 2 * 1000 * 1000 // 2MB
      },
      audioFile: {
        isRequired: false,
        maxSize: 2 * 1000 * 1000 // 2MB
      },
      audioFileAr: {
        isRequired: false,
        maxSize: 2 * 1000 * 1000 // 2MB
      },
      priority: {
        isRequired: true,
        minNum: 1,
        maxNum: 10
      },
      startAt: {
        isRequired: true
      },
      endAt: {
        isRequired: true
      },
      displayLimit: {
        maxNum: 999999999
      },
      displayLimitPerUser: {
        maxNum: 999999999
      },
      clickLimit: {
        maxNum: 999999999
      },
      consumeLimit: {
        maxNum: 999999999
      },
      consumeLimitPerUser: {
        maxNum: 999999999
      },
      answerLimit: {
        maxNum: 999999999
      },
      transferLimitPerUser: {
        maxNum: 999999999
      },
      clickedPoints: {
        maxNum: 999999999
      },
      answeredPoints: {
        maxNum: 999999999
      },
      consumedPoints: {
        maxNum: 999999999
      },
      qOptions: {
        name: {
          required: true,
          maxChar: 40,
        },
        nameAr: {
          maxChar: 40
        }
      },
      rightOptionHint: {
        maxNum: 200
      },
      rightOptionHintAr: {
        maxNum: 200
      },
      feedbackHint: {
        maxNum: 200
      },
      feedbackHintAr: {
        maxNum: 200
      },
    },
    profile: {
      bio: {
        maxChar: 200,
      },
    },
    productItem: {
      category: {
        isRequired: true,
      },
      imageUrls: {
        isRequired: true,
        maxAllowed: 3,
      },
      name: {
        isRequired: true,
        maxChar: 100,
      },
      nameAr: {
        isRequired: true,
        maxChar: 100
      },
      details: {
        maxChar: 500,
      },
      detailsAr: {
        maxChar: 500
      },
      price: {
        isRequired: true,
        min: 0
      },
    }
  },
  states: {
    DELETE: 0,
    ACTIVE: 1,
    INACTIVE: 2,
    APPROVAL_PENDING: 70,
    REJECTED: 71,
    WAITING: 72,
  },
};
