import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {baseStyles, FIELD_SPACE, FIELD_SPACE_XS, withMediaQueries, withMultipleStyles} from "src/styles/Styles";
import {Typography} from "@material-ui/core";
import View from "../commons/View";
import {reportActivity, reportActivityPost} from "../../actions/funActions";
import {FontWeightMedium} from "src/res/FontWeight";
import Space from "../commons/Space";
import {GetActionButton, GetImageActionButton} from "../../utils/ActivityHelper";
import FieldSpace from "src/js/components/commons/FieldSpace";
import {SpaceNormal} from "src/res/Spacing";
import {GameTextField} from "src/js/components/commons/GameTextField";

const useStyles = () => ({
});

class ActivityReport extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      reportText: '',
    };

    this.postApi = this.postApi.bind(this);
  }

  render() {
    const {classes, uiConfigs, maxWidth, isXS} = this.props;
    const {reportText, showValidationMsg, errorMsg} = this.state;
    const reportError = showValidationMsg && (!reportText || reportText.trim().length < 2);
    const sizeFactor = uiConfigs.sizeFactor;
    let buttonWidth = (maxWidth - 2 * uiConfigs.infoViewContentHorizontalMargin * sizeFactor - (!isXS ? 2 * SpaceNormal : 0) - (isXS ? FIELD_SPACE_XS : FIELD_SPACE)) / 2;
    buttonWidth = Math.max(150, buttonWidth) / sizeFactor;

    const actionButton = (title, onClick, isNegative, width) => {
      if (uiConfigs.otherActionImage) {
        return (
          <GetImageActionButton
            uiConfigs={uiConfigs}
            sizeFactor={sizeFactor}
            onClick={onClick}
            title={title}
            width={width}
          />
        );
      } else {
        return (
          <GetActionButton
            isNegative={isNegative}
            uiConfigs={uiConfigs}
            onClick={onClick}
            title={title}
          />
        );
      }
    };

    return (
      <div className={classes.flexColumn}>
        <form className={classes.form} onKeyDown={this.handleKeyPress}>
          <Space horizontalSpace={2}/>
          <GameTextField
            color={uiConfigs.overlayTitleColor}
            bgColor={uiConfigs.overlayBgColor}
            variant="filled"
            fullWidth
            required
            multiline
            autoFocus
            rows={2}
            rowsMax={6}
            size="small"
            type="text"
            id="reportText"
            name="reportText"
            value={reportText}
            label={<FormattedMessage id={'activity.describeToReport'}/>}
            inputProps={{maxLength: 200}}
            onChange={this.onChange}
            error={reportError}
            helperText={reportError && <FormattedMessage id='generic.validation.required' />}
          />
          <FieldSpace multiplier={2}/>

          <View className={classes.viewRowFullWidth} style={{justifyContent: 'center'}}>
            {actionButton(<FormattedMessage id={'generic.cancel'} />, this.cancelPost, true, buttonWidth)}
            <FieldSpace vertical={true}/>
            {actionButton(<FormattedMessage id={'generic.submit'} />, this.postApi, false, buttonWidth)}
          </View>
          <FieldSpace/>

          {errorMsg ?
            <React.Fragment>
              <Typography align={"center"} color={"error"} variant="body1" style={{fontWeight: FontWeightMedium}}>
                {errorMsg}
              </Typography>
              <FieldSpace/>
            </React.Fragment>
            : null
          }

        </form>
      </div>
    );
  }

  onChange = (event) => {
    const value = event.target.value;
    this.setState({[event.target.name]: value, showValidationMsg: false, errorMsg: null});
  };

  cancelPost = () => {
    if (this.props.onDone) {
      this.props.onDone();
    }
  };

  postApi = () => {
    const {activityId, activityPostId, onDone} = this.props;
    const {reportText} = this.state;
    if (!reportText || reportText.trim().length < 2) {
      this.setState({showValidationMsg: true, errorMsg: null});
      return;
    }
    this.setState({apiError: null});

    const _this = this;
    const onSuccess = () => {
      if (onDone) {
        onDone(1);
      }
    };
    const onFailure = (response) => {
      let error = null;
      if (response.data && response.data.error) {
        error = response.data.error;
      } else {
        error = this.context.intl.formatMessage({id: 'generic.server_error_msg'});
      }
      _this.setState({errorMsg: error});
    };

    if (activityPostId) {
      const data = {
        reviewId: activityPostId,
        details: reportText
      };
      this.props.reportActivityPost(data).then(onSuccess).catch(onFailure);
    } else if (activityId) {
      const data = {
        activityId: activityId,
        details: reportText
      };
      this.props.reportActivity(data).then(onSuccess).catch(onFailure);
    }
  };
}

ActivityReport.propTypes = {
  classes: PropTypes.object,
  onDone: PropTypes.func,
};

ActivityReport.contextTypes = {
  intl:PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    reportActivity: (data) => dispatch(reportActivity(data)),
    reportActivityPost: (data) => dispatch(reportActivityPost(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMultipleStyles(baseStyles, useStyles)(withMediaQueries([
  ['isXS', theme => theme.breakpoints.down('xs'), {defaultMatches: true}],
])(ActivityReport)));
