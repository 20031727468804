import {Scene} from 'phaser';
import {addTimerEvent, getReadableColor} from "src/game/GameHelper";

export class GPRMainMenu extends Scene {
  constructor() {
    super('GPRMainMenu');
  }

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
  }

  onEventSimulate = () => {
    this.inAutoSimulation = true;
    addTimerEvent(this, 1000, () => {
      this.autoPlay();
    });
  }

  autoPlay = () => {
    this.onClick();
  }

  cleanup = () => {
    this.game.events.off('mute', this.onEventMute);
    this.game.events.off('simulate', this.onEventSimulate);
    if (this.audioGameBg) {
      this.audioGameBg.stop();
    }
    this.input.off('pointerdown');
    this.time.removeAllEvents();
    this.events.off('shutdown');
    this.events.off('destroy');
  }

  create() {
    this.events.on('shutdown', this.cleanup);
    this.events.on('destroy', this.cleanup);
    this.game.events.on('mute', this.onEventMute);
    this.game.events.on('simulate', this.onEventSimulate);

    const stringHint = this.game.options.locale && this.game.options.locale == 'ar' ? 'اضغط للبدء' : 'Click to Start';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    const readableColor = getReadableColor(this.game.options.themeColor);

    this.inAutoSimulation = false;

    if (!this.game.options.simulation) {
      this.audioGameBg = this.sound.add('audio_bg', {loop: true})
        .setMute(this.game.options.mute);
      this.audioGameBg.play();
    }

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);
    const dynamicColor = Phaser.Display.Color.HexStringToColor(this.game.options.themeColor).color; // Convert hex string to Phaser color
    const dynamicAlpha = 0.7;
    const graphics = this.add.graphics();
    graphics.fillStyle(dynamicColor, dynamicAlpha);
    graphics.fillRect(0, 0, gameWidth, gameHeight);

    const ballWidth = 224 * .7;
    const ballHeight = 180 * .7;
    const ballImageSize = ballHeight * .8;

    this.trackCenterX = gameWidth/2;
    this.trackCenterY = gameWidth/2 + ((gameHeight-gameWidth) * .75);
    this.trackRadius = gameWidth/2 - 22 - 11 - ballHeight/2 + 4;

    const outerBorder = 5;
    const innerBorder = 4;
    this.add.circle(this.trackCenterX, this.trackCenterY, gameWidth/2 - 22, 0x334B4A);
    const adImageSize = 2 * (gameWidth/2 - 22 - 2*outerBorder - ballHeight + 5 - 6);
    this.adImage1 = this.add.image(this.trackCenterX, this.trackCenterY, 'adImage1')
      .setDisplaySize(adImageSize, adImageSize)
      .setAlpha(1);
    this.add.circle(this.trackCenterX, this.trackCenterY, gameWidth/2 - 22 - 2*outerBorder - ballHeight/2).setAlpha(0.8).setStrokeStyle(ballHeight, 0x0cccc6);
    this.add.circle(this.trackCenterX, this.trackCenterY, gameWidth/2 - 22 - 2*outerBorder - ballHeight + 5 - 3).setStrokeStyle(2*innerBorder, 0x334B4A);

    this.ball = this.physics.add.image(this.trackCenterX - this.trackRadius, this.trackCenterY, 'ball')
      .setDisplaySize(ballImageSize, ballImageSize);

    this.pacman = this.add.container(this.trackCenterX, this.trackCenterY + this.trackRadius);
    this.pacmanSprite = this.physics.add.sprite(0, 0, 'pacmanS', 'pac1.png')
      .setDisplaySize(ballWidth, ballHeight);
    this.pacman.add(this.pacmanSprite);
    this.pacmanBody = this.physics.add.image((ballWidth-ballHeight)/2, 0, 'striker')
      .setDisplaySize(ballImageSize, ballImageSize)
      .setBodySize(ballImageSize *.7, ballImageSize *.7);
    this.pacman.add(this.pacmanBody);

    const sawSize = gameWidth/4;
    const saw = this.physics.add.image(gameWidth/2, 112, 'saw');
    saw.setDisplaySize(sawSize, sawSize);
    saw.setOffset(0, saw.body.height * .65);
    saw.setBodySize(saw.body.width, saw.body.height * .35, false);

    // const saw = this.add.container(gameWidth/2, 200);
    // const sawOut = this.physics.add.image(0, 0, 'saw');
    // sawOut.setDisplaySize(sawSize, sawSize);
    // sawOut.setOffset(0, sawOut.body.height * .65);
    // sawOut.setBodySize(sawOut.body.width, sawOut.body.height * .35, false);
    // saw.add(sawOut);
    // const sawIn = this.add.image(0, 0, 'sawIn');
    // sawIn.setDisplaySize(sawSize * .8, sawSize * .8);
    // saw.add(sawIn);

    if (!this.game.options.simulation) {
      this.textHint = this.add.text(gameWidth / 2, gameHeight - 56, stringHint, {
        fontFamily: 'Poppins',
        fontSize: 45, color: readableColor,
        align: 'center'
      }).setOrigin(0.5);
    }

    this.input.once('pointerdown', this.onClick);
  }

  onClick = () => {
    this.scene.start('GPRGame', {inAutoSimulation: this.inAutoSimulation});
  }
}
