import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {baseStyles, withMultipleStyles} from "src/styles/Styles";
import {GridList} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import View from "../commons/View";
import {fetchActivityAnswerDistributions, fetchDummyActivityAnswerDistributions,} from "../../actions/funActions";
import {FontWeightMedium} from "src/res/FontWeight";
import {SpaceNormal, SpaceSmall, SpaceXSmall} from "src/res/Spacing";
import AppText from "../commons/AppText";
import TextView from "../commons/TextView";
import {locString} from "../commons/ViewHelper";
import {FontSizeLarge} from "src/res/FontSize";
import ValueHelper from "../../utils/ValueHelper";
import Utils from "../../utils/utils";
import {fetchActivityAnswerDistributionsForBusiness} from "src/js/actions/activitiesActions";

const useStyles = () => ({
  gridItemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    paddingLeft: 8,
    paddingRight: 8,
  },
  messageBox: {
    display: 'flex',
    width: '100%',
    minHeight: 300,
    alignItems: 'center',
    justifyContent: 'center'
  },
  winnerName: {
    textAlign: 'left',
    fontWeight: FontWeightMedium,
  },
  winnerState: {
    marginLeft: SpaceNormal,
    textAlign: 'right',
    fontWeight: FontWeightMedium,
  }
});

class ActivityAnswerDistributions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      items: null,
    };

  }

  componentDidMount() {
    this.fetchActivityAnswerDistributions();
  }

  componentWillUnmount() {
  }

  render() {
    const {classes, items, uiConfigs, activity} = this.props;
    const isLoading = items.isLoading;

    if (isLoading) {
      return (
        <View className={classes.flexColumn} style={{justifyContent: 'center'}}>
          <CircularProgress />
        </View>
      );
    }

    let message = null;
    if (items.errMsg && items.errMsg.length > 0) {
      message = items.errMsg;
    } else if (items.errMsgF && items.errMsgF.length > 0) {
      message = <FormattedMessage id={items.errMsgF}/>;
    }

    return (
      <div className={classes.flexColumn}>
        {!message ?
          <View className={classes.viewColumnFullWidth}>
            <AppText
              fontSizeN={uiConfigs.fontSize}
              variant={'h6'}
              align={'center'}
              style={{
                marginTop: 4 * uiConfigs.sizeFactor,
                color: uiConfigs.overlayHeadingColor,
                fontWeight: FontWeightMedium}}>
              {items.qTitle || activity.qTitle}
            </AppText>
            <View className={classes.viewRow} style={{marginTop: 20, marginBottom: 8, alignItems: 'center', backgroundColor: Utils.colorWithAlpha(uiConfigs.messageBorderColor, 0.1), borderRadius: 8, padding: 4, paddingLeft: 12, paddingRight: 12}}>
              <AppText
                fontSizeN={uiConfigs.fontSize}
                variant={'body1'}
                style={{
                  marginRight: SpaceSmall,
                  color: uiConfigs.overlayHeadingColor,
                  fontWeight: FontWeightMedium}}>
                {locString('totalAnswersColon')}
              </AppText>
              <AppText
                fontSizeN={uiConfigs.fontSize}
                variant={'body1'}
                style={{
                  color: uiConfigs.overlayHeadingColor,
                  fontWeight: FontWeightMedium,
                  fontSize: FontSizeLarge}}>
                {''+ items.totalAnswers}
              </AppText>
            </View>

            <GridList cols={1} className={classes.viewColumnFullWidthNoCenter} cellHeight={'auto'}>
              {items.answers.map((item, index) => {
                return this.getViewForItem(index, item);
              })}
            </GridList>
          </View>
          :
          <div className={classes.messageBox}>
            <TextView fontSizeN={uiConfigs.fontSize} variant={'body1'} color={this.props.uiConfigs.overlaySubTitleColor} className={classes.typoMedium}>
              {message}
            </TextView>
          </div>
        }
      </div>
    );
  }

  getViewForItem(index, item) {
    const {classes, uiConfigs} = this.props;
    const answerName = item.name;
    const answers = item.answers;
    const percentage = item.percentage;
    const decimal = percentage > 10.0 ? 0 : 1;

    let marginTop = 0;
    if (index === 0) {
      marginTop = 8;
    }

    return (
      <View style={{width: '100%', marginTop: marginTop, marginBottom: SpaceXSmall}}>
        <View style={{flex: 1, padding: 16, paddingTop: 6, paddingBottom: 6, backgroundColor: Utils.colorWithAlpha(uiConfigs.messageBorderColor, 0.1), borderRadius: 8}}>
          <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <AppText fontSizeN={uiConfigs.fontSize} align={'left'} style={{flex: 1, color: uiConfigs.overlaySubTitleColor, marginEnd: 16, fontWeight: FontWeightMedium}}>
              {answerName}
            </AppText>
            <View className={classes.viewColumn} style={{alignItems: 'flex-end'}}>
              <AppText fontSizeN={uiConfigs.fontSize} variant={'body2'} style={{textAlign: 'right', color: uiConfigs.overlayTitleColor}}>
                {ValueHelper.getDisplayValue(answers, false)}
              </AppText>
              <AppText fontSizeN={uiConfigs.fontSize} variant={'caption'} style={{color: uiConfigs.overlaySubTitleColor, fontWeight: FontWeightMedium, marginTop: -2}}>
                {ValueHelper.getDisplayValue(percentage, false, decimal) + "%"}
              </AppText>
            </View>
          </View>
        </View>
      </View>
    );
  }

  fetchActivityAnswerDistributions() {
    const {activity, readOnly, forUser} = this.props;
    const activityId = activity.id;
    if (!forUser) {
      this.props.fetchActivityAnswerDistributionsForBusiness(activityId);
    } else if (!readOnly) {
      if (this.props.previewData) {
        setTimeout(() => {
          this.props.fetchDummyActivityAnswerDistributions(activityId, this.props.previewData);
        }, 200);
      } else if (activityId > 0) {
        this.props.fetchActivityAnswerDistributions(activityId);
      }
    }
  }

}

ActivityAnswerDistributions.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.object,
  activity: PropTypes.object,
  previewData: PropTypes.object,
};

ActivityAnswerDistributions.contextTypes = {
  intl:PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    items: state.admin.activityAnswerDistributions,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivityAnswerDistributionsForBusiness: (id) => dispatch(fetchActivityAnswerDistributionsForBusiness(id)),
    fetchActivityAnswerDistributions: (id) => dispatch(fetchActivityAnswerDistributions(id)),
    fetchDummyActivityAnswerDistributions: (id, data) => dispatch(fetchDummyActivityAnswerDistributions(id, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMultipleStyles(baseStyles, useStyles)(ActivityAnswerDistributions));
