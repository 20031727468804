'use strict';
import React from 'react';

import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import {
  ColorBackground, ColorBlack,
  ColorClear,
  ColorField,
  ColorFieldBgWhite,
  ColorFieldBorder,
  ColorFieldBorderHover,
  ColorFieldBorderSelected,
  ColorFieldHover, ColorMediaPickerBgCrop, ColorNegative, ColorSecondary, ColorStatusDialog,
  ColorTheme,
  ColorTitle,
  ColorWhite
} from "src/styles/Colors";
import {
  baseStyles,
  BORDER_RADIUS,
  changeNavColor,
  IMAGE_BORDER_RADIUS,
  withMediaQueries,
  withMultipleStyles
} from "src/styles/Styles";
import {Button, Card, IconButton, MenuItem, Typography} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Cropper from 'react-easy-crop';
import DialogTitle from "@material-ui/core/DialogTitle";
import {FormattedMessage} from "react-intl";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {getCroppedImg} from "../../utils/canvasUtils";
import imageCompression from 'browser-image-compression';
import Space from "./Space";
import VideocamIcon from '@material-ui/icons/Videocam';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import DescriptionIcon from '@material-ui/icons/Description';
import Utils from "../../utils/utils";
import TextView from "./TextView";
import Menu from "@material-ui/core/Menu";
import Dropzone from "react-dropzone";
import {ImageIcon, locString, SNACKBAR_AUTO_HIDE_DURATION} from "src/js/components/commons/ViewHelper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MediaRecorderView from "src/js/components/commons/MediaRecorderView";
import DialogView from "src/js/components/commons/DialogView";
import {logDebug} from "src/js/utils/AppLog";
import View from "src/js/components/commons/View";
import AppText from "src/js/components/commons/AppText";
import {FontWeightMedium} from "src/res/FontWeight";
import DateTimeHelper from "src/js/utils/DateTimeHelper";
import {history} from "src/js/store/configureStore";
import {getNavLocationFromUrl} from "src/js/utils/AppUtils";
import AppIcon, {IconFilter} from "src/js/components/commons/AppIcon";

const useStyles = (theme) => ({
  menuItemBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 28
  },
  dropzoneBoxView: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorField,
    borderRadius: BORDER_RADIUS,
    position: 'relative',
    border: '1px dashed '+ ColorFieldBorder,
    '&:hover': {
      border: '1px solid '+ ColorFieldBorderHover,
      backgroundColor: ColorFieldHover,
    },
  },
  dropzoneBoxViewDrag: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    position: 'relative',
    backgroundColor: ColorFieldHover,
    border: '2px solid '+ ColorFieldBorderSelected,
    '&:hover': {
      border: '2px solid '+ ColorFieldBorderSelected,
      backgroundColor: ColorFieldHover,
    },
  },
  dropzoneBoxViewBgWhite: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    position: 'relative',
    border: '1px solid '+ ColorFieldBorder,
    backgroundColor: ColorFieldBgWhite,
    '&:hover': {
      border: '1px solid '+ ColorFieldBorderHover,
      backgroundColor: ColorFieldHover,
    },
  },
  dropzoneBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorClear,
    borderRadius: BORDER_RADIUS,
    position: 'relative',
    // overflow: 'hidden',
  },
  dropZoneContainer: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    borderRadius: BORDER_RADIUS,
    borderWidth: 0,
    padding: 0,
    position: 'relative',
  },
  dropZone: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    alignItems: 'center',
    // justifyContent: 'center',
    backgroundColor: ColorClear,
    padding: 8,
  },
  dropZoneText: {
    fontSize: 13,
    opacity: 0.6,
    marginTop: 0,
    marginBottom: -16
  },
  dropZoneTextDisabled: {
    fontSize: 13,
    opacity: 0,
    marginTop: 0,
    marginBottom: -24,
  },
  previewContainer: {
    container: true,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 1,
    marginLeft: 1,
    marginRight: 1,
    marginBottom: 1,
    zIndex: 0,
  },
  previewImgContainer: {
    padding: 0,
    height: 164,
    width: 164,
    maxWidth: 164,
    item: true,
    borderRadius: IMAGE_BORDER_RADIUS,
    marginBottom: 0,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewImg: {
    height: 164,
    width: 164,
    minWidth: 164,
    maxWidth: '100%',
    borderRadius: IMAGE_BORDER_RADIUS,
    opacity: 1,
  },
  previewImgContainerSmall: {
    margin: -5,
    marginTop: -6,
    height: 56,
    width: 56,
    item: true,
    borderRadius: IMAGE_BORDER_RADIUS,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewImgSmall: {
    height: 64,
    width: 64,
    minWidth: 64,
    minHeight: 64,
    maxWidth: '100%',
    borderRadius: IMAGE_BORDER_RADIUS,
    opacity: 1,
  },
  previewImgContainerXSmall: {
    margin: -16,
    height: 40,
    width: 40,
    item: true,
    borderRadius: IMAGE_BORDER_RADIUS,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #FF0000'
  },
  previewImgXSmall: {
    height: 44,
    width: 44,
    minWidth: 44,
    minHeight: 44,
    maxWidth: '100%',
    borderRadius: IMAGE_BORDER_RADIUS,
    opacity: 1,
  },
  previewImgContainerSmall16x9: {
    margin: 8,
    marginTop: -10,
    width: 84,
    height: 48,
    item: true,
    borderRadius: IMAGE_BORDER_RADIUS,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewImgSmall16x9: {
    width: 84,
    minWidth: 84,
    height: 48,
    minHeight: 48,
    maxWidth: '100%',
    borderRadius: IMAGE_BORDER_RADIUS,
    opacity: 1,
  },
  previewImgContainerXSmall16x9: {
    margin: 8,
    marginTop: -10,
    width: 86,
    height: 48,
    item: true,
    borderRadius: IMAGE_BORDER_RADIUS,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewImgXSmall16x9: {
    width: 86,
    minWidth: 86,
    height: 48,
    minHeight: 48,
    maxWidth: '100%',
    borderRadius: IMAGE_BORDER_RADIUS,
    opacity: 1,
  },
  previewImgContainer16x9: {
    padding: 0,
    height: 138,
    maxHeight: 138,
    width: 244,
    maxWidth: 244,
    item: true,
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewImg16x9: {
    height: 138,
    width: 244,
    borderRadius: IMAGE_BORDER_RADIUS,
    opacity: 1,
  },
  previewImgContainerLarge: {
    padding: 0,
    height: 284,
    width: 284,
    maxWidth: 284,
    item: true,
    borderRadius: IMAGE_BORDER_RADIUS,
    marginBottom: 0,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewImgLarge: {
    height: 284,
    width: 284,
    minWidth: 284,
    maxWidth: '100%',
    borderRadius: IMAGE_BORDER_RADIUS,
    opacity: 1,
  },
  previewImgContainerLarge16x9: {
    padding: 0,
    height: 160,
    width: 284,
    maxWidth: 284,
    item: true,
    borderRadius: IMAGE_BORDER_RADIUS,
    marginBottom: 0,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewImgLarge16x9: {
    height: 160,
    width: 284,
    minWidth: 284,
    maxWidth: '100%',
    borderRadius: IMAGE_BORDER_RADIUS,
    opacity: 1,
  },
  previewVideoContainer: {
    margin: -5,
    marginTop: -6,
    height: 56,
    width: 56,
    item: true,
    borderRadius: IMAGE_BORDER_RADIUS,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewVideo: {
    height: 64,
    width: 64,
    minWidth: 64,
    minHeight: 64,
    maxWidth: '100%',
    borderRadius: IMAGE_BORDER_RADIUS,
    opacity: 1,
  },
  previewAudioContainer: {
    padding: 0,
    height: 44,
    minWidth: 368,
    maxWidth: 368,
    item: true,
    backgroundColor: '#FAFAFA',
    borderRadius: 4,
    borderWidth: 4,
    marginTop: 4,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 4,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    textAlign: 'center',
    '&:hover $previewAudio': {
      opacity: 0.3
    },
    '&:hover $removeButton': {
      opacity: 1
    }
  },
  previewAudio: {
    height: 40,
    width: 360,
    minWidth: 360,
    maxWidth: '100%',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    borderRadius: 4,
    zIndex: 5,
    opacity: 1
  },
  previewFile: {
    height: 80,
    width: 60,
    minWidth: 60,
    maxWidth: '100%',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    borderRadius: 4,
    zIndex: 5,
    opacity: 1
  },
  dropIcon: {
    opacity: 0.5,
    width: 40,
    height: 0,
  },
  progressContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none'
  },
  progressContainerCenter: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none'
  },
  dialog: {
    [theme.breakpoints.down('xs')]: {
      "& .MuiDialog-container .MuiDialog-paper": {
        margin: 0
      },
    }
  },
  dialogContent: {
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 16,
  },
  mainContainerWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: ColorMediaPickerBgCrop,
    borderRadius: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: Math.min(window.appWidth, 1200),
      height: Math.min(window.appHeight, 900),
      borderRadius: BORDER_RADIUS,
      overflow: 'hidden',
    }
  }
});

let counter = 0;

export const FILE_TYPE_IMAGE = 'image';
export const FILE_TYPE_AUDIO = 'audio';
export const FILE_TYPE_VIDEO = 'video';
export const IMAGE_MAX_SIZE = 20*1024*1024;
export const AUDIO_MAX_SIZE = 3*1024*1024;
export const SLIDE_IMAGE_MAX_WIDTH_HEIGHT = 1920;
export const REWARD_IMAGE_MAX_WIDTH_HEIGHT = 1600;
export const IMAGE_MAX_WIDTH_HEIGHT = 1200;
export const IMAGE_PROFILE_MAX_WIDTH_HEIGHT = 800;

export const DEFAULT_SPACING = 3;
export const SIZE_XSMALL_XS = 52;
export const SIZE_XSMALL = 60;
export const SIZE_SMALL_XS = 60;
export const SIZE_SMALL = 80;
export const SIZE_LARGE_XS = 120;
export const SIZE_LARGE = 160;

const objectUrls = new Set();

const addObjectUrl = (url) => {
  objectUrls.add(url);
};

const removeObjectUrl = (url) => {
  objectUrls.delete(url);
};

export const clearObjectUrls = () => {
  objectUrls.forEach((url) => {
    URL.revokeObjectURL(url);
  });
  objectUrls.clear();
};

class SingleFilePicker extends React.Component {

  constructor(props) {
    super(props);

    const initialFiles = this.processInitialFiles(this.props.initialFiles);
    this.hashPushed = [];
    this.state = {
      key: 0,
      newFile: null,
      newFileType: null,
      initialFiles: initialFiles,
      isLoading: initialFiles.length > 0,
      isImageProcessing: false,
      crop: {x: 0, y: 0},
      zoom: 1,
      showMenuElement: null
    }
  }

  componentDidMount() {
    this.processLoadingState();
  }

  componentWillUnmount() {
    this.removeAllListeners();
  }

  historyNavHandler = (event) => {
    const newLocation = getNavLocationFromUrl(event.newURL);
    logDebug('sfp-historyNavHandler', newLocation);
    if (this.hashPushed.length > 0 && (!newLocation.hash || !this.hashPushed.includes(newLocation.hash))) {
      const hashPopped = this.hashPushed.pop();
      if (hashPopped === 'mp') {
        this.hideDialog();
      } else if (hashPopped === 'mc') {
        this.hideCropper();
      }
    }
  }

  startNav(hash, replace) {
    if (replace) {
      history.replace({pathname: history.location.path, search: history.location.search, hash: '#'+ hash});
      this.hashPushed.pop();
      this.hashPushed.push(hash);
    } else {
      history.push({pathname: history.location.path, search: history.location.search, hash: '#'+ hash});
      this.hashPushed.push(hash);
      window.addEventListener('hashchange', this.historyNavHandler);
    }
    changeNavColor(ColorBlack);
  }

  stopNav() {
    window.removeEventListener('hashchange', this.historyNavHandler);
    this.hashPushed.pop();
    history.goBack();
    changeNavColor();
  }

  render() {
    const {classes, isXS, acceptedFiles, disabled, hideDropzoneText, filesLimit,
      maxDuration, maxDurationMimes, aspectRatio = 1, aspectRatioFree, size, error, errorText, showPreviews, showPreviewsInDropzone, helperText, isInBgWhite,} = this.props;
    let {width, height, borderRadius = BORDER_RADIUS} = this.props;
    const {newFile, newFileType} = this.state;

    let eAspectRatio = aspectRatio || 16/9;
    if (aspectRatioFree) {
      eAspectRatio = 1;
    }
    const eDisabled = disabled !== undefined ? disabled : false;
    let eHideDropzoneText = hideDropzoneText || eDisabled;

    let eWidth= eAspectRatio === 1 ? 180 : 260;
    let addImagePlaceholderSize = 80;
    if (size === 'large') {
      eWidth = eAspectRatio === 1 ? 300: 430;
      addImagePlaceholderSize = 120;
    } else if (size === 'small') {
      eWidth = eAspectRatio === 1 ? 70 : 100;
      addImagePlaceholderSize = 40;
    } else if (size === 'xsmall') {
      eWidth = eAspectRatio === 1 ? 52 : 100;
      addImagePlaceholderSize = 26;
    }
    let eHeight = eWidth/eAspectRatio;
    if (eAspectRatio !== 1) {
      eHeight += 4;
    }

    const dzClasses = {root: classes.dropZone, icon: classes.dropIcon};
    let dropzoneBoxViewExtraStyle = null;
    let dropzoneBoxExtraStyle = null;
    if (!eDisabled) {
      if (!eHideDropzoneText) {
        if (size === 'large') {
          eHeight += 20;
        } else {
          eHeight += 24;
        }
        dzClasses.text = classes.dropZoneText;
      } else {
        dzClasses.text = classes.dropZoneTextDisabled;
      }
      dropzoneBoxExtraStyle = {width: eWidth, height: eHeight};
      dropzoneBoxViewExtraStyle = {width: eWidth+4};
    } else {
      dzClasses.text = classes.dropZoneTextDisabled;
      dropzoneBoxExtraStyle = {width: eWidth, height: eHeight, backgroundColor: ColorClear};
      dropzoneBoxViewExtraStyle = {width: eWidth+4, backgroundColor: ColorField};
    }

    let extraStyle = null;
    if (error) {
      extraStyle = {border: '1px solid '+ ColorNegative};
    }

    if (!width || !height) {
      if (size === 'large') {
        height = isXS ? SIZE_LARGE_XS : SIZE_LARGE;
      } else if (size === 'xsmall') {
        height = isXS ? SIZE_XSMALL_XS : SIZE_XSMALL;
      } else {
        height = isXS ? SIZE_SMALL_XS : SIZE_SMALL;
      }
      width = height * aspectRatio;
    }

    return (
      <div>
        <div className={classes.viewColumn}>
        <div className={isInBgWhite ? classes.dropzoneBoxViewBgWhite : this.state.drag ? classes.dropzoneBoxViewDrag : classes.dropzoneBoxView} style={{borderRadius: borderRadius, width: width, height: height, padding: 0, alignItems: 'center', justifyContent: 'center', ...extraStyle}}>
          <Dropzone
            ref={(ref) => this.refDropzone = ref}
            accept={acceptedFiles}
            maxFiles={1}
            multiple={false}
            noClick={true}
            noKeyboard={true}
            onDragEnter={() => {
              this.setState({drag: true});
            }}
            onDragLeave={() => {
              this.setState({drag: false});
            }}
            onDropRejected={(error) => {
              let errorMessage = null;
              if (error && error.length > 0 && error[0] && error[0].errors && error[0].errors.length > 0) {
                errorMessage = error[0].errors[0].message;
              }
              if (!errorMessage) {
                errorMessage = this.context.intl.formatMessage({id: 'somethingWentWrong'});
              }
              this.setState({drag: false, snackbarInfo: {message: errorMessage, type: 'error'}});
            }}
            onError={(error) => {
              let errorMessage = null;
              if (error) {
                errorMessage = error.message;
              }
              if (!errorMessage) {
                errorMessage = this.context.intl.formatMessage({id: 'somethingWentWrong'});
              }
              this.setState({snackbarInfo: {message: errorMessage, type: 'error'}});
            }}
            onDrop={this.onDrop}>
            {(dropConfigs) => (
              this.getDropzoneView(dropConfigs, width, height)
            )}
          </Dropzone>

          {this.renderPickedImageView()}
          {newFile && (newFileType === 'video' || newFileType === 'audio') ?
            <video preload={'metadata'} muted={true} height={1} role="presentation" src={this.state.newFile} onLoadedMetadata={this.validateAudioVideoFile} onError={this.onPreviewError} />
            : null
          }
        </div>
        <div className={classes.viewColumn} style={{paddingLeft: 12, paddingRight: 12}}>
          {helperText && <FormHelperText className={classes.helper}>{helperText}</FormHelperText>}
          {errorText && <FormHelperText className={classes.helperError}>{errorText}</FormHelperText>}
        </div>
        {this.renderMenu()}
        {this.renderDialog()}
        {this.renderSnackBar()}
      </div>
      </div>
    );
  }

  onDrop = (files) => {
    const {maxFileSize, maxDurationMimes, maxDuration} = this.props;
    const maxDurationVideo = maxDurationMimes ? maxDurationMimes.video || maxDuration : maxDuration;
    const maxDurationAudio = maxDurationMimes ? maxDurationMimes.audio || maxDuration : maxDuration;
    let isInProcessing = false;
    if (files && files.length > 0) {
      const file = files[files.length - 1];
      const fileType = file.type.split('/')[0];
      const acceptedFilesInfo = this.getAcceptedFilesInfo();
      if (acceptedFilesInfo.types && !acceptedFilesInfo.types.includes(fileType)) {
        this.setState({snackbarInfo: {message: this.context.intl.formatMessage({id: 'picker.fileTypeNotSupported'}, {value: acceptedFilesInfo.typesText}), type: 'error'}});
        return;
      }

      let maxFileSizeL = maxFileSize;
      if (this.props.maxSizeMimes) {
        if (fileType === 'image') {
          maxFileSizeL = this.props.maxSizeMimes.image;
        } else if (fileType === 'video') {
          maxFileSizeL = this.props.maxSizeMimes.video;
        } else if (fileType === 'audio') {
          maxFileSizeL = this.props.maxSizeMimes.audio;
        }
      }
      // if (fileType === 'image' || fileType === 'file' || (fileType === 'video' && maxDurationVideo > 0) || (fileType === 'audio' && maxDurationAudio > 0)) {
        if (file.size <= maxFileSizeL) {
          this.onFilePicked(file, fileType);
        } else {
          const message = this.context.intl.formatMessage({id: 'filePicker.fileSizeLong'}, {value: fileType, size: maxFileSizeL/1024/1024});
          this.setState({snackbarInfo: {message, type: 'error'}});
        }
      // }
    }
    if (!isInProcessing) {
      this.setState({drag: false, newFile: null, newFileType: null, key: this.state.key + 1});
    }
  }

  getDropzoneView = (dropConfigs, width, height) => {
    const {getRootProps, getInputProps} = dropConfigs;
    const {classes, spacing = DEFAULT_SPACING, borderRadius = BORDER_RADIUS, acceptedFiles, size, pickTitle, required, error} = this.props;
    const {initialFiles, isLoading} = this.state;

    const iconSize = Math.min(38, height * 0.5);

    let hasAudio = false;
    let hasImage = false;
    let hasVideo = false;
    let counter = 0;
    if (acceptedFiles) {
      Object.keys(acceptedFiles).forEach((mime) => {
        if (mime.startsWith('audio')) {
          hasAudio = true;
          counter++;
        }
        if (mime.startsWith('image')) {
          hasImage = true;
          counter++;
        }
        if (mime.startsWith('video')) {
          hasVideo = true;
          counter++;
        }
      });
    }
    let pickIcon = null;
    if (counter > 1) {
      pickIcon = require('src/images/ic_add_media_plus.png');
    } else if (hasAudio) {
      pickIcon = require('src/images/ic_add_audio.png');
    } else if (hasImage) {
      pickIcon = require('src/images/ic_add_image.png');
    } else if (hasVideo) {
      pickIcon = require('src/images/ic_add_video.png');
    }
    if (!pickIcon) {
      pickIcon = require('src/images/ic_add.png');
    }
    let pickIconSize = 30;
    if (size === 'xsmall') {
      pickIconSize = 24;
    }
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <div style={{width: width, height: height, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {/*<div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>*/}
          {/*  <Skeleton variant={'rect'} style={{width: '100%', height: '100%', borderRadius: borderRadius, backgroundColor: AppUtils.colorWithAlpha(ColorTheme, 0.3)}}/>*/}
          {/*</div>*/}

          {initialFiles && initialFiles.length > 0 && initialFiles.map((file) => {
            let view = null;
            if (file.type === 'image') {
              view = (
                 <img ref={(ref) => this.refImage = ref} role="presentation" src={file.url} style={{width: '100%', height: '100%', borderRadius: borderRadius-2, objectFit: 'contain'}} />
              );
            } else if (file.type === 'video') {
              view = (
                <React.Fragment>
                  {file.thumbnail ?
                    <img ref={(ref) => this.refImage = ref} role="presentation" src={file.thumbnail} style={{width: '100%', height: '100%', borderRadius: borderRadius-2, objectFit: 'contain'}} />
                    : <video ref={(ref) => this.refVideo = ref} role="presentation" src={file.url} preload={'auto'} muted={true} controls={false} style={{width: '100%', height: '100%', borderRadius: borderRadius-2, objectFit: 'contain'}} />
                  }
                  <div style={{position: 'absolute', bottom: 0, right: 0}}>
                    <VideocamIcon fontSize={'small'} style={{color: Utils.colorWithAlpha(ColorWhite, 0.6)}} />
                  </div>
                </React.Fragment>
              );
            } else if (file.type === 'audio') {
              view = (
                <div style={{width: '100%', height: '100%', borderRadius: borderRadius-2, backgroundColor: ColorBackground, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <AudiotrackIcon fontSize={'large'} style={{color: Utils.colorWithAlpha(ColorTheme, 0.8)}} />
                </div>
              );
            } else {
              view = (
                <div style={{width: '100%', height: '100%', borderRadius: borderRadius-2, backgroundColor: ColorBackground, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <DescriptionIcon fontSize={'large'} style={{color: Utils.colorWithAlpha(ColorTheme, 0.8)}} />
                </div>
              );
            }
            return (
              <div style={{width: width, height: height, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: spacing, overflow: 'hidden'}}>
                {view}
              </div>
            );
          })}

          <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
            <div className={classes.viewRow}>
              <IconButton
                onClick={this.onAddNewClicked}
                style={{width: width, height: height, borderRadius: borderRadius}}>
                {initialFiles && initialFiles.length > 0 ? null : <ImageIcon src={pickIcon} width={pickIconSize} height={pickIconSize}/> }
                {(!initialFiles || initialFiles.length === 0) && pickTitle ?
                  <React.Fragment>
                    <Space verticalSpace={1}/>
                    <TextView>{pickTitle}</TextView>
                  </React.Fragment>
                  : null
                }
              </IconButton>
            </div>
            {required && (!initialFiles || initialFiles.length === 0) ?
              <TextView color={error ? ColorNegative : ColorSecondary} variant={'body2'} style={{position: 'absolute', top: 2, right: 4}}>{'*'}</TextView>
              : null
            }
          </div>
          {/*{isLoading ?*/}
          {/*  <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', pointerEvents: 'none'}}>*/}
          {/*    <CircularProgress size={20} />*/}
          {/*  </div>*/}
          {/*  : null*/}
          {/*}*/}
          {initialFiles && initialFiles.length > 0 ?
            <div style={{position: 'absolute', top: -10, right: -10}}>
              <IconButton
                size={'small'}
                onClick={this.onDelete}
                style={{width: iconSize+4, height: iconSize+4}}
                className={classes.imageIconButton}>
                {<ImageIcon src={require('src/images/ic_delete_bg.png')} width={iconSize} height={iconSize} />}
              </IconButton>
            </div>
            : null
          }
        </div>
      </div>
    )
  };

  onPreviewError = (error) => {
    this.setState({snackbarInfo: {message: this.context.intl.formatMessage({id: 'picker.fileNotWorking'}, {value: this.getAcceptedFilesInfo().typesText}), type: 'error'}});
  };

  getAcceptedFilesInfo = () => {
    const {acceptedFiles} = this.props;
    let types = null;
    let typesText = null;
    if (acceptedFiles) {
      types = [];
      let index = 0;
      Object.keys(acceptedFiles).forEach((type) => {
        const splits = type.split('/');
        // if (splits.length > 1 && splits.length[1] !== '*') {
        //   types.push(type.split('/')[1]);
        // } else {
          types.push(type.split('/')[0]);
        // }
        if (index === 0) {
          typesText = type;
        }
        acceptedFiles[type].forEach((subType) => {
          typesText += ', '+ subType;
        });
        index++;
      });
    }
    return {
      types: types,
      typesText: typesText,
    };
  }

  onDelete = (event) => {
    event.stopPropagation();
    const file = this.state.initialFiles[0];
    if (file.url && file.url.startsWith('blob:')) {
      URL.revokeObjectURL(file.url);
      removeObjectUrl(file.url);
    }
    this.props.onDelete(file);
    if (this.state.initialFiles && this.state.initialFiles.length === 1) {
      this.state.initialFiles.splice(0, 1);
      this.setState({initialFiles: this.state.initialFiles});
    }
  }

  renderPickedImageView() {
    const {newFile, newFileType} = this.state;
    if (newFile && newFileType === 'image') {
      const translucentColor = 'rgba(0,0,0,0.1)';
      const dialogInfo = {
        renderContent: () => {
          const {aspectRatio, aspectRatioFree, classes, isXS} = this.props;
          const {crop, zoom, isImageProcessing, imageAspectRatio} = this.state;
          let eAspectRatio = aspectRatio || 1;
          if (aspectRatioFree && imageAspectRatio) {
            eAspectRatio = imageAspectRatio;
          }
          return (
            <div className={classes.mainContainerWrapper}>
              <div className={classes.mainContainer}>
                <div style={{position: 'relative', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: translucentColor, padding: 12}}>
                  <View style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center'}}>
                    <AppText
                      variant={'h6'}
                      style={{
                        color: ColorWhite,
                        fontWeight: FontWeightMedium,
                        textAlign: 'center'}}>
                      <FormattedMessage id={'filePicker.resizeImage'}/>
                    </AppText>
                  </View>
                  <Button disableElevation size={isXS ? 'medium' : 'large'} variant={'contained'} color={ColorBlack} style={{backgroundColor: ColorWhite}} onClick={() => {
                    this.stopNav();
                    this.hideCropper();
                  }}>{locString('generic.cancel')}</Button>
                  <div style={{flex: 1}}/>
                  <Button
                    disableElevation size={isXS ? 'medium' : 'large'}
                    variant={'contained'}
                    color={ColorBlack}
                    style={{backgroundColor: ColorWhite}}
                    onClick={() => {
                      this.stopNav();
                      this.setState({isImageProcessing: true}, () => {
                        this.processImageFile();
                      });
                    }}>{locString('generic.done')}</Button>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', flex: 1, width: '100%', overflow: 'hidden', position: 'relative'}}>
                  <Cropper
                    style={{
                      containerStyle: {display: 'flex', flexDirection: 'column', flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center', paddingTop: 66, backgroundColor: ColorMediaPickerBgCrop},
                    }}
                    image={newFile}
                    crop={crop}
                    zoom={zoom}
                    aspect={eAspectRatio}
                    onMediaLoaded={(mediaSize) => {
                      this.setState({imageAspectRatio: mediaSize.naturalWidth/mediaSize.naturalHeight})
                    }}
                    onCropChange={(crop) => {
                      this.setState({crop});
                    }}
                    onCropComplete={(croppedArea, croppedAreaPixels) => {
                      // console.log(croppedAreaPixels);
                      this.setState({croppedAreaPixels: croppedAreaPixels});
                    }}
                    onZoomChange={(zoom) => {
                      this.setState({zoom})
                    }}
                  />
                </div>
                {isImageProcessing &&
                  <div style={{
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Card style={{
                      width: 80,
                      height: 80,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      borderRadius: 8,
                      opacity: 0.95
                    }}>
                      <CircularProgress/>
                    </Card>
                  </div>
                }

              </div>
            </div>
          );
        },
        fullScreen: true,
        transparent: true,
        noSpace: true,
        show: true,
      }

      return <DialogView {...dialogInfo} />
      // (
      //   <Dialog disableBackdropClick onClose={this.onDialogClose} aria-labelledby="customized-dialog-title"
      //           open={open}>
      //     <DialogTitle>
      //       <Typography variant="h6" align="center" style={{color: ColorTitle}}>
      //         <FormattedMessage id={'filePicker.resizeImage'}/>
      //       </Typography>
      //     </DialogTitle>
      //     <DialogContent>
      //       <div style={{width: Math.min(window.appWidth, 800), height: Math.min(window.appHeight, 600)}}>
      //         <Cropper
      //           style={{
      //             containerStyle: {position: 'absolute', top: 66, bottom: 60, left: 2, right: 2, borderRadius: 4},
      //           }}
      //           image={newFile}
      //           crop={crop}
      //           zoom={zoom}
      //           aspect={eAspectRatio}
      //           onCropChange={(crop) => {
      //             this.setState({crop});
      //           }}
      //           onCropComplete={(croppedArea, croppedAreaPixels) => {
      //             // console.log(croppedAreaPixels);
      //             this.setState({croppedAreaPixels: croppedAreaPixels});
      //           }}
      //           onZoomChange={(zoom) => {
      //             this.setState({zoom})
      //           }}
      //         />
      //         {isImageProcessing &&
      //           <div style={{
      //             display: 'flex',
      //             position: 'absolute',
      //             top: 0,
      //             bottom: 0,
      //             left: 0,
      //             right: 0,
      //             alignItems: 'center',
      //             justifyContent: 'center'
      //           }}>
      //             <Card style={{
      //               width: 80,
      //               height: 80,
      //               alignItems: 'center',
      //               justifyContent: 'center',
      //               display: 'flex',
      //               borderRadius: 8,
      //               opacity: 0.95
      //             }}>
      //               <CircularProgress/>
      //             </Card>
      //           </div>
      //         }
      //       </div>
      //     </DialogContent>
      //     <DialogActions>
      //       <div className={classes.dialogActionsViewOutline}>
      //         <Button
      //           color="secondary"
      //           variant="outlined"
      //           onClick={() => {
      //             this.setState({newFile: null, newFileType: null, key: this.state.key + 1});
      //           }}>
      //           <FormattedMessage id={'generic.no'}/>
      //         </Button>
      //         <Space verticalSpace={2}/>
      //         <Button
      //           disableElevation
      //           color="primary"
      //           variant="contained"
      //           onClick={() => {
      //             this.setState({isImageProcessing: true}, () => {
      //               this.processImageFile();
      //             });
      //           }}>
      //           <FormattedMessage id={'generic.yes'}/>
      //         </Button>
      //       </div>
      //     </DialogActions>
      //   </Dialog>
      // );
    }
  }

  renderMenu() {
    const {showMenuElement} = this.state;
    if (showMenuElement) {
      const {classes} = this.props;
      const acceptedFilesInfo = this.getAcceptedFilesInfo();
      let needImage = false;
      let needVideo = false;
      let needAudio = false;
      acceptedFilesInfo.types.forEach((type) => {
        if (type === 'image') {
          needImage = true;
        } else if (type === 'video') {
          needVideo = true;
        } else if (type === 'audio') {
          needAudio = true;
        }
      });
      let recordCameraTextId = null;
      if (needImage && needVideo) {
        recordCameraTextId = this.context.intl.formatMessage({id: 'picker.recordImageVideo'});
      } else if (needImage) {
        recordCameraTextId = this.context.intl.formatMessage({id: 'picker.recordImage'});
      } else if (needVideo) {
        recordCameraTextId = this.context.intl.formatMessage({id: 'picker.recordVideo'});
      } else if (needAudio) {
        recordCameraTextId = this.context.intl.formatMessage({id: 'picker.recordAudio'});
      }
      if ((needImage || needVideo) && needAudio) {
        recordCameraTextId += this.context.intl.formatMessage({id: 'picker.orRecordAudio'});
      }

      return (
        <Menu
          id="menu"
          anchorEl={showMenuElement}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          open={showMenuElement}
          onClose={this.closeMenu}>
          {recordCameraTextId ?
            <MenuItem dense onClick={this.openRecorder}>
              <div className={classes.menuItemBox}>
                <ImageIcon src={require('src/images/ic_camera.png')} />
                <Space verticalSpace={1.2} />
                <TextView color={ColorTitle} variant={'body2'}>
                  {recordCameraTextId}
                </TextView>
              </div>
            </MenuItem>
            : null
          }
          <MenuItem dense onClick={this.openPicker}>
            <div className={classes.menuItemBox}>
              <ImageIcon src={require('src/images/ic_folder.png')} />
              <Space verticalSpace={1.2} />
              <TextView color={ColorTitle} variant={'body2'}>
                <FormattedMessage id='picker.openFile' />
              </TextView>
            </div>
          </MenuItem>
        </Menu>
      );
    }
  }

  renderSnackBar() {
    const {snackbarInfo} = this.state;
    if (snackbarInfo) {
      const {messageId, message, type} = snackbarInfo;
      const onClose = () => {
        this.setState({snackbarInfo: null});
      };
      let snackbarMessage = message;
      if (!snackbarMessage && messageId) {
        snackbarMessage = <FormattedMessage id={messageId}/>;
      }
      return (
        <Snackbar open={message != null} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} onClose={onClose}>
          <MuiAlert severity={type || "success"} onClose={onClose} elevation={6} variant="filled">
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      );
    }
  }

  renderDialog() {
    const {showDialogInfo} = this.state;
    if (!showDialogInfo) {
      return null;
    }

    return <DialogView show={true} {...showDialogInfo}/>
  }

  showDialog(dialogInfo) {
    if (dialogInfo) {
      this.setState({showDialogInfo: dialogInfo});
    }
  }

  hideDialog = () => {
    changeNavColor();
    this.setState({showDialogInfo: null});
  };

  showCropper = () => {
    this.setState({newFile: null, newFileType: null, key: this.state.key + 1});
  };

  hideCropper = () => {
    changeNavColor();
    this.setState({newFile: null, newFileType: null, key: this.state.key + 1});
  };

  onAddNewClicked = (event) => {
    const acceptedFilesInfo = this.getAcceptedFilesInfo();
    let needImage = false;
    let needVideo = false;
    let needAudio = false;
    acceptedFilesInfo.types.forEach((type) => {
      if (type === 'image') {
        needImage = true;
      } else if (type === 'video') {
        needVideo = true;
      } else if (type === 'audio') {
        needAudio = true;
      }
    });
    if (needImage || needVideo || needAudio) {
      this.setState({showMenuElement: event.target});
    } else {
      this.openPicker();
    }
  };

  closeMenu = () => {
    this.setState({showMenuElement: null});
  };

  openPicker = () => {
    this.closeMenu();

    if (this.refDropzone) {
      this.refDropzone.open();
    }
  };

  openRecorder = () => {
    this.closeMenu();
    this.startNav('mp');

    const {isXS, maxDuration, maxDurationMimes, aspectRatio = 1} = this.props;
    const maxDurationVideo = maxDurationMimes ? maxDurationMimes.video || maxDuration : maxDuration;
    const acceptedFilesInfo = this.getAcceptedFilesInfo();
    this.showDialog({
      renderContent: () => {
        return (
          <MediaRecorderView
            recordTypes={acceptedFilesInfo.types}
            aspectRatioMimes={this.props.aspectRatioMimes}
            duration={maxDurationVideo}
            isXS={isXS}
            onCancel={this.onMediaRecorderClose}
            onDone={(file) => {
              this.onMediaRecorderClose(file.type === 'image');

              addObjectUrl(file.url);
              if (file.type === 'image') {
                // move to cropper
                this.startNav('mc', true);
                this.setState({drag: false, isLoading: false, newFile: file.blob, newFileOrg: file.file, newFileObj: file.url, newFileType: 'image', crop: {x: 0, y: 0}, zoom: 1});
              } else {
                this.processNewPickedFile(file.file, file.url, file.type);
              }
            }}
          />
        );
      },
      fullScreen: true,
      transparent: true,
      noSpace: true,
    });
  };

  onMediaRecorderClose = (noStopNav = false) => {
    if (!noStopNav) {
      this.stopNav();
    }
    this.hideDialog();
  }

  showImageCrop() {
    let eAspectRatio = this.props.aspectRatio || 16/9;
    if (this.props.aspectRatioFree) {
      eAspectRatio = 1;
    }
    const contentView = (
      <div style={{width: Math.min(window.appWidth, 800), height: Math.min(window.appHeight, 600)}}>
        <Cropper
          style={{
            containerStyle: {position: 'absolute', top: 66, bottom: 68, left: 8, right: 8, borderRadius: 4},
          }}
          image={this.state.newFile}
          crop={this.state.crop}
          zoom={this.state.zoom}
          aspect={eAspectRatio}
          objectFit={'contain'}
          onCropChange={(crop) => {
            this.setState({crop});
          }}
          onCropComplete={(croppedArea, croppedAreaPixels) => {
            // console.log(croppedAreaPixels);
            this.setState({croppedAreaPixels: croppedAreaPixels});
          }}
          onZoomChange={(zoom) => {
            this.setState({zoom})
          }}
        />
        {/*{this.state.isImageProcessing &&*/}
        {/*  <div style={{display: 'flex', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, alignItems: 'center', justifyContent: 'center'}}>*/}
        {/*    <Card style={{width: 80, height: 80, alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: 8, opacity: 0.95}}>*/}
        {/*      <CircularProgress />*/}
        {/*    </Card>*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
    );
    this.showDialog({
      title: <FormattedMessage id={'filePicker.resizeImage'} />,
      content: contentView,
      positiveText: <FormattedMessage id={'generic.yes'} />,
      onPositiveClicked: () => {
        this.setState({showDialogInfo: null, isLoading: true}, () => {
          this.processImageFile();
        });
      },
      neutralText: <FormattedMessage id={'generic.no'} />,
      onNeutralClicked: () => {
        this.setState({showDialogInfo: null, newFile: null, newFileType: null, key: this.state.key + 1});
      },
    });
  }

  processInitialFiles(files) {
    const initialFiles = [];
    if (files && files.length > 0) {
      files.forEach((file) => {
        // eslint-disable-next-line no-prototype-builtins
        // if (file instanceof File && file.hasOwnProperty('tempData') && file.tempData) {
        //   initialFiles.push(file.tempData);
        // } else {
          initialFiles.push(file);
        // }
      });
    }
    return initialFiles;
  }

  processLoadingState() {
    // this.removeAllListeners();
    if (this.refImage) {
      this.refImage.addEventListener('loaded', this.onLoaded);
    } else if (this.refVideo) {
      this.refVideo.addEventListener('loaded', this.onLoaded);
    }
  }

  removeAllListeners() {
    if (this.refImage) {
      this.refImage.removeEventListener('loaded', this.onLoaded);
    }
    if (this.refVideo) {
      this.refVideo.addEventListener('loaded', this.onLoaded);
    }
  }

  onLoaded = () => {
    this.setState({isLoaded: false});
  };

  async onFilePicked(file, fileType) {
    const newFile = await this.readFile(file);
    let objectUrl = null;
    if (fileType === 'image') {
      this.startNav('mc');
    } else {
      objectUrl = URL.createObjectURL(file);
      addObjectUrl(objectUrl);
    }

    this.setState({drag: false, isLoading: false, newFile: newFile, newFileOrg: file, newFileObj: objectUrl, newFileType: fileType, crop: {x: 0, y: 0}, zoom: 1}, () => {
      if (fileType !== 'image' && fileType !== 'video' && fileType !== 'audio') {
        this.processNewPickedFile(this.state.newFileOrg, this.state.newFileObj, this.state.newFileType);
      }
    });
  }

  readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    });
  }

  async processImageFile() {
    const {maxWidthOrHeight} = this.props;
    try {
      const croppedImage = await getCroppedImg(this.state.newFile, this.state.croppedAreaPixels);

      const compressedImage = await imageCompression(croppedImage, {
        maxSizeMB: 2,
        maxWidthOrHeight: maxWidthOrHeight || IMAGE_MAX_WIDTH_HEIGHT,
        initialQuality: 0.8,
      });
      const imageBitmap = await createImageBitmap(compressedImage);
      const canvas = document.createElement("canvas");
      canvas.width = imageBitmap.width;
      canvas.height = imageBitmap.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(imageBitmap, 0, 0);
      await canvas.toBlob((webpBlob) => {
        const finalImage = new File([webpBlob], 'TempRab7_'+ (++counter) +'.webp');
        const objectUrl = URL.createObjectURL(webpBlob);
        addObjectUrl(objectUrl);
        this.processNewPickedFile(finalImage, objectUrl, 'image');
      }, 'image/webp');

      // const maxSize = maxWidthOrHeight || IMAGE_MAX_WIDTH_HEIGHT;
      // const img = new Image();
      // img.src = URL.createObjectURL(croppedImage);
      // img.onload = () => {
      //   // Calculate new dimensions while maintaining aspect ratio
      //   let { width, height } = img;
      //   if (height > maxSize) {
      //     const ratio = maxSize / height;
      //     width = Math.round(width * ratio);
      //     height = maxSize;
      //   }
      //   // Ensure width doesn't exceed maxWidth
      //   if (width > maxSize) {
      //     const ratio = maxSize / width;
      //     width = maxSize;
      //     height = Math.round(height * ratio);
      //   }
      //
      //   // Create a canvas and draw the resized image
      //   const canvas = document.createElement("canvas");
      //   canvas.width = width;
      //   canvas.height = height;
      //   const ctx = canvas.getContext("2d");
      //   ctx.drawImage(img, 0, 0, width, height);
      //
      //   canvas.toBlob((webpBlob) => {
      //     const finalImage = new File([webpBlob], 'TempRab7_'+ (++counter) +'.webp');
      //
      //     const objectUrl = URL.createObjectURL(webpBlob);
      //     addObjectUrl(objectUrl);
      //     this.processNewPickedFile(finalImage, objectUrl, 'image');
      //   }, 'image/webp', 0.8);
      // };
    } catch (e) {
      // console.error(e);
      this.setState({isImageProcessing: false, newFile: null, newFileType: null, key: this.state.key + 1});
    }
    changeNavColor();
  }

  validateAudioVideoFile = (element) => {
    if (element.currentTarget) {
      let maxDuration = this.props.maxDuration;
      if (this.props.maxDurationMimes) {
        if (this.state.newFileType === 'audio' && this.props.maxDurationMimes.audio) {
          maxDuration = this.props.maxDurationMimes.audio;
        } else if (this.state.newFileType === 'video' && this.props.maxDurationMimes.video) {
          maxDuration = this.props.maxDurationMimes.video;
        }
      }

      if (element.currentTarget.duration <= maxDuration) {
        this.processNewPickedFile(this.state.newFileOrg, this.state.newFileObj, this.state.newFileType);
      } else {
        const message = this.context.intl.formatMessage({id: 'filePicker.fileDurationLong'}, {value: this.state.newFileType, duration: maxDuration});
        this.setState({newFile: null, newFileType: null, key: this.state.key + 1, snackbarInfo: {message, type: 'error'}});
      }
    }
  }

  processNewPickedFile(file, url, type) {
    const {initialFiles, filesLimit, onChange} = this.props;
    const {newFileType} = this.state;
    const limit = filesLimit || 1;
    let changedFiles = null;
    if (limit > 1) {
      changedFiles = initialFiles;
      if (changedFiles) {
        changedFiles.push({file: file, url: url, type: type});
      } else {
        changedFiles = [{file: file, url: url, type: type}];
      }
    } else {
      changedFiles = [{file: file, url: url, type: type}];
    }

    const newInitialFiles = this.processInitialFiles(changedFiles);
    this.setState({isImageProcessing: false, newFile: null, newFileType: null, initialFiles: newInitialFiles, initialFileType: newFileType, key: this.state.key + 1, isLoading: true}, () => {
      onChange(changedFiles);
      this.processLoadingState();
    });
  }

  convertBytesToMbsOrKbs(filesize) {
    let size = '';

    if (filesize >= 1048576) {
      size = filesize / 1048576 + ' MB';
    } else if (filesize >= 1024) {
      size = filesize / 1024 + ' KB';
    } else {
      size = filesize + ' B';
    }

    return size;
  }
}

SingleFilePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  highlighted: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hideDropzoneText: PropTypes.bool,
  maxFileSize: PropTypes.number,
  maxDuration: PropTypes.number,
  maxWidthOrHeight: PropTypes.number,
  maxDurationMimes: PropTypes.object,
  maxSizeMimes: PropTypes.object,
  acceptedFiles: PropTypes.object,
  filesLimit: PropTypes.number,
  aspectRatio: PropTypes.number,
  aspectRatioFree: PropTypes.bool,
  size: PropTypes.string,
  showPreviews: PropTypes.bool,
  showPreviewsInDropzone: PropTypes.bool,
  initialFiles: PropTypes.array,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onError: PropTypes.func,
}

SingleFilePicker.contextTypes = {
  intl:PropTypes.object.isRequired
}

export default withMultipleStyles(baseStyles, useStyles)(withMediaQueries([
    ['isXS', theme => theme.breakpoints.down('xs'), {defaultMatches: true}],
    ['isSM', theme => theme.breakpoints.down('sm'), {defaultMatches: true}],
  ])(SingleFilePicker)
);

// export const ACCEPTED_AUDIO_MIMES = ["audio/mp3", "audio/m4a", "audio/mpeg"];
export const ACCEPTED_AUDIO_MIMES = {"audio/*": [".mp3", ".mpeg", ".mp4", ".m4a"]};
// export const ACCEPTED_IMAGE_MIMES = ["image/jpeg", "image/jpg", "image/png", "image/webp", "image/gif"];
export const ACCEPTED_IMAGE_MIMES = {"image/*": [".jpeg", ".jpg", ".png", ".webp", ".gif"]};
export const ACCEPTED_VIDEO_MIMES = {"video/*": [".mp4", ".mov"]};
export const ACCEPTED_ZIP_MIMES = {"application/zip": [".zip"]};
