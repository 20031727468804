import PropTypes from 'prop-types';
import React, {forwardRef, useImperativeHandle, useLayoutEffect, useRef} from 'react';
import Phaser from "phaser";
import {GPRBoot} from "src/gamePacManRush/scenes/GPRBoot.js";
import {GPRPreloader} from "src/gamePacManRush/scenes/GPRPreloader.js";
import {GPRMainMenu} from "src/gamePacManRush/scenes/GPRMainMenu.js";
import {GPRGame} from "src/gamePacManRush/scenes/GPRGame.js";
import {logDebug} from "src/js/utils/AppLog";
import {isWebGLAvailable} from "src/game/GameHelper";

const config = {
  type: Phaser.AUTO,
  transparent: true,
  width: 675,
  height: 1080,
  parent: 'game-container',
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.NO_CENTER
  },
  physics: {
    default: 'arcade',
    arcade: {
      // debug: true
    }
  },
  scene: [
    GPRBoot,
    GPRPreloader,
    GPRMainMenu,
    GPRGame
  ],
};

export const gamePacManRushInfo = () => {
  return {
    'name': [{'en': 'Tap Tap Shots'}, {'ar': 'Tap Tap Shots'}],
    'imageUrl': 'media/gamePacManRush/ball.png',
    'guide': [{'en': 'Tap Tap Shots'}, {'ar': 'Tap Tap Shots'}],
    'themeColor': '#E0863B',
    'resultType': 2,
    'scoreMid': 10,
    'configs': {
      'themeBg': 1,
      'images': [
        {'number': 0, 'label': 'Striker', 'url': 'media/gamePacManRush/striker.png'},
        {'number': 1, 'label': 'Ball', 'url': 'media/gamePacManRush/ball.png'},
        {'number': 2, 'label': 'Sword', 'url': 'media/gamePacManRush/saw.png'},
        {'number': 3, 'label': 'Ad Banner', 'url': 'media/gamePacManRush/adImage.png'}],
      'audios': [{'label': 'Background', 'url': 'media/gamePacManRush/audios/game_bg.m4a'}],
      'preload': {
        'baseUrl': 'media/gamePacManRush',
        'media': [
          'adImage.png',
          'ball.png',
          'bg.1.png',
          'pacman.json',
          'pacman.png',
          'saw.png',
          'striker.png',
        ],
      }
    }
  };
}

export const GamePacManRush = forwardRef((props, ref) => {
  const {width, height, options} = props;

  const game = useRef();

  useImperativeHandle(ref, () => ({
    start, restart, mute, simulate
  }));

  const start = () => {
    game.current.scene.switch('GPRGame');
  }

  const restart = (options) => {
    if (game.current) {
      game.current.options.canPlay = options.canPlay;
      if (!game.current.options.simulation) {
        game.current.events.emit('restart');
      }
    }
  }

  const mute = (mute) => {
    if (game.current) {
      if (game.current.options) {
        game.current.options.mute = mute;
      }
      game.current.events.emit('mute', mute);
    }
  }

  const simulate = () => {
    if (game.current) {
      game.current.events.emit('simulate');
    }
  }

  const onAppDidFocus = () => {
    if (game.current) {
      game.current.resume();
    }
  }

  const onAppDidBlur = () => {
    if (game.current) {
      game.current.pause();
    }
  }

  // Create the gamePacManRush inside a useLayoutEffect hook to avoid the gamePacManRush being created outside the DOM
  useLayoutEffect(() => {
    if (game.current === undefined) {
      if (width && height) {
        config.width = config.height * width/height;
      }
      if (!isWebGLAvailable()) {
        config.type = Phaser.CANVAS;
      }
      if (options.simulation) {
        config.type = Phaser.CANVAS;
        config.audio = {noAudio: true}
      }

      const parent = 'game-container';
      game.current = new Phaser.Game({...config, parent});
      game.current.options = options;

      game.current.events.on('eventOnStart', () => {
        if (options && options.onStart) {
          options.onStart();
        }
      });
      game.current.events.on('eventOnEnd', (score) => {
        if (options && options.onEnd) {
          options.onEnd(score);
        }
      });
      window.addEventListener('focus', onAppDidFocus);
      window.addEventListener('blur', onAppDidBlur);
    }

    return () => {
      if (game.current) {
        game.current.events.removeListener('eventOnStart');
        game.current.events.removeListener('eventOnEnd');
        window.removeEventListener('focus', onAppDidFocus);
        window.removeEventListener('blur', onAppDidBlur);

        game.current.options = null;
        game.current.destroy(true);
        logDebug('DGG:GameDestroyed');
        game.current = undefined;
      }
    }
  }, [width, height, options]);

  return (
    <div id="game-container" style={{backgroundColor: options.bgColor, width: (options.isRotated ? height : width)/options.scale, height: (options.isRotated ? width : height)/options.scale}}/>
  );

});

// Props definitions
GamePacManRush.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.object
}
