import {Scene} from 'phaser';
import {addTimerEvent} from "src/game/GameHelper";

export class GFKMainMenu extends Scene {
  constructor() {
    super('GFKMainMenu');
  }

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
  }

  onEventSimulate = () => {
    this.inAutoSimulation = true;
    addTimerEvent(this, 1000, this.autoPlay);
  }

  autoPlay = () => {
    this.onClick();
  }

  cleanup = () => {
    this.game.events.off('mute', this.onEventMute);
    this.game.events.off('simulate', this.onEventSimulate);
    if (this.audioGameBg) {
      this.audioGameBg.stop();
    }
    this.input.off('pointerdown');
    this.time.removeAllEvents();
    this.events.off('shutdown');
    this.events.off('destroy');
  }

  create() {
    this.events.on('shutdown', this.cleanup);
    this.events.on('destroy', this.cleanup);
    this.game.events.on('mute', this.onEventMute);
    this.game.events.on('simulate', this.onEventSimulate);

    const stringHint = this.game.options.locale && this.game.options.locale == 'ar' ? 'اضغط للبدء' : 'Click to Start';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    if (!this.game.options.simulation) {
      this.audioGameBg = this.sound.add('audio_bg', {loop: true})
        .setMute(this.game.options.mute)
        .setVolume(0.1);
      this.audioGameBg.play();
    }

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const adImageSize = 1000;
    this.adImage1 = this.add.image(gameWidth / 2, gameHeight / 2 + 90, 'adImage1')
      .setDisplaySize(adImageSize, adImageSize)
      .setAlpha(0.4)
      .setDepth(1);

    // this.textScore = this.add.text(gameWidth / 2, 90, this.goalCounter, {
    //   fontFamily: 'Poppins',
    //   fontSize: 128, color: '#fff',
    //   stroke: '#48464F', strokeThickness: 4,
    //   align: 'center'
    // }).setOrigin(0.5);

    // this.add.image(gameWidth - 166, 90, 'ball')
    //   .setDisplaySize(64, 64);
    // this.add.text(gameWidth - 102, 88, 'x', {
    //   fontFamily: 'Poppins',
    //   fontSize: 58, color: '#fff',
    //   stroke: '#48464F', strokeThickness: 2,
    //   align: 'center'
    // }).setOrigin(0.5);
    // this.textTryCount = this.add.text(gameWidth - 54, 90, this.kickCounts, {
    //   fontFamily: 'Poppins',
    //   fontSize: 58, color: '#fff',
    //   stroke: '#48464F', strokeThickness: 2,
    //   align: 'center'
    // }).setOrigin(0.5);

    const goalPostWidth = gameWidth - 60;
    const goalPostHeight = Math.min(goalPostWidth*.45, gameHeight*0.38-160);
    const goalPostY = gameHeight * 0.38 - goalPostHeight/2;
    const goalPostPoleTopHeight = goalPostHeight*.08;
    const goalPostPoleBottomHeight = 4;
    this.goalLine = this.add.image(gameWidth/2, goalPostY+.416*goalPostHeight, 'goalLine')
      .setDisplaySize(goalPostWidth*1.05, goalPostHeight*.25);
    this.goalNet = this.add.image(gameWidth/2, goalPostY-.016*goalPostHeight, 'goalNet')
      .setDisplaySize(goalPostWidth*.95, goalPostHeight*.83);
    this.goalPost = this.physics.add.staticImage(gameWidth/2, goalPostY, 'goalPost')
      .setDisplaySize(goalPostWidth, goalPostHeight);
    const goalPostInHeight = goalPostHeight-goalPostPoleTopHeight-goalPostPoleBottomHeight;
    const goalPostInY = goalPostY-goalPostHeight/2+goalPostPoleTopHeight+goalPostInHeight/2;

    this.ballYInitial = gameHeight * 0.9 - 160;
    this.ballShadow = this.add.image(gameWidth / 2, this.ballYInitial + 120, 'ballShadow')
      .setDisplaySize(224, 160);
    this.ballScaleInitial = 2;
    this.ball = this.physics.add.image(gameWidth / 2, this.ballYInitial, 'ball')
      .setScale(this.ballScaleInitial)
      .setDepth(1);

    this.saverSpace = 4;
    this.saverSize = Math.floor((goalPostInHeight - 2*this.saverSpace)/ 3);
    this.goalScorer = this.physics.add.image(gameWidth*.4, goalPostInY, 'scorer')
      .setDisplaySize(this.saverSize, this.saverSize * 1.3)
      .setBounce(1)
      .setDepth(1);
    this.goalSaver0 = this.physics.add.image(gameWidth*.2, goalPostInY + this.saverSize + this.saverSpace, 'saver')
      .setDisplaySize(this.saverSize, this.saverSize)
      .setBounce(1)
      .setDepth(1);
    this.goalSaver1 = this.physics.add.image(gameWidth*.8, goalPostInY + this.saverSize + this.saverSpace, 'saver')
      .setDisplaySize(this.saverSize, this.saverSize)
      .setBounce(1)
      .setDepth(1);
    this.goalSaver_1 = this.physics.add.image(gameWidth*.6, goalPostInY, 'saver_1')
      .setDisplaySize(this.saverSize, this.saverSize * 1.3)
      .setBounce(1)
      .setDepth(1);

    if (!this.game.options.simulation) {
      this.add.text(gameWidth / 2, gameHeight - 560, stringHint, {
        fontFamily: 'Poppins',
        fontSize: 70, color: '#ffffff',
        stroke: '#48464F', strokeThickness: 4,
        align: 'center'
      }).setOrigin(0.5).setDepth(1);
    }

    this.input.once('pointerdown', this.onClick);
  }

  onClick = () => {
    this.scene.start('GFKGame', {inAutoSimulation: this.inAutoSimulation});
  }
}
