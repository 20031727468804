import React, {useCallback, useEffect, useState} from 'react';
import {
  ColorBlack,
  ColorMediaPickerBg,
  ColorMediaPickerBgError,
  ColorMediaPickerBgPreview,
  ColorWhite
} from 'src/styles/Colors';
import {AppIcon, IconCircle, IconPlay, IconRecord, IconStop} from 'src/js/components/commons/AppIcon';
import View from "src/js/components/commons/View";
import AppText from "src/js/components/commons/AppText";
import {FontWeightMedium} from "src/res/FontWeight";
import {Button, IconButton, LinearProgress, makeStyles} from "@material-ui/core";
import DateTimeHelper from "src/js/utils/DateTimeHelper";
import {logDebug} from "src/js/utils/AppLog";
import Webcam from "react-webcam";
import TextView from "src/js/components/commons/TextView";
import FieldSpace from "src/js/components/commons/FieldSpace";
import {ImageIcon, locString} from "src/js/components/commons/ViewHelper";
import currentDevice from "current-device";
import fixWebmDuration from "fix-webm-duration";
import {BORDER_RADIUS} from "src/styles/Styles";


const useStyles = makeStyles((theme) => ({
  mainContainerWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: ColorMediaPickerBg,
    borderRadius: 0,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      width: Math.min(window.appWidth, 1200),
      height: Math.min(window.appHeight, 900),
      borderRadius: BORDER_RADIUS,
      overflow: 'hidden',
    }
  }
}))

const STATE_NONE = 0;
const STATE_CAPTURED = 1;
const STATE_READY_TO_CAPTURE = 2;
const STATE_RECORDING = 3;
const STATE_SAVING = 4;
const STATE_PLAYING = 5;
const STATE_ERROR = 10;

const RECORD_TYPE_IMAGE = 'image';
const RECORD_TYPE_VIDEO = 'video';
const RECORD_TYPE_AUDIO = 'audio';

const VIDEO_CODECS = ['video/webm', 'video/mp4'];
const AUDIO_CODECS = ['audio/webm', 'audio/mp4'];

const MediaRecorderView = (props) => {
  const {recordTypes, aspectRatioMimes, isXS, duration = 30} = props;

  const classes = useStyles();

  const [device, setDevice] = React.useState({});
  const [camera, setCamera] = React.useState(0);
  const [devices, setDevices] = React.useState([]);
  const [recordType, setRecordType] = useState(recordTypes && recordTypes.length === 1 ? recordTypes[0] : RECORD_TYPE_VIDEO);
  const [enableAction, setEnableAction] = useState(true);
  const [recordState, setRecordState] = useState(recordType === RECORD_TYPE_AUDIO ? STATE_READY_TO_CAPTURE : STATE_NONE);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recordProgress, setRecordProgress] = useState(0);
  const [playProgress, setPlayProgress] = useState(0);
  const supportedVideoMime = React.useRef(null);
  const recordError = React.useRef(null);
  const refWebcam = React.useRef(null);
  const refVideo = React.useRef(null);
  const refMediaRecorder = React.useRef(null);
  const captureFile = React.useRef(null);
  const startTime = React.useRef(null);
  const startTimerHandler = React.useRef(null);
  const isAndroid = React.useRef(currentDevice.android());
  const isMobile = React.useRef(currentDevice.mobile());
  const isTablet = React.useRef(currentDevice.tablet());
  const isLandscape = React.useRef(currentDevice.landscape());
  const isPortrait = React.useRef(currentDevice.portrait());

  const getMediaDimensions = React.useCallback((mediaType) => {
    const aspectRatioVideo = aspectRatioMimes ? aspectRatioMimes.video : 1;
    const aspectRatioImage = aspectRatioMimes ? aspectRatioMimes.image : 1;
    let aspectRatio = mediaType === RECORD_TYPE_IMAGE ? aspectRatioImage : aspectRatioVideo;
    if (!aspectRatio) {
      aspectRatio = 4/3;
    }
    const mediaRes = {aspectRatio: aspectRatio};

    if (isMobile.current && isPortrait.current) {
      if (aspectRatio == 9/16) {
        aspectRatio = 16/9;
      }
    }
    if (aspectRatio == 16/9) {
      mediaRes.minWidth = 1280;
      mediaRes.idealWidth = 1920;
      mediaRes.maxWidth = 2560;
      mediaRes.minHeight = 720;
      mediaRes.idealHeight = 1080;
      mediaRes.maxHeight = 1440;
    } else if (aspectRatio == 9/16) {
      mediaRes.minWidth = 720;
      mediaRes.idealWidth = 1080;
      mediaRes.maxWidth = 1440;
      mediaRes.minHeight = 1280;
      mediaRes.idealHeight = 1920;
      mediaRes.maxHeight = 2560;
    } else if (aspectRatio == 4/3) {
      mediaRes.minWidth = 960;
      mediaRes.idealWidth = 1440;
      mediaRes.maxWidth = 1920;
      mediaRes.minHeight = 1280;
      mediaRes.idealHeight = 1920;
      mediaRes.maxHeight = 2560;
    } else {
      mediaRes.minWidth = 720;
      mediaRes.idealWidth = 1280;
      mediaRes.maxWidth = 1920;
      mediaRes.minHeight = 720;
      mediaRes.idealHeight = 1280;
      mediaRes.maxHeight = 1920;
    }

    logDebug('MediaRecorder-mediaDimensions', mediaRes);
    return mediaRes;
  });

  const mediaDimensions = React.useRef(getMediaDimensions(recordType.current));

  useEffect(() => {
    if (recordState === STATE_RECORDING) {
      setIntervalForRecordProgress();
    }
    // if (recordState === STATE_SAVING && recordedChunks.length) {
    if (recordState === STATE_SAVING) {
      if (recordType === RECORD_TYPE_IMAGE) {
        saveImage();
      } else if (recordType === RECORD_TYPE_VIDEO || recordType === RECORD_TYPE_AUDIO) {
        saveCaptured();
      }
    }
    return (() => {
      if (startTimerHandler.current) {
        clearInterval(startTimerHandler.current);
      }
    })
  }, [recordState, recordedChunks, recordType])

  const callAfter = (handler, timeout) => {
    setTimeout(handler, timeout);
  }

  const setIntervalForRecordProgress = () => {
    startTimerHandler.current = setInterval(onRecordProgress, 500);
  }

  const onRecordProgress = () => {
    const progress = ((new Date()).getTime()-startTime.current)/1000;
    // logDebug('MediaRecorder-RecordProgress', progress);
    setRecordProgress(progress);
    if (progress >= duration) {
      handleStopCaptureClick();
    }
  };

  const onPlayProgress = () => {
    const progress = refVideo.current.currentTime/recordProgress*100;
    // logDebug('MediaRecorder-PlayProgress', progress);
    setPlayProgress(progress);
  };

  const onPlayEnded = () => {
    // logDebug('MediaRecorder-PlayEnded');
    setPlayProgress(0);
    setRecordState(STATE_CAPTURED);
  };

  const handleDevices = React.useCallback((mediaDevices) => {
    const devices = mediaDevices.filter(({kind}) => kind === "videoinput");
    setDevices(devices);
    if (devices && devices.length > 0) {
      setDevice(devices[0]);
    }
  }, [setDevices, setDevice]);

  const handleSwitchDeviceClick = React.useCallback(() => {
    setRecordState(STATE_ERROR);
    let index = 0;
    for (let i = 0; i < devices.length; i++) {
      if (devices[i].id === device.id) {
        break;
      }
      index++;
    }
    if (index === devices.length) {
      index = 0;
    }
    setTimeout(() => {
      setRecordState(STATE_NONE);
      setDevice(devices[index]);
    });
  }, [devices, device, setDevice, setRecordState]);

  const handleSwitchCameraClick = React.useCallback(() => {
    setCamera(camera ? 0 : 1);
  }, [devices, device, setDevice, setRecordState, camera, setCamera]);

  const getNoPermissionErrorMessage = () => {
    if (recordType === RECORD_TYPE_AUDIO) {
      return isAndroid.current ? locString('record.audioCaptureErrorAndroid') : locString('record.audioCaptureError');
    } else if (recordType === RECORD_TYPE_VIDEO) {
      return isAndroid.current ? locString('record.videoCaptureErrorAndroid') : locString('record.videoCaptureError');
    } else {
      return isAndroid.current ? locString('record.imageCaptureErrorAndroid') : locString('record.imageCaptureError');
    }
  }

  const getRecordErrorMessage = () => {
    if (recordType === RECORD_TYPE_AUDIO) {
      return locString('record.audioError');
    } else if (recordType === RECORD_TYPE_VIDEO) {
      return locString('record.videoError');
    } else {
      return locString('record.imageError');
    }
  }

  const saveCaptured = React.useCallback(() => {
    // const saveCaptured = (callback) => {
    logDebug('MediaRecorder-savingCaptured');
    if (recordState === STATE_SAVING) {
      setEnableAction(false);
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: supportedVideoMime.current
        });
        const onBlobFixed = (newBlob) => {
          const url = URL.createObjectURL(newBlob);
          logDebug('MediaRecorder-saved');
          captureFile.current = {file: new File([newBlob], 'TempRab7_0.mp4'), url: url, type: recordType};
          setRecordState(STATE_CAPTURED);
          cleanUp();
          callAfter(() => {
            setEnableAction(true);
          });
        };
        if (supportedVideoMime.current.startsWith('video/webm') || supportedVideoMime.current.startsWith('audio/webm')) {
          fixWebmDuration(blob, recordProgress*1000, onBlobFixed);
        } else {
          setTimeout(() => {
            onBlobFixed(blob);
          });
        }
      } else {
        logDebug('MediaRecorder-NoDataPresent');
        recordError.current = getRecordErrorMessage();
        setRecordState(STATE_ERROR);
        cleanUp();
        callAfter(() => {
          setEnableAction(true);
        });
      }
    }
    // };
  }, [recordedChunks, setRecordedChunks, recordType, setRecordType, recordState, supportedVideoMime, setRecordState]);

  const cleanUp = () => {
    if (refMediaRecorder.current) {
      refMediaRecorder.current.removeEventListener(
        "dataavailable",
        handleDataAvailable
      );
      refMediaRecorder.current.removeEventListener(
        "error",
        handleMediaError
      );
      refMediaRecorder.current.removeEventListener(
        "stop",
        handleMediaOnStop
      );
      refMediaRecorder.current.removeEventListener(
        "start",
        handleMediaOnStart
      );
      refMediaRecorder.current = null;
    }
    if (refWebcam.current) {
      refWebcam.current.stopAndCleanup;
      refWebcam.current = null;
    }
    setRecordedChunks([]);
  }

  const handleStartCaptureClick = React.useCallback(() => {
  // const handleStartCaptureClick = () => {
    const handleError = (error) => {
      logDebug('MediaRecorder-error', error);
      // recordError.current = error;
      recordError.current = getNoPermissionErrorMessage();
      setRecordState(STATE_ERROR);
      callAfter(() => {
        setEnableAction(true);
      });
    }

    setEnableAction(false);
    try {
      if (!refMediaRecorder.current) {
        supportedVideoMime.current = null;
        const codecs = recordType === RECORD_TYPE_AUDIO ? AUDIO_CODECS : VIDEO_CODECS;
        for (let i = 0; i < codecs.length; i++) {
          const isSupported = MediaRecorder.isTypeSupported(codecs[i]);
          if (isSupported) {
            supportedVideoMime.current = codecs[i];
            break;
          }
        }

        const startMediaStream = (mediaStream) => {
          if (mediaStream) {
            logDebug('MediaRecorder-SupportedMediaType', supportedVideoMime.current);
            refMediaRecorder.current = new MediaRecorder(mediaStream, {
              audioBitsPerSecond: 128 * 1000 * 8,
              videoBitsPerSecond: 5000 * 1000 * 8,
              mimeType: supportedVideoMime.current
            });
            refMediaRecorder.current.addEventListener(
              "dataavailable",
              handleDataAvailable
            );
            refMediaRecorder.current.addEventListener(
              "error",
              handleMediaError
            );
            refMediaRecorder.current.addEventListener(
              "stop",
              handleMediaOnStop
            );
            refMediaRecorder.current.addEventListener(
              "start",
              handleMediaOnStart
            );
            refMediaRecorder.current.start();
          } else {
            recordError.current = getNoPermissionErrorMessage();
            setRecordState(STATE_ERROR);
            callAfter(() => {
              setEnableAction(true);
            });
          }
        }

        if (recordType === RECORD_TYPE_AUDIO) {
          navigator.mediaDevices.getUserMedia({audio: {echoCancellation: false}, video: false}).then(startMediaStream).catch(handleError);
        } else {
          startMediaStream(refWebcam.current.stream);
        }
      }
    } catch (error) {
      handleError(error);
    }
  // };
  }, [refWebcam, refMediaRecorder, recordState, startTime, startTimerHandler, recordError, supportedVideoMime, setEnableAction, setRecordState]);

  const handleStopCaptureClick = () => {
    setEnableAction(false);

    refMediaRecorder.current.stop();
    const progress = ((new Date()).getTime()-startTime.current)/1000;
    setRecordProgress(progress);
    clearInterval(startTimerHandler.current);
    startTime.current = null;
    startTimerHandler.current = null;
  };

  const handleDataAvailable = useCallback(({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
        logDebug('MediaRecorder-OnDataAvailable');
      }
    }, [setRecordedChunks]);

  const handleMediaError = useCallback((error) => {
    // recordError.current = error;
    recordError.current = getRecordErrorMessage();
    setRecordState(STATE_ERROR);
  }, [setRecordState]);

  const handleMediaOnStop = useCallback(() => {
    logDebug('MediaRecorder-OnStop');
    setRecordState(STATE_SAVING);
  });

  const handleMediaOnStart = useCallback(() => {
    logDebug('MediaRecorder-OnStart');
    startTime.current = (new Date()).getTime();
    recordError.current = null;
    setRecordState(STATE_RECORDING);
    callAfter(() => {
      setEnableAction(true);
    }, 3500);
  }, [startTime, recordError, setRecordState, setEnableAction]);

  // const handleStartPlayClick = React.useCallback(() => {
  const handleStartPlayClick = () => {
    setEnableAction(false);
    if (refVideo.current) {
      refVideo.current.play();
    }
    setRecordState(STATE_PLAYING);
    callAfter(() => {
      setEnableAction(true);
    });
  };
  // }, [refVideo]);

  // const handleStopPlayClick = React.useCallback(() => {
  const handleStopPlayClick = () => {
      setEnableAction(false);
      if (refVideo.current) {
        refVideo.current.pause();
        refVideo.current.currentTime = 0;
        setPlayProgress(0);
      }
      setRecordState(STATE_CAPTURED);

      callAfter(() => {
        setEnableAction(true);
      })
  };
  // }, [refMediaRecorder, refWebcam, setRecordState, setcaptureFile]);

  // const captureImage = useCallback(() => {
  const handleStartCaptureImage = () => {
    setEnableAction(false);
    setRecordedChunks((prev) => prev.concat(refWebcam.current.getScreenshot()));
    setRecordState(STATE_SAVING);
  };

  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

  const saveImage = React.useCallback(() => {
    if (recordState === STATE_SAVING) {
      setEnableAction(false);
      if (recordedChunks.length) {
        const base64Data = recordedChunks[0];
        const blob = b64toBlob(base64Data.substring(base64Data.indexOf('base64,')+ 7), 'image/webp');
        const url = URL.createObjectURL(blob);
        captureFile.current = {file: new File([blob], 'TempRab7_0.webp'), url: url, type: recordType, blob: base64Data};
        setRecordState(STATE_CAPTURED);
        logDebug('MediaRecorder-SavedImage');
      } else {
        logDebug('MediaRecorder-NoData');
        recordError.current = getRecordErrorMessage();
        setRecordState(STATE_ERROR);
      }
      cleanUp();
      callAfter(() => {
        setEnableAction(true);
      });
    }
  }, [recordedChunks, setRecordedChunks, recordType, setRecordType, recordState, setRecordState]);

  // }, [refWebcam, setcaptureFile]);

  // const handleDiscard = React.useCallback(() => {
  const handleDiscard = () => {
    setEnableAction(false);
    URL.revokeObjectURL(captureFile.current.url);
    captureFile.current = null;
    setRecordState(recordType === RECORD_TYPE_AUDIO ? STATE_READY_TO_CAPTURE : STATE_NONE);
    callAfter(() => {
      setEnableAction(true);
    });
  };
  // }, [captureFile, setcaptureFile, setRecordState]);

  // const handleDone = React.useCallback(() => {
  const handleDone = () => {
    props.onDone(captureFile.current)
  };

  const handleCancel = () => {
    cleanUp();
    props.onCancel();
  };
  // }, [captureFile]);

  const handleRecordMediaType = (media) => {
    if (media === recordType) {
      return;
    }

    setRecordState(STATE_ERROR);
    setRecordType(media);
    setTimeout(() => {
      mediaDimensions.current = getMediaDimensions(media);
      setRecordState(STATE_READY_TO_CAPTURE);
    })
  };

  const videoConstraints = {
    width: {min: mediaDimensions.current.minWidth, ideal: mediaDimensions.current.idealWidth, max: mediaDimensions.current.maxWidth},
    height: {min: mediaDimensions.current.minHeight, ideal: mediaDimensions.current.idealHeight, max: mediaDimensions.current.maxHeight},
    aspectRatio: mediaDimensions.current.aspectRatio,
    frameRate: 30,
    echoCancellation: false,
    facingMode: camera ? {exact:  "environment"} : "user",
  }
  // if (device) {
  //   videoConstraints.deviceId = device.id;
  // }
  const imageConstraints = {
    width: mediaDimensions.current.width,
    height: mediaDimensions.current.height,
  }
  const translucentColor = 'rgba(0,0,0,0.1)';

  let actionIcon = null;
  let actionColor = '#FFF';
  let actionSize = isXS ? 22 : 26;
  let onActionClick = null;
  if (recordState === STATE_READY_TO_CAPTURE) {
    actionIcon = IconCircle;
    if (recordType === RECORD_TYPE_IMAGE) {
      actionSize = isXS ? 36 : 44;
      onActionClick = handleStartCaptureImage;
    } else if (recordType === RECORD_TYPE_VIDEO) {
      actionColor = '#F00';
      onActionClick = handleStartCaptureClick;
    } else if (recordType === RECORD_TYPE_AUDIO) {
      actionIcon = IconRecord;
      actionColor = '#F00';
      onActionClick = handleStartCaptureClick;
    }
  } else if (recordState === STATE_RECORDING) {
    actionIcon = IconStop;
    actionColor = '#F00';
    onActionClick = handleStopCaptureClick;
  } else if (recordState === STATE_SAVING) {
    if (recordType === RECORD_TYPE_IMAGE) {
      actionIcon = IconCircle;
      actionSize = isXS ? 36 : 44;
    } else if (recordType === RECORD_TYPE_VIDEO || recordType === RECORD_TYPE_AUDIO) {
      actionIcon = IconStop;
      actionColor = '#F00';
    }
  } else if (recordState === STATE_PLAYING) {
    actionIcon = IconStop;
    onActionClick = handleStopPlayClick;
  } else if (recordState === STATE_CAPTURED) {
    if (recordType === RECORD_TYPE_VIDEO || recordType === RECORD_TYPE_AUDIO) {
      actionIcon = IconPlay;
      onActionClick = handleStartPlayClick;
    }
  }
  const showRecordTypePicker = recordState === STATE_READY_TO_CAPTURE
    || recordState === STATE_RECORDING
    || recordState === STATE_SAVING
    || recordState === STATE_PLAYING
    || (recordState === STATE_CAPTURED && (recordType === RECORD_TYPE_VIDEO || recordType === RECORD_TYPE_AUDIO));
  const showRecordImagePicker = !recordTypes || recordTypes.length === 0 ? RECORD_TYPE_IMAGE : recordTypes.includes(RECORD_TYPE_IMAGE);
  const showRecordVideoPicker = !recordTypes || recordTypes.length === 0 ? RECORD_TYPE_VIDEO : recordTypes.includes(RECORD_TYPE_VIDEO);
  const showRecordAudioPicker = !recordTypes || recordTypes.length === 0 ? RECORD_TYPE_AUDIO : recordTypes.includes(RECORD_TYPE_AUDIO);
  let enableCameraSwitcher = recordState === STATE_READY_TO_CAPTURE && devices && devices.length > 1 && (isMobile.current || isTablet.current);
  const doneButtonTitle = captureFile.current && captureFile.current.type === RECORD_TYPE_IMAGE ? locString('record.usePhoto') : (captureFile.current && captureFile.current.type === RECORD_TYPE_AUDIO ? locString('record.useAudio') : locString('record.useVideo'));

  return (
    <div className={classes.mainContainerWrapper}>
      <div className={classes.mainContainer}>
        {(recordState === STATE_NONE || recordState === STATE_READY_TO_CAPTURE || recordState === STATE_RECORDING)
          && (recordType === RECORD_TYPE_VIDEO || recordType === RECORD_TYPE_IMAGE) ?
          <Webcam
            width={'100%'}
            height={'100%'}
            style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center'}}

            audio={true}
            muted={true}
            disablePictureInPicture={true}
            audioConstraints={{
              echoCancellation: false
            }}
            videoConstraints={videoConstraints}
            ref={refWebcam}
            onUserMedia={() => {
              if (navigator.mediaDevices) {
                navigator.mediaDevices.enumerateDevices().then(handleDevices);
              }
              setRecordProgress(0);
              setRecordState(STATE_READY_TO_CAPTURE);
            }}
            onUserMediaError={(error) => {
              logDebug('MediaRecorder-Error', error)
              // recordError.current = error.message;
              recordError.current = getNoPermissionErrorMessage();
              setRecordState(STATE_ERROR)
            }}
          />
          : null
        }

        {(recordState === STATE_CAPTURED || recordState === STATE_PLAYING) && captureFile.current ?
          <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: ColorMediaPickerBgPreview}}>
            {(recordState === STATE_CAPTURED || recordState === STATE_PLAYING) && captureFile.current && (captureFile.current.type === RECORD_TYPE_VIDEO || captureFile.current.type === RECORD_TYPE_AUDIO) ?
              <React.Fragment>
                <video ref={refVideo} onEnded={onPlayEnded} onTimeUpdate={onPlayProgress} style={{width: '100%', height: '100%'}} playsInline={true} src={captureFile.current.url}/>
                {captureFile.current && captureFile.current.type === RECORD_TYPE_AUDIO ?
                  <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                    <img src={recordState === STATE_CAPTURED ? require('src/images/img_audio_poster.png') : require('src/images/img_audio_poster.gif')} style={{width: '100%', height: '100%', objectFit: 'contain'}} />
                  </div>
                  : null
                }
              </React.Fragment>
              : null
            }
            {recordState === STATE_CAPTURED && captureFile.current && captureFile.current.type === RECORD_TYPE_IMAGE ?
              <img style={{width: '100%', height: '100%', objectFit: 'contain'}} src={captureFile.current.url}/>
              : null
            }
          </div>
          : null
        }
        {(recordState === STATE_RECORDING) && recordType === RECORD_TYPE_AUDIO ?
          <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <React.Fragment>
              <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                <img src={require('src/images/img_listening.gif')} style={{width: 600, height: 450}} />
              </div>
            </React.Fragment>
          </div>
          : null
        }

        <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexDirection: 'column'}}>
          <div style={{width: '100%', height: 4, backgroundColor: translucentColor}}>
            {recordState === STATE_RECORDING ?
              <LinearProgress color={'secondary'} variant={'determinate'} value={recordProgress/duration*100}/>
              : null
            }
          </div>
          {recordState === STATE_RECORDING || recordState === STATE_CAPTURED ?
            <div style={{position: 'relative', width: '100%', display: 'flex', flexDirection: 'row', backgroundColor: translucentColor, padding: 12, minHeight: 60}}>
              {recordState === STATE_RECORDING || (recordState === STATE_CAPTURED && captureFile.current && (captureFile.current.type === RECORD_TYPE_VIDEO || captureFile.current.type === RECORD_TYPE_AUDIO)) ?
                <View style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center'}}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F00', borderRadius: 8, padding: 4, paddingLeft: 12, paddingRight: 12}}>
                    <AppText
                      variant={'body1'}
                      style={{
                        color: ColorWhite,
                        fontWeight: FontWeightMedium,
                        textAlign: 'center'}}>
                      {/*{Math.floor(recordProgress) +' secs'}*/}
                      {DateTimeHelper.getDisplayTimeForSeconds(Math.min(duration, recordProgress))}
                    </AppText>
                  </div>
                </View>
                : null
              }
              {recordState === STATE_CAPTURED ?
                <Button disableElevation size={isXS ? 'medium' : 'large'} variant={'contained'} color={ColorBlack} style={{backgroundColor: ColorWhite}} onClick={handleDiscard}>{locString('record.retake')}</Button>
                : null
              }
              <div style={{flex: 1}}/>
              {recordState === STATE_CAPTURED ?
                <Button disableElevation disabled={!captureFile.current} size={isXS ? 'medium' : 'large'} variant={'contained'} color={ColorBlack} style={{backgroundColor: ColorWhite}} onClick={handleDone}>{doneButtonTitle}</Button>
                : null
              }
            </div>
            : null
          }

          <div style={{flex: 1}}/>

          {/*{devices && devices.map((device) => <TextView>{JSON.stringify(device)}</TextView>)}*/}

          {actionIcon || showRecordTypePicker ?
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: translucentColor, padding: 16, paddingLeft: 16, paddingRight: 16}}>
              <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 4}}>
                <div style={{flex: 1}}/>
                <IconButton
                  style={{width: 44, height: 44, borderRadius: 22, backgroundColor: translucentColor, opacity: 0}}
                  disabled={true}
                  onClick={onActionClick}>
                  <AppIcon style={{width: actionSize, height: actionSize}} icon={actionIcon} color={actionColor}/>
                </IconButton>
                <div style={{minWidth: 160, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <IconButton
                    style={{width: isXS ? 52 : 60, height: isXS ? 52 : 60, borderRadius: isXS ? 26 : 30, border: '4px solid #FFF', backgroundColor: translucentColor, opacity: enableAction ? 1 : 0.5}}
                    disabled={!enableAction}
                    onClick={onActionClick}>
                    <AppIcon style={{width: actionSize, height: actionSize}} icon={actionIcon} color={actionColor}/>
                  </IconButton>
                </div>
                <IconButton
                  style={{width: 44, height: 44, borderRadius: 22, backgroundColor: translucentColor, opacity: enableCameraSwitcher ? 1 : 0}}
                  disabled={!enableCameraSwitcher}
                  onClick={handleSwitchCameraClick}>
                  <ImageIcon style={{width: 25, height: 22}} src={require('src/images/ic_camera_rotate.png')}/>
                </IconButton>
                <div style={{flex: 1}}/>
              </div>

              <div style={{width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 16, opacity: recordState === STATE_READY_TO_CAPTURE ? 1 : 0}}>
                <div style={{flex: 1}}/>
                {showRecordImagePicker ? <Button disableElevation disabled={recordState !== STATE_READY_TO_CAPTURE} size={'small'} style={{color: recordType === RECORD_TYPE_IMAGE ? ColorWhite : '#FFFFFF55', marginLeft: 12, marginRight: 12}} onClick={() => handleRecordMediaType(RECORD_TYPE_IMAGE)}>{locString('record.photoCaps')}</Button> : null}
                {showRecordVideoPicker ? <Button disableElevation disabled={recordState !== STATE_READY_TO_CAPTURE} size={'small'} style={{color: recordType === RECORD_TYPE_VIDEO ? ColorWhite : '#FFFFFF55', marginLeft: 12, marginRight: 12}} onClick={() => handleRecordMediaType(RECORD_TYPE_VIDEO)}>{locString('record.videoCaps')}</Button> : null}
                {showRecordAudioPicker ? <Button disableElevation disabled={recordState !== STATE_READY_TO_CAPTURE} size={'small'} style={{color: recordType === RECORD_TYPE_AUDIO ? ColorWhite : '#FFFFFF55', marginLeft: 12, marginRight: 12}} onClick={() => handleRecordMediaType(RECORD_TYPE_AUDIO)}>{locString('record.recordAudioCaps')}</Button> : null}
                <div style={{flex: 1}}/>
              </div>
            </div>
            : null
          }
          <div style={{width: '100%', height: 4, backgroundColor: translucentColor}}>
            {recordState === STATE_PLAYING ?
              <LinearProgress color={'secondary'} variant={'determinate'} value={playProgress}/>
              : null
            }
          </div>
        </div>

        {recordState === STATE_ERROR && recordError.current ?
          <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: ColorMediaPickerBgError}}>
            <div style={{padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <TextView textAlign={'center'} variant={'h6'} color={ColorWhite}>{recordError.current}</TextView>
              <FieldSpace multiplier={2} />
              <Button disableElevation size={isXS ? 'medium' : 'large'} variant={'contained'} color={ColorBlack} style={{backgroundColor: ColorWhite}} onClick={() => {
                recordError.current = null;
                setRecordState(recordType === RECORD_TYPE_AUDIO ? STATE_READY_TO_CAPTURE : STATE_NONE);
              }}>{locString('generic.retry')}</Button>
            </div>
          </div>
          : null
        }
        {recordState === STATE_NONE || recordState === STATE_ERROR || recordState === STATE_READY_TO_CAPTURE ?
          <div style={{position: 'absolute', top: 0, left: 0}}>
            <div style={{padding: 12, paddingTop: 16}}>
              <Button disableElevation size={isXS ? 'medium' : 'large'} variant={'contained'} color={ColorBlack} style={{backgroundColor: ColorWhite}} onClick={handleCancel}>{locString('generic.close')}</Button>
            </div>
          </div>
          : null
        }
      </div>
    </div>
  );
};
export default MediaRecorderView;
