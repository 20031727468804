import {Scene} from 'phaser';
import {logDebug} from "src/js/utils/AppLog";
import {addTimerEvent} from "src/game/GameHelper";

export class GFKGame extends Scene {
  constructor() {
    super('GFKGame');
  }

  onEventRestart = () => {
    this.cleanup();
    if (!this.game.options.simulation) {
      this.scene.stop('GFKGame');
      this.scene.start('GFKMainMenu');
    }
  };

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
    if (this.audioKick) {
      this.audioKick.setMute(mute);
    }
    if (this.audioGoal) {
      this.audioGoal.setMute(mute);
    }
    if (this.audioWhistle) {
      this.audioWhistle.setMute(mute);
    }
    if (this.audioHitPlayer) {
      this.audioHitPlayer.setMute(mute);
    }
    if (this.audioHitPole) {
      this.audioHitPole.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
    if (this.audioGameWin) {
      this.audioGameWin.setMute(mute);
    }
  };

  autoPlay = () => {
    if (!this.gameEnded) {
      const xDelta = 200;
      const yDelta = 400;
      let x1 = this.ball.x - xDelta;
      let x2 = this.ball.x + xDelta;
      let y1 = this.ball.y + yDelta;
      let y2 = this.ball.y - yDelta;

      const downX = Phaser.Math.Between(x1, x2);
      const upX = Phaser.Math.Between(x1, x2);
      let downY = Phaser.Math.Between(y1, y1 + (y2-y1)/2);
      const upY = Phaser.Math.Between(y1 + (y2-y1)/2, y2);
      const pointer = {
        downX: downX,
        upX: upX,
        downY: downY,
        upY: upY,
        position: {
          x: upX,
          y: upY,
        },
      }
      if (Math.abs(pointer.upY - pointer.downY) <= 40) {
        pointer.downY = upY - 50;
      }

      this.onSwipeStart(pointer);
      this.onSwipeMove(pointer);
      addTimerEvent(this, 500, () => {
        this.onSwipeEnd(pointer);
      });
    }
  }

  cleanup = () => {
    this.game.events.off('mute', this.onEventMute);
    this.game.events.off('restart', this.onEventRestart);
    if (this.audioGameBg) {
      this.audioGameBg.stop();
    }
    this.detachPointers();
    this.time.removeAllEvents();
    this.events.off('destroy');
  }

  init(data) {
    this.inAutoSimulation = data.inAutoSimulation;
  }

  create() {
    this.events.on('destroy', this.cleanup);
    this.game.events.on('mute', this.onEventMute);
    this.game.events.on('restart', this.onEventRestart);

    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';
    this.stringGoal = this.game.options.locale && this.game.options.locale == 'ar' ? ' G O A L ' : ' G O A L ';
    this.stringHurray = this.game.options.locale && this.game.options.locale == 'ar' ? ' H U R R A Y ' : ' H U R R A Y ';
    const stringHint = this.game.options.locale && this.game.options.locale == 'ar' ? 'امسح و اركل الكرة' : 'Swipe to Kick the Ball';
    const stringHintDetail = this.game.options.locale && this.game.options.locale == 'ar' ? 'اسحب:  ↑ (ارتفاع),  →/← (عرض)' : 'Swipe:  ↑ (height),  ←/→ (width)';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.strikeHeight = 0;
    this.ballCollided = false;
    this.ballKicked = false;
    this.goalCounter = 0;
    this.kickCounts = this.game.options.tryCount || 10;
    this.scoreToWin = this.game.options.scoreToWin || 5;
    this.kickCounter = 0;
    this.readyToShoot = true;
    this.circles = [];

    this.gameEnded = false;
    this.showAdBanner = false;

    if (!this.game.options.simulation) {
      this.audioGameBg = this.sound.add('audio_bg', {loop: true})
        .setMute(this.game.options.mute)
        .setVolume(0.1);
      this.audioGameBg.play();
      this.audioKick = this.sound.add('audio_kick').setMute(this.game.options.mute);
      // this.audioKick.setVolume(0.3);
      this.audioGoal = this.sound.add('audio_goal').setMute(this.game.options.mute)
        .setVolume(0.7);
      this.audioWhistle = this.sound.add('audio_whistle').setMute(this.game.options.mute);
      // this.audioWhistle.setVolume(0.7);
      this.audioHitPlayer = this.sound.add('audio_hit_player').setMute(this.game.options.mute);
      // this.audioHitPlayer.setVolume(0.7);
      this.audioHitPole = this.sound.add('audio_hit_pole').setMute(this.game.options.mute);
      // this.audioHitPole.setVolume(0.7);

      this.audioGameEnd = this.sound.add('audio_game_end').setMute(this.game.options.mute);
      this.audioGameEnd.setVolume(0.2);
      this.audioGameWin = this.sound.add('audio_game_win').setMute(this.game.options.mute);
      this.audioGameWin.setVolume(0.5);
    }

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const adImageSize = 1000;
    this.adImage1 = this.add.image(gameWidth / 2, gameHeight / 2 + 90, 'adImage1')
      .setDisplaySize(adImageSize, adImageSize)
      .setAlpha(0.4)
      .setDepth(1);

    this.textScore = this.add.text(gameWidth / 2, 90, this.goalCounter, {
      fontFamily: 'Poppins',
      fontSize: 128, color: '#fff',
      stroke: '#48464F', strokeThickness: 4,
      align: 'center'
    }).setOrigin(0.5);

    this.add.image(gameWidth - 166, 90, 'ball')
      .setDisplaySize(64, 64);
    this.add.text(gameWidth - 102, 88, 'x', {
      fontFamily: 'Poppins',
      fontSize: 58, color: '#fff',
      stroke: '#48464F', strokeThickness: 2,
      align: 'center'
    }).setOrigin(0.5);
    this.textTryCount = this.add.text(gameWidth - 54, 90, this.kickCounts, {
      fontFamily: 'Poppins',
      fontSize: 58, color: '#fff',
      stroke: '#48464F', strokeThickness: 2,
      align: 'center'
    }).setOrigin(0.5);

    const goalPostWidth = gameWidth - 60;
    const goalPostHeight = Math.min(goalPostWidth*.45, gameHeight*0.38-160);
    const goalPostY = gameHeight*0.38 - goalPostHeight/2;
    const goalPostPoleWidth = goalPostWidth*.044;
    const goalPostPoleTopHeight = goalPostHeight*.08;
    const goalPostPoleBottomHeight = 4;
    this.goalLine = this.add.image(gameWidth/2, goalPostY+.416*goalPostHeight, 'goalLine')
      .setDisplaySize(goalPostWidth*1.05, goalPostHeight*.25);
    this.goalNet = this.add.image(gameWidth/2, goalPostY-.016*goalPostHeight, 'goalNet')
      .setDisplaySize(goalPostWidth*.95, goalPostHeight*.83);
    this.goalPost = this.physics.add.staticImage(gameWidth/2, goalPostY, 'goalPost')
      .setDisplaySize(goalPostWidth, goalPostHeight);
    const goalPostInHeight = goalPostHeight-goalPostPoleTopHeight-goalPostPoleBottomHeight;
    const goalPostInWidth = goalPostWidth - 2*goalPostPoleWidth;
    const goalPostInY = goalPostY-goalPostHeight/2+goalPostPoleTopHeight+goalPostInHeight/2;
    this.goalPostIn = this.add.rectangle(gameWidth/2, goalPostInY, goalPostInWidth, goalPostInHeight, 0xFFFFFF)
      .setVisible(false)
      .setAlpha(0.4);
    this.physics.add.existing(this.goalPostIn);
    this.goalPostOutTop = this.add.rectangle(gameWidth/2, goalPostY-goalPostHeight/2+goalPostPoleTopHeight/2, goalPostWidth - goalPostPoleWidth, goalPostPoleTopHeight, 0xFF0000)
      .setVisible(false)
      .setAlpha(0.4);
    this.goalPostOutTop = this.physics.add.existing(this.goalPostOutTop, true);
    this.goalPostOutBottom = this.add.rectangle(gameWidth/2, goalPostY+goalPostHeight/2+goalPostPoleBottomHeight/2, goalPostWidth - goalPostPoleWidth, goalPostPoleBottomHeight, 0xFF0000)
      .setVisible(false)
      .setAlpha(0.4);
    this.goalPostOutBottom = this.physics.add.existing(this.goalPostOutBottom, true);
    this.goalPostOutLeft = this.add.rectangle(gameWidth/2-(goalPostWidth/2-goalPostPoleWidth/2), goalPostY+goalPostPoleTopHeight/2, goalPostPoleWidth, goalPostHeight-goalPostPoleTopHeight/2-goalPostPoleBottomHeight/2, 0xFF0000)
      .setVisible(false)
      .setAlpha(0.4);
    this.goalPostOutLeft = this.physics.add.existing(this.goalPostOutLeft, true);
    this.goalPostOutRight = this.add.rectangle(gameWidth/2+(goalPostWidth/2-goalPostPoleWidth/2), goalPostY+goalPostPoleTopHeight/2, goalPostPoleWidth, goalPostHeight-goalPostPoleTopHeight/2-goalPostPoleBottomHeight/2, 0xFF0000)
      .setVisible(false)
      .setAlpha(0.4);
    this.goalPostOutRight = this.physics.add.existing(this.goalPostOutRight, true);

    this.ballYInitial = gameHeight * 0.9 - 160;
    this.ballShadow = this.add.image(gameWidth / 2, this.ballYInitial + 120, 'ballShadow')
      .setDisplaySize(224, 160);
    this.ballScaleInitial = 2;
    this.ball = this.physics.add.image(gameWidth / 2, this.ballYInitial, 'ball')
      .setScale(this.ballScaleInitial)
      .setAlpha(0.5)
      .setDepth(10);

    this.saverSpace = 4;
    this.saverSize = Math.floor((goalPostInHeight - 2*this.saverSpace)/ 3);
    this.goalScorer = this.physics.add.image(gameWidth*.4, goalPostInY, 'scorer')
      .setDisplaySize(this.saverSize, this.saverSize * 1.3)
      .setBounce(1)
      .setDepth(1);
    this.goalSaver0 = this.physics.add.image(gameWidth*.2, goalPostInY + this.saverSize + this.saverSpace, 'saver')
      .setDisplaySize(this.saverSize, this.saverSize)
      .setBounce(1)
      .setDepth(1);
    this.goalSaver1 = this.physics.add.image(gameWidth*.8, goalPostInY + this.saverSize + this.saverSpace, 'saver')
      .setDisplaySize(this.saverSize, this.saverSize)
      .setBounce(1)
      .setDepth(1);
    this.goalSaver_1 = this.physics.add.image(gameWidth*.6, goalPostInY, 'saver_1')
      .setDisplaySize(this.saverSize, this.saverSize * 1.3)
      .setBounce(1)
      .setDepth(1);
    this.physics.add.collider(this.goalScorer, this.goalPostOutTop);
    this.physics.add.collider(this.goalScorer, this.goalPostOutBottom);
    this.physics.add.collider(this.goalScorer, this.goalPostOutLeft);
    this.physics.add.collider(this.goalScorer, this.goalPostOutRight);
    this.physics.add.collider(this.goalSaver0, this.goalPostOutTop);
    this.physics.add.collider(this.goalSaver0, this.goalPostOutBottom);
    this.physics.add.collider(this.goalSaver0, this.goalPostOutLeft);
    this.physics.add.collider(this.goalSaver0, this.goalPostOutRight);
    this.physics.add.collider(this.goalSaver1, this.goalPostOutTop);
    this.physics.add.collider(this.goalSaver1, this.goalPostOutBottom);
    this.physics.add.collider(this.goalSaver1, this.goalPostOutLeft);
    this.physics.add.collider(this.goalSaver1, this.goalPostOutRight);
    this.physics.add.collider(this.goalSaver_1, this.goalPostOutTop);
    this.physics.add.collider(this.goalSaver_1, this.goalPostOutBottom);
    this.physics.add.collider(this.goalSaver_1, this.goalPostOutLeft);
    this.physics.add.collider(this.goalSaver_1, this.goalPostOutRight);

    if (!this.game.options.simulation) {
      this.textHint = this.add.text(gameWidth / 2, gameHeight - 560, stringHint, {
        fontFamily: 'Poppins',
        fontSize: 70, color: '#ffffff',
        stroke: '#48464F', strokeThickness: 4,
        align: 'center'
      }).setOrigin(0.5)
        .setDepth(1);
      this.textHintDetail = this.add.text(gameWidth / 2, gameHeight - 70, stringHintDetail, {
        fontFamily: 'Poppins',
        fontSize: 40, color: '#ffffff',
        stroke: '#48464F', strokeThickness: 2,
        align: 'center'
      }).setOrigin(0.5)
        .setAlpha(0.9);
      this.gameOverText = this.add.text(gameWidth / 2, goalPostY, this.stringGameOver, {
        fontFamily: 'Poppins',
        fontSize: 120, color: '#666',
        backgroundColor: '#eee',
        stroke: '#000', strokeThickness: 4,
        align: 'center', padding: 24,
      }).setOrigin(0.5).setDepth(1).setVisible(false);
    }

    this.textGoalY = goalPostY;
    this.textGoal = this.add.text(gameWidth / 2, this.textGoalY, this.stringGoal, {
      fontFamily: 'Poppins',
      fontSize: 120, color: '#FFF',
      stroke: '#000', strokeThickness: 4,
      backgroundColor: '#007c00',
      align: 'center', padding: 20,
    }).setOrigin(0.5).setDepth(1).setVisible(false);

    for (let i = 0; i < 20; i++) {
      this.circles.push(this.add.circle(0, 0, 40, 0x000).setAlpha(0.02).setVisible(false));
    }

    this.physics.add.overlap(this.ball, this.goalPostOutTop, this.onBallCollide);
    this.physics.add.overlap(this.ball, this.goalPostOutLeft, this.onBallCollide);
    this.physics.add.overlap(this.ball, this.goalPostOutRight, this.onBallCollide);
    this.physics.add.overlap(this.ball, this.goalSaver0, this.onBallCollide);
    this.physics.add.overlap(this.ball, this.goalSaver1, this.onBallCollide);
    this.goalSaver_1Collider = this.physics.add.overlap(this.ball, this.goalSaver_1, this.onBallCollide);

    this.goalScorerCollider = this.physics.add.overlap(this.ball, this.goalScorer, this.onBallOverlap);
    this.physics.add.overlap(this.ball, this.goalPostIn, this.onBallOverlap);

    this.addTimerEvent(1000, () => {
      if (this.textHint) {
        this.textHint.setVisible(false);
      }
      this.ball.setAlpha(1);
      this.readyToPlayGame();
    });
  }

  attachPointers = () => {
    this.input.on('pointerdown', this.onSwipeStart);
    this.input.on('pointermove', this.onSwipeMove);
    this.input.on('pointerup', this.onSwipeEnd);
  }

  detachPointers = () => {
    this.input.off('pointerdown', this.onSwipeStart);
    this.input.off('pointermove', this.onSwipeMove);
    this.input.off('pointerup', this.onSwipeEnd);
  }

  update(time, delta) {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    if (this.ballHit) {
      if (this.ball.y > this.goalPost.y + this.goalPost.displayHeight/2 - this.goalPost.displayHeight/6) {
        this.ball.setVelocity(0, 0);
      }
    } else if (this.ballCollided) {
      if (this.ball.y > gameHeight/2) {
        this.ball.setVelocity(0, 0);
        this.ball.setAngularVelocity(0);
      }
      if (this.ball.width * this.ball.scale < gameWidth + 300) {
        const scaleChange = 12 * (delta/1000);
        this.ball.setScale(this.ball.scale + scaleChange);
      } else {
        this.onBallShootDone();
      }
    } else if (this.ballKicked) {
      if (this.ball.y > this.strikeHeight) {
        const actualDistanceToTravel = this.ballYInitial - this.strikeHeight;
        const distanceRemaining = this.ball.y - this.strikeHeight;
        let newScale = 1;
        if (distanceRemaining <= actualDistanceToTravel*.6) {
          let finalScale = 0.6;
          if (this.strikeHeight < this.goalPostIn.y - this.goalPostIn.displayHeight/2) {
            finalScale = 0.5;
          }
          newScale = finalScale + distanceRemaining/actualDistanceToTravel*(1-finalScale);
        }
        this.ball.setScale(newScale);
      }
      // else {
      //   this.ball.setVelocity(0, 0);
      // }

      if (this.ball.y < -2*this.ball.height || this.ball.x < -2*this.ball.height || this.ball.x > gameWidth + 2*this.ball.height) {
        logDebug('kickedOut');
        this.onBallShootDone();
      }
    }

    if (this.showGoalDown && this.textGoal.y < this.textGoalY) {
      this.textGoal.setY(this.textGoal.y + 15);
    }

    if (this.adImage1) {
      if (this.showAdBanner) {
        if (this.adImage1.alpha < 0.8) {
          this.adImage1.setAlpha(this.adImage1.alpha + 0.05);
        }
      } else {
        if (this.adImage1.alpha > 0.2) {
          this.adImage1.setAlpha(this.adImage1.alpha - 0.03);
        }
      }
    }
  }

  onSwipeMove = (pointer) => {
    if (this.readyToShoot && this.swipeStartTime) {
      // logDebug('pointer', pointer);
      const circle = this.circles[this.circleIndex];
      circle.setX(pointer.position.x);
      circle.setY(pointer.position.y);
      circle.setVisible(true);

      this.circleIndex++;
      if (this.circleIndex >= this.circles.length) {
        this.circleIndex = 0;
      }
    }
  }

  onSwipeStart = (pointer) => {
    this.swipeStartTime = Date.now();
    this.circleIndex = 0;
  }

  onSwipeEnd = (pointer) => {
    if (this.gameEnded || this.ballKicked || this.showAdBanner) {
      return;
    }
    const swipeYAbs = Math.abs(pointer.upY - pointer.downY);
    if (swipeYAbs <= 40) {
      return;
    }

    this.detachPointers();
    this.ballKicked = true;
    this.readyToShoot = false;
    this.swipeStartTime = false;

    this.strikeHeight = 0;
    let swipeYFactor = swipeYAbs;
    swipeYFactor -= 80;
    let goalPostTop = this.goalPost.y - this.goalPost.displayHeight/2;
    let goalPostBottom = this.goalPost.y + this.goalPost.displayHeight/2;
    let height = this.ball.displayHeight/4;
    const swipeYMoveInGoalPost = 600;
    if (swipeYFactor >= 80 && swipeYFactor <= swipeYMoveInGoalPost) {
      height = this.goalPost.displayHeight / swipeYMoveInGoalPost * swipeYFactor;
    } else if (swipeYFactor > swipeYMoveInGoalPost) {
      height = this.goalPost.displayHeight + swipeYFactor - 600;
    }

    this.strikeHeight = Math.max(goalPostTop, goalPostBottom - height);
    const strikeX = this.findXCoordinate(this.strikeHeight, {x: pointer.downX, y: pointer.downY}, {x: pointer.upX, y: pointer.upY});

    // if (!this.circlePos) {
    //   this.circlePos = this.add.circle(0, 0, 8, 0x000000).setDepth(10);
    // }
    // this.circlePos.setX(strikeX);
    // this.circlePos.setY(this.strikeHeight);
    // this.circlePos.setVisible(true);

    const velocity = this.calculateVelocity({x: this.ball.x, y: this.ball.y}, {x: strikeX, y: this.strikeHeight});
    this.ball.setVelocity(velocity.x, velocity.y);
    this.ball.setAngularVelocity(velocity.x);
    this.ballShadow.setVisible(false);
    if (this.textHintDetail) {
      this.textHintDetail.setVisible(false);
    }
    if (this.audioKick) {
      this.audioKick.play();
    }

    this.addTimerEvent(200, () => {
        this.circles.forEach((circle) => {
          circle.setVisible(false);
        });
    });
  }

  findXCoordinate(y, point1, point2) {
    const { x: x1, y: y1 } = point1;
    const { x: x2, y: y2 } = point2;

    // Handle vertical line (x1 === x2)
    if (x1 === x2) {
      return x1;
    }
    // Calculate slope (m)
    const m = (y2 - y1) / (x2 - x1);
    // Calculate y-intercept (c)
    const c = y1 - m * x1;
    // Calculate x for the given y
    const x = (y - c) / m;
    return x;
  }

  calculateVelocity(point1, point2, speed = 2000) {
    // Create vectors
    const start = new Phaser.Math.Vector2(point1.x, point1.y);
    const target = new Phaser.Math.Vector2(point2.x, point2.y);
    // Calculate direction vector
    const direction = target.subtract(start);
    // Normalize the direction vector (unit length)
    direction.normalize();
    // Scale by the desired speed
    direction.scale(speed);
    return direction; // This vector contains velocity in x and y
  }

  onBallCollide = (ball, otherObject) => {
    if (this.ballCollided) {
      return;
    }

    if (this.ball.y <= this.strikeHeight && this.ball.y + this.ball.height >= this.strikeHeight) {
      if (otherObject === this.goalPostOutTop || otherObject === this.goalPostOutLeft || otherObject === this.goalPostOutRight) {
        if (this.audioHitPole) {
          this.audioHitPole.play();
        }
      } else {
        if (this.audioHitPlayer) {
          this.audioHitPlayer.play();
        }
      }
      this.ballCollided = true;
      this.ballCollided_1 = otherObject === this.goalSaver_1;
      this.ball.setVelocity(0, 250);
    }
  }

  onBallOverlap = (ball, otherObject) => {
    if (this.ballHit || this.ballCollided) {
      return;
    }

    const strikeHeight = this.strikeHeight;
    if (ball.y <= strikeHeight && ball.y + ball.height >= strikeHeight && this.checkOverlap(ball, otherObject)) {
      this.ball.setDepth(0);
      this.ballHit = true;
      this.ballHit2x = otherObject === this.goalScorer;
      if (this.audioGoal) {
        this.audioGoal.play();
      }
      this.ball.setVelocity(0, 800);
      this.ball.setAngularVelocity(0);
      if (this.ballHit2x) {
        this.goalScorer.setVelocity(0, 0);
        this.goalScorer.setVisible(false);
      }
      this.shakeObject(this.goalNet, 500, 10);
      this.addTimerEvent(500, () => {
        this.onBallShootDone(true);
      });
    }
  }

  readyToPlayGame = () => {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    let randomSaverValues = this.randomSaverValues();
    let goalSaverMove = false;
    let goalSaver_1Move = false;
    let goalScorerMove = false;
    this.readyToShoot = false;
    if (!this.gameEnded) {
      goalSaverMove = true;
      this.attachPointers();
      this.readyToShoot = true;
      // const diffRise = Math.ceil(this.scoreToWin / 3);
      if (this.goalCounter > 0) {
        goalSaver_1Move = true;
        goalScorerMove = true;
      }
      if (this.audioWhistle) {
        this.audioWhistle.play();
      }
    }

    if (goalSaverMove) {
      this.goalSaver0.setVisible(true);
      this.goalSaver0.setVelocity(randomSaverValues.saver0.velocityX, randomSaverValues.saver0.velocityY);
      this.goalSaver0.setX(randomSaverValues.saver0.x);
      this.goalSaver0.setY(randomSaverValues.saver0.y);

      this.goalSaver1.setVisible(true);
      this.goalSaver1.setVelocity(randomSaverValues.saver1.velocityX, randomSaverValues.saver1.velocityY);
      this.goalSaver1.setX(randomSaverValues.saver1.x);
      this.goalSaver1.setY(randomSaverValues.saver1.y);
    } else {
      this.goalSaver0.setVisible(true);
      this.goalSaver0.setVelocity(0, 0);
      this.goalSaver0.setX(gameWidth * .6);
      // this.goalSaver0.setY(this.goalPostIn.y); // leave at bottom

      this.goalSaver1.setVisible(true);
      this.goalSaver1.setVelocity(0, 0);
      this.goalSaver1.setX(gameWidth/2);
      this.goalSaver1.setY(this.goalPostIn.y);
    }
    if (goalSaver_1Move) {
      this.goalSaver_1.setVisible(true);
      this.goalSaver_1Collider.active = true;
      this.goalSaver_1.setVelocity(randomSaverValues.saver_1.velocityX, randomSaverValues.saver_1.velocityY);
      this.goalSaver_1.setX(randomSaverValues.saver_1.x);
      this.goalSaver_1.setY(randomSaverValues.saver_1.y);
    } else {
      this.goalSaver_1.setVisible(false);
      this.goalSaver_1Collider.active = false;
      this.goalSaver_1.setVelocity(0, 0);
    }
    if (goalScorerMove) {
      this.goalScorer.setVisible(true);
      this.goalScorerCollider.active = true;
      this.goalScorer.setVelocity(randomSaverValues.scorer.velocityX, randomSaverValues.scorer.velocityY);
      this.goalScorer.setX(randomSaverValues.scorer.x);
      this.goalScorer.setY(randomSaverValues.scorer.y);
    } else {
      this.goalScorer.setVisible(false);
      this.goalScorerCollider.active = false;
      this.goalScorer.setVelocity(0, 0);
    }

    this.textGoal.setVisible(false);
    this.ball.setVisible(true);
    this.ballShadow.setVisible(true);
    if (this.textHintDetail) {
      this.textHintDetail.setVisible(true);
    }
    this.adImage1.setDepth(0);

    if (this.inAutoSimulation) {
      this.autoPlay();
    }
  }

  checkOverlap = (object1, object2) => {
    return Phaser.Geom.Intersects.RectangleToRectangle(object1.getBounds(), object2.getBounds());
  }

  saveGoal = (ballVelocityX, ballVelocityY, height) => {
    let delay = 300;
    if (ballVelocityY > 1000) {
      delay = 250;
    }

    const correctSideProbability = (this.goalCounter + 1) * 25;
    const random = Phaser.Math.Between(0, 100);
    let shouldSave = false;
    if (random <= correctSideProbability) {
      shouldSave = true;
    }

    let move = 0;
    if (shouldSave) {
      if (ballVelocityX >= 100) {
        move = 2;
      } else if (ballVelocityX >= 20) {
        move = 1;
      } else if (ballVelocityX <= -100) {
        move = -2;
      } else if (ballVelocityX <= -20) {
        move = -1;
      }
      // else if (height <= this.goalSaver1.y - this.goalSaver1.displayHeight/2) {
      //   move = 10;
      //   delay += 200;
      // }
    } else {
      move = Phaser.Math.Between(-2, 2);
    }
    this.goalKeeperMove = move;

    logDebug('saveGoalShouldSave', shouldSave);

    this.moveGoalKeeper(delay, this.goalKeeperMove);
  }

  moveGoalKeeper = (delay, move) => {
    logDebug('saveGoalMove', move);
    logDebug('saveGoalDelay', delay);
    this.time.addEvent({
      delay: delay,
      callback: () => {
        if (move === 3) {
          // this.goalSaver1.setAngularVelocity(100);
        } else if (move === 2) {
          this.goalSaver1.setVelocityX(800);
          this.addTimerEvent(this.getDelayFor(800, 165), () => {
            this.goalSaver1.setVelocityX(0);
          });
        } else if (move === 1) {
          this.goalSaver1.setVelocityX(500);
          this.addTimerEvent(this.getDelayFor(500, 60), () => {
            this.goalSaver1.setVelocityX(0);
          });
        } else if (move === -3) {
          // this.goalSaver1.setAngularVelocity(-100);
        } else if (move === -2) {
          this.goalSaver1.setVelocityX(-800);
          this.addTimerEvent(this.getDelayFor(-800, -165), () => {
            this.goalSaver1.setVelocityX(0);
          });
        } else if (move === -1) {
          this.goalSaver1.setVelocityX(-500);
          this.addTimerEvent(this.getDelayFor(-500, -60), () => {
            this.goalSaver1.setVelocityX(0);
          });
        } else if (move === 10) {
          logDebug('goalSaver1Jumped');
          this.goalSaver1.setVelocityY(-500);
          this.addTimerEvent(this.getDelayFor(500, 60), () => {
            this.goalSaver1.setVelocityY(500);
            this.addTimerEvent(this.getDelayFor(-500, 60), () => {
              this.goalSaver1.setVelocityY(0);
            });
          });
        }
      },
      callbackScope: this
    })
  }

  getDelayFor(velocity, delta) {
    return 1000 / Math.abs(velocity) * Math.abs(delta);
  }

  addTimerEvent = (delay, callback) => {
    this.time.addEvent({
      delay: delay,
      callback: callback
    })
  }

  shakeObject = (object, duration, magnitude, axis = 0) => {
    let originalX = object.x;
    let originalY = object.y;

    this.time.addEvent({
      delay: 10,
      repeat: duration / 10, // Adjust based on desired shake frequency
      callback: () => {
        let randomOffsetX = Phaser.Math.RND.between(-magnitude, magnitude);
        let randomOffsetY = (axis === 0 || axis === 2) ? Phaser.Math.RND.between(-magnitude, magnitude) : 0;
        object.setPosition(originalX + randomOffsetX, originalY + randomOffsetY);
      },
      callbackScope: this // Optional, set context for 'this'
    });

    this.time.addEvent({
      delay: duration + 20,
      callback: () => {
        object.setPosition(originalX, originalY); // Reset position after shake
      }
    });
  }

  onBallShootDone = (isGoal) => {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    if (this.circlePos) {
      this.circlePos.setVisible(false);
    }
    this.kickCounter++;
    if (this.kickCounter === 1) {
      this.game.events.emit('eventOnStart');
    }
    this.textTryCount.setText(this.kickCounts - this.kickCounter);
    if (isGoal) {
      this.goalCounter += (this.ballHit2x ? 2 : 1);
      this.textScore.setText(this.goalCounter);
      this.showGoalDown = true;
      this.textGoal.setText(this.ballHit2x ? this.stringHurray : this.stringGoal);
      this.textGoal.setVisible(true);
      this.textGoal.setY(-60);
    }
    if (this.ballCollided_1) {
      this.goalCounter = Math.max(0, this.goalCounter - 1);
      this.textScore.setText(this.goalCounter);
    }

    this.ballKicked = false;
    this.ballCollided = false;
    this.ballCollided_1 = false;
    this.ballHit = false;
    this.ballHit2x = false;
    this.ball.setVisible(false);
    this.ball.setAngle(0);
    this.ball.setVelocity(0, 0);
    this.ball.setAngularVelocity(0);
    this.ball.setScale(this.ballScaleInitial);
    this.ball.setX(gameWidth / 2);
    this.ball.setY(this.ballYInitial);
    this.ball.setDepth(10);
    this.goalSaver0.setVelocity(0, 0);
    this.goalSaver1.setVelocity(0, 0);
    this.goalSaver_1.setVelocity(0, 0);
    this.goalScorer.setVelocity(0, 0);

    this.addTimerEvent(500, () => {
      this.showGoalDown = false;
      this.adImage1.setDepth(2);
      this.showAdBanner = true;

      this.addTimerEvent(2000, () => {
        if (this.kickCounter >= this.kickCounts /*|| this.goalCounter >= this.scoreToWin*/) {
          this.endGame(this.goalCounter >= this.scoreToWin);
        }
      });

      this.addTimerEvent(3000, () => {
        this.adImage1.setDepth(0);
        this.showAdBanner = false;
        this.addTimerEvent(500, () => {
          this.readyToPlayGame();
        });
      });
    });
  }

  randomSaverValues = () => {
    const gameWidth = this.game.config.width;

    let minVelocity = 300 * (1 + .2 * this.goalCounter);
    let maxVelocity = 400 * (1 + .2 * this.goalCounter);

    // let velocitySaver0X = Phaser.Math.Between(minVelocity, maxVelocity);
    // velocitySaver0X = Phaser.Math.Between(0, 1) ? -velocitySaver0X : velocitySaver0X;
    //
    // let velocitySaver1X = Phaser.Math.Between(minVelocity, maxVelocity);
    // velocitySaver1X = Phaser.Math.Between(0, 1) ? -velocitySaver1X : velocitySaver1X;
    // velocitySaver1X = velocitySaver1X * (velocitySaver0X > 0 ? -1 : 1);
    // const velocitySaver1Pos = Phaser.Math.Between(0, 1);

    let velocitySaver0Y = Phaser.Math.Between(minVelocity, maxVelocity);
    velocitySaver0Y = Phaser.Math.Between(0, 1) ? -velocitySaver0Y : velocitySaver0Y;
    let velocitySaver0X = velocitySaver0Y * Phaser.Math.Between(3, 7)/10;
    velocitySaver0X = Phaser.Math.Between(0, 1) ? -velocitySaver0X : velocitySaver0X;

    let velocitySaver1Y = Phaser.Math.Between(minVelocity, maxVelocity);
    velocitySaver1Y = Phaser.Math.Between(0, 1) ? -velocitySaver1Y : velocitySaver1Y;
    let velocitySaver1X = velocitySaver0Y * Phaser.Math.Between(3, 7)/10;
    velocitySaver1X = Phaser.Math.Between(0, 1) ? -velocitySaver1X : velocitySaver1X;

    let minVelocity_1 = 400 * (1 + .2 * this.goalCounter);
    let maxVelocity_1 = 600 * (1 + .2 * this.goalCounter);
    let velocitySaver_1Y = Phaser.Math.Between(minVelocity_1, maxVelocity_1);
    velocitySaver_1Y = Phaser.Math.Between(0, 1) ? -velocitySaver_1Y : velocitySaver_1Y;
    let velocitySaver_1X = velocitySaver_1Y * Phaser.Math.Between(3, 7)/10;
    velocitySaver_1X = Phaser.Math.Between(0, 1) ? -velocitySaver_1X : velocitySaver_1X;

    let velocityScorerY = Phaser.Math.Between(minVelocity, maxVelocity);
    velocityScorerY = Phaser.Math.Between(0, 1) ? -velocityScorerY : velocityScorerY;
    let velocityScorerX = velocityScorerY * Phaser.Math.Between(3, 7)/10;
    velocityScorerX = Phaser.Math.Between(0, 1) ? -velocityScorerX : velocityScorerX;

    return {
      saver0: {
        velocityX: velocitySaver0X,
        velocityY: velocitySaver0Y,
        x: gameWidth*.2,
        y: this.goalPostIn.y + this.saverSize + this.saverSpace
      },
      saver1: {
        velocityX: velocitySaver1X,
        velocityY: velocitySaver1Y,
        x: gameWidth*.8,
        y: this.goalPostIn.y - this.saverSize - this.saverSpace
      },
      saver_1: {
        velocityX: velocitySaver_1X,
        velocityY: velocitySaver_1Y,
        x: gameWidth*.6,
        y: this.goalPostIn.y
      },
      scorer: {
        velocityX: velocityScorerX,
        velocityY: velocityScorerY,
        x: gameWidth*.4,
        y: this.goalPostIn.y
      }
    }
  }

  endGame = (isWin) =>  {
    this.gameEnded = true;

    this.ball.setAlpha(0.5);
    if (this.gameOverText) {
      this.gameOverText.setVisible(true);
    }
    if (isWin) {
      if (this.audioGameWin) {
        this.audioGameWin.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringWellDone);
      }
    } else {
      if (this.audioGameEnd) {
        this.audioGameEnd.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringGameOver);
      }
    }
    this.addTimerEvent(2000, () => {
      this.game.events.emit('eventOnEnd', this.goalCounter);
    });
  }
}
