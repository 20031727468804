import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import auth from '../utils/auth';

import Logout from './Logout';
import NotFound from './NotFound';
import PrivateNavRoute from './routes/PrivateNavRoute';
import PrivateAccountRoute from "./routes/PrivateAccountRoute";
import PrivatePlayRoute from "./routes/PrivatePlayRoute";
import {PublicDrawerRoute} from "src/js/components/routes/PublicDrawerRoute";
import {
  PathAboutUsPublic,
  PathAccount,
  PathBusinessesWallets,
  PathCampaigns,
  PathGameGuidelines,
  PathGames,
  PathGamesManage,
  PathHome,
  PathHowItWorks,
  PathLogin,
  PathLogout,
  PathNotificationSettings,
  PathPayment,
  PathPaymentEnd,
  PathPaymentRedirect,
  PathPaymentStart,
  PathPlays,
  PathResetPassword, PathLanding,
  PathScreenCampaignRequests,
  PathScreens,
  PathScreensInsight,
  PathScreensManage,
  PathShow, PathPartners, PathScreenManageCampaigns
} from "src/js/components/routes/NavHelper";
import PrivateTopRoute from "src/js/components/routes/PrivateTopRoute";
import {APP_SHOWCASE} from "src/js/utils/services";
import CircularProgress from "@material-ui/core/CircularProgress";
import {isUserPrivileged} from "src/js/utils/AppUtils";

const Landing = React.lazy(() => import( 'src/js/components/landing/Landing'));
const Login = React.lazy(() => import( './Login'));
const Register = React.lazy(() => import('./Register'));
const RecoverPassword = React.lazy(() => import('./RecoverPassword'));
const RedeemOffer = React.lazy(() => import('./redeemOffer/RedeemOffer'));
const HelpRedeemOffer = React.lazy(() => import('./activity/HelpRedeemOffer'));
const VerifyAccount = React.lazy(() => import('./admin/profile/VerifyAccount'));
const PaymentRedirect = React.lazy(() => import('src/js/payment/PaymentRedirect'));
const PaymentEnd = React.lazy(() => import('src/js/payment/PaymentEnd'));
const PaymentStart = React.lazy(() => import('src/js/payment/PaymentStart'));

const AdminLanding = React.lazy(() => import('./admin/AdminLanding'));
const Profile = React.lazy(() => import('./admin/profile/Profile'));
const ProfileDetails = React.lazy(() => import('src/js/components/user/ProfileDetails'));
const Account = React.lazy(() => import('./admin/profile/Account'));
const UserReels = React.lazy(() => import('src/js/components/user/UserReels'));
const ChangePassword = React.lazy(() => import('./admin/profile/ChangePassword'));
const ActivityLists = React.lazy(() => import('./admin/management/activities/ActivityLists'));
const FunLists = React.lazy(() => import('./activity/FunLists'));
const ActivityDetail = React.lazy(() => import('./activity/ActivityDetail'));
const ManageActivity = React.lazy(() => import('./activity/ManageActivity'));
const ActivityInsight = React.lazy(() => import('src/js/components/insight/ActivityInsight'));
const ActivityPayment = React.lazy(() => import('src/js/components/activity/ActivityPayment'));
const ActivityReviews = React.lazy(() => import('src/js/components/activity/ActivityReviews'));
const Settings = React.lazy(() => import('src/js/components/setting/Settings'));
const SettingOthers = React.lazy(() => import('src/js/components/setting/Others'));
const AboutUs = React.lazy(() => import('src/js/components/setting/AboutUs'));
const AboutPublic = React.lazy(() => import('src/js/components/setting/AboutPublic'));
const HowItWorks = React.lazy(() => import('src/js/components/setting/HowItWorks'));
const ActivityTransactions = React.lazy(() => import('src/js/components/activity/ActivityTransactions'));
const BusinessesWallets = React.lazy(() => import('src/js/components/wallet/BusinessesWallets'));
const ManagePartners = React.lazy(() => import('src/js/components/business/ManagePartners'));
const GamesList = React.lazy(() => import('src/js/components/games/GamesList'));
const ManageGame = React.lazy(() => import('src/js/components/games/ManageGame'));
const GameGuildelines = React.lazy(() => import('src/js/components/games/GameGuildelines'));
const Showcase = React.lazy(() => import('./showcase/Showcase'));
const Screens = React.lazy(() => import('./showcase/Screens'));
// const ScreensWrapper = React.lazy(() => import('./showcase/ScreensWrapper'));
const ManageScreen = React.lazy(() => import('src/js/components/showcase/ManageScreen'));
const ScreenCampaignRequests = React.lazy(() => import('src/js/components/showcase/ScreenCampaignRequests'));
const ScreenManageCampaigns = React.lazy(() => import('src/js/components/showcase/ScreenManageCampaigns'));
const ShowcaseFuns = React.lazy(() => import('src/js/components/showcase/ShowcaseFuns'));
const NotificationSettings = React.lazy(() => import('src/js/components/user/NotificationSettings'));

const SuspenseLoading = () => {
  return (
    <div style={{
      display: 'flex',
      flex: 1,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: Number.MAX_SAFE_INTEGER,
      alignItems: 'center',
      justifyContent: 'center'
    }}><CircularProgress />
    </div>
  );
}

class Routes extends React.Component {

  render() {
    const isAuthed = auth.isAuthenticated();
    const isUserGuest = this.props.isUserGuest;
    const isUserPrivileged = this.props.isUserPrivileged;

    if (APP_SHOWCASE) {
      return (
        <Suspense fallback={<SuspenseLoading/>}>
          <Switch>

            <Route exact path={PathLogout} component={Logout} />
            <Route exact path={'/'} component={Showcase} />
            <Route exact path='/play/:id' component={ActivityDetail} />

            <Route component={NotFound} />
          </Switch>
        </Suspense>
      );
    }

    return (
      <Suspense fallback={<SuspenseLoading/>}>
        <Switch>

          <Route exact path={PathLogout} component={Logout} />
          <Route exact path={PathLanding} component={Landing} />

          <PublicDrawerRoute exact path={PathCampaigns} authed={isAuthed} component={ActivityLists} />
          <PublicDrawerRoute exact path={PathShow +'/:id'} authed={isAuthed} component={ShowcaseFuns} />
          <PrivatePlayRoute exact path='/play/:id' component={ActivityDetail} />

          <PublicDrawerRoute exact path={PathAboutUsPublic} authed={isAuthed} component={AboutPublic} />
          <PublicDrawerRoute exact path={PathHowItWorks} authed={isAuthed} component={HowItWorks} />
          {/*<Route exact path='/redeemOffer' component={RedeemOffer} />*/}
          {/*<PublicDrawerRoute exact path='/helpRedeemOffer' component={HelpRedeemOffer} />*/}
          {/*<Route exact path='/verifyAccount/:id' component={VerifyAccount} />*/}

          {!isAuthed || isUserGuest ? <Route exact path={PathLogin} component={Login} /> : null}
          {/*{!isAuthed || isUserGuest ? <Route exact path={PathRegister} component={Register} /> : null}*/}
          {!isAuthed || isUserGuest ? <Route exact path={PathResetPassword} component={RecoverPassword} /> : null}
          {isAuthed ? <PrivateAccountRoute exact path={PathAccount} authed={isAuthed} component={Account} /> : null}
          {isAuthed ? <Route exact path={PathPaymentRedirect} component={PaymentRedirect} /> : null}
          {isAuthed ? <Route exact path={PathPaymentEnd} component={PaymentEnd} /> : null}
          {isAuthed ? <Route exact path={PathPaymentStart} component={PaymentStart} /> : null}

          {/*<PublicDrawerRoute exact path={PathPlays} authed={isAuthed} component={FunLists} />*/}
          {isAuthed ? <PrivateNavRoute exact path={PathPlays} authed={isAuthed} component={FunLists} /> : null}
          {isAuthed ? <PrivateNavRoute exact path='/wons' authed={isAuthed} component={FunLists} /> : null}
          {isAuthed ? <PrivatePlayRoute exact path='/won/:id' component={ActivityDetail} /> : null}

          {isAuthed && !isUserGuest ? <PrivateNavRoute exact path='/profile/manage' authed={isAuthed} component={Profile} /> : null}
          {isAuthed && !isUserGuest ? <PrivateNavRoute exact path='/profile/details' authed={isAuthed} component={ProfileDetails} /> : null}
          {isAuthed && !isUserGuest ? <PrivateNavRoute exact path='/profile/changePassword' authed={isAuthed} component={ChangePassword} /> : null}
          {isAuthed && !isUserGuest ? <PrivateNavRoute exact path='/profile/reels' authed={isAuthed} component={UserReels} /> : null}
          {isAuthed && !isUserGuest ? <PrivateNavRoute exact path='/profile/transactions' authed={isAuthed} component={ActivityTransactions} /> : null}
          {isAuthed && !isUserGuest ? <PrivateNavRoute exact path={PathNotificationSettings} authed={isAuthed} component={NotificationSettings} /> : null}
          {isUserPrivileged && isAuthed && !isUserGuest ? <PrivateNavRoute exact path={PathBusinessesWallets} authed={isAuthed} component={BusinessesWallets} /> : null}
          {isUserPrivileged && isAuthed && !isUserGuest ? <PrivateNavRoute exact path={PathPartners} authed={isAuthed} component={ManagePartners} /> : null}
          {isAuthed ? <PrivateNavRoute exact path='/rewards' authed={isAuthed} component={ActivityLists} /> : null}
          {isAuthed ? <PrivateNavRoute exact path='/settings' authed={isAuthed} component={Settings} /> : null}
          {isAuthed ? <PrivateNavRoute exact path='/settings/others' authed={isAuthed} component={SettingOthers} /> : null}
          {isAuthed ? <PrivateNavRoute exact path='/settings/aboutUs' authed={isAuthed} component={AboutUs} /> : null}

          <PrivateNavRoute exact path={PathScreens} authed={isAuthed} component={Screens} />
          <PrivateNavRoute exact path={PathScreensManage} authed={isAuthed} component={ManageScreen} />
          <PrivateNavRoute exact path={PathScreensManage +'/:id'} authed={isAuthed} component={ManageScreen} />
          <PrivateNavRoute exact path={PathScreensInsight +'/:id'} authed={isAuthed} component={ActivityInsight} />
          <PrivateNavRoute exact path={PathScreenCampaignRequests} authed={isAuthed} component={ScreenCampaignRequests} />
          <PrivateNavRoute exact path={PathScreenManageCampaigns +'/:id'} authed={isAuthed} component={ScreenManageCampaigns} />

          {isUserPrivileged ? <PrivateNavRoute exact path={PathGameGuidelines} authed={isAuthed} component={GameGuildelines} /> : null}
          {isUserPrivileged ? <PrivateNavRoute exact path={PathGames} authed={isAuthed} component={GamesList} /> : null}
          {isUserPrivileged ? <PrivateNavRoute exact path={PathGamesManage} authed={isAuthed} component={ManageGame} /> : null}
          {isUserPrivileged ? <PrivateNavRoute exact path={PathGamesManage +'/:id'} authed={isAuthed} component={ManageGame} /> : null}

          {/*<PrivateNavRoute exact path='/rewards' authed={isAuthed} component={ActivityLists} />*/}
          {/*<PrivateNavRoute exact path='/rewards/manage' authed={isAuthed} component={ManageActivity} />*/}
          {/*<PrivateNavRoute exact path='/rewards/manage/:id' authed={isAuthed} component={ManageActivity} />*/}

          <PrivateNavRoute exact path='/campaigns/manage' authed={isAuthed} component={ManageActivity} />
          <PrivateNavRoute exact path='/campaigns/manage/:id' authed={isAuthed} component={ManageActivity} />
          <PrivateNavRoute exact path='/campaigns/insight/:id' authed={isAuthed} component={ActivityInsight} />
          <PrivateNavRoute exact path='/campaigns/reviews/:id' authed={isAuthed} component={ActivityReviews} />
          {isAuthed ? <PrivateTopRoute exact path={PathPayment} authed={isAuthed} component={ActivityPayment} /> : null}

          {/*<PrivateNavRoute exact path='/marketingCampaigns' authed={isAuthed} component={ActivityLists} />*/}
          {/*<PrivateNavRoute exact path='/marketingCampaigns/manage' authed={isAuthed} component={ManageActivity} />*/}
          {/*<PrivateNavRoute exact path='/marketingCampaigns/manage/:id' authed={isAuthed} component={ManageActivity} />*/}

          {/*<PrivateNavRoute exact path='/loyaltyCampaigns' authed={isAuthed} component={ActivityLists} />*/}
          {/*<PrivateNavRoute exact path='/loyaltyCampaigns/manage' authed={isAuthed} component={ManageActivity} />*/}
          {/*<PrivateNavRoute exact path='/loyaltyCampaigns/manage/:id' authed={isAuthed} component={ManageActivity} />*/}

          {/*<PrivateNavRoute exact path='/marketingCampaigns' authed={isAuthed} component={ActivityLists} />*/}
          {/*<PrivateNavRoute exact path='/marketingCampaigns/manage' authed={isAuthed} component={ManageActivity} />*/}
          {/*<PrivateNavRoute exact path='/marketingCampaigns/manage/:id' authed={isAuthed} component={ManageActivity} />*/}

          {/*<PrivateNavRoute exact path='/educationCampaigns' authed={isAuthed} component={ActivityLists} />*/}
          {/*<PrivateNavRoute exact path='/educationCampaigns/manage' authed={isAuthed} component={ManageActivity} />*/}
          {/*<PrivateNavRoute exact path='/educationCampaigns/manage/:id' authed={isAuthed} component={ManageActivity} />*/}

          {/*<PrivateNavRoute exact path='/trainingCampaigns' authed={isAuthed} component={ActivityLists} />*/}
          {/*<PrivateNavRoute exact path='/trainingCampaigns/manage' authed={isAuthed} component={ManageActivity} />*/}
          {/*<PrivateNavRoute exact path='/trainingCampaigns/manage/:id' authed={isAuthed} component={ManageActivity} />*/}

          {/*<PrivateNavRoute exact path='/socialCampaigns' authed={isAuthed} component={ActivityLists} />*/}
          {/*<PrivateNavRoute exact path='/socialCampaigns/manage' authed={isAuthed} component={ManageActivity} />*/}
          {/*<PrivateNavRoute exact path='/socialCampaigns/manage/:id' authed={isAuthed} component={ManageActivity} />*/}

          {/*<PrivateNavRoute exact path='/influencerCampaigns' authed={isAuthed} component={ActivityLists} />*/}
          {/*<PrivateNavRoute exact path='/influencerCampaigns/manage' authed={isAuthed} component={ManageActivity} />*/}
          {/*<PrivateNavRoute exact path='/influencerCampaigns/manage/:id' authed={isAuthed} component={ManageActivity} />*/}

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}

Routes.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  const user = state.admin.user;
  return {
    isUserGuest: user ? !user.registerType : false,
    isUserPrivileged: isUserPrivileged(user),
  };
}

const mapDispatchToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
