import React, {createRef} from 'react';
import {HeightElementMinimum} from 'src/res/Spacing';
import AppTextWrapper from 'src/js/components/commons/AppTextWrapper';
import {ColorTheme} from 'src/styles/Colors';
import {FontWeightMedium} from 'src/res/FontWeight';
import ImageBackground from "./ImageBackground";
import TouchableOpacity from "./TouchableOpacity";

export default class AppImageButtonEx extends React.PureComponent {

  constructor() {
    super();
    this.refTouch = createRef();
  }

  componentWillUnmount() {
    this.refTouch = null;
  }

  click = () => {
    if (this.refTouch.current) {
      this.refTouch.current.click();
    }
  }

  dispatchEvent = (event) => {
    if (this.refTouch.current) {
      this.refTouch.current.dispatchEvent(event);
    }
  };

  getBoundingClientRect = () => {
    if (this.refTouch.current) {
      return this.refTouch.current.getBoundingClientRect();
    }
    return {};
  };

  render() {
    const imageSource = this.props.imageSource;
    const imageSourceDisabled = this.props.imageSourceDisabled;
    const disabled = this.props.disabled;
    const title = this.props.title;
    const resizeMode = this.props.resizeMode || 'fill';

    let opacity = 1;
    let imageSourceE = imageSource;
    if (disabled) {
      if (imageSourceDisabled) {
        imageSourceE = imageSourceDisabled;
      }
      // opacity = 0.3;
    }

    const view = (
      <ImageBackground
        source={imageSourceE}
        resizeMode={resizeMode}
        style={{display: 'flex', height: HeightElementMinimum, width: HeightElementMinimum, alignItems: 'center', justifyContent: 'center', opacity: opacity, ...this.props.imageStyle}}>
        {title ?
          <div style={{display: 'flex', flexDirection: 'column', position: 'relative', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
            <AppTextWrapper
              style={{color: ColorTheme, fontWeight: FontWeightMedium, ...this.props.titleStyle}}>
              {title}
            </AppTextWrapper>
          </div>
          : null
        }
      </ImageBackground>
    );
    return (
      <div>
        <TouchableOpacity ref={this.refTouch} disabled={disabled} onClick={this.props.onClick}>
          {view}
        </TouchableOpacity>
      </div>
    );
  }
}
