import React, {useState} from "react";
import TextView from "./TextView";
import {FontWeightBold} from "../../../res/FontWeight";
import {locString} from "./ViewHelper";

const ReadMoreText = (props) => {
  const {showCount, text, aStyle} = props;

  const [expanded, setExpanded] = useState(false);

  const showMoreLess = text.length > showCount;
  const minLines = props.minLines || 2;

  const configs = {};
  if (!expanded && showMoreLess) {
    configs.numberOfLines = minLines;
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <TextView {...props} style={{...props.style}} {...configs} />
      {showMoreLess ?
        <a style={aStyle} onClick={() => setExpanded(!expanded)}>
          <TextView {...props} text={null} style={{...props.style}} fontWeight={FontWeightBold}>
            {locString(expanded ? 'generic.showLess' : 'generic.showMore')}
          </TextView>
        </a>
        : null
      }
    </div>
  );
};

export default ReadMoreText;
