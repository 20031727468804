import {Scene} from 'phaser';
import {addTimerEvent, checkOverlap, getCenterPoint, getOverlap, getReadableColor} from "src/game/GameHelper";
import {logDebug} from "src/js/utils/AppLog";
import utils from "src/js/utils/utils";
import {colorWithAlpha} from "src/js/utils/AppUtils";

const ROAD_WIDTH = 168;
const CURVED_ROAD_WIDTH = 225;
const ROAD_SEGMENT_HEIGHT = 225;
const MAX_SIDE_ROAD_LENGTH = 3 * ROAD_SEGMENT_HEIGHT;
const MAX_SIDE_VIEW_WIDTH = MAX_SIDE_ROAD_LENGTH + 2 * ROAD_SEGMENT_HEIGHT;
const HORIZONTAL_CAR_VELOCITY = 300;
const HORIZONTAL_CAR_VELOCITY_INCREASE = HORIZONTAL_CAR_VELOCITY/10;

export class GTGGame extends Scene {

  constructor() {
    super('GTGGame');
  }

  onEventRestart = () => {
    this.cleanup();
    if (!this.game.options.simulation) {
      this.scene.stop('GTGGame');
      this.scene.start('GTGGame');
    }
  };

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
    if (this.audioPlay) {
      this.audioPlay.setMute(mute);
    }
    if (this.audioBonus) {
      this.audioBonus.setMute(mute);
    }
    if (this.audioCrash) {
      this.audioCrash.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
    if (this.audioGameWin) {
      this.audioGameWin.setMute(mute);
    }
  };

  onEventSimulate = () => {
    this.inAutoSimulation = true;
    this.autoSimulationWin = (Math.random() * 100) > 60;
    addTimerEvent(this, 1000, () => {
      this.autoPlay();
    });
  }

  autoPlay = () => {
    if (!this.gameEnded) {
      this.onPointerDown();
    }
  }

  cleanup = () => {
    this.game.events.off('mute', this.onEventMute);
    this.game.events.off('restart', this.onEventRestart);
    this.game.events.off('simulate', this.onEventSimulate);
    if (this.audioGameBg) {
      this.audioGameBg.stop();
    }
    this.input.off('pointerdown');
    this.input.off('pointerup');
    this.time.removeAllEvents();
    this.events.off('destroy');
  }

  create() {
    this.events.on('destroy', this.cleanup);
    this.game.events.on('mute', this.onEventMute);
    this.game.events.on('restart', this.onEventRestart);
    this.game.events.on('simulate', this.onEventSimulate);

    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';
    const stringHint = this.game.options.locale && this.game.options.locale == 'ar' ? 'عقد للتحرك' : 'Hold to Move';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.gameEnded = false;
    this.score = 0;
    this.scoreToWin = this.game.options.scoreToWin || 50;
    this.carCollided = false;
    this.bonusScore = 0;
    this.speed = 0;
    this.moveToSpeed = 0;
    this.maxSpeed = 750;
    this.segmentCounter = 0;
    this.bonusName_OverlappedMap = {};
    this.horizontalSegments = {};
    this.curveSegments = {};
    this.curvedRoad = 0;
    this.raceEndDisplayed = false;
    this.autoPlayPauseCounter = 0;
    this.inAutoSimulation = false;
    this.autoSimulationWin = false;

    if (!this.game.options.simulation) {
      this.audioGameBg = this.sound.add('audio_bg', {loop: true})
        .setMute(this.game.options.mute);
      this.audioGameBg.play();
      this.audioPlay = this.sound.add('audio_play', {loop: true}).setMute(this.game.options.mute)
        .setVolume(0.4);
      this.audioBonus = this.sound.add('audio_bonus').setMute(this.game.options.mute)
        .setVolume(0.5);
      this.audioCrash = this.sound.add('audio_crash').setMute(this.game.options.mute)
        .setVolume(0.6);
      this.audioGameEnd = this.sound.add('audio_game_end').setMute(this.game.options.mute)
        .setVolume(0.2);
      this.audioGameWin = this.sound.add('audio_game_win').setMute(this.game.options.mute)
        .setVolume(0.5);
    }

    this.textScore = this.add.text(gameWidth / 2, 68, this.score, {
      // fontFamily: 'Poppins',
      fontSize: 45, color: '#000',
      stroke: '#666', strokeThickness: 2,
      align: 'center',
      backgroundColor: '#ffffffEE',
      padding: {
        left: 20,
        right: 20,
        top: 4,
        bottom: 2
      },
      cornerRadius: 4,
    }).setOrigin(0.5)
      .setDepth(2)
      .setShadow(0, 0, 0xffffff, 4);

    // Set camera to move infinitely on the Y-axis
    this.cameras.main.scrollY = 0;

    this.roadGraphics = this.add.graphics();
    for (let i = 0; i < Math.ceil(gameHeight/ROAD_SEGMENT_HEIGHT); i++) {
      this.drawRoadSegment(gameHeight - (i+1) * ROAD_SEGMENT_HEIGHT);
    }
    // Create a mask to hide repeating sections outside the viewport
    // this.roadGraphicsRect = this.add.rectangle(gameWidth/2, gameHeight/2, gameWidth, gameHeight);
    // this.roadGraphicsRect.setFillStyle(0xff0000);
    // this.roadGraphics.setMask(this.roadGraphicsRect.createGeometryMask());

    this.carWidth = ROAD_WIDTH - 18;
    this.carHeight = this.carWidth*1.8;
    this.car = this.physics.add.image(gameWidth/2, gameHeight-this.carHeight, 'car')
      .setDisplaySize(this.carWidth, this.carHeight)
      .setBounce(1, 1)
      .setDepth(1);
    this.car.setBodySize(this.car.body.width *.8, this.car.body.height *.95);

    const bonusHeight = this.carWidth*.5 * 1.4;
    const bonus = this.physics.add.image(gameWidth/2, gameHeight - 3*ROAD_SEGMENT_HEIGHT - bonusHeight/2, 'bonus')
      .setDisplaySize(this.carWidth*.5, bonusHeight);
    bonus.name = 'bonus';
    this.physics.add.overlap(this.car, bonus, this.onBonusHit);

    if (!this.game.options.simulation) {
      this.textHint = this.add.text(gameWidth / 2, gameHeight - 68, stringHint, {
        fontFamily: 'Poppins',
        fontSize: 45, color: '#ffffff',
        stroke: '#48464F', strokeThickness: 2,
        align: 'center'
      }).setOrigin(0.5)
        .setAlpha(0.9);

      this.gameOverText = this.add.text(gameWidth / 2, 180, this.stringGameOver, {
        // fontFamily: 'Poppins',
        fontSize: 68, color: '#666',
        backgroundColor: '#eee',
        stroke: '#000', strokeThickness: 4,
        align: 'center', padding: 24,
      }).setOrigin(0.5).setVisible(false).setDepth(2);
    }

    this.readyToPlayGame();
  }

  update(time, delta) {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    const heightMoved = Math.abs(this.cameras.main.scrollY) + this.car.displayHeight - (ROAD_SEGMENT_HEIGHT-ROAD_WIDTH)/2;
    const segmentCounter = Math.ceil(heightMoved/ROAD_SEGMENT_HEIGHT);

    if (this.inAutoSimulation) {
      const sideMoveCount = segmentCounter/5;
      if ((sideMoveCount === 4 && this.autoPlayPauseCounter === 3)) {
        this.pointerUpAndDown(this.autoSimulationWin ? 8500 : Math.random() * 8500);
      } else if ((sideMoveCount === 8 && this.autoPlayPauseCounter === 7)) {
        this.pointerUpAndDown(this.autoSimulationWin ? 7500 : Math.random() * 8500);
      } else if ((sideMoveCount === 6 && this.autoPlayPauseCounter === 5)
        || (sideMoveCount === 7 && this.autoPlayPauseCounter === 6)) {
        this.pointerUpAndDown(Math.random() * 800);
      } else if ((sideMoveCount === 2 && this.autoPlayPauseCounter === 1)
        || (sideMoveCount === 3 && this.autoPlayPauseCounter === 2)
        || (sideMoveCount === 5 && this.autoPlayPauseCounter === 4)) {
        this.pointerUpAndDown(Math.random() * 800);
      } else if ((sideMoveCount === 1 && this.autoPlayPauseCounter === 0)) {
        this.pointerUpAndDown(Math.random() * 500);
      }
    }

    if (this.isHolding || this.speed !== this.moveToSpeed) {
      const speedChangeDelta = (this.maxSpeed * (delta/1000))/10;
      const maxSpeed = this.moveToSpeed * (delta/1000);
      if (maxSpeed > 0) {
        this.speed = Math.min(maxSpeed, this.speed + speedChangeDelta);
      } else {
        this.speed = Math.max(maxSpeed, this.speed - speedChangeDelta * 1.2);
      }

      const curvedSegment = this.curveSegments[segmentCounter];
      if (curvedSegment && this.cameras.main.scrollX >= -MAX_SIDE_ROAD_LENGTH && this.cameras.main.scrollX <= MAX_SIDE_ROAD_LENGTH) {
        const backToCenter = curvedSegment.backToCenter;
        let delta = curvedSegment.direction * this.speed;
        if (backToCenter) {
          if (curvedSegment.direction === 1 && this.cameras.main.scrollX + delta > 0) {
            delta = this.cameras.main.scrollX;
          } else if (curvedSegment.direction === -1 && this.cameras.main.scrollX + delta < 0) {
            delta = - this.cameras.main.scrollX;
          }
        } else {
          if (curvedSegment.direction === 1 && this.cameras.main.scrollX + delta > MAX_SIDE_ROAD_LENGTH) {
            delta = MAX_SIDE_ROAD_LENGTH - this.cameras.main.scrollX;
          } else if (curvedSegment.direction === -1 && this.cameras.main.scrollX + delta < -MAX_SIDE_ROAD_LENGTH) {
            delta = -MAX_SIDE_ROAD_LENGTH - this.cameras.main.scrollX;
          }
        }

        this.cameras.main.scrollX += delta;
        this.car.setX(this.car.x + delta);
        this.textScore.setX(this.textScore.x + delta);
        if (this.gameOverText) {
          this.gameOverText.setX(this.gameOverText.x + delta);
        }
        // this.roadGraphicsRect.setX(this.roadGraphicsRect.x - this.speed);

        let effScrollXForRotation = this.cameras.main.scrollX;
        if (effScrollXForRotation <= -MAX_SIDE_ROAD_LENGTH + ROAD_WIDTH/2) {
          effScrollXForRotation = -MAX_SIDE_ROAD_LENGTH - effScrollXForRotation;
        } else if (effScrollXForRotation >= MAX_SIDE_ROAD_LENGTH - ROAD_WIDTH/2) {
          effScrollXForRotation = MAX_SIDE_ROAD_LENGTH - effScrollXForRotation;
        }
        if (backToCenter) {
          effScrollXForRotation = -effScrollXForRotation;
        }

        logDebug('effScrollXForRotation', this.cameras.main.scrollX);
        let rotateAngle = Math.max(-90, Math.min(90, 90/CURVED_ROAD_WIDTH * effScrollXForRotation));

        logDebug('rotateAngle', rotateAngle);
        this.car.setAngle(rotateAngle);
        this.car.body.angle = rotateAngle;
      } else if (!curvedSegment) {
        const possibleXValues = [-MAX_SIDE_ROAD_LENGTH, 0, MAX_SIDE_ROAD_LENGTH];
        const actualScrollX = this.cameras.main.scrollX;
        const closest = possibleXValues.reduce(function(prev, curr) {
          return (Math.abs(curr - actualScrollX) < Math.abs(prev - actualScrollX) ? curr : prev);
        });

        this.cameras.main.scrollX = closest;
        if (!closest) {
          this.car.setX(gameWidth/2);
          this.textScore.setX(gameWidth/2);
          if (this.gameOverText) {
            this.gameOverText.setX(gameWidth / 2);
          }
        }

        this.car.setAngle(0);
      }
      if ((this.cameras.main.scrollX >= -ROAD_WIDTH/2 && this.cameras.main.scrollX <= ROAD_WIDTH/2)
        || this.cameras.main.scrollX <= -MAX_SIDE_ROAD_LENGTH+ROAD_WIDTH/2
        || this.cameras.main.scrollX >= MAX_SIDE_ROAD_LENGTH-ROAD_WIDTH/2) {
        let delta = this.speed;
        this.cameras.main.scrollY -= delta;
        this.car.setY(this.car.y - delta);
        this.textScore.setY(this.textScore.y - delta);
        if (this.gameOverText) {
          this.gameOverText.setY(this.gameOverText.y - delta);
        }
        // this.roadGraphicsRect.setY(this.roadGraphicsRect.y - this.speed);
      }

      // logDebug('scroll', Math.abs(this.cameras.main.scrollY) + gameHeight);
      // logDebug('roadUpto', (this.segmentCounter - 2) * ROAD_SEGMENT_HEIGHT);

      if ((this.segmentCounter - 2) * ROAD_SEGMENT_HEIGHT < Math.abs(this.cameras.main.scrollY) + gameHeight) {
        this.drawRoadSegment(gameHeight - (this.segmentCounter+1) * ROAD_SEGMENT_HEIGHT);
      }
    }

    // set score
    const segmentDelta = heightMoved % ROAD_SEGMENT_HEIGHT;
    const score = Math.max(0, segmentCounter-2);
    if (score !== this.score) {
      this.score = score;
      if (this.score === 1) {
        this.game.events.emit('eventOnStart');
      }
      this.textScore.setText(this.score + this.bonusScore);
    }

    // reset horizontal movement
    const segmentStart = segmentCounter - 1;
    const segmentEnd = segmentStart + Math.ceil(gameHeight/ROAD_SEGMENT_HEIGHT);
    const horizontalSegmentKeys = Object.keys(this.horizontalSegments);
    if (horizontalSegmentKeys && horizontalSegmentKeys.length > 0) {
      const screenRightEnd = gameWidth + this.cameras.main.scrollX + 10;
      const screenLeftEnd = this.cameras.main.scrollX - 10;
      horizontalSegmentKeys.forEach((horizontalSegmentKey) => {
        if (horizontalSegmentKey >= segmentStart && horizontalSegmentKey <= segmentEnd) {
          const firstCar = this.horizontalSegments[horizontalSegmentKey].otherCars[0];
          const lastCar = this.horizontalSegments[horizontalSegmentKey].otherCars[this.horizontalSegments[horizontalSegmentKey].otherCars.length - 1];
          if (this.horizontalSegments[horizontalSegmentKey].direction === 1) {
            if (lastCar.x - lastCar.displayWidth / 2 > screenRightEnd) {
              this.horizontalSegments[horizontalSegmentKey].otherCars.forEach((otherCar) => {
                otherCar.setX(otherCar.initialX - firstCar.displayWidth / 2);
              });
            }
          } else {
            if (lastCar.x + lastCar.displayWidth / 2 < screenLeftEnd) {
              this.horizontalSegments[horizontalSegmentKey].otherCars.forEach((otherCar) => {
                otherCar.setX(otherCar.initialX + firstCar.displayWidth / 2);
              });
            }
          }
        }
      });
    }
  }

  pointerUpAndDown = (delay) => {
    this.autoPlayPauseCounter++;
    this.onPointerUp();
    addTimerEvent(this, delay, () => {
      this.autoPlay();
    });
  }

  getLastHorizontalDirection = () => {
    if (this.curvedRoad !== 0) {
      return this.curvedRoad;
    }
    const lastHSegment = this.horizontalSegments[this.segmentCounter - 1];
    if (lastHSegment) {
      return lastHSegment.direction;
    }
    return 1;
  }

  drawRoadSegment = (y) => {
    if (this.raceEndDisplayed) {
      return;
    }

    const gameWidth = this.game.config.width;

    this.segmentCounter++;
    // if (this.segmentCounter % 2 === 1) {
      this.roadGraphics.fillStyle(0xFFFFFF, this.segmentCounter % 2 === 1 ? 0.4 : 0.2);
      this.roadGraphics.fillRect(-MAX_SIDE_VIEW_WIDTH, y, gameWidth + 2*MAX_SIDE_VIEW_WIDTH, ROAD_SEGMENT_HEIGHT);
    // }

    let drawHorizontal = this.segmentCounter > 5 && this.segmentCounter % 5 === 1;
    const drawReverseHorizontal = this.segmentCounter > 20 && this.segmentCounter % 20 === 2;
    drawHorizontal = drawHorizontal || drawReverseHorizontal;
    let drawCurve = false;
    if (drawHorizontal) {
      const horizontalSegmentsCounter = Object.keys(this.horizontalSegments).length;
      drawCurve = !drawReverseHorizontal && (horizontalSegmentsCounter % 5 === 1 || horizontalSegmentsCounter % 5 === 2);
      this.drawHorizontalRoadSegment(y, this.getHorizontalDirection(this.curvedRoad !== 0 || drawReverseHorizontal), drawCurve);
    }

    if (drawCurve) {
      this.drawCurveRoadSegment(y);
    } else {
      this.drawStraightRoadSegment(y, drawHorizontal);
    }
  }

  drawStraightRoadSegment = (y, skipAdBanner) => {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    const x = gameWidth/2 + this.curvedRoad * MAX_SIDE_ROAD_LENGTH;

    this.roadGraphics.fillStyle(0x333333, 1);
    this.roadGraphics.fillRect(x - ROAD_WIDTH/2, y, ROAD_WIDTH, ROAD_SEGMENT_HEIGHT);

    const stripHeight = ROAD_SEGMENT_HEIGHT/4;
    this.roadGraphics.fillStyle(0xffffff, 0.8);
    this.roadGraphics.fillRect(x - 2, y + stripHeight*.5, 4, stripHeight);
    this.roadGraphics.fillRect(x - 2, y + stripHeight*2.5, 4, ROAD_SEGMENT_HEIGHT/4);

    this.roadGraphics.fillStyle(0xffffff, 1);
    this.roadGraphics.fillRect(x - ROAD_WIDTH/2, y, 4, ROAD_SEGMENT_HEIGHT);
    this.roadGraphics.fillRect(x + ROAD_WIDTH/2 - 4, y, 4, ROAD_SEGMENT_HEIGHT);

    if (this.segmentCounter % 2 === 0 && !skipAdBanner) {
      const spacing = 8;
      const spaceExceptRoad = (gameWidth - ROAD_WIDTH)/2;
      const size = Math.min(spaceExceptRoad, ROAD_SEGMENT_HEIGHT) - 2*spacing;
      const posFromCenter = ROAD_WIDTH/2 + (spaceExceptRoad-size)/2 + size/2;
      const xPos = this.segmentCounter % 4 === 0 ? x + posFromCenter : x - posFromCenter;
      const imageId = this.segmentCounter % 4 === 0 ? 'adImage2' : 'adImage1';
      this.add.image(xPos, y + size/2 + spacing, imageId)
        .setDisplaySize(size, size);
    }

    if (this.score + this.bonusScore >= this.scoreToWin - 5 && !skipAdBanner && !this.raceEndDisplayed) {
      this.raceEndDisplayed = true;
      const width = ROAD_WIDTH - 8;
      const height = ROAD_WIDTH * .15;
      const end = this.physics.add.image(x, y, 'finish')
        .setDisplaySize(width, height);
      this.physics.add.overlap(this.car, end, this.onReachFinish);
    }
  }

  getHorizontalDirection = (oppositeToLast) => {
    let direction = Phaser.Math.Between(0, 1) ? 1 : -1;
    if (oppositeToLast) {
      const lastDirection = this.getLastHorizontalDirection();
      if (lastDirection) {
        direction = -1 * lastDirection;
      }
    }
    return direction;
  }

  drawHorizontalRoadSegment = (y, direction, isCurve) => {
    y = y + (ROAD_SEGMENT_HEIGHT - ROAD_WIDTH) / 2;
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    const horizontalSegmentsCounter = Object.keys(this.horizontalSegments).length;
    const velocity = HORIZONTAL_CAR_VELOCITY + horizontalSegmentsCounter * HORIZONTAL_CAR_VELOCITY_INCREASE;

    const effRoadWidth = gameWidth + 2 * MAX_SIDE_VIEW_WIDTH;
    const effRoadHeight = ROAD_WIDTH;
    this.roadGraphics.fillStyle(0x333333, 1); // Black road fill
    this.roadGraphics.fillRect(-MAX_SIDE_VIEW_WIDTH, y, effRoadWidth, effRoadHeight);

    // Draw white borders
    this.roadGraphics.fillStyle(0xffffff, 1);
    this.roadGraphics.fillRect(-MAX_SIDE_VIEW_WIDTH, y, effRoadWidth, 4);
    this.roadGraphics.fillRect(-MAX_SIDE_VIEW_WIDTH, y + effRoadHeight, effRoadWidth, 4);

    if (isCurve) {
      let xPos = gameWidth/2;
      let xPosDirection = direction;
      if (this.curvedRoad) {
        xPosDirection = -direction;
      }
      const stripHeight = ROAD_SEGMENT_HEIGHT/4;
      this.roadGraphics.fillStyle(0xffffff, 0.8);
      this.roadGraphics.fillRect(xPos + xPosDirection*stripHeight, y + ROAD_WIDTH/2 - 2, stripHeight, 4);
      this.roadGraphics.fillRect(xPos + xPosDirection*stripHeight*3, y + ROAD_WIDTH/2 - 2, stripHeight, 4);
      this.roadGraphics.fillRect(xPos + xPosDirection*stripHeight*5, y + ROAD_WIDTH/2 - 2, stripHeight, 4);
      this.roadGraphics.fillRect(xPos + xPosDirection*stripHeight*7, y + ROAD_WIDTH/2 - 2, stripHeight, 4);
    }

    let vehiclePositions = null;
    if (this.inAutoSimulation) {
      const sideMoveCount = Math.floor(this.segmentCounter/5);
      if (sideMoveCount < 4 || isCurve) {
        vehiclePositions = [
          {image: 'bonus', height: this.carWidth*.5, width: this.carWidth*.5},
          {image: 'bonus', height: this.carWidth*.5, width: this.carWidth*.5},
          {height: this.carWidth*.5, width: this.carWidth},
          {height: this.carWidth*.5, width: this.carWidth},
        ];
        vehiclePositions = utils.shuffleArray(vehiclePositions);
        vehiclePositions.push({image: 'carCop', height: this.carWidth*.93, width: this.carHeight*1.13});
        vehiclePositions.push({image: 'carCop', height: this.carWidth*.93, width: this.carHeight*1.13});
        vehiclePositions.push({image: 'carBlue', height: this.carWidth*.93, width: this.carHeight*1.13});
        vehiclePositions.push({image: 'carBlue', height: this.carWidth*.93, width: this.carHeight*1.13});
        vehiclePositions.push({image: 'pickupTruck', height: this.carWidth*.93, width: this.carHeight*1.22});
        vehiclePositions.push({image: 'pickupTruck', height: this.carWidth*.93, width: this.carHeight*1.22});
      } else {
        vehiclePositions = [
          {image: 'bonus', height: this.carWidth*.5, width: this.carWidth*.5},
          {image: 'carCop', height: this.carWidth*.93, width: this.carHeight*1.13},
          {image: 'carCop', height: this.carWidth*.93, width: this.carHeight*1.13},
          {image: 'carBlue', height: this.carWidth*.93, width: this.carHeight*1.13},
          {image: 'carBlue', height: this.carWidth*.93, width: this.carHeight*1.13},
          {image: 'pickupTruck', height: this.carWidth*.93, width: this.carHeight*1.22},
          {image: 'pickupTruck', height: this.carWidth*.93, width: this.carHeight*1.22},
        ];
        vehiclePositions = utils.shuffleArray(vehiclePositions);
        vehiclePositions.push({image: 'bonus', height: this.carWidth*.5, width: this.carWidth*.5});
        vehiclePositions.push({height: this.carWidth*.5, width: this.carWidth});
        vehiclePositions.push({image: 'bonus', height: this.carWidth*.5, width: this.carWidth*.5});
        vehiclePositions.push({height: this.carWidth*.5, width: this.carWidth});
      }
    }

    else {
      vehiclePositions = [
        {image: 'bonus', height: this.carWidth*.5, width: this.carWidth*.5},
        {image: 'bonus', height: this.carWidth*.5, width: this.carWidth*.5},
        {image: 'bonus', height: this.carWidth*.5, width: this.carWidth*.5},
        {image: 'bonus', height: this.carWidth*.5, width: this.carWidth*.5},
        {image: 'carCop', height: this.carWidth*.93, width: this.carHeight*1.13},
        {image: 'carCop', height: this.carWidth*.93, width: this.carHeight*1.13},
        {image: 'carCop', height: this.carWidth*.93, width: this.carHeight*1.13},
        {image: 'carCop', height: this.carWidth*.93, width: this.carHeight*1.13},
        {image: 'carBlue', height: this.carWidth*.93, width: this.carHeight*1.13},
        {image: 'carBlue', height: this.carWidth*.93, width: this.carHeight*1.13},
        {image: 'carBlue', height: this.carWidth*.93, width: this.carHeight*1.13},
        {image: 'carBlue', height: this.carWidth*.93, width: this.carHeight*1.13},
        {image: 'pickupTruck', height: this.carWidth*.93, width: this.carHeight*1.22},
        {image: 'pickupTruck', height: this.carWidth*.93, width: this.carHeight*1.22},
        {image: 'pickupTruck', height: this.carWidth*.93, width: this.carHeight*1.22},
        {image: 'pickupTruck', height: this.carWidth*.93, width: this.carHeight*1.22},
        {height: this.carWidth*.5, width: this.carWidth},
        {height: this.carWidth*.5, width: this.carWidth},
        {height: this.carWidth*.5, width: this.carWidth},
        {height: this.carWidth*.5, width: this.carWidth},
      ];
      vehiclePositions = utils.shuffleArray(vehiclePositions);
    }

    const carSpace = this.carWidth*1.5;
    let widthFilled = direction === 1 ? 0 : gameWidth;
    const otherCars = [];
    let bonusCounter = 0;

    // const container = this.add.container(0, y);
    vehiclePositions.forEach((vehicle) => {
      if (!vehicle.image) {
        widthFilled += vehicle.width;
      } else if (vehicle.image === 'bonus') {
        const bonusHeight = vehicle.width * 1.4;
        const bonus1 = this.physics.add.image(-direction * widthFilled, y + (ROAD_WIDTH-vehicle.width)/2 + vehicle.width/2, vehicle.image)
          .setDisplaySize(vehicle.width, bonusHeight)
          .setVelocityX(direction * velocity)
          .setDepth(1);
        bonus1.name = 'bonus_'+ this.segmentCounter +'_'+ (++bonusCounter);
        bonus1.initialX = bonus1.x;
        this.physics.add.overlap(this.car, bonus1, this.onBonusHit);
        otherCars.push(bonus1);
        widthFilled += (vehicle.width * 1.5);

        const bonus2 = this.physics.add.image(-direction * widthFilled, y + (ROAD_WIDTH-vehicle.width)/2 + vehicle.width/2, vehicle.image)
          .setDisplaySize(vehicle.width, bonusHeight)
          .setVelocityX(direction * velocity)
          .setDepth(1);
        bonus2.name = 'bonus_'+ this.segmentCounter +'_'+ (++bonusCounter);
        bonus2.initialX = bonus2.x;
        this.physics.add.overlap(this.car, bonus2, this.onBonusHit);
        otherCars.push(bonus2);
        widthFilled += vehicle.width;
      } else {
        const otherCar = this.physics.add.image(-direction * widthFilled, y + (ROAD_WIDTH-vehicle.width)/2 + vehicle.width/2, vehicle.image)
          .setDisplaySize(vehicle.width, vehicle.height)
          .setVelocityX(direction * velocity)
          .setBounce(1, 1)
          .setDepth(1);
        if (direction === 1) {
          otherCar.flipX = true;
        }
        otherCar.initialX = otherCar.x;
        otherCar.setBodySize(otherCar.body.width *.95, otherCar.body.height *.8);
        this.physics.add.overlap(this.car, otherCar, this.onCarCollide);
        otherCars.push(otherCar);
        widthFilled += vehicle.width;
      }
      widthFilled += carSpace;
    });

    this.horizontalSegments[this.segmentCounter] = {
      totalWidth: Math.abs(widthFilled),
      otherCars: otherCars,
      direction: direction
    };
  }

  drawCurveRoadSegment = (y) => {
    let direction = 1;
    const hSegment = this.horizontalSegments[this.segmentCounter];
    if (hSegment) {
      direction = hSegment.direction;
    }
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    let posDirection = direction;
    if (this.curvedRoad) {
      posDirection = -1 * posDirection; // reverse to set the curve road at perfect position
    }
    const x1 = gameWidth/2 + posDirection * (CURVED_ROAD_WIDTH-ROAD_WIDTH)/2;
    const y1 = y + ROAD_SEGMENT_HEIGHT/2 + (ROAD_SEGMENT_HEIGHT-ROAD_WIDTH)/2 + 2;
    const x2 = x1 + posDirection * MAX_SIDE_ROAD_LENGTH - posDirection * (CURVED_ROAD_WIDTH-ROAD_WIDTH);
    const y2 = y + ROAD_SEGMENT_HEIGHT/2 - (ROAD_SEGMENT_HEIGHT-ROAD_WIDTH)/2 + 2;
    const curve1 = this.add.image(this.curvedRoad ? x2 : x1, y1, 'roadCurve')
      .setDisplaySize(CURVED_ROAD_WIDTH, ROAD_SEGMENT_HEIGHT+2);
    if (direction === -1) {
      curve1.setAngle(90);
    }
    const curve2 = this.add.image(this.curvedRoad ? x1 : x2, y2, 'roadCurve')
      .setDisplaySize(CURVED_ROAD_WIDTH, ROAD_SEGMENT_HEIGHT+2);
    if (direction === -1) {
      curve2.setAngle(270);
    } else if (direction === 1) {
      curve2.setAngle(180);
    }

    this.curveSegments[this.segmentCounter] = {
      x1: x1,
      y1: y1,
      x2: x2,
      y2: y2,
      direction: direction,
      backToCenter: this.curvedRoad !== 0
    };
    if (!this.curvedRoad) {
      this.curvedRoad = direction;
    } else {
      this.curvedRoad = 0;
    }
  }

  attachPointers = () => {
    this.input.on('pointerdown', this.onPointerDown);
    this.input.on('pointerup', this.onPointerUp);
  }

  detachPointers = () => {
    this.input.off('pointerdown', this.onPointerDown);
    this.input.off('pointerup', this.onPointerUp);
  }

  onPointerDown = () => {
    this.isHolding = true;
    this.moveToSpeed = this.maxSpeed;
    if (this.audioPlay) {
      this.audioPlay.play();
    }
  };

  onPointerUp = () => {
    this.isHolding = false;
    this.moveToSpeed = 0;
    if (this.audioPlay) {
      this.audioPlay.stop();
    }
  };

  onReachFinish = (car, otherObject) => {
    if (!this.carCollided) {
      this.carCollided = true;
      if (this.audioGameWin) {
        this.audioGameWin.play();
      }
      this.endGame(true);
    }
  }

  onCarCollide = (car, otherObject) => {
    if (!this.carCollided) {
      this.carCollided = true;
      if (this.audioCrash) {
        this.audioCrash.play();
      }
      const overlap = getOverlap(car, otherObject);
      if (overlap && overlap.length > 1) {
        // const overlap[0]
        // Extract intersection data from the result object
        logDebug('overlap', overlap);
        const centerPoint = getCenterPoint(overlap[0], overlap[1]);

        const size = 300;
        this.add.image(centerPoint.x, centerPoint.y, 'crash')
          .setDisplaySize(size, size)
          .setDepth(1);
      }
      this.endGame();
    }
  }

  onBonusHit = (car, otherObject) => {
    const isOverlapped = checkOverlap(car, otherObject);
    if (!this.gameEnded && isOverlapped && !this.bonusName_OverlappedMap[otherObject.name]) {
      if (this.audioBonus) {
        this.audioBonus.play();
      }
      this.bonusName_OverlappedMap[otherObject.name] = 1;
      this.bonusScore += 2;
      this.textScore.setText(this.score + this.bonusScore)
      otherObject.setVisible(false);
    }
  }

  readyToPlayGame = () => {
    if (!this.gameEnded) {
      this.attachPointers();
    }
  }

  endGame = (isWin) =>  {
    this.gameEnded = true;

    this.detachPointers();
    this.onPointerUp();
    // stop horizontal traffic
    const horizontalSegmentKeys = Object.keys(this.horizontalSegments);
    if (horizontalSegmentKeys && horizontalSegmentKeys.length > 0) {
      horizontalSegmentKeys.forEach((horizontalSegmentKey) => {
        this.horizontalSegments[horizontalSegmentKey].otherCars.forEach((otherCar) => {
          otherCar.setVelocityX(0);
        });
      });
    }

    isWin = isWin && (this.score+this.bonusScore) >= this.scoreToWin;
    if (isWin) {
      if (this.audioGameWin) {
        this.audioGameWin.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringWellDone);
      }
    } else {
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringGameOver);
      }
    }

    addTimerEvent(this, 1000, () => {
      if (this.gameOverText) {
        this.gameOverText.setVisible(true);
      }
      addTimerEvent(this, 1000, () => {
        this.game.events.emit('eventOnEnd', this.score);
      });
    });
  }
}
