import PropTypes from 'prop-types';
import React, {forwardRef, useImperativeHandle, useLayoutEffect, useRef} from 'react';
import Phaser from "phaser";
import {GCCBoot} from "src/gameCannonCraze/scenes/GCCBoot.js";
import {GCCPreloader} from "src/gameCannonCraze/scenes/GCCPreloader.js";
import {GCCGame} from "src/gameCannonCraze/scenes/GCCGame.js";
import {logDebug} from "src/js/utils/AppLog";
import {isWebGLAvailable} from "src/game/GameHelper";

const config = {
  type: Phaser.AUTO,
  transparent: true,
  width: 675,
  height: 1080,
  parent: 'game-container',
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.NO_CENTER
  },
  physics: {
    default: 'arcade',
    arcade: {
      // debug: true
    }
  },
  scene: [
    GCCBoot,
    GCCPreloader,
    GCCGame
  ],
};

export const gameCannonCrazeInfo = () => {
  return {
    'name': [{'en': 'Tap Tap Shots'}, {'ar': 'Tap Tap Shots'}],
    'imageUrl': 'media/gameCannonCraze/ball.png',
    'guide': [{'en': 'Tap Tap Shots'}, {'ar': 'Tap Tap Shots'}],
    'themeColor': '#E0863B',
    'resultType': 2,
    'scoreMid': 10,
    'configs': {
      'themeBg': 1,
      'images': [
        {'number': 0, 'label': 'cannonTop', 'url': 'media/gameCannonCraze/cannonTop.png'},
        {'number': 1, 'label': 'targetTop1', 'url': 'media/gameCannonCraze/targetTop1.png'},
        {'number': 2, 'label': 'targetTop2', 'url': 'media/gameCannonCraze/targetTop2.png'},
        {'number': 3, 'label': 'targetTop3', 'url': 'media/gameCannonCraze/targetTop3.png'}
      ],
      'audios': [{'label': 'Background', 'url': 'media/gameCannonCraze/audios/game_bg.m4a'}],
      'preload': {
        'baseUrl': 'media/gameCannonCraze',
        'media': [
          'adImage.png',
          'ball.png',
          'bg.2.png',
          'cannon.png',
          'cannonTop.png',
          'colorSplash.png',
          'plus1.png',
          'plus2.png',
          'target.png',
          'targetTop1.png',
          'targetTop2.png',
          'targetTop3.png',
          'timer.png'
        ],
      }
    }
  };
}

export const GameCannonCraze = forwardRef((props, ref) => {
  const {width, height, options} = props;

  const game = useRef();

  useImperativeHandle(ref, () => ({
    start, restart, mute, simulate
  }));

  const start = () => {
    game.current.scene.switch('GCCGame');
  }

  const restart = (options) => {
    if (game.current) {
      game.current.options.canPlay = options.canPlay;
      if (!game.current.options.simulation) {
        game.current.events.emit('restart');
      }
    }
  }

  const mute = (mute) => {
    if (game.current) {
      if (game.current.options) {
        game.current.options.mute = mute;
      }
      game.current.events.emit('mute', mute);
    }
  }

  const simulate = () => {
    if (game.current) {
      game.current.events.emit('simulate');
    }
  }

  const onAppDidFocus = () => {
    if (game.current) {
      game.current.resume();
    }
  }

  const onAppDidBlur = () => {
    if (game.current) {
      game.current.pause();
    }
  }

  // Create the gameCannonCraze inside a useLayoutEffect hook to avoid the gameCannonCraze being created outside the DOM
  useLayoutEffect(() => {
    if (game.current === undefined) {
      if (width && height) {
        config.width = config.height * width/height;
      }
      if (!isWebGLAvailable()) {
        config.type = Phaser.CANVAS;
      }
      if (options.simulation) {
        config.type = Phaser.CANVAS;
        config.audio = {noAudio: true}
      }

      const parent = 'game-container';
      game.current = new Phaser.Game({...config, parent});
      game.current.options = options;

      game.current.events.on('eventOnStart', () => {
        if (options && options.onStart) {
          options.onStart();
        }
      });
      game.current.events.on('eventOnEnd', (score) => {
        if (options && options.onEnd) {
          options.onEnd(score);
        }
      });
      window.addEventListener('focus', onAppDidFocus);
      window.addEventListener('blur', onAppDidBlur);
    }

    return () => {
      if (game.current) {
        game.current.events.removeListener('eventOnStart');
        game.current.events.removeListener('eventOnEnd');
        window.removeEventListener('focus', onAppDidFocus);
        window.removeEventListener('blur', onAppDidBlur);

        game.current.options = null;
        game.current.destroy(true);
        logDebug('DGG:GameDestroyed');
        game.current = undefined;
      }
    }
  }, [width, height, options]);

  return (
    <div id="game-container" style={{backgroundColor: options.bgColor, width: (options.isRotated ? height : width)/options.scale, height: (options.isRotated ? width : height)/options.scale}}/>
  );

});

// Props definitions
GameCannonCraze.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.object
}
