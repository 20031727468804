import {Scene} from 'phaser';
import {addTimerEvent, getReadableColor, shakeObject} from "src/game/GameHelper";
import {logDebug} from "src/js/utils/AppLog";
import {colorWithAlpha} from "src/js/utils/AppUtils";

const PADDLE_SIZE = 140;
const PADDLE_BOT_Y = 190;
const PADDLE_PLAYER_Y_FROM_BOTTOM = 130;
const SIDE_WALL_WIDTH = 16;
const PADDLE_SLIDE_FACTOR = 6;

export class GPGame extends Scene {

  constructor() {
    super('GPGame');
  }

  onEventRestart = () => {
    this.cleanup();
    if (!this.game.options.simulation) {
      this.scene.restart('GPGame');
    }
  };

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
    if (this.audioHit) {
      this.audioHit.setMute(mute);
    }
    if (this.audioMiss) {
      this.audioMiss.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
    if (this.audioGameWin) {
      this.audioGameWin.setMute(mute);
    }
  };

  onEventSimulate = () => {
    this.autoPlay();
  }

  autoPlay = () => {
    addTimerEvent(this, 1000, () => {
      if (!this.gameEnded) {
        this.onPointerMove({x: this.game.config.width/2});
      }
    });
  }

  getRoundText = () => {
    if (this.game.options.locale && this.game.options.locale == 'ar') {
      return "الجولة    "+ this.round +"   من   "+ this.tryCount;
    }
    return "Round   "+ this.round +"  of  "+ this.tryCount;
  }

  getBotScoreText = () => {
    if (this.game.options.locale && this.game.options.locale == 'ar') {
      return `بوت:  ${this.scoreN}`;
    }
    return `Bot:  ${this.scoreN}`;
  }

  getPlayerScoreText = () => {
    if (this.game.options.locale && this.game.options.locale == 'ar') {
      return `أنت:  ${this.score}`;
    }
    return `You:  ${this.score}`;
  }

  cleanup = () => {
    this.game.events.off('mute', this.onEventMute);
    this.game.events.off('restart', this.onEventRestart);
    this.game.events.off('simulate', this.onEventSimulate);
    if (this.audioGameBg) {
      this.audioGameBg.stop();
    }
    this.detachPointers();
    this.time.removeAllEvents();
    this.events.off('destroy');
  }

  create() {
    this.events.on('destroy', this.cleanup);
    this.game.events.on('mute', this.onEventMute);
    this.game.events.on('restart', this.onEventRestart);
    this.game.events.on('simulate', this.onEventSimulate);

    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';
    this.stringRoundWon = this.game.options.locale && this.game.options.locale == 'ar' ? 'لقد فزت في الجولة' : 'You Won Round';
    this.stringRoundLost = this.game.options.locale && this.game.options.locale == 'ar' ? 'لقد خسرت الجولة' : 'You Lost Round';
    const stringHint = this.game.options.locale && this.game.options.locale == 'ar' ? 'اضغط للبدء' : 'Click to Start';
    const stringHintDetail = this.game.options.locale && this.game.options.locale == 'ar' ? 'تحرك يسارًا/يمينًا للتحكم' : 'Move left/right to control';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    const themeColor = this.game.options.themeColor;
    const themeColorInt = Phaser.Display.Color.HexStringToColor(this.game.options.themeColor).color;
    const readableColor = getReadableColor(this.game.options.themeColor);
    const readableColorInt = Phaser.Display.Color.HexStringToColor(readableColor).color;

    this.score = 0;
    this.scoreN = 0;
    this.round = 0;
    this.scoreToWin = this.game.options.scoreToWin || 1;
    this.tryCount = this.game.options.simulation ? 3 : (this.game.options.tryCount || 5);
    this.isGameStarted = false;

    this.gameEnded = false;
    this.showAdBanner = false;
    this.canClick = true;
    this.playerMoveId = null;

    if (!this.game.options.simulation) {
      this.audioGameBg = this.sound.add('audio_bg', {loop: true})
        .setMute(this.game.options.mute);
      this.audioGameBg.play();
      this.audioHit = this.sound.add('audio_hit')
        .setMute(this.game.options.mute)
        .setVolume(0.5);
      this.audioMiss = this.sound.add('audio_miss')
        .setMute(this.game.options.mute);
      this.audioGameEnd = this.sound.add('audio_game_end')
        .setMute(this.game.options.mute)
        .setVolume(0.2);
      this.audioGameWin = this.sound.add('audio_game_win')
        .setMute(this.game.options.mute)
        .setVolume(0.7);
    }

    // const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    // bgImage.setDisplaySize(gameWidth, gameHeight);
    this.centerY = 60+(gameHeight-60)/2;
    const graphics = this.add.graphics();
    graphics.fillStyle(themeColorInt, 0.7);
    graphics.fillRect(0, 0, gameWidth, gameHeight);

    this.textRound = this.add.text(gameWidth / 2, 38, this.getRoundText(), {
      fontFamily: 'Poppins',
      fontSize: 36,
      color: readableColor,
      stroke: themeColor,
      align: 'center',
    }).setOrigin(0.5)
      .setDepth (1)
      .setShadow(0, 0, 0xffffff, 4);

    // const graphics = this.add.graphics();
    graphics.fillStyle(readableColorInt, 0.8)
      .fillRoundedRect(gameWidth/2 - 135, 64, 270, 3, 2);

    this.drawHorizontalDottedRect(graphics, readableColorInt, 0.1, 2, 16, gameWidth-16, this.centerY, 20, 10);
    graphics.fillStyle(readableColorInt, 0.4)
      .fillRoundedRect(0, 0, 2, gameHeight, 2)
      .fillRoundedRect(14, 0, 2, gameHeight, 2)
      .fillRoundedRect(gameWidth-16, 0, 2, gameHeight, 2)
      .fillRoundedRect(gameWidth-2, 0, 2, gameHeight, 2);
    this.drawVerticalDottedRect(graphics, readableColorInt, 0.4, 8, 4, 0, gameHeight, 10, 2);
    this.drawVerticalDottedRect(graphics, readableColorInt, 0.4, 8, gameWidth-12, 0, gameHeight, 10, 2);

    this.textBotScore = this.add.text(gameWidth/2, 80, this.getBotScoreText(), {
      fontFamily: 'Poppins',
      fontSize: 28,
      color: readableColor,
      align: 'center',
      padding: 4,
      backgroundColor: colorWithAlpha(readableColor, 0.1)
    }).setOrigin(0.5,0);
    this.textPlayerScore = this.add.text(gameWidth/2, gameHeight-20, this.getPlayerScoreText(), {
      fontFamily: 'Poppins',
      fontSize: 28,
      color: readableColor,
      align: 'center',
      padding: 4,
      backgroundColor: colorWithAlpha(readableColor, 0.1)
    }).setOrigin(0.5,1);

    if (!this.game.options.simulation) {
      this.textHint = this.add.text(gameWidth / 2, gameHeight/2 + 80, stringHint, {
        fontFamily: 'Poppins',
        fontSize: 32,
        color: readableColor,
        align: 'center',
      }).setOrigin(0.5);
      this.textHintDetail = this.add.text(gameWidth / 2, gameHeight/2 + 130, stringHintDetail, {
        fontFamily: 'Poppins',
        fontSize: 28,
        color: readableColor,
        align: 'center',
      }).setOrigin(0.5);
    }

    this.physics.world.setBounds(16, -gameHeight/2, gameWidth-32, 2*gameHeight);

    const topBoundary = this.physics.add.staticImage(gameWidth/2, -40, 'dummy');
    topBoundary.setSize(gameWidth, 16);
    const bottomBoundary = this.physics.add.staticImage(gameWidth/2, gameHeight+40, 'dummy');
    bottomBoundary.setSize(gameWidth, 16);

    this.paddleBot = this.getPaddle('robot', gameWidth/2, PADDLE_BOT_Y);
    this.paddlePlayer = this.getPaddle('user', gameWidth/2, gameHeight - PADDLE_PLAYER_Y_FROM_BOTTOM);

    const shadowRect = this.add.ellipse(15, 15, 30, 30, themeColorInt, 1).setOrigin(0.5, 0.5);
    const mainRect = this.add.ellipse(15, 15, 26, 26, readableColorInt).setOrigin(0.5, 0.5);
    const ballContainer = this.add.container(gameWidth/2 - 15, this.centerY - 15, [shadowRect, mainRect]);
    this.ball = this.physics.add.existing(ballContainer);
    this.ball.body.setSize(30, 30);
    this.ball.body.setCollideWorldBounds(true, 1, 1);
    this.physics.add.collider(this.ball, this.paddleBot, this.hitPaddle);
    this.physics.add.collider(this.ball, this.paddlePlayer, this.hitPaddle);

    this.physics.add.overlap(this.ball, topBoundary, this.onHitTopBoundary);
    this.physics.add.overlap(this.ball, bottomBoundary, this.onHitBottomBoundary);

    const adSize = Math.min(gameWidth - 2*SIDE_WALL_WIDTH, gameHeight-120-PADDLE_BOT_Y-PADDLE_SIZE) - 4;
    this.adImage1 = this.add.image(gameWidth / 2, this.centerY, 'adImage1').setAlpha(0.2);
    this.adImage1.setDisplaySize(adSize, adSize);
    this.adImage = this.adImage1;
    this.adImage2 = this.add.image(gameWidth / 2, this.centerY, 'adImage2').setAlpha(0.2);
    this.adImage2.setDisplaySize(adSize, adSize);
    this.adImage2.setVisible(false);
    this.adImage3 = this.add.image(gameWidth / 2, this.centerY, 'adImage3').setAlpha(0.2);
    this.adImage3.setDisplaySize(adSize, adSize);
    this.adImage3.setVisible(false);

    // this.botPoint = this.add.rectangle(0,PADDLE_BOT_Y+PADDLE_SIZE/2,4,4,0xFF0000);
    // this.playerPoint = this.add.rectangle(0,gameHeight-PADDLE_PLAYER_Y_FROM_BOTTOM-PADDLE_SIZE/2,4,4,0xFF0000);

    this.roundOverTextTopY = 320;
    this.roundOverText = this.add.text(gameWidth / 2, 320, this.stringRoundWon, {
      fontFamily: 'Poppins',
      fontSize: 42,
      fontStyle: '500',
      color: readableColor,
      backgroundColor: 'rgba(255,255,255,0.3)',
      align: 'center',
      padding: 8,
    }).setOrigin(0.5)
      .setVisible(false);
    this.physics.add.existing(this.roundOverText);

    if (!this.game.options.simulation) {
      this.gameOverText = this.add.text(gameWidth / 2, -72, this.stringGameOver, {
        fontFamily: 'Poppins',
        fontSize: 72,
        fontStyle: '500',
        color: readableColor,
        backgroundColor: colorWithAlpha(readableColor, 0.2),
        align: 'center',
        padding: 14,
      }).setOrigin(0.5).setVisible(false).setDepth(1);
      this.physics.add.existing(this.gameOverText);
    }

    this.attachPointers();
  }

  update(time, delta) {
    if (this.roundOverText.visible && this.roundOverText.y <= this.roundOverTextTopY) {
      this.roundOverText.body.setVelocityY(0);
    }
    if (this.gameOverText && this.gameOverText.visible && this.gameOverText.y >= 300) {
      this.gameOverText.body.setVelocityY(0);
    }

    if (this.adImage) {
      if (this.showAdBanner) {
        if (this.adImage.alpha < 1) {
          this.adImage.setAlpha(this.adImage.alpha + 0.05);
        }
      } else {
        if (this.adImage.alpha > 0.2) {
          this.adImage.setAlpha(this.adImage.alpha - 0.03);
        }
      }
    }

    if (this.gameEnded) {
      return;
    }
    if (this.inPlaying) {
      const delta = this.pointerPlayerX - (this.paddlePlayer.body.x + this.paddlePlayer.body.width/2);
      let speedR = delta * PADDLE_SLIDE_FACTOR;
      let maxSpeed = this.game.config.width * 0.9;
      if (this.game.options.simulation) {
        speedR = delta * (PADDLE_SLIDE_FACTOR - 2);
        maxSpeed = this.game.config.width * 0.6;
      }
      const speed = Phaser.Math.Clamp(speedR, -maxSpeed, maxSpeed);
      if (Math.abs(delta) > 1) {
        this.paddlePlayer.body.setVelocityX(speed);
      } else {
        this.paddlePlayer.body.setVelocityX(0);
      }

      if (this.pointerBotX) {
        const delta = this.pointerBotX - (this.paddleBot.body.x + this.paddleBot.body.width/2);
        let speedR = delta * (PADDLE_SLIDE_FACTOR - 2);
        let maxSpeed = this.game.config.width * 0.5;
        if (this.game.options.simulation) {
          speedR = delta * (PADDLE_SLIDE_FACTOR - 2);
          maxSpeed = this.game.config.width * 0.6;
        }
        const speed = Phaser.Math.Clamp(speedR, -maxSpeed, maxSpeed);
        if (Math.abs(delta) > 1) {
          this.paddleBot.body.setVelocityX(speed);
        } else {
          this.paddleBot.body.setVelocityX(0);
        }
      }
    }

    // this.playerPoint.x = this.pointerPlayerX;
    // this.botPoint.x = this.pointerBotX;
  }

  attachPointers = () => {
    if (this.isGameStarted) {
      this.input.off('pointerdown', this.onPointerMove);
      this.input.on('pointermove', this.onPointerMove);
    } else {
      this.input.off('pointermove', this.onPointerMove);
      this.input.on('pointerdown', this.onPointerMove);
    }
  }

  detachPointers = () => {
    this.input.off('pointerdown', this.onPointerMove);
    this.input.off('pointermove', this.onPointerMove);
  }

  drawVerticalDottedRect(graphics, color, alpha, thickness, x, y1, y2, segmentLength, gapLength) {
    graphics.fillStyle(color, alpha);
    let currentY = y1;
    while (currentY < y2) {
      graphics.fillRect(x, currentY, thickness, segmentLength);
      currentY = currentY + segmentLength + gapLength;
    }
  }

  drawHorizontalDottedRect(graphics, color, alpha, thickness, x1, x2, y, segmentLength, gapLength) {
    graphics.fillStyle(color, alpha);
    let currentX = x1;
    while (currentX < x2) {
      graphics.fillRect(currentX, y, segmentLength, thickness);
      currentX = currentX + segmentLength + gapLength;
    }
  }

  getPaddle(imageId, x, y) {
    const themeColor = this.game.options.themeColor;
    const themeColorInt = Phaser.Display.Color.HexStringToColor(this.game.options.themeColor).color;
    const readableColor = getReadableColor(this.game.options.themeColor);
    const readableColorInt = Phaser.Display.Color.HexStringToColor(readableColor).color;
    const extraSpacing = 2;
    const width = PADDLE_SIZE;
    const height = PADDLE_SIZE + extraSpacing;
    const spacing = 4;
    const innerSize = width - 2 * spacing;
    const innerY = imageId === 'user' ? height/2 + extraSpacing/2 : height/2 - extraSpacing/2;

    const shadowWRect = this.add.rectangle(width/2, height/2, width, height, themeColorInt);
    const shadowRect = this.add.rectangle(width/2, height/2, width-4, height-4, readableColorInt, 0.8);
    const mainRect = this.add.rectangle(width/2, innerY, innerSize, innerSize, 0xFFFFFF);
    const image = this.add.image(width/2, innerY, imageId)
      .setDisplaySize(innerSize, innerSize);
    let container = this.add.container(x - width/2, y - height/2, [shadowWRect, shadowRect, mainRect, image]);
    container = this.physics.add.existing(container);
    container.body.setCollideWorldBounds(true, 0, 0);
    container.body.setSize(width, height*.25);
    container.body.setImmovable(true);
    if (imageId === 'robot') {
      container.body.setOffset(0, height*.75);
    }
    return container;
  }

  onHitTopBoundary = () => {
    if (this.hitBoundary) {
      return;
    }
    this.hitBoundary = true;
    this.score++;
    this.roundOverText.setText(this.stringRoundWon);
    this.textPlayerScore.setText(this.getPlayerScoreText());
    shakeObject(this, this.textPlayerScore, 300);
    this.onHitTopBottom();
  }

  onHitBottomBoundary = () => {
    if (this.hitBoundary) {
      return;
    }
    this.hitBoundary = true;
    this.scoreN++;
    this.roundOverText.setText(this.stringRoundLost);
    this.textBotScore.setText(this.getBotScoreText());
    shakeObject(this, this.textBotScore, 300);
    this.onHitTopBottom();
  }

  onHitTopBottom = () => {
    this.detachPointers();
    this.stopBall();
    this.showAd();
    if (this.audioMiss) {
      this.audioMiss.play();
    }

    addTimerEvent(this, this.round === this.tryCount ? 3500 : 4500, () => {
      this.hitBoundary = false;
      this.roundOverText.setVisible(false);

      if (this.round === this.tryCount) {
        this.endGame();
      } else {
        this.round++;
        this.textRound.setText(this.getRoundText());
        this.attachPointers();
        this.moveBall();
      }
    });
  }

  hitPaddle = (ball, paddle) => {
    this.hitCounter++;
    const speedIncrease = this.game.options.simulation ? 1.02 : 1.005;
    this.ballVelocityX = ball.body.velocity.x + paddle.body.velocity.x * (this.game.options.simulation ? 0.5 : 0.3); // Add effect of paddle motion
    this.ballVelocityY = -1 * (this.ballVelocityY * speedIncrease); // Increase speed & keep upward
    ball.body.setVelocity(this.ballVelocityX, this.ballVelocityY);
    if (this.audioHit) {
      this.audioHit.play();
    }

    this.pointerBotX = null;
    const gameWidth = this.game.config.width - 2*SIDE_WALL_WIDTH;
    const gameHeight = this.game.config.height;
    if (paddle === this.paddlePlayer) {
      const playRandom = this.hitCounter > 8 && Phaser.Math.Between(1,100) > 70;
      if (playRandom) {
        this.pointerBotX = Phaser.Math.Between(SIDE_WALL_WIDTH, gameWidth-SIDE_WALL_WIDTH);
      } else {
        this.pointerBotX = this.findXAtYWithBounce(ball.body.x + ball.body.width / 2, ball.body.y + ball.body.height / 2, this.ballVelocityX, this.ballVelocityY, PADDLE_BOT_Y + PADDLE_SIZE / 2);
        if (!this.pointerBotX) {
          this.pointerBotX = 1;
        }
      }

      if (this.game.options.simulation) {
        this.pointerPlayerX = gameWidth/2;
      }
    } else if (paddle === this.paddleBot) {
      this.pointerBotX = gameWidth/2;

      if (this.game.options.simulation) {
        const playRandom = this.hitCounter > 8 && Phaser.Math.Between(1,100) > 70;
        if (playRandom) {
          this.pointerPlayerX = Phaser.Math.Between(SIDE_WALL_WIDTH, gameWidth-SIDE_WALL_WIDTH);
        } else {
          this.pointerPlayerX = this.findXAtYWithBounce(ball.body.x + ball.body.width/2, ball.body.y + ball.body.height/2, this.ballVelocityX, this.ballVelocityY, gameHeight-PADDLE_PLAYER_Y_FROM_BOTTOM-PADDLE_SIZE/2);
          if (!this.pointerPlayerX) {
            this.pointerPlayerX = 1;
          }
        }
      }
    }
  }

  findXAtYWithBounce(x, y, velocityX, velocityY, targetY) {
    const minX = SIDE_WALL_WIDTH + this.ball.body.width/2;
    const maxX = this.game.config.width - SIDE_WALL_WIDTH - this.ball.body.width/2;
    if (velocityY === 0) return null; // Prevent division by zero

    let timeToHit = (targetY - y) / velocityY;
    let hitX = x + timeToHit * velocityX;

    // Bounce logic
    while (hitX < minX || hitX > maxX) {
      if (hitX < minX) {
        // Calculate time to reach minX
        let t = (minX - x) / velocityX;
        x = minX;
        y += t * velocityY;
        timeToHit -= t;
        velocityX *= -1; // Reverse direction
      } else if (hitX > maxX) {
        // Calculate time to reach maxX
        let t = (maxX - x) / velocityX;
        x = maxX;
        y += t * velocityY;
        timeToHit -= t;
        velocityX *= -1; // Reverse direction
      }
      hitX = x + timeToHit * velocityX; // Update x after bouncing
    }

    return hitX;
  }

  moveBall() {
    const gameWidth = this.game.config.width;

    this.inPlaying = true;
    this.hitCounter = 0;
    const velocityFactor = this.game.options.simulation ? 1.1 : 0.8;
    const velocity = (this.paddlePlayer.body.y - this.paddleBot.body.y) * velocityFactor;
    const speed = velocity + velocity/10 * this.score;
    const angle = Phaser.Math.FloatBetween(-0.8, 0.8);

    let vx = speed * Math.sin(angle);
    let vy = -speed * Math.cos(angle);
    this.ballVelocityX = vx;
    this.ballVelocityY = vy;
    this.ball.body.setVelocity(vx, vy);

    this.pointerPlayerX = gameWidth/2;
    this.pointerBotX = gameWidth/2;

    this.pointerBotX = this.findXAtYWithBounce(this.ball.body.x + this.ball.body.width/2, this.ball.body.y + this.ball.body.height/2, this.ballVelocityX, this.ballVelocityY, PADDLE_BOT_Y+PADDLE_SIZE/2);
    if (!this.pointerBotX) {
      this.pointerBotX = 1;
    }
  }

  stopBall() {
    this.inPlaying = false;
    const gameWidth = this.game.config.width;
    this.ball.body.x = gameWidth/2 - this.ball.body.width/2;
    this.ball.body.y = this.centerY - this.ball.body.height/2;
    this.ball.body.setVelocity(0, 0);
    this.paddlePlayer.body.setVelocityX(0);
    this.paddlePlayer.body.x = gameWidth/2 - this.paddlePlayer.body.width/2;
    this.paddleBot.body.setVelocityX(0);
    this.paddleBot.body.x = gameWidth/2 - this.paddleBot.body.width/2;
  }

  onPointerMove = (pointer) => {
    this.pointerPlayerX = Phaser.Math.Clamp(pointer.x, 16, this.game.config.width-16);
    if (!this.isGameStarted) {
      this.round++;
      this.textRound.setText(this.getRoundText());
      this.isGameStarted = true;
      if (this.textHint) {
        this.textHint.setVisible(false);
      }
      if (this.textHintDetail) {
        this.textHintDetail.setVisible(false);
      }
      this.attachPointers();
      this.moveBall();

      addTimerEvent(this, 1000, () => {
        this.game.events.emit('eventOnStart');
      });
    }
  }

  showAd = () => {
    addTimerEvent(this, 500, () => {
      this.showAdBanner = true;
      this.updateAdImage();
      addTimerEvent(this, 3000, () => {
        this.showAdBanner = false;
      });
    });
  }

  updateAdImage = () => {
    if (this.adImage == this.adImage1) {
      this.adImage1.setVisible(false);
      this.adImage3.setVisible(false);
      this.adImage2.setVisible(true);
      this.adImage = this.adImage2;
    } else if (this.adImage == this.adImage2) {
      this.adImage1.setVisible(false);
      this.adImage2.setVisible(false);
      this.adImage3.setVisible(true);
      this.adImage = this.adImage3;
    } else {
      this.adImage2.setVisible(false);
      this.adImage3.setVisible(false);
      this.adImage1.setVisible(true);
      this.adImage = this.adImage1;
    }
  }

  endGame = () =>  {
    const isWin = this.score > this.scoreN;
    this.gameEnded = true;
    this.roundOverText.setVisible(false);
    if (this.gameOverText) {
      this.gameOverText.setVisible(true);
      this.gameOverText.body.setVelocityY(1000);
    }
    if (isWin) {
      if (this.audioGameWin) {
        this.audioGameWin.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringWellDone);
      }
    } else {
      if (this.audioGameEnd) {
        this.audioGameEnd.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringGameOver);
      }
    }

    addTimerEvent(this, 1000, () => {
      this.game.events.emit('eventOnEnd', isWin ? 1 : 0);
    });
  }
}
