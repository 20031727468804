import React, {forwardRef, useRef} from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  scrollView: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
     display: 'none'
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    scrollBehavior: "smooth"
  },
  scrollViewH: {
    overflowY: 'hidden',
    overflowX: 'scroll',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    scrollBehavior: "smooth"
  },
}));

const ScrollView = forwardRef(({ horizontal = false, children, className, ...otherProps }, ref) => {
  const classes = useStyles();

  // Combine custom and conditional styles.
  const scrollClass = horizontal ? classes.scrollViewH : classes.scrollView;
  const combinedClassName = className ? `${scrollClass} ${className}` : scrollClass;

  return (
    <div ref={ref} className={combinedClassName} {...otherProps}>
      {children}
    </div>
  );
});

export default React.memo(ScrollView);
