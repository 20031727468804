import {Scene} from 'phaser';
import {addTimerEvent, getReadableColor} from "src/game/GameHelper";
import {logDebug} from "src/js/utils/AppLog";
import {colorWithAlpha} from "src/js/utils/AppUtils";

const SPEED_BALL = 310;
const SPEED_BALL_INCREASE = SPEED_BALL/10;
const SPEED_SECTOR = 200;
const SPEED_SECTOR_INCREASE = SPEED_SECTOR/10;
const MIN_ANGULAR_SIZE = 45;
const MAX_ANGULAR_SIZE = 90;
const ANGULAR_SIZE_REDUCE = 2;
const ANGLE_DELTA = 5;
const SECTOR_POS_CHANGE_AFTER_MS = 3500;

export class GAHGame extends Scene {

  constructor() {
    super('GAHGame');
  }

  onEventRestart = () => {
    this.cleanup();
    if (!this.game.options.simulation) {
      this.scene.stop('GAHGame');
      this.scene.start('GAHGame');
    }
  };

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
    if (this.audioHit) {
      this.audioHit.setMute(mute);
    }
    if (this.audioFire) {
      this.audioFire.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
    if (this.audioGameWin) {
      this.audioGameWin.setMute(mute);
    }
  };

  onEventSimulate = () => {
    this.autoSimulate = true;
    this.autoPlay();
  }

  autoPlay = () => {
    this.autoPlayEvent = addTimerEvent(this, Phaser.Math.Between(900, 3000), () => {
      if (!this.gameEnded) {
        this.onClick();
        this.autoPlay();
      }
    });
  }

  cleanup = () => {
    this.autoSimulate = false;
    this.game.events.off('mute', this.onEventMute);
    this.game.events.off('restart', this.onEventRestart);
    this.game.events.off('simulate', this.onEventSimulate);
    if (this.audioGameBg) {
      this.audioGameBg.stop();
    }
    this.detachPointers();
    this.time.removeAllEvents();
    this.events.off('destroy');
  }

  create() {
    this.events.on('destroy', this.cleanup);
    this.game.events.on('mute', this.onEventMute);
    this.game.events.on('restart', this.onEventRestart);
    this.game.events.on('simulate', this.onEventSimulate);

    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';
    const stringHintDetail = this.game.options.locale && this.game.options.locale == 'ar' ? 'انقر لتبدأ' : 'Click to Start';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.startTimestamp = 0;
    this.moveDir = 0;
    this.ballCollided = false;
    this.ballEaten = false;
    this.score = 0;
    this.tryCount = this.game.options.tryCount || 20;
    this.scoreToWin = this.game.options.scoreToWin || 12;
    this.gameEnded = false;

    if (!this.game.options.simulation) {
      this.audioGameBg = this.sound.add('audio_bg', {loop: true})
        .setMute(this.game.options.mute);
      this.audioGameBg.play();
      this.audioHit = this.sound.add('audio_hit').setMute(this.game.options.mute)
        .setVolume(0.5);
      this.audioFire = this.sound.add('audio_fire').setMute(this.game.options.mute)
        .setVolume(0.5);
      this.audioGameEnd = this.sound.add('audio_game_end').setMute(this.game.options.mute);
      this.audioGameEnd.setVolume(0.2);
      this.audioGameWin = this.sound.add('audio_game_win').setMute(this.game.options.mute);
      this.audioGameWin.setVolume(0.5);
    }

    const themeColorInt = Phaser.Display.Color.HexStringToColor(this.game.options.themeColor).color;
    const readableColor = getReadableColor(this.game.options.themeColor);
    const readableColorInt = Phaser.Display.Color.HexStringToColor(readableColor).color;

    const graphics = this.add.graphics();
    graphics.fillStyle(themeColorInt, 0.7);
    graphics.fillRect(0, 0, gameWidth, gameHeight);

    this.textScore = this.add.text(gameWidth / 2, 68, this.score, {
      fontFamily: 'Poppins',
      fontSize: 72,
      align: 'center',
      color: readableColor,
      backgroundColor: colorWithAlpha(readableColor, 0.1),
      padding: {
        left: 20,
        right: 20,
        top: 8,
        bottom: 0
      },
      cornerRadius: 4,
    }).setOrigin(0.5)
      .setDepth(1)
      .setShadow(0, 0, 0xffffff, 4);

    this.add.image(gameWidth - 104, 62, 'handPointer')
      .setDisplaySize(36, 36);
    this.add.text(gameWidth - 68, 60, 'x', {
      fontFamily: 'Poppins',
      fontSize: 32, color: readableColor,
      align: 'center'
    }).setOrigin(0.5);
    this.textTryCount = this.add.text(gameWidth - 36, 62, this.tryCount, {
      fontFamily: 'Poppins',
      fontSize: 32, color: readableColor,
      align: 'center'
    }).setOrigin(0.5);

    const ballImageSize = 112;
    const marginCircle = 2;
    const borderCircle = 4;
    const spaceBall = 2;
    const raceWidth = ballImageSize + 2*spaceBall;
    this.trackCenterX = gameWidth/2;
    this.trackCenterY = gameWidth/2 + ((gameHeight-gameWidth) * .6);
    this.trackRadius = gameWidth/2 - marginCircle - borderCircle - ballImageSize/2 - spaceBall;

    const outerCircleRadius = gameWidth/2 - marginCircle;
    const innerCircleRadius = outerCircleRadius - 2*borderCircle - ballImageSize - 2*spaceBall;
    this.add.circle(this.trackCenterX, this.trackCenterY, outerCircleRadius, 0x000000);
    this.add.circle(this.trackCenterX, this.trackCenterY, innerCircleRadius + borderCircle + raceWidth/2).setStrokeStyle(raceWidth, 0xf46b5f);

    this.arcRadius = outerCircleRadius - borderCircle;
    this.arcGraphics = this.add.graphics({fillStyle: {color: 0x8c3d36}});
    this.arcContainer = this.add.container(this.trackCenterX, this.trackCenterY);
    this.arcContainer.setSize(2*this.arcRadius, 2*this.arcRadius);
    this.arcContainer.add(this.arcGraphics);
    this.updateArc(45);

    const adImageSize = 2 * (innerCircleRadius + borderCircle/2);
    this.add.image(this.trackCenterX, this.trackCenterY, 'adImage1')
      .setDisplaySize(adImageSize, adImageSize);
    this.add.circle(this.trackCenterX, this.trackCenterY, innerCircleRadius + borderCircle/2).setStrokeStyle(borderCircle, 0x000000);

    this.ball = this.physics.add.image(this.trackCenterX, this.trackCenterY + this.trackRadius, 'ball')
      .setDisplaySize(ballImageSize, ballImageSize);

    if (!this.game.options.simulation) {
      this.textHintDetail = this.add.text(gameWidth / 2, gameHeight - 78, stringHintDetail, {
        fontFamily: 'Poppins',
        fontSize: 45, color: readableColor,
        align: 'center'
      }).setOrigin(0.5)
        .setAlpha(0.9);

      this.gameOverText = this.add.text(gameWidth / 2, 180, this.stringGameOver, {
        fontFamily: 'Poppins',
        fontSize: 68,
        color: readableColor,
        backgroundColor: colorWithAlpha(readableColor, 0.2),
        stroke: '#000', strokeThickness: 4,
        align: 'center', padding: 24,
      }).setOrigin(0.5).setVisible(false).setDepth(1);
    }

    this.readyToPlayGame();
  }

  update(time, delta) {
    const speedBall = this.game.options.simulation ? SPEED_BALL*.8 : SPEED_BALL;
    const speed = (speedBall + SPEED_BALL_INCREASE * this.score) * (delta/1000);
    const speedSector = (SPEED_SECTOR + SPEED_SECTOR_INCREASE * this.score) * (delta/1000);
    let angleChange = 0;
    let deltaAngle = 0;
    let sectorAngleChange = 0;
    if (this.moveDir === 1) {
      angleChange = speed;
      deltaAngle = -80;
      sectorAngleChange = -speedSector;
    } else if (this.moveDir === -1) {
      angleChange = -speed;
      deltaAngle = 80;
      sectorAngleChange = speedSector;
    }
    if (angleChange) {
      let angle = this.ballRevolutionAngle + angleChange/100;
      this.ballRevolutionAngle = angle;

      const x = this.trackCenterX + this.trackRadius * Math.cos(angle);
      const y = this.trackCenterY + this.trackRadius * Math.sin(angle);
      this.ball.setPosition(x, y);

      let directionX = this.trackCenterX - x;
      let directionY = this.trackCenterY - y;
      let moonRotationAngle = Math.atan2(directionY, directionX) + deltaAngle;
      this.ball.setRotation(moonRotationAngle);

      this.arcContainer.angle += sectorAngleChange;
    }
  }

  updateArc = (angle = 0) => {
    this.arcGraphics.clear();
    const randomAngleLength = angle ? MAX_ANGULAR_SIZE : Phaser.Math.Between(MIN_ANGULAR_SIZE, MAX_ANGULAR_SIZE);
    const angleLength = randomAngleLength - this.score * ANGULAR_SIZE_REDUCE;

    let startAngle;
    if (this.arcStartAngle) {
      const oldAngleLength = this.arcEndAngle < this.arcStartAngle ? this.arcEndAngle+360 - this.arcStartAngle : this.arcEndAngle - this.arcStartAngle;
      if (oldAngleLength > angleLength) {
        startAngle = (this.arcStartAngle + (oldAngleLength-angleLength)/2) % 360;
      } else {
        startAngle = (this.arcStartAngle - (angleLength-oldAngleLength)/2);
        if (startAngle < 0) {
          startAngle += 360;
        }
      }
    } else {
      startAngle = angle || Phaser.Math.Between(1, 360);
    }

    this.arcStartAngle = startAngle;
    this.arcEndAngle = (startAngle + angleLength) % 360;
    this.arcGraphics.fillStyle(0x000000, 0.3);
    this.arcGraphics.slice(0, 0, this.arcRadius, Phaser.Math.DegToRad(this.arcStartAngle), Phaser.Math.DegToRad(this.arcEndAngle), false);
    this.arcGraphics.fillPath();

    if (!angle) {
      this.moveDir = Phaser.Math.Between(0, 1) ? 1 : -1;
    }

    if (this.startTimestamp && !this.gameEnded && !this.ballEaten) {
      addTimerEvent(this, SECTOR_POS_CHANGE_AFTER_MS, () => {
        if (this.startTimestamp && !this.gameEnded && !this.ballEaten) {
          this.updateArc();
        }
      });
    }
  }

  updateScore = () => {
    this.textScore.setText(this.score);
  }

  attachPointers = () => {
    this.input.on('pointerdown', this.onClick);
  }

  detachPointers = () => {
    this.input.off('pointerdown', this.onClick);
  }

  onClick = () => {
    if (this.gameEnded) {
      return;
    }

    if (!this.startTimestamp) {
      this.moveDir = 1;
      this.startTimestamp = Date.now();
      if (this.textHintDetail) {
        this.textHintDetail.setVisible(false);
      }
      this.ballRevolutionAngle = this.getRevolutionAngle(this.ball.x, this.ball.y, this.trackCenterX, this.trackCenterY, this.trackRadius);
      this.updateArc(315);
    } else if (!this.ballEaten && this.tryCount > 0) {
      if (this.audioFire) {
        this.audioFire.play();
      }
      this.tryCount--;
      this.textTryCount.setText(this.tryCount);

      let ballAngle = this.getBallAngle();
      let arcStartAngle = (this.arcStartAngle - ANGLE_DELTA + this.arcContainer.angle) % 360;
      if (arcStartAngle < 0) {
        arcStartAngle += 360;
      }
      let arcEndAngle = (this.arcEndAngle + ANGLE_DELTA + this.arcContainer.angle) % 360;
      if (arcEndAngle < 0) {
        arcEndAngle += 360;
      }
      logDebug('arcContainerAngle', this.arcContainer.angle);
      logDebug('arcStartAngle', arcStartAngle);
      logDebug('arcEndAngle', arcEndAngle);
      logDebug('ballAngle', ballAngle);
      if (arcStartAngle > arcEndAngle) {
        if (ballAngle > arcStartAngle) {
          arcEndAngle += 360;
        } else {
          arcStartAngle = 0;
        }
      }

      if (ballAngle >= arcStartAngle && ballAngle <= arcEndAngle) {
        this.onBallOverlap();
        const width = this.ball.width;
        const height = this.ball.height;
        this.ball.setDisplaySize(width*1.5, height*1.5);
        addTimerEvent(this, 300, () => {
          this.ball.setDisplaySize(width, height);
        });
      } else {
        this.score = Math.max(0, this.score - 1);
        this.updateScore();
        this.ball.setAlpha(0.2);
        addTimerEvent(this, 300, () => {
          this.ball.setAlpha(1);
        });
      }

      if (this.tryCount === 0) {
        addTimerEvent(this, 200, () => {
          this.endGame();
        });
      }
    }
  }

  getBallAngle() {
    // Define the endpoints of the first line (x1, y1) to (x2, y2)
    const x1 = this.ball.x, y1 = this.ball.y;
    const x2 = this.trackCenterX, y2 = this.trackCenterY;

    // Define the endpoints of the second line (x3, y3) to (x4, y4)
    const x3 = this.trackCenterX + this.trackRadius, y3 = this.trackCenterY;
    const x4 = this.trackCenterX, y4 = this.trackCenterY;

    // Calculate the angle of the first line with respect to the horizontal axis
    const angle1 = Phaser.Math.Angle.Between(x1, y1, x2, y2);

    // Calculate the angle of the second line with respect to the horizontal axis
    const angle2 = Phaser.Math.Angle.Between(x3, y3, x4, y4);

    // Calculate the difference between the two angles to find the angle between the lines
    const angleBetweenLines = Phaser.Math.Angle.Wrap(angle1 - angle2);

    // Convert the angle from radians to degrees
    let angleBetweenLinesDegrees = Phaser.Math.RadToDeg(angleBetweenLines);
    if (angleBetweenLinesDegrees < 0) {
      angleBetweenLinesDegrees += 360;
    }

    // console.log('BallAngle', angleBetweenLinesDegrees);
    return angleBetweenLinesDegrees;
  }

  getRevolutionAngle(pointX, pointY, circleCenterX, circleCenterY, circleRadius) {
    // Calculate the difference between point and circle center coordinates
    let dx = pointX - circleCenterX;
    let dy = pointY - circleCenterY;

    // Calculate the angle using Math.atan2 (adjust for zero radius case)
    let angle = Math.atan2(dy, dx) + (circleRadius === 0 ? Math.PI / 2 : 0);

    // Adjust angle to be between 0 and 2PI (optional)
    angle = (angle + Math.PI * 2) % (Math.PI * 2);

    return angle;
  }

  onBallOverlap = () => {
    if (!this.ballEaten) {
      if (this.score === 0) {
        this.game.events.emit('eventOnStart');
      }
      this.detachPointers();
      this.ballEaten = true;
      this.score++;
      this.updateScore();
      this.ball.setAlpha(0.8);
      if (this.audioHit) {
        this.audioHit.play();
      }

      addTimerEvent(this, 200, () => {
        if (this.score >= this.scoreToWin) {
          this.endGame();
        } else {
          this.updateArc();
          addTimerEvent(this, 200, () => {
            logDebug('ballOverlapEnd');
            this.attachPointers();
            this.ballEaten = false;
            this.ball.setAlpha(1);
          });
        }
      });
    }
  }

  readyToPlayGame = () => {
    this.moveDir = 0;
    if (!this.gameEnded) {
      this.attachPointers();
    }
    if (this.autoSimulate) {
      this.autoPlay();
    }
  }

  endGame = () =>  {
    const isWin = this.score >= this.scoreToWin;
    this.gameEnded = true;

    this.detachPointers();
    this.moveDir = 0;
    this.ball.setVelocity(0, 0);

    if (isWin) {
      if (this.audioGameWin) {
        this.audioGameWin.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringWellDone);
      }
    } else {
      if (this.audioGameEnd) {
        this.audioGameEnd.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringGameOver);
      }
    }

    addTimerEvent(this, 1000, () => {
      if (this.gameOverText) {
        this.gameOverText.setVisible(true);
      }
      addTimerEvent(this, 1000, () => {
        this.game.events.emit('eventOnEnd', this.score);
      });
    });
  }
}
