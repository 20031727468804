import PropTypes from 'prop-types';
import React, {forwardRef, useImperativeHandle, useLayoutEffect, useRef} from 'react';
import Phaser from "phaser";
import {GPBoot} from "src/gamePonk/scenes/GPBoot.js";
import {GPPreloader} from "src/gamePonk/scenes/GPPreloader.js";
import {GPGame} from "src/gamePonk/scenes/GPGame.js";
import {logDebug} from "src/js/utils/AppLog";
import {isWebGLAvailable} from "src/game/GameHelper";

const config = {
  type: Phaser.AUTO,
  transparent: true,
  width: 675,
  height: 1080,
  parent: 'game-container',
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.NO_CENTER
  },
  physics: {
    default: 'arcade',
    arcade: {
      // debug: true
    }
  },
  scene: [
    GPBoot,
    GPPreloader,
    GPGame
  ],
};

export const gamePonkInfo = () => {
  return {
    'configs': {
      'themeBg': 1,
      'images': [
        {'number': 0, 'url': 'media/gamePonk/adImage1.png'},
        {'number': 1, 'url': 'media/gamePonk/adImage2.png'},
        {'number': 2, 'url': 'media/gamePonk/adImage3.png'},
        {'number': 3, 'url': 'media/gamePonk/user.webp'},
        {'number': 4, 'url': 'media/gamePonk/robot.webp'},
      ],
      'audios': [{'label': 'Background', 'url': 'media/gamePonk/audios/game_bg.mp3'}],
      'preload': {
        'baseUrl': 'media/gamePonk',
        'media': [
          'adImage1.png',
          'adImage2.png',
          'adImage3.png',
          'dummy.webp',
          'robot.webp',
          'user.webp'
        ],
      }
    },
  };
}

export const GamePonk = forwardRef((props, ref) => {
  const {width, height, options} = props;

  const game = useRef();

  useImperativeHandle(ref, () => ({
    start, restart, mute, simulate
  }));

  const start = () => {
    game.current.scene.switch('GPGame');
  }

  const restart = (options) => {
    if (game.current) {
      game.current.options.canPlay = options.canPlay;
      if (!game.current.options.simulation) {
        game.current.events.emit('restart');
      }
    }
  }

  const mute = (mute) => {
    if (game.current) {
      if (game.current.options) {
        game.current.options.mute = mute;
      }
      game.current.events.emit('mute', mute);
    }
  }

  const simulate = () => {
    if (game.current) {
      game.current.events.emit('simulate');
    }
  }

  const onAppDidFocus = () => {
    if (game.current) {
      game.current.resume();
    }
  }

  const onAppDidBlur = () => {
    if (game.current) {
      game.current.pause();
    }
  }

  // Create the GamePonk inside a useLayoutEffect hook to avoid the GamePonk being created outside the DOM
  useLayoutEffect(() => {
    if (game.current === undefined) {
      logDebug('DG-game useLayoutEffect');

      if (width && height) {
        config.width = config.height * width/height;
      }
      if (!isWebGLAvailable()) {
        config.type = Phaser.CANVAS;
      }
      if (options.simulation) {
        config.type = Phaser.CANVAS;
        config.audio = {noAudio: true}
      }

      const parent = 'game-container';
      game.current = new Phaser.Game({...config, parent});
      game.current.options = options;

      game.current.events.on('eventOnStart', () => {
        if (options && options.onStart) {
          options.onStart();
        }
      });
      game.current.events.on('eventOnEnd', (score) => {
        if (options && options.onEnd) {
          options.onEnd(score);
        }
      });
      window.addEventListener('focus', onAppDidFocus);
      window.addEventListener('blur', onAppDidBlur);
    }

    return () => {
      if (game.current) {
        game.current.events.removeListener('eventOnStart');
        game.current.events.removeListener('eventOnEnd');
        window.removeEventListener('focus', onAppDidFocus);
        window.removeEventListener('blur', onAppDidBlur);

        game.current.options = null;
        game.current.destroy(true);
        logDebug('DGG:GameDestroyed');
        game.current = undefined;
      }
    }
  }, [width, height, options]);

  return (
    <div id="game-container" style={{backgroundColor: options.bgColor, width: (options.isRotated ? height : width)/options.scale, height: (options.isRotated ? width : height)/options.scale}}/>
  );

});

// Props definitions
GamePonk.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.object
}
