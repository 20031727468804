import {Scene} from 'phaser';
import {addTimerEvent, checkOverlap, getReadableColor} from "src/game/GameHelper";
import {logDebug} from "src/js/utils/AppLog";
import {colorWithAlpha} from "src/js/utils/AppUtils";

const SPEED_PAC = 100;
const SPEED_PAC_INCREASE = SPEED_PAC/10;
const SPEED_SAW = 250;
const SPEED_SAW_INCREASE_PER = SPEED_SAW/15;
const SAW_DELAY = 2500;
const SAW_DELAY_REDUCE = 100;
const NO_HIT_AUTO_SIMULATION_BEFORE_SCORE = 6;

export class GPRGame extends Scene {

  constructor() {
    super('GPRGame');
  }

  onEventRestart = () => {
    this.cleanup();
    if (!this.game.options.simulation) {
      this.scene.stop('GPRGame');
      this.scene.start('GPRMainMenu');
    }
  };

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
    if (this.audioEat) {
      this.audioEat.setMute(mute);
    }
    if (this.audioCrash) {
      this.audioCrash.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
    if (this.audioGameWin) {
      this.audioGameWin.setMute(mute);
    }
  };

  autoPlay = () => {
    this.autoPlayEvent = addTimerEvent(this, Phaser.Math.Between(2000, 8000), () => {
      if (!this.gameEnded) {
        this.onClick();
        this.autoPlay();
      }
    });
  }

  cleanup = () => {
    this.game.events.off('mute', this.onEventMute);
    this.game.events.off('restart', this.onEventRestart);
    if (this.audioGameBg) {
      this.audioGameBg.stop();
    }
    this.input.off('pointerdown');
    this.time.removeAllEvents();
    this.events.off('destroy');
  }

  init(data) {
    this.inAutoSimulation = data.inAutoSimulation;
  }

  create() {
    this.events.on('destroy', this.cleanup);
    this.game.events.on('mute', this.onEventMute);
    this.game.events.on('restart', this.onEventRestart);

    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';
    const stringHintDetail = this.game.options.locale && this.game.options.locale == 'ar' ? 'انقر لتغيير الاتجاه' : 'Tap to change direction';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    const readableColor = getReadableColor(this.game.options.themeColor);

    this.moveDir = 0;
    this.ballCollided = false;
    this.ballEaten = false;
    this.score = 0;
    this.scoreToWin = this.game.options.scoreToWin || 7;
    this.gameEnded = false;

    if (!this.game.options.simulation) {
      this.audioGameBg = this.sound.add('audio_bg', {loop: true})
        .setMute(this.game.options.mute);
      this.audioGameBg.play();
      this.audioEat = this.sound.add('audio_eat').setMute(this.game.options.mute)
        .setVolume(0.5);
      this.audioCrash = this.sound.add('audio_crash').setMute(this.game.options.mute);
      // .setVolume(0.7);

      this.audioGameEnd = this.sound.add('audio_game_end').setMute(this.game.options.mute);
      this.audioGameEnd.setVolume(0.2);
      this.audioGameWin = this.sound.add('audio_game_win').setMute(this.game.options.mute);
      this.audioGameWin.setVolume(0.5);
    }

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);
    const dynamicColor = Phaser.Display.Color.HexStringToColor(this.game.options.themeColor).color; // Convert hex string to Phaser color
    const dynamicAlpha = 0.7;
    const graphics = this.add.graphics();
    graphics.fillStyle(dynamicColor, dynamicAlpha);
    graphics.fillRect(0, 0, gameWidth, gameHeight);

    this.textScore = this.add.text(gameWidth / 2, 68, this.score, {
      // fontFamily: 'Poppins',
      fontSize: 72,
      align: 'center',
      color: readableColor,
      backgroundColor: colorWithAlpha(readableColor, 0.1),
      padding: {
        left: 20,
        right: 20,
        top: 2,
        bottom: 0
      },
      cornerRadius: 4,
    }).setOrigin(0.5)
      .setDepth(1)
      .setShadow(0, 0, 0xffffff, 4);

    const ballWidth = 224 * .7;
    const ballHeight = 180 * .7;
    this.ballImageSize = ballHeight * .8;
    const ballImageSize = this.ballImageSize;

    this.trackCenterX = gameWidth/2;
    this.trackCenterY = gameWidth/2 + ((gameHeight-gameWidth) * .75);
    this.trackRadius = gameWidth/2 - 22 - 11 - ballHeight/2 + 4;

    const outerBorder = 5;
    const innerBorder = 4;
    this.add.circle(this.trackCenterX, this.trackCenterY, gameWidth/2 - 22, 0x375654);
    const adImageSize = 2 * (gameWidth/2 - 22 - 2*outerBorder - ballHeight + 5 - 6);
    this.adImage1 = this.add.image(this.trackCenterX, this.trackCenterY, 'adImage1')
      .setDisplaySize(adImageSize, adImageSize);
    this.add.circle(this.trackCenterX, this.trackCenterY, gameWidth/2 - 22 - 2*outerBorder - ballHeight/2).setAlpha(0.8).setStrokeStyle(ballHeight, 0x16E7E0);
    this.add.circle(this.trackCenterX, this.trackCenterY, gameWidth/2 - 22 - 2*outerBorder - ballHeight + 5 - 3).setStrokeStyle(2*innerBorder, 0x375654);

    this.ball = this.physics.add.image(this.trackCenterX - this.trackRadius, this.trackCenterY, 'ball')
      .setDisplaySize(ballImageSize, ballImageSize);
    // this.ball.body.setCircle(this.ball.width*.4);
    const actualRadius = this.ball.width*.5;
    const bodyRadius = this.ball.width*.4;
    this.ball.body.setCircle(this.ball.width*.4, actualRadius-bodyRadius, actualRadius-bodyRadius);

    this.pacman = this.add.container(this.trackCenterX, this.trackCenterY + this.trackRadius);
    this.pacmanSprite = this.physics.add.sprite(0, 0, 'pacmanS', 'pac1.png')
      .setDisplaySize(ballWidth, ballHeight);
    this.pacmanSprite.body.setCircle(this.pacmanSprite.width/2);
    this.pacman.add(this.pacmanSprite);
    this.pacmanBody = this.physics.add.image((ballWidth-ballHeight)/2, 0, 'striker')
      .setDisplaySize(ballImageSize, ballImageSize)
      .setBodySize(ballImageSize *.7, ballImageSize *.7);
    this.pacmanBody.body.setCircle(this.pacmanBody.body.width/2);
    this.pacman.add(this.pacmanBody);
    this.frameNames = this.anims.generateFrameNames('pacmanS', {
      prefix: 'pac',
      suffix: '.png',
      start: 1,
      end: 16,
    });
    this.anims.create({
      key: 'animPacmanMove',
      frames: this.frameNames,
      frameRate: 24,
      repeat: -1
    });

    this.sawsData = {
      saws: [],
      sawColliders: [],
      activeSawIndexes: new Set(),
    }

    if (!this.game.options.simulation) {
      this.textHintDetail = this.add.text(gameWidth / 2, gameHeight - 56, stringHintDetail, {
        fontFamily: 'Poppins',
        fontSize: 34, color: readableColor,
        align: 'center'
      }).setOrigin(0.5)
        .setAlpha(0.9);
      addTimerEvent(this, 2000, () => {
        this.textHintDetail.setVisible(false);
      });

      this.gameOverText = this.add.text(gameWidth / 2, 180, this.stringGameOver, {
        fontSize: 68,
        color: readableColor,
        backgroundColor: colorWithAlpha(readableColor, 0.2),
        stroke: '#000', strokeThickness: 4,
        align: 'center', padding: 14,
      }).setOrigin(0.5).setVisible(false).setDepth(1);
    }

    this.ballOverlap = this.physics.add.overlap(this.pacmanSprite, this.ball, this.onBallOverlap);
    this.readyToPlayGame();
  }

  update(time, delta) {
    const speed = (SPEED_PAC + SPEED_PAC_INCREASE * this.score) * (delta/1000);
    let angleChange = 0;
    let deltaAngle = 0;
    if (this.moveDir === 1) {
      angleChange = speed;
      deltaAngle = -80;
    } else if (this.moveDir === -1) {
      angleChange = -speed;
      deltaAngle = 80;
    }
    if (angleChange) {
      let angle = this.startAngle + angleChange/100;
      this.startAngle = angle;

      const x = this.trackCenterX + this.trackRadius * Math.cos(angle);
      const y = this.trackCenterY + this.trackRadius * Math.sin(angle);
      this.pacman.setPosition(x, y);

      let directionX = this.trackCenterX - x;
      let directionY = this.trackCenterY - y;
      let moonRotationAngle = Math.atan2(directionY, directionX) + deltaAngle;
      this.pacman.setRotation(moonRotationAngle);
    }
  }

  attachPointers = () => {
    this.input.on('pointerdown', this.onClick);
  }

  detachPointers = () => {
    this.input.off('pointerdown', this.onClick);
  }

  onClick = () => {
    if (!this.moveDir) {
      this.sawMoveTimer = addTimerEvent(this, 100, this.processSaw);
    }

    if (!this.moveDir || this.moveDir === -1) {
      this.startAngle = this.getRevolutionAngle(this.pacman.x, this.pacman.y, this.trackCenterX, this.trackCenterY, this.trackRadius);
      this.moveDir = 1;
      this.pacmanSprite.anims.play('animPacmanMove');
    } else {
      this.startAngle = this.getRevolutionAngle(this.pacman.x, this.pacman.y, this.trackCenterX, this.trackCenterY, this.trackRadius);
      this.moveDir = -1;
      this.pacmanSprite.anims.play('animPacmanMove');
    }
  }

  processSaw = () => {
    // reset out indexes
    if (this.sawsData.activeSawIndexes.size > 0) {
      let indexesToReset = null;
      this.sawsData.activeSawIndexes.forEach((sawIndex) => {
        const saw = this.sawsData.saws[sawIndex];
        if (saw.y > this.game.config.height + saw.displayHeight) {
          if (!indexesToReset) {
            indexesToReset = [];
          }
          indexesToReset.push(sawIndex);
          saw.setPosition(this.game.config.width/2, -saw.displayHeight);
          saw.setVelocityY(0);
          // saw.setVisible(false);
        }
      });
      if (indexesToReset && indexesToReset.length > 0) {
        indexesToReset.forEach((indexToReset) => {
          this.sawsData.activeSawIndexes.delete(indexToReset);
        });
      }
    }

    let sawIndex = -1;
    for (let i = 0; i < this.sawsData.saws.length; i++) {
      if (!this.sawsData.activeSawIndexes.has(i)) {
        sawIndex = i;
        break;
      }
    }
    if (sawIndex === -1) {
      const sawSize = this.game.config.width/4;
      const saw = this.physics.add.image(0, -sawSize, 'saw');
      saw.setDisplaySize(sawSize, sawSize);
      saw.setOffset(saw.body.width * .05, saw.body.height * .65);
      saw.setBodySize(saw.body.width * .9, saw.body.height * .3, false);
      this.sawsData.sawColliders.push(this.physics.add.overlap(this.pacmanBody, saw, this.onBallCollide));

      this.sawsData.saws.push(saw);
      sawIndex = this.sawsData.saws.length - 1;
    }

    let delaySaw = SAW_DELAY;
    let speedSaw = SPEED_SAW;
    if (this.inAutoSimulation) {
      speedSaw = SPEED_SAW/2;
      delaySaw = SAW_DELAY*2;
    }

    if (sawIndex >= 0) {
      this.sawsData.activeSawIndexes.add(sawIndex);
      const saw = this.sawsData.saws[sawIndex];
      let xPos = Phaser.Math.Between(saw.displayWidth/4, this.game.config.width - saw.displayWidth/4);
      if (this.inAutoSimulation && this.score <= NO_HIT_AUTO_SIMULATION_BEFORE_SCORE) {
        if (Math.random() > 0.5) {
          xPos = Phaser.Math.Between(saw.displayWidth/4, this.game.config.width/4 - saw.displayWidth/4);
        } else {
          xPos = Phaser.Math.Between(this.game.config.width *3/4 - saw.displayWidth/4, this.game.config.width);
        }
      }
      saw.setPosition(xPos, -saw.displayHeight);
      saw.setVelocityY(speedSaw + SPEED_SAW_INCREASE_PER * this.score);
      saw.setVisible(true);
    }
    this.sawMoveTimer = addTimerEvent(this, delaySaw - SAW_DELAY_REDUCE * this.score, this.processSaw);
    if (this.lastProcess) {
      logDebug('processSaw', new Date() - this.lastProcess);
    }
    this.lastProcess = new Date();
  }

  getRevolutionAngle(pointX, pointY, circleCenterX, circleCenterY, circleRadius) {
    // Calculate the difference between point and circle center coordinates
    let dx = pointX - circleCenterX;
    let dy = pointY - circleCenterY;

    // Calculate the angle using Math.atan2 (adjust for zero radius case)
    let angle = Math.atan2(dy, dx) + (circleRadius === 0 ? Math.PI / 2 : 0);

    // Adjust angle to be between 0 and 2PI (optional)
    angle = (angle + Math.PI * 2) % (Math.PI * 2);

    return angle;
  }

  onBallCollideAutoSimulation = (ball, otherObject) => {
    const isOverlapped = checkOverlap(ball, otherObject);
    if (isOverlapped && !this.autoSimulationBallCollided) {
      this.autoSimulationBallCollided = true;
      if (this.autoPlayEvent) {
        this.time.removeEvent(this.autoPlayEvent);
      }
      this.onClick();
      addTimerEvent(this, 1000, () => {
        this.autoSimulationBallCollided = false;
        this.autoPlay();
      });
    }
  }

  onBallCollide = (ball, otherObject) => {
    if (this.inAutoSimulation && this.score <= NO_HIT_AUTO_SIMULATION_BEFORE_SCORE) {
      this.onBallCollideAutoSimulation(ball, otherObject);
      return;
    }

    const isOverlapped = checkOverlap(ball, otherObject);
    if (isOverlapped && !this.ballCollided) {
      this.ballCollided = true;
      this.pacman.setAlpha(0.5);
      if (this.audioCrash) {
        this.audioCrash.play();
      }
      this.endGame();
    }
  }

  onBallOverlap = (ball, otherObject) => {
    this.pacmanSprite.anims.pause();
    const isOverlapped = checkOverlap(ball, otherObject);
    if (isOverlapped && !this.ballEaten) {
      if (this.score === 0) {
        this.game.events.emit('eventOnStart');
      }
      this.ballEaten = true;
      this.score++;
      this.textScore.setText(this.score);
      if (this.inAutoSimulation && this.score > NO_HIT_AUTO_SIMULATION_BEFORE_SCORE) {
        this.pacmanBody.setBodySize(this.ballImageSize*.7, this.ballImageSize*.7);
        this.pacmanBody.body.setCircle(this.pacmanBody.body.width/2);
      }

      this.ballOverlap.active = false;

      addTimerEvent(this, 200, () => {
        this.ball.setVisible(false);
        this.updateBallPosition();
        if (this.audioEat) {
          this.audioEat.play();
        }

        if (this.score >= this.scoreToWin) {
          this.endGame();
        } else {
          addTimerEvent(this, 200, () => {
            this.pacmanSprite.anims.resume();
            this.ballEaten = false;
            this.ballOverlap.active = true;
            this.ball.setVisible(true);
          });
        }
      });
    }
  }

  updateBallPosition = () => {
    let rotationSpeed = Phaser.Math.Between(-3, 3);
    if (rotationSpeed === 0) {
      rotationSpeed = -1;
    }
    const currentAngle = this.getRevolutionAngle(this.ball.x, this.ball.y, this.trackCenterX, this.trackCenterY, this.trackRadius);
    const newAngle = currentAngle + rotationSpeed;
    const x = this.trackCenterX + this.trackRadius * Math.cos(newAngle);
    const y = this.trackCenterY + this.trackRadius * Math.sin(newAngle);
    this.ball.setPosition(x, y);
  };

  readyToPlayGame = () => {
    this.moveDir = 0;
    if (!this.gameEnded) {
      this.attachPointers();
    }
    this.pacman.setVisible(true);
    this.sawsData.sawColliders.forEach((sawCollider) => {
      sawCollider.active = true;
    });
    this.onClick();
    if (this.inAutoSimulation) {
      this.autoPlay();
      this.pacmanBody.setBodySize(this.ballImageSize*2.5, this.ballImageSize*2.5);
      this.pacmanBody.body.setCircle(this.pacmanBody.body.width/2);
    }
  }

  endGame = () =>  {
    const isWin = this.score >= this.scoreToWin;
    this.gameEnded = true;

    this.detachPointers();
    this.moveDir = 0;
    this.pacmanSprite.setVelocity(0, 0);
    this.pacmanBody.setVelocity(0, 0);
    this.pacmanSprite.anims.stop();
    if (this.sawMoveTimer) {
      this.time.removeEvent(this.sawMoveTimer);
      this.sawMoveTimer = null;
    }
    this.sawsData.saws.forEach((saw) => {
      saw.setVelocityY(0);
    });
    this.sawsData.sawColliders.forEach((sawCollider) => {
      sawCollider.active = false;
    });

    if (isWin) {
      if (this.audioGameWin) {
        this.audioGameWin.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringWellDone);
      }
    } else {
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringGameOver);
      }
    }

    addTimerEvent(this, 1000, () => {
      if (this.gameOverText) {
        this.gameOverText.setVisible(true);
      }
      addTimerEvent(this, 1000, () => {
        this.game.events.emit('eventOnEnd', this.score);
      });
    });
  }
}
